import { useState} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import RegisterLogo from './images/LogoRegister.png'
//*******************end of UI Materials */
import { useTranslation } from 'react-i18next'; // multi language package
//*******************************iconsax */
import { Briefcase, Home2 ,People,Trade} from 'iconsax-react';
import { Profile2User } from 'iconsax-react';
import { Profile } from 'iconsax-react';
import Logo from './images/logo-tele.png';
//******************************** */
const ResetPassword = () => {
    //********************************fetching API */


    const history = useHistory();
    const handleSubmit = (e) => {
         e.preventDefault();
         localStorage.setItem('userType' , 1);
         history.push('/chooseservice');

    }
    
    const handleSubmit2 = (e) => {
      e.preventDefault();
      localStorage.setItem('userType' , 2);
      history.push('/chooseDis');

 }
 const handleSubmit3 = (e) => {
  e.preventDefault();
  localStorage.setItem('userType' , 3);
  history.push('/regcstep2');

}
const handleSubmit4 = (e) => {
  e.preventDefault();
  localStorage.setItem('userType' , 4);
  history.push('/regmstep2');

}
    //***************************************** */


//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);

//***************************************/
    return (
      <><div className="pagePic"></div>
          <div className="loginMainRow form_regisster">
            <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
                <div className="mainRegisterheader col-xxl-12 col-12">
                  <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting appropriate user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
                 
                </div>
                <div className="progressRow col-xxl-10 col-12">
                  <div className="myshapes">
                    <div className="mycircle">
                      <h6>1 of 6</h6> 
                    </div>
                    <div className="myProCircle"></div>
                  </div>
                  <div className="mytext">
                    <h5 className="step1">{t('Step 1')}</h5>
                    <h6 className="choosText">{t('Choose Your Account Type')}</h6>
                  </div>
                </div>
                <div className="selectProfile col-xxl-10 col-12">
                  {/* <div className="circle">
                    <Link onClick={handleSubmit} className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Briefcase color="white" variant="Bold" size="60" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Lawyer')}</span>
                    </Link>
                  </div> */}
                   <div className="circle">
                    <Link onClick={handleSubmit} className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Trade color="white" variant="Bold" size="60" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Service Owner')}</span>
                    </Link>
                  </div>
                  <div className="circle">
                  <Link onClick={handleSubmit2} className="font-face-gm BriefcaseA" to='/chooseDis'>
                    <Profile2User color="white" variant="Bold" size="60" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Distributor')}</span>
                    </Link>
                  </div>
                  <div className="circle">
                  <Link onClick={handleSubmit3} className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <Profile color="white" variant="Bold" size="60" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Customer')}</span>
                    </Link>
                  </div>
                  <div className="circle">
                  <Link onClick={handleSubmit4} className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <People color="white" variant="Bold" size="60" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Agent')}</span>
                    </Link>
                  </div>
                </div>
            </div> 
            <div className="footerDasilav col-12">
              <div className="footer-copy">
                Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
              <div className="footer-copy2">
                <br />
                Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
            </div>
          </div>
      </>
           
    );
}
 
export default ResetPassword;