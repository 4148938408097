import { useState,useEffect } from "react";
import { useHistory } from "react-router";
import {Sms,Lock1,Home2,Eye,EyeSlash,Global,InfoCircle} from 'iconsax-react';
import { Link } from "react-router-dom";
// import {BrowserRouter as Router , Route, Switch} from 'react-router-dom'
import Logingroup from './images/Banner02.png';// right city image
import { useTranslation } from 'react-i18next'; // multi language package
import Logo from './images/logo-tele.png';
import CreatableSelect from 'react-select';

const techCompanies = [
  
  { label: "English", value: 1 },
  { label: "Persian", value: 2 },
  { label: "Mandarin", value: 3 },
  { label: "Hindi", value: 4 },
  { label: "Spanish", value: 5 },
  { label: "French", value: 6 },
  { label: "Arabic", value: 7 },
  { label: "Bengali", value: 8 },
  { label: "Russian", value: 9 },
  { label: "Portuguese", value: 10 },
  { label: "Indonesian", value: 11 },
  { label: "Japanese", value: 12 },
  { label: "Turkish", value: 13 },
  { label: "Korean", value: 14 },
  { label: "Dutch", value: 15 },
  { label: "Italian", value: 16 },
  { label: "Romanian", value: 17 },
  { label: "Thai", value: 18 },
  { label: "Nepali", value: 19 },
  { label: "Gujarati", value: 20 },
];
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
    backgroundColor: 'transparent',
  }),
}
const ResetPassword = () => {
  const [username, setUsername] = useState();
  const [helpEmail,setHelpEmail] = useState('');
  const [helppassword,setHelppassword] = useState('');
  const [formError , setFormError] = useState(true);
  const [body, setBody] = useState();
  const [author, setAuthor] = useState('kafa');
  const [ispending, setIspending] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [emailFoucus,setEmailFoucus] = useState(false);
  const [passFoucus,setPassFoucus] = useState(false);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    setIspending(true);
    var stringUrl = window.location.href;
    var strCodePart1 = stringUrl.split("/resetpass2/")[1];
    console.log(strCodePart1);
    fetch('https://telesiness.ir/api/ResetPassword/' , {
        method: 'POST',
        headers: {"Content-type": "application/json"},
        body: JSON.stringify({ 
          ActiveCode: strCodePart1,
          Password: values.password,
          ConfirmPassword: values.Repassword,
        })
    }).then(()=>{
        console.log('new blog added');
        setIspending(false);
        
    }
    )
    // history.push('/resetpass2');
      history.push('/resetpass3');
  }
  //***************************************** */

 // ui materials functions...
      const [values, setValues] = useState({
        Email: '',
        password: '',
        Repassword: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        SelectLanguage:localStorage.getItem('langV'),
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.log(document.getElementById('inputpassword2').value,document.getElementById('inputpassword').value);
        console.log(!(document.getElementById('inputpassword2').value==""),!(document.getElementById('inputpassword').value == document.getElementById('inputpassword2').value));
        if(!(document.getElementById('inputpassword2').value=="") && 
          !(document.getElementById('inputpassword').value == document.getElementById('inputpassword2').value)){
            //  document.getElementById('errorLabelDegree').style.display="flex";
             setHelppassword(t('You Have Not Code OR UnEqual Password...'));
             document.getElementById('errorLabelpassword').style.display="flex";
             setFormError(true);
            }
            else{
              setHelppassword(t(''));
             setFormError(false);
            }
          if(document.activeElement.id=='inputpassword')
        {
          document.getElementById('Mypasswordplaceholder').style.color="transparent";
        }
        else if(values.password=="")
        {
          document.getElementById('Mypasswordplaceholder').style.color="#313131";
        }  
        // if(document.activeElement.id=='inputpasswordM')
        // {
        //   document.getElementById('MypasswordplaceholderM').style.color="transparent";
        // }
        // else if(values.password=="")
        // {
        //   document.getElementById('MypasswordplaceholderM').style.color="#313131";
        // }  
      };
     const handleinputEmailBlur = (e) =>{
      if((!values.Email.match("^[a-zA-Z0-9]+\.+[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$"))&
      !(document.activeElement.id=='inputEmail')){
        setHelpEmail('Envalid Email Format');
        setError(true);}
        else{
          setHelpEmail('');
          setError(false);
        }
        
       
         console.log(document.getElementById('inputEmail').value);
        document.getElementById('errorLabelEmail').style.display="flex";
        document.getElementById('MyEmailplaceholder').style.color="transparent";
        document.getElementById('MyEmailSapn').style.color="transparent";
        if(document.getElementById('inputEmail').value==""){
        document.getElementById('errorLabelEmail').style.display="none";
        document.getElementById('MyEmailplaceholder').style.color="gray";
        document.getElementById('MyEmailSapn').style.color="red";
        setEmailFoucus(!emailFoucus);
       }
       
     }

     
     const handleinputpasswordBlur = (e) =>{
       if(!(document.getElementById('inputpassword').value=="")){
        document.getElementById('errorLabelpassword').style.display="flex";
        document.getElementById('Mypasswordplaceholder').style.color="transparent";
        document.getElementById('MypasswordSapn').style.color="transparent";
       }
       else{
        document.getElementById('errorLabelpassword').style.display="none";
        document.getElementById('Mypasswordplaceholder').style.color="gray";
        document.getElementById('MypasswordSapn').style.color="red";
       }
     }

    //  const handleinputpasswordBlurM = (e) =>{
    //   if(!(document.getElementById('inputpasswordM').value=="")){
    //    document.getElementById('errorLabelpasswordM').style.display="flex";
    //    document.getElementById('MypasswordplaceholderM').style.color="transparent";
    //    document.getElementById('MypasswordSapnM').style.color="transparent";
    //   }
    //   else{
    //    document.getElementById('errorLabelpasswordM').style.display="none";
    //    document.getElementById('MypasswordplaceholderM').style.color="gray";
    //    document.getElementById('MypasswordSapnM').style.color="red";
    //   }
    // }

     const handleEye =(e) =>{
       document.getElementById('eye').style.display='none';
       document.getElementById('eyeslash').style.display='flex';
       document.getElementById('inputpassword').setAttribute('type','text');
     }
     
     const handleEyeSlash =(e) =>{
      document.getElementById('eyeslash').style.display='none';
      document.getElementById('eye').style.display='flex';
      document.getElementById('inputpassword').setAttribute('type','password');


    }
    
    
    const handleMouseOverEye =(e) =>{
      document.getElementById('eye').setAttribute('color','black');
          }

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
  // e.preventDefault();
  i18n.changeLanguage('fa_IR');
  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
  document.body.setAttribute('lang', 'fa');
}
const  handelUS = (e) => {
  // e.preventDefault();
  i18n.changeLanguage('en_US');
  document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
  document.body.setAttribute('lang', 'en');
}
const handleClickGlobal = (e) => {

  document.getElementById('GlobalBtn1').style.display="none";
  document.getElementById('GlobalBtn2').style.display="flex";
}
const handleMouseLeaveGlobal = (e) => {
document.getElementById('GlobalBtn1').style.display="flex";
document.getElementById('GlobalBtn2').style.display="none";

}
const handlePassOver = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="#313131";
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
}

const handlePassOut = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="white";
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
  
}


const handlePassFocus = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="#313131";
    setPassFoucus(!passFoucus);
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
}

// const handlePassFocusM = (e) => {
//   if(values.password=="")
//   {
//     document.getElementById('MypasswordplaceholderM').style.color="#313131";
//     setPassFoucus(!passFoucus);
//   }
//   else{
//     document.getElementById('MypasswordplaceholderM').style.color="transparent";
//   }
// }

// const handleEyeM =(e) =>{
//   document.getElementById('eyeM').style.display='none';
//   document.getElementById('eyeslashM').style.display='flex';
//   document.getElementById('inputpasswordM').setAttribute('type','text');
// }

// const handleEyeSlashM =(e) =>{
//  document.getElementById('eyeslashM').style.display='none';
//  document.getElementById('eyeM').style.display='flex';
//  document.getElementById('inputpasswordM').setAttribute('type','password');

// }

if(localStorage.getItem('langL')){

}
else
{
  localStorage.setItem('langL' , "English");
  localStorage.setItem('langV' , 1);
}

const [selectValue , setSelectValue] = useState({ label: localStorage.getItem('langL'), value: localStorage.getItem('langV') });
useEffect(() => {
  if(values.SelectLanguage != 2)
  {
    handelUS();
    localStorage.setItem('langV' , 1);
    localStorage.setItem('langL' , "English");
  }
   
  else
  {
    handelIR()
    localStorage.setItem('langV' , 2);
    localStorage.setItem('langL' , "Persian");
  }
    
}, [values.SelectLanguage]);
//***************************************/
    return (
      <><div className="pagePic"></div>
      <div className="loginMainRow">
        <div className="rectangle sectionsLogin col-xl-8 col-12">
            <div className="section leftSection col-xl-6 col-12">
              <div className="LogoMobile col-12">
                <img src={Logo} alt="" />
              </div>
              <div className="leftSectionTopGrup forgpasss col-xl-11 col-lg-7 col-md-9 col-12">
                <Link className="font-face-gm backtohome" to='/'>
                  <Home2 color="white" size="24" />
                </Link>
                <br />
                <h4 className="myBoldFont">{t('Reset Your Password')}</h4>
                <p className="LoginSomeText">{t('Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.')}</p>
                <form onSubmit={handleSubmit} noValidate>
                        <div className="input-placeholder" onMouseOver={handlePassOver} onMouseLeave={handlePassOut}>
                        <input 
                          value = {values.password}
                          type="password"
                          className="form-control"
                          id="inputpassword"
                          onBlur={handleinputpasswordBlur}
                          onChange={handleChange('password')}
                          onFocus={handlePassFocus}
                          required 
                          />
                          
                        {!passFoucus &&<><div id="Mypasswordplaceholder" className="placeholder">
                      {t('Password')}<span id="MypasswordSapn"></span>
                    </div><Lock1 size="18" color="#ffffff" /><label id="errorLabelpassword"></label><Eye  id="eye" size="18" color="#ffffff" /><EyeSlash  id="eyeslash" size="18" color="#ffffff" /></>}
                    {passFoucus &&<><div id="Mypasswordplaceholder" className="placeholder">
                      {t('type your password here...')}<span id="MypasswordSapn"></span>
                    </div><Lock1 size="18" color="#ffffff" /><label id="errorLabelpassword"></label><Eye  id="eye" size="18" color="#ffffff" /><EyeSlash  id="eyeslash" size="18" color="#ffffff" /></>}
                    </div>

                    <div className="input-placeholder" onMouseOver={handlePassOver} onMouseLeave={handlePassOut}>
                        <input 
                          value = {values.Repassword}
                          type="password"
                          className="form-control"
                          id="inputpassword2"
                          // onBlur={handleinputpasswordBlur}
                          onChange={handleChange('Repassword')}
                          onFocus={handlePassFocus}
                          required 
                          placeholder={t('type your password here...')}
                          />
                          
                      
                    {<>
                    <Lock1 size="18" color="#ffffff" /><label id="errorLabelpassword">{helppassword}</label><Eye   id="eye" size="18" color="#ffffff" /><EyeSlash  id="eyeslash" size="18" color="#ffffff" /></>}
                    </div>

                    {!ispending && !formError &&<button className="btn btn-dark login-btn ">{t('Submit')}</button>}
                  {!ispending && formError &&<button disabled className="btn btn-dark login-btn ">{t('Submit')}</button>}
                    
                  
                </form>
              </div>
            </div>
            <div className="section rightSection col-xl-6">
              <img src={Logingroup} alt="" />
            </div>
            <div className="rectangle_bottom col-12">
              <div className="CreatableSelectLogin">
              <div className="inputAndlabel">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='6'>{t('French')}</option >
                  <option  value='21'>{t('Chinese')}</option >
                  <option  value='5'>{t('Spanish')}</option >
                  <option  value='22'>{t('German')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicant’s Skin Color')}</label> */}
            </div>
                </div>
                <div className="about-us">
                
                    {/* <div onMouseEnter={handleClickGlobal}  id="GlobalBtn1" className="LangBoxPrim">
                    { <Global color="white" size="24"/>}
                    </div> */}
                    <div className="LangBox" id="GlobalBtn2" onMouseLeave={handleMouseLeaveGlobal}>
                            <a href="" onClick={handelIR} id="fa" className="handleIR">Fa</a> <hr />
                            <a href="" onClick={handelUS} id="en" className="handleUS">En</a><Global variant="Bold" color="#313131" size="24"/></div>
                  <div className="spanAbout">
                    <p>
                    <span>|</span> <Link to="/services"> <span>{t('Services')}</span> <span>|</span> <span>{t('About')}</span> <span>|</span>
                    <span> {t('Collaborations')}</span> <span>|</span> <span>{t('Terms')}</span> <span>|</span>
                    <span> {t('Help')}</span> <span>|</span> <span>{t('Contact Us')}</span> </Link>
                    </p>
                  </div>
                  
              
                </div>
            </div>
        </div>
        <div className="footerDasilav col-12">
          <div className="footer-copy">
              {/* <hr width="2%" size="3px" className="mx-2" /> */}
              Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              {/* <hr width="2%" className="mx-2" /> */}
              
            </div>
            <div className="footer-copy2">
              <br />
              Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
         
        </div>
          </div>
         </>


// </div><div className="row col-12 loginMainRow">
//       <div className="rectangle col-8">
//         <div className="sections">
//           <div className="section col-6 leftSection">
//             <Link className="font-face-gm backtohome" to='/'> {t('< Back to Home page')}</Link>
//             <br />
//             <h4 className="">{t('Reset Your Password')}</h4>
//             <hr width="30px" />
//             <p className="" width="80%">{t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.')}</p>
//             <form onSubmit={handleSubmit}>
//               <div className="input-placeholder">
//                     <input 
//                       value = {values.Email}
//                       type="email"
//                       className="form-control"
//                       id="inputEmail"
//                       onBlur={handleinputEmailBlur}
//                       onChange={handleChange('Email')}
//                       required 
//                       />
//                     <div id="MyEmailplaceholder" className="placeholder">
//                     {t('Email')}<span id="MyEmailSapn"></span>
//                     </div>
//                     <Sms size="18"  color="#ffffff" />
//                     <label id="errorLabelEmail">{helpEmail}</label>
//                 </div>
//               <br />
//               {<Link to="/resetpass2" className="btn btn-dark login-btn">{t('Send Link')}</Link>}
            
//             </form>
      
  
    );
}
 
export default ResetPassword;