import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentFamilyInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      

      CustomerFatherFirstName: values.FatherName,
      CustomerFatherSurname: values.FatherFamily,
      CustomerFatherJob: values.FatherJob,
      CustomerFatherBirthDay: FatherBirthDay.toString(),
      CustomerFathersResidenceAddress: values.FathersResidenceAddress,
      CustomerFatherPostalCode: values.FatherPostalCode,
      CustomerFatherPhoneNumber: values.FatherPhoneCall,
      CustomerFatherEducation: values.FatherEducation,
      CustomerFatherFinancialAbility: values.FatherFinancialAbility,
      CustomerFatherDateOfDivorce: FatherDateofDivorce.toString(),
      CustomerFatherDateOfDeath: FatherDateofDeath.toString(),
      CustomerFathersForeignTripNumberOne: values.FathersForeignTrip1,
      CustomerFathersForeignTripNumberTwo: values.FathersForeignTrip2,
      CustomerFathersForeignTripNumberThree: values.FathersForeignTrip3,
      CustomerFathersForeignTripNumberFour: values.FathersForeignTrip4,
      CustomerFathersForeignTripNumberFive: values.FathersForeignTrip5,
      CustomerFathersForeignTripNumberSix: values.FathersForeignTrip6,
      CustomerFathersForeignTripNumberSeven: values.FathersForeignTrip7,
      CustomerFathersForeignTripNumberEight: values.FathersForeignTrip8,
      CustomerFathersForeignTripNumberNine: values.FathersForeignTrip9,
      
      CustomerMotherFirstName: values.MotherName,
      CustomerMotherSurname: values.MotherFamily,
      CustomerMotherJob: values.MotherJob,
      CustomerMotherBirthDay: MotherBirthDay.toString(),
      CustomerMotherResidenceAddress: values.MothersResidenceAddress,
      CustomerMotherPostalCode: values.MotherPostalCode,
      CustomerMotherPhoneNumber: values.MotherPhoneCall,
      CustomerMotherEducation: values.MotherEducation,
      CustomerMotherFinancialAbility: values.MotherFinancialAbility,
      CustomerMotherDateOfDivorce: MotherDateofDivorce.toString(),
      CustomerMotherDateOfDeath: MotherDateofDeath.toString(),
      CustomerMothersForeignTripNumberOne: values.MothersForeignTrip1,
      CustomerMothersForeignTripNumberTwo: values.MothersForeignTrip2,
      CustomerMothersForeignTripNumberThree: values.MothersForeignTrip3,
      CustomerMothersForeignTripNumberFour: values.MothersForeignTrip4,
      CustomerMothersForeignTripNumberFive: values.MothersForeignTrip5,
      CustomerMothersForeignTripNumberSix: values.MothersForeignTrip6,
      CustomerMothersForeignTripNumberSeven: values.MothersForeignTrip7,
      CustomerMothersForeignTripNumberEight: values.MothersForeignTrip8,
      CustomerMothersForeignTripNumberNine: values.MothersForeignTrip9,

      CustomerFirstBrotherFirstName: values.Brother1Name,
      CustomerFirstBrotherSurname: values.Brother1Family,
      CustomerFirstBrotherJob: values.Brother1Job,
      CustomerFirstBrotherBirthDay: Brother1BirthDay.toString(),
      CustomerFirstBrotherResidenceAddress: values.Brother1sResidenceAddress,
      CustomerFirstBrotherPostalCode: values.Brother1PostalCode,
      CustomerFirstBrotherPhoneNumber: values.Brother1PhoneCall,
      CustomerFirstBrotherEducation: values.Brother1Education,
      CustomerFirstBrotherFinancialAbility: values.Brother1FinancialAbility,
      CustomerFirstBrotherDateOfDivorce: Brother1DateofDivorce.toString(),
      CustomerFirstBrotherDateOfDeath: Brother1DateofDeath.toString(),
      CustomerFirstBrothersForeignTripNumberOne: values.Brother1sForeignTrip1,
      CustomerFirstBrothersForeignTripNumberTwo: values.Brother1sForeignTrip2,
      CustomerFirstBrothersForeignTripNumberThree: values.Brother1sForeignTrip3,
      CustomerFirstBrothersForeignTripNumberFour: values.Brother1sForeignTrip4,
      CustomerFirstBrothersForeignTripNumberFive: values.Brother1sForeignTrip5,
      CustomerFirstBrothersForeignTripNumberSix: values.Brother1sForeignTrip6,
      CustomerFirstBrothersForeignTripNumberSeven: values.Brother1sForeignTrip7,
      CustomerFirstBrothersForeignTripNumberEight: values.Brother1sForeignTrip8,
      CustomerFirstBrothersForeignTripNumberNine: values.Brother1sForeignTrip9,

      CustomerSecondBrotherFirstName: values.Brother2Name,
      CustomerSecondBrotherSurname: values.Brother2Family,
      CustomerSecondBrotherJob: values.Brother2Job,
      CustomerSecondBrotherBirthDay: Brother2BirthDay.toString(),
      CustomerSecondBrotherResidenceAddress: values.Brother2sResidenceAddress,
      CustomerSecondBrotherPostalCode: values.Brother2PostalCode,
      CustomerSecondBrotherPhoneNumber: values.Brother2PhoneCall,
      CustomerSecondBrotherEducation: values.Brother2Education,
      CustomerSecondBrotherFinancialAbility: values.Brother2FinancialAbility,
      CustomerSecondBrotherDateOfDivorce: Brother2DateofDivorce.toString(),
      CustomerSecondBrotherDateOfDeath: Brother2DateofDeath.toString(),
      CustomerSecondBrothersForeignTripNumberOne: values.Brother2sForeignTrip1,
      CustomerSecondBrothersForeignTripNumberTwo: values.Brother2sForeignTrip2,
      CustomerSecondBrothersForeignTripNumberThree: values.Brother2sForeignTrip3,
      CustomerSecondBrothersForeignTripNumberFour: values.Brother2sForeignTrip4,
      CustomerSecondBrothersForeignTripNumberFive: values.Brother2sForeignTrip5,
      CustomerSecondBrothersForeignTripNumberSix: values.Brother2sForeignTrip6,
      CustomerSecondBrothersForeignTripNumberSeven: values.Brother2sForeignTrip7,
      CustomerSecondBrothersForeignTripNumberEight: values.Brother2sForeignTrip8,
      CustomerSecondBrothersForeignTripNumberNine: values.Brother2sForeignTrip9,

      CustomerThirdBrotherFirstName: values.Brother3Name,
      CustomerThirdBrotherSurname: values.Brother3Family,
      CustomerThirdBrotherJob: values.Brother3Job,
      CustomerThirdBrotherBirthDay: Brother3BirthDay.toString(),
      CustomerThirdBrotherResidenceAddress: values.Brother3sResidenceAddress,
      CustomerThirdBrotherPostalCode: values.Brother3PostalCode,
      CustomerThirdBrotherPhoneNumber: values.Brother3PhoneCall,
      CustomerThirdBrotherEducation: values.Brother3Education,
      CustomerThirdBrotherFinancialAbility: values.Brother3FinancialAbility,
      CustomerThirdBrotherDateOfDivorce: Brother3DateofDivorce.toString(),
      CustomerThirdBrotherDateOfDeath: Brother3DateofDeath.toString(),
      CustomerThirdBrothersForeignTripNumberOne: values.Brother3sForeignTrip1,
      CustomerThirdBrothersForeignTripNumberTwo: values.Brother3sForeignTrip2,
      CustomerThirdBrothersForeignTripNumberThree: values.Brother3sForeignTrip3,
      CustomerThirdBrothersForeignTripNumberFour: values.Brother3sForeignTrip4,
      CustomerThirdBrothersForeignTripNumberFive: values.Brother3sForeignTrip5,
      CustomerThirdBrothersForeignTripNumberSix: values.Brother3sForeignTrip6,
      CustomerThirdBrothersForeignTripNumberSeven: values.Brother3sForeignTrip7,
      CustomerThirdBrothersForeignTripNumberEight: values.Brother3sForeignTrip8,
      CustomerThirdBrothersForeignTripNumberNine: values.Brother3sForeignTrip9,

      CustomerFirstSisterFirstName: values.Sister1Name,
      CustomerFirstSisterSurname: values.Sister1Family,
      CustomerFirstSisterJob: values.Sister1Job,
      CustomerFirstSisterBirthDay: Sister1BirthDay.toString(),
      CustomerFirstSisterResidenceAddress: values.Sister1sResidenceAddress,
      CustomerFirstSisterPostalCode: values.Sister1PostalCode,
      CustomerFirstSisterPhoneNumber: values.Sister1PhoneCall,
      CustomerFirstSisterEducation: values.Sister1Education,
      CustomerFirstSisterFinancialAbility: values.Sister1FinancialAbility,
      CustomerFirstSisterDateOfDivorce: Sister1DateofDivorce.toString(),
      CustomerFirstSisterDateOfDeath: Sister1DateofDeath.toString(),
      CustomerFirstSistersForeignTripNumberOne: values.Sister1sForeignTrip1,
      CustomerFirstSistersForeignTripNumberTwo: values.Sister1sForeignTrip2,
      CustomerFirstSistersForeignTripNumberThree: values.Sister1sForeignTrip3,
      CustomerFirstSistersForeignTripNumberFour: values.Sister1sForeignTrip4,
      CustomerFirstSistersForeignTripNumberFive: values.Sister1sForeignTrip5,
      CustomerFirstSistersForeignTripNumberSix: values.Sister1sForeignTrip6,
      CustomerFirstSistersForeignTripNumberSeven: values.Sister1sForeignTrip7,
      CustomerFirstSistersForeignTripNumberEight: values.Sister1sForeignTrip8,
      CustomerFirstSistersForeignTripNumberNine: values.Sister1sForeignTrip9,

      CustomerSecondSisterSecondName: values.Sister2Name,
      CustomerSecondSisterSurname: values.Sister2Family,
      CustomerSecondSisterJob: values.Sister2Job,
      CustomerSecondSisterBirthDay: Sister2BirthDay.toString(),
      CustomerSecondSisterResidenceAddress: values.Sister2sResidenceAddress,
      CustomerSecondSisterPostalCode: values.Sister2PostalCode,
      CustomerSecondSisterPhoneNumber: values.Sister2PhoneCall,
      CustomerSecondSisterEducation: values.Sister2Education,
      CustomerSecondSisterFinancialAbility: values.Sister2FinancialAbility,
      CustomerSecondSisterDateOfDivorce: Sister2DateofDivorce.toString(), 
      CustomerSecondSisterDateOfDeath: Sister2DateofDeath.toString(),
      CustomerSecondSistersForeignTripNumberOne: values.Sister2sForeignTrip1,
      CustomerSecondSistersForeignTripNumberTwo: values.Sister2sForeignTrip2,
      CustomerSecondSistersForeignTripNumberThree: values.Sister2sForeignTrip3,
      CustomerSecondSistersForeignTripNumberFour: values.Sister2sForeignTrip4,
      CustomerSecondSistersForeignTripNumberFive: values.Sister2sForeignTrip5,
      CustomerSecondSistersForeignTripNumberSix: values.Sister2sForeignTrip6,
      CustomerSecondSistersForeignTripNumberSeven: values.Sister2sForeignTrip7,
      CustomerSecondSistersForeignTripNumberEight: values.Sister2sForeignTrip8,
      CustomerSecondSistersForeignTripNumberNine: values.Sister2sForeignTrip9,

      CustomerThirdSisterThirdName: values.Sister3Name,
      CustomerThirdSisterSurname: values.Sister3Family,
      CustomerThirdSisterJob: values.Sister3Job,
      CustomerThirdSisterBirthDay: Sister3BirthDay.toString(),
      CustomerThirdSisterResidenceAddress: values.Sister3sResidenceAddress,
      CustomerThirdSisterPostalCode: values.Sister3PostalCode,
      CustomerThirdSisterPhoneNumber: values.Sister3PhoneCall,
      CustomerThirdSisterEducation: values.Sister3Education,
      CustomerThirdSisterFinancialAbility: values.Sister3FinancialAbility,
      CustomerThirdSisterDateOfDivorce: Sister3DateofDivorce.toString(),
      CustomerThirdSisterDateOfDeath: Sister3DateofDeath.toString(),
      CustomerThirdSistersForeignTripNumberOne: values.Sister3sForeignTrip1,
      CustomerThirdSistersForeignTripNumberTwo: values.Sister3sForeignTrip2,
      CustomerThirdSistersForeignTripNumberThree: values.Sister3sForeignTrip3,
      CustomerThirdSistersForeignTripNumberFour: values.Sister3sForeignTrip4,
      CustomerThirdSistersForeignTripNumberFive: values.Sister3sForeignTrip5,
      CustomerThirdSistersForeignTripNumberSix: values.Sister3sForeignTrip6,
      CustomerThirdSistersForeignTripNumberSeven: values.Sister3sForeignTrip7,
      CustomerThirdSistersForeignTripNumberEight: values.Sister3sForeignTrip8,
      CustomerThirdSistersForeignTripNumberNine: values.Sister3sForeignTrip9,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentFamilyInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerFatherFirstName: values.FatherName,
           CustomerFatherSurname: values.FatherFamily,
           CustomerFatherJob: values.FatherJob,
           CustomerFatherBirthDay: FatherBirthDay.toString(),
           CustomerFathersResidenceAddress: values.FathersResidenceAddress,
           CustomerFatherPostalCode: values.FatherPostalCode,
           CustomerFatherPhoneNumber: values.FatherPhoneCall,
           CustomerFatherEducation: values.FatherEducation,
           CustomerFatherFinancialAbility: values.FatherFinancialAbility,
           CustomerFatherDateOfDivorce: FatherDateofDivorce.toString(),
           CustomerFatherDateOfDeath: FatherDateofDeath.toString(),
           CustomerFathersForeignTripNumberOne: values.FathersForeignTrip1,
           CustomerFathersForeignTripNumberTwo: values.FathersForeignTrip2,
           CustomerFathersForeignTripNumberThree: values.FathersForeignTrip3,
           CustomerFathersForeignTripNumberFour: values.FathersForeignTrip4,
           CustomerFathersForeignTripNumberFive: values.FathersForeignTrip5,
           CustomerFathersForeignTripNumberSix: values.FathersForeignTrip6,
           CustomerFathersForeignTripNumberSeven: values.FathersForeignTrip7,
           CustomerFathersForeignTripNumberEight: values.FathersForeignTrip8,
           CustomerFathersForeignTripNumberNine: values.FathersForeignTrip9,
           
           CustomerMotherFirstName: values.MotherName,
           CustomerMotherSurname: values.MotherFamily,
           CustomerMotherJob: values.MotherJob,
           CustomerMotherBirthDay: MotherBirthDay.toString(),
           CustomerMotherResidenceAddress: values.MothersResidenceAddress,
           CustomerMotherPostalCode: values.MotherPostalCode,
           CustomerMotherPhoneNumber: values.MotherPhoneCall,
           CustomerMotherEducation: values.MotherEducation,
           CustomerMotherFinancialAbility: values.MotherFinancialAbility,
           CustomerMotherDateOfDivorce: MotherDateofDivorce.toString(),
           CustomerMotherDateOfDeath: MotherDateofDeath.toString(),
           CustomerMothersForeignTripNumberOne: values.MothersForeignTrip1,
           CustomerMothersForeignTripNumberTwo: values.MothersForeignTrip2,
           CustomerMothersForeignTripNumberThree: values.MothersForeignTrip3,
           CustomerMothersForeignTripNumberFour: values.MothersForeignTrip4,
           CustomerMothersForeignTripNumberFive: values.MothersForeignTrip5,
           CustomerMothersForeignTripNumberSix: values.MothersForeignTrip6,
           CustomerMothersForeignTripNumberSeven: values.MothersForeignTrip7,
           CustomerMothersForeignTripNumberEight: values.MothersForeignTrip8,
           CustomerMothersForeignTripNumberNine: values.MothersForeignTrip9,
     
           CustomerFirstBrotherFirstName: values.Brother1Name,
           CustomerFirstBrotherSurname: values.Brother1Family,
           CustomerFirstBrotherJob: values.Brother1Job,
           CustomerFirstBrotherBirthDay: Brother1BirthDay.toString(),
           CustomerFirstBrotherResidenceAddress: values.Brother1sResidenceAddress,
           CustomerFirstBrotherPostalCode: values.Brother1PostalCode,
           CustomerFirstBrotherPhoneNumber: values.Brother1PhoneCall,
           CustomerFirstBrotherEducation: values.Brother1Education,
           CustomerFirstBrotherFinancialAbility: values.Brother1FinancialAbility,
           CustomerFirstBrotherDateOfDivorce: Brother1DateofDivorce.toString(),
           CustomerFirstBrotherDateOfDeath: Brother1DateofDeath.toString(),
           CustomerFirstBrothersForeignTripNumberOne: values.Brother1sForeignTrip1,
           CustomerFirstBrothersForeignTripNumberTwo: values.Brother1sForeignTrip2,
           CustomerFirstBrothersForeignTripNumberThree: values.Brother1sForeignTrip3,
           CustomerFirstBrothersForeignTripNumberFour: values.Brother1sForeignTrip4,
           CustomerFirstBrothersForeignTripNumberFive: values.Brother1sForeignTrip5,
           CustomerFirstBrothersForeignTripNumberSix: values.Brother1sForeignTrip6,
           CustomerFirstBrothersForeignTripNumberSeven: values.Brother1sForeignTrip7,
           CustomerFirstBrothersForeignTripNumberEight: values.Brother1sForeignTrip8,
           CustomerFirstBrothersForeignTripNumberNine: values.Brother1sForeignTrip9,
     
           CustomerSecondBrotherFirstName: values.Brother2Name,
           CustomerSecondBrotherSurname: values.Brother2Family,
           CustomerSecondBrotherJob: values.Brother2Job,
           CustomerSecondBrotherBirthDay: Brother2BirthDay.toString(),
           CustomerSecondBrotherResidenceAddress: values.Brother2sResidenceAddress,
           CustomerSecondBrotherPostalCode: values.Brother2PostalCode,
           CustomerSecondBrotherPhoneNumber: values.Brother2PhoneCall,
           CustomerSecondBrotherEducation: values.Brother2Education,
           CustomerSecondBrotherFinancialAbility: values.Brother2FinancialAbility,
           CustomerSecondBrotherDateOfDivorce: Brother2DateofDivorce.toString(),
           CustomerSecondBrotherDateOfDeath: Brother2DateofDeath.toString(),
           CustomerSecondBrothersForeignTripNumberOne: values.Brother2sForeignTrip1,
           CustomerSecondBrothersForeignTripNumberTwo: values.Brother2sForeignTrip2,
           CustomerSecondBrothersForeignTripNumberThree: values.Brother2sForeignTrip3,
           CustomerSecondBrothersForeignTripNumberFour: values.Brother2sForeignTrip4,
           CustomerSecondBrothersForeignTripNumberFive: values.Brother2sForeignTrip5,
           CustomerSecondBrothersForeignTripNumberSix: values.Brother2sForeignTrip6,
           CustomerSecondBrothersForeignTripNumberSeven: values.Brother2sForeignTrip7,
           CustomerSecondBrothersForeignTripNumberEight: values.Brother2sForeignTrip8,
           CustomerSecondBrothersForeignTripNumberNine: values.Brother2sForeignTrip9,
     
           CustomerThirdBrotherFirstName: values.Brother3Name,
           CustomerThirdBrotherSurname: values.Brother3Family,
           CustomerThirdBrotherJob: values.Brother3Job,
           CustomerThirdBrotherBirthDay: Brother3BirthDay.toString(),
           CustomerThirdBrotherResidenceAddress: values.Brother3sResidenceAddress,
           CustomerThirdBrotherPostalCode: values.Brother3PostalCode,
           CustomerThirdBrotherPhoneNumber: values.Brother3PhoneCall,
           CustomerThirdBrotherEducation: values.Brother3Education,
           CustomerThirdBrotherFinancialAbility: values.Brother3FinancialAbility,
           CustomerThirdBrotherDateOfDivorce: Brother3DateofDivorce.toString(),
           CustomerThirdBrotherDateOfDeath: Brother3DateofDeath.toString(),
           CustomerThirdBrothersForeignTripNumberOne: values.Brother3sForeignTrip1,
           CustomerThirdBrothersForeignTripNumberTwo: values.Brother3sForeignTrip2,
           CustomerThirdBrothersForeignTripNumberThree: values.Brother3sForeignTrip3,
           CustomerThirdBrothersForeignTripNumberFour: values.Brother3sForeignTrip4,
           CustomerThirdBrothersForeignTripNumberFive: values.Brother3sForeignTrip5,
           CustomerThirdBrothersForeignTripNumberSix: values.Brother3sForeignTrip6,
           CustomerThirdBrothersForeignTripNumberSeven: values.Brother3sForeignTrip7,
           CustomerThirdBrothersForeignTripNumberEight: values.Brother3sForeignTrip8,
           CustomerThirdBrothersForeignTripNumberNine: values.Brother3sForeignTrip9,
     
           CustomerFirstSisterFirstName: values.Sister1Name,
           CustomerFirstSisterSurname: values.Sister1Family,
           CustomerFirstSisterJob: values.Sister1Job,
           CustomerFirstSisterBirthDay: Sister1BirthDay.toString(),
           CustomerFirstSisterResidenceAddress: values.Sister1sResidenceAddress,
           CustomerFirstSisterPostalCode: values.Sister1PostalCode,
           CustomerFirstSisterPhoneNumber: values.Sister1PhoneCall,
           CustomerFirstSisterEducation: values.Sister1Education,
           CustomerFirstSisterFinancialAbility: values.Sister1FinancialAbility,
           CustomerFirstSisterDateOfDivorce: Sister1DateofDivorce.toString(),
           CustomerFirstSisterDateOfDeath: Sister1DateofDeath.toString(),
           CustomerFirstSistersForeignTripNumberOne: values.Sister1sForeignTrip1,
           CustomerFirstSistersForeignTripNumberTwo: values.Sister1sForeignTrip2,
           CustomerFirstSistersForeignTripNumberThree: values.Sister1sForeignTrip3,
           CustomerFirstSistersForeignTripNumberFour: values.Sister1sForeignTrip4,
           CustomerFirstSistersForeignTripNumberFive: values.Sister1sForeignTrip5,
           CustomerFirstSistersForeignTripNumberSix: values.Sister1sForeignTrip6,
           CustomerFirstSistersForeignTripNumberSeven: values.Sister1sForeignTrip7,
           CustomerFirstSistersForeignTripNumberEight: values.Sister1sForeignTrip8,
           CustomerFirstSistersForeignTripNumberNine: values.Sister1sForeignTrip9,
     
           CustomerSecondSisterSecondName: values.Sister2Name,
           CustomerSecondSisterSurname: values.Sister2Family,
           CustomerSecondSisterJob: values.Sister2Job,
           CustomerSecondSisterBirthDay: Sister2BirthDay.toString(),
           CustomerSecondSisterResidenceAddress: values.Sister2sResidenceAddress,
           CustomerSecondSisterPostalCode: values.Sister2PostalCode,
           CustomerSecondSisterPhoneNumber: values.Sister2PhoneCall,
           CustomerSecondSisterEducation: values.Sister2Education,
           CustomerSecondSisterFinancialAbility: values.Sister2FinancialAbility,
           CustomerSecondSisterDateOfDivorce: Sister2DateofDivorce.toString(), 
           CustomerSecondSisterDateOfDeath: Sister2DateofDeath.toString(),
           CustomerSecondSistersForeignTripNumberOne: values.Sister2sForeignTrip1,
           CustomerSecondSistersForeignTripNumberTwo: values.Sister2sForeignTrip2,
           CustomerSecondSistersForeignTripNumberThree: values.Sister2sForeignTrip3,
           CustomerSecondSistersForeignTripNumberFour: values.Sister2sForeignTrip4,
           CustomerSecondSistersForeignTripNumberFive: values.Sister2sForeignTrip5,
           CustomerSecondSistersForeignTripNumberSix: values.Sister2sForeignTrip6,
           CustomerSecondSistersForeignTripNumberSeven: values.Sister2sForeignTrip7,
           CustomerSecondSistersForeignTripNumberEight: values.Sister2sForeignTrip8,
           CustomerSecondSistersForeignTripNumberNine: values.Sister2sForeignTrip9,
     
           CustomerThirdSisterThirdName: values.Sister3Name,
           CustomerThirdSisterSurname: values.Sister3Family,
           CustomerThirdSisterJob: values.Sister3Job,
           CustomerThirdSisterBirthDay: Sister3BirthDay.toString(),
           CustomerThirdSisterResidenceAddress: values.Sister3sResidenceAddress,
           CustomerThirdSisterPostalCode: values.Sister3PostalCode,
           CustomerThirdSisterPhoneNumber: values.Sister3PhoneCall,
           CustomerThirdSisterEducation: values.Sister3Education,
           CustomerThirdSisterFinancialAbility: values.Sister3FinancialAbility,
           CustomerThirdSisterDateOfDivorce: Sister3DateofDivorce.toString(),
           CustomerThirdSisterDateOfDeath: Sister3DateofDeath.toString(),
           CustomerThirdSistersForeignTripNumberOne: values.Sister3sForeignTrip1,
           CustomerThirdSistersForeignTripNumberTwo: values.Sister3sForeignTrip2,
           CustomerThirdSistersForeignTripNumberThree: values.Sister3sForeignTrip3,
           CustomerThirdSistersForeignTripNumberFour: values.Sister3sForeignTrip4,
           CustomerThirdSistersForeignTripNumberFive: values.Sister3sForeignTrip5,
           CustomerThirdSistersForeignTripNumberSix: values.Sister3sForeignTrip6,
           CustomerThirdSistersForeignTripNumberSeven: values.Sister3sForeignTrip7,
           CustomerThirdSistersForeignTripNumberEight: values.Sister3sForeignTrip8,
           CustomerThirdSistersForeignTripNumberNine: values.Sister3sForeignTrip9,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerFatherFirstName',response.customerFatherFirstName);
        localStorage.setItem('customerFatherSurname',response.customerFatherSurname);
        localStorage.setItem('customerFatherJob',response.customerFatherJob);
        localStorage.setItem('customerFatherBirthDay',response.customerFatherBirthDay);
        localStorage.setItem('customerFathersResidenceAddress',response.customerFathersResidenceAddress);
        localStorage.setItem('customerFatherPostalCode',response.customerFatherPostalCode);
        localStorage.setItem('customerFatherPhoneNumber',response.customerFatherPhoneNumber);
        localStorage.setItem('customerFatherEducation',response.customerFatherEducation);
        localStorage.setItem('customerFatherFinancialAbility',response.customerFatherFinancialAbility);
        localStorage.setItem('customerFatherDateOfDivorce',response.customerFatherDateOfDivorce);
        localStorage.setItem('customerFatherDateOfDeath',response.customerFatherDateOfDeath);
        localStorage.setItem('customerFathersForeignTripNumberOne',response.customerFathersForeignTripNumberOne);
        localStorage.setItem('customerFathersForeignTripNumberTwo',response.customerFathersForeignTripNumberTwo);
        localStorage.setItem('customerFathersForeignTripNumberThree',response.customerFathersForeignTripNumberThree);
        localStorage.setItem('customerFathersForeignTripNumberFour',response.customerFathersForeignTripNumberFour);
        localStorage.setItem('customerFathersForeignTripNumberFive',response.customerFathersForeignTripNumberFive);
        localStorage.setItem('customerFathersForeignTripNumberSix',response.customerFathersForeignTripNumberSix);
        localStorage.setItem('customerFathersForeignTripNumberSeven',response.customerFathersForeignTripNumberSeven);
        localStorage.setItem('customerFathersForeignTripNumberEight',response.customerFathersForeignTripNumberEight);
        localStorage.setItem('customerFathersForeignTripNumberNine',response.customerFathersForeignTripNumberNine);
        localStorage.setItem('customerMotherFirstName',response.customerMotherFirstName);
        localStorage.setItem('customerMotherSurname',response.customerMotherSurname);
        localStorage.setItem('customerMotherJob',response.customerMotherJob);
        localStorage.setItem('customerMotherBirthDay',response.customerMotherBirthDay);
        localStorage.setItem('customerMotherResidenceAddress',response.customerMotherResidenceAddress);
        localStorage.setItem('customerMotherPostalCode',response.customerMotherPostalCode);
        localStorage.setItem('customerMotherPhoneNumber',response.customerMotherPhoneNumber);
        localStorage.setItem('customerMotherEducation',response.customerMotherEducation);
        localStorage.setItem('customerMotherFinancialAbility',response.customerMotherFinancialAbility);
        localStorage.setItem('customerMotherDateOfDivorce',response.customerMotherDateOfDivorce);
        localStorage.setItem('customerMotherDateOfDeath',response.customerMotherDateOfDeath);
        localStorage.setItem('customerMothersForeignTripNumberOne',response.customerMothersForeignTripNumberOne);
        localStorage.setItem('customerMothersForeignTripNumberTwo',response.customerMothersForeignTripNumberTwo);
        localStorage.setItem('customerMothersForeignTripNumberThree',response.customerMothersForeignTripNumberThree);
        localStorage.setItem('customerMothersForeignTripNumberFour',response.customerMothersForeignTripNumberFour);
        localStorage.setItem('customerMothersForeignTripNumberFive',response.customerMothersForeignTripNumberFive);
        localStorage.setItem('customerMothersForeignTripNumberSix',response.customerMothersForeignTripNumberSix);
        localStorage.setItem('customerMothersForeignTripNumberSeven',response.customerMothersForeignTripNumberSeven);
        localStorage.setItem('customerMothersForeignTripNumberEight',response.customerMothersForeignTripNumberEight);
        localStorage.setItem('customerMothersForeignTripNumberNine',response.customerMothersForeignTripNumberNine);
        localStorage.setItem('customerFirstBrotherFirstName',response.customerFirstBrotherFirstName);
        localStorage.setItem('customerFirstBrotherSurname',response.customerFirstBrotherSurname);
        localStorage.setItem('customerFirstBrotherJob',response.customerFirstBrotherJob);
        localStorage.setItem('customerFirstBrotherBirthDay',response.customerFirstBrotherBirthDay);
        localStorage.setItem('customerFirstBrotherResidenceAddress',response.customerFirstBrotherResidenceAddress);
        localStorage.setItem('customerFirstBrotherPostalCode',response.customerFirstBrotherPostalCode);
        localStorage.setItem('customerFirstBrotherPhoneNumber',response.customerFirstBrotherPhoneNumber);
        localStorage.setItem('customerFirstBrotherEducation',response.customerFirstBrotherEducation);
        localStorage.setItem('customerFirstBrotherFinancialAbility',response.customerFirstBrotherFinancialAbility);
        localStorage.setItem('customerFirstBrotherDateOfDivorce',response.customerFirstBrotherDateOfDivorce);
        localStorage.setItem('customerFirstBrotherDateOfDeath',response.customerFirstBrotherDateOfDeath);
        localStorage.setItem('customerFirstBrothersForeignTripNumberOne',response.customerFirstBrothersForeignTripNumberOne);
        localStorage.setItem('customerFirstBrothersForeignTripNumberTwo',response.customerFirstBrothersForeignTripNumberTwo);
        localStorage.setItem('customerFirstBrothersForeignTripNumberThree',response.customerFirstBrothersForeignTripNumberThree);
        localStorage.setItem('customerFirstBrothersForeignTripNumberFour',response.customerFirstBrothersForeignTripNumberFour);
        localStorage.setItem('customerFirstBrothersForeignTripNumberFive',response.customerFirstBrothersForeignTripNumberFive);
        localStorage.setItem('customerFirstBrothersForeignTripNumberSix',response.customerFirstBrothersForeignTripNumberSix);
        localStorage.setItem('customerFirstBrothersForeignTripNumberSeven',response.customerFirstBrothersForeignTripNumberSeven);
        localStorage.setItem('customerFirstBrothersForeignTripNumberEight',response.customerFirstBrothersForeignTripNumberEight);
        localStorage.setItem('customerFirstBrothersForeignTripNumberNine',response.customerFirstBrothersForeignTripNumberNine);
        localStorage.setItem('customerSecondBrotherFirstName',response.customerSecondBrotherFirstName);
        localStorage.setItem('customerSecondBrotherSurname',response.customerSecondBrotherSurname);
        localStorage.setItem('customerSecondBrotherJob',response.customerSecondBrotherJob);
        localStorage.setItem('customerSecondBrotherBirthDay',response.customerSecondBrotherBirthDay);
        localStorage.setItem('customerSecondBrotherResidenceAddress',response.customerSecondBrotherResidenceAddress);
        localStorage.setItem('customerSecondBrotherPostalCode',response.customerSecondBrotherPostalCode);
        localStorage.setItem('customerSecondBrotherPhoneNumber',response.customerSecondBrotherPhoneNumber);
        localStorage.setItem('customerSecondBrotherEducation',response.customerSecondBrotherEducation);
        localStorage.setItem('customerSecondBrotherFinancialAbility',response.customerSecondBrotherFinancialAbility);
        localStorage.setItem('customerSecondBrotherDateOfDivorce',response.customerSecondBrotherDateOfDivorce);
        localStorage.setItem('customerSecondBrotherDateOfDeath',response.customerSecondBrotherDateOfDeath);
        localStorage.setItem('customerSecondBrothersForeignTripNumberOne',response.customerSecondBrothersForeignTripNumberOne);
        localStorage.setItem('customerSecondBrothersForeignTripNumberTwo',response.customerSecondBrothersForeignTripNumberTwo);
        localStorage.setItem('customerSecondBrothersForeignTripNumberThree',response.customerSecondBrothersForeignTripNumberThree);
        localStorage.setItem('customerSecondBrothersForeignTripNumberFour',response.customerSecondBrothersForeignTripNumberFour);
        localStorage.setItem('customerSecondBrothersForeignTripNumberFive',response.customerSecondBrothersForeignTripNumberFive);
        localStorage.setItem('customerSecondBrothersForeignTripNumberSix',response.customerSecondBrothersForeignTripNumberSix);
        localStorage.setItem('customerSecondBrothersForeignTripNumberSeven',response.customerSecondBrothersForeignTripNumberSeven);
        localStorage.setItem('customerSecondBrothersForeignTripNumberEight',response.customerSecondBrothersForeignTripNumberEight);
        localStorage.setItem('customerSecondBrothersForeignTripNumberNine',response.customerSecondBrothersForeignTripNumberNine);
        localStorage.setItem('customerThirdBrotherFirstName',response.customerThirdBrotherFirstName);
        localStorage.setItem('customerThirdBrotherSurname',response.customerThirdBrotherSurname);
        localStorage.setItem('customerThirdBrotherJob',response.customerThirdBrotherJob);
        localStorage.setItem('customerThirdBrotherBirthDay',response.customerThirdBrotherBirthDay);
        localStorage.setItem('customerThirdBrotherResidenceAddress',response.customerThirdBrotherResidenceAddress);
        localStorage.setItem('customerThirdBrotherPostalCode',response.customerThirdBrotherPostalCode);
        localStorage.setItem('customerThirdBrotherPhoneNumber',response.customerThirdBrotherPhoneNumber);
        localStorage.setItem('customerThirdBrotherEducation',response.customerThirdBrotherEducation);
        localStorage.setItem('customerThirdBrotherFinancialAbility',response.customerThirdBrotherFinancialAbility);
        localStorage.setItem('customerThirdBrotherDateOfDivorce',response.customerThirdBrotherDateOfDivorce);
        localStorage.setItem('customerThirdBrotherDateOfDeath',response.customerThirdBrotherDateOfDeath);
        localStorage.setItem('customerThirdBrothersForeignTripNumberOne',response.customerThirdBrothersForeignTripNumberOne);
        localStorage.setItem('customerThirdBrothersForeignTripNumberTwo',response.customerThirdBrothersForeignTripNumberTwo);
        localStorage.setItem('customerThirdBrothersForeignTripNumberThree',response.customerThirdBrothersForeignTripNumberThree);
        localStorage.setItem('customerThirdBrothersForeignTripNumberFour',response.customerThirdBrothersForeignTripNumberFour);
        localStorage.setItem('customerThirdBrothersForeignTripNumberFive',response.customerThirdBrothersForeignTripNumberFive);
        localStorage.setItem('customerThirdBrothersForeignTripNumberSix',response.customerThirdBrothersForeignTripNumberSix);
        localStorage.setItem('customerThirdBrothersForeignTripNumberSeven',response.customerThirdBrothersForeignTripNumberSeven);
        localStorage.setItem('customerThirdBrothersForeignTripNumberEight',response.customerThirdBrothersForeignTripNumberEight);
        localStorage.setItem('customerThirdBrothersForeignTripNumberNine',response.customerThirdBrothersForeignTripNumberNine);
        localStorage.setItem('customerFirstSisterFirstName',response.customerFirstSisterFirstName);
        localStorage.setItem('customerFirstSisterSurname',response.customerFirstSisterSurname);
        localStorage.setItem('customerFirstSisterJob',response.customerFirstSisterJob);
        localStorage.setItem('customerFirstSisterBirthDay',response.customerFirstSisterBirthDay);
        localStorage.setItem('customerFirstSisterResidenceAddress',response.customerFirstSisterResidenceAddress);
        localStorage.setItem('customerFirstSisterPostalCode',response.customerFirstSisterPostalCode);
        localStorage.setItem('customerFirstSisterPhoneNumber',response.customerFirstSisterPhoneNumber);
        localStorage.setItem('customerFirstSisterEducation',response.customerFirstSisterEducation);
        localStorage.setItem('customerFirstSisterFinancialAbility',response.customerFirstSisterFinancialAbility);
        localStorage.setItem('customerFirstSisterDateOfDivorce',response.customerFirstSisterDateOfDivorce);
        localStorage.setItem('customerFirstSisterDateOfDeath',response.customerFirstSisterDateOfDeath);
        localStorage.setItem('customerFirstSistersForeignTripNumberOne',response.customerFirstSistersForeignTripNumberOne);
        localStorage.setItem('customerFirstSistersForeignTripNumberTwo',response.customerFirstSistersForeignTripNumberTwo);
        localStorage.setItem('customerFirstSistersForeignTripNumberThree',response.customerFirstSistersForeignTripNumberThree);
        localStorage.setItem('customerFirstSistersForeignTripNumberFour',response.customerFirstSistersForeignTripNumberFour);
        localStorage.setItem('customerFirstSistersForeignTripNumberFive',response.customerFirstSistersForeignTripNumberFive);
        localStorage.setItem('customerFirstSistersForeignTripNumberSix',response.customerFirstSistersForeignTripNumberSix);
        localStorage.setItem('customerFirstSistersForeignTripNumberSeven',response.customerFirstSistersForeignTripNumberSeven);
        localStorage.setItem('customerFirstSistersForeignTripNumberEight',response.customerFirstSistersForeignTripNumberEight);
        localStorage.setItem('customerFirstSistersForeignTripNumberNine',response.customerFirstSistersForeignTripNumberNine);
        localStorage.setItem('customerSecondSisterSecondName',response.customerSecondSisterSecondName);
        localStorage.setItem('customerSecondSisterSurname',response.customerSecondSisterSurname);
        localStorage.setItem('customerSecondSisterJob',response.customerSecondSisterJob);
        localStorage.setItem('customerSecondSisterBirthDay',response.customerSecondSisterBirthDay);
        localStorage.setItem('customerSecondSisterResidenceAddress',response.customerSecondSisterResidenceAddress);
        localStorage.setItem('customerSecondSisterPostalCode',response.customerSecondSisterPostalCode);
        localStorage.setItem('customerSecondSisterPhoneNumber',response.customerSecondSisterPhoneNumber);
        localStorage.setItem('customerSecondSisterEducation',response.customerSecondSisterEducation);
        localStorage.setItem('customerSecondSisterFinancialAbility',response.customerSecondSisterFinancialAbility);
        localStorage.setItem('customerSecondSisterDateOfDivorce',response.customerSecondSisterDateOfDivorce);
        localStorage.setItem('customerSecondSisterDateOfDeath',response.customerSecondSisterDateOfDeath);
        localStorage.setItem('customerSecondSistersForeignTripNumberOne',response.customerSecondSistersForeignTripNumberOne);
        localStorage.setItem('customerSecondSistersForeignTripNumberTwo',response.customerSecondSistersForeignTripNumberTwo);
        localStorage.setItem('customerSecondSistersForeignTripNumberThree',response.customerSecondSistersForeignTripNumberThree);
        localStorage.setItem('customerSecondSistersForeignTripNumberFour',response.customerSecondSistersForeignTripNumberFour);
        localStorage.setItem('customerSecondSistersForeignTripNumberFive',response.customerSecondSistersForeignTripNumberFive);
        localStorage.setItem('customerSecondSistersForeignTripNumberSix',response.customerSecondSistersForeignTripNumberSix);
        localStorage.setItem('customerSecondSistersForeignTripNumberSeven',response.customerSecondSistersForeignTripNumberSeven);
        localStorage.setItem('customerSecondSistersForeignTripNumberEight',response.customerSecondSistersForeignTripNumberEight);
        localStorage.setItem('customerSecondSistersForeignTripNumberNine',response.customerSecondSistersForeignTripNumberNine);
        localStorage.setItem('customerThirdSisterThirdName',response.customerThirdSisterThirdName);
        localStorage.setItem('customerThirdSisterSurname',response.customerThirdSisterSurname);
        localStorage.setItem('customerThirdSisterJob',response.customerThirdSisterJob);
        localStorage.setItem('customerThirdSisterBirthDay',response.customerThirdSisterBirthDay);
        localStorage.setItem('customerThirdSisterResidenceAddress',response.customerThirdSisterResidenceAddress);
        localStorage.setItem('customerThirdSisterPostalCode',response.customerThirdSisterPostalCode);
        localStorage.setItem('customerThirdSisterPhoneNumber',response.customerThirdSisterPhoneNumber);
        localStorage.setItem('customerThirdSisterEducation',response.customerThirdSisterEducation);
        localStorage.setItem('customerThirdSisterFinancialAbility',response.customerThirdSisterFinancialAbility);
        localStorage.setItem('customerThirdSisterDateOfDivorce',response.customerThirdSisterDateOfDivorce);
        localStorage.setItem('customerThirdSisterDateOfDeath',response.customerThirdSisterDateOfDeath);
        localStorage.setItem('customerThirdSistersForeignTripNumberOne',response.customerThirdSistersForeignTripNumberOne);
        localStorage.setItem('customerThirdSistersForeignTripNumberTwo',response.customerThirdSistersForeignTripNumberTwo);
        localStorage.setItem('customerThirdSistersForeignTripNumberThree',response.customerThirdSistersForeignTripNumberThree);
        localStorage.setItem('customerThirdSistersForeignTripNumberFour',response.customerThirdSistersForeignTripNumberFour);
        localStorage.setItem('customerThirdSistersForeignTripNumberFive',response.customerThirdSistersForeignTripNumberFive);
        localStorage.setItem('customerThirdSistersForeignTripNumberSix',response.customerThirdSistersForeignTripNumberSix);
        localStorage.setItem('customerThirdSistersForeignTripNumberSeven',response.customerThirdSistersForeignTripNumberSeven);
        localStorage.setItem('customerThirdSistersForeignTripNumberEight',response.customerThirdSistersForeignTripNumberEight);
        localStorage.setItem('customerThirdSistersForeignTripNumberNine',response.customerThirdSistersForeignTripNumberNine);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        values.FatherName = localStorage.getItem('customerFatherFirstName');
        values.FatherFamily = localStorage.getItem('customerFatherSurname');
        values.FatherJob = localStorage.getItem('customerFatherJob');
        values.FathersResidenceAddress = localStorage.getItem('customerFathersResidenceAddress');
        values.FatherPostalCode = localStorage.getItem('customerFatherPostalCode');
        values.FatherPhoneCall = localStorage.getItem('customerFatherPhoneNumber');
        values.FatherEducation = localStorage.getItem('customerFatherEducation');
        values.FatherFinancialAbility = localStorage.getItem('customerFatherFinancialAbility');
        values.FathersForeignTrip1 = localStorage.getItem('customerFathersForeignTripNumberOne');
        values.FathersForeignTrip2 = localStorage.getItem('customerFathersForeignTripNumberTwo');
        values.FathersForeignTrip3 = localStorage.getItem('customerFathersForeignTripNumberThree');
        values.FathersForeignTrip4 = localStorage.getItem('customerFathersForeignTripNumberFour');
        values.FathersForeignTrip5 = localStorage.getItem('customerFathersForeignTripNumberFive');
        values.FathersForeignTrip6 = localStorage.getItem('customerFathersForeignTripNumberSix');
        values.FathersForeignTrip7 = localStorage.getItem('customerFathersForeignTripNumberSeven');
        values.FathersForeignTrip8 = localStorage.getItem('customerFathersForeignTripNumberEight');
        values.FathersForeignTrip9 = localStorage.getItem('customerFathersForeignTripNumberNine');
  
        values.MotherName = localStorage.getItem('customerMotherFirstName');
        values.MotherFamily = localStorage.getItem('customerMotherSurname');
        values.MotherJob = localStorage.getItem('customerMotherJob');
        values.MothersResidenceAddress = localStorage.getItem('customerMotherResidenceAddress');
        values.MotherPostalCode = localStorage.getItem('customerMotherPostalCode');
        values.MotherPhoneCall = localStorage.getItem('customerMotherPhoneNumber');
        values.MotherEducation = localStorage.getItem('customerMotherEducation');
        values.MotherFinancialAbility = localStorage.getItem('customerMotherFinancialAbility');
        values.MothersForeignTrip1 = localStorage.getItem('customerMothersForeignTripNumberOne');
        values.MothersForeignTrip2 = localStorage.getItem('customerMothersForeignTripNumberTwo');
        values.MothersForeignTrip3 = localStorage.getItem('customerMothersForeignTripNumberThree');
        values.MothersForeignTrip4 = localStorage.getItem('customerMothersForeignTripNumberFour');
        values.MothersForeignTrip5 = localStorage.getItem('customerMothersForeignTripNumberFive');
        values.MothersForeignTrip6 = localStorage.getItem('customerMothersForeignTripNumberSix');
        values.MothersForeignTrip7 = localStorage.getItem('customerMothersForeignTripNumberSeven');
        values.MothersForeignTrip8 = localStorage.getItem('customerMothersForeignTripNumberEight');
        values.MothersForeignTrip9 = localStorage.getItem('customerMothersForeignTripNumberNine');
  
        values.Brother1Name = localStorage.getItem('customerFirstBrotherFirstName');
        values.Brother1Family = localStorage.getItem('customerFirstBrotherSurname');
        values.Brother1Job = localStorage.getItem('customerFirstBrotherJob');
        values.Brother1sResidenceAddress = localStorage.getItem('customerFirstBrotherResidenceAddress');
        values.Brother1PostalCode = localStorage.getItem('customerFirstBrotherPostalCode');
        values.Brother1PhoneCall = localStorage.getItem('customerFirstBrotherPhoneNumber');
        values.Brother1Education = localStorage.getItem('customerFirstBrotherEducation');
        values.Brother1FinancialAbility = localStorage.getItem('customerFirstBrotherFinancialAbility');
        values.Brother1sForeignTrip1 = localStorage.getItem('customerFirstBrothersForeignTripNumberOne');
        values.Brother1sForeignTrip2 = localStorage.getItem('customerFirstBrothersForeignTripNumberTwo');
        values.Brother1sForeignTrip3 = localStorage.getItem('customerFirstBrothersForeignTripNumberThree');
        values.Brother1sForeignTrip4 = localStorage.getItem('customerFirstBrothersForeignTripNumberFour');
        values.Brother1sForeignTrip5 = localStorage.getItem('customerFirstBrothersForeignTripNumberFive');
        values.Brother1sForeignTrip6 = localStorage.getItem('customerFirstBrothersForeignTripNumberSix');
        values.Brother1sForeignTrip7 = localStorage.getItem('customerFirstBrothersForeignTripNumberSeven');
        values.Brother1sForeignTrip8 = localStorage.getItem('customerFirstBrothersForeignTripNumberEight');
        values.Brother1sForeignTrip9 = localStorage.getItem('customerFirstBrothersForeignTripNumberNine');
  
        values.Brother2Name = localStorage.getItem('customerSecondBrotherFirstName');
        values.Brother2Family = localStorage.getItem('customerSecondBrotherSurname');
        values.Brother2Job = localStorage.getItem('customerSecondBrotherJob');
        values.Brother2sResidenceAddress = localStorage.getItem('customerSecondBrotherResidenceAddress');
        values.Brother2PostalCode = localStorage.getItem('customerSecondBrotherPostalCode');
        values.Brother2PhoneCall = localStorage.getItem('customerSecondBrotherPhoneNumber');
        values.Brother2Education = localStorage.getItem('customerSecondBrotherEducation');
        values.Brother2FinancialAbility = localStorage.getItem('customerSecondBrotherFinancialAbility');
        values.Brother2sForeignTrip1 = localStorage.getItem('customerSecondBrothersForeignTripNumberOne');
        values.Brother2sForeignTrip2 = localStorage.getItem('customerSecondBrothersForeignTripNumberTwo');
        values.Brother2sForeignTrip3 = localStorage.getItem('customerSecondBrothersForeignTripNumberThree');
        values.Brother2sForeignTrip4 = localStorage.getItem('customerSecondBrothersForeignTripNumberFour');
        values.Brother2sForeignTrip5 = localStorage.getItem('customerSecondBrothersForeignTripNumberFive');
        values.Brother2sForeignTrip6 = localStorage.getItem('customerSecondBrothersForeignTripNumberSix');
        values.Brother2sForeignTrip7 = localStorage.getItem('customerSecondBrothersForeignTripNumberSeven');
        values.Brother2sForeignTrip8 = localStorage.getItem('customerSecondBrothersForeignTripNumberEight');
        values.Brother2sForeignTrip9 = localStorage.getItem('customerSecondBrothersForeignTripNumberNine');
  
        values.Brother3Name = localStorage.getItem('customerThirdBrotherFirstName');
        values.Brother3Family = localStorage.getItem('customerThirdBrotherSurname');
        values.Brother3Job = localStorage.getItem('customerThirdBrotherJob');
        values.Brother3sResidenceAddress = localStorage.getItem('customerThirdBrotherResidenceAddress');
        values.Brother3PostalCode = localStorage.getItem('customerThirdBrotherPostalCode');
        values.Brother3PhoneCall = localStorage.getItem('customerThirdBrotherPhoneNumber');
        values.Brother3Education = localStorage.getItem('customerThirdBrotherEducation');
        values.Brother3FinancialAbility = localStorage.getItem('customerThirdBrotherFinancialAbility');
        values.Brother3sForeignTrip1 = localStorage.getItem('customerThirdBrothersForeignTripNumberOne');
        values.Brother3sForeignTrip2 = localStorage.getItem('customerThirdBrothersForeignTripNumberTwo');
        values.Brother3sForeignTrip3 = localStorage.getItem('customerThirdBrothersForeignTripNumberThree');
        values.Brother3sForeignTrip4 = localStorage.getItem('customerThirdBrothersForeignTripNumberFour');
        values.Brother3sForeignTrip5 = localStorage.getItem('customerThirdBrothersForeignTripNumberFive');
        values.Brother3sForeignTrip6 = localStorage.getItem('customerThirdBrothersForeignTripNumberSix');
        values.Brother3sForeignTrip7 = localStorage.getItem('customerThirdBrothersForeignTripNumberSeven');
        values.Brother3sForeignTrip8 = localStorage.getItem('customerThirdBrothersForeignTripNumberEight');
        values.Brother3sForeignTrip9 = localStorage.getItem('customerThirdBrothersForeignTripNumberNine');
  
        values.Sister1Name = localStorage.getItem('customerFirstSisterFirstName');
        values.Sister1Family = localStorage.getItem('customerFirstSisterSurname');
        values.Sister1Job = localStorage.getItem('customerFirstSisterJob');
        values.Sister1sResidenceAddress = localStorage.getItem('customerFirstSisterResidenceAddress');
        values.Sister1PostalCode = localStorage.getItem('customerFirstSisterPostalCode');
        values.Sister1PhoneCall = localStorage.getItem('customerFirstSisterPhoneNumber');
        values.Sister1Education = localStorage.getItem('customerFirstSisterEducation');
        values.Sister1FinancialAbility = localStorage.getItem('customerFirstSisterFinancialAbility');
        values.Sister1sForeignTrip1 = localStorage.getItem('customerFirstSistersForeignTripNumberOne');
        values.Sister1sForeignTrip2 = localStorage.getItem('customerFirstSistersForeignTripNumberTwo');
        values.Sister1sForeignTrip3 = localStorage.getItem('customerFirstSistersForeignTripNumberThree');
        values.Sister1sForeignTrip4 = localStorage.getItem('customerFirstSistersForeignTripNumberFour');
        values.Sister1sForeignTrip5 = localStorage.getItem('customerFirstSistersForeignTripNumberFive');
        values.Sister1sForeignTrip6 = localStorage.getItem('customerFirstSistersForeignTripNumberSix');
        values.Sister1sForeignTrip7 = localStorage.getItem('customerFirstSistersForeignTripNumberSeven');
        values.Sister1sForeignTrip8 = localStorage.getItem('customerFirstSistersForeignTripNumberEight');
        values.Sister1sForeignTrip9 = localStorage.getItem('customerFirstSistersForeignTripNumberNine');
  
        values.Sister2Name = localStorage.getItem('customerSecondSisterSecondName');
        values.Sister2Family = localStorage.getItem('customerSecondSisterSurname');
        values.Sister2Job = localStorage.getItem('customerSecondSisterJob');
        values.Sister2sResidenceAddress = localStorage.getItem('customerSecondSisterResidenceAddress');
        values.Sister2PostalCode = localStorage.getItem('customerSecondSisterPostalCode');
        values.Sister2PhoneCall = localStorage.getItem('customerSecondSisterPhoneNumber');
        values.Sister2Education = localStorage.getItem('customerSecondSisterEducation');
        values.Sister2FinancialAbility = localStorage.getItem('customerSecondSisterFinancialAbility');
        values.Sister2sForeignTrip1 = localStorage.getItem('customerSecondSistersForeignTripNumberOne');
        values.Sister2sForeignTrip2 = localStorage.getItem('customerSecondSistersForeignTripNumberTwo');
        values.Sister2sForeignTrip3 = localStorage.getItem('customerSecondSistersForeignTripNumberThree');
        values.Sister2sForeignTrip4 = localStorage.getItem('customerSecondSistersForeignTripNumberFour');
        values.Sister2sForeignTrip5 = localStorage.getItem('customerSecondSistersForeignTripNumberFive');
        values.Sister2sForeignTrip6 = localStorage.getItem('customerSecondSistersForeignTripNumberSix');
        values.Sister2sForeignTrip7 = localStorage.getItem('customerSecondSistersForeignTripNumberSeven');
        values.Sister2sForeignTrip8 = localStorage.getItem('customerSecondSistersForeignTripNumberEight');
        values.Sister2sForeignTrip9 = localStorage.getItem('customerSecondSistersForeignTripNumberNine');
  
        values.Sister3Name = localStorage.getItem('customerThirdSisterThirdName');
        values.Sister3Family = localStorage.getItem('customerThirdSisterSurname');
        values.Sister3Job = localStorage.getItem('customerThirdSisterJob');
        values.Sister3sResidenceAddress = localStorage.getItem('customerThirdSisterResidenceAddress');
        values.Sister3PostalCode = localStorage.getItem('customerThirdSisterPostalCode');
        values.Sister3PhoneCall = localStorage.getItem('customerThirdSisterPhoneNumber');
        values.Sister3Education = localStorage.getItem('customerThirdSisterEducation');
        values.Sister3FinancialAbility = localStorage.getItem('customerThirdSisterFinancialAbility');
        values.Sister3sForeignTrip1 = localStorage.getItem('customerThirdSistersForeignTripNumberOne');
        values.Sister3sForeignTrip2 = localStorage.getItem('customerThirdSistersForeignTripNumberTwo');
        values.Sister3sForeignTrip3 = localStorage.getItem('customerThirdSistersForeignTripNumberThree');
        values.Sister3sForeignTrip4 = localStorage.getItem('customerThirdSistersForeignTripNumberFour');
        values.Sister3sForeignTrip5 = localStorage.getItem('customerThirdSistersForeignTripNumberFive');
        values.Sister3sForeignTrip6 = localStorage.getItem('customerThirdSistersForeignTripNumberSix');
        values.Sister3sForeignTrip7 = localStorage.getItem('customerThirdSistersForeignTripNumberSeven');
        values.Sister3sForeignTrip8 = localStorage.getItem('customerThirdSistersForeignTripNumberEight');
        values.Sister3sForeignTrip9 = localStorage.getItem('customerThirdSistersForeignTripNumberNine');
  
        setFatherBirthDay(localStorage.getItem('customerFatherBirthDay'));
        setFatherDateofDivorce(localStorage.getItem('customerFatherDateOfDivorce'));
        setFatherDateofDeath(localStorage.getItem('customerFatherDateOfDeath'));
  
        setMotherBirthDay(localStorage.getItem('customerMotherBirthDay'));
        setMotherDateofDivorce(localStorage.getItem('customerMotherDateOfDivorce'));
        setMotherDateofDeath(localStorage.getItem('customerMotherDateOfDeath'));
  
        setBrother1BirthDay(localStorage.getItem('customerFirstBrotherBirthDay'));
        setBrother1DateofDivorce(localStorage.getItem('customerFirstBrotherDateOfDivorce'));
        setBrother1DateofDeath(localStorage.getItem('customerFirstBrotherDateOfDeath'));
  
        setBrother2BirthDay(localStorage.getItem('customerSecondBrotherBirthDay'));
        setBrother2DateofDivorce(localStorage.getItem('customerSecondBrotherDateOfDivorce'));
        setBrother2DateofDeath(localStorage.getItem('customerSecondBrotherDateOfDeath'));
  
        setBrother3BirthDay(localStorage.getItem('customerThirdBrotherBirthDay'));
        setBrother3DateofDivorce(localStorage.getItem('customerThirdBrotherDateOfDivorce'));
        setBrother3DateofDeath(localStorage.getItem('customerThirdBrotherDateOfDeath'));
  
        setSister1BirthDay(localStorage.getItem('customerFirstSisterBirthDay'));
        setSister1DateofDivorce(localStorage.getItem('customerFirstSisterDateOfDivorce'));
        setSister1DateofDeath(localStorage.getItem('customerFirstSisterDateOfDeath'));
  
        setSister2BirthDay(localStorage.getItem('customerSecondSisterBirthDay'));
        setSister2DateofDivorce(localStorage.getItem('customerSecondSisterDateOfDivorce'));
        setSister2DateofDeath(localStorage.getItem('customerSecondSisterDateOfDeath'));
  
        setSister3BirthDay(localStorage.getItem('customerThirdSisterBirthDay'));
        setSister3DateofDivorce(localStorage.getItem('customerThirdSisterDateOfDivorce'));
        setSister3DateofDeath(localStorage.getItem('customerThirdSisterDateOfDeath'));
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
else if(localStorage.getItem('whoIs')=='sp'){
  if(!localStorage.getItem('EvFormUpdate')){ 
    fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpouseFamilyInformation' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({
  //Evaluation Customer form
        //Evaluation Customer Form Personal Information
        Email: localStorage.getItem('email'),
      
        CustomerDocumentNumber:localStorage.getItem('documentCode'),
        
  
        CustomerSpouseFatherFirstName: values.FatherName,
        CustomerSpouseFatherSurname: values.FatherFamily,
        CustomerSpouseFatherJob: values.FatherJob,
        CustomerSpouseFatherBirthDay: FatherBirthDay.toString(),
        CustomerSpouseFathersResidenceAddress: values.FathersResidenceAddress,
        CustomerSpouseFatherPostalCode: values.FatherPostalCode,
        CustomerSpouseFatherPhoneNumber: values.FatherPhoneCall,
        CustomerSpouseFatherEducation: values.FatherEducation,
        CustomerSpouseFatherFinancialAbility: values.FatherFinancialAbility,
        CustomerSpouseFatherDateOfDivorce: FatherDateofDivorce.toString(),
        CustomerSpouseFatherDateOfDeath: FatherDateofDeath.toString(),
        CustomerSpouseFathersForeignTripNumberOne: values.FathersForeignTrip1,
        CustomerSpouseFathersForeignTripNumberTwo: values.FathersForeignTrip2,
        CustomerSpouseFathersForeignTripNumberThree: values.FathersForeignTrip3,
        CustomerSpouseFathersForeignTripNumberFour: values.FathersForeignTrip4,
        CustomerSpouseFathersForeignTripNumberFive: values.FathersForeignTrip5,
        CustomerSpouseFathersForeignTripNumberSix: values.FathersForeignTrip6,
        CustomerSpouseFathersForeignTripNumberSeven: values.FathersForeignTrip7,
        CustomerSpouseFathersForeignTripNumberEight: values.FathersForeignTrip8,
        CustomerSpouseFathersForeignTripNumberNine: values.FathersForeignTrip9,
        
        CustomerSpouseMotherFirstName: values.MotherName,
        CustomerSpouseMotherSurname: values.MotherFamily,
        CustomerSpouseMotherJob: values.MotherJob,
        CustomerSpouseMotherBirthDay: MotherBirthDay.toString(),
        CustomerSpouseMotherResidenceAddress: values.MothersResidenceAddress,
        CustomerSpouseMotherPostalCode: values.MotherPostalCode,
        CustomerSpouseMotherPhoneNumber: values.MotherPhoneCall,
        CustomerSpouseMotherEducation: values.MotherEducation,
        CustomerSpouseMotherFinancialAbility: values.MotherFinancialAbility,
        CustomerSpouseMotherDateOfDivorce: MotherDateofDivorce.toString(),
        CustomerSpouseMotherDateOfDeath: MotherDateofDeath.toString(),
        CustomerSpouseMothersForeignTripNumberOne: values.MothersForeignTrip1,
        CustomerSpouseMothersForeignTripNumberTwo: values.MothersForeignTrip2,
        CustomerSpouseMothersForeignTripNumberThree: values.MothersForeignTrip3,
        CustomerSpouseMothersForeignTripNumberFour: values.MothersForeignTrip4,
        CustomerSpouseMothersForeignTripNumberFive: values.MothersForeignTrip5,
        CustomerSpouseMothersForeignTripNumberSix: values.MothersForeignTrip6,
        CustomerSpouseMothersForeignTripNumberSeven: values.MothersForeignTrip7,
        CustomerSpouseMothersForeignTripNumberEight: values.MothersForeignTrip8,
        CustomerSpouseMothersForeignTripNumberNine: values.MothersForeignTrip9,
  
        CustomerSpouseFirstBrotherFirstName: values.Brother1Name,
        CustomerSpouseFirstBrotherSurname: values.Brother1Family,
        CustomerSpouseFirstBrotherJob: values.Brother1Job,
        CustomerSpouseFirstBrotherBirthDay: Brother1BirthDay.toString(),
        CustomerSpouseFirstBrotherResidenceAddress: values.Brother1sResidenceAddress,
        CustomerSpouseFirstBrotherPostalCode: values.Brother1PostalCode,
        CustomerSpouseFirstBrotherPhoneNumber: values.Brother1PhoneCall,
        CustomerSpouseFirstBrotherEducation: values.Brother1Education,
        CustomerSpouseFirstBrotherFinancialAbility: values.Brother1FinancialAbility,
        CustomerSpouseFirstBrotherDateOfDivorce: Brother1DateofDivorce.toString(),
        CustomerSpouseFirstBrotherDateOfDeath: Brother1DateofDeath.toString(),
        CustomerSpouseFirstBrothersForeignTripNumberOne: values.Brother1sForeignTrip1,
        CustomerSpouseFirstBrothersForeignTripNumberTwo: values.Brother1sForeignTrip2,
        CustomerSpouseFirstBrothersForeignTripNumberThree: values.Brother1sForeignTrip3,
        CustomerSpouseFirstBrothersForeignTripNumberFour: values.Brother1sForeignTrip4,
        CustomerSpouseFirstBrothersForeignTripNumberFive: values.Brother1sForeignTrip5,
        CustomerSpouseFirstBrothersForeignTripNumberSix: values.Brother1sForeignTrip6,
        CustomerSpouseFirstBrothersForeignTripNumberSeven: values.Brother1sForeignTrip7,
        CustomerSpouseFirstBrothersForeignTripNumberEight: values.Brother1sForeignTrip8,
        CustomerSpouseFirstBrothersForeignTripNumberNine: values.Brother1sForeignTrip9,
  
        CustomerSpouseSecondBrotherFirstName: values.Brother2Name,
        CustomerSpouseSecondBrotherSurname: values.Brother2Family,
        CustomerSpouseSecondBrotherJob: values.Brother2Job,
        CustomerSpouseSecondBrotherBirthDay: Brother2BirthDay.toString(),
        CustomerSpouseSecondBrotherResidenceAddress: values.Brother2sResidenceAddress,
        CustomerSpouseSecondBrotherPostalCode: values.Brother2PostalCode,
        CustomerSpouseSecondBrotherPhoneNumber: values.Brother2PhoneCall,
        CustomerSpouseSecondBrotherEducation: values.Brother2Education,
        CustomerSpouseSecondBrotherFinancialAbility: values.Brother2FinancialAbility,
        CustomerSpouseSecondBrotherDateOfDivorce: Brother2DateofDivorce.toString(),
        CustomerSpouseSecondBrotherDateOfDeath: Brother2DateofDeath.toString(),
        CustomerSpouseSecondBrothersForeignTripNumberOne: values.Brother2sForeignTrip1,
        CustomerSpouseSecondBrothersForeignTripNumberTwo: values.Brother2sForeignTrip2,
        CustomerSpouseSecondBrothersForeignTripNumberThree: values.Brother2sForeignTrip3,
        CustomerSpouseSecondBrothersForeignTripNumberFour: values.Brother2sForeignTrip4,
        CustomerSpouseSecondBrothersForeignTripNumberFive: values.Brother2sForeignTrip5,
        CustomerSpouseSecondBrothersForeignTripNumberSix: values.Brother2sForeignTrip6,
        CustomerSpouseSecondBrothersForeignTripNumberSeven: values.Brother2sForeignTrip7,
        CustomerSpouseSecondBrothersForeignTripNumberEight: values.Brother2sForeignTrip8,
        CustomerSpouseSecondBrothersForeignTripNumberNine: values.Brother2sForeignTrip9,
  
        CustomerSpouseThirdBrotherFirstName: values.Brother3Name,
        CustomerSpouseThirdBrotherSurname: values.Brother3Family,
        CustomerSpouseThirdBrotherJob: values.Brother3Job,
        CustomerSpouseThirdBrotherBirthDay: Brother3BirthDay.toString(),
        CustomerSpouseThirdBrotherResidenceAddress: values.Brother3sResidenceAddress,
        CustomerSpouseThirdBrotherPostalCode: values.Brother3PostalCode,
        CustomerSpouseThirdBrotherPhoneNumber: values.Brother3PhoneCall,
        CustomerSpouseThirdBrotherEducation: values.Brother3Education,
        CustomerSpouseThirdBrotherFinancialAbility: values.Brother3FinancialAbility,
        CustomerSpouseThirdBrotherDateOfDivorce: Brother3DateofDivorce.toString(),
        CustomerSpouseThirdBrotherDateOfDeath: Brother3DateofDeath.toString(),
        CustomerSpouseThirdBrothersForeignTripNumberOne: values.Brother3sForeignTrip1,
        CustomerSpouseThirdBrothersForeignTripNumberTwo: values.Brother3sForeignTrip2,
        CustomerSpouseThirdBrothersForeignTripNumberThree: values.Brother3sForeignTrip3,
        CustomerSpouseThirdBrothersForeignTripNumberFour: values.Brother3sForeignTrip4,
        CustomerSpouseThirdBrothersForeignTripNumberFive: values.Brother3sForeignTrip5,
        CustomerSpouseThirdBrothersForeignTripNumberSix: values.Brother3sForeignTrip6,
        CustomerSpouseThirdBrothersForeignTripNumberSeven: values.Brother3sForeignTrip7,
        CustomerSpouseThirdBrothersForeignTripNumberEight: values.Brother3sForeignTrip8,
        CustomerSpouseThirdBrothersForeignTripNumberNine: values.Brother3sForeignTrip9,
  
        CustomerSpouseFirstSisterFirstName: values.Sister1Name,
        CustomerSpouseFirstSisterSurname: values.Sister1Family,
        CustomerSpouseFirstSisterJob: values.Sister1Job,
        CustomerSpouseFirstSisterBirthDay: Sister1BirthDay.toString(),
        CustomerSpouseFirstSisterResidenceAddress: values.Sister1sResidenceAddress,
        CustomerSpouseFirstSisterPostalCode: values.Sister1PostalCode,
        CustomerSpouseFirstSisterPhoneNumber: values.Sister1PhoneCall,
        CustomerSpouseFirstSisterEducation: values.Sister1Education,
        CustomerSpouseFirstSisterFinancialAbility: values.Sister1FinancialAbility,
        CustomerSpouseFirstSisterDateOfDivorce: Sister1DateofDivorce.toString(),
        CustomerSpouseFirstSisterDateOfDeath: Sister1DateofDeath.toString(),
        CustomerSpouseFirstSistersForeignTripNumberOne: values.Sister1sForeignTrip1,
        CustomerSpouseFirstSistersForeignTripNumberTwo: values.Sister1sForeignTrip2,
        CustomerSpouseFirstSistersForeignTripNumberThree: values.Sister1sForeignTrip3,
        CustomerSpouseFirstSistersForeignTripNumberFour: values.Sister1sForeignTrip4,
        CustomerSpouseFirstSistersForeignTripNumberFive: values.Sister1sForeignTrip5,
        CustomerSpouseFirstSistersForeignTripNumberSix: values.Sister1sForeignTrip6,
        CustomerSpouseFirstSistersForeignTripNumberSeven: values.Sister1sForeignTrip7,
        CustomerSpouseFirstSistersForeignTripNumberEight: values.Sister1sForeignTrip8,
        CustomerSpouseFirstSistersForeignTripNumberNine: values.Sister1sForeignTrip9,
  
        CustomerSpouseSecondSisterSecondName: values.Sister2Name,
        CustomerSpouseSecondSisterSurname: values.Sister2Family,
        CustomerSpouseSecondSisterJob: values.Sister2Job,
        CustomerSpouseSecondSisterBirthDay: Sister2BirthDay.toString(),
        CustomerSpouseSecondSisterResidenceAddress: values.Sister2sResidenceAddress,
        CustomerSpouseSecondSisterPostalCode: values.Sister2PostalCode,
        CustomerSpouseSecondSisterPhoneNumber: values.Sister2PhoneCall,
        CustomerSpouseSecondSisterEducation: values.Sister2Education,
        CustomerSpouseSecondSisterFinancialAbility: values.Sister2FinancialAbility,
        CustomerSpouseSecondSisterDateOfDivorce: Sister2DateofDivorce.toString(), 
        CustomerSpouseSecondSisterDateOfDeath: Sister2DateofDeath.toString(),
        CustomerSpouseSecondSistersForeignTripNumberOne: values.Sister2sForeignTrip1,
        CustomerSpouseSecondSistersForeignTripNumberTwo: values.Sister2sForeignTrip2,
        CustomerSpouseSecondSistersForeignTripNumberThree: values.Sister2sForeignTrip3,
        CustomerSpouseSecondSistersForeignTripNumberFour: values.Sister2sForeignTrip4,
        CustomerSpouseSecondSistersForeignTripNumberFive: values.Sister2sForeignTrip5,
        CustomerSpouseSecondSistersForeignTripNumberSix: values.Sister2sForeignTrip6,
        CustomerSpouseSecondSistersForeignTripNumberSeven: values.Sister2sForeignTrip7,
        CustomerSpouseSecondSistersForeignTripNumberEight: values.Sister2sForeignTrip8,
        CustomerSpouseSecondSistersForeignTripNumberNine: values.Sister2sForeignTrip9,
  
        CustomerSpouseThirdSisterThirdName: values.Sister3Name,
        CustomerSpouseThirdSisterSurname: values.Sister3Family,
        CustomerSpouseThirdSisterJob: values.Sister3Job,
        CustomerSpouseThirdSisterBirthDay: Sister3BirthDay.toString(),
        CustomerSpouseThirdSisterResidenceAddress: values.Sister3sResidenceAddress,
        CustomerSpouseThirdSisterPostalCode: values.Sister3PostalCode,
        CustomerSpouseThirdSisterPhoneNumber: values.Sister3PhoneCall,
        CustomerSpouseThirdSisterEducation: values.Sister3Education,
        CustomerSpouseThirdSisterFinancialAbility: values.Sister3FinancialAbility,
        CustomerSpouseThirdSisterDateOfDivorce: Sister3DateofDivorce.toString(),
        CustomerSpouseThirdSisterDateOfDeath: Sister3DateofDeath.toString(),
        CustomerSpouseThirdSistersForeignTripNumberOne: values.Sister3sForeignTrip1,
        CustomerSpouseThirdSistersForeignTripNumberTwo: values.Sister3sForeignTrip2,
        CustomerSpouseThirdSistersForeignTripNumberThree: values.Sister3sForeignTrip3,
        CustomerSpouseThirdSistersForeignTripNumberFour: values.Sister3sForeignTrip4,
        CustomerSpouseThirdSistersForeignTripNumberFive: values.Sister3sForeignTrip5,
        CustomerSpouseThirdSistersForeignTripNumberSix: values.Sister3sForeignTrip6,
        CustomerSpouseThirdSistersForeignTripNumberSeven: values.Sister3sForeignTrip7,
        CustomerSpouseThirdSistersForeignTripNumberEight: values.Sister3sForeignTrip8,
        CustomerSpouseThirdSistersForeignTripNumberNine: values.Sister3sForeignTrip9,
    
    })
   }).then(response => response.json()).then(response=>{
  
     if(response.statusCode==400 || response.statusCode==404)
     {
       console.log(response.statusMessage);
       console.log(values.EnglishCheckBox);
      //  setFormError(true);
      //  setHelpStatusMessage(response.statusMessage);
      //  setIspending(false);
     }
     else{
      //  setHelpStatusMessage("");
      //  setIspending(false);
      //  history.push('/regstep6');
     }
       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
  
       // }
     })
    }
    else 
    {
      fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpouseFamilyInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
     //Evaluation Customer form
             //Evaluation Customer Form Personal Information
             Email: localStorage.getItem('email'),
             CustomerCode:localStorage.getItem('customerCode'),
             
             CustomerSpouseFatherFirstName: values.FatherName,
             CustomerSpouseFatherSurname: values.FatherFamily,
             CustomerSpouseFatherJob: values.FatherJob,
             CustomerSpouseFatherBirthDay: FatherBirthDay.toString(),
             CustomerSpouseFathersResidenceAddress: values.FathersResidenceAddress,
             CustomerSpouseFatherPostalCode: values.FatherPostalCode,
             CustomerSpouseFatherPhoneNumber: values.FatherPhoneCall,
             CustomerSpouseFatherEducation: values.FatherEducation,
             CustomerSpouseFatherFinancialAbility: values.FatherFinancialAbility,
             CustomerSpouseFatherDateOfDivorce: FatherDateofDivorce.toString(),
             CustomerSpouseFatherDateOfDeath: FatherDateofDeath.toString(),
             CustomerSpouseFathersForeignTripNumberOne: values.FathersForeignTrip1,
             CustomerSpouseFathersForeignTripNumberTwo: values.FathersForeignTrip2,
             CustomerSpouseFathersForeignTripNumberThree: values.FathersForeignTrip3,
             CustomerSpouseFathersForeignTripNumberFour: values.FathersForeignTrip4,
             CustomerSpouseFathersForeignTripNumberFive: values.FathersForeignTrip5,
             CustomerSpouseFathersForeignTripNumberSix: values.FathersForeignTrip6,
             CustomerSpouseFathersForeignTripNumberSeven: values.FathersForeignTrip7,
             CustomerSpouseFathersForeignTripNumberEight: values.FathersForeignTrip8,
             CustomerSpouseFathersForeignTripNumberNine: values.FathersForeignTrip9,
             
             CustomerSpouseMotherFirstName: values.MotherName,
             CustomerSpouseMotherSurname: values.MotherFamily,
             CustomerSpouseMotherJob: values.MotherJob,
             CustomerSpouseMotherBirthDay: MotherBirthDay.toString(),
             CustomerSpouseMotherResidenceAddress: values.MothersResidenceAddress,
             CustomerSpouseMotherPostalCode: values.MotherPostalCode,
             CustomerSpouseMotherPhoneNumber: values.MotherPhoneCall,
             CustomerSpouseMotherEducation: values.MotherEducation,
             CustomerSpouseMotherFinancialAbility: values.MotherFinancialAbility,
             CustomerSpouseMotherDateOfDivorce: MotherDateofDivorce.toString(),
             CustomerSpouseMotherDateOfDeath: MotherDateofDeath.toString(),
             CustomerSpouseMothersForeignTripNumberOne: values.MothersForeignTrip1,
             CustomerSpouseMothersForeignTripNumberTwo: values.MothersForeignTrip2,
             CustomerSpouseMothersForeignTripNumberThree: values.MothersForeignTrip3,
             CustomerSpouseMothersForeignTripNumberFour: values.MothersForeignTrip4,
             CustomerSpouseMothersForeignTripNumberFive: values.MothersForeignTrip5,
             CustomerSpouseMothersForeignTripNumberSix: values.MothersForeignTrip6,
             CustomerSpouseMothersForeignTripNumberSeven: values.MothersForeignTrip7,
             CustomerSpouseMothersForeignTripNumberEight: values.MothersForeignTrip8,
             CustomerSpouseMothersForeignTripNumberNine: values.MothersForeignTrip9,
       
             CustomerSpouseFirstBrotherFirstName: values.Brother1Name,
             CustomerSpouseFirstBrotherSurname: values.Brother1Family,
             CustomerSpouseFirstBrotherJob: values.Brother1Job,
             CustomerSpouseFirstBrotherBirthDay: Brother1BirthDay.toString(),
             CustomerSpouseFirstBrotherResidenceAddress: values.Brother1sResidenceAddress,
             CustomerSpouseFirstBrotherPostalCode: values.Brother1PostalCode,
             CustomerSpouseFirstBrotherPhoneNumber: values.Brother1PhoneCall,
             CustomerSpouseFirstBrotherEducation: values.Brother1Education,
             CustomerSpouseFirstBrotherFinancialAbility: values.Brother1FinancialAbility,
             CustomerSpouseFirstBrotherDateOfDivorce: Brother1DateofDivorce.toString(),
             CustomerSpouseFirstBrotherDateOfDeath: Brother1DateofDeath.toString(),
             CustomerSpouseFirstBrothersForeignTripNumberOne: values.Brother1sForeignTrip1,
             CustomerSpouseFirstBrothersForeignTripNumberTwo: values.Brother1sForeignTrip2,
             CustomerSpouseFirstBrothersForeignTripNumberThree: values.Brother1sForeignTrip3,
             CustomerSpouseFirstBrothersForeignTripNumberFour: values.Brother1sForeignTrip4,
             CustomerSpouseFirstBrothersForeignTripNumberFive: values.Brother1sForeignTrip5,
             CustomerSpouseFirstBrothersForeignTripNumberSix: values.Brother1sForeignTrip6,
             CustomerSpouseFirstBrothersForeignTripNumberSeven: values.Brother1sForeignTrip7,
             CustomerSpouseFirstBrothersForeignTripNumberEight: values.Brother1sForeignTrip8,
             CustomerSpouseFirstBrothersForeignTripNumberNine: values.Brother1sForeignTrip9,
       
             CustomerSpouseSecondBrotherFirstName: values.Brother2Name,
             CustomerSpouseSecondBrotherSurname: values.Brother2Family,
             CustomerSpouseSecondBrotherJob: values.Brother2Job,
             CustomerSpouseSecondBrotherBirthDay: Brother2BirthDay.toString(),
             CustomerSpouseSecondBrotherResidenceAddress: values.Brother2sResidenceAddress,
             CustomerSpouseSecondBrotherPostalCode: values.Brother2PostalCode,
             CustomerSpouseSecondBrotherPhoneNumber: values.Brother2PhoneCall,
             CustomerSpouseSecondBrotherEducation: values.Brother2Education,
             CustomerSpouseSecondBrotherFinancialAbility: values.Brother2FinancialAbility,
             CustomerSpouseSecondBrotherDateOfDivorce: Brother2DateofDivorce.toString(),
             CustomerSpouseSecondBrotherDateOfDeath: Brother2DateofDeath.toString(),
             CustomerSpouseSecondBrothersForeignTripNumberOne: values.Brother2sForeignTrip1,
             CustomerSpouseSecondBrothersForeignTripNumberTwo: values.Brother2sForeignTrip2,
             CustomerSpouseSecondBrothersForeignTripNumberThree: values.Brother2sForeignTrip3,
             CustomerSpouseSecondBrothersForeignTripNumberFour: values.Brother2sForeignTrip4,
             CustomerSpouseSecondBrothersForeignTripNumberFive: values.Brother2sForeignTrip5,
             CustomerSpouseSecondBrothersForeignTripNumberSix: values.Brother2sForeignTrip6,
             CustomerSpouseSecondBrothersForeignTripNumberSeven: values.Brother2sForeignTrip7,
             CustomerSpouseSecondBrothersForeignTripNumberEight: values.Brother2sForeignTrip8,
             CustomerSpouseSecondBrothersForeignTripNumberNine: values.Brother2sForeignTrip9,
       
             CustomerSpouseThirdBrotherFirstName: values.Brother3Name,
             CustomerSpouseThirdBrotherSurname: values.Brother3Family,
             CustomerSpouseThirdBrotherJob: values.Brother3Job,
             CustomerSpouseThirdBrotherBirthDay: Brother3BirthDay.toString(),
             CustomerSpouseThirdBrotherResidenceAddress: values.Brother3sResidenceAddress,
             CustomerSpouseThirdBrotherPostalCode: values.Brother3PostalCode,
             CustomerSpouseThirdBrotherPhoneNumber: values.Brother3PhoneCall,
             CustomerSpouseThirdBrotherEducation: values.Brother3Education,
             CustomerSpouseThirdBrotherFinancialAbility: values.Brother3FinancialAbility,
             CustomerSpouseThirdBrotherDateOfDivorce: Brother3DateofDivorce.toString(),
             CustomerSpouseThirdBrotherDateOfDeath: Brother3DateofDeath.toString(),
             CustomerSpouseThirdBrothersForeignTripNumberOne: values.Brother3sForeignTrip1,
             CustomerSpouseThirdBrothersForeignTripNumberTwo: values.Brother3sForeignTrip2,
             CustomerSpouseThirdBrothersForeignTripNumberThree: values.Brother3sForeignTrip3,
             CustomerSpouseThirdBrothersForeignTripNumberFour: values.Brother3sForeignTrip4,
             CustomerSpouseThirdBrothersForeignTripNumberFive: values.Brother3sForeignTrip5,
             CustomerSpouseThirdBrothersForeignTripNumberSix: values.Brother3sForeignTrip6,
             CustomerSpouseThirdBrothersForeignTripNumberSeven: values.Brother3sForeignTrip7,
             CustomerSpouseThirdBrothersForeignTripNumberEight: values.Brother3sForeignTrip8,
             CustomerSpouseThirdBrothersForeignTripNumberNine: values.Brother3sForeignTrip9,
       
             CustomerSpouseFirstSisterFirstName: values.Sister1Name,
             CustomerSpouseFirstSisterSurname: values.Sister1Family,
             CustomerSpouseFirstSisterJob: values.Sister1Job,
             CustomerSpouseFirstSisterBirthDay: Sister1BirthDay.toString(),
             CustomerSpouseFirstSisterResidenceAddress: values.Sister1sResidenceAddress,
             CustomerSpouseFirstSisterPostalCode: values.Sister1PostalCode,
             CustomerSpouseFirstSisterPhoneNumber: values.Sister1PhoneCall,
             CustomerSpouseFirstSisterEducation: values.Sister1Education,
             CustomerSpouseFirstSisterFinancialAbility: values.Sister1FinancialAbility,
             CustomerSpouseFirstSisterDateOfDivorce: Sister1DateofDivorce.toString(),
             CustomerSpouseFirstSisterDateOfDeath: Sister1DateofDeath.toString(),
             CustomerSpouseFirstSistersForeignTripNumberOne: values.Sister1sForeignTrip1,
             CustomerSpouseFirstSistersForeignTripNumberTwo: values.Sister1sForeignTrip2,
             CustomerSpouseFirstSistersForeignTripNumberThree: values.Sister1sForeignTrip3,
             CustomerSpouseFirstSistersForeignTripNumberFour: values.Sister1sForeignTrip4,
             CustomerSpouseFirstSistersForeignTripNumberFive: values.Sister1sForeignTrip5,
             CustomerSpouseFirstSistersForeignTripNumberSix: values.Sister1sForeignTrip6,
             CustomerSpouseFirstSistersForeignTripNumberSeven: values.Sister1sForeignTrip7,
             CustomerSpouseFirstSistersForeignTripNumberEight: values.Sister1sForeignTrip8,
             CustomerSpouseFirstSistersForeignTripNumberNine: values.Sister1sForeignTrip9,
       
             CustomerSpouseSecondSisterSecondName: values.Sister2Name,
             CustomerSpouseSecondSisterSurname: values.Sister2Family,
             CustomerSpouseSecondSisterJob: values.Sister2Job,
             CustomerSpouseSecondSisterBirthDay: Sister2BirthDay.toString(),
             CustomerSpouseSecondSisterResidenceAddress: values.Sister2sResidenceAddress,
             CustomerSpouseSecondSisterPostalCode: values.Sister2PostalCode,
             CustomerSpouseSecondSisterPhoneNumber: values.Sister2PhoneCall,
             CustomerSpouseSecondSisterEducation: values.Sister2Education,
             CustomerSpouseSecondSisterFinancialAbility: values.Sister2FinancialAbility,
             CustomerSpouseSecondSisterDateOfDivorce: Sister2DateofDivorce.toString(), 
             CustomerSpouseSecondSisterDateOfDeath: Sister2DateofDeath.toString(),
             CustomerSpouseSecondSistersForeignTripNumberOne: values.Sister2sForeignTrip1,
             CustomerSpouseSecondSistersForeignTripNumberTwo: values.Sister2sForeignTrip2,
             CustomerSpouseSecondSistersForeignTripNumberThree: values.Sister2sForeignTrip3,
             CustomerSpouseSecondSistersForeignTripNumberFour: values.Sister2sForeignTrip4,
             CustomerSpouseSecondSistersForeignTripNumberFive: values.Sister2sForeignTrip5,
             CustomerSpouseSecondSistersForeignTripNumberSix: values.Sister2sForeignTrip6,
             CustomerSpouseSecondSistersForeignTripNumberSeven: values.Sister2sForeignTrip7,
             CustomerSpouseSecondSistersForeignTripNumberEight: values.Sister2sForeignTrip8,
             CustomerSpouseSecondSistersForeignTripNumberNine: values.Sister2sForeignTrip9,
       
             CustomerSpouseThirdSisterThirdName: values.Sister3Name,
             CustomerSpouseThirdSisterSurname: values.Sister3Family,
             CustomerSpouseThirdSisterJob: values.Sister3Job,
             CustomerSpouseThirdSisterBirthDay: Sister3BirthDay.toString(),
             CustomerSpouseThirdSisterResidenceAddress: values.Sister3sResidenceAddress,
             CustomerSpouseThirdSisterPostalCode: values.Sister3PostalCode,
             CustomerSpouseThirdSisterPhoneNumber: values.Sister3PhoneCall,
             CustomerSpouseThirdSisterEducation: values.Sister3Education,
             CustomerSpouseThirdSisterFinancialAbility: values.Sister3FinancialAbility,
             CustomerSpouseThirdSisterDateOfDivorce: Sister3DateofDivorce.toString(),
             CustomerSpouseThirdSisterDateOfDeath: Sister3DateofDeath.toString(),
             CustomerSpouseThirdSistersForeignTripNumberOne: values.Sister3sForeignTrip1,
             CustomerSpouseThirdSistersForeignTripNumberTwo: values.Sister3sForeignTrip2,
             CustomerSpouseThirdSistersForeignTripNumberThree: values.Sister3sForeignTrip3,
             CustomerSpouseThirdSistersForeignTripNumberFour: values.Sister3sForeignTrip4,
             CustomerSpouseThirdSistersForeignTripNumberFive: values.Sister3sForeignTrip5,
             CustomerSpouseThirdSistersForeignTripNumberSix: values.Sister3sForeignTrip6,
             CustomerSpouseThirdSistersForeignTripNumberSeven: values.Sister3sForeignTrip7,
             CustomerSpouseThirdSistersForeignTripNumberEight: values.Sister3sForeignTrip8,
             CustomerSpouseThirdSistersForeignTripNumberNine: values.Sister3sForeignTrip9,
         
     
         })
        }).then(response => response.json()).then(response=>{
     
          localStorage.setItem('customerSpouseFatherFirstName',response.customerSpouseFatherFirstName);
          localStorage.setItem('customerSpouseFatherSurname',response.customerSpouseFatherSurname);
          localStorage.setItem('customerSpouseFatherJob',response.customerSpouseFatherJob);
          localStorage.setItem('customerSpouseFatherBirthDay',response.customerSpouseFatherBirthDay);
          localStorage.setItem('customerSpouseFathersResidenceAddress',response.customerSpouseFathersResidenceAddress);
          localStorage.setItem('customerSpouseFatherPostalCode',response.customerSpouseFatherPostalCode);
          localStorage.setItem('customerSpouseFatherPhoneNumber',response.customerSpouseFatherPhoneNumber);
          localStorage.setItem('customerSpouseFatherEducation',response.customerSpouseFatherEducation);
          localStorage.setItem('customerSpouseFatherFinancialAbility',response.customerSpouseFatherFinancialAbility);
          localStorage.setItem('customerSpouseFatherDateOfDivorce',response.customerSpouseFatherDateOfDivorce);
          localStorage.setItem('customerSpouseFatherDateOfDeath',response.customerSpouseFatherDateOfDeath);
          localStorage.setItem('customerSpouseFathersForeignTripNumberOne',response.customerSpouseFathersForeignTripNumberOne);
          localStorage.setItem('customerSpouseFathersForeignTripNumberTwo',response.customerSpouseFathersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseFathersForeignTripNumberThree',response.customerSpouseFathersForeignTripNumberThree);
          localStorage.setItem('customerSpouseFathersForeignTripNumberFour',response.customerSpouseFathersForeignTripNumberFour);
          localStorage.setItem('customerSpouseFathersForeignTripNumberFive',response.customerSpouseFathersForeignTripNumberFive);
          localStorage.setItem('customerSpouseFathersForeignTripNumberSix',response.customerSpouseFathersForeignTripNumberSix);
          localStorage.setItem('customerSpouseFathersForeignTripNumberSeven',response.customerSpouseFathersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseFathersForeignTripNumberEight',response.customerSpouseFathersForeignTripNumberEight);
          localStorage.setItem('customerSpouseFathersForeignTripNumberNine',response.customerSpouseFathersForeignTripNumberNine);
          localStorage.setItem('customerSpouseMotherFirstName',response.customerSpouseMotherFirstName);
          localStorage.setItem('customerSpouseMotherSurname',response.customerSpouseMotherSurname);
          localStorage.setItem('customerSpouseMotherJob',response.customerSpouseMotherJob);
          localStorage.setItem('customerSpouseMotherBirthDay',response.customerSpouseMotherBirthDay);
          localStorage.setItem('customerSpouseMotherResidenceAddress',response.customerSpouseMotherResidenceAddress);
          localStorage.setItem('customerSpouseMotherPostalCode',response.customerSpouseMotherPostalCode);
          localStorage.setItem('customerSpouseMotherPhoneNumber',response.customerSpouseMotherPhoneNumber);
          localStorage.setItem('customerSpouseMotherEducation',response.customerSpouseMotherEducation);
          localStorage.setItem('customerSpouseMotherFinancialAbility',response.customerSpouseMotherFinancialAbility);
          localStorage.setItem('customerSpouseMotherDateOfDivorce',response.customerSpouseMotherDateOfDivorce);
          localStorage.setItem('customerSpouseMotherDateOfDeath',response.customerSpouseMotherDateOfDeath);
          localStorage.setItem('customerSpouseMothersForeignTripNumberOne',response.customerSpouseMothersForeignTripNumberOne);
          localStorage.setItem('customerSpouseMothersForeignTripNumberTwo',response.customerSpouseMothersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseMothersForeignTripNumberThree',response.customerSpouseMothersForeignTripNumberThree);
          localStorage.setItem('customerSpouseMothersForeignTripNumberFour',response.customerSpouseMothersForeignTripNumberFour);
          localStorage.setItem('customerSpouseMothersForeignTripNumberFive',response.customerSpouseMothersForeignTripNumberFive);
          localStorage.setItem('customerSpouseMothersForeignTripNumberSix',response.customerSpouseMothersForeignTripNumberSix);
          localStorage.setItem('customerSpouseMothersForeignTripNumberSeven',response.customerSpouseMothersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseMothersForeignTripNumberEight',response.customerSpouseMothersForeignTripNumberEight);
          localStorage.setItem('customerSpouseMothersForeignTripNumberNine',response.customerSpouseMothersForeignTripNumberNine);
          localStorage.setItem('customerSpouseFirstBrotherFirstName',response.customerSpouseFirstBrotherFirstName);
          localStorage.setItem('customerSpouseFirstBrotherSurname',response.customerSpouseFirstBrotherSurname);
          localStorage.setItem('customerSpouseFirstBrotherJob',response.customerSpouseFirstBrotherJob);
          localStorage.setItem('customerSpouseFirstBrotherBirthDay',response.customerSpouseFirstBrotherBirthDay);
          localStorage.setItem('customerSpouseFirstBrotherResidenceAddress',response.customerSpouseFirstBrotherResidenceAddress);
          localStorage.setItem('customerSpouseFirstBrotherPostalCode',response.customerSpouseFirstBrotherPostalCode);
          localStorage.setItem('customerSpouseFirstBrotherPhoneNumber',response.customerSpouseFirstBrotherPhoneNumber);
          localStorage.setItem('customerSpouseFirstBrotherEducation',response.customerSpouseFirstBrotherEducation);
          localStorage.setItem('customerSpouseFirstBrotherFinancialAbility',response.customerSpouseFirstBrotherFinancialAbility);
          localStorage.setItem('customerSpouseFirstBrotherDateOfDivorce',response.customerSpouseFirstBrotherDateOfDivorce);
          localStorage.setItem('customerSpouseFirstBrotherDateOfDeath',response.customerSpouseFirstBrotherDateOfDeath);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberOne',response.customerSpouseFirstBrothersForeignTripNumberOne);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberTwo',response.customerSpouseFirstBrothersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberThree',response.customerSpouseFirstBrothersForeignTripNumberThree);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFour',response.customerSpouseFirstBrothersForeignTripNumberFour);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFive',response.customerSpouseFirstBrothersForeignTripNumberFive);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSix',response.customerSpouseFirstBrothersForeignTripNumberSix);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSeven',response.customerSpouseFirstBrothersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberEight',response.customerSpouseFirstBrothersForeignTripNumberEight);
          localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberNine',response.customerSpouseFirstBrothersForeignTripNumberNine);
          localStorage.setItem('customerSpouseSecondBrotherFirstName',response.customerSpouseSecondBrotherFirstName);
          localStorage.setItem('customerSpouseSecondBrotherSurname',response.customerSpouseSecondBrotherSurname);
          localStorage.setItem('customerSpouseSecondBrotherJob',response.customerSpouseSecondBrotherJob);
          localStorage.setItem('customerSpouseSecondBrotherBirthDay',response.customerSpouseSecondBrotherBirthDay);
          localStorage.setItem('customerSpouseSecondBrotherResidenceAddress',response.customerSpouseSecondBrotherResidenceAddress);
          localStorage.setItem('customerSpouseSecondBrotherPostalCode',response.customerSpouseSecondBrotherPostalCode);
          localStorage.setItem('customerSpouseSecondBrotherPhoneNumber',response.customerSpouseSecondBrotherPhoneNumber);
          localStorage.setItem('customerSpouseSecondBrotherEducation',response.customerSpouseSecondBrotherEducation);
          localStorage.setItem('customerSpouseSecondBrotherFinancialAbility',response.customerSpouseSecondBrotherFinancialAbility);
          localStorage.setItem('customerSpouseSecondBrotherDateOfDivorce',response.customerSpouseSecondBrotherDateOfDivorce);
          localStorage.setItem('customerSpouseSecondBrotherDateOfDeath',response.customerSpouseSecondBrotherDateOfDeath);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberOne',response.customerSpouseSecondBrothersForeignTripNumberOne);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberTwo',response.customerSpouseSecondBrothersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberThree',response.customerSpouseSecondBrothersForeignTripNumberThree);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFour',response.customerSpouseSecondBrothersForeignTripNumberFour);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFive',response.customerSpouseSecondBrothersForeignTripNumberFive);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSix',response.customerSpouseSecondBrothersForeignTripNumberSix);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSeven',response.customerSpouseSecondBrothersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberEight',response.customerSpouseSecondBrothersForeignTripNumberEight);
          localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberNine',response.customerSpouseSecondBrothersForeignTripNumberNine);
          localStorage.setItem('customerSpouseThirdBrotherFirstName',response.customerSpouseThirdBrotherFirstName);
          localStorage.setItem('customerSpouseThirdBrotherSurname',response.customerSpouseThirdBrotherSurname);
          localStorage.setItem('customerSpouseThirdBrotherJob',response.customerSpouseThirdBrotherJob);
          localStorage.setItem('customerSpouseThirdBrotherBirthDay',response.customerSpouseThirdBrotherBirthDay);
          localStorage.setItem('customerSpouseThirdBrotherResidenceAddress',response.customerSpouseThirdBrotherResidenceAddress);
          localStorage.setItem('customerSpouseThirdBrotherPostalCode',response.customerSpouseThirdBrotherPostalCode);
          localStorage.setItem('customerSpouseThirdBrotherPhoneNumber',response.customerSpouseThirdBrotherPhoneNumber);
          localStorage.setItem('customerSpouseThirdBrotherEducation',response.customerSpouseThirdBrotherEducation);
          localStorage.setItem('customerSpouseThirdBrotherFinancialAbility',response.customerSpouseThirdBrotherFinancialAbility);
          localStorage.setItem('customerSpouseThirdBrotherDateOfDivorce',response.customerSpouseThirdBrotherDateOfDivorce);
          localStorage.setItem('customerSpouseThirdBrotherDateOfDeath',response.customerSpouseThirdBrotherDateOfDeath);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberOne',response.customerSpouseThirdBrothersForeignTripNumberOne);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberTwo',response.customerSpouseThirdBrothersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberThree',response.customerSpouseThirdBrothersForeignTripNumberThree);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFour',response.customerSpouseThirdBrothersForeignTripNumberFour);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFive',response.customerSpouseThirdBrothersForeignTripNumberFive);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSix',response.customerSpouseThirdBrothersForeignTripNumberSix);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSeven',response.customerSpouseThirdBrothersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberEight',response.customerSpouseThirdBrothersForeignTripNumberEight);
          localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberNine',response.customerSpouseThirdBrothersForeignTripNumberNine);
          localStorage.setItem('customerSpouseFirstSisterFirstName',response.customerSpouseFirstSisterFirstName);
          localStorage.setItem('customerSpouseFirstSisterSurname',response.customerSpouseFirstSisterSurname);
          localStorage.setItem('customerSpouseFirstSisterJob',response.customerSpouseFirstSisterJob);
          localStorage.setItem('customerSpouseFirstSisterBirthDay',response.customerSpouseFirstSisterBirthDay);
          localStorage.setItem('customerSpouseFirstSisterResidenceAddress',response.customerSpouseFirstSisterResidenceAddress);
          localStorage.setItem('customerSpouseFirstSisterPostalCode',response.customerSpouseFirstSisterPostalCode);
          localStorage.setItem('customerSpouseFirstSisterPhoneNumber',response.customerSpouseFirstSisterPhoneNumber);
          localStorage.setItem('customerSpouseFirstSisterEducation',response.customerSpouseFirstSisterEducation);
          localStorage.setItem('customerSpouseFirstSisterFinancialAbility',response.customerSpouseFirstSisterFinancialAbility);
          localStorage.setItem('customerSpouseFirstSisterDateOfDivorce',response.customerSpouseFirstSisterDateOfDivorce);
          localStorage.setItem('customerSpouseFirstSisterDateOfDeath',response.customerSpouseFirstSisterDateOfDeath);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberOne',response.customerSpouseFirstSistersForeignTripNumberOne);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberTwo',response.customerSpouseFirstSistersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberThree',response.customerSpouseFirstSistersForeignTripNumberThree);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFour',response.customerSpouseFirstSistersForeignTripNumberFour);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFive',response.customerSpouseFirstSistersForeignTripNumberFive);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSix',response.customerSpouseFirstSistersForeignTripNumberSix);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSeven',response.customerSpouseFirstSistersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberEight',response.customerSpouseFirstSistersForeignTripNumberEight);
          localStorage.setItem('customerSpouseFirstSistersForeignTripNumberNine',response.customerSpouseFirstSistersForeignTripNumberNine);
          localStorage.setItem('customerSpouseSecondSisterSecondName',response.customerSpouseSecondSisterSecondName);
          localStorage.setItem('customerSpouseSecondSisterSurname',response.customerSpouseSecondSisterSurname);
          localStorage.setItem('customerSpouseSecondSisterJob',response.customerSpouseSecondSisterJob);
          localStorage.setItem('customerSpouseSecondSisterBirthDay',response.customerSpouseSecondSisterBirthDay);
          localStorage.setItem('customerSpouseSecondSisterResidenceAddress',response.customerSpouseSecondSisterResidenceAddress);
          localStorage.setItem('customerSpouseSecondSisterPostalCode',response.customerSpouseSecondSisterPostalCode);
          localStorage.setItem('customerSpouseSecondSisterPhoneNumber',response.customerSpouseSecondSisterPhoneNumber);
          localStorage.setItem('customerSpouseSecondSisterEducation',response.customerSpouseSecondSisterEducation);
          localStorage.setItem('customerSpouseSecondSisterFinancialAbility',response.customerSpouseSecondSisterFinancialAbility);
          localStorage.setItem('customerSpouseSecondSisterDateOfDivorce',response.customerSpouseSecondSisterDateOfDivorce);
          localStorage.setItem('customerSpouseSecondSisterDateOfDeath',response.customerSpouseSecondSisterDateOfDeath);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberOne',response.customerSpouseSecondSistersForeignTripNumberOne);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberTwo',response.customerSpouseSecondSistersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberThree',response.customerSpouseSecondSistersForeignTripNumberThree);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFour',response.customerSpouseSecondSistersForeignTripNumberFour);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFive',response.customerSpouseSecondSistersForeignTripNumberFive);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSix',response.customerSpouseSecondSistersForeignTripNumberSix);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSeven',response.customerSpouseSecondSistersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberEight',response.customerSpouseSecondSistersForeignTripNumberEight);
          localStorage.setItem('customerSpouseSecondSistersForeignTripNumberNine',response.customerSpouseSecondSistersForeignTripNumberNine);
          localStorage.setItem('customerSpouseThirdSisterThirdName',response.customerSpouseThirdSisterThirdName);
          localStorage.setItem('customerSpouseThirdSisterSurname',response.customerSpouseThirdSisterSurname);
          localStorage.setItem('customerSpouseThirdSisterJob',response.customerSpouseThirdSisterJob);
          localStorage.setItem('customerSpouseThirdSisterBirthDay',response.customerSpouseThirdSisterBirthDay);
          localStorage.setItem('customerSpouseThirdSisterResidenceAddress',response.customerSpouseThirdSisterResidenceAddress);
          localStorage.setItem('customerSpouseThirdSisterPostalCode',response.customerSpouseThirdSisterPostalCode);
          localStorage.setItem('customerSpouseThirdSisterPhoneNumber',response.customerSpouseThirdSisterPhoneNumber);
          localStorage.setItem('customerSpouseThirdSisterEducation',response.customerSpouseThirdSisterEducation);
          localStorage.setItem('customerSpouseThirdSisterFinancialAbility',response.customerSpouseThirdSisterFinancialAbility);
          localStorage.setItem('customerSpouseThirdSisterDateOfDivorce',response.customerSpouseThirdSisterDateOfDivorce);
          localStorage.setItem('customerSpouseThirdSisterDateOfDeath',response.customerSpouseThirdSisterDateOfDeath);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberOne',response.customerSpouseThirdSistersForeignTripNumberOne);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberTwo',response.customerSpouseThirdSistersForeignTripNumberTwo);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberThree',response.customerSpouseThirdSistersForeignTripNumberThree);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFour',response.customerSpouseThirdSistersForeignTripNumberFour);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFive',response.customerSpouseThirdSistersForeignTripNumberFive);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSix',response.customerSpouseThirdSistersForeignTripNumberSix);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSeven',response.customerSpouseThirdSistersForeignTripNumberSeven);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberEight',response.customerSpouseThirdSistersForeignTripNumberEight);
          localStorage.setItem('customerSpouseThirdSistersForeignTripNumberNine',response.customerSpouseThirdSistersForeignTripNumberNine);
  
          localStorage.setItem('EvFormUpdate',true);
          
          console.log(localStorage.getItem('EvFormUpdate'));
      
          values.FatherName = localStorage.getItem('customerSpouseFatherFirstName');
          values.FatherFamily = localStorage.getItem('customerSpouseFatherSurname');
          values.FatherJob = localStorage.getItem('customerSpouseFatherJob');
          values.FathersResidenceAddress = localStorage.getItem('customerSpouseFathersResidenceAddress');
          values.FatherPostalCode = localStorage.getItem('customerSpouseFatherPostalCode');
          values.FatherPhoneCall = localStorage.getItem('customerSpouseFatherPhoneNumber');
          values.FatherEducation = localStorage.getItem('customerSpouseFatherEducation');
          values.FatherFinancialAbility = localStorage.getItem('customerSpouseFatherFinancialAbility');
          values.FathersForeignTrip1 = localStorage.getItem('customerSpouseFathersForeignTripNumberOne');
          values.FathersForeignTrip2 = localStorage.getItem('customerSpouseFathersForeignTripNumberTwo');
          values.FathersForeignTrip3 = localStorage.getItem('customerSpouseFathersForeignTripNumberThree');
          values.FathersForeignTrip4 = localStorage.getItem('customerSpouseFathersForeignTripNumberFour');
          values.FathersForeignTrip5 = localStorage.getItem('customerSpouseFathersForeignTripNumberFive');
          values.FathersForeignTrip6 = localStorage.getItem('customerSpouseFathersForeignTripNumberSix');
          values.FathersForeignTrip7 = localStorage.getItem('customerSpouseFathersForeignTripNumberSeven');
          values.FathersForeignTrip8 = localStorage.getItem('customerSpouseFathersForeignTripNumberEight');
          values.FathersForeignTrip9 = localStorage.getItem('customerSpouseFathersForeignTripNumberNine');
    
          values.MotherName = localStorage.getItem('customerSpouseMotherFirstName');
          values.MotherFamily = localStorage.getItem('customerSpouseMotherSurname');
          values.MotherJob = localStorage.getItem('customerSpouseMotherJob');
          values.MothersResidenceAddress = localStorage.getItem('customerSpouseMotherResidenceAddress');
          values.MotherPostalCode = localStorage.getItem('customerSpouseMotherPostalCode');
          values.MotherPhoneCall = localStorage.getItem('customerSpouseMotherPhoneNumber');
          values.MotherEducation = localStorage.getItem('customerSpouseMotherEducation');
          values.MotherFinancialAbility = localStorage.getItem('customerSpouseMotherFinancialAbility');
          values.MothersForeignTrip1 = localStorage.getItem('customerSpouseMothersForeignTripNumberOne');
          values.MothersForeignTrip2 = localStorage.getItem('customerSpouseMothersForeignTripNumberTwo');
          values.MothersForeignTrip3 = localStorage.getItem('customerSpouseMothersForeignTripNumberThree');
          values.MothersForeignTrip4 = localStorage.getItem('customerSpouseMothersForeignTripNumberFour');
          values.MothersForeignTrip5 = localStorage.getItem('customerSpouseMothersForeignTripNumberFive');
          values.MothersForeignTrip6 = localStorage.getItem('customerSpouseMothersForeignTripNumberSix');
          values.MothersForeignTrip7 = localStorage.getItem('customerSpouseMothersForeignTripNumberSeven');
          values.MothersForeignTrip8 = localStorage.getItem('customerSpouseMothersForeignTripNumberEight');
          values.MothersForeignTrip9 = localStorage.getItem('customerSpouseMothersForeignTripNumberNine');
    
          values.Brother1Name = localStorage.getItem('customerSpouseFirstBrotherFirstName');
          values.Brother1Family = localStorage.getItem('customerSpouseFirstBrotherSurname');
          values.Brother1Job = localStorage.getItem('customerSpouseFirstBrotherJob');
          values.Brother1sResidenceAddress = localStorage.getItem('customerSpouseFirstBrotherResidenceAddress');
          values.Brother1PostalCode = localStorage.getItem('customerSpouseFirstBrotherPostalCode');
          values.Brother1PhoneCall = localStorage.getItem('customerSpouseFirstBrotherPhoneNumber');
          values.Brother1Education = localStorage.getItem('customerSpouseFirstBrotherEducation');
          values.Brother1FinancialAbility = localStorage.getItem('customerSpouseFirstBrotherFinancialAbility');
          values.Brother1sForeignTrip1 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberOne');
          values.Brother1sForeignTrip2 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberTwo');
          values.Brother1sForeignTrip3 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberThree');
          values.Brother1sForeignTrip4 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberFour');
          values.Brother1sForeignTrip5 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberFive');
          values.Brother1sForeignTrip6 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberSix');
          values.Brother1sForeignTrip7 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberSeven');
          values.Brother1sForeignTrip8 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberEight');
          values.Brother1sForeignTrip9 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberNine');
    
          values.Brother2Name = localStorage.getItem('customerSpouseSecondBrotherFirstName');
          values.Brother2Family = localStorage.getItem('customerSpouseSecondBrotherSurname');
          values.Brother2Job = localStorage.getItem('customerSpouseSecondBrotherJob');
          values.Brother2sResidenceAddress = localStorage.getItem('customerSpouseSecondBrotherResidenceAddress');
          values.Brother2PostalCode = localStorage.getItem('customerSpouseSecondBrotherPostalCode');
          values.Brother2PhoneCall = localStorage.getItem('customerSpouseSecondBrotherPhoneNumber');
          values.Brother2Education = localStorage.getItem('customerSpouseSecondBrotherEducation');
          values.Brother2FinancialAbility = localStorage.getItem('customerSpouseSecondBrotherFinancialAbility');
          values.Brother2sForeignTrip1 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberOne');
          values.Brother2sForeignTrip2 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberTwo');
          values.Brother2sForeignTrip3 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberThree');
          values.Brother2sForeignTrip4 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberFour');
          values.Brother2sForeignTrip5 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberFive');
          values.Brother2sForeignTrip6 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberSix');
          values.Brother2sForeignTrip7 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberSeven');
          values.Brother2sForeignTrip8 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberEight');
          values.Brother2sForeignTrip9 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberNine');
    
          values.Brother3Name = localStorage.getItem('customerSpouseThirdBrotherFirstName');
          values.Brother3Family = localStorage.getItem('customerSpouseThirdBrotherSurname');
          values.Brother3Job = localStorage.getItem('customerSpouseThirdBrotherJob');
          values.Brother3sResidenceAddress = localStorage.getItem('customerSpouseThirdBrotherResidenceAddress');
          values.Brother3PostalCode = localStorage.getItem('customerSpouseThirdBrotherPostalCode');
          values.Brother3PhoneCall = localStorage.getItem('customerSpouseThirdBrotherPhoneNumber');
          values.Brother3Education = localStorage.getItem('customerSpouseThirdBrotherEducation');
          values.Brother3FinancialAbility = localStorage.getItem('customerSpouseThirdBrotherFinancialAbility');
          values.Brother3sForeignTrip1 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberOne');
          values.Brother3sForeignTrip2 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberTwo');
          values.Brother3sForeignTrip3 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberThree');
          values.Brother3sForeignTrip4 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberFour');
          values.Brother3sForeignTrip5 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberFive');
          values.Brother3sForeignTrip6 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberSix');
          values.Brother3sForeignTrip7 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberSeven');
          values.Brother3sForeignTrip8 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberEight');
          values.Brother3sForeignTrip9 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberNine');
    
          values.Sister1Name = localStorage.getItem('customerSpouseFirstSisterFirstName');
          values.Sister1Family = localStorage.getItem('customerSpouseFirstSisterSurname');
          values.Sister1Job = localStorage.getItem('customerSpouseFirstSisterJob');
          values.Sister1sResidenceAddress = localStorage.getItem('customerSpouseFirstSisterResidenceAddress');
          values.Sister1PostalCode = localStorage.getItem('customerSpouseFirstSisterPostalCode');
          values.Sister1PhoneCall = localStorage.getItem('customerSpouseFirstSisterPhoneNumber');
          values.Sister1Education = localStorage.getItem('customerSpouseFirstSisterEducation');
          values.Sister1FinancialAbility = localStorage.getItem('customerSpouseFirstSisterFinancialAbility');
          values.Sister1sForeignTrip1 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberOne');
          values.Sister1sForeignTrip2 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberTwo');
          values.Sister1sForeignTrip3 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberThree');
          values.Sister1sForeignTrip4 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberFour');
          values.Sister1sForeignTrip5 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberFive');
          values.Sister1sForeignTrip6 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberSix');
          values.Sister1sForeignTrip7 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberSeven');
          values.Sister1sForeignTrip8 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberEight');
          values.Sister1sForeignTrip9 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberNine');
    
          values.Sister2Name = localStorage.getItem('customerSpouseSecondSisterSecondName');
          values.Sister2Family = localStorage.getItem('customerSpouseSecondSisterSurname');
          values.Sister2Job = localStorage.getItem('customerSpouseSecondSisterJob');
          values.Sister2sResidenceAddress = localStorage.getItem('customerSpouseSecondSisterResidenceAddress');
          values.Sister2PostalCode = localStorage.getItem('customerSpouseSecondSisterPostalCode');
          values.Sister2PhoneCall = localStorage.getItem('customerSpouseSecondSisterPhoneNumber');
          values.Sister2Education = localStorage.getItem('customerSpouseSecondSisterEducation');
          values.Sister2FinancialAbility = localStorage.getItem('customerSpouseSecondSisterFinancialAbility');
          values.Sister2sForeignTrip1 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberOne');
          values.Sister2sForeignTrip2 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberTwo');
          values.Sister2sForeignTrip3 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberThree');
          values.Sister2sForeignTrip4 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberFour');
          values.Sister2sForeignTrip5 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberFive');
          values.Sister2sForeignTrip6 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberSix');
          values.Sister2sForeignTrip7 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberSeven');
          values.Sister2sForeignTrip8 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberEight');
          values.Sister2sForeignTrip9 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberNine');
    
          values.Sister3Name = localStorage.getItem('customerSpouseThirdSisterThirdName');
          values.Sister3Family = localStorage.getItem('customerSpouseThirdSisterSurname');
          values.Sister3Job = localStorage.getItem('customerSpouseThirdSisterJob');
          values.Sister3sResidenceAddress = localStorage.getItem('customerSpouseThirdSisterResidenceAddress');
          values.Sister3PostalCode = localStorage.getItem('customerSpouseThirdSisterPostalCode');
          values.Sister3PhoneCall = localStorage.getItem('customerSpouseThirdSisterPhoneNumber');
          values.Sister3Education = localStorage.getItem('customerSpouseThirdSisterEducation');
          values.Sister3FinancialAbility = localStorage.getItem('customerSpouseThirdSisterFinancialAbility');
          values.Sister3sForeignTrip1 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberOne');
          values.Sister3sForeignTrip2 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberTwo');
          values.Sister3sForeignTrip3 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberThree');
          values.Sister3sForeignTrip4 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberFour');
          values.Sister3sForeignTrip5 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberFive');
          values.Sister3sForeignTrip6 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberSix');
          values.Sister3sForeignTrip7 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberSeven');
          values.Sister3sForeignTrip8 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberEight');
          values.Sister3sForeignTrip9 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberNine');
    
          setFatherBirthDay(localStorage.getItem('customerSpouseFatherBirthDay'));
          setFatherDateofDivorce(localStorage.getItem('customerSpouseFatherDateOfDivorce'));
          setFatherDateofDeath(localStorage.getItem('customerSpouseFatherDateOfDeath'));
    
          setMotherBirthDay(localStorage.getItem('customerSpouseMotherBirthDay'));
          setMotherDateofDivorce(localStorage.getItem('customerSpouseMotherDateOfDivorce'));
          setMotherDateofDeath(localStorage.getItem('customerSpouseMotherDateOfDeath'));
    
          setBrother1BirthDay(localStorage.getItem('customerSpouseFirstBrotherBirthDay'));
          setBrother1DateofDivorce(localStorage.getItem('customerSpouseFirstBrotherDateOfDivorce'));
          setBrother1DateofDeath(localStorage.getItem('customerSpouseFirstBrotherDateOfDeath'));
    
          setBrother2BirthDay(localStorage.getItem('customerSpouseSecondBrotherBirthDay'));
          setBrother2DateofDivorce(localStorage.getItem('customerSpouseSecondBrotherDateOfDivorce'));
          setBrother2DateofDeath(localStorage.getItem('customerSpouseSecondBrotherDateOfDeath'));
    
          setBrother3BirthDay(localStorage.getItem('customerSpouseThirdBrotherBirthDay'));
          setBrother3DateofDivorce(localStorage.getItem('customerSpouseThirdBrotherDateOfDivorce'));
          setBrother3DateofDeath(localStorage.getItem('customerSpouseThirdBrotherDateOfDeath'));
    
          setSister1BirthDay(localStorage.getItem('customerSpouseFirstSisterBirthDay'));
          setSister1DateofDivorce(localStorage.getItem('customerSpouseFirstSisterDateOfDivorce'));
          setSister1DateofDeath(localStorage.getItem('customerSpouseFirstSisterDateOfDeath'));
    
          setSister2BirthDay(localStorage.getItem('customerSpouseSecondSisterBirthDay'));
          setSister2DateofDivorce(localStorage.getItem('customerSpouseSecondSisterDateOfDivorce'));
          setSister2DateofDeath(localStorage.getItem('customerSpouseSecondSisterDateOfDeath'));
    
          setSister3BirthDay(localStorage.getItem('customerSpouseThirdSisterBirthDay'));
          setSister3DateofDivorce(localStorage.getItem('customerSpouseThirdSisterDateOfDivorce'));
          setSister3DateofDeath(localStorage.getItem('customerSpouseThirdSisterDateOfDeath'));
     
      // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');
  
      
      
  
  
  
  
          if(response.statusCode==400 || response.statusCode==404)
          {
            console.log(response.statusMessage);
            console.log(values.EnglishCheckBox);
           //  setFormError(true);
           //  setHelpStatusMessage(response.statusMessage);
           //  setIspending(false);
          }
          else{
           //  setHelpStatusMessage("");
           //  setIspending(false);
           //  history.push('/regstep6');
          }
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
        })
    
    }
}
    }

    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({

          FatherName: '',
          FatherFamily: '',
          FatherJob:'',
          FathersResidenceAddress:'',
          FatherPostalCode: '',
          FatherPhoneCall: '',
          FatherEducation: '',
          FatherFinancialAbility:"",
          FathersForeignTrip1: '',
          FathersForeignTrip2: '',
          FathersForeignTrip3: '',
          FathersForeignTrip4: '',
          FathersForeignTrip5: '',
          FathersForeignTrip6: '',
          FathersForeignTrip7: '',
          FathersForeignTrip8: '',
          FathersForeignTrip9: '',

          MotherName: '',
          MotherFamily: '',
          MotherJob:'',
          MothersResidenceAddress:'',
          MotherPostalCode: '',
          MotherPhoneCall: '',
          MotherEducation: '',
          MotherFinancialAbility:"",
          MothersForeignTrip1: '',
          MothersForeignTrip2: '',
          MothersForeignTrip3: '',
          MothersForeignTrip4: '',
          MothersForeignTrip5: '',
          MothersForeignTrip6: '',
          MothersForeignTrip7: '',
          MothersForeignTrip8: '',
          MothersForeignTrip9: '',

          Brother1Name: '',
          Brother1Family: '',
          Brother1Job:'',
          Brother1sResidenceAddress:'',
          Brother1PostalCode: '',
          Brother1PhoneCall: '',
          Brother1Education: '',
          Brother1FinancialAbility:"",
          Brother1sForeignTrip1: '',
          Brother1sForeignTrip2: '',
          Brother1sForeignTrip3: '',
          Brother1sForeignTrip4: '',
          Brother1sForeignTrip5: '',
          Brother1sForeignTrip6: '',
          Brother1sForeignTrip7: '',
          Brother1sForeignTrip8: '',
          Brother1sForeignTrip9: '',

          Brother2Name: '',
          Brother2Family: '',
          Brother2Job:'',
          Brother2sResidenceAddress:'',
          Brother2PostalCode: '',
          Brother2PhoneCall: '',
          Brother2Education: '',
          Brother2FinancialAbility:"",
          Brother2sForeignTrip1: '',
          Brother2sForeignTrip2: '',
          Brother2sForeignTrip3: '',
          Brother2sForeignTrip4: '',
          Brother2sForeignTrip5: '',
          Brother2sForeignTrip6: '',
          Brother2sForeignTrip7: '',
          Brother2sForeignTrip8: '',
          Brother2sForeignTrip9: '',

          Brother3Name: '',
          Brother3Family: '',
          Brother3Job:'',
          Brother3sResidenceAddress:'',
          Brother3PostalCode: '',
          Brother3PhoneCall: '',
          Brother3Education: '',
          Brother3FinancialAbility:"",
          Brother3sForeignTrip1: '',
          Brother3sForeignTrip2: '',
          Brother3sForeignTrip3: '',
          Brother3sForeignTrip4: '',
          Brother3sForeignTrip5: '',
          Brother3sForeignTrip6: '',
          Brother3sForeignTrip7: '',
          Brother3sForeignTrip8: '',
          Brother3sForeignTrip9: '',

          Sister1Name: '',
          Sister1Family: '',
          Sister1Job:'',
          Sister1sResidenceAddress:'',
          Sister1PostalCode: '',
          Sister1PhoneCall: '',
          Sister1Education: '',
          Sister1FinancialAbility:"",
          Sister1sForeignTrip1: '',
          Sister1sForeignTrip2: '',
          Sister1sForeignTrip3: '',
          Sister1sForeignTrip4: '',
          Sister1sForeignTrip5: '',
          Sister1sForeignTrip6: '',
          Sister1sForeignTrip7: '',
          Sister1sForeignTrip8: '',
          Sister1sForeignTrip9: '',

          Sister2Name: '',
          Sister2Family: '',
          Sister2Job:'',
          Sister2sResidenceAddress:'',
          Sister2PostalCode: '',
          Sister2PhoneCall: '',
          Sister2Education: '',
          Sister2FinancialAbility:"",
          Sister2sForeignTrip1: '',
          Sister2sForeignTrip2: '',
          Sister2sForeignTrip3: '',
          Sister2sForeignTrip4: '',
          Sister2sForeignTrip5: '',
          Sister2sForeignTrip6: '',
          Sister2sForeignTrip7: '',
          Sister2sForeignTrip8: '',
          Sister2sForeignTrip9: '',

          Sister3Name: '',
          Sister3Family: '',
          Sister3Job:'',
          Sister3sResidenceAddress:'',
          Sister3PostalCode: '',
          Sister3PhoneCall: '',
          Sister3Education: '',
          Sister3FinancialAbility:"",
          Sister3sForeignTrip1: '',
          Sister3sForeignTrip2: '',
          Sister3sForeignTrip3: '',
          Sister3sForeignTrip4: '',
          Sister3sForeignTrip5: '',
          Sister3sForeignTrip6: '',
          Sister3sForeignTrip7: '',
          Sister3sForeignTrip8: '',
          Sister3sForeignTrip9: '',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentFamilyInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();


  localStorage.setItem('customerFatherFirstName',response.customerFatherFirstName);
  localStorage.setItem('customerFatherSurname',response.customerFatherSurname);
  localStorage.setItem('customerFatherJob',response.customerFatherJob);
  localStorage.setItem('customerFatherBirthDay',response.customerFatherBirthDay);
  localStorage.setItem('customerFathersResidenceAddress',response.customerFathersResidenceAddress);
  localStorage.setItem('customerFatherPostalCode',response.customerFatherPostalCode);
  localStorage.setItem('customerFatherPhoneNumber',response.customerFatherPhoneNumber);
  localStorage.setItem('customerFatherEducation',response.customerFatherEducation);
  localStorage.setItem('customerFatherFinancialAbility',response.customerFatherFinancialAbility);
  localStorage.setItem('customerFatherDateOfDivorce',response.customerFatherDateOfDivorce);
  localStorage.setItem('customerFatherDateOfDeath',response.customerFatherDateOfDeath);
  localStorage.setItem('customerFathersForeignTripNumberOne',response.customerFathersForeignTripNumberOne);
  localStorage.setItem('customerFathersForeignTripNumberTwo',response.customerFathersForeignTripNumberTwo);
  localStorage.setItem('customerFathersForeignTripNumberThree',response.customerFathersForeignTripNumberThree);
  localStorage.setItem('customerFathersForeignTripNumberFour',response.customerFathersForeignTripNumberFour);
  localStorage.setItem('customerFathersForeignTripNumberFive',response.customerFathersForeignTripNumberFive);
  localStorage.setItem('customerFathersForeignTripNumberSix',response.customerFathersForeignTripNumberSix);
  localStorage.setItem('customerFathersForeignTripNumberSeven',response.customerFathersForeignTripNumberSeven);
  localStorage.setItem('customerFathersForeignTripNumberEight',response.customerFathersForeignTripNumberEight);
  localStorage.setItem('customerFathersForeignTripNumberNine',response.customerFathersForeignTripNumberNine);
  localStorage.setItem('customerMotherFirstName',response.customerMotherFirstName);
  localStorage.setItem('customerMotherSurname',response.customerMotherSurname);
  localStorage.setItem('customerMotherJob',response.customerMotherJob);
  localStorage.setItem('customerMotherBirthDay',response.customerMotherBirthDay);
  localStorage.setItem('customerMotherResidenceAddress',response.customerMotherResidenceAddress);
  localStorage.setItem('customerMotherPostalCode',response.customerMotherPostalCode);
  localStorage.setItem('customerMotherPhoneNumber',response.customerMotherPhoneNumber);
  localStorage.setItem('customerMotherEducation',response.customerMotherEducation);
  localStorage.setItem('customerMotherFinancialAbility',response.customerMotherFinancialAbility);
  localStorage.setItem('customerMotherDateOfDivorce',response.customerMotherDateOfDivorce);
  localStorage.setItem('customerMotherDateOfDeath',response.customerMotherDateOfDeath);
  localStorage.setItem('customerMothersForeignTripNumberOne',response.customerMothersForeignTripNumberOne);
  localStorage.setItem('customerMothersForeignTripNumberTwo',response.customerMothersForeignTripNumberTwo);
  localStorage.setItem('customerMothersForeignTripNumberThree',response.customerMothersForeignTripNumberThree);
  localStorage.setItem('customerMothersForeignTripNumberFour',response.customerMothersForeignTripNumberFour);
  localStorage.setItem('customerMothersForeignTripNumberFive',response.customerMothersForeignTripNumberFive);
  localStorage.setItem('customerMothersForeignTripNumberSix',response.customerMothersForeignTripNumberSix);
  localStorage.setItem('customerMothersForeignTripNumberSeven',response.customerMothersForeignTripNumberSeven);
  localStorage.setItem('customerMothersForeignTripNumberEight',response.customerMothersForeignTripNumberEight);
  localStorage.setItem('customerMothersForeignTripNumberNine',response.customerMothersForeignTripNumberNine);
  localStorage.setItem('customerFirstBrotherFirstName',response.customerFirstBrotherFirstName);
  localStorage.setItem('customerFirstBrotherSurname',response.customerFirstBrotherSurname);
  localStorage.setItem('customerFirstBrotherJob',response.customerFirstBrotherJob);
  localStorage.setItem('customerFirstBrotherBirthDay',response.customerFirstBrotherBirthDay);
  localStorage.setItem('customerFirstBrotherResidenceAddress',response.customerFirstBrotherResidenceAddress);
  localStorage.setItem('customerFirstBrotherPostalCode',response.customerFirstBrotherPostalCode);
  localStorage.setItem('customerFirstBrotherPhoneNumber',response.customerFirstBrotherPhoneNumber);
  localStorage.setItem('customerFirstBrotherEducation',response.customerFirstBrotherEducation);
  localStorage.setItem('customerFirstBrotherFinancialAbility',response.customerFirstBrotherFinancialAbility);
  localStorage.setItem('customerFirstBrotherDateOfDivorce',response.customerFirstBrotherDateOfDivorce);
  localStorage.setItem('customerFirstBrotherDateOfDeath',response.customerFirstBrotherDateOfDeath);
  localStorage.setItem('customerFirstBrothersForeignTripNumberOne',response.customerFirstBrothersForeignTripNumberOne);
  localStorage.setItem('customerFirstBrothersForeignTripNumberTwo',response.customerFirstBrothersForeignTripNumberTwo);
  localStorage.setItem('customerFirstBrothersForeignTripNumberThree',response.customerFirstBrothersForeignTripNumberThree);
  localStorage.setItem('customerFirstBrothersForeignTripNumberFour',response.customerFirstBrothersForeignTripNumberFour);
  localStorage.setItem('customerFirstBrothersForeignTripNumberFive',response.customerFirstBrothersForeignTripNumberFive);
  localStorage.setItem('customerFirstBrothersForeignTripNumberSix',response.customerFirstBrothersForeignTripNumberSix);
  localStorage.setItem('customerFirstBrothersForeignTripNumberSeven',response.customerFirstBrothersForeignTripNumberSeven);
  localStorage.setItem('customerFirstBrothersForeignTripNumberEight',response.customerFirstBrothersForeignTripNumberEight);
  localStorage.setItem('customerFirstBrothersForeignTripNumberNine',response.customerFirstBrothersForeignTripNumberNine);
  localStorage.setItem('customerSecondBrotherFirstName',response.customerSecondBrotherFirstName);
  localStorage.setItem('customerSecondBrotherSurname',response.customerSecondBrotherSurname);
  localStorage.setItem('customerSecondBrotherJob',response.customerSecondBrotherJob);
  localStorage.setItem('customerSecondBrotherBirthDay',response.customerSecondBrotherBirthDay);
  localStorage.setItem('customerSecondBrotherResidenceAddress',response.customerSecondBrotherResidenceAddress);
  localStorage.setItem('customerSecondBrotherPostalCode',response.customerSecondBrotherPostalCode);
  localStorage.setItem('customerSecondBrotherPhoneNumber',response.customerSecondBrotherPhoneNumber);
  localStorage.setItem('customerSecondBrotherEducation',response.customerSecondBrotherEducation);
  localStorage.setItem('customerSecondBrotherFinancialAbility',response.customerSecondBrotherFinancialAbility);
  localStorage.setItem('customerSecondBrotherDateOfDivorce',response.customerSecondBrotherDateOfDivorce);
  localStorage.setItem('customerSecondBrotherDateOfDeath',response.customerSecondBrotherDateOfDeath);
  localStorage.setItem('customerSecondBrothersForeignTripNumberOne',response.customerSecondBrothersForeignTripNumberOne);
  localStorage.setItem('customerSecondBrothersForeignTripNumberTwo',response.customerSecondBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSecondBrothersForeignTripNumberThree',response.customerSecondBrothersForeignTripNumberThree);
  localStorage.setItem('customerSecondBrothersForeignTripNumberFour',response.customerSecondBrothersForeignTripNumberFour);
  localStorage.setItem('customerSecondBrothersForeignTripNumberFive',response.customerSecondBrothersForeignTripNumberFive);
  localStorage.setItem('customerSecondBrothersForeignTripNumberSix',response.customerSecondBrothersForeignTripNumberSix);
  localStorage.setItem('customerSecondBrothersForeignTripNumberSeven',response.customerSecondBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSecondBrothersForeignTripNumberEight',response.customerSecondBrothersForeignTripNumberEight);
  localStorage.setItem('customerSecondBrothersForeignTripNumberNine',response.customerSecondBrothersForeignTripNumberNine);
  localStorage.setItem('customerThirdBrotherFirstName',response.customerThirdBrotherFirstName);
  localStorage.setItem('customerThirdBrotherSurname',response.customerThirdBrotherSurname);
  localStorage.setItem('customerThirdBrotherJob',response.customerThirdBrotherJob);
  localStorage.setItem('customerThirdBrotherBirthDay',response.customerThirdBrotherBirthDay);
  localStorage.setItem('customerThirdBrotherResidenceAddress',response.customerThirdBrotherResidenceAddress);
  localStorage.setItem('customerThirdBrotherPostalCode',response.customerThirdBrotherPostalCode);
  localStorage.setItem('customerThirdBrotherPhoneNumber',response.customerThirdBrotherPhoneNumber);
  localStorage.setItem('customerThirdBrotherEducation',response.customerThirdBrotherEducation);
  localStorage.setItem('customerThirdBrotherFinancialAbility',response.customerThirdBrotherFinancialAbility);
  localStorage.setItem('customerThirdBrotherDateOfDivorce',response.customerThirdBrotherDateOfDivorce);
  localStorage.setItem('customerThirdBrotherDateOfDeath',response.customerThirdBrotherDateOfDeath);
  localStorage.setItem('customerThirdBrothersForeignTripNumberOne',response.customerThirdBrothersForeignTripNumberOne);
  localStorage.setItem('customerThirdBrothersForeignTripNumberTwo',response.customerThirdBrothersForeignTripNumberTwo);
  localStorage.setItem('customerThirdBrothersForeignTripNumberThree',response.customerThirdBrothersForeignTripNumberThree);
  localStorage.setItem('customerThirdBrothersForeignTripNumberFour',response.customerThirdBrothersForeignTripNumberFour);
  localStorage.setItem('customerThirdBrothersForeignTripNumberFive',response.customerThirdBrothersForeignTripNumberFive);
  localStorage.setItem('customerThirdBrothersForeignTripNumberSix',response.customerThirdBrothersForeignTripNumberSix);
  localStorage.setItem('customerThirdBrothersForeignTripNumberSeven',response.customerThirdBrothersForeignTripNumberSeven);
  localStorage.setItem('customerThirdBrothersForeignTripNumberEight',response.customerThirdBrothersForeignTripNumberEight);
  localStorage.setItem('customerThirdBrothersForeignTripNumberNine',response.customerThirdBrothersForeignTripNumberNine);
  localStorage.setItem('customerFirstSisterFirstName',response.customerFirstSisterFirstName);
  localStorage.setItem('customerFirstSisterSurname',response.customerFirstSisterSurname);
  localStorage.setItem('customerFirstSisterJob',response.customerFirstSisterJob);
  localStorage.setItem('customerFirstSisterBirthDay',response.customerFirstSisterBirthDay);
  localStorage.setItem('customerFirstSisterResidenceAddress',response.customerFirstSisterResidenceAddress);
  localStorage.setItem('customerFirstSisterPostalCode',response.customerFirstSisterPostalCode);
  localStorage.setItem('customerFirstSisterPhoneNumber',response.customerFirstSisterPhoneNumber);
  localStorage.setItem('customerFirstSisterEducation',response.customerFirstSisterEducation);
  localStorage.setItem('customerFirstSisterFinancialAbility',response.customerFirstSisterFinancialAbility);
  localStorage.setItem('customerFirstSisterDateOfDivorce',response.customerFirstSisterDateOfDivorce);
  localStorage.setItem('customerFirstSisterDateOfDeath',response.customerFirstSisterDateOfDeath);
  localStorage.setItem('customerFirstSistersForeignTripNumberOne',response.customerFirstSistersForeignTripNumberOne);
  localStorage.setItem('customerFirstSistersForeignTripNumberTwo',response.customerFirstSistersForeignTripNumberTwo);
  localStorage.setItem('customerFirstSistersForeignTripNumberThree',response.customerFirstSistersForeignTripNumberThree);
  localStorage.setItem('customerFirstSistersForeignTripNumberFour',response.customerFirstSistersForeignTripNumberFour);
  localStorage.setItem('customerFirstSistersForeignTripNumberFive',response.customerFirstSistersForeignTripNumberFive);
  localStorage.setItem('customerFirstSistersForeignTripNumberSix',response.customerFirstSistersForeignTripNumberSix);
  localStorage.setItem('customerFirstSistersForeignTripNumberSeven',response.customerFirstSistersForeignTripNumberSeven);
  localStorage.setItem('customerFirstSistersForeignTripNumberEight',response.customerFirstSistersForeignTripNumberEight);
  localStorage.setItem('customerFirstSistersForeignTripNumberNine',response.customerFirstSistersForeignTripNumberNine);
  localStorage.setItem('customerSecondSisterSecondName',response.customerSecondSisterSecondName);
  localStorage.setItem('customerSecondSisterSurname',response.customerSecondSisterSurname);
  localStorage.setItem('customerSecondSisterJob',response.customerSecondSisterJob);
  localStorage.setItem('customerSecondSisterBirthDay',response.customerSecondSisterBirthDay);
  localStorage.setItem('customerSecondSisterResidenceAddress',response.customerSecondSisterResidenceAddress);
  localStorage.setItem('customerSecondSisterPostalCode',response.customerSecondSisterPostalCode);
  localStorage.setItem('customerSecondSisterPhoneNumber',response.customerSecondSisterPhoneNumber);
  localStorage.setItem('customerSecondSisterEducation',response.customerSecondSisterEducation);
  localStorage.setItem('customerSecondSisterFinancialAbility',response.customerSecondSisterFinancialAbility);
  localStorage.setItem('customerSecondSisterDateOfDivorce',response.customerSecondSisterDateOfDivorce);
  localStorage.setItem('customerSecondSisterDateOfDeath',response.customerSecondSisterDateOfDeath);
  localStorage.setItem('customerSecondSistersForeignTripNumberOne',response.customerSecondSistersForeignTripNumberOne);
  localStorage.setItem('customerSecondSistersForeignTripNumberTwo',response.customerSecondSistersForeignTripNumberTwo);
  localStorage.setItem('customerSecondSistersForeignTripNumberThree',response.customerSecondSistersForeignTripNumberThree);
  localStorage.setItem('customerSecondSistersForeignTripNumberFour',response.customerSecondSistersForeignTripNumberFour);
  localStorage.setItem('customerSecondSistersForeignTripNumberFive',response.customerSecondSistersForeignTripNumberFive);
  localStorage.setItem('customerSecondSistersForeignTripNumberSix',response.customerSecondSistersForeignTripNumberSix);
  localStorage.setItem('customerSecondSistersForeignTripNumberSeven',response.customerSecondSistersForeignTripNumberSeven);
  localStorage.setItem('customerSecondSistersForeignTripNumberEight',response.customerSecondSistersForeignTripNumberEight);
  localStorage.setItem('customerSecondSistersForeignTripNumberNine',response.customerSecondSistersForeignTripNumberNine);
  localStorage.setItem('customerThirdSisterThirdName',response.customerThirdSisterThirdName);
  localStorage.setItem('customerThirdSisterSurname',response.customerThirdSisterSurname);
  localStorage.setItem('customerThirdSisterJob',response.customerThirdSisterJob);
  localStorage.setItem('customerThirdSisterBirthDay',response.customerThirdSisterBirthDay);
  localStorage.setItem('customerThirdSisterResidenceAddress',response.customerThirdSisterResidenceAddress);
  localStorage.setItem('customerThirdSisterPostalCode',response.customerThirdSisterPostalCode);
  localStorage.setItem('customerThirdSisterPhoneNumber',response.customerThirdSisterPhoneNumber);
  localStorage.setItem('customerThirdSisterEducation',response.customerThirdSisterEducation);
  localStorage.setItem('customerThirdSisterFinancialAbility',response.customerThirdSisterFinancialAbility);
  localStorage.setItem('customerThirdSisterDateOfDivorce',response.customerThirdSisterDateOfDivorce);
  localStorage.setItem('customerThirdSisterDateOfDeath',response.customerThirdSisterDateOfDeath);
  localStorage.setItem('customerThirdSistersForeignTripNumberOne',response.customerThirdSistersForeignTripNumberOne);
  localStorage.setItem('customerThirdSistersForeignTripNumberTwo',response.customerThirdSistersForeignTripNumberTwo);
  localStorage.setItem('customerThirdSistersForeignTripNumberThree',response.customerThirdSistersForeignTripNumberThree);
  localStorage.setItem('customerThirdSistersForeignTripNumberFour',response.customerThirdSistersForeignTripNumberFour);
  localStorage.setItem('customerThirdSistersForeignTripNumberFive',response.customerThirdSistersForeignTripNumberFive);
  localStorage.setItem('customerThirdSistersForeignTripNumberSix',response.customerThirdSistersForeignTripNumberSix);
  localStorage.setItem('customerThirdSistersForeignTripNumberSeven',response.customerThirdSistersForeignTripNumberSeven);
  localStorage.setItem('customerThirdSistersForeignTripNumberEight',response.customerThirdSistersForeignTripNumberEight);
  localStorage.setItem('customerThirdSistersForeignTripNumberNine',response.customerThirdSistersForeignTripNumberNine);

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentFamilyInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      
  localStorage.setItem('customerFatherFirstName',response.customerFatherFirstName);
  localStorage.setItem('customerFatherSurname',response.customerFatherSurname);
  localStorage.setItem('customerFatherJob',response.customerFatherJob);
  localStorage.setItem('customerFatherBirthDay',response.customerFatherBirthDay);
  localStorage.setItem('customerFathersResidenceAddress',response.customerFathersResidenceAddress);
  localStorage.setItem('customerFatherPostalCode',response.customerFatherPostalCode);
  localStorage.setItem('customerFatherPhoneNumber',response.customerFatherPhoneNumber);
  localStorage.setItem('customerFatherEducation',response.customerFatherEducation);
  localStorage.setItem('customerFatherFinancialAbility',response.customerFatherFinancialAbility);
  localStorage.setItem('customerFatherDateOfDivorce',response.customerFatherDateOfDivorce);
  localStorage.setItem('customerFatherDateOfDeath',response.customerFatherDateOfDeath);
  localStorage.setItem('customerFathersForeignTripNumberOne',response.customerFathersForeignTripNumberOne);
  localStorage.setItem('customerFathersForeignTripNumberTwo',response.customerFathersForeignTripNumberTwo);
  localStorage.setItem('customerFathersForeignTripNumberThree',response.customerFathersForeignTripNumberThree);
  localStorage.setItem('customerFathersForeignTripNumberFour',response.customerFathersForeignTripNumberFour);
  localStorage.setItem('customerFathersForeignTripNumberFive',response.customerFathersForeignTripNumberFive);
  localStorage.setItem('customerFathersForeignTripNumberSix',response.customerFathersForeignTripNumberSix);
  localStorage.setItem('customerFathersForeignTripNumberSeven',response.customerFathersForeignTripNumberSeven);
  localStorage.setItem('customerFathersForeignTripNumberEight',response.customerFathersForeignTripNumberEight);
  localStorage.setItem('customerFathersForeignTripNumberNine',response.customerFathersForeignTripNumberNine);
  localStorage.setItem('customerMotherFirstName',response.customerMotherFirstName);
  localStorage.setItem('customerMotherSurname',response.customerMotherSurname);
  localStorage.setItem('customerMotherJob',response.customerMotherJob);
  localStorage.setItem('customerMotherBirthDay',response.customerMotherBirthDay);
  localStorage.setItem('customerMotherResidenceAddress',response.customerMotherResidenceAddress);
  localStorage.setItem('customerMotherPostalCode',response.customerMotherPostalCode);
  localStorage.setItem('customerMotherPhoneNumber',response.customerMotherPhoneNumber);
  localStorage.setItem('customerMotherEducation',response.customerMotherEducation);
  localStorage.setItem('customerMotherFinancialAbility',response.customerMotherFinancialAbility);
  localStorage.setItem('customerMotherDateOfDivorce',response.customerMotherDateOfDivorce);
  localStorage.setItem('customerMotherDateOfDeath',response.customerMotherDateOfDeath);
  localStorage.setItem('customerMothersForeignTripNumberOne',response.customerMothersForeignTripNumberOne);
  localStorage.setItem('customerMothersForeignTripNumberTwo',response.customerMothersForeignTripNumberTwo);
  localStorage.setItem('customerMothersForeignTripNumberThree',response.customerMothersForeignTripNumberThree);
  localStorage.setItem('customerMothersForeignTripNumberFour',response.customerMothersForeignTripNumberFour);
  localStorage.setItem('customerMothersForeignTripNumberFive',response.customerMothersForeignTripNumberFive);
  localStorage.setItem('customerMothersForeignTripNumberSix',response.customerMothersForeignTripNumberSix);
  localStorage.setItem('customerMothersForeignTripNumberSeven',response.customerMothersForeignTripNumberSeven);
  localStorage.setItem('customerMothersForeignTripNumberEight',response.customerMothersForeignTripNumberEight);
  localStorage.setItem('customerMothersForeignTripNumberNine',response.customerMothersForeignTripNumberNine);
  localStorage.setItem('customerFirstBrotherFirstName',response.customerFirstBrotherFirstName);
  localStorage.setItem('customerFirstBrotherSurname',response.customerFirstBrotherSurname);
  localStorage.setItem('customerFirstBrotherJob',response.customerFirstBrotherJob);
  localStorage.setItem('customerFirstBrotherBirthDay',response.customerFirstBrotherBirthDay);
  localStorage.setItem('customerFirstBrotherResidenceAddress',response.customerFirstBrotherResidenceAddress);
  localStorage.setItem('customerFirstBrotherPostalCode',response.customerFirstBrotherPostalCode);
  localStorage.setItem('customerFirstBrotherPhoneNumber',response.customerFirstBrotherPhoneNumber);
  localStorage.setItem('customerFirstBrotherEducation',response.customerFirstBrotherEducation);
  localStorage.setItem('customerFirstBrotherFinancialAbility',response.customerFirstBrotherFinancialAbility);
  localStorage.setItem('customerFirstBrotherDateOfDivorce',response.customerFirstBrotherDateOfDivorce);
  localStorage.setItem('customerFirstBrotherDateOfDeath',response.customerFirstBrotherDateOfDeath);
  localStorage.setItem('customerFirstBrothersForeignTripNumberOne',response.customerFirstBrothersForeignTripNumberOne);
  localStorage.setItem('customerFirstBrothersForeignTripNumberTwo',response.customerFirstBrothersForeignTripNumberTwo);
  localStorage.setItem('customerFirstBrothersForeignTripNumberThree',response.customerFirstBrothersForeignTripNumberThree);
  localStorage.setItem('customerFirstBrothersForeignTripNumberFour',response.customerFirstBrothersForeignTripNumberFour);
  localStorage.setItem('customerFirstBrothersForeignTripNumberFive',response.customerFirstBrothersForeignTripNumberFive);
  localStorage.setItem('customerFirstBrothersForeignTripNumberSix',response.customerFirstBrothersForeignTripNumberSix);
  localStorage.setItem('customerFirstBrothersForeignTripNumberSeven',response.customerFirstBrothersForeignTripNumberSeven);
  localStorage.setItem('customerFirstBrothersForeignTripNumberEight',response.customerFirstBrothersForeignTripNumberEight);
  localStorage.setItem('customerFirstBrothersForeignTripNumberNine',response.customerFirstBrothersForeignTripNumberNine);
  localStorage.setItem('customerSecondBrotherFirstName',response.customerSecondBrotherFirstName);
  localStorage.setItem('customerSecondBrotherSurname',response.customerSecondBrotherSurname);
  localStorage.setItem('customerSecondBrotherJob',response.customerSecondBrotherJob);
  localStorage.setItem('customerSecondBrotherBirthDay',response.customerSecondBrotherBirthDay);
  localStorage.setItem('customerSecondBrotherResidenceAddress',response.customerSecondBrotherResidenceAddress);
  localStorage.setItem('customerSecondBrotherPostalCode',response.customerSecondBrotherPostalCode);
  localStorage.setItem('customerSecondBrotherPhoneNumber',response.customerSecondBrotherPhoneNumber);
  localStorage.setItem('customerSecondBrotherEducation',response.customerSecondBrotherEducation);
  localStorage.setItem('customerSecondBrotherFinancialAbility',response.customerSecondBrotherFinancialAbility);
  localStorage.setItem('customerSecondBrotherDateOfDivorce',response.customerSecondBrotherDateOfDivorce);
  localStorage.setItem('customerSecondBrotherDateOfDeath',response.customerSecondBrotherDateOfDeath);
  localStorage.setItem('customerSecondBrothersForeignTripNumberOne',response.customerSecondBrothersForeignTripNumberOne);
  localStorage.setItem('customerSecondBrothersForeignTripNumberTwo',response.customerSecondBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSecondBrothersForeignTripNumberThree',response.customerSecondBrothersForeignTripNumberThree);
  localStorage.setItem('customerSecondBrothersForeignTripNumberFour',response.customerSecondBrothersForeignTripNumberFour);
  localStorage.setItem('customerSecondBrothersForeignTripNumberFive',response.customerSecondBrothersForeignTripNumberFive);
  localStorage.setItem('customerSecondBrothersForeignTripNumberSix',response.customerSecondBrothersForeignTripNumberSix);
  localStorage.setItem('customerSecondBrothersForeignTripNumberSeven',response.customerSecondBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSecondBrothersForeignTripNumberEight',response.customerSecondBrothersForeignTripNumberEight);
  localStorage.setItem('customerSecondBrothersForeignTripNumberNine',response.customerSecondBrothersForeignTripNumberNine);
  localStorage.setItem('customerThirdBrotherFirstName',response.customerThirdBrotherFirstName);
  localStorage.setItem('customerThirdBrotherSurname',response.customerThirdBrotherSurname);
  localStorage.setItem('customerThirdBrotherJob',response.customerThirdBrotherJob);
  localStorage.setItem('customerThirdBrotherBirthDay',response.customerThirdBrotherBirthDay);
  localStorage.setItem('customerThirdBrotherResidenceAddress',response.customerThirdBrotherResidenceAddress);
  localStorage.setItem('customerThirdBrotherPostalCode',response.customerThirdBrotherPostalCode);
  localStorage.setItem('customerThirdBrotherPhoneNumber',response.customerThirdBrotherPhoneNumber);
  localStorage.setItem('customerThirdBrotherEducation',response.customerThirdBrotherEducation);
  localStorage.setItem('customerThirdBrotherFinancialAbility',response.customerThirdBrotherFinancialAbility);
  localStorage.setItem('customerThirdBrotherDateOfDivorce',response.customerThirdBrotherDateOfDivorce);
  localStorage.setItem('customerThirdBrotherDateOfDeath',response.customerThirdBrotherDateOfDeath);
  localStorage.setItem('customerThirdBrothersForeignTripNumberOne',response.customerThirdBrothersForeignTripNumberOne);
  localStorage.setItem('customerThirdBrothersForeignTripNumberTwo',response.customerThirdBrothersForeignTripNumberTwo);
  localStorage.setItem('customerThirdBrothersForeignTripNumberThree',response.customerThirdBrothersForeignTripNumberThree);
  localStorage.setItem('customerThirdBrothersForeignTripNumberFour',response.customerThirdBrothersForeignTripNumberFour);
  localStorage.setItem('customerThirdBrothersForeignTripNumberFive',response.customerThirdBrothersForeignTripNumberFive);
  localStorage.setItem('customerThirdBrothersForeignTripNumberSix',response.customerThirdBrothersForeignTripNumberSix);
  localStorage.setItem('customerThirdBrothersForeignTripNumberSeven',response.customerThirdBrothersForeignTripNumberSeven);
  localStorage.setItem('customerThirdBrothersForeignTripNumberEight',response.customerThirdBrothersForeignTripNumberEight);
  localStorage.setItem('customerThirdBrothersForeignTripNumberNine',response.customerThirdBrothersForeignTripNumberNine);
  localStorage.setItem('customerFirstSisterFirstName',response.customerFirstSisterFirstName);
  localStorage.setItem('customerFirstSisterSurname',response.customerFirstSisterSurname);
  localStorage.setItem('customerFirstSisterJob',response.customerFirstSisterJob);
  localStorage.setItem('customerFirstSisterBirthDay',response.customerFirstSisterBirthDay);
  localStorage.setItem('customerFirstSisterResidenceAddress',response.customerFirstSisterResidenceAddress);
  localStorage.setItem('customerFirstSisterPostalCode',response.customerFirstSisterPostalCode);
  localStorage.setItem('customerFirstSisterPhoneNumber',response.customerFirstSisterPhoneNumber);
  localStorage.setItem('customerFirstSisterEducation',response.customerFirstSisterEducation);
  localStorage.setItem('customerFirstSisterFinancialAbility',response.customerFirstSisterFinancialAbility);
  localStorage.setItem('customerFirstSisterDateOfDivorce',response.customerFirstSisterDateOfDivorce);
  localStorage.setItem('customerFirstSisterDateOfDeath',response.customerFirstSisterDateOfDeath);
  localStorage.setItem('customerFirstSistersForeignTripNumberOne',response.customerFirstSistersForeignTripNumberOne);
  localStorage.setItem('customerFirstSistersForeignTripNumberTwo',response.customerFirstSistersForeignTripNumberTwo);
  localStorage.setItem('customerFirstSistersForeignTripNumberThree',response.customerFirstSistersForeignTripNumberThree);
  localStorage.setItem('customerFirstSistersForeignTripNumberFour',response.customerFirstSistersForeignTripNumberFour);
  localStorage.setItem('customerFirstSistersForeignTripNumberFive',response.customerFirstSistersForeignTripNumberFive);
  localStorage.setItem('customerFirstSistersForeignTripNumberSix',response.customerFirstSistersForeignTripNumberSix);
  localStorage.setItem('customerFirstSistersForeignTripNumberSeven',response.customerFirstSistersForeignTripNumberSeven);
  localStorage.setItem('customerFirstSistersForeignTripNumberEight',response.customerFirstSistersForeignTripNumberEight);
  localStorage.setItem('customerFirstSistersForeignTripNumberNine',response.customerFirstSistersForeignTripNumberNine);
  localStorage.setItem('customerSecondSisterSecondName',response.customerSecondSisterSecondName);
  localStorage.setItem('customerSecondSisterSurname',response.customerSecondSisterSurname);
  localStorage.setItem('customerSecondSisterJob',response.customerSecondSisterJob);
  localStorage.setItem('customerSecondSisterBirthDay',response.customerSecondSisterBirthDay);
  localStorage.setItem('customerSecondSisterResidenceAddress',response.customerSecondSisterResidenceAddress);
  localStorage.setItem('customerSecondSisterPostalCode',response.customerSecondSisterPostalCode);
  localStorage.setItem('customerSecondSisterPhoneNumber',response.customerSecondSisterPhoneNumber);
  localStorage.setItem('customerSecondSisterEducation',response.customerSecondSisterEducation);
  localStorage.setItem('customerSecondSisterFinancialAbility',response.customerSecondSisterFinancialAbility);
  localStorage.setItem('customerSecondSisterDateOfDivorce',response.customerSecondSisterDateOfDivorce);
  localStorage.setItem('customerSecondSisterDateOfDeath',response.customerSecondSisterDateOfDeath);
  localStorage.setItem('customerSecondSistersForeignTripNumberOne',response.customerSecondSistersForeignTripNumberOne);
  localStorage.setItem('customerSecondSistersForeignTripNumberTwo',response.customerSecondSistersForeignTripNumberTwo);
  localStorage.setItem('customerSecondSistersForeignTripNumberThree',response.customerSecondSistersForeignTripNumberThree);
  localStorage.setItem('customerSecondSistersForeignTripNumberFour',response.customerSecondSistersForeignTripNumberFour);
  localStorage.setItem('customerSecondSistersForeignTripNumberFive',response.customerSecondSistersForeignTripNumberFive);
  localStorage.setItem('customerSecondSistersForeignTripNumberSix',response.customerSecondSistersForeignTripNumberSix);
  localStorage.setItem('customerSecondSistersForeignTripNumberSeven',response.customerSecondSistersForeignTripNumberSeven);
  localStorage.setItem('customerSecondSistersForeignTripNumberEight',response.customerSecondSistersForeignTripNumberEight);
  localStorage.setItem('customerSecondSistersForeignTripNumberNine',response.customerSecondSistersForeignTripNumberNine);
  localStorage.setItem('customerThirdSisterThirdName',response.customerThirdSisterThirdName);
  localStorage.setItem('customerThirdSisterSurname',response.customerThirdSisterSurname);
  localStorage.setItem('customerThirdSisterJob',response.customerThirdSisterJob);
  localStorage.setItem('customerThirdSisterBirthDay',response.customerThirdSisterBirthDay);
  localStorage.setItem('customerThirdSisterResidenceAddress',response.customerThirdSisterResidenceAddress);
  localStorage.setItem('customerThirdSisterPostalCode',response.customerThirdSisterPostalCode);
  localStorage.setItem('customerThirdSisterPhoneNumber',response.customerThirdSisterPhoneNumber);
  localStorage.setItem('customerThirdSisterEducation',response.customerThirdSisterEducation);
  localStorage.setItem('customerThirdSisterFinancialAbility',response.customerThirdSisterFinancialAbility);
  localStorage.setItem('customerThirdSisterDateOfDivorce',response.customerThirdSisterDateOfDivorce);
  localStorage.setItem('customerThirdSisterDateOfDeath',response.customerThirdSisterDateOfDeath);
  localStorage.setItem('customerThirdSistersForeignTripNumberOne',response.customerThirdSistersForeignTripNumberOne);
  localStorage.setItem('customerThirdSistersForeignTripNumberTwo',response.customerThirdSistersForeignTripNumberTwo);
  localStorage.setItem('customerThirdSistersForeignTripNumberThree',response.customerThirdSistersForeignTripNumberThree);
  localStorage.setItem('customerThirdSistersForeignTripNumberFour',response.customerThirdSistersForeignTripNumberFour);
  localStorage.setItem('customerThirdSistersForeignTripNumberFive',response.customerThirdSistersForeignTripNumberFive);
  localStorage.setItem('customerThirdSistersForeignTripNumberSix',response.customerThirdSistersForeignTripNumberSix);
  localStorage.setItem('customerThirdSistersForeignTripNumberSeven',response.customerThirdSistersForeignTripNumberSeven);
  localStorage.setItem('customerThirdSistersForeignTripNumberEight',response.customerThirdSistersForeignTripNumberEight);
  localStorage.setItem('customerThirdSistersForeignTripNumberNine',response.customerThirdSistersForeignTripNumberNine);



     
  
    }
     
    }).catch(()=>{
      
      
  localStorage.setItem('customerFatherFirstName','');
  localStorage.setItem('customerFatherSurname','');
  localStorage.setItem('customerFatherJob','');
  localStorage.setItem('customerFatherBirthDay','');
  localStorage.setItem('customerFathersResidenceAddress','');
  localStorage.setItem('customerFatherPostalCode','');
  localStorage.setItem('customerFatherPhoneNumber','');
  localStorage.setItem('customerFatherEducation','');
  localStorage.setItem('customerFatherFinancialAbility','');
  localStorage.setItem('customerFatherDateOfDivorce','');
  localStorage.setItem('customerFatherDateOfDeath','');
  localStorage.setItem('customerFathersForeignTripNumberOne','');
  localStorage.setItem('customerFathersForeignTripNumberTwo','');
  localStorage.setItem('customerFathersForeignTripNumberThree','');
  localStorage.setItem('customerFathersForeignTripNumberFour','');
  localStorage.setItem('customerFathersForeignTripNumberFive','');
  localStorage.setItem('customerFathersForeignTripNumberSix','');
  localStorage.setItem('customerFathersForeignTripNumberSeven','');
  localStorage.setItem('customerFathersForeignTripNumberEight','');
  localStorage.setItem('customerFathersForeignTripNumberNine','');
  localStorage.setItem('customerMotherFirstName','');
  localStorage.setItem('customerMotherSurname','');
  localStorage.setItem('customerMotherJob','');
  localStorage.setItem('customerMotherBirthDay','');
  localStorage.setItem('customerMotherResidenceAddress','');
  localStorage.setItem('customerMotherPostalCode','');
  localStorage.setItem('customerMotherPhoneNumber','');
  localStorage.setItem('customerMotherEducation','');
  localStorage.setItem('customerMotherFinancialAbility','');
  localStorage.setItem('customerMotherDateOfDivorce','');
  localStorage.setItem('customerMotherDateOfDeath','');
  localStorage.setItem('customerMothersForeignTripNumberOne','');
  localStorage.setItem('customerMothersForeignTripNumberTwo','');
  localStorage.setItem('customerMothersForeignTripNumberThree','');
  localStorage.setItem('customerMothersForeignTripNumberFour','');
  localStorage.setItem('customerMothersForeignTripNumberFive','');
  localStorage.setItem('customerMothersForeignTripNumberSix','');
  localStorage.setItem('customerMothersForeignTripNumberSeven','');
  localStorage.setItem('customerMothersForeignTripNumberEight','');
  localStorage.setItem('customerMothersForeignTripNumberNine','');
  localStorage.setItem('customerFirstBrotherFirstName','');
  localStorage.setItem('customerFirstBrotherSurname','');
  localStorage.setItem('customerFirstBrotherJob','');
  localStorage.setItem('customerFirstBrotherBirthDay','');
  localStorage.setItem('customerFirstBrotherResidenceAddress','');
  localStorage.setItem('customerFirstBrotherPostalCode','');
  localStorage.setItem('customerFirstBrotherPhoneNumber','');
  localStorage.setItem('customerFirstBrotherEducation','');
  localStorage.setItem('customerFirstBrotherFinancialAbility','');
  localStorage.setItem('customerFirstBrotherDateOfDivorce','');
  localStorage.setItem('customerFirstBrotherDateOfDeath','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberOne','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberThree','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberFour','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberFive','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberSix','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberEight','');
  localStorage.setItem('customerFirstBrothersForeignTripNumberNine','');
  localStorage.setItem('customerSecondBrotherFirstName','');
  localStorage.setItem('customerSecondBrotherSurname','');
  localStorage.setItem('customerSecondBrotherJob','');
  localStorage.setItem('customerSecondBrotherBirthDay','');
  localStorage.setItem('customerSecondBrotherResidenceAddress','');
  localStorage.setItem('customerSecondBrotherPostalCode','');
  localStorage.setItem('customerSecondBrotherPhoneNumber','');
  localStorage.setItem('customerSecondBrotherEducation','');
  localStorage.setItem('customerSecondBrotherFinancialAbility','');
  localStorage.setItem('customerSecondBrotherDateOfDivorce','');
  localStorage.setItem('customerSecondBrotherDateOfDeath','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberOne','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberThree','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberFour','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberFive','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberSix','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberEight','');
  localStorage.setItem('customerSecondBrothersForeignTripNumberNine','');
  localStorage.setItem('customerThirdBrotherFirstName','');
  localStorage.setItem('customerThirdBrotherSurname','');
  localStorage.setItem('customerThirdBrotherJob','');
  localStorage.setItem('customerThirdBrotherBirthDay','');
  localStorage.setItem('customerThirdBrotherResidenceAddress','');
  localStorage.setItem('customerThirdBrotherPostalCode','');
  localStorage.setItem('customerThirdBrotherPhoneNumber','');
  localStorage.setItem('customerThirdBrotherEducation','');
  localStorage.setItem('customerThirdBrotherFinancialAbility','');
  localStorage.setItem('customerThirdBrotherDateOfDivorce','');
  localStorage.setItem('customerThirdBrotherDateOfDeath','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberOne','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberThree','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberFour','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberFive','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberSix','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberEight','');
  localStorage.setItem('customerThirdBrothersForeignTripNumberNine','');
  localStorage.setItem('customerFirstSisterFirstName','');
  localStorage.setItem('customerFirstSisterSurname','');
  localStorage.setItem('customerFirstSisterJob','');
  localStorage.setItem('customerFirstSisterBirthDay','');
  localStorage.setItem('customerFirstSisterResidenceAddress','');
  localStorage.setItem('customerFirstSisterPostalCode','');
  localStorage.setItem('customerFirstSisterPhoneNumber','');
  localStorage.setItem('customerFirstSisterEducation','');
  localStorage.setItem('customerFirstSisterFinancialAbility','');
  localStorage.setItem('customerFirstSisterDateOfDivorce','');
  localStorage.setItem('customerFirstSisterDateOfDeath','');
  localStorage.setItem('customerFirstSistersForeignTripNumberOne','');
  localStorage.setItem('customerFirstSistersForeignTripNumberTwo','');
  localStorage.setItem('customerFirstSistersForeignTripNumberThree','');
  localStorage.setItem('customerFirstSistersForeignTripNumberFour','');
  localStorage.setItem('customerFirstSistersForeignTripNumberFive','');
  localStorage.setItem('customerFirstSistersForeignTripNumberSix','');
  localStorage.setItem('customerFirstSistersForeignTripNumberSeven','');
  localStorage.setItem('customerFirstSistersForeignTripNumberEight','');
  localStorage.setItem('customerFirstSistersForeignTripNumberNine','');
  localStorage.setItem('customerSecondSisterSecondName','');
  localStorage.setItem('customerSecondSisterSurname','');
  localStorage.setItem('customerSecondSisterJob','');
  localStorage.setItem('customerSecondSisterBirthDay','');
  localStorage.setItem('customerSecondSisterResidenceAddress','');
  localStorage.setItem('customerSecondSisterPostalCode','');
  localStorage.setItem('customerSecondSisterPhoneNumber','');
  localStorage.setItem('customerSecondSisterEducation','');
  localStorage.setItem('customerSecondSisterFinancialAbility','');
  localStorage.setItem('customerSecondSisterDateOfDivorce','');
  localStorage.setItem('customerSecondSisterDateOfDeath','');
  localStorage.setItem('customerSecondSistersForeignTripNumberOne','');
  localStorage.setItem('customerSecondSistersForeignTripNumberTwo','');
  localStorage.setItem('customerSecondSistersForeignTripNumberThree','');
  localStorage.setItem('customerSecondSistersForeignTripNumberFour','');
  localStorage.setItem('customerSecondSistersForeignTripNumberFive','');
  localStorage.setItem('customerSecondSistersForeignTripNumberSix','');
  localStorage.setItem('customerSecondSistersForeignTripNumberSeven','');
  localStorage.setItem('customerSecondSistersForeignTripNumberEight','');
  localStorage.setItem('customerSecondSistersForeignTripNumberNine','');
  localStorage.setItem('customerThirdSisterThirdName','');
  localStorage.setItem('customerThirdSisterSurname','');
  localStorage.setItem('customerThirdSisterJob','');
  localStorage.setItem('customerThirdSisterBirthDay','');
  localStorage.setItem('customerThirdSisterResidenceAddress','');
  localStorage.setItem('customerThirdSisterPostalCode','');
  localStorage.setItem('customerThirdSisterPhoneNumber','');
  localStorage.setItem('customerThirdSisterEducation','');
  localStorage.setItem('customerThirdSisterFinancialAbility','');
  localStorage.setItem('customerThirdSisterDateOfDivorce','');
  localStorage.setItem('customerThirdSisterDateOfDeath','');
  localStorage.setItem('customerThirdSistersForeignTripNumberOne','');
  localStorage.setItem('customerThirdSistersForeignTripNumberTwo','');
  localStorage.setItem('customerThirdSistersForeignTripNumberThree','');
  localStorage.setItem('customerThirdSistersForeignTripNumberFour','');
  localStorage.setItem('customerThirdSistersForeignTripNumberFive','');
  localStorage.setItem('customerThirdSistersForeignTripNumberSix','');
  localStorage.setItem('customerThirdSistersForeignTripNumberSeven','');
  localStorage.setItem('customerThirdSistersForeignTripNumberEight','');
  localStorage.setItem('customerThirdSistersForeignTripNumberNine','');


  
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
  }
  else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseFamilyInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();


  localStorage.setItem('customerSpouseFatherFirstName',response.customerSpouseFatherFirstName);
  localStorage.setItem('customerSpouseFatherSurname',response.customerSpouseFatherSurname);
  localStorage.setItem('customerSpouseFatherJob',response.customerSpouseFatherJob);
  localStorage.setItem('customerSpouseFatherBirthDay',response.customerSpouseFatherBirthDay);
  localStorage.setItem('customerSpouseFathersResidenceAddress',response.customerSpouseFathersResidenceAddress);
  localStorage.setItem('customerSpouseFatherPostalCode',response.customerSpouseFatherPostalCode);
  localStorage.setItem('customerSpouseFatherPhoneNumber',response.customerSpouseFatherPhoneNumber);
  localStorage.setItem('customerSpouseFatherEducation',response.customerSpouseFatherEducation);
  localStorage.setItem('customerSpouseFatherFinancialAbility',response.customerSpouseFatherFinancialAbility);
  localStorage.setItem('customerSpouseFatherDateOfDivorce',response.customerSpouseFatherDateOfDivorce);
  localStorage.setItem('customerSpouseFatherDateOfDeath',response.customerSpouseFatherDateOfDeath);
  localStorage.setItem('customerSpouseFathersForeignTripNumberOne',response.customerSpouseFathersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFathersForeignTripNumberTwo',response.customerSpouseFathersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFathersForeignTripNumberThree',response.customerSpouseFathersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFathersForeignTripNumberFour',response.customerSpouseFathersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFathersForeignTripNumberFive',response.customerSpouseFathersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFathersForeignTripNumberSix',response.customerSpouseFathersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFathersForeignTripNumberSeven',response.customerSpouseFathersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFathersForeignTripNumberEight',response.customerSpouseFathersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFathersForeignTripNumberNine',response.customerSpouseFathersForeignTripNumberNine);
  localStorage.setItem('customerSpouseMotherFirstName',response.customerSpouseMotherFirstName);
  localStorage.setItem('customerSpouseMotherSurname',response.customerSpouseMotherSurname);
  localStorage.setItem('customerSpouseMotherJob',response.customerSpouseMotherJob);
  localStorage.setItem('customerSpouseMotherBirthDay',response.customerSpouseMotherBirthDay);
  localStorage.setItem('customerSpouseMotherResidenceAddress',response.customerSpouseMotherResidenceAddress);
  localStorage.setItem('customerSpouseMotherPostalCode',response.customerSpouseMotherPostalCode);
  localStorage.setItem('customerSpouseMotherPhoneNumber',response.customerSpouseMotherPhoneNumber);
  localStorage.setItem('customerSpouseMotherEducation',response.customerSpouseMotherEducation);
  localStorage.setItem('customerSpouseMotherFinancialAbility',response.customerSpouseMotherFinancialAbility);
  localStorage.setItem('customerSpouseMotherDateOfDivorce',response.customerSpouseMotherDateOfDivorce);
  localStorage.setItem('customerSpouseMotherDateOfDeath',response.customerSpouseMotherDateOfDeath);
  localStorage.setItem('customerSpouseMothersForeignTripNumberOne',response.customerSpouseMothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseMothersForeignTripNumberTwo',response.customerSpouseMothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseMothersForeignTripNumberThree',response.customerSpouseMothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseMothersForeignTripNumberFour',response.customerSpouseMothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseMothersForeignTripNumberFive',response.customerSpouseMothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseMothersForeignTripNumberSix',response.customerSpouseMothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseMothersForeignTripNumberSeven',response.customerSpouseMothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseMothersForeignTripNumberEight',response.customerSpouseMothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseMothersForeignTripNumberNine',response.customerSpouseMothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseFirstBrotherFirstName',response.customerSpouseFirstBrotherFirstName);
  localStorage.setItem('customerSpouseFirstBrotherSurname',response.customerSpouseFirstBrotherSurname);
  localStorage.setItem('customerSpouseFirstBrotherJob',response.customerSpouseFirstBrotherJob);
  localStorage.setItem('customerSpouseFirstBrotherBirthDay',response.customerSpouseFirstBrotherBirthDay);
  localStorage.setItem('customerSpouseFirstBrotherResidenceAddress',response.customerSpouseFirstBrotherResidenceAddress);
  localStorage.setItem('customerSpouseFirstBrotherPostalCode',response.customerSpouseFirstBrotherPostalCode);
  localStorage.setItem('customerSpouseFirstBrotherPhoneNumber',response.customerSpouseFirstBrotherPhoneNumber);
  localStorage.setItem('customerSpouseFirstBrotherEducation',response.customerSpouseFirstBrotherEducation);
  localStorage.setItem('customerSpouseFirstBrotherFinancialAbility',response.customerSpouseFirstBrotherFinancialAbility);
  localStorage.setItem('customerSpouseFirstBrotherDateOfDivorce',response.customerSpouseFirstBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseFirstBrotherDateOfDeath',response.customerSpouseFirstBrotherDateOfDeath);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberOne',response.customerSpouseFirstBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberTwo',response.customerSpouseFirstBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberThree',response.customerSpouseFirstBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFour',response.customerSpouseFirstBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFive',response.customerSpouseFirstBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSix',response.customerSpouseFirstBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSeven',response.customerSpouseFirstBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberEight',response.customerSpouseFirstBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberNine',response.customerSpouseFirstBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseSecondBrotherFirstName',response.customerSpouseSecondBrotherFirstName);
  localStorage.setItem('customerSpouseSecondBrotherSurname',response.customerSpouseSecondBrotherSurname);
  localStorage.setItem('customerSpouseSecondBrotherJob',response.customerSpouseSecondBrotherJob);
  localStorage.setItem('customerSpouseSecondBrotherBirthDay',response.customerSpouseSecondBrotherBirthDay);
  localStorage.setItem('customerSpouseSecondBrotherResidenceAddress',response.customerSpouseSecondBrotherResidenceAddress);
  localStorage.setItem('customerSpouseSecondBrotherPostalCode',response.customerSpouseSecondBrotherPostalCode);
  localStorage.setItem('customerSpouseSecondBrotherPhoneNumber',response.customerSpouseSecondBrotherPhoneNumber);
  localStorage.setItem('customerSpouseSecondBrotherEducation',response.customerSpouseSecondBrotherEducation);
  localStorage.setItem('customerSpouseSecondBrotherFinancialAbility',response.customerSpouseSecondBrotherFinancialAbility);
  localStorage.setItem('customerSpouseSecondBrotherDateOfDivorce',response.customerSpouseSecondBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseSecondBrotherDateOfDeath',response.customerSpouseSecondBrotherDateOfDeath);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberOne',response.customerSpouseSecondBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberTwo',response.customerSpouseSecondBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberThree',response.customerSpouseSecondBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFour',response.customerSpouseSecondBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFive',response.customerSpouseSecondBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSix',response.customerSpouseSecondBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSeven',response.customerSpouseSecondBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberEight',response.customerSpouseSecondBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberNine',response.customerSpouseSecondBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseThirdBrotherFirstName',response.customerSpouseThirdBrotherFirstName);
  localStorage.setItem('customerSpouseThirdBrotherSurname',response.customerSpouseThirdBrotherSurname);
  localStorage.setItem('customerSpouseThirdBrotherJob',response.customerSpouseThirdBrotherJob);
  localStorage.setItem('customerSpouseThirdBrotherBirthDay',response.customerSpouseThirdBrotherBirthDay);
  localStorage.setItem('customerSpouseThirdBrotherResidenceAddress',response.customerSpouseThirdBrotherResidenceAddress);
  localStorage.setItem('customerSpouseThirdBrotherPostalCode',response.customerSpouseThirdBrotherPostalCode);
  localStorage.setItem('customerSpouseThirdBrotherPhoneNumber',response.customerSpouseThirdBrotherPhoneNumber);
  localStorage.setItem('customerSpouseThirdBrotherEducation',response.customerSpouseThirdBrotherEducation);
  localStorage.setItem('customerSpouseThirdBrotherFinancialAbility',response.customerSpouseThirdBrotherFinancialAbility);
  localStorage.setItem('customerSpouseThirdBrotherDateOfDivorce',response.customerSpouseThirdBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseThirdBrotherDateOfDeath',response.customerSpouseThirdBrotherDateOfDeath);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberOne',response.customerSpouseThirdBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberTwo',response.customerSpouseThirdBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberThree',response.customerSpouseThirdBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFour',response.customerSpouseThirdBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFive',response.customerSpouseThirdBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSix',response.customerSpouseThirdBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSeven',response.customerSpouseThirdBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberEight',response.customerSpouseThirdBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberNine',response.customerSpouseThirdBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseFirstSisterFirstName',response.customerSpouseFirstSisterFirstName);
  localStorage.setItem('customerSpouseFirstSisterSurname',response.customerSpouseFirstSisterSurname);
  localStorage.setItem('customerSpouseFirstSisterJob',response.customerSpouseFirstSisterJob);
  localStorage.setItem('customerSpouseFirstSisterBirthDay',response.customerSpouseFirstSisterBirthDay);
  localStorage.setItem('customerSpouseFirstSisterResidenceAddress',response.customerSpouseFirstSisterResidenceAddress);
  localStorage.setItem('customerSpouseFirstSisterPostalCode',response.customerSpouseFirstSisterPostalCode);
  localStorage.setItem('customerSpouseFirstSisterPhoneNumber',response.customerSpouseFirstSisterPhoneNumber);
  localStorage.setItem('customerSpouseFirstSisterEducation',response.customerSpouseFirstSisterEducation);
  localStorage.setItem('customerSpouseFirstSisterFinancialAbility',response.customerSpouseFirstSisterFinancialAbility);
  localStorage.setItem('customerSpouseFirstSisterDateOfDivorce',response.customerSpouseFirstSisterDateOfDivorce);
  localStorage.setItem('customerSpouseFirstSisterDateOfDeath',response.customerSpouseFirstSisterDateOfDeath);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberOne',response.customerSpouseFirstSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberTwo',response.customerSpouseFirstSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberThree',response.customerSpouseFirstSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFour',response.customerSpouseFirstSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFive',response.customerSpouseFirstSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSix',response.customerSpouseFirstSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSeven',response.customerSpouseFirstSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberEight',response.customerSpouseFirstSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberNine',response.customerSpouseFirstSistersForeignTripNumberNine);
  localStorage.setItem('customerSpouseSecondSisterSecondName',response.customerSpouseSecondSisterSecondName);
  localStorage.setItem('customerSpouseSecondSisterSurname',response.customerSpouseSecondSisterSurname);
  localStorage.setItem('customerSpouseSecondSisterJob',response.customerSpouseSecondSisterJob);
  localStorage.setItem('customerSpouseSecondSisterBirthDay',response.customerSpouseSecondSisterBirthDay);
  localStorage.setItem('customerSpouseSecondSisterResidenceAddress',response.customerSpouseSecondSisterResidenceAddress);
  localStorage.setItem('customerSpouseSecondSisterPostalCode',response.customerSpouseSecondSisterPostalCode);
  localStorage.setItem('customerSpouseSecondSisterPhoneNumber',response.customerSpouseSecondSisterPhoneNumber);
  localStorage.setItem('customerSpouseSecondSisterEducation',response.customerSpouseSecondSisterEducation);
  localStorage.setItem('customerSpouseSecondSisterFinancialAbility',response.customerSpouseSecondSisterFinancialAbility);
  localStorage.setItem('customerSpouseSecondSisterDateOfDivorce',response.customerSpouseSecondSisterDateOfDivorce);
  localStorage.setItem('customerSpouseSecondSisterDateOfDeath',response.customerSpouseSecondSisterDateOfDeath);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberOne',response.customerSpouseSecondSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberTwo',response.customerSpouseSecondSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberThree',response.customerSpouseSecondSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFour',response.customerSpouseSecondSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFive',response.customerSpouseSecondSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSix',response.customerSpouseSecondSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSeven',response.customerSpouseSecondSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberEight',response.customerSpouseSecondSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberNine',response.customerSpouseSecondSistersForeignTripNumberNine);
  localStorage.setItem('customerSpouseThirdSisterThirdName',response.customerSpouseThirdSisterThirdName);
  localStorage.setItem('customerSpouseThirdSisterSurname',response.customerSpouseThirdSisterSurname);
  localStorage.setItem('customerSpouseThirdSisterJob',response.customerSpouseThirdSisterJob);
  localStorage.setItem('customerSpouseThirdSisterBirthDay',response.customerSpouseThirdSisterBirthDay);
  localStorage.setItem('customerSpouseThirdSisterResidenceAddress',response.customerSpouseThirdSisterResidenceAddress);
  localStorage.setItem('customerSpouseThirdSisterPostalCode',response.customerSpouseThirdSisterPostalCode);
  localStorage.setItem('customerSpouseThirdSisterPhoneNumber',response.customerSpouseThirdSisterPhoneNumber);
  localStorage.setItem('customerSpouseThirdSisterEducation',response.customerSpouseThirdSisterEducation);
  localStorage.setItem('customerSpouseThirdSisterFinancialAbility',response.customerSpouseThirdSisterFinancialAbility);
  localStorage.setItem('customerSpouseThirdSisterDateOfDivorce',response.customerSpouseThirdSisterDateOfDivorce);
  localStorage.setItem('customerSpouseThirdSisterDateOfDeath',response.customerSpouseThirdSisterDateOfDeath);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberOne',response.customerSpouseThirdSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberTwo',response.customerSpouseThirdSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberThree',response.customerSpouseThirdSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFour',response.customerSpouseThirdSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFive',response.customerSpouseThirdSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSix',response.customerSpouseThirdSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSeven',response.customerSpouseThirdSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberEight',response.customerSpouseThirdSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberNine',response.customerSpouseThirdSistersForeignTripNumberNine);

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseFamilyInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      
  localStorage.setItem('customerSpouseFatherFirstName',response.customerSpouseFatherFirstName);
  localStorage.setItem('customerSpouseFatherSurname',response.customerSpouseFatherSurname);
  localStorage.setItem('customerSpouseFatherJob',response.customerSpouseFatherJob);
  localStorage.setItem('customerSpouseFatherBirthDay',response.customerSpouseFatherBirthDay);
  localStorage.setItem('customerSpouseFathersResidenceAddress',response.customerSpouseFathersResidenceAddress);
  localStorage.setItem('customerSpouseFatherPostalCode',response.customerSpouseFatherPostalCode);
  localStorage.setItem('customerSpouseFatherPhoneNumber',response.customerSpouseFatherPhoneNumber);
  localStorage.setItem('customerSpouseFatherEducation',response.customerSpouseFatherEducation);
  localStorage.setItem('customerSpouseFatherFinancialAbility',response.customerSpouseFatherFinancialAbility);
  localStorage.setItem('customerSpouseFatherDateOfDivorce',response.customerSpouseFatherDateOfDivorce);
  localStorage.setItem('customerSpouseFatherDateOfDeath',response.customerSpouseFatherDateOfDeath);
  localStorage.setItem('customerSpouseFathersForeignTripNumberOne',response.customerSpouseFathersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFathersForeignTripNumberTwo',response.customerSpouseFathersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFathersForeignTripNumberThree',response.customerSpouseFathersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFathersForeignTripNumberFour',response.customerSpouseFathersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFathersForeignTripNumberFive',response.customerSpouseFathersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFathersForeignTripNumberSix',response.customerSpouseFathersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFathersForeignTripNumberSeven',response.customerSpouseFathersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFathersForeignTripNumberEight',response.customerSpouseFathersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFathersForeignTripNumberNine',response.customerSpouseFathersForeignTripNumberNine);
  localStorage.setItem('customerSpouseMotherFirstName',response.customerSpouseMotherFirstName);
  localStorage.setItem('customerSpouseMotherSurname',response.customerSpouseMotherSurname);
  localStorage.setItem('customerSpouseMotherJob',response.customerSpouseMotherJob);
  localStorage.setItem('customerSpouseMotherBirthDay',response.customerSpouseMotherBirthDay);
  localStorage.setItem('customerSpouseMotherResidenceAddress',response.customerSpouseMotherResidenceAddress);
  localStorage.setItem('customerSpouseMotherPostalCode',response.customerSpouseMotherPostalCode);
  localStorage.setItem('customerSpouseMotherPhoneNumber',response.customerSpouseMotherPhoneNumber);
  localStorage.setItem('customerSpouseMotherEducation',response.customerSpouseMotherEducation);
  localStorage.setItem('customerSpouseMotherFinancialAbility',response.customerSpouseMotherFinancialAbility);
  localStorage.setItem('customerSpouseMotherDateOfDivorce',response.customerSpouseMotherDateOfDivorce);
  localStorage.setItem('customerSpouseMotherDateOfDeath',response.customerSpouseMotherDateOfDeath);
  localStorage.setItem('customerSpouseMothersForeignTripNumberOne',response.customerSpouseMothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseMothersForeignTripNumberTwo',response.customerSpouseMothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseMothersForeignTripNumberThree',response.customerSpouseMothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseMothersForeignTripNumberFour',response.customerSpouseMothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseMothersForeignTripNumberFive',response.customerSpouseMothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseMothersForeignTripNumberSix',response.customerSpouseMothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseMothersForeignTripNumberSeven',response.customerSpouseMothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseMothersForeignTripNumberEight',response.customerSpouseMothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseMothersForeignTripNumberNine',response.customerSpouseMothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseFirstBrotherFirstName',response.customerSpouseFirstBrotherFirstName);
  localStorage.setItem('customerSpouseFirstBrotherSurname',response.customerSpouseFirstBrotherSurname);
  localStorage.setItem('customerSpouseFirstBrotherJob',response.customerSpouseFirstBrotherJob);
  localStorage.setItem('customerSpouseFirstBrotherBirthDay',response.customerSpouseFirstBrotherBirthDay);
  localStorage.setItem('customerSpouseFirstBrotherResidenceAddress',response.customerSpouseFirstBrotherResidenceAddress);
  localStorage.setItem('customerSpouseFirstBrotherPostalCode',response.customerSpouseFirstBrotherPostalCode);
  localStorage.setItem('customerSpouseFirstBrotherPhoneNumber',response.customerSpouseFirstBrotherPhoneNumber);
  localStorage.setItem('customerSpouseFirstBrotherEducation',response.customerSpouseFirstBrotherEducation);
  localStorage.setItem('customerSpouseFirstBrotherFinancialAbility',response.customerSpouseFirstBrotherFinancialAbility);
  localStorage.setItem('customerSpouseFirstBrotherDateOfDivorce',response.customerSpouseFirstBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseFirstBrotherDateOfDeath',response.customerSpouseFirstBrotherDateOfDeath);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberOne',response.customerSpouseFirstBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberTwo',response.customerSpouseFirstBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberThree',response.customerSpouseFirstBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFour',response.customerSpouseFirstBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFive',response.customerSpouseFirstBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSix',response.customerSpouseFirstBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSeven',response.customerSpouseFirstBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberEight',response.customerSpouseFirstBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberNine',response.customerSpouseFirstBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseSecondBrotherFirstName',response.customerSpouseSecondBrotherFirstName);
  localStorage.setItem('customerSpouseSecondBrotherSurname',response.customerSpouseSecondBrotherSurname);
  localStorage.setItem('customerSpouseSecondBrotherJob',response.customerSpouseSecondBrotherJob);
  localStorage.setItem('customerSpouseSecondBrotherBirthDay',response.customerSpouseSecondBrotherBirthDay);
  localStorage.setItem('customerSpouseSecondBrotherResidenceAddress',response.customerSpouseSecondBrotherResidenceAddress);
  localStorage.setItem('customerSpouseSecondBrotherPostalCode',response.customerSpouseSecondBrotherPostalCode);
  localStorage.setItem('customerSpouseSecondBrotherPhoneNumber',response.customerSpouseSecondBrotherPhoneNumber);
  localStorage.setItem('customerSpouseSecondBrotherEducation',response.customerSpouseSecondBrotherEducation);
  localStorage.setItem('customerSpouseSecondBrotherFinancialAbility',response.customerSpouseSecondBrotherFinancialAbility);
  localStorage.setItem('customerSpouseSecondBrotherDateOfDivorce',response.customerSpouseSecondBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseSecondBrotherDateOfDeath',response.customerSpouseSecondBrotherDateOfDeath);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberOne',response.customerSpouseSecondBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberTwo',response.customerSpouseSecondBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberThree',response.customerSpouseSecondBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFour',response.customerSpouseSecondBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFive',response.customerSpouseSecondBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSix',response.customerSpouseSecondBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSeven',response.customerSpouseSecondBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberEight',response.customerSpouseSecondBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberNine',response.customerSpouseSecondBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseThirdBrotherFirstName',response.customerSpouseThirdBrotherFirstName);
  localStorage.setItem('customerSpouseThirdBrotherSurname',response.customerSpouseThirdBrotherSurname);
  localStorage.setItem('customerSpouseThirdBrotherJob',response.customerSpouseThirdBrotherJob);
  localStorage.setItem('customerSpouseThirdBrotherBirthDay',response.customerSpouseThirdBrotherBirthDay);
  localStorage.setItem('customerSpouseThirdBrotherResidenceAddress',response.customerSpouseThirdBrotherResidenceAddress);
  localStorage.setItem('customerSpouseThirdBrotherPostalCode',response.customerSpouseThirdBrotherPostalCode);
  localStorage.setItem('customerSpouseThirdBrotherPhoneNumber',response.customerSpouseThirdBrotherPhoneNumber);
  localStorage.setItem('customerSpouseThirdBrotherEducation',response.customerSpouseThirdBrotherEducation);
  localStorage.setItem('customerSpouseThirdBrotherFinancialAbility',response.customerSpouseThirdBrotherFinancialAbility);
  localStorage.setItem('customerSpouseThirdBrotherDateOfDivorce',response.customerSpouseThirdBrotherDateOfDivorce);
  localStorage.setItem('customerSpouseThirdBrotherDateOfDeath',response.customerSpouseThirdBrotherDateOfDeath);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberOne',response.customerSpouseThirdBrothersForeignTripNumberOne);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberTwo',response.customerSpouseThirdBrothersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberThree',response.customerSpouseThirdBrothersForeignTripNumberThree);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFour',response.customerSpouseThirdBrothersForeignTripNumberFour);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFive',response.customerSpouseThirdBrothersForeignTripNumberFive);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSix',response.customerSpouseThirdBrothersForeignTripNumberSix);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSeven',response.customerSpouseThirdBrothersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberEight',response.customerSpouseThirdBrothersForeignTripNumberEight);
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberNine',response.customerSpouseThirdBrothersForeignTripNumberNine);
  localStorage.setItem('customerSpouseFirstSisterFirstName',response.customerSpouseFirstSisterFirstName);
  localStorage.setItem('customerSpouseFirstSisterSurname',response.customerSpouseFirstSisterSurname);
  localStorage.setItem('customerSpouseFirstSisterJob',response.customerSpouseFirstSisterJob);
  localStorage.setItem('customerSpouseFirstSisterBirthDay',response.customerSpouseFirstSisterBirthDay);
  localStorage.setItem('customerSpouseFirstSisterResidenceAddress',response.customerSpouseFirstSisterResidenceAddress);
  localStorage.setItem('customerSpouseFirstSisterPostalCode',response.customerSpouseFirstSisterPostalCode);
  localStorage.setItem('customerSpouseFirstSisterPhoneNumber',response.customerSpouseFirstSisterPhoneNumber);
  localStorage.setItem('customerSpouseFirstSisterEducation',response.customerSpouseFirstSisterEducation);
  localStorage.setItem('customerSpouseFirstSisterFinancialAbility',response.customerSpouseFirstSisterFinancialAbility);
  localStorage.setItem('customerSpouseFirstSisterDateOfDivorce',response.customerSpouseFirstSisterDateOfDivorce);
  localStorage.setItem('customerSpouseFirstSisterDateOfDeath',response.customerSpouseFirstSisterDateOfDeath);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberOne',response.customerSpouseFirstSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberTwo',response.customerSpouseFirstSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberThree',response.customerSpouseFirstSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFour',response.customerSpouseFirstSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFive',response.customerSpouseFirstSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSix',response.customerSpouseFirstSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSeven',response.customerSpouseFirstSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberEight',response.customerSpouseFirstSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberNine',response.customerSpouseFirstSistersForeignTripNumberNine);
  localStorage.setItem('customerSpouseSecondSisterSecondName',response.customerSpouseSecondSisterSecondName);
  localStorage.setItem('customerSpouseSecondSisterSurname',response.customerSpouseSecondSisterSurname);
  localStorage.setItem('customerSpouseSecondSisterJob',response.customerSpouseSecondSisterJob);
  localStorage.setItem('customerSpouseSecondSisterBirthDay',response.customerSpouseSecondSisterBirthDay);
  localStorage.setItem('customerSpouseSecondSisterResidenceAddress',response.customerSpouseSecondSisterResidenceAddress);
  localStorage.setItem('customerSpouseSecondSisterPostalCode',response.customerSpouseSecondSisterPostalCode);
  localStorage.setItem('customerSpouseSecondSisterPhoneNumber',response.customerSpouseSecondSisterPhoneNumber);
  localStorage.setItem('customerSpouseSecondSisterEducation',response.customerSpouseSecondSisterEducation);
  localStorage.setItem('customerSpouseSecondSisterFinancialAbility',response.customerSpouseSecondSisterFinancialAbility);
  localStorage.setItem('customerSpouseSecondSisterDateOfDivorce',response.customerSpouseSecondSisterDateOfDivorce);
  localStorage.setItem('customerSpouseSecondSisterDateOfDeath',response.customerSpouseSecondSisterDateOfDeath);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberOne',response.customerSpouseSecondSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberTwo',response.customerSpouseSecondSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberThree',response.customerSpouseSecondSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFour',response.customerSpouseSecondSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFive',response.customerSpouseSecondSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSix',response.customerSpouseSecondSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSeven',response.customerSpouseSecondSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberEight',response.customerSpouseSecondSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberNine',response.customerSpouseSecondSistersForeignTripNumberNine);
  localStorage.setItem('customerSpouseThirdSisterThirdName',response.customerSpouseThirdSisterThirdName);
  localStorage.setItem('customerSpouseThirdSisterSurname',response.customerSpouseThirdSisterSurname);
  localStorage.setItem('customerSpouseThirdSisterJob',response.customerSpouseThirdSisterJob);
  localStorage.setItem('customerSpouseThirdSisterBirthDay',response.customerSpouseThirdSisterBirthDay);
  localStorage.setItem('customerSpouseThirdSisterResidenceAddress',response.customerSpouseThirdSisterResidenceAddress);
  localStorage.setItem('customerSpouseThirdSisterPostalCode',response.customerSpouseThirdSisterPostalCode);
  localStorage.setItem('customerSpouseThirdSisterPhoneNumber',response.customerSpouseThirdSisterPhoneNumber);
  localStorage.setItem('customerSpouseThirdSisterEducation',response.customerSpouseThirdSisterEducation);
  localStorage.setItem('customerSpouseThirdSisterFinancialAbility',response.customerSpouseThirdSisterFinancialAbility);
  localStorage.setItem('customerSpouseThirdSisterDateOfDivorce',response.customerSpouseThirdSisterDateOfDivorce);
  localStorage.setItem('customerSpouseThirdSisterDateOfDeath',response.customerSpouseThirdSisterDateOfDeath);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberOne',response.customerSpouseThirdSistersForeignTripNumberOne);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberTwo',response.customerSpouseThirdSistersForeignTripNumberTwo);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberThree',response.customerSpouseThirdSistersForeignTripNumberThree);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFour',response.customerSpouseThirdSistersForeignTripNumberFour);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFive',response.customerSpouseThirdSistersForeignTripNumberFive);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSix',response.customerSpouseThirdSistersForeignTripNumberSix);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSeven',response.customerSpouseThirdSistersForeignTripNumberSeven);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberEight',response.customerSpouseThirdSistersForeignTripNumberEight);
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberNine',response.customerSpouseThirdSistersForeignTripNumberNine);



     
  
    }
     
    }).catch(()=>{
      
      
  localStorage.setItem('customerSpouseFatherFirstName','');
  localStorage.setItem('customerSpouseFatherSurname','');
  localStorage.setItem('customerSpouseFatherJob','');
  localStorage.setItem('customerSpouseFatherBirthDay','');
  localStorage.setItem('customerSpouseFathersResidenceAddress','');
  localStorage.setItem('customerSpouseFatherPostalCode','');
  localStorage.setItem('customerSpouseFatherPhoneNumber','');
  localStorage.setItem('customerSpouseFatherEducation','');
  localStorage.setItem('customerSpouseFatherFinancialAbility','');
  localStorage.setItem('customerSpouseFatherDateOfDivorce','');
  localStorage.setItem('customerSpouseFatherDateOfDeath','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseFathersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseMotherFirstName','');
  localStorage.setItem('customerSpouseMotherSurname','');
  localStorage.setItem('customerSpouseMotherJob','');
  localStorage.setItem('customerSpouseMotherBirthDay','');
  localStorage.setItem('customerSpouseMotherResidenceAddress','');
  localStorage.setItem('customerSpouseMotherPostalCode','');
  localStorage.setItem('customerSpouseMotherPhoneNumber','');
  localStorage.setItem('customerSpouseMotherEducation','');
  localStorage.setItem('customerSpouseMotherFinancialAbility','');
  localStorage.setItem('customerSpouseMotherDateOfDivorce','');
  localStorage.setItem('customerSpouseMotherDateOfDeath','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseMothersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseFirstBrotherFirstName','');
  localStorage.setItem('customerSpouseFirstBrotherSurname','');
  localStorage.setItem('customerSpouseFirstBrotherJob','');
  localStorage.setItem('customerSpouseFirstBrotherBirthDay','');
  localStorage.setItem('customerSpouseFirstBrotherResidenceAddress','');
  localStorage.setItem('customerSpouseFirstBrotherPostalCode','');
  localStorage.setItem('customerSpouseFirstBrotherPhoneNumber','');
  localStorage.setItem('customerSpouseFirstBrotherEducation','');
  localStorage.setItem('customerSpouseFirstBrotherFinancialAbility','');
  localStorage.setItem('customerSpouseFirstBrotherDateOfDivorce','');
  localStorage.setItem('customerSpouseFirstBrotherDateOfDeath','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseFirstBrothersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseSecondBrotherFirstName','');
  localStorage.setItem('customerSpouseSecondBrotherSurname','');
  localStorage.setItem('customerSpouseSecondBrotherJob','');
  localStorage.setItem('customerSpouseSecondBrotherBirthDay','');
  localStorage.setItem('customerSpouseSecondBrotherResidenceAddress','');
  localStorage.setItem('customerSpouseSecondBrotherPostalCode','');
  localStorage.setItem('customerSpouseSecondBrotherPhoneNumber','');
  localStorage.setItem('customerSpouseSecondBrotherEducation','');
  localStorage.setItem('customerSpouseSecondBrotherFinancialAbility','');
  localStorage.setItem('customerSpouseSecondBrotherDateOfDivorce','');
  localStorage.setItem('customerSpouseSecondBrotherDateOfDeath','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseSecondBrothersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseThirdBrotherFirstName','');
  localStorage.setItem('customerSpouseThirdBrotherSurname','');
  localStorage.setItem('customerSpouseThirdBrotherJob','');
  localStorage.setItem('customerSpouseThirdBrotherBirthDay','');
  localStorage.setItem('customerSpouseThirdBrotherResidenceAddress','');
  localStorage.setItem('customerSpouseThirdBrotherPostalCode','');
  localStorage.setItem('customerSpouseThirdBrotherPhoneNumber','');
  localStorage.setItem('customerSpouseThirdBrotherEducation','');
  localStorage.setItem('customerSpouseThirdBrotherFinancialAbility','');
  localStorage.setItem('customerSpouseThirdBrotherDateOfDivorce','');
  localStorage.setItem('customerSpouseThirdBrotherDateOfDeath','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseThirdBrothersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseFirstSisterFirstName','');
  localStorage.setItem('customerSpouseFirstSisterSurname','');
  localStorage.setItem('customerSpouseFirstSisterJob','');
  localStorage.setItem('customerSpouseFirstSisterBirthDay','');
  localStorage.setItem('customerSpouseFirstSisterResidenceAddress','');
  localStorage.setItem('customerSpouseFirstSisterPostalCode','');
  localStorage.setItem('customerSpouseFirstSisterPhoneNumber','');
  localStorage.setItem('customerSpouseFirstSisterEducation','');
  localStorage.setItem('customerSpouseFirstSisterFinancialAbility','');
  localStorage.setItem('customerSpouseFirstSisterDateOfDivorce','');
  localStorage.setItem('customerSpouseFirstSisterDateOfDeath','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseFirstSistersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseSecondSisterSecondName','');
  localStorage.setItem('customerSpouseSecondSisterSurname','');
  localStorage.setItem('customerSpouseSecondSisterJob','');
  localStorage.setItem('customerSpouseSecondSisterBirthDay','');
  localStorage.setItem('customerSpouseSecondSisterResidenceAddress','');
  localStorage.setItem('customerSpouseSecondSisterPostalCode','');
  localStorage.setItem('customerSpouseSecondSisterPhoneNumber','');
  localStorage.setItem('customerSpouseSecondSisterEducation','');
  localStorage.setItem('customerSpouseSecondSisterFinancialAbility','');
  localStorage.setItem('customerSpouseSecondSisterDateOfDivorce','');
  localStorage.setItem('customerSpouseSecondSisterDateOfDeath','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseSecondSistersForeignTripNumberNine','');
  localStorage.setItem('customerSpouseThirdSisterThirdName','');
  localStorage.setItem('customerSpouseThirdSisterSurname','');
  localStorage.setItem('customerSpouseThirdSisterJob','');
  localStorage.setItem('customerSpouseThirdSisterBirthDay','');
  localStorage.setItem('customerSpouseThirdSisterResidenceAddress','');
  localStorage.setItem('customerSpouseThirdSisterPostalCode','');
  localStorage.setItem('customerSpouseThirdSisterPhoneNumber','');
  localStorage.setItem('customerSpouseThirdSisterEducation','');
  localStorage.setItem('customerSpouseThirdSisterFinancialAbility','');
  localStorage.setItem('customerSpouseThirdSisterDateOfDivorce','');
  localStorage.setItem('customerSpouseThirdSisterDateOfDeath','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberOne','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberTwo','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberThree','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFour','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberFive','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSix','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberSeven','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberEight','');
  localStorage.setItem('customerSpouseThirdSistersForeignTripNumberNine','');


  
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
  }
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }
      if(localStorage.getItem('whoIs')=='ap'){
      values.FatherName = localStorage.getItem('customerFatherFirstName');
      values.FatherFamily = localStorage.getItem('customerFatherSurname');
      values.FatherJob = localStorage.getItem('customerFatherJob');
      values.FathersResidenceAddress = localStorage.getItem('customerFathersResidenceAddress');
      values.FatherPostalCode = localStorage.getItem('customerFatherPostalCode');
      values.FatherPhoneCall = localStorage.getItem('customerFatherPhoneNumber');
      values.FatherEducation = localStorage.getItem('customerFatherEducation');
      values.FatherFinancialAbility = localStorage.getItem('customerFatherFinancialAbility');
      values.FathersForeignTrip1 = localStorage.getItem('customerFathersForeignTripNumberOne');
      values.FathersForeignTrip2 = localStorage.getItem('customerFathersForeignTripNumberTwo');
      values.FathersForeignTrip3 = localStorage.getItem('customerFathersForeignTripNumberThree');
      values.FathersForeignTrip4 = localStorage.getItem('customerFathersForeignTripNumberFour');
      values.FathersForeignTrip5 = localStorage.getItem('customerFathersForeignTripNumberFive');
      values.FathersForeignTrip6 = localStorage.getItem('customerFathersForeignTripNumberSix');
      values.FathersForeignTrip7 = localStorage.getItem('customerFathersForeignTripNumberSeven');
      values.FathersForeignTrip8 = localStorage.getItem('customerFathersForeignTripNumberEight');
      values.FathersForeignTrip9 = localStorage.getItem('customerFathersForeignTripNumberNine');

      values.MotherName = localStorage.getItem('customerMotherFirstName');
      values.MotherFamily = localStorage.getItem('customerMotherSurname');
      values.MotherJob = localStorage.getItem('customerMotherJob');
      values.MothersResidenceAddress = localStorage.getItem('customerMotherResidenceAddress');
      values.MotherPostalCode = localStorage.getItem('customerMotherPostalCode');
      values.MotherPhoneCall = localStorage.getItem('customerMotherPhoneNumber');
      values.MotherEducation = localStorage.getItem('customerMotherEducation');
      values.MotherFinancialAbility = localStorage.getItem('customerMotherFinancialAbility');
      values.MothersForeignTrip1 = localStorage.getItem('customerMothersForeignTripNumberOne');
      values.MothersForeignTrip2 = localStorage.getItem('customerMothersForeignTripNumberTwo');
      values.MothersForeignTrip3 = localStorage.getItem('customerMothersForeignTripNumberThree');
      values.MothersForeignTrip4 = localStorage.getItem('customerMothersForeignTripNumberFour');
      values.MothersForeignTrip5 = localStorage.getItem('customerMothersForeignTripNumberFive');
      values.MothersForeignTrip6 = localStorage.getItem('customerMothersForeignTripNumberSix');
      values.MothersForeignTrip7 = localStorage.getItem('customerMothersForeignTripNumberSeven');
      values.MothersForeignTrip8 = localStorage.getItem('customerMothersForeignTripNumberEight');
      values.MothersForeignTrip9 = localStorage.getItem('customerMothersForeignTripNumberNine');

      values.Brother1Name = localStorage.getItem('customerFirstBrotherFirstName');
      values.Brother1Family = localStorage.getItem('customerFirstBrotherSurname');
      values.Brother1Job = localStorage.getItem('customerFirstBrotherJob');
      values.Brother1sResidenceAddress = localStorage.getItem('customerFirstBrotherResidenceAddress');
      values.Brother1PostalCode = localStorage.getItem('customerFirstBrotherPostalCode');
      values.Brother1PhoneCall = localStorage.getItem('customerFirstBrotherPhoneNumber');
      values.Brother1Education = localStorage.getItem('customerFirstBrotherEducation');
      values.Brother1FinancialAbility = localStorage.getItem('customerFirstBrotherFinancialAbility');
      values.Brother1sForeignTrip1 = localStorage.getItem('customerFirstBrothersForeignTripNumberOne');
      values.Brother1sForeignTrip2 = localStorage.getItem('customerFirstBrothersForeignTripNumberTwo');
      values.Brother1sForeignTrip3 = localStorage.getItem('customerFirstBrothersForeignTripNumberThree');
      values.Brother1sForeignTrip4 = localStorage.getItem('customerFirstBrothersForeignTripNumberFour');
      values.Brother1sForeignTrip5 = localStorage.getItem('customerFirstBrothersForeignTripNumberFive');
      values.Brother1sForeignTrip6 = localStorage.getItem('customerFirstBrothersForeignTripNumberSix');
      values.Brother1sForeignTrip7 = localStorage.getItem('customerFirstBrothersForeignTripNumberSeven');
      values.Brother1sForeignTrip8 = localStorage.getItem('customerFirstBrothersForeignTripNumberEight');
      values.Brother1sForeignTrip9 = localStorage.getItem('customerFirstBrothersForeignTripNumberNine');

      values.Brother2Name = localStorage.getItem('customerSecondBrotherFirstName');
      values.Brother2Family = localStorage.getItem('customerSecondBrotherSurname');
      values.Brother2Job = localStorage.getItem('customerSecondBrotherJob');
      values.Brother2sResidenceAddress = localStorage.getItem('customerSecondBrotherResidenceAddress');
      values.Brother2PostalCode = localStorage.getItem('customerSecondBrotherPostalCode');
      values.Brother2PhoneCall = localStorage.getItem('customerSecondBrotherPhoneNumber');
      values.Brother2Education = localStorage.getItem('customerSecondBrotherEducation');
      values.Brother2FinancialAbility = localStorage.getItem('customerSecondBrotherFinancialAbility');
      values.Brother2sForeignTrip1 = localStorage.getItem('customerSecondBrothersForeignTripNumberOne');
      values.Brother2sForeignTrip2 = localStorage.getItem('customerSecondBrothersForeignTripNumberTwo');
      values.Brother2sForeignTrip3 = localStorage.getItem('customerSecondBrothersForeignTripNumberThree');
      values.Brother2sForeignTrip4 = localStorage.getItem('customerSecondBrothersForeignTripNumberFour');
      values.Brother2sForeignTrip5 = localStorage.getItem('customerSecondBrothersForeignTripNumberFive');
      values.Brother2sForeignTrip6 = localStorage.getItem('customerSecondBrothersForeignTripNumberSix');
      values.Brother2sForeignTrip7 = localStorage.getItem('customerSecondBrothersForeignTripNumberSeven');
      values.Brother2sForeignTrip8 = localStorage.getItem('customerSecondBrothersForeignTripNumberEight');
      values.Brother2sForeignTrip9 = localStorage.getItem('customerSecondBrothersForeignTripNumberNine');

      values.Brother3Name = localStorage.getItem('customerThirdBrotherFirstName');
      values.Brother3Family = localStorage.getItem('customerThirdBrotherSurname');
      values.Brother3Job = localStorage.getItem('customerThirdBrotherJob');
      values.Brother3sResidenceAddress = localStorage.getItem('customerThirdBrotherResidenceAddress');
      values.Brother3PostalCode = localStorage.getItem('customerThirdBrotherPostalCode');
      values.Brother3PhoneCall = localStorage.getItem('customerThirdBrotherPhoneNumber');
      values.Brother3Education = localStorage.getItem('customerThirdBrotherEducation');
      values.Brother3FinancialAbility = localStorage.getItem('customerThirdBrotherFinancialAbility');
      values.Brother3sForeignTrip1 = localStorage.getItem('customerThirdBrothersForeignTripNumberOne');
      values.Brother3sForeignTrip2 = localStorage.getItem('customerThirdBrothersForeignTripNumberTwo');
      values.Brother3sForeignTrip3 = localStorage.getItem('customerThirdBrothersForeignTripNumberThree');
      values.Brother3sForeignTrip4 = localStorage.getItem('customerThirdBrothersForeignTripNumberFour');
      values.Brother3sForeignTrip5 = localStorage.getItem('customerThirdBrothersForeignTripNumberFive');
      values.Brother3sForeignTrip6 = localStorage.getItem('customerThirdBrothersForeignTripNumberSix');
      values.Brother3sForeignTrip7 = localStorage.getItem('customerThirdBrothersForeignTripNumberSeven');
      values.Brother3sForeignTrip8 = localStorage.getItem('customerThirdBrothersForeignTripNumberEight');
      values.Brother3sForeignTrip9 = localStorage.getItem('customerThirdBrothersForeignTripNumberNine');

      values.Sister1Name = localStorage.getItem('customerFirstSisterFirstName');
      values.Sister1Family = localStorage.getItem('customerFirstSisterSurname');
      values.Sister1Job = localStorage.getItem('customerFirstSisterJob');
      values.Sister1sResidenceAddress = localStorage.getItem('customerFirstSisterResidenceAddress');
      values.Sister1PostalCode = localStorage.getItem('customerFirstSisterPostalCode');
      values.Sister1PhoneCall = localStorage.getItem('customerFirstSisterPhoneNumber');
      values.Sister1Education = localStorage.getItem('customerFirstSisterEducation');
      values.Sister1FinancialAbility = localStorage.getItem('customerFirstSisterFinancialAbility');
      values.Sister1sForeignTrip1 = localStorage.getItem('customerFirstSistersForeignTripNumberOne');
      values.Sister1sForeignTrip2 = localStorage.getItem('customerFirstSistersForeignTripNumberTwo');
      values.Sister1sForeignTrip3 = localStorage.getItem('customerFirstSistersForeignTripNumberThree');
      values.Sister1sForeignTrip4 = localStorage.getItem('customerFirstSistersForeignTripNumberFour');
      values.Sister1sForeignTrip5 = localStorage.getItem('customerFirstSistersForeignTripNumberFive');
      values.Sister1sForeignTrip6 = localStorage.getItem('customerFirstSistersForeignTripNumberSix');
      values.Sister1sForeignTrip7 = localStorage.getItem('customerFirstSistersForeignTripNumberSeven');
      values.Sister1sForeignTrip8 = localStorage.getItem('customerFirstSistersForeignTripNumberEight');
      values.Sister1sForeignTrip9 = localStorage.getItem('customerFirstSistersForeignTripNumberNine');

      values.Sister2Name = localStorage.getItem('customerSecondSisterSecondName');
      values.Sister2Family = localStorage.getItem('customerSecondSisterSurname');
      values.Sister2Job = localStorage.getItem('customerSecondSisterJob');
      values.Sister2sResidenceAddress = localStorage.getItem('customerSecondSisterResidenceAddress');
      values.Sister2PostalCode = localStorage.getItem('customerSecondSisterPostalCode');
      values.Sister2PhoneCall = localStorage.getItem('customerSecondSisterPhoneNumber');
      values.Sister2Education = localStorage.getItem('customerSecondSisterEducation');
      values.Sister2FinancialAbility = localStorage.getItem('customerSecondSisterFinancialAbility');
      values.Sister2sForeignTrip1 = localStorage.getItem('customerSecondSistersForeignTripNumberOne');
      values.Sister2sForeignTrip2 = localStorage.getItem('customerSecondSistersForeignTripNumberTwo');
      values.Sister2sForeignTrip3 = localStorage.getItem('customerSecondSistersForeignTripNumberThree');
      values.Sister2sForeignTrip4 = localStorage.getItem('customerSecondSistersForeignTripNumberFour');
      values.Sister2sForeignTrip5 = localStorage.getItem('customerSecondSistersForeignTripNumberFive');
      values.Sister2sForeignTrip6 = localStorage.getItem('customerSecondSistersForeignTripNumberSix');
      values.Sister2sForeignTrip7 = localStorage.getItem('customerSecondSistersForeignTripNumberSeven');
      values.Sister2sForeignTrip8 = localStorage.getItem('customerSecondSistersForeignTripNumberEight');
      values.Sister2sForeignTrip9 = localStorage.getItem('customerSecondSistersForeignTripNumberNine');

      values.Sister3Name = localStorage.getItem('customerThirdSisterThirdName');
      values.Sister3Family = localStorage.getItem('customerThirdSisterSurname');
      values.Sister3Job = localStorage.getItem('customerThirdSisterJob');
      values.Sister3sResidenceAddress = localStorage.getItem('customerThirdSisterResidenceAddress');
      values.Sister3PostalCode = localStorage.getItem('customerThirdSisterPostalCode');
      values.Sister3PhoneCall = localStorage.getItem('customerThirdSisterPhoneNumber');
      values.Sister3Education = localStorage.getItem('customerThirdSisterEducation');
      values.Sister3FinancialAbility = localStorage.getItem('customerThirdSisterFinancialAbility');
      values.Sister3sForeignTrip1 = localStorage.getItem('customerThirdSistersForeignTripNumberOne');
      values.Sister3sForeignTrip2 = localStorage.getItem('customerThirdSistersForeignTripNumberTwo');
      values.Sister3sForeignTrip3 = localStorage.getItem('customerThirdSistersForeignTripNumberThree');
      values.Sister3sForeignTrip4 = localStorage.getItem('customerThirdSistersForeignTripNumberFour');
      values.Sister3sForeignTrip5 = localStorage.getItem('customerThirdSistersForeignTripNumberFive');
      values.Sister3sForeignTrip6 = localStorage.getItem('customerThirdSistersForeignTripNumberSix');
      values.Sister3sForeignTrip7 = localStorage.getItem('customerThirdSistersForeignTripNumberSeven');
      values.Sister3sForeignTrip8 = localStorage.getItem('customerThirdSistersForeignTripNumberEight');
      values.Sister3sForeignTrip9 = localStorage.getItem('customerThirdSistersForeignTripNumberNine');

      setFatherBirthDay(localStorage.getItem('customerFatherBirthDay'));
      setFatherDateofDivorce(localStorage.getItem('customerFatherDateOfDivorce'));
      setFatherDateofDeath(localStorage.getItem('customerFatherDateOfDeath'));

      setMotherBirthDay(localStorage.getItem('customerMotherBirthDay'));
      setMotherDateofDivorce(localStorage.getItem('customerMotherDateOfDivorce'));
      setMotherDateofDeath(localStorage.getItem('customerMotherDateOfDeath'));

      setBrother1BirthDay(localStorage.getItem('customerFirstBrotherBirthDay'));
      setBrother1DateofDivorce(localStorage.getItem('customerFirstBrotherDateOfDivorce'));
      setBrother1DateofDeath(localStorage.getItem('customerFirstBrotherDateOfDeath'));

      setBrother2BirthDay(localStorage.getItem('customerSecondBrotherBirthDay'));
      setBrother2DateofDivorce(localStorage.getItem('customerSecondBrotherDateOfDivorce'));
      setBrother2DateofDeath(localStorage.getItem('customerSecondBrotherDateOfDeath'));

      setBrother3BirthDay(localStorage.getItem('customerThirdBrotherBirthDay'));
      setBrother3DateofDivorce(localStorage.getItem('customerThirdBrotherDateOfDivorce'));
      setBrother3DateofDeath(localStorage.getItem('customerThirdBrotherDateOfDeath'));

      setSister1BirthDay(localStorage.getItem('customerFirstSisterBirthDay'));
      setSister1DateofDivorce(localStorage.getItem('customerFirstSisterDateOfDivorce'));
      setSister1DateofDeath(localStorage.getItem('customerFirstSisterDateOfDeath'));

      setSister2BirthDay(localStorage.getItem('customerSecondSisterBirthDay'));
      setSister2DateofDivorce(localStorage.getItem('customerSecondSisterDateOfDivorce'));
      setSister2DateofDeath(localStorage.getItem('customerSecondSisterDateOfDeath'));

      setSister3BirthDay(localStorage.getItem('customerThirdSisterBirthDay'));
      setSister3DateofDivorce(localStorage.getItem('customerThirdSisterDateOfDivorce'));
      setSister3DateofDeath(localStorage.getItem('customerThirdSisterDateOfDeath'));
      }
      else if(localStorage.getItem('whoIs')=='sp'){
        values.FatherName = localStorage.getItem('customerSpouseFatherFirstName');
        values.FatherFamily = localStorage.getItem('customerSpouseFatherSurname');
        values.FatherJob = localStorage.getItem('customerSpouseFatherJob');
        values.FathersResidenceAddress = localStorage.getItem('customerSpouseFathersResidenceAddress');
        values.FatherPostalCode = localStorage.getItem('customerSpouseFatherPostalCode');
        values.FatherPhoneCall = localStorage.getItem('customerSpouseFatherPhoneNumber');
        values.FatherEducation = localStorage.getItem('customerSpouseFatherEducation');
        values.FatherFinancialAbility = localStorage.getItem('customerSpouseFatherFinancialAbility');
        values.FathersForeignTrip1 = localStorage.getItem('customerSpouseFathersForeignTripNumberOne');
        values.FathersForeignTrip2 = localStorage.getItem('customerSpouseFathersForeignTripNumberTwo');
        values.FathersForeignTrip3 = localStorage.getItem('customerSpouseFathersForeignTripNumberThree');
        values.FathersForeignTrip4 = localStorage.getItem('customerSpouseFathersForeignTripNumberFour');
        values.FathersForeignTrip5 = localStorage.getItem('customerSpouseFathersForeignTripNumberFive');
        values.FathersForeignTrip6 = localStorage.getItem('customerSpouseFathersForeignTripNumberSix');
        values.FathersForeignTrip7 = localStorage.getItem('customerSpouseFathersForeignTripNumberSeven');
        values.FathersForeignTrip8 = localStorage.getItem('customerSpouseFathersForeignTripNumberEight');
        values.FathersForeignTrip9 = localStorage.getItem('customerSpouseFathersForeignTripNumberNine');
  
        values.MotherName = localStorage.getItem('customerSpouseMotherFirstName');
        values.MotherFamily = localStorage.getItem('customerSpouseMotherSurname');
        values.MotherJob = localStorage.getItem('customerSpouseMotherJob');
        values.MothersResidenceAddress = localStorage.getItem('customerSpouseMotherResidenceAddress');
        values.MotherPostalCode = localStorage.getItem('customerSpouseMotherPostalCode');
        values.MotherPhoneCall = localStorage.getItem('customerSpouseMotherPhoneNumber');
        values.MotherEducation = localStorage.getItem('customerSpouseMotherEducation');
        values.MotherFinancialAbility = localStorage.getItem('customerSpouseMotherFinancialAbility');
        values.MothersForeignTrip1 = localStorage.getItem('customerSpouseMothersForeignTripNumberOne');
        values.MothersForeignTrip2 = localStorage.getItem('customerSpouseMothersForeignTripNumberTwo');
        values.MothersForeignTrip3 = localStorage.getItem('customerSpouseMothersForeignTripNumberThree');
        values.MothersForeignTrip4 = localStorage.getItem('customerSpouseMothersForeignTripNumberFour');
        values.MothersForeignTrip5 = localStorage.getItem('customerSpouseMothersForeignTripNumberFive');
        values.MothersForeignTrip6 = localStorage.getItem('customerSpouseMothersForeignTripNumberSix');
        values.MothersForeignTrip7 = localStorage.getItem('customerSpouseMothersForeignTripNumberSeven');
        values.MothersForeignTrip8 = localStorage.getItem('customerSpouseMothersForeignTripNumberEight');
        values.MothersForeignTrip9 = localStorage.getItem('customerSpouseMothersForeignTripNumberNine');
  
        values.Brother1Name = localStorage.getItem('customerSpouseFirstBrotherFirstName');
        values.Brother1Family = localStorage.getItem('customerSpouseFirstBrotherSurname');
        values.Brother1Job = localStorage.getItem('customerSpouseFirstBrotherJob');
        values.Brother1sResidenceAddress = localStorage.getItem('customerSpouseFirstBrotherResidenceAddress');
        values.Brother1PostalCode = localStorage.getItem('customerSpouseFirstBrotherPostalCode');
        values.Brother1PhoneCall = localStorage.getItem('customerSpouseFirstBrotherPhoneNumber');
        values.Brother1Education = localStorage.getItem('customerSpouseFirstBrotherEducation');
        values.Brother1FinancialAbility = localStorage.getItem('customerSpouseFirstBrotherFinancialAbility');
        values.Brother1sForeignTrip1 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberOne');
        values.Brother1sForeignTrip2 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberTwo');
        values.Brother1sForeignTrip3 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberThree');
        values.Brother1sForeignTrip4 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberFour');
        values.Brother1sForeignTrip5 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberFive');
        values.Brother1sForeignTrip6 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberSix');
        values.Brother1sForeignTrip7 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberSeven');
        values.Brother1sForeignTrip8 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberEight');
        values.Brother1sForeignTrip9 = localStorage.getItem('customerSpouseFirstBrothersForeignTripNumberNine');
  
        values.Brother2Name = localStorage.getItem('customerSpouseSecondBrotherFirstName');
        values.Brother2Family = localStorage.getItem('customerSpouseSecondBrotherSurname');
        values.Brother2Job = localStorage.getItem('customerSpouseSecondBrotherJob');
        values.Brother2sResidenceAddress = localStorage.getItem('customerSpouseSecondBrotherResidenceAddress');
        values.Brother2PostalCode = localStorage.getItem('customerSpouseSecondBrotherPostalCode');
        values.Brother2PhoneCall = localStorage.getItem('customerSpouseSecondBrotherPhoneNumber');
        values.Brother2Education = localStorage.getItem('customerSpouseSecondBrotherEducation');
        values.Brother2FinancialAbility = localStorage.getItem('customerSpouseSecondBrotherFinancialAbility');
        values.Brother2sForeignTrip1 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberOne');
        values.Brother2sForeignTrip2 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberTwo');
        values.Brother2sForeignTrip3 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberThree');
        values.Brother2sForeignTrip4 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberFour');
        values.Brother2sForeignTrip5 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberFive');
        values.Brother2sForeignTrip6 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberSix');
        values.Brother2sForeignTrip7 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberSeven');
        values.Brother2sForeignTrip8 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberEight');
        values.Brother2sForeignTrip9 = localStorage.getItem('customerSpouseSecondBrothersForeignTripNumberNine');
  
        values.Brother3Name = localStorage.getItem('customerSpouseThirdBrotherFirstName');
        values.Brother3Family = localStorage.getItem('customerSpouseThirdBrotherSurname');
        values.Brother3Job = localStorage.getItem('customerSpouseThirdBrotherJob');
        values.Brother3sResidenceAddress = localStorage.getItem('customerSpouseThirdBrotherResidenceAddress');
        values.Brother3PostalCode = localStorage.getItem('customerSpouseThirdBrotherPostalCode');
        values.Brother3PhoneCall = localStorage.getItem('customerSpouseThirdBrotherPhoneNumber');
        values.Brother3Education = localStorage.getItem('customerSpouseThirdBrotherEducation');
        values.Brother3FinancialAbility = localStorage.getItem('customerSpouseThirdBrotherFinancialAbility');
        values.Brother3sForeignTrip1 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberOne');
        values.Brother3sForeignTrip2 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberTwo');
        values.Brother3sForeignTrip3 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberThree');
        values.Brother3sForeignTrip4 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberFour');
        values.Brother3sForeignTrip5 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberFive');
        values.Brother3sForeignTrip6 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberSix');
        values.Brother3sForeignTrip7 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberSeven');
        values.Brother3sForeignTrip8 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberEight');
        values.Brother3sForeignTrip9 = localStorage.getItem('customerSpouseThirdBrothersForeignTripNumberNine');
  
        values.Sister1Name = localStorage.getItem('customerSpouseFirstSisterFirstName');
        values.Sister1Family = localStorage.getItem('customerSpouseFirstSisterSurname');
        values.Sister1Job = localStorage.getItem('customerSpouseFirstSisterJob');
        values.Sister1sResidenceAddress = localStorage.getItem('customerSpouseFirstSisterResidenceAddress');
        values.Sister1PostalCode = localStorage.getItem('customerSpouseFirstSisterPostalCode');
        values.Sister1PhoneCall = localStorage.getItem('customerSpouseFirstSisterPhoneNumber');
        values.Sister1Education = localStorage.getItem('customerSpouseFirstSisterEducation');
        values.Sister1FinancialAbility = localStorage.getItem('customerSpouseFirstSisterFinancialAbility');
        values.Sister1sForeignTrip1 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberOne');
        values.Sister1sForeignTrip2 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberTwo');
        values.Sister1sForeignTrip3 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberThree');
        values.Sister1sForeignTrip4 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberFour');
        values.Sister1sForeignTrip5 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberFive');
        values.Sister1sForeignTrip6 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberSix');
        values.Sister1sForeignTrip7 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberSeven');
        values.Sister1sForeignTrip8 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberEight');
        values.Sister1sForeignTrip9 = localStorage.getItem('customerSpouseFirstSistersForeignTripNumberNine');
  
        values.Sister2Name = localStorage.getItem('customerSpouseSecondSisterSecondName');
        values.Sister2Family = localStorage.getItem('customerSpouseSecondSisterSurname');
        values.Sister2Job = localStorage.getItem('customerSpouseSecondSisterJob');
        values.Sister2sResidenceAddress = localStorage.getItem('customerSpouseSecondSisterResidenceAddress');
        values.Sister2PostalCode = localStorage.getItem('customerSpouseSecondSisterPostalCode');
        values.Sister2PhoneCall = localStorage.getItem('customerSpouseSecondSisterPhoneNumber');
        values.Sister2Education = localStorage.getItem('customerSpouseSecondSisterEducation');
        values.Sister2FinancialAbility = localStorage.getItem('customerSpouseSecondSisterFinancialAbility');
        values.Sister2sForeignTrip1 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberOne');
        values.Sister2sForeignTrip2 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberTwo');
        values.Sister2sForeignTrip3 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberThree');
        values.Sister2sForeignTrip4 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberFour');
        values.Sister2sForeignTrip5 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberFive');
        values.Sister2sForeignTrip6 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberSix');
        values.Sister2sForeignTrip7 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberSeven');
        values.Sister2sForeignTrip8 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberEight');
        values.Sister2sForeignTrip9 = localStorage.getItem('customerSpouseSecondSistersForeignTripNumberNine');
  
        values.Sister3Name = localStorage.getItem('customerSpouseThirdSisterThirdName');
        values.Sister3Family = localStorage.getItem('customerSpouseThirdSisterSurname');
        values.Sister3Job = localStorage.getItem('customerSpouseThirdSisterJob');
        values.Sister3sResidenceAddress = localStorage.getItem('customerSpouseThirdSisterResidenceAddress');
        values.Sister3PostalCode = localStorage.getItem('customerSpouseThirdSisterPostalCode');
        values.Sister3PhoneCall = localStorage.getItem('customerSpouseThirdSisterPhoneNumber');
        values.Sister3Education = localStorage.getItem('customerSpouseThirdSisterEducation');
        values.Sister3FinancialAbility = localStorage.getItem('customerSpouseThirdSisterFinancialAbility');
        values.Sister3sForeignTrip1 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberOne');
        values.Sister3sForeignTrip2 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberTwo');
        values.Sister3sForeignTrip3 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberThree');
        values.Sister3sForeignTrip4 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberFour');
        values.Sister3sForeignTrip5 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberFive');
        values.Sister3sForeignTrip6 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberSix');
        values.Sister3sForeignTrip7 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberSeven');
        values.Sister3sForeignTrip8 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberEight');
        values.Sister3sForeignTrip9 = localStorage.getItem('customerSpouseThirdSistersForeignTripNumberNine');
  
        setFatherBirthDay(localStorage.getItem('customerSpouseFatherBirthDay'));
        setFatherDateofDivorce(localStorage.getItem('customerSpouseFatherDateOfDivorce'));
        setFatherDateofDeath(localStorage.getItem('customerSpouseFatherDateOfDeath'));
  
        setMotherBirthDay(localStorage.getItem('customerSpouseMotherBirthDay'));
        setMotherDateofDivorce(localStorage.getItem('customerSpouseMotherDateOfDivorce'));
        setMotherDateofDeath(localStorage.getItem('customerSpouseMotherDateOfDeath'));
  
        setBrother1BirthDay(localStorage.getItem('customerSpouseFirstBrotherBirthDay'));
        setBrother1DateofDivorce(localStorage.getItem('customerSpouseFirstBrotherDateOfDivorce'));
        setBrother1DateofDeath(localStorage.getItem('customerSpouseFirstBrotherDateOfDeath'));
  
        setBrother2BirthDay(localStorage.getItem('customerSpouseSecondBrotherBirthDay'));
        setBrother2DateofDivorce(localStorage.getItem('customerSpouseSecondBrotherDateOfDivorce'));
        setBrother2DateofDeath(localStorage.getItem('customerSpouseSecondBrotherDateOfDeath'));
  
        setBrother3BirthDay(localStorage.getItem('customerSpouseThirdBrotherBirthDay'));
        setBrother3DateofDivorce(localStorage.getItem('customerSpouseThirdBrotherDateOfDivorce'));
        setBrother3DateofDeath(localStorage.getItem('customerSpouseThirdBrotherDateOfDeath'));
  
        setSister1BirthDay(localStorage.getItem('customerSpouseFirstSisterBirthDay'));
        setSister1DateofDivorce(localStorage.getItem('customerSpouseFirstSisterDateOfDivorce'));
        setSister1DateofDeath(localStorage.getItem('customerSpouseFirstSisterDateOfDeath'));
  
        setSister2BirthDay(localStorage.getItem('customerSpouseSecondSisterBirthDay'));
        setSister2DateofDivorce(localStorage.getItem('customerSpouseSecondSisterDateOfDivorce'));
        setSister2DateofDeath(localStorage.getItem('customerSpouseSecondSisterDateOfDeath'));
  
        setSister3BirthDay(localStorage.getItem('customerSpouseThirdSisterBirthDay'));
        setSister3DateofDivorce(localStorage.getItem('customerSpouseThirdSisterDateOfDivorce'));
        setSister3DateofDeath(localStorage.getItem('customerSpouseThirdSisterDateOfDeath'));
      }
        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */



const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


          const [image13 , setImage13] = useState(null);
          const   onImage13Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader13 = new FileReader();
                reader13.onload = (e) => {
                  setImage13(e.target.result);
                  document.getElementById('target13').style.display ="block";
                  document.getElementById('MyGrayBox13').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader13.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage13 = () =>{
              document.getElementById('target13').style.display ="none";
              document.getElementById('MyGrayBox13').style.display= "flex";
            }


          const [image14 , setImage14] = useState(null);
          const   onImage14Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader14 = new FileReader();
                reader14.onload = (e) => {
                  setImage14(e.target.result);
                  document.getElementById('target14').style.display ="block";
                  document.getElementById('MyGrayBox14').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader14.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage14 = () =>{
              document.getElementById('target14').style.display ="none";
              document.getElementById('MyGrayBox14').style.display= "flex";
            }


            const [image15 , setImage15] = useState(null);
            const   onImage15Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader15 = new FileReader();
                  reader15.onload = (e) => {
                    setImage15(e.target.result);
                    document.getElementById('target15').style.display ="block";
                    document.getElementById('MyGrayBox15').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader15.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage15 = () =>{
                document.getElementById('target15').style.display ="none";
                document.getElementById('MyGrayBox15').style.display= "flex";
              }


            const [image16 , setImage16] = useState(null);
            const   onImage16Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader16 = new FileReader();
                  reader16.onload = (e) => {
                    setImage16(e.target.result);
                    document.getElementById('target16').style.display ="block";
                    document.getElementById('MyGrayBox16').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader16.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage16 = () =>{
                document.getElementById('target16').style.display ="none";
                document.getElementById('MyGrayBox16').style.display= "flex";
              }


            const [image17 , setImage17] = useState(null);
            const   onImage17Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader17 = new FileReader();
                  reader17.onload = (e) => {
                    setImage17(e.target.result);
                    document.getElementById('target17').style.display ="block";
                    document.getElementById('MyGrayBox17').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader17.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage17 = () =>{
                document.getElementById('target17').style.display ="none";
                document.getElementById('MyGrayBox17').style.display= "flex";
              }


              const [image18 , setImage18] = useState(null);
              const   onImage18Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader18 = new FileReader();
                    reader18.onload = (e) => {
                      setImage18(e.target.result);
                      document.getElementById('target18').style.display ="block";
                      document.getElementById('MyGrayBox18').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader18.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage18 = () =>{
                  document.getElementById('target18').style.display ="none";
                  document.getElementById('MyGrayBox18').style.display= "flex";
                }


              const [image19 , setImage19] = useState(null);
              const   onImage19Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader19 = new FileReader();
                    reader19.onload = (e) => {
                      setImage19(e.target.result);
                      document.getElementById('target19').style.display ="block";
                      document.getElementById('MyGrayBox19').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader19.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage19 = () =>{
                  document.getElementById('target19').style.display ="none";
                  document.getElementById('MyGrayBox19').style.display= "flex";
                }


              const [image20 , setImage20] = useState(null);
              const   onImage20Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader20 = new FileReader();
                    reader20.onload = (e) => {
                      setImage20(e.target.result);
                      document.getElementById('target20').style.display ="block";
                      document.getElementById('MyGrayBox20').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader20.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage20 = () =>{
                  document.getElementById('target20').style.display ="none";
                  document.getElementById('MyGrayBox20').style.display= "flex";
                }


            const [image21 , setImage21] = useState(null);
            const   onImage21Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader21 = new FileReader();
                  reader21.onload = (e) => {
                    setImage21(e.target.result);
                    document.getElementById('target21').style.display ="block";
                    document.getElementById('MyGrayBox21').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader21.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage21 = () =>{
                document.getElementById('target21').style.display ="none";
                document.getElementById('MyGrayBox21').style.display= "flex";
              }


            const [image22 , setImage22] = useState(null);
            const   onImage22Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader22 = new FileReader();
                  reader22.onload = (e) => {
                    setImage22(e.target.result);
                    document.getElementById('target22').style.display ="block";
                    document.getElementById('MyGrayBox22').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader22.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage22 = () =>{
                document.getElementById('target22').style.display ="none";
                document.getElementById('MyGrayBox22').style.display= "flex";
              }


            const [image23 , setImage23] = useState(null);
            const   onImage23Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader23 = new FileReader();
                  reader23.onload = (e) => {
                    setImage23(e.target.result);
                    document.getElementById('target23').style.display ="block";
                    document.getElementById('MyGrayBox23').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader23.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage23 = () =>{
                document.getElementById('target23').style.display ="none";
                document.getElementById('MyGrayBox23').style.display= "flex";
              }


          const [image24 , setImage24] = useState(null);
          const   onImage24Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader24 = new FileReader();
                reader24.onload = (e) => {
                  setImage24(e.target.result);
                  document.getElementById('target24').style.display ="block";
                  document.getElementById('MyGrayBox24').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader24.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage24 = () =>{
              document.getElementById('target24').style.display ="none";
              document.getElementById('MyGrayBox24').style.display= "flex";
            }

          const [image25 , setImage25] = useState(null);
          const   onImage25Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader25 = new FileReader();
                reader25.onload = (e) => {
                  setImage25(e.target.result);
                  document.getElementById('target25').style.display ="block";
                  document.getElementById('MyGrayBox25').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader25.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage25 = () =>{
              document.getElementById('target25').style.display ="none";
              document.getElementById('MyGrayBox25').style.display= "flex";
            }

          const [image26 , setImage26] = useState(null);
          const   onImage26Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader26 = new FileReader();
                reader26.onload = (e) => {
                  setImage26(e.target.result);
                  document.getElementById('target26').style.display ="block";
                  document.getElementById('MyGrayBox26').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader26.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage26 = () =>{
              document.getElementById('target26').style.display ="none";
              document.getElementById('MyGrayBox26').style.display= "flex";
            }

          const [image27 , setImage27] = useState(null);
          const   onImage27Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader27 = new FileReader();
                reader27.onload = (e) => {
                  setImage27(e.target.result);
                  document.getElementById('target27').style.display ="block";
                  document.getElementById('MyGrayBox27').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader27.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage27 = () =>{
              document.getElementById('target27').style.display ="none";
              document.getElementById('MyGrayBox27').style.display= "flex";
            }

          const [image28 , setImage28] = useState(null);
          const   onImage28Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader28 = new FileReader();
                reader28.onload = (e) => {
                  setImage28(e.target.result);
                  document.getElementById('target28').style.display ="block";
                  document.getElementById('MyGrayBox28').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader28.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage28 = () =>{
              document.getElementById('target28').style.display ="none";
              document.getElementById('MyGrayBox28').style.display= "flex";
            }

          const [image29 , setImage29] = useState(null);
          const   onImage29Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader29 = new FileReader();
                reader29.onload = (e) => {
                  setImage29(e.target.result);
                  document.getElementById('target29').style.display ="block";
                  document.getElementById('MyGrayBox29').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader29.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage29 = () =>{
              document.getElementById('target29').style.display ="none";
              document.getElementById('MyGrayBox29').style.display= "flex";
            }
            
        const [FatherBirthDay, setFatherBirthDay] = useState();
        const [FatherDateofDivorce, setFatherDateofDivorce] = useState();
        const [FatherDateofDeath, setFatherDateofDeath] = useState();

        const [MotherBirthDay, setMotherBirthDay] = useState();
        const [MotherDateofDivorce, setMotherDateofDivorce] = useState();
        const [MotherDateofDeath, setMotherDateofDeath] = useState();

        const [Brother1BirthDay, setBrother1BirthDay] = useState();
        const [Brother1DateofDivorce, setBrother1DateofDivorce] = useState();
        const [Brother1DateofDeath, setBrother1DateofDeath] = useState();

        const [Brother2BirthDay, setBrother2BirthDay] = useState();
        const [Brother2DateofDivorce, setBrother2DateofDivorce] = useState();
        const [Brother2DateofDeath, setBrother2DateofDeath] = useState();

        const [Brother3BirthDay, setBrother3BirthDay] = useState();
        const [Brother3DateofDivorce, setBrother3DateofDivorce] = useState();
        const [Brother3DateofDeath, setBrother3DateofDeath] = useState();

        const [Sister1BirthDay, setSister1BirthDay] = useState();
        const [Sister1DateofDivorce, setSister1DateofDivorce] = useState();
        const [Sister1DateofDeath, setSister1DateofDeath] = useState();

        const [Sister2BirthDay, setSister2BirthDay] = useState();
        const [Sister2DateofDivorce, setSister2DateofDivorce] = useState();
        const [Sister2DateofDeath, setSister2DateofDeath] = useState();

        const [Sister3BirthDay, setSister3BirthDay] = useState();
        const [Sister3DateofDivorce, setSister3DateofDivorce] = useState();
        const [Sister3DateofDeath, setSister3DateofDeath] = useState();

        const [DateofEnteringUniversityFogDiplom , setDateofEnteringUniversityFogDiplom ] = useState()
        const [GraduationDateFogDiplom , setGraduationDateFogDiplom ] = useState()
        const [DateofEnteringUniversityBachelor , setDateofEnteringUniversityBachelor ] = useState()
        const [GraduationDateBachelor , setGraduationDateBachelor ] = useState()
        const [DateofEnteringUniversityMasters , setDateofEnteringUniversityMasters ] = useState()
        const [GraduationDateMasters , setGraduationDateMasters ] = useState()
        const [DateofEnteringUniversityPHD , setDateofEnteringUniversityPHD ] = useState()
        const [GraduationDatePHD , setGraduationDatePHD ] = useState()
        const [DateofEnteringUniversityPostDoctoral , setDateofEnteringUniversityPostDoctoral ] = useState()
        const [GraduationDatePostDoctoral , setGraduationDatePostDoctoral ] = useState()
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"><p className="Cus2Step1GoldP">{t('Step 6')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Family Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicants Spouse Family Information')}</p>  <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Prime Family Information')}</p> </div>
          
          <div className="Cus2Inputs">

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EnglishLanguageStatus"
                  value={values.EnglishLanguageStatus}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EnglishLanguageStatus')}  
                  placeholder={t('English Language Status')}
                  >
                    <option  value="">
                    {t('English Language Status')}
                  </option >
                  <option  value='1'>{t('I Have')}</option >
                  <option  value='2'>{t('I do not have')}</option >
                  <option  value='3'>{t('I am reading')}</option >
                  <option  value='4'>{t('I will be able to get a degree in the next 6 months')}</option >
                  <option  value='5'>{t('I will not be able to get a degree for the next 8 months')}</option > 
                </select >
                <label htmlFor="EnglishLanguageStatus">{t('Enter the applicant’s English Language Status')}</label>
            </div> */}

           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Father')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherName" 
                value={values.FatherName}
                type="text"
                placeholder={t('Father’s Name')}
                onChange={handleChange('FatherName')}
                />
                <label htmlFor="FatherName">{t('Enter the applicant’s Father Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherFamily" 
                value={values.FatherFamily}
                type="text"
                placeholder={t('Father’s Family')}
                onChange={handleChange('FatherFamily')}
                />
                <label htmlFor="FatherFamily">{t('Enter the applicant’s Father Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherJob" 
                value={values.FatherJob}
                type="text"
                placeholder={t('Father’s Job')}
                onChange={handleChange('FatherJob')}
                />
                <label htmlFor="FatherJob">{t('Enter the applicant’s Father Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherBirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Father’s Birth Day')}
              id="FatherBirthDay"
              onChange={setFatherBirthDay} 
              className="col-12"
            />}
              <label htmlFor="FatherBirthDay">{t('Enter the applicant’s Father Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="FathersResidenceAddress" 
                value={values.FathersResidenceAddress}
                type="text"
                placeholder={t('Father’s Residence Address')}
                onChange={handleChange('FathersResidenceAddress')}
                />
                <label htmlFor="FathersResidenceAddress">{t('Enter the applicant’s Fathers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherPostalCode" 
                value={values.FatherPostalCode}
                type="text"
                placeholder={t('Father’s Postal Code')}
                onChange={handleChange('FatherPostalCode')}
                />
                <label htmlFor="FatherPostalCode">{t('Enter the applicant’s Father Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherPhoneCall" 
                value={values.FatherPhoneCall}
                type="text"
                placeholder={t('Father’s Phone Call')}
                onChange={handleChange('FatherPhoneCall')}
                />
                <label htmlFor="FatherPhoneCall">{t('Enter the applicant’s Father Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherEducation" 
                value={values.FatherEducation}
                type="text"
                placeholder={t('Father’s Education')}
                onChange={handleChange('FatherEducation')}
                />
                <label htmlFor="FatherEducation">{t('Enter the applicant’s Father’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherFinancialAbility" 
                value={values.FatherFinancialAbility}
                type="text"
                placeholder={t('Father’s Financial Ability')}
                onChange={handleChange('FatherFinancialAbility')}
                />
                <label htmlFor="FatherFinancialAbility">{t('Enter the applicant’s Father’s Financial Ability')}</label>
            </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherDateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Father’s Date of Divorce')}
              id="FatherDateofDivorce"
              onChange={setFatherDateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="FatherDateofDivorce">{t('Enter the applicant’s Father’s Date of Divorce')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherDateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Father’s Date of Death')}
              id="FatherDateofDeath"
              onChange={setFatherDateofDeath} 
              className="col-12"
            />}
              <label htmlFor="FatherDateofDeath">{t('Enter the applicant’s Father’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip1" 
                value={values.FathersForeignTrip1}
                type="text"
                placeholder={t('Father’s Foreign Trip (1)')}
                onChange={handleChange('FathersForeignTrip1')}
                />
                <label htmlFor="FathersForeignTrip1">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip2" 
                value={values.FathersForeignTrip2}
                type="text"
                placeholder={t('Father’s Foreign Trip (2)')}
                onChange={handleChange('FathersForeignTrip2')}
                />
                <label htmlFor="FathersForeignTrip2">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip3" 
                value={values.FathersForeignTrip3}
                type="text"
                placeholder={t('Father’s Foreign Trip (3)')}
                onChange={handleChange('FathersForeignTrip3')}
                />
                <label htmlFor="FathersForeignTrip3">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip4" 
                value={values.FathersForeignTrip4}
                type="text"
                placeholder={t('Father’s Foreign Trip (4)')}
                onChange={handleChange('FathersForeignTrip4')}
                />
                <label htmlFor="FathersForeignTrip4">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip5" 
                value={values.FathersForeignTrip5}
                type="text"
                placeholder={t('Father’s Foreign Trip (5)')}
                onChange={handleChange('FathersForeignTrip5')}
                />
                <label htmlFor="FathersForeignTrip5">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip6" 
                value={values.FathersForeignTrip6}
                type="text"
                placeholder={t('Father’s Foreign Trip (6)')}
                onChange={handleChange('FathersForeignTrip6')}
                />
                <label htmlFor="FathersForeignTrip6">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip7" 
                value={values.FathersForeignTrip7}
                type="text"
                placeholder={t('Father’s Foreign Trip (7)')}
                onChange={handleChange('FathersForeignTrip7')}
                />
                <label htmlFor="FathersForeignTrip7">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip8" 
                value={values.FathersForeignTrip8}
                type="text"
                placeholder={t('Father’s Foreign Trip (8)')}
                onChange={handleChange('FathersForeignTrip8')}
                />
                <label htmlFor="FathersForeignTrip8">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip9" 
                value={values.FathersForeignTrip9}
                type="text"
                placeholder={t('Father’s Foreign Trip (9)')}
                onChange={handleChange('FathersForeignTrip9')}
                />
                <label htmlFor="FathersForeignTrip9">{t('Enter the applicant’s Father’s Foreign Trip')}</label>
            </div>

            

            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Mother')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherName" 
                value={values.MotherName}
                type="text"
                placeholder={t('Mother’s Name')}
                onChange={handleChange('MotherName')}
                />
                <label htmlFor="MotherName">{t('Enter the applicant’s Mother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherFamily" 
                value={values.MotherFamily}
                type="text"
                placeholder={t('Mother’s Family')}
                onChange={handleChange('MotherFamily')}
                />
                <label htmlFor="MotherFamily">{t('Enter the applicant’s Mother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherJob" 
                value={values.MotherJob}
                type="text"
                placeholder={t('Mother’s Job')}
                onChange={handleChange('MotherJob')}
                />
                <label htmlFor="MotherJob">{t('Enter the applicant’s Mother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherBirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Mother’s Birth Day')}
              id="MotherBirthDay"
              onChange={setMotherBirthDay} 
              className="col-12"
            />}
              <label htmlFor="MotherBirthDay">{t('Enter the applicant’s Mother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="MothersResidenceAddress" 
                value={values.MothersResidenceAddress}
                type="text"
                placeholder={t('Mother’s Residence Address')}
                onChange={handleChange('MothersResidenceAddress')}
                />
                <label htmlFor="MothersResidenceAddress">{t('Enter the applicant’s Mothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherPostalCode" 
                value={values.MotherPostalCode}
                type="text"
                placeholder={t('Mother’s Postal Code')}
                onChange={handleChange('MotherPostalCode')}
                />
                <label htmlFor="MotherPostalCode">{t('Enter the applicant’s Mother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherPhoneCall" 
                value={values.MotherPhoneCall}
                type="text"
                placeholder={t('Mother’s Phone Call')}
                onChange={handleChange('MotherPhoneCall')}
                />
                <label htmlFor="MotherPhoneCall">{t('Enter the applicant’s Mother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherEducation" 
                value={values.MotherEducation}
                type="text"
                placeholder={t('Mother’s Education')}
                onChange={handleChange('MotherEducation')}
                />
                <label htmlFor="MotherEducation">{t('Enter the applicant’s Mother’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherFinancialAbility" 
                value={values.MotherFinancialAbility}
                type="text"
                placeholder={t('Mother’s Financial Ability')}
                onChange={handleChange('MotherFinancialAbility')}
                />
                <label htmlFor="MotherFinancialAbility">{t('Enter the applicant’s Mother’s Financial Ability')}</label>
            </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherDateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Mother’s Date of Divorce')}
              id="MotherDateofDivorce"
              onChange={setMotherDateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="MotherDateofDivorce">{t('Enter the applicant’s Mother’s Date of Divorce')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherDateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Mother’s Date of Death')}
              id="MotherDateofDeath"
              onChange={setMotherDateofDeath} 
              className="col-12"
            />}
              <label htmlFor="MotherDateofDeath">{t('Enter the applicant’s Mother’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip1" 
                value={values.MothersForeignTrip1}
                type="text"
                placeholder={t('Mother’s Foreign Trip (1)')}
                onChange={handleChange('MothersForeignTrip1')}
                />
                <label htmlFor="MothersForeignTrip1">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip2" 
                value={values.MothersForeignTrip2}
                type="text"
                placeholder={t('Mother’s Foreign Trip (2)')}
                onChange={handleChange('MothersForeignTrip2')}
                />
                <label htmlFor="MothersForeignTrip2">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip3" 
                value={values.MothersForeignTrip3}
                type="text"
                placeholder={t('Mother’s Foreign Trip (3)')}
                onChange={handleChange('MothersForeignTrip3')}
                />
                <label htmlFor="MothersForeignTrip3">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip4" 
                value={values.MothersForeignTrip4}
                type="text"
                placeholder={t('Mother’s Foreign Trip (4)')}
                onChange={handleChange('MothersForeignTrip4')}
                />
                <label htmlFor="MothersForeignTrip4">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip5" 
                value={values.MothersForeignTrip5}
                type="text"
                placeholder={t('Mother’s Foreign Trip (5)')}
                onChange={handleChange('MothersForeignTrip5')}
                />
                <label htmlFor="MothersForeignTrip5">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip6" 
                value={values.MothersForeignTrip6}
                type="text"
                placeholder={t('Mother’s Foreign Trip (6)')}
                onChange={handleChange('MothersForeignTrip6')}
                />
                <label htmlFor="MothersForeignTrip6">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip7" 
                value={values.MothersForeignTrip7}
                type="text"
                placeholder={t('Mother’s Foreign Trip (7)')}
                onChange={handleChange('MothersForeignTrip7')}
                />
                <label htmlFor="MothersForeignTrip7">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip8" 
                value={values.MothersForeignTrip8}
                type="text"
                placeholder={t('Mother’s Foreign Trip (8)')}
                onChange={handleChange('MothersForeignTrip8')}
                />
                <label htmlFor="MothersForeignTrip8">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip9" 
                value={values.MothersForeignTrip9}
                type="text"
                placeholder={t('Mother’s Foreign Trip (9)')}
                onChange={handleChange('MothersForeignTrip9')}
                />
                <label htmlFor="MothersForeignTrip9">{t('Enter the applicant’s Mother’s Foreign Trip')}</label>
            </div>






            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (1)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Name" 
                value={values.Brother1Name}
                type="text"
                placeholder={t('1th Brother’s Name') }
                onChange={handleChange('Brother1Name')}
                />
                <label htmlFor="Brother1Name">{t('Enter the applicant’s 1th Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Family" 
                value={values.Brother1Family}
                type="text"
                placeholder={t('1th Brother’s Family')}
                onChange={handleChange('Brother1Family')}
                />
                <label htmlFor="Brother1Family">{t('Enter the applicant’s 1th Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Job" 
                value={values.Brother1Job}
                type="text"
                placeholder={t('1th Brother’s Job')} 
                onChange={handleChange('Brother1Job')}
                />
                <label htmlFor="Brother1Job">{t('Enter the applicant’s 1th Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Brother’s Birth Day')}
              id="Brother1BirthDay"
              onChange={setBrother1BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother1BirthDay">{t('Enter the applicant’s 1th Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother1sResidenceAddress" 
                value={values.Brother1sResidenceAddress}
                type="text"
                placeholder={t('1th Brother’s Reside nce Address')}
                onChange={handleChange('Brother1sResidenceAddress')}
                />
                <label htmlFor="Brother1sResidenceAddress">{t('Enter the applicant’s 1th Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1PostalCode" 
                value={values.Brother1PostalCode}
                type="text"
                placeholder={t('1th Brother’s Postal Code')}
                onChange={handleChange('Brother1PostalCode')}
                />
                <label htmlFor="Brother1PostalCode">{t('Enter the applicant’s 1th Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1PhoneCall" 
                value={values.Brother1PhoneCall}
                type="text"
                placeholder={t('1th Brother’s Phone Call')}
                onChange={handleChange('Brother1PhoneCall')}
                />
                <label htmlFor="Brother1PhoneCall">{t('Enter the applicant’s 1th Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Education" 
                value={values.Brother1Education}
                type="text"
                placeholder={t('1th Brother’s Education')}
                onChange={handleChange('Brother1Education')}
                />
                <label htmlFor="Brother1Education">{t('Enter the applicant’s 1th Brother’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1FinancialAbility" 
                value={values.Brother1FinancialAbility}
                type="text"
                placeholder={t('1th Brother’s Financial Ability')}
                onChange={handleChange('Brother1FinancialAbility')}
                />
                <label htmlFor="Brother1FinancialAbility">{t('Enter the applicant’s 1th Brother’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Brother’s Date o f Divorce')}
              id="Brother1DateofDivorce"
              onChange={setBrother1DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother1DateofDivorce">{t('Enter the applicant’s 1th Brother’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Brother’s Date of Death')}
              id="Brother1DateofDeath"
              onChange={setBrother1DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother1DateofDeath">{t('Enter the applicant’s 1th Brother’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip1" 
                value={values.Brother1sForeignTrip1}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (1)')}
                onChange={handleChange('Brother1sForeignTrip1')}
                />
                <label htmlFor="Brother1sForeignTrip1">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip2" 
                value={values.Brother1sForeignTrip2}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (2)')}
                onChange={handleChange('Brother1sForeignTrip2')}
                />
                <label htmlFor="Brother1sForeignTrip2">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip3" 
                value={values.Brother1sForeignTrip3}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (3)')}
                onChange={handleChange('Brother1sForeignTrip3')}
                />
                <label htmlFor="Brother1sForeignTrip3">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip4" 
                value={values.Brother1sForeignTrip4}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (4)')}
                onChange={handleChange('Brother1sForeignTrip4')}
                />
                <label htmlFor="Brother1sForeignTrip4">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip5" 
                value={values.Brother1sForeignTrip5}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (5)')}
                onChange={handleChange('Brother1sForeignTrip5')}
                />
                <label htmlFor="Brother1sForeignTrip5">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip6" 
                value={values.Brother1sForeignTrip6}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (6)')}
                onChange={handleChange('Brother1sForeignTrip6')}
                />
                <label htmlFor="Brother1sForeignTrip6">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip7" 
                value={values.Brother1sForeignTrip7}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (7)')}
                onChange={handleChange('Brother1sForeignTrip7')}
                />
                <label htmlFor="Brother1sForeignTrip7">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip8" 
                value={values.Brother1sForeignTrip8}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (8)')}
                onChange={handleChange('Brother1sForeignTrip8')}
                />
                <label htmlFor="Brother1sForeignTrip8">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip9" 
                value={values.Brother1sForeignTrip9}
                type="text"
                placeholder={t('1th Brother’s Foreign Trip (9)')}
                onChange={handleChange('Brother1sForeignTrip9')}
                />
                <label htmlFor="Brother1sForeignTrip9">{t('Enter the applicant’s 1th Brother’s Foreign Trip')}</label>
            </div>




            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (2)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Name" 
                value={values.Brother2Name}
                type="text"
                placeholder={t('2nd Brother’s Name') }
                onChange={handleChange('Brother2Name')}
                />
                <label htmlFor="Brother2Name">{t('Enter the applicant’s 2nd Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Family" 
                value={values.Brother2Family}
                type="text"
                placeholder={t('2nd Brother’s Family')}
                onChange={handleChange('Brother2Family')}
                />
                <label htmlFor="Brother2Family">{t('Enter the applicant’s 2nd Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Job" 
                value={values.Brother2Job}
                type="text"
                placeholder={t('2nd Brother’s Job')} 
                onChange={handleChange('Brother2Job')}
                />
                <label htmlFor="Brother2Job">{t('Enter the applicant’s 2nd Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Brother’s Birth Day')}
              id="Brother2BirthDay"
              onChange={setBrother2BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother2BirthDay">{t('Enter the applicant’s 2nd Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother2sResidenceAddress" 
                value={values.Brother2sResidenceAddress}
                type="text"
                placeholder={t('2nd Brother’s Reside nce Address')}
                onChange={handleChange('Brother2sResidenceAddress')}
                />
                <label htmlFor="Brother2sResidenceAddress">{t('Enter the applicant’s 2nd Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2PostalCode" 
                value={values.Brother2PostalCode}
                type="text"
                placeholder={t('2nd Brother’s Postal Code')}
                onChange={handleChange('Brother2PostalCode')}
                />
                <label htmlFor="Brother2PostalCode">{t('Enter the applicant’s 2nd Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2PhoneCall" 
                value={values.Brother2PhoneCall}
                type="text"
                placeholder={t('2nd Brother’s Phone Call')}
                onChange={handleChange('Brother2PhoneCall')}
                />
                <label htmlFor="Brother2PhoneCall">{t('Enter the applicant’s 2nd Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Education" 
                value={values.Brother2Education}
                type="text"
                placeholder={t('2nd Brother’s Education')}
                onChange={handleChange('Brother2Education')}
                />
                <label htmlFor="Brother2Education">{t('Enter the applicant’s 2nd Brother’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2FinancialAbility" 
                value={values.Brother2FinancialAbility}
                type="text"
                placeholder={t('2nd Brother’s Financial Ability')}
                onChange={handleChange('Brother2FinancialAbility')}
                />
                <label htmlFor="Brother2FinancialAbility">{t('Enter the applicant’s 2nd Brother’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Brother’s Date o f Divorce')}
              id="Brother2DateofDivorce"
              onChange={setBrother2DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother2DateofDivorce">{t('Enter the applicant’s 2nd Brother’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Brother’s Date of Death')}
              id="Brother2DateofDeath"
              onChange={setBrother2DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother2DateofDeath">{t('Enter the applicant’s 2nd Brother’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip1" 
                value={values.Brother2sForeignTrip1}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (1)')}
                onChange={handleChange('Brother2sForeignTrip1')}
                />
                <label htmlFor="Brother2sForeignTrip1">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip2" 
                value={values.Brother2sForeignTrip2}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (2)')}
                onChange={handleChange('Brother2sForeignTrip2')}
                />
                <label htmlFor="Brother2sForeignTrip2">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip3" 
                value={values.Brother2sForeignTrip3}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (3)')}
                onChange={handleChange('Brother2sForeignTrip3')}
                />
                <label htmlFor="Brother2sForeignTrip3">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip4" 
                value={values.Brother2sForeignTrip4}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (4)')}
                onChange={handleChange('Brother2sForeignTrip4')}
                />
                <label htmlFor="Brother2sForeignTrip4">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip5" 
                value={values.Brother2sForeignTrip5}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (5)')}
                onChange={handleChange('Brother2sForeignTrip5')}
                />
                <label htmlFor="Brother2sForeignTrip5">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip6" 
                value={values.Brother2sForeignTrip6}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (6)')}
                onChange={handleChange('Brother2sForeignTrip6')}
                />
                <label htmlFor="Brother2sForeignTrip6">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip7" 
                value={values.Brother2sForeignTrip7}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (7)')}
                onChange={handleChange('Brother2sForeignTrip7')}
                />
                <label htmlFor="Brother2sForeignTrip7">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip8" 
                value={values.Brother2sForeignTrip8}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (8)')}
                onChange={handleChange('Brother2sForeignTrip8')}
                />
                <label htmlFor="Brother2sForeignTrip8">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip9" 
                value={values.Brother2sForeignTrip9}
                type="text"
                placeholder={t('2nd Brother’s Foreign Trip (9)')}
                onChange={handleChange('Brother2sForeignTrip9')}
                />
                <label htmlFor="Brother2sForeignTrip9">{t('Enter the applicant’s 2nd Brother’s Foreign Trip')}</label>
            </div>












            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (3)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Name" 
                value={values.Brother3Name}
                type="text"
                placeholder={t('3rd Brother’s Name') }
                onChange={handleChange('Brother3Name')}
                />
                <label htmlFor="Brother3Name">{t('Enter the applicant’s 3rd Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Family" 
                value={values.Brother3Family}
                type="text"
                placeholder={t('3rd Brother’s Family')}
                onChange={handleChange('Brother3Family')}
                />
                <label htmlFor="Brother3Family">{t('Enter the applicant’s 3rd Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Job" 
                value={values.Brother3Job}
                type="text"
                placeholder={t('3rd Brother’s Job')} 
                onChange={handleChange('Brother3Job')}
                />
                <label htmlFor="Brother3Job">{t('Enter the applicant’s 3rd Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Brother’s Birth Day')}
              id="Brother3BirthDay"
              onChange={setBrother3BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother3BirthDay">{t('Enter the applicant’s 3rd Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother3sResidenceAddress" 
                value={values.Brother3sResidenceAddress}
                type="text"
                placeholder={t('3rd Brother’s Reside nce Address')}
                onChange={handleChange('Brother3sResidenceAddress')}
                />
                <label htmlFor="Brother3sResidenceAddress">{t('Enter the applicant’s 3rd Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3PostalCode" 
                value={values.Brother3PostalCode}
                type="text"
                placeholder={t('3rd Brother’s Postal Code')}
                onChange={handleChange('Brother3PostalCode')}
                />
                <label htmlFor="Brother3PostalCode">{t('Enter the applicant’s 3rd Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3PhoneCall" 
                value={values.Brother3PhoneCall}
                type="text"
                placeholder={t('3rd Brother’s Phone Call')}
                onChange={handleChange('Brother3PhoneCall')}
                />
                <label htmlFor="Brother3PhoneCall">{t('Enter the applicant’s 3rd Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Education" 
                value={values.Brother3Education}
                type="text"
                placeholder={t('3rd Brother’s Education')}
                onChange={handleChange('Brother3Education')}
                />
                <label htmlFor="Brother3Education">{t('Enter the applicant’s 3rd Brother’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3FinancialAbility" 
                value={values.Brother3FinancialAbility}
                type="text"
                placeholder={t('3rd Brother’s Financial Ability')}
                onChange={handleChange('Brother3FinancialAbility')}
                />
                <label htmlFor="Brother3FinancialAbility">{t('Enter the applicant’s 3rd Brother’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Brother’s Date o f Divorce')}
              id="Brother3DateofDivorce"
              onChange={setBrother3DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother3DateofDivorce">{t('Enter the applicant’s 3rd Brother’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Brother’s Date of Death')}
              id="Brother3DateofDeath"
              onChange={setBrother3DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother3DateofDeath">{t('Enter the applicant’s 3rd Brother’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip1" 
                value={values.Brother3sForeignTrip1}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (1)')}
                onChange={handleChange('Brother3sForeignTrip1')}
                />
                <label htmlFor="Brother3sForeignTrip1">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip2" 
                value={values.Brother3sForeignTrip2}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (2)')}
                onChange={handleChange('Brother3sForeignTrip2')}
                />
                <label htmlFor="Brother3sForeignTrip2">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip3" 
                value={values.Brother3sForeignTrip3}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (3)')}
                onChange={handleChange('Brother3sForeignTrip3')}
                />
                <label htmlFor="Brother3sForeignTrip3">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip4" 
                value={values.Brother3sForeignTrip4}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (4)')}
                onChange={handleChange('Brother3sForeignTrip4')}
                />
                <label htmlFor="Brother3sForeignTrip4">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip5" 
                value={values.Brother3sForeignTrip5}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (5)')}
                onChange={handleChange('Brother3sForeignTrip5')}
                />
                <label htmlFor="Brother3sForeignTrip5">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip6" 
                value={values.Brother3sForeignTrip6}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (6)')}
                onChange={handleChange('Brother3sForeignTrip6')}
                />
                <label htmlFor="Brother3sForeignTrip6">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip7" 
                value={values.Brother3sForeignTrip7}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (7)')}
                onChange={handleChange('Brother3sForeignTrip7')}
                />
                <label htmlFor="Brother3sForeignTrip7">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip8" 
                value={values.Brother3sForeignTrip8}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (8)')}
                onChange={handleChange('Brother3sForeignTrip8')}
                />
                <label htmlFor="Brother3sForeignTrip8">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip9" 
                value={values.Brother3sForeignTrip9}
                type="text"
                placeholder={t('3rd Brother’s Foreign Trip (9)')}
                onChange={handleChange('Brother3sForeignTrip9')}
                />
                <label htmlFor="Brother3sForeignTrip9">{t('Enter the applicant’s 3rd Brother’s Foreign Trip')}</label>
            </div>




           
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (1)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Name" 
                value={values.Sister1Name}
                type="text"
                placeholder={t('1th Sister’s Name') }
                onChange={handleChange('Sister1Name')}
                />
                <label htmlFor="Sister1Name">{t('Enter the applicant’s 1th Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Family" 
                value={values.Sister1Family}
                type="text"
                placeholder={t('1th Sister’s Family')}
                onChange={handleChange('Sister1Family')}
                />
                <label htmlFor="Sister1Family">{t('Enter the applicant’s 1th Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Job" 
                value={values.Sister1Job}
                type="text"
                placeholder={t('1th Sister’s Job')} 
                onChange={handleChange('Sister1Job')}
                />
                <label htmlFor="Sister1Job">{t('Enter the applicant’s 1th Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Sister’s Birth Day')}
              id="Sister1BirthDay"
              onChange={setSister1BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister1BirthDay">{t('Enter the applicant’s 1th Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister1sResidenceAddress" 
                value={values.Sister1sResidenceAddress}
                type="text"
                placeholder={t('1th Sister’s Reside nce Address')}
                onChange={handleChange('Sister1sResidenceAddress')}
                />
                <label htmlFor="Sister1sResidenceAddress">{t('Enter the applicant’s 1th Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1PostalCode" 
                value={values.Sister1PostalCode}
                type="text"
                placeholder={t('1th Sister’s Postal Code')}
                onChange={handleChange('Sister1PostalCode')}
                />
                <label htmlFor="Sister1PostalCode">{t('Enter the applicant’s 1th Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1PhoneCall" 
                value={values.Sister1PhoneCall}
                type="text"
                placeholder={t('1th Sister’s Phone Call')}
                onChange={handleChange('Sister1PhoneCall')}
                />
                <label htmlFor="Sister1PhoneCall">{t('Enter the applicant’s 1th Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Education" 
                value={values.Sister1Education}
                type="text"
                placeholder={t('1th Sister’s Education')}
                onChange={handleChange('Sister1Education')}
                />
                <label htmlFor="Sister1Education">{t('Enter the applicant’s 1th Sister’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1FinancialAbility" 
                value={values.Sister1FinancialAbility}
                type="text"
                placeholder={t('1th Sister’s Financial Ability')}
                onChange={handleChange('Sister1FinancialAbility')}
                />
                <label htmlFor="Sister1FinancialAbility">{t('Enter the applicant’s 1th Sister’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Sister’s Date o f Divorce')}
              id="Sister1DateofDivorce"
              onChange={setSister1DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister1DateofDivorce">{t('Enter the applicant’s 1th Sister’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('1th Sister’s Date of Death')}
              id="Sister1DateofDeath"
              onChange={setSister1DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister1DateofDeath">{t('Enter the applicant’s 1th Sister’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip1" 
                value={values.Sister1sForeignTrip1}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (1)')}
                onChange={handleChange('Sister1sForeignTrip1')}
                />
                <label htmlFor="Sister1sForeignTrip1">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip2" 
                value={values.Sister1sForeignTrip2}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (2)')}
                onChange={handleChange('Sister1sForeignTrip2')}
                />
                <label htmlFor="Sister1sForeignTrip2">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip3" 
                value={values.Sister1sForeignTrip3}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (3)')}
                onChange={handleChange('Sister1sForeignTrip3')}
                />
                <label htmlFor="Sister1sForeignTrip3">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip4" 
                value={values.Sister1sForeignTrip4}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (4)')}
                onChange={handleChange('Sister1sForeignTrip4')}
                />
                <label htmlFor="Sister1sForeignTrip4">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip5" 
                value={values.Sister1sForeignTrip5}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (5)')}
                onChange={handleChange('Sister1sForeignTrip5')}
                />
                <label htmlFor="Sister1sForeignTrip5">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip6" 
                value={values.Sister1sForeignTrip6}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (6)')}
                onChange={handleChange('Sister1sForeignTrip6')}
                />
                <label htmlFor="Sister1sForeignTrip6">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip7" 
                value={values.Sister1sForeignTrip7}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (7)')}
                onChange={handleChange('Sister1sForeignTrip7')}
                />
                <label htmlFor="Sister1sForeignTrip7">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip8" 
                value={values.Sister1sForeignTrip8}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (8)')}
                onChange={handleChange('Sister1sForeignTrip8')}
                />
                <label htmlFor="Sister1sForeignTrip8">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip9" 
                value={values.Sister1sForeignTrip9}
                type="text"
                placeholder={t('1th Sister’s Foreign Trip (9)')}
                onChange={handleChange('Sister1sForeignTrip9')}
                />
                <label htmlFor="Sister1sForeignTrip9">{t('Enter the applicant’s 1th Sister’s Foreign Trip')}</label>
            </div>




            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (2)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Name" 
                value={values.Sister2Name}
                type="text"
                placeholder={t('2nd Sister’s Name') }
                onChange={handleChange('Sister2Name')}
                />
                <label htmlFor="Sister2Name">{t('Enter the applicant’s 2nd Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Family" 
                value={values.Sister2Family}
                type="text"
                placeholder={t('2nd Sister’s Family')}
                onChange={handleChange('Sister2Family')}
                />
                <label htmlFor="Sister2Family">{t('Enter the applicant’s 2nd Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Job" 
                value={values.Sister2Job}
                type="text"
                placeholder={t('2nd Sister’s Job')} 
                onChange={handleChange('Sister2Job')}
                />
                <label htmlFor="Sister2Job">{t('Enter the applicant’s 2nd Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Sister’s Birth Day')}
              id="Sister2BirthDay"
              onChange={setSister2BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister2BirthDay">{t('Enter the applicant’s 2nd Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister2sResidenceAddress" 
                value={values.Sister2sResidenceAddress}
                type="text"
                placeholder={t('2nd Sister’s Reside nce Address')}
                onChange={handleChange('Sister2sResidenceAddress')}
                />
                <label htmlFor="Sister2sResidenceAddress">{t('Enter the applicant’s 2nd Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2PostalCode" 
                value={values.Sister2PostalCode}
                type="text"
                placeholder={t('2nd Sister’s Postal Code')}
                onChange={handleChange('Sister2PostalCode')}
                />
                <label htmlFor="Sister2PostalCode">{t('Enter the applicant’s 2nd Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2PhoneCall" 
                value={values.Sister2PhoneCall}
                type="text"
                placeholder={t('2nd Sister’s Phone Call')}
                onChange={handleChange('Sister2PhoneCall')}
                />
                <label htmlFor="Sister2PhoneCall">{t('Enter the applicant’s 2nd Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Education" 
                value={values.Sister2Education}
                type="text"
                placeholder={t('2nd Sister’s Education')}
                onChange={handleChange('Sister2Education')}
                />
                <label htmlFor="Sister2Education">{t('Enter the applicant’s 2nd Sister’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2FinancialAbility" 
                value={values.Sister2FinancialAbility}
                type="text"
                placeholder={t('2nd Sister’s Financial Ability')}
                onChange={handleChange('Sister2FinancialAbility')}
                />
                <label htmlFor="Sister2FinancialAbility">{t('Enter the applicant’s 2nd Sister’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Sister’s Date o f Divorce')}
              id="Sister2DateofDivorce"
              onChange={setSister2DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister2DateofDivorce">{t('Enter the applicant’s 2nd Sister’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('2nd Sister’s Date of Death')}
              id="Sister2DateofDeath"
              onChange={setSister2DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister2DateofDeath">{t('Enter the applicant’s 2nd Sister’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip1" 
                value={values.Sister2sForeignTrip1}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (1)')}
                onChange={handleChange('Sister2sForeignTrip1')}
                />
                <label htmlFor="Sister2sForeignTrip1">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip2" 
                value={values.Sister2sForeignTrip2}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (2)')}
                onChange={handleChange('Sister2sForeignTrip2')}
                />
                <label htmlFor="Sister2sForeignTrip2">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip3" 
                value={values.Sister2sForeignTrip3}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (3)')}
                onChange={handleChange('Sister2sForeignTrip3')}
                />
                <label htmlFor="Sister2sForeignTrip3">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip4" 
                value={values.Sister2sForeignTrip4}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (4)')}
                onChange={handleChange('Sister2sForeignTrip4')}
                />
                <label htmlFor="Sister2sForeignTrip4">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip5" 
                value={values.Sister2sForeignTrip5}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (5)')}
                onChange={handleChange('Sister2sForeignTrip5')}
                />
                <label htmlFor="Sister2sForeignTrip5">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip6" 
                value={values.Sister2sForeignTrip6}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (6)')}
                onChange={handleChange('Sister2sForeignTrip6')}
                />
                <label htmlFor="Sister2sForeignTrip6">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip7" 
                value={values.Sister2sForeignTrip7}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (7)')}
                onChange={handleChange('Sister2sForeignTrip7')}
                />
                <label htmlFor="Sister2sForeignTrip7">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip8" 
                value={values.Sister2sForeignTrip8}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (8)')}
                onChange={handleChange('Sister2sForeignTrip8')}
                />
                <label htmlFor="Sister2sForeignTrip8">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip9" 
                value={values.Sister2sForeignTrip9}
                type="text"
                placeholder={t('2nd Sister’s Foreign Trip (9)')}
                onChange={handleChange('Sister2sForeignTrip9')}
                />
                <label htmlFor="Sister2sForeignTrip9">{t('Enter the applicant’s 2nd Sister’s Foreign Trip')}</label>
            </div>












            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (3)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Name" 
                value={values.Sister3Name}
                type="text"
                placeholder={t('3rd Sister’s Name') }
                onChange={handleChange('Sister3Name')}
                />
                <label htmlFor="Sister3Name">{t('Enter the applicant’s 3rd Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Family" 
                value={values.Sister3Family}
                type="text"
                placeholder={t('3rd Sister’s Family')}
                onChange={handleChange('Sister3Family')}
                />
                <label htmlFor="Sister3Family">{t('Enter the applicant’s 3rd Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Job" 
                value={values.Sister3Job}
                type="text"
                placeholder={t('3rd Sister’s Job')} 
                onChange={handleChange('Sister3Job')}
                />
                <label htmlFor="Sister3Job">{t('Enter the applicant’s 3rd Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3BirthDay}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Sister’s Birth Day')}
              id="Sister3BirthDay"
              onChange={setSister3BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister3BirthDay">{t('Enter the applicant’s 3rd Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister3sResidenceAddress" 
                value={values.Sister3sResidenceAddress}
                type="text"
                placeholder={t('3rd Sister’s Reside nce Address')}
                onChange={handleChange('Sister3sResidenceAddress')}
                />
                <label htmlFor="Sister3sResidenceAddress">{t('Enter the applicant’s 3rd Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3PostalCode" 
                value={values.Sister3PostalCode}
                type="text"
                placeholder={t('3rd Sister’s Postal Code')}
                onChange={handleChange('Sister3PostalCode')}
                />
                <label htmlFor="Sister3PostalCode">{t('Enter the applicant’s 3rd Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3PhoneCall" 
                value={values.Sister3PhoneCall}
                type="text"
                placeholder={t('3rd Sister’s Phone Call')}
                onChange={handleChange('Sister3PhoneCall')}
                />
                <label htmlFor="Sister3PhoneCall">{t('Enter the applicant’s 3rd Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Education" 
                value={values.Sister3Education}
                type="text"
                placeholder={t('3rd Sister’s Education')}
                onChange={handleChange('Sister3Education')}
                />
                <label htmlFor="Sister3Education">{t('Enter the applicant’s 3rd Sister’s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3FinancialAbility" 
                value={values.Sister3FinancialAbility}
                type="text"
                placeholder={t('3rd Sister’s Financial Ability')}
                onChange={handleChange('Sister3FinancialAbility')}
                />
                <label htmlFor="Sister3FinancialAbility">{t('Enter the applicant’s 3rd Sister’s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3DateofDivorce}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Sister’s Date o f Divorce')}
              id="Sister3DateofDivorce"
              onChange={setSister3DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister3DateofDivorce">{t('Enter the applicant’s 3rd Sister’s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3DateofDeath}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('3rd Sister’s Date of Death')}
              id="Sister3DateofDeath"
              onChange={setSister3DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister3DateofDeath">{t('Enter the applicant’s 3rd Sister’s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip1" 
                value={values.Sister3sForeignTrip1}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (1)')}
                onChange={handleChange('Sister3sForeignTrip1')}
                />
                <label htmlFor="Sister3sForeignTrip1">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip2" 
                value={values.Sister3sForeignTrip2}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (2)')}
                onChange={handleChange('Sister3sForeignTrip2')}
                />
                <label htmlFor="Sister3sForeignTrip2">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip3" 
                value={values.Sister3sForeignTrip3}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (3)')}
                onChange={handleChange('Sister3sForeignTrip3')}
                />
                <label htmlFor="Sister3sForeignTrip3">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip4" 
                value={values.Sister3sForeignTrip4}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (4)')}
                onChange={handleChange('Sister3sForeignTrip4')}
                />
                <label htmlFor="Sister3sForeignTrip4">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip5" 
                value={values.Sister3sForeignTrip5}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (5)')}
                onChange={handleChange('Sister3sForeignTrip5')}
                />
                <label htmlFor="Sister3sForeignTrip5">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip6" 
                value={values.Sister3sForeignTrip6}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (6)')}
                onChange={handleChange('Sister3sForeignTrip6')}
                />
                <label htmlFor="Sister3sForeignTrip6">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip7" 
                value={values.Sister3sForeignTrip7}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (7)')}
                onChange={handleChange('Sister3sForeignTrip7')}
                />
                <label htmlFor="Sister3sForeignTrip7">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip8" 
                value={values.Sister3sForeignTrip8}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (8)')}
                onChange={handleChange('Sister3sForeignTrip8')}
                />
                <label htmlFor="Sister3sForeignTrip8">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip9" 
                value={values.Sister3sForeignTrip9}
                type="text"
                placeholder={t('3rd Sister’s Foreign Trip (9)')}
                onChange={handleChange('Sister3sForeignTrip9')}
                />
                <label htmlFor="Sister3sForeignTrip9">{t('Enter the applicant’s 3rd Sister’s Foreign Trip')}</label>
            </div>



          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Prime Family Documents')}</p> 
          
          </div>
          
          
  <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImageChange} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target" src={image} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (1)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox2">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage2Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (2)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox3">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage3Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (3)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox4">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage4Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (4)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox5">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage5Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (5)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox6">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage6Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (6)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox7">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage7Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (7)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox8">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage8Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (8)')}</p> 
    </div>
    </div>

 


   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;