import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import mainPic from './404.png';
import teleLogo from './teleLogo.png';
import { Link } from "react-router-dom";
import {Facebook,Whatsapp,CallCalling,Instagram,Youtube} from 'iconsax-react';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='main404 col-12'>
            {/* <div className="myreq"><p>{t('My 404')}</p></div> */}
            <div className="cus1Pic404">
                <div className="teleLogo404Div col-12">
                    <img className='teleLogo404' src={teleLogo} alt="" />
                </div>
                <div className='mainPic404Div col-12'>
                    <img src={mainPic} alt="" />
                </div>
                
            </div>
            <div className="youdont404"> <p>{t('SORRY, PAGE NOT FOUND')}</p> </div>
            <div className="setupNew btn_404">
                <Link className='btn btn-dark' to='/'>{t('Return to Home Page')}</Link>
            </div>
            <div className="leftCircle404-01"></div>
            <div className="leftCircle404-02"></div>
            <div className="leftCircle404-03"></div>
        </div>
    )
}
 
export default Cus1;