import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import RegisterLogo from './images/LogoRegister.png';
import { Link } from "react-router-dom";
import {Facebook,Whatsapp,CallCalling,Instagram,Youtube,Home2,SearchNormal1} from 'iconsax-react';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='content_page'>
                <div className='header_page col-12'>
                    <div className='top_header_page col-md-11 col-12'>
                        <div className='titlehead_page col-4'>
                            <h3 className="font-face-gm">{t('Collaborations')}</h3>
                        </div>
                        <div className='logohead_page col-md-4 col-6'>
                            <img src={RegisterLogo} alt="" />
                        </div>
                        <Link className="font-face-gm icohead_page col-md-4 col-2" to='/'>
                            <Home2 color="white" size="24" />
                        </Link>
                    </div>
                </div>
                <div className='body_page col-12'>
                    <div className='txt_aboutus col-12'>
                        <p className="font-face-gm">{`Our system, based on two-sided collaborations and earning customers' satisfaction, has provided a situation for qualified members to connect by presenting their services and making money.
These collaborations, regarding the general conditions of users, are categorized into different groups:
`}</p>
                        <h3 className="font-face-gm">{`Business Owners' Collaborations`}</h3>
                        <p className="font-face-gm">{`Business owners can define their dashboard according to the services they provide. Their list of services is only accessible to distributers. Distributors can plan according to their list of services and costs and start a collaboration with the chosen business owner.`}</p>
                        <p className="font-face-gm">{`Business owners can collaborate with too many distributors, giving eye-catching bonuses, which saves marketing and advertising costs. Also, considering various situations, business owners reserve much time to use elsewhere.`}</p>
                        <p className="font-face-gm">{`Business owners can be in touch with their customers directly, apart from developing their network marketing by distributors. This part of the dashboard helps with providing services to customers and service management and accounting.`}</p>
                        <p className="font-face-gm">{`Cost management, payment management, and customer list are some of the numerous facilities of this part of our dashboards. Also, business owners can contact their colleagues, which could speed up the service delivery process to customers.`}</p>
                        <h3 className="font-face-gm">{`Distributors Collaboration`}</h3>
                        <p className="font-face-gm">{`Distributors generally fall into two categories: distributer pluses (+) and distributors ( we mentioned the difference between these two categories in the rules and conditions of using the dashboards before.)`}</p>
                        <p className="font-face-gm">{`Distributors play the leading role in this social network. In a way, they can affect a business owner's business improvement. This collaboration is two-sided, like with the business grows, distributors grow too. Distributors are directly in touch with business owners and present their services to their agents.`}</p>
                        <p className="font-face-gm">{`Distributors can benefit from a vast advertisement by developing a network of marketers. Also, they can connect with their colleagues to use each others' services. Distributors can generally contact business owners, other distributors, agents, and customers.`}</p>
                        <p className="font-face-gm">{`Distributer pluses can connect with the other distributor pluses in a VIP format and collaborate with them. Also, they can add regular distributors to their network and use their cooperation and marketing network to their benefit.`}</p>
                        <h3 className="font-face-gm">{`Agents' Collaboration`}</h3>
                        <p className="font-face-gm">{`Agents can use various services and costs of distributors and act better in attracting customers. The collaboration of agents with distributors is direct, and according to the offered services from their distributors, they can start attracting customers. Also, agents are directly in contact with their customers and can know the general situation of receiving benefits.`}</p>
                        <p className="font-face-gm">{`We've mentioned the rules and conditions of using dashboards or users in the "rules and conditions" part of the website. Considering the general conditions of each one, users can identify their roles in Telesiness. Also, we explained more partial rules in each dashboard for the user.`}</p>


                        <div className='mail_pagelog col-12'>
                            <span>{t('To Contact Us, just send us an email from the address below')+ ' : '}</span> <strong>{'support@telesiness.ir'}</strong>
                        </div>
                    </div>
                </div>
                <div className='footer_page col-12'>
                    <div className="about-us col-12">
                        <div className="spanAbout">
                        <p> 
                            <Link to="/services"> <span>{t('Services')+' '}</span></Link>
                            <span>|</span>  <Link to="/AboutusLogin"><span>{t('About')}</span></Link> <span>|</span>
                            <Link to="/CollaborationsLogin"><span> {t('Collaborations')}</span></Link> <span>|</span> 
                            <Link to="/TermsLogin"><span>{' '+t('Terms')}</span></Link> <span>|</span>
                            <Link to="/HelpLogin"><span> {t('Help')}</span></Link> <span>|</span> 
                            <Link to="/ContactUsLogin"><span>{' '+t('Contact Us')}</span></Link> 
                        </p>
                        </div>
                    </div>
                    <div className="footer-copy col-12">
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                    <div className="footer-copy2 col-12">
                    <br />
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                </div>
        </div>
    )
}
 
export default Cus1;