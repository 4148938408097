import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,InfoCircle,Warning2,DocumentDownload,MessageText1,LampCharge} from 'iconsax-react';
import logoDash from './images/tele.png';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import Popup2 from './Popup2'
import Popup3 from './Popup3'
import Popup4 from './Popup4'
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


const techCompanies = [
    {},
    { label: "یگانه طباطبایی", value: 1 },
    { label: "دکتر محسن یگانه", value: 2 },
    { label: "فریبرز ناهیدی", value: 3 },
    { label: "سودا قنادی", value: 4 },
    { label: "احمد احمدزاده", value: 5 },
    { label: "رعنا باقری", value: 6 },
    { label: "کامران افکاری", value: 7 },
  ];
  
  

const customStyles = {
option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
}),
control: (provided) => ({
    ...provided,
    marginTop: "5%",
})
}




const Customers = ({childToParentCus1e1 , childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {visa:'',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+',CustomerStatus:'Active',CustomerCreated:'', CustomerAgent:'',key:'1'},
     
      ]);


      const [ServicesPrice , setServicesPrice] = useState([
        {pen:"true",tick:"false",SName:'Business Visa', SPrice:'450',SFee:'4',SOther:'false',SOtherDesc:'24 ', key:'1000'},
      ]);


      const [ServicesPrice2 , setServicesPrice2] = useState([
        {
            email:"",
            lawyerCode:"",
            lawyerFirstName:'', 
            lawyerLastName:'',
            Lawyer1:'',
            Lawyer2:'',
            Lawyer3:'',
            Lawyer4:'',
            Lawyer5:'',
            Lawyer6:'',
            key:''},
      ]);

    
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleAddingServicetoPending = (itemKey,b) =>{
            setIsOpen4(!isOpen4);
            // console.log(selectValue.value);
            localStorage.setItem('LawCusUserID',itemKey);
            localStorage.setItem('LawCusCusCode',b);
            // if(values.SkinColor!=999 && values.SkinColor!=0)
            // {
            //     for(var i in people){
            //         if(people[i].key==itemKey){
            //             people[i].CustomerStatus='Active';
            //             setVisaId(i);
            //         }
                    
            //     }
            //     console.log(selectValue.value);
            // }
            
            // forceUpdate() ;
            // setPeople(people);
        
        }

    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey,CusCode,customerDocumentNumber) =>{
        localStorage.setItem('LawCusUserID',itemKey);
        localStorage.setItem('LawCusCode',CusCode);
        localStorage.setItem('customerDocumentNumber',customerDocumentNumber);
    setIsOpen(!isOpen);
   

}
const AddServicetoCustomer = (a,b,c,d) =>{
    fetch('https://telesiness.ir/api/Dashboards/CustomerServiceDocuments/1' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
             Email:  localStorage.getItem('email'),
             UserId:a,
             CustomerCode:b,
             CustomerServiceDocument: c,
             CustomerServiceDocumentCode:d,
            
            })
        }).then().then();

    setIsOpen4(!isOpen4);
}

const addLawyersToCus = (a,b,c,d,e,f,g) =>{
    console.log(a,b,c,d,e,f,g);
    fetch('https://telesiness.ir/api/Dashboards/AddLawyerToCustomer/1005' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
             Email:  localStorage.getItem('email'),
             UserId:a,
             CaseReviewLawyerOne:b,
             CaseReviewLawyerTwo: c,
             CaseReviewLawyerThree:d,
             CaseReviewLawyerFour:e,
             CaseReviewLawyerFive:f,
             CaseReviewLawyerSix:g,
            
            })
        }).then().then();

    setIsOpen5(!isOpen5);
}
    const  handleVisaPbox = (itemKey,CusCode,customerDocumentNumber) =>{
        fetch('https://telesiness.ir/api/Dashboards/ChangeCustomersDocumentStatusProgress' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                 Email:  localStorage.getItem('email'),
                 CustomerDocumentNumber:customerDocumentNumber,
                 CustomerCode:CusCode,
                 CustomerDocumentStatusProgress: '2',
                 CustomerDocumentStatusDescription:'Accepted Visa',
                })
            }).then().then();
            for(var i in people){
                if(people[i].key==itemKey){
                    people[i].CustomerStatus='2';
                    setVisaId(i);
                }
                
            }
            forceUpdate() ;
            setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey,CusCode,customerDocumentNumber) =>{
        fetch('https://telesiness.ir/api/Dashboards/ChangeCustomersDocumentStatusProgress' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                 Email:  localStorage.getItem('email'),
                 CustomerDocumentNumber:customerDocumentNumber,
                 CustomerCode:CusCode,
                 CustomerDocumentStatusProgress: '2',
                 CustomerDocumentStatusDescription:'Rejected Visa',
                })
            }).then().then();
            for(var i in people){
                if(people[i].key==itemKey){
                    people[i].CustomerStatus='2';
                    setVisaId(i);
                }
                
            }
            forceUpdate() ;
            setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey,CusCode,customerDocumentNumber) =>{
        fetch('https://telesiness.ir/api/Dashboards/ChangeCustomersDocumentStatusProgress' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                 Email:  localStorage.getItem('email'),
                 CustomerDocumentNumber:customerDocumentNumber,
                 CustomerCode:CusCode,
                 CustomerDocumentStatusProgress: '2',
                 CustomerDocumentStatusDescription:'Declined Visa',
                })
            }).then().then();
            for(var i in people){
                if(people[i].key==itemKey){
                    people[i].CustomerStatus='2';
                    setVisaId(i);
                }
                
            }
            forceUpdate() ;
            setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey,CusCode,customerDocumentNumber) =>{
        fetch('https://telesiness.ir/api/Dashboards/ChangeCustomersDocumentStatusProgress' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                 Email:  localStorage.getItem('email'),
                 CustomerDocumentNumber:customerDocumentNumber,
                 CustomerCode:CusCode,
                 CustomerDocumentStatusProgress: '1',
                 CustomerDocumentStatusDescription:'Active',
                })
            }).then().then();
            for(var i in people){
                if(people[i].key==itemKey){
                    people[i].CustomerStatus='1';
                    setVisaId(i);
                }
                
            }
            forceUpdate() ;
            setPeople(people);
        
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName > b.CustomerName) ? 1 : ((b.CustomerName > a.CustomerName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName < b.CustomerName) ? 1 : ((b.CustomerName < a.CustomerName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated > b.CustomerCreated) ? 1 : ((b.CustomerCreated > a.CustomerCreated) ? -1 : 0)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated < b.CustomerCreated) ? 1 : ((b.CustomerCreated < a.CustomerCreated) ? -1 : 0)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    const [filterBoxShowing2 , setFilterBoxShowing2] = useState(false);
    const handleFilterStatus2 = () =>{ 
        if(!filterBoxShowing2){
            document.getElementById("FilterStatusBoxID2").style.display="flex";
            setFilterBoxShowing2(true);
        }
        else{
            document.getElementById("FilterStatusBoxID2").style.display="none";
            setFilterBoxShowing2(false);
        }
        
    }
    const handleFilterStatusClose = () =>{ 
        if(!filterBoxShowing){
            // document.getElementById("FilterStatusBoxID").style.display="flex";
            // setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        if(!filterBoxShowing2){
            // document.getElementById("FilterStatusBoxID").style.display="flex";
            // setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID2").style.display="none";
            setFilterBoxShowing2(false);
        }
        
    }
    const [values, setValues] = useState({
        name: 'کامبیز',
        family:' فخر',
        gender:'مرد',
        IDNumber:'1379340969',
        NationalCode:'752',
        Email: '',
        CallNumber:'09143158460',
        phoneNumber:'09143158460',
        Country:'ایران',
        Address:'تبریز منظریه 10 متری 24 ام',
        ZipCode:'5174876552',
        PlaceBirth:'تبریز',


        Lawyer1:'',
        Lawyer2:'',
        Lawyer3:'',
        Lawyer4:'',
        Lawyer5:'',
        Lawyer6:'',


        PassPortName:'668521',
        CreationDate:'2021/02/06',
        ExpirationDate:'2025/02/31',
        PassPortCreationPlace:'ایران',
        CountryofResidence:'ایران',
        BirthDay:'1981/06/24',
        PlaceofIssuanceofNationalCard:'ایران',
        BirthCertificateNumber:'752',
        FathersNameontheBirthCertificate:'سید محمد رضا',
        MothersNameontheBirthCertificate:'سام وان',
        AliasNameSurnameorchangedintheidentitycard:'کافا',
        MilitaryCardNumber:'995233014',
        DateofMilitaryCard:'2008/02/30',
        TypeofMilitaryCard:'معافیت دائم',
        Height:'178',
        SkinColor:'برنزه',
        EyeColor:'عسلی',
        ZipCodeOne:'99621',
        AddressOne:'تبریز منظریه',
        ZipCodeTwo:'98145021',
        AddressTwo:'تهران ونک ظفر',
        CallNumberOne:'09143158460',
        PhoneNumberOne:'34798632',
        WorkplaceCallNumberOne:'33369521',
        CallNumberTwo:'09143158460',
        PhoneNumberTwo:'34798632',
        WorkplaceCallNumberTwo:'33369521',
        Email2: 'kambiz.fakhr@yahoo.com',
        TypeofMarriage:'متاهل',
        Country2:'ایران',
        TrackingNumber:'663201',



        EnglishLanguageStatus:'دارم',
        DateofIELTSdegree:'2021/03/01',
        totalscoreofthedegree:'7.5',
        ELTSReadingScore:'8',
        IELTSWritingScore:'6.5',
        IELTSListeningScore:'7',
        IELTSSpeackingScore:'6',
        DateofTOEFLdegree:'2020/06/30',
        totalscoreofthedegreeT:'98',
        TOEFLReadingScore:'90',
        TOEFLWritingScore:'102',
        TOEFLListeningScore:'110',
        TOEFLSpeackingScore:'79',
        DateofOtherLanguagedegree1:'2019/03/06',
        totalscoreoftheOtherLanguagedegree1:'C1',
        OtherLanguageReadingScore1:'C2',
        OtherLanguageWritingScore1:'B1',
        OtherLanguageListeningScore1:'B2',
        OtherLanguageSpeackingScore1:'C1',
        DateofOtherLanguagedegree2:'2022/01/05',
        totalscoreoftheOtherLanguagedegree2:'160',
        OtherLanguageReadingScore2:'120',
        OtherLanguageWritingScore2:'180',
        OtherLanguageListeningScore2:'190',
        OtherLanguageSpeackingScore2:'200',
        DateofOtherLanguagedegree3:'2021/12/25',
        totalscoreoftheOtherLanguagedegree3:'B2',
        OtherLanguageReadingScore3:'B1',
        OtherLanguageWritingScore3:'C1',
        OtherLanguageListeningScore3:'C1',
        OtherLanguageSpeackingScore3:'B2',



        LastEducationalCertificate:'',
        FieldofStudy:'',
        DateofEnteringHighSchool:'',
        GraduationDate:'',
        FieldofStudyinDiploma:'',
        DateofEnteringUniversityAD:'',
        UniversityNameAD:'',
        UniversityPlcaeAD:'',
        FieldofStudyAD:'',
        GraduationDateAD:'',
        DateofEnteringUniversityBD:'',
        UniversityNameBD:'',
        UniversityPlcaeBD:'',
        FieldofStudyBD:'',
        GraduationDateBD:'',
        DateofEnteringUniversityMD:'',
        UniversityNameMD:'',
        UniversityPlcaeMD:'',
        FieldofStudyMD:'',
        GraduationDateMD:'',
        DateofEnteringUniversityPHD:'',
        UniversityNamePHD:'',
        UniversityPlcaePHD:'',
        FieldofStudyPHD:'',
        GraduationDatePHD:'',
        DateofEnteringUniversityPoPHD:'',
        UniversityNamePoPHD:'',
        UniversityPlcaePoPHD:'',
        FieldofStudyPoPHD:'',
        GraduationDatePoPHD:'',
        NonuniversityCertificateName1:'',
        NonuniversityCertificateName2:'',
        NonuniversityCertificateName3:'',
        NonuniversityCertificateName4:'',
        NonuniversityCertificateName5:'',
        NonuniversityCertificateName6:'',


        SkinColor:'',



        password: '',
        checked: '',
        refCode: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if(values.SkinColor==999 && values.SkinColor==1000)

        
        {forceUpdate() ;
            for(var i in people){
                if(people[i].key==localStorage.getItem('SelectedListItemInLawCus')){
                    people[i].CustomerStatus='Pending';
                    setVisaId(i);
                }
                
            }
            console.log(values.SkinColor);
            
            setPeople(people);
            forceUpdate() ;
        }
        // if(values.SkinColor!=999 && values.SkinColor!=1000)
        // {forceUpdate() ;
        //     for(var i in people){
        //         if(people[i].key==localStorage.getItem('SelectedListItemInLawCus')){
        //             people[i].CustomerStatus='Active';
        //             setVisaId(i);
        //         }
                
        //     }
        //     console.log(values.SkinColor);
            
        //     setPeople(people);
        //     forceUpdate() ;
        // }

      }
    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomerByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(response=>{
                setIsOpen2(!isOpen2);
                values.Email='';
            })
        
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.CustomerStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.CustomerStatus == 'Deactive').length)));
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }

    const handleViewCusInfo = (itemKey) =>{
        setIsOpen3(!isOpen3);
        localStorage.setItem('Box2CusCode', itemKey);
    }

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen72, setIsOpen72] = useState(true);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

    const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    }

    const togglePopup4 = () => {
        setIsOpen4(!isOpen4);
        }

    const togglePopup5 = (itemkye) => {
        
        setIsOpen5(!isOpen5);
        }

    const togglePopup7 = (itemkye) => {
    
        setIsOpen7(!isOpen7);
        }
    const togglePopup72 = () => {
        setIsOpen72(!isOpen72);
        }
const [selectValue , setSelectValue] = useState({ label: "Select a Service", value: 999 });

const [isOpenRow, setisOpenRow] = useState(true);

const handleAgentRowClick = (itemKey) =>{
    if(isOpenRow){
        document.getElementById(itemKey).style.display="flex";
        setisOpenRow(!isOpenRow);
    }
    else{
        document.getElementById(itemKey).style.display="none";
        setisOpenRow(!isOpenRow);
    }
}
const handleSendMsgReDirect = (CustomerName) => {
    localStorage.setItem('CusNameMsg',CustomerName);
    
}

useEffect(() => {
    fetch('https://telesiness.ir/api/Dashboards/LawyersSelfCustomerList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
            togglePopup72();
             var i =0;
             try{ 
    
                if(people.length<2)
                {
                    people.pop();
                    var MyLawCusStat;
         
                    for (i in response.customersListView){
                        // console.log(response.distributorList[i].distributorFirstName);
                        // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                        // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
                        // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
                        if(response.customersListView[i].verifyCustomerByDistributor==0)
                        MyLawCusStat = 'Pending';
                        else if(response.customersListView[i].verifyCustomerByDistributor==1)
                        MyLawCusStat ='Active';
                        else
                        MyLawCusStat = 'Deactive';
                        people.push({
                             visa: response.customersListView[i].registerDate,
                            CustomerPic:response.customersListView[i].customerAvatar, 
                            CustomerName:response.customersListView[i].firstName + " " + 
                                 response.customersListView[i].lastName,
                            // CustomerPhone:response.customersListView[i].customerPhoneNumber,
                            CustomerStatus:response.customersListView[i].verifyCustomerByDistributor,
                            CustomerCreated:response.customersListView[i].registerDate, 
                            Email:response.customersListView[i].emailAddress,
                            customerCode : response.customersListView[i].customerCode,
                            phoneNumber: response.customersListView[i].phoneNumber,
                            // CustomerAgent:response.customersListView[i].customerDistributorFirstName + " " + 
                            // response.customersListView[i].customerDistributorLastName,
                            // CustomerLawyer:response.customersListView[i].lawyerFirstName + " " + 
                            // response.customersListView[i].lawyerLastName,
                            // customerDocumentId:response.customersListView[i].customerDocumentId,
                            L1N: response.customersListView[i].lawyerRelatedNumOneFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberOneLastName,
                            L2N: response.customersListView[i].lawyerRelatedNumberTwoFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberTwoFirstName,
                            L3N: response.customersListView[i].lawyerRelatedNumberThreeFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberThreeFirstName,
                            L4N: response.customersListView[i].lawyerRelatedNumberFourFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberFourFirstName,
                            L5N: response.customersListView[i].lawyerRelatedNumberFiveFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberFiveFirstName,
                            L6N: response.customersListView[i].lawyerRelatedNumberSixFirstName + ' ' + response.customersListView[i].lawyerRelatedNumberSixFirstName,
                            key:(parseInt(i)+1).toString(),
                            userId:response.customersListView[i].userId,
                            customerDocumentNumber:response.customersListView[i].customerDocumentNumber,
                            customerServiceCode:response.customersListView[i].customerServiceCode,
                            lawyerCode:response.customersListView[i].lawyerCode,
                            
                            radif: (parseInt(i)+1).toString(),
                        },)
                        
                    }
                }
            //    console.log(people);
                
             }
    
            catch{}
            // console.log(response.distributorList[0].distributorFirstName);
            //   forceUpdate();
            // window.location.reload(false);
            
        });
    
     
        
        fetch('https://telesiness.ir/api/Dashboards/GetLawyerTariffs' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email'),})
            }).then(response => response.json()).then(response=>{
                 var i =0;
                 try
                 {
                     
                    if(ServicesPrice.length<2)
                    {
                        ServicesPrice.pop();
                        var myActDec = ''
                    for (i in response){
                       
                        ServicesPrice.push( {pen:"true",tick:"false",
                        SName:response[i].nameOfVisa, 
                        lawyerTariffsCode:response[i].lawyerTariffsCode,
                        // SPrice:response[i].servicePrice,
                        // SFee:response[i].serviceOffer,
                        // distributorCode:response[i].distributorCode,
                        // SOtherDesc:response[i].requiredDocuments, 
                        key:i},)
                                     }
                     }
                 }
    
                catch{
    
                }
                // console.log(response[0]);
                
            });
    
    
            fetch('https://telesiness.ir/api/Dashboards/LawyersInvitedByLawyersList' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: localStorage.getItem('email'),})
                }).then(response => response.json()).then(response=>{
                     var i =0;
                     try
                     {
                         
                        if(ServicesPrice2.length<2)
                        {
                            ServicesPrice2.pop();
                            var myActDec = ''
                        for (i in response.lawyerList){
                           
                            ServicesPrice2.push( {pen:"true",tick:"false",
    
                            email:response.lawyerList[i].email,
                            lawyerCode:response.lawyerList[i].lawyerCode,
                            lawyerFirstName:response.lawyerList[i].lawyerFirstName, 
                            lawyerLastName:response.lawyerList[i].lawyerLastName,
                            // SOtherDesc:response[i].requiredDocuments, 
                            key:i},)
                                         }
                         }
                     }
        
                    catch{
        
                    }
                    // console.log(response[0]);
                    
                });
    
  }, []);



    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);
    
    useEffect(() => {
      if (counter.current < 1) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
  
        return () => clearTimeout(timer);
      }
    }, [state]);
const myFuncforCusInfo = (a,b) => {
    localStorage.setItem('emailCus',a);
    localStorage.setItem('CusCode',b);
}

const handleServiceInformation = (a,b,c) =>{
    fetch('https://telesiness.ir/api/Dashboards/GetLawyerServicesByDistributor' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email') ,
            LawyerCode:a,
            CustomerServiceCode:b, 
            CustomerCode:c
        })
        }).then(response => response.json()).then(response=>{
            localStorage.setItem('lawyerTariffsList',JSON.stringify(response.lawyerTariffsList));
            setIsOpen7(!isOpen7);
        });
    
}

    return (  
        <><div className="topMain top_listcuslaw col-12" onClick={handleFilterStatusClose}>
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Customers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                        <p className="pricesInfo">{t('Customers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserCustomer} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Customer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                
                <div className="col-3 Col-2Status">
                <p>{t('Customer')} <a onClick={handleUpArrowCustomer}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowCustomer}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p><div className="FilterIcon" onClick={handleFilterStatus2}></div> 
                    <div id="FilterStatusBoxID2" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Rezai')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Khoshbayan')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Fakhr')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Setaki')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        {/* <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div> */}
                    </div>
                </div>
                <div className="col-3 Col-2Status">
                    {/* <Filter  size="16"  color="#313131"/> */}
                    <p>{t('Status')} </p><div className="FilterIcon" onClick={handleFilterStatus}></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-2"><p>{t('View')}</p></div>
                <div className="col-2"><p>{t('Add Lawyer')}</p></div>
            </div>
            <hr className="CustomerLine"/>
           </div>
            <div className="mainRowCus col-12">
                {people.map(item =>(
                <div className="itemBox2 col-12"  key={item.key}>
                    <div className="agentCusRow col-12">
                        <a className="col-lg-4 col-8 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                            <div className="col-3 id_agentCus"><p>{item.key}</p> 
                                {item.CustomerStatus=='Pending' &&<div className="notif_daysWaiting" title='This Profile is More than 7 days Waiting For you Please Make Decesion'><LampCharge size="28" color="#E95060" variant="Bulk"/></div>}
                            </div>
                            <div className="CusPic2 col-lg-9 col-12">
                                <div className='img_agentCus'>
                                    <img src={'https://telesiness.ir/CustomersAvatar/'+ item.CustomerPic} alt="" />
                                </div> 
                                <div className='detail_agentCus'>
                                    <p>{item.CustomerName}</p>
                                    <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                </div>                           
                            </div>
                        </a>
                        {item.CustomerStatus=='1' && <div className="col-lg-3 col-4 de_active_agentCus">
                            <button onBlur={()=>handleStatusClickOut(item.key)} onClick={()=>{console.log(item.key); handleStatusClick(item.key);}} className="btn-sm active">
                                {t('Active') + ' | ' }<ArrowDown2  size="9" color="#4A9D77"/>
                                <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>{
                                     handleDeactiveClick(item.key, item.customerCode,item.customerDocumentNumber)}}>{t('Deactive')}</a>
                                </div>
                            </button>
                            {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                            <a>
                                <InfoCircle size="18" variant="Bold" color="#4A9D77"/>
                            </a>
                        </div>} 
                        <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div>
                        </div>}
                        {item.CustomerStatus=='0' && <div className="col-lg-3 col-4 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            <div className='btn btn-dark add_servCus' onClick={()=>handleAddingServicetoPending(item.userId,item.customerCode)}>{t('+ Services')}</div>
                        </div>}

                        {item.CustomerStatus=='2' && <div className="col-lg-3 col-4 de_active_agentCus">
                            <button  className="btn-sm deactive">
                                {t('Deactive')}
                                {/* <ArrowDown2  size="9" color="#E95060"/> */}
                                <div id={item.key} className="ChoosBox"> 
                                    <a onClick={()=>handleActiveClick(item.key,item.customerCode,item.customerDocumentNumber)}>{t('Active')}</a><br />
                                    <a className='disabled'>{t('Deactive')}</a>
                                </div>
                            </button>
                            {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                            <a>
                                <InfoCircle size="18" variant="Bold" color="#E95060"/>
                            </a>
                        </div>} 
                        <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div>
                    </div>}
                
                    <div className="col-2 view_infocus">
                        <div onClick={(dataCustomerHome) => {
                             if(item.CustomerStatus=='Active'){
                                localStorage.setItem('verifyCustomerByDistributor',1);
                            } else if(item.CustomerStatus=='Pending'){
                                localStorage.setItem('verifyCustomerByDistributor',0);
                            }
                            if(item.CustomerStatus=='Deactive'){
                                localStorage.setItem('verifyCustomerByDistributor',2);
                            }
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.customerCode);
                            console.log(localStorage.getItem('CusCode'));
                            // myFuncforCusInfo(item.Email,item.customerCode);
                            childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark btn_infoLawCus">{t('View')}</div>  
                    </div>
                    <div className="col-2 add_LawCus">
                        <div className='btn btn-dark btn_addLawCus' onClick={() => {
                            localStorage.setItem('LawyerCusUserId',item.userId);
                            togglePopup5()
                            }}>{t('+ Lawyer')}</div>
                    </div>  
                    <div className="Send_Message">
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                            document.getElementById('PeopleBtn').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.customerCode);
                            localStorage.setItem('MsgUserNewType',3);
                             childToParentMessage(dataMessage);
                              handleSendMsgReDirect(item.CustomerName)}}/>
                        <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                        {/* <div className='btn btn-dark btn_addLawCus' >{t('Send Message')}</div> */}
                    </div> 
                    </div> 
                    {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                            <hr className="linebetitem col-12"/>
                            <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                            document.getElementById('PeopleBtn').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.customerCode);
                            localStorage.setItem('MsgUserNewType',3);
                             childToParentMessage(dataMessage);
                              handleSendMsgReDirect(item.CustomerName)}}/>
                            <div className="d-lg-none col-12 hidenItem"><span>{t('ID')}:</span><p>{item.key}</p></div>
                            <div className="col-lg-6 col-12 hidenItem"><span>{t('Related lawyers')}:</span>
                            {item.L1N!=' ' &&<p> {item.L1N}</p>}
                            {item.L2N!=' ' && <p> {( ' - ' +item.L2N)}</p>}
                            {item.L3N!=' ' &&<p> {( ' - ' + item.L3N)} </p>} 
                            {item.L4N!=' ' &&<p> {( ' - ' + item.L4N)} </p>} 
                            {item.L5N!=' ' &&<p> {( ' - ' + item.L5N)} </p>}
                            {item.L6N!=' ' &&<p> {( ' - ' + item.L6N)}</p>}
                            </div>
                           
                            <div className="col-lg-6 col-12 hidenItem"><span>{t('Phone Number')}:</span><p>{item.phoneNumber}</p></div>
                            <div className="col-lg-6 col-12 hidenItem"><span>{t('Created')}:</span><p>{item.CustomerCreated.split('T',1)}</p></div>
                         
                            <div className="col-lg-12 col-md-5 col-12 btn_servlawcus">
                                <button onClick={() => {
                                    
                                        values.itemDCname = true;
                                
                                    handleServiceInformation(item.lawyerCode,item.customerServiceCode,item.customerCode);
                                    }} className="btn btn-dark btn_infoLawCus">{t('Lawyer service information')}</button >
                            </div>

                            <div className="d-lg-none col-md-7 col-12 hidenItem all_btnslawcus">
                                <div className='btn col-6 btn-dark btn_addLawCus' onClick={()=>{
                                    localStorage.setItem('LawyerCusUserId',item.userId);
                                    togglePopup5(item.key);
                                    }}>{t('+ Lawyer')}</div>
                                {item.CustomerStatus=='0' && <div className='btn col-6 btn-dark add_servCus' onClick={()=>handleAddingServicetoPending(item.userId,item.customerCode)}>{t('+ Services')}</div>}
                                <div onClick={(dataCustomerHome) => {
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.customerCode);
                            console.log(localStorage.getItem('CusCode'));
                            // myFuncforCusInfo(item.Email,item.customerCode);
                            childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark btn_infoLawCus">{t('View Information')}</div> 
                            </div>
                        </div>
                    </div>}
                    <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                </div>
                ))}
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why do You want to De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={() => {handleVisaPbox(
                        localStorage.getItem('LawCusUserID') , 
                        localStorage.getItem('LawCusCode') , 
                        localStorage.getItem('customerDocumentNumber') ,
                )}} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={() => {handleRejectedPbox(
                     localStorage.getItem('LawCusUserID') , 
                     localStorage.getItem('LawCusCode') , 
                     localStorage.getItem('customerDocumentNumber') ,
                )}} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={() =>{handleDeclinedPbox(
                     localStorage.getItem('LawCusUserID') , 
                     localStorage.getItem('LawCusCode') , 
                     localStorage.getItem('customerDocumentNumber') ,
                )}} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow"> 
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}


{isOpen5 && <Popup3
      content={<>
      <div className="PboxMainRow overheigthpbox">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a Lawyer for Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please Select Your Co-Lawyers for Your Customer.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Lawyers Name')}</label>
                <div className="input-groupLawyerCusServicePopUp">
                <div className="box_selectpbox">
               <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor1"
                        value={values.Lawyer1}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer1')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 1 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer1">{t('Enter the applicants Lawyers Num 1')}</label>

                <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor2"
                        value={values.Lawyer2}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer2')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 2 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer2">{t('Enter the applicants Lawyers Num 2')}</label>

                <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor3"
                        value={values.Lawyer3}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer3')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 3 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer3">{t('Enter the applicants Lawyers Num 3')}</label>

                <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor4"
                        value={values.Lawyer4}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer4')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 4 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer4">{t('Enter the applicants Lawyers Num 4')}</label>

                <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor5"
                        value={values.Lawyer5}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer5')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 5 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer5">{t('Enter the applicants Lawyers Num 5')}</label>

                <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor6"
                        value={values.Lawyer6}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('Lawyer6')}  
                        placeholder={t('Lawyer Name')}
                        >
                <option  value='1000'>
                {t('Lawyer 6 Name...')}</option >
                {ServicesPrice2.map(item =>(
                     <option  value={item.lawyerCode}>{item.lawyerFirstName + ' ' + item.lawyerLastName}</option >
                ))}
                            
                        </select >
                <label htmlFor="Lawyer6">{t('Enter the applicants Lawyers Num 6')}</label>
            </div>
                    <button onClick={()=> 
                    {
                        var s1 = '';
                        var s2 = '';
                        var s3 = '';
                        var s4 = '';
                        var s5 = '';
                        var s6 = '';
                        if(document.getElementById('SkinColor1').value=='1000')
                        s1 = ''
                        else
                        s1 = document.getElementById('SkinColor1').value;

                        if(document.getElementById('SkinColor2').value=='1000')
                        s2 = ''
                        else
                        s2 = document.getElementById('SkinColor2').value;

                        if(document.getElementById('SkinColor3').value=='1000')
                        s3 = ''
                        else
                        s3 = document.getElementById('SkinColor3').value;

                        if(document.getElementById('SkinColor4').value=='1000')
                        s4 = ''
                        else
                        s4 = document.getElementById('SkinColor4').value;

                        if(document.getElementById('SkinColor5').value=='1000')
                        s5 = ''
                        else
                        s5 = document.getElementById('SkinColor5').value;

                        if(document.getElementById('SkinColor6').value=='1000')
                        s6 = ''
                        else
                        s6 = document.getElementById('SkinColor6').value;
                        addLawyersToCus(localStorage.getItem('LawyerCusUserId'),
                        // values.Lawyer1,
                        // values.Lawyer2,
                        // values.Lawyer3,
                        // values.Lawyer4,
                        // values.Lawyer5
                        // ,values.Lawyer6
                        s1,
                        s2,
                        s3,
                        s4,
                        s5,
                        s6,
                        )}
                        } className="btn btn-primary btn_addlowyer" type="button" id="button-addon2">{t('All Done!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup5}
    />}

{isOpen4 && <Popup
      content={<>  
            <div className="PboxSecondRow">
                <h4>{t('Adding a Service to Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please Select a Service to customer.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Service Name')}</label>
                <div className="input-groupLawyerCusServicePopUp">
                    <div className="box_selectpbox">
                        <select
                        aria-label="Default"
                        // labelId="demo-simple-select-Degree-helper-label"
                        id="SkinColor"
                        value={values.SkinColor}
                        label={t('Skin Color')}
                        // MenuProps={MenuProps}
                        onChange={handleChange('SkinColor')}  
                        placeholder={t('Service Name')}
                        >
                <option  value='1000'>
                {t('Service Name...')}</option >
                {ServicesPrice.map(item =>(
                     <option  value={item.lawyerTariffsCode}>{item.SName}</option >
                ))}
                            
                        </select >
                        <label htmlFor="SkinColor">{t('Enter the applicants Service Name')}</label>
                    </div>
                    <button onClick={() => {
                        var sel = document.getElementById("SkinColor");

                        AddServicetoCustomer(
                            localStorage.getItem('LawCusUserID'),
                            localStorage.getItem('LawCusCusCode'),
                            sel.options[sel.selectedIndex].text,
                            document.getElementById('SkinColor').value
                            
                    )}} className="btn btn-primary" type="button" id="button-addon2">{t('All Done!')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup4}
    />}

{isOpen7 && <Popup4
      content={<>
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Services assigned by the lawyer')}</h4>
            </div>
           
            <div className="box_HomeLightBoxRowTwo col-12">
                        <div className="HomeLightBoxRowTwo col-12">
                            {/* <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                <AddSquare className='AddUserHome' onClick={childToParentPrices} size="40" color="#000000" variant="Bold"/>
                            </div>                              */}
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                {JSON.parse(localStorage.getItem('lawyerTariffsList')).length==0 &&
                                <div className="servassignlaw col-12">
                                    <div className="subLawyerTerafiDcCusRow noservlaw col-12">
                                        <p>{t('There is no service assigned yet.')}</p>   
                                    </div>
                           </div>}  
                                {JSON.parse(localStorage.getItem('lawyerTariffsList')).map(item =>(
                    <div  key={item.key}>
                        
                       <div className="servassignlaw col-12">
                      
                          <div className="LawyerTerafiDcCusRow col-12">
                           {/* {<div className="col-1 id_tariff"><p>{item.key+1}</p> </div>} */}
                           {<div className="subLawyerTerafiDcCusRow col-12"><p>{t('Name of Service') + " : "}</p><p>{item.nameOfVisa}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                {/* <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}
                            </div>}
                            {values.itemDCname &&<div className="subLawyerTerafiDcCusRow col-12"><p>{t('Price') + " : "}</p><p>{"$ " +item.wageFigure}</p></div>}
                            {values.itemDCname && <div className="subLawyerTerafiDcCusRow col-12"><p>{t('DC Commission') + " : "}</p><p>{"% " +item.percentageOfCommission}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Service Code') + " : "}</p><p>{"# " +item.lawyerTariffsCode}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Required Documents') + " : "}</p><p>{item.requiredDocuments}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Visa Process') + " : "}</p><p>{item.visaProcess}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('About Service') + " : "}</p><p>{item.aboutService}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
        
      </>}
      handleClose={togglePopup7}
    />}

{isOpen3 && <Popup2
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
            <div className="box2 col-12">
            <img src={logoDash} alt="" />
                <div className="box2Left">
                    <div className="CusCodeBox2">{t('Customer Code')}:{localStorage.getItem('Box2CusCode')}</div>
                    <div className="DownloadFormBox2">
                        {t('Download Form')}
                        <DocumentDownload className='DocumentDownloadBox2' size="28" color="#FF8A65"/>
                    </div>
                </div>
            </div>
            </div>
            <div className="MyMainContentBox2">
                <p className='LawyerBoxInfoTitr col-12'>{t('Basic Information')}</p>
            </div>
            <div className="MyMainContentBox2Warp col-12">
                {values.name!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='name'>{t('Name')}</label>
                    <label id='name'>{values.name}</label>
                </div>}

                {values.family!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='family'>{t('Family')}</label>
                    <label id='family'>{values.family}</label>
                </div>}

                {values.gender!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='gender'>{t('Gender')}</label>
                    <label id='gender'>{values.gender}</label>
                </div>}

                {values.IDNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='IDNumber'>{t('ID Number')}</label>
                    <label id='IDNumber'>{values.IDNumber}</label>
                </div>}

                {values.NationalCode!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='NationalCode'>{t('National Code')}</label>
                    <label id='NationalCode'>{values.NationalCode}</label>
                </div>}

                {values.Email!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Email'>{t('Email')}</label>
                    <label id='Email'>{values.Email}</label>
                </div>}

                {values.CallNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='CallNumber'>{t('Call Number')}</label>
                    <label id='CallNumber'>{values.CallNumber}</label>
                </div>}

                {values.phoneNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='phoneNumber'>{t('Phone Number')}</label>
                    <label id='phoneNumber'>{values.phoneNumber}</label>
                </div>}

                {values.Country!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Country'>{t('Country')}</label>
                    <label id='Country'>{values.Country}</label>
                </div>}

                {values.Address!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Address'>{t('Address')}</label>
                    <label id='Address'>{values.Address}</label>
                </div>}

                {values.ZipCode!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='ZipCode'>{t('Postal Code')}</label>
                    <label id='ZipCode'>{values.ZipCode}</label>
                </div>}

                {values.PlaceBirth!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PlaceBirth'>{t('Place of Birth')}</label>
                    <label id='PlaceBirth'>{values.PlaceBirth}</label>
                </div>}
            </div>

            <div className="MyMainContentBox2">
                <p className='LawyerBoxInfoTitr col-12'>{t('Applicant Personal Information')}</p>
            </div>
            <div className="MyMainContentBox2Warp col-12">
                {values.PassPortName!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PassPortName'>{t('PassPort Number')}</label>
                    <label id='PassPortName'>{values.PassPortName}</label>
                </div>}

                {values.CreationDate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='CreationDate'>{t('PassPort Creation Date')}</label>
                    <label id='CreationDate'>{values.CreationDate}</label>
                </div>}

                {values.ExpirationDate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='ExpirationDate'>{t('PassPort Expiration Date')}</label>
                    <label id='ExpirationDate'>{values.ExpirationDate}</label>
                </div>}

                {values.PassPortCreationPlace!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PassPortCreationPlace'>{t('PassPort Creation Place')}</label>
                    <label id='PassPortCreationPlace'>{values.PassPortCreationPlace}</label>
                </div>}

                {values.CountryofResidence!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='CountryofResidence'>{t('Country of Residence')}</label>
                    <label id='CountryofResidence'>{values.CountryofResidence}</label>
                </div>}

                {values.BirthDay!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='BirthDay'>{t('Birth Day')}</label>
                    <label id='BirthDay'>{values.BirthDay}</label>
                </div>}

                {values.PlaceofIssuanceofNationalCard!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PlaceofIssuanceofNationalCard'>{t('Place of Issuance of National Card')}</label>
                    <label id='PlaceofIssuanceofNationalCard'>{values.PlaceofIssuanceofNationalCard}</label>
                </div>}

                {values.BirthCertificateNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='BirthCertificateNumber'>{t('Birth Certificate Number')}</label>
                    <label id='BirthCertificateNumber'>{values.BirthCertificateNumber}</label>
                </div>}

                {values.FathersNameontheBirthCertificate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FathersNameontheBirthCertificate'>{t('Father’s Name on the Birth Certificate')}</label>
                    <label id='FathersNameontheBirthCertificate'>{values.FathersNameontheBirthCertificate}</label>
                </div>}

                {values.MothersNameontheBirthCertificate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='MothersNameontheBirthCertificate'>{t('Mother’s Name on the Birth Certificate')}</label>
                    <label id='MothersNameontheBirthCertificate'>{values.MothersNameontheBirthCertificate}</label>
                </div>}

                {values.AliasNameSurnameorchangedintheidentitycard!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='AliasNameSurnameorchangedintheidentitycard'>{t('Alias Name, Surname or changed in the identity card')}</label>
                    <label id='AliasNameSurnameorchangedintheidentitycard'>{values.AliasNameSurnameorchangedintheidentitycard}</label>
                </div>}

                {values.MilitaryCardNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='MilitaryCardNumber'>{t('Military Card Number')}</label>
                    <label id='MilitaryCardNumber'>{values.MilitaryCardNumber}</label>
                </div>}

                {values.DateofMilitaryCard!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofMilitaryCard'>{t('Date of Military Card')}</label>
                    <label id='DateofMilitaryCard'>{values.DateofMilitaryCard}</label>
                </div>}

                {values.TypeofMilitaryCard!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TypeofMilitaryCard'>{t('Type of Military Card')}</label>
                    <label id='TypeofMilitaryCard'>{values.TypeofMilitaryCard}</label>
                </div>}

                {values.Height!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Height'>{t('Height')}</label>
                    <label id='Height'>{values.Height}</label>
                </div>}

                {values.SkinColor!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='SkinColor'>{t('Skin Color')}</label>
                    <label id='SkinColor'>{values.SkinColor}</label>
                </div>}

                {values.EyeColor!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EyeColor'>{t('Eye Color')}</label>
                    <label id='EyeColor'>{values.EyeColor}</label>
                </div>}

                {values.ZipCodeOne!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='ZipCodeOne'>{t('Zip Code One')}</label>
                    <label id='ZipCodeOne'>{values.ZipCodeOne}</label>
                </div>}

                {values.AddressOne!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='AddressOne'>{t('Address One')}</label>
                    <label id='AddressOne'>{values.AddressOne}</label>
                </div>}

                {values.ZipCodeTwo!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='ZipCodeTwo'>{t('Postal Code Two')}</label>
                    <label id='ZipCodeTwo'>{values.ZipCodeTwo}</label>
                </div>}

                {values.AddressTwo!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='AddressTwo'>{t('Address Two')}</label>
                    <label id='AddressTwo'>{values.AddressTwo}</label>
                </div>}

                {values.CallNumberOne!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='CallNumberOne'>{t('Call Number One')}</label>
                    <label id='CallNumberOne'>{values.CallNumberOne}</label>
                </div>}

                {values.PhoneNumberOne!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PhoneNumberOne'>{t('Phone Number One')}</label>
                    <label id='PhoneNumberOne'>{values.PhoneNumberOne}</label>
                </div>}

                {values.WorkplaceCallNumberOne!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='WorkplaceCallNumberOne'>{t('Workplace Call Number One')}</label>
                    <label id='WorkplaceCallNumberOne'>{values.WorkplaceCallNumberOne}</label>
                </div>}

                {values.CallNumberTwo!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='CallNumberTwo'>{t('Call Number Two')}</label>
                    <label id='CallNumberTwo'>{values.CallNumberTwo}</label>
                </div>}

                {values.PhoneNumberTwo!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='PhoneNumberTwo'>{t('Phone Number Two')}</label>
                    <label id='PhoneNumberTwo'>{values.PhoneNumberTwo}</label>
                </div>}

                {values.WorkplaceCallNumberTwo!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='WorkplaceCallNumberTwo'>{t('Workplace Call Number Two')}</label>
                    <label id='WorkplaceCallNumberTwo'>{values.WorkplaceCallNumberTwo}</label>
                </div>}

                {values.Email2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Email2'>{t('Email')}</label>
                    <label id='Email2'>{values.Email2}</label>
                </div>}

                {values.TypeofMarriage!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TypeofMarriage'>{t('Type of Marriage')}</label>
                    <label id='TypeofMarriage'>{values.TypeofMarriage}</label>
                </div>}

                {values.Country2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='Country2'>{t('Country')}</label>
                    <label id='Country2'>{values.Country2}</label>
                </div>}

                {values.TrackingNumber!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TrackingNumber'>{t('Tracking Number')}</label>
                    <label id='TrackingNumber'>{values.TrackingNumber}</label>
                </div>}
            </div>




            <div className="MyMainContentBox2">
                <p className='LawyerBoxInfoTitr col-12'>{t('Applicant Language Information')}</p>
            </div>
            <div className="MyMainContentBox2Warp col-12">
                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.DateofIELTSdegree!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofIELTSdegree'>{t('Date of IELTS degree')}</label>
                    <label id='DateofIELTSdegree'>{values.DateofIELTSdegree}</label>
                </div>}

                {values.totalscoreofthedegree!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='totalscoreofthedegree'>{t('total score of the degree')}</label>
                    <label id='totalscoreofthedegree'>{values.totalscoreofthedegree}</label>
                </div>}

                {values.ELTSReadingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='ELTSReadingScore'>{t('ELTS Reading Score')}</label>
                    <label id='ELTSReadingScore'>{values.ELTSReadingScore}</label>
                </div>}

                {values.IELTSWritingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='IELTSWritingScore'>{t('IELTS Writing Score')}</label>
                    <label id='IELTSWritingScore'>{values.IELTSWritingScore}</label>
                </div>}

                {values.IELTSListeningScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='IELTSListeningScore'>{t('IELTS Listening Score')}</label>
                    <label id='IELTSListeningScore'>{values.IELTSListeningScore}</label>
                </div>}

                {values.IELTSSpeackingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='IELTSSpeackingScore'>{t('IELTS Speacking Score')}</label>
                    <label id='IELTSSpeackingScore'>{values.IELTSSpeackingScore}</label>
                </div>}

                {values.DateofTOEFLdegree!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofTOEFLdegree'>{t('Date of TOEFL degree')}</label>
                    <label id='DateofTOEFLdegree'>{values.DateofTOEFLdegree}</label>
                </div>}

                {values.totalscoreofthedegreeT!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='totalscoreofthedegreeT'>{t('total score of the degree')}</label>
                    <label id='totalscoreofthedegreeT'>{values.totalscoreofthedegreeT}</label>
                </div>}

                {values.TOEFLReadingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TOEFLReadingScore'>{t('TOEFL Reading Score')}</label>
                    <label id='TOEFLReadingScore'>{values.TOEFLReadingScore}</label>
                </div>}

                {values.TOEFLWritingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TOEFLWritingScore'>{t('TOEFL Writing Score')}</label>
                    <label id='TOEFLWritingScore'>{values.TOEFLWritingScore}</label>
                </div>}

                {values.TOEFLListeningScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TOEFLListeningScore'>{t('TOEFL Listening Score')}</label>
                    <label id='TOEFLListeningScore'>{values.TOEFLListeningScore}</label>
                </div>}

                {values.TOEFLSpeackingScore!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='TOEFLSpeackingScore'>{t('TOEFL Speacking Score')}</label>
                    <label id='TOEFLSpeackingScore'>{values.TOEFLSpeackingScore}</label>
                </div>}

                {values.DateofOtherLanguagedegree1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofOtherLanguagedegree1'>{t('Date of Other Language degree(1)')}</label>
                    <label id='DateofOtherLanguagedegree1'>{values.DateofOtherLanguagedegree1}</label>
                </div>}

                {values.totalscoreoftheOtherLanguagedegree1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='totalscoreoftheOtherLanguagedegree1'>{t('total score of the Other Language degree(1)')}</label>
                    <label id='totalscoreoftheOtherLanguagedegree1'>{values.totalscoreoftheOtherLanguagedegree1}</label>
                </div>}

                {values.OtherLanguageReadingScore1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageReadingScore1'>{t('Other Language Reading Score(1)')}</label>
                    <label id='OtherLanguageReadingScore1'>{values.OtherLanguageReadingScore1}</label>
                </div>}

                {values.OtherLanguageWritingScore1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageWritingScore1'>{t('Other Language Writing Score(1)')}</label>
                    <label id='OtherLanguageWritingScore1'>{values.OtherLanguageWritingScore1}</label>
                </div>}

                {values.OtherLanguageListeningScore1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageListeningScore1'>{t('Other Language Listening Score(1)')}</label>
                    <label id='OtherLanguageListeningScore1'>{values.OtherLanguageListeningScore1}</label>
                </div>}

                {values.OtherLanguageSpeackingScore1!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageSpeackingScore1'>{t('Other Language Speacking Score(1)')}</label>
                    <label id='OtherLanguageSpeackingScore1'>{values.OtherLanguageSpeackingScore1}</label>
                </div>}

                {values.DateofOtherLanguagedegree2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofOtherLanguagedegree2'>{t('Date of Other Language degree(2)')}</label>
                    <label id='DateofOtherLanguagedegree2'>{values.DateofOtherLanguagedegree2}</label>
                </div>}

                {values.totalscoreoftheOtherLanguagedegree2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='totalscoreoftheOtherLanguagedegree2'>{t('total score of the Other Language degree(2)')}</label>
                    <label id='totalscoreoftheOtherLanguagedegree2'>{values.totalscoreoftheOtherLanguagedegree2}</label>
                </div>}

                {values.OtherLanguageReadingScore2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageReadingScore2'>{t('Other Language Reading Score(2)')}</label>
                    <label id='OtherLanguageReadingScore2'>{values.OtherLanguageReadingScore2}</label>
                </div>}

                {values.OtherLanguageWritingScore2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageWritingScore2'>{t('Other Language Writing Score(2)')}</label>
                    <label id='OtherLanguageWritingScore2'>{values.OtherLanguageWritingScore2}</label>
                </div>}

                {values.OtherLanguageListeningScore2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageListeningScore2'>{t('Other Language Listening Score(2)')}</label>
                    <label id='OtherLanguageListeningScore2'>{values.OtherLanguageListeningScore2}</label>
                </div>}

                {values.OtherLanguageSpeackingScore2!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageSpeackingScore2'>{t('Other Language Speacking Score(2)')}</label>
                    <label id='OtherLanguageSpeackingScore2'>{values.OtherLanguageSpeackingScore2}</label>
                </div>}

                {values.DateofOtherLanguagedegree3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofOtherLanguagedegree3'>{t('Date of Other Language degree(3)')}</label>
                    <label id='DateofOtherLanguagedegree3'>{values.DateofOtherLanguagedegree3}</label>
                </div>}

                {values.totalscoreoftheOtherLanguagedegree3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='totalscoreoftheOtherLanguagedegree3'>{t('total score of the Other Language degree(3)')}</label>
                    <label id='totalscoreoftheOtherLanguagedegree3'>{values.totalscoreoftheOtherLanguagedegree3}</label>
                </div>}

                {values.OtherLanguageReadingScore3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageReadingScore3'>{t('Other Language Reading Score(3)')}</label>
                    <label id='OtherLanguageReadingScore3'>{values.OtherLanguageReadingScore3}</label>
                </div>}

                {values.OtherLanguageWritingScore3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageWritingScore3'>{t('Other Language Writing Score(3)')}</label>
                    <label id='OtherLanguageWritingScore3'>{values.OtherLanguageWritingScore3}</label>
                </div>}

                {values.OtherLanguageListeningScore3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageListeningScore3'>{t('Other Language Listening Score(3)')}</label>
                    <label id='OtherLanguageListeningScore3'>{values.OtherLanguageListeningScore3}</label>
                </div>}

                {values.OtherLanguageSpeackingScore3!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='OtherLanguageSpeackingScore3'>{t('Other Language Speacking Score(3)')}</label>
                    <label id='OtherLanguageSpeackingScore3'>{values.OtherLanguageSpeackingScore3}</label>
                </div>}
            </div>




            <div className="MyMainContentBox2">
                <p className='LawyerBoxInfoTitr col-12'>{t('Applicant Educational Information')}</p>
            </div>
            <div className="MyMainContentBox2Warp col-12">
                {values.LastEducationalCertificate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='LastEducationalCertificate'>{t('Last Educational Certificate')}</label>
                    <label id='LastEducationalCertificate'>{values.LastEducationalCertificate}</label>
                </div>}

                {values.FieldofStudy!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FieldofStudy'>{t('Field of Study')}</label>
                    <label id='FieldofStudy'>{values.FieldofStudy}</label>
                </div>}

                {values.DateofEnteringHighSchool!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofEnteringHighSchool'>{t('Date of Entering High School')}</label>
                    <label id='DateofEnteringHighSchool'>{values.DateofEnteringHighSchool}</label>
                </div>}

                {values.GraduationDate!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='GraduationDate'>{t('Graduation Date')}</label>
                    <label id='GraduationDate'>{values.GraduationDate}</label>
                </div>}

                {values.FieldofStudyinDiploma!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FieldofStudyinDiploma'>{t('Field of Study in Diploma')}</label>
                    <label id='FieldofStudyinDiploma'>{values.FieldofStudyinDiploma}</label>
                </div>}

                {values.DateofEnteringUniversityAD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofEnteringUniversityAD'>{t('Date of Entering University')}-{t('Associate Degree')}</label>
                    <label id='DateofEnteringUniversityAD'>{values.DateofEnteringUniversityAD}</label>
                </div>}

                {values.UniversityNameAD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityNameAD'>{t('University Name')}-{t('Associate Degree')}</label>
                    <label id='UniversityNameAD'>{values.UniversityNameAD}</label>
                </div>}

                {values.UniversityPlcaeAD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityPlcaeAD'>{t('University Plcae')}-{t('Associate Degree')}</label>
                    <label id='UniversityPlcaeAD'>{values.UniversityPlcaeAD}</label>
                </div>}

                {values.FieldofStudyAD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FieldofStudyAD'>{t('Field of Study')}-{t('Associate Degree')}</label>
                    <label id='FieldofStudyAD'>{values.FieldofStudyAD}</label>
                </div>}

                {values.GraduationDateAD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='GraduationDateAD'>{t('Graduation Date')}-{t('Associate Degree')}</label>
                    <label id='GraduationDateAD'>{values.GraduationDateAD}</label>
                </div>}

                {values.DateofEnteringUniversityBD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofEnteringUniversityBD'>{t('Date of Entering University')}-{t('Bachelor Degree')}</label>
                    <label id='DateofEnteringUniversityBD'>{values.DateofEnteringUniversityBD}</label>
                </div>}

                {values.UniversityNameBD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityNameBD'>{t('University Name')}-{t('Bachelor Degree')}</label>
                    <label id='UniversityNameBD'>{values.UniversityNameBD}</label>
                </div>}

                {values.UniversityPlcaeBD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityPlcaeBD'>{t('University Plcae')}-{t('Bachelor Degree')}</label>
                    <label id='UniversityPlcaeBD'>{values.UniversityPlcaeBD}</label>
                </div>}

                {values.FieldofStudyBD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FieldofStudyBD'>{t('Field of Study')}-{t('Bachelor Degree')}</label>
                    <label id='FieldofStudyBD'>{values.FieldofStudyBD}</label>
                </div>}

                {values.GraduationDateBD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='GraduationDateBD'>{t('Graduation Date')}-{t('Bachelor Degree')}</label>
                    <label id='GraduationDateBD'>{values.GraduationDateBD}</label>
                </div>}

                {values.DateofEnteringUniversityMD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='DateofEnteringUniversityMD'>{t('Date of Entering University')}-{t('Masters Degree')}</label>
                    <label id='DateofEnteringUniversityMD'>{values.DateofEnteringUniversityMD}</label>
                </div>}

                {values.UniversityNameMD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityNameMD'>{t('University Name')}-{t('Masters Degree')}</label>
                    <label id='UniversityNameMD'>{values.UniversityNameMD}</label>
                </div>}

                {values.UniversityPlcaeMD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='UniversityPlcaeMD'>{t('University Plcae')}-{t('Masters Degree')}</label>
                    <label id='UniversityPlcaeMD'>{values.UniversityPlcaeMD}</label>
                </div>}

                {values.FieldofStudyMD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='FieldofStudyMD'>{t('Field of Study')}-{t('Masters Degree')}</label>
                    <label id='FieldofStudyMD'>{values.FieldofStudyMD}</label>
                </div>}

                {values.GraduationDateMD!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='GraduationDateMD'>{t('Graduation Date')}-{t('Masters Degree')}</label>
                    <label id='GraduationDateMD'>{values.GraduationDateMD}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}

                {values.EnglishLanguageStatus!='' && <div className="MyLabelsCoup col-2">
                    <label htmlFor='EnglishLanguageStatus'>{t('English Language Status')}</label>
                    <label id='EnglishLanguageStatus'>{values.EnglishLanguageStatus}</label>
                </div>}
            </div>
        </div>
      </>}
      handleClose={togglePopup3}
    />}
         {isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
    </>
        
    );
}
 
export default Customers;


