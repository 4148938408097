import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LoginLocale from './Login.local';

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      ...LoginLocale // merge Home locale to i18n resource
      // add other translation to the array
      
    },
    Login: {
      'Sign In to Telesiness': 'Sign In to Telesiness',
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.':'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      'Email':'Email',
      'Password':'Password',
      'ConfirmPassword':'Confirm Password',
      'Confirm New Password':'Confirm New Password',
      'New Password':'New Password',
      'Remember me':'Remember me',
      'Forgot Password':'Forgot Password',
      'Login':'Login',
      'or':'or',
      'Sign Up Now':'Sign Up Now',
      'Services':'Services',
      'About':'About',
      'Collaborations':'Collaborations',
      'Terms':'Terms',
      'Help':'Help',
      'Contact Us':'Contact Us',
      'Reset Your Password': 'Reset Your Password',
      'Send Link':'Send Link',
      '< Back to Home page':'< Back to Home page',
      'Submit':'Submit',
      'Changes were registered ':'Changes were registered ',
      'Login now':'Login now',
      'New Password':'New Password',
      'Confirm New Password':'Confirm New Password',
      'Sign Up To Telesiness':'Sign Up To Telesiness',
      'Step 1':'Step 1',
      'Step 2':'Step 2',
      'Step 3':'Step 3',
      'Step 4':'Step 4',
      'Step 5':'Step 5',
      'Choose Your Account Type':'Choose Your Account Type',
      '1 of 5':'1 of 5',
      '2 of 5':'2 of 5',
      '3 of 5':'3 of 5',
      '4 of 5':'4 of 5',
      '5 of 5':'5 of 5',
      'Lawyer':'Lawyer',
      'Agent':'Agent',
      'Customer':'Customer',
      'FirstName':'FirstName',
      'LastName':'LastName',
      'Phone Number...':'Phone Number...',
      'Date of Birth:':'Date of Birth:',
      'about':'about',
      'Day':'Day',
      'Month':'Month',
      'Year':'Year',
      'Next Step':'Next Step',
      'Back':'Back',
      'January':'January',
      'February':'February',
      'March':'March',
      'April':'April',
      'May':'May',
      'June':'June',
      'July':'July',
      'August':'August',
      'September':'September',
      'October':'October',
      'November':'November',
      'December':'December',
      'Field of Study':'Field of Study',
      'Institute / University...':'Institute / University...',
      'Level / Degree of education':'Level / Degree of education',
      'Less than high school diploma':'Less than high school diploma',
      'High school diploma':'High school diploma',
      'Some college, no degree':'Some college, no degree',
      'Associate degree':'Associate degree',
      'Bachelor degree':'Bachelor degree',
      'Master degree':'Master degree',
      'Professional  degree':'Professional  degree',
      'Doctoral degree':'Doctoral degree',
      'Country':'Country',
      'City':'City',
      'Iran':'Iran',
      'England':'England',
      'Turkey':'Turkey',
      'UAE':'UAE',
      'USA':'USA',
      'Canada':'Canada',
      'Paris':'Paris',
      'Tehran':'Tehran',
      'California':'California',
      'Toronto':'Toronto',
      'Istanbul':'Istanbul',
      'Job Specialty':'Job Specialty',
      'Specialization Field in Immigration Issues':'Specialization Field in Immigration Issues',
      'Law society number/ICCRC Code':'Law society number/ICCRC Code',
      'Personal or company website':'Personal or company website...',
      'Uplod your Resume/CV':'Uplod your Resume/CV',
      'Choose Your Password':'Choose Your Password',
      'Enter your username and password in box below to log in And have access to a world of immigration facilities':'Enter your username and password in box below to log in And have access to a world of immigration facilities',
      'Enter your email address signed up below in the box below to receive a link to reset your password.':'Enter your email address signed up below in the box below to receive a link to reset your password.',
      'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.',
      'Your password has been successfully changed. Now you can enter with your new password.':'Your password has been successfully changed. Now you can enter with your new password.',
      'Access your specific features by selecting appropriate user type.':'Access your specific features by selecting appropriate user type.',
      'Please help know you better by completing your personal information.':'Please help know you better by completing your personal information.',
      'Please help us know you better by completing your educational information.':'Please help us know you better by completing your educational information.',
      'Please help us know you better by completing your job information.':'Please help us know you better by completing your job information.',
      'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.',
      'Your registration was successful. You already have access to a variety of immigration opportunities.':'Your registration was successful. You already have access to a variety of immigration opportunities.',
      'Go to main page':'Go to main page',
      'Dear user, your information has been successfully registered in our database':'Dear user, your information has been successfully registered in our database',
      'Our experts will contact you as soon as possible.':'Our experts will contact you as soon as possible.',
      'Certificate':'Certificate',
    },
    lng: 'en_US', // default language
    fallbackLng: 'en_US',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });
export default i18n;