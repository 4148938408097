import { useState } from 'react';
import {Suspense} from 'react';
import LeftNavBar from './LeftNavBar/LeftNavBar';
import TopMenu from './TopMenu/TopMenu';
import MainPic from './images/mainDashboard.png'
import mainDashboardNotActive from './images/mainDashboardNotActive.png';
import mainDashboardNotActiveM from './images/mobDash.png';
import mainDashboardAC from './images/mainDashboardAC.png';
import UserProfile from './TopMenu/profile/profile';
import UserProfileA from './TopMenu/profileA/profile';
import UserProfileC from './TopMenu/profileC/profile';
import Customers from './Customers/Customers';
import RefCustomers from './ReferalCustomers/Customers';
import DCCustomers from './DCCustomers/Customers';
import CustomersA2 from './Customers/agentCus/Customers';
import CustomersADis from './Customers/agentCusDis/Customers';
import CustomersA2A from './Customers/agentCusAgent/Customers';
import CustomersMark from './Customers/MarketerCus/Customers';
import Customer1 from './LeftNavBar/customer1/cus1';
import Customer1e1 from './LeftNavBar/customer1-1/cus1';
import Customer1e1e1 from './LeftNavBar/customer1-1-1/prices';
import Customer1e2 from './LeftNavBar/customer1-2/cus1';
import Customer1e3 from './LeftNavBar/customer1-3/cus1';
import Customer2 from './LeftNavBar/customer2/cus2';
import Customer3 from './LeftNavBar/customer3/cus3';
import Customer4 from './LeftNavBar/customer4/cus4';
import Customer5 from './LeftNavBar/customer5/cus5';
import Customer6 from './LeftNavBar/customer6/cus6';
import Customer7 from './LeftNavBar/customer7/cus7';
import Customer8 from './LeftNavBar/customer8/cus8';
import Customer9 from './LeftNavBar/customer9/cus9';
import Customer10 from './LeftNavBar/customer10/cus10';
import Customer11 from './LeftNavBar/customer11/cus11';
import Customer12 from './LeftNavBar/customer12/cus12';
import AplSelfInfoS1 from './LeftNavBar/AplSelfInfoS1/AplSelfInfoS1';
import TahsilChoosePage from './LeftNavBar/tahsilChoosePage/tahsilChoosPage';
import ZabanInfo from './LeftNavBar/ZabanInfo/ZabanInfo';
import TahsilInfo from './LeftNavBar/TahsilInfo/TahsilInfo';
import Shoghli from './LeftNavBar/Shoghli/Shoghli';
import Mali from './LeftNavBar/Mali/Mali';
import SafarKharejeh from './LeftNavBar/SafarKharejeh/SafarKharejeh';
import Couples from './LeftNavBar/Couples/Couples';
import Khanevadeh from './LeftNavBar/Khanevadeh/Khanevadeh';
import Farzandan from './LeftNavBar/Farzandan/Farzandan';
import OtherCus from './LeftNavBar/Other/Other';
import SoalVisa from './LeftNavBar/SoalVisa/SoalVisa';
import Agents from './Agents/Agents';
import Lawyers from './Lawyers/Lawyers';
import Prices from './Prices/prices';
import AgentLawyer from './Customers/agentLawyer/Agents';
import AgentAgent from './Customers/agentAgent/Agents';
import AgentDis from './Customers/agentDis/AgentsDis';
import AgentPrice from './Customers/agentPrice/prices'
import LawyerHome from './Home/HomeLawyer/home';
import AgentHome from './Home/HomeAgent/home';
import CustomerHome from './Home/HomeCustomer/home';
import MarketerHome from './Home/HomeMarketer/home';
import Message from './Message/Message';
import MarketerDist from './Customers/agentMarketer/MarketerDist';
import MarketerPrice from './Customers/marketerPrice/prices';
import { useHistory } from "react-router";


function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Dashborad = () => {
  const forceUpdate = useForceUpdate();
  const history = useHistory();

  
   const userActive =true;
   var userNotLawyer= false;
   

   const [data, setData] = useState(false);
   const childToParent = (childdata) => {
     setData(childdata);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataCusMark(false);
     setDataMessage(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
     setDataAplSelfInfoS1(false);
     setDatatahsilChoosePage(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDataCusADis(false);
     setDataCusA2(false);
     setDataCusA2A(false);
     setDataAgentPrice(false);
        }

  const [dataMarketerDist, setDataMarketerDist] = useState(false);
  const childToParentMarketerDist = (childdataMarketerDist) => {
    setDataMarketerDist(childdataMarketerDist);
    setDataMarketerPrice(false);
    setData(false);
    setDataMessage(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataLawyer(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataAplSelfInfoS1(false);
    setDatatahsilChoosePage(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataCusA2(false);
    setDataAgentPrice(false);
        }

  const [dataMarketerPrice, setDataMarketerPrice] = useState(false);
  const childToParentMarketerPrice = (childdataMarketerPrice) => {
    setDataMarketerPrice(childdataMarketerPrice);
    setDataCusMark(false);
    setDataMarketerDist(false);
    setData(false);
    setDataMessage(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataLawyer(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataAplSelfInfoS1(false);
    setDatatahsilChoosePage(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataCusA2(false);
    setDataAgentPrice(false);
        }
  
  const [dataCouples, setDataCouples] = useState(false);
  const childToParentCouples = (childdataCouples) => {
    setDataCouples(childdataCouples);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataAplSelfInfoS1(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDatatahsilChoosePage(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDataKhanevadeh(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }

  const [dataSoalVisa, setDataSoalVisa] = useState(false);
  const childToParentSoalVisa = (childdataSoalVisa) => {
    setDataSoalVisa(childdataSoalVisa);
    setDataCouples(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataAplSelfInfoS1(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDatatahsilChoosePage(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDataKhanevadeh(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }

  const [dataOtherCus, setDataOtherCus] = useState(false);
  const childToParentOtherCus = (childdataOtherCus) => {
    setDataOtherCus(childdataOtherCus);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataFarzandan(false);
    setDataAplSelfInfoS1(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDatatahsilChoosePage(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDataKhanevadeh(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }

const [dataFarzandan, setDataFarzandan] = useState(false);
const childToParentFarzandan = (childdataFarzandan) => {
  setDataFarzandan(childdataFarzandan);
  setDataCouples(false);
  setDataSoalVisa(false);
  setDataMarketerDist(false);
  setDataMarketerPrice(false);
  setDataOtherCus(false);
  setDataAplSelfInfoS1(false);
  setDataZabanInfo(false);
  setDataTahsilInfo(false);
  setDatatahsilChoosePage(false);
  setDataShoghliInfo(false);
  setDataMaliInfo(false);
  setDataSafarKharejehInfo(false);
  setDataKhanevadeh(false);
  setData(false);
  setDataMessage(false);
  setDataCus(false);
  setDataRefCustomers(false);
  setDataDCCustomers(false);
  setdataCus1e1(false);
  setdataCus1e1e1(false);
  setdataCus1e2(false);
  setdataCus1e3(false);
  setDataCus1(false);
  setDataCus2(false);
  setDataCus3(false);
  setDataCus4(false);
  setDataCus5(false);
  setDataCus6(false);
  setDataCus7(false);
  setDataCus8(false);
  setDataCus9(false);
  setDataCus10(false);
  setDataCus11(false);
  setDataCus12(false);
  setDataAgents(false);
  setDataLawyers(false);
  setDataPrices(false);
  setDataAgentAgent(false);
  setDataAgentDis(false);
  setDataLawyerHome(false);
  setDataAgentHome(false);
  setDataMarketerHome(false);
  setDataCustomerHome(false);
  setDataCusADis(false);
  setDataCusA2A(false);
  setDataAgentPrice(false);
      }

    const [dataKhanevadeh, setDataKhanevadeh] = useState(false);
    const childToParentKhanevadeh = (childdataKhanevadeh) => {
      setDataKhanevadeh(childdataKhanevadeh);
      setDataAplSelfInfoS1(false);
      setDataZabanInfo(false);
      setDataTahsilInfo(false);
      setDatatahsilChoosePage(false);
      setDataShoghliInfo(false);
      setDataMaliInfo(false);
      setDataSafarKharejehInfo(false);
      setDataCouples(false);
      setDataSoalVisa(false);
      setDataMarketerDist(false);
      setDataMarketerPrice(false);
      setDataOtherCus(false);
      setDataFarzandan(false);
      setData(false);
      setDataMessage(false);
      setDataCus(false);
      setDataRefCustomers(false);
      setDataDCCustomers(false);
      setdataCus1e1(false);
      setdataCus1e1e1(false);
      setdataCus1e2(false);
      setdataCus1e3(false);
      setDataCus1(false);
      setDataCus2(false);
      setDataCus3(false);
      setDataCus4(false);
      setDataCus5(false);
      setDataCus6(false);
      setDataCus7(false);
      setDataCus8(false);
      setDataCus9(false);
      setDataCus10(false);
      setDataCus11(false);
      setDataCus12(false);
      setDataAgents(false);
      setDataLawyers(false);
      setDataPrices(false);
      setDataAgentAgent(false);
      setDataAgentDis(false);
      setDataLawyerHome(false);
      setDataAgentHome(false);
      setDataMarketerHome(false);
      setDataCustomerHome(false);
      setDataCusADis(false);
      setDataCusA2A(false);
      setDataAgentPrice(false);
          }

    const [dataAplSelfInfoS1, setDataAplSelfInfoS1] = useState(false);
    const childToParentAplSelfInfoS1 = (childdataAplSelfInfoS1) => {
      setDataAplSelfInfoS1(childdataAplSelfInfoS1);
      setDataCouples(false);
      setDataSoalVisa(false);
      setDataMarketerDist(false);
      setDataMarketerPrice(false);
      setDataOtherCus(false);
      setDataFarzandan(false);
      setDataKhanevadeh(false);
      setDataZabanInfo(false);
      setDataTahsilInfo(false);
      setDatatahsilChoosePage(false);
      setDataShoghliInfo(false);
      setDataMaliInfo(false);
      setDataSafarKharejehInfo(false);
      setData(false);
      setDataMessage(false);
      setDataCus(false);
      setDataRefCustomers(false);
      setDataDCCustomers(false);
      setdataCus1e1(false);
      setdataCus1e1e1(false);
      setdataCus1e2(false);
      setdataCus1e3(false);
      setDataCus1(false);
      setDataCus2(false);
      setDataCus3(false);
      setDataCus4(false);
      setDataCus5(false);
      setDataCus6(false);
      setDataCus7(false);
      setDataCus8(false);
      setDataCus9(false);
      setDataCus10(false);
      setDataCus11(false);
      setDataCus12(false);
      setDataAgents(false);
      setDataLawyers(false);
      setDataPrices(false);
      setDataAgentAgent(false);
      setDataAgentDis(false);
      setDataLawyerHome(false);
      setDataAgentHome(false);
      setDataMarketerHome(false);
      setDataCustomerHome(false);
      setDataCusADis(false);
      setDataCusA2A(false);
      setDataAgentPrice(false);
          }

  const [dataZabanInfo, setDataZabanInfo] = useState(false);
  const childToParentZabanInfo = (childdataZabanInfo) => {
    setDataZabanInfo(childdataZabanInfo);
    setDataTahsilInfo(false);
    setDatatahsilChoosePage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }

  const [dataTahsilInfo, setDataTahsilInfo] = useState(false);
  const childToParentTahsilInfo = (childdataTahsilInfo) => {
    setDataTahsilInfo(childdataTahsilInfo);
    setDataZabanInfo(false);
    setDatatahsilChoosePage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }

  const [dataShoghliInfo, setDataShoghliInfo] = useState(false);
  const childToParentShoghliInfo = (childdataShoghliInfo) => {
    setDataShoghliInfo(childdataShoghliInfo);
    setDataZabanInfo(false);
    setDatatahsilChoosePage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataTahsilInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }


  const [dataMaliInfo, setDataMaliInfo] = useState(false);
  const childToParentMaliInfo = (childdataMaliInfo) => {
    setDataMaliInfo(childdataMaliInfo);
    setDataZabanInfo(false);
    setDatatahsilChoosePage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataSafarKharejehInfo(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }


const [dataSafarKharejehInfo, setDataSafarKharejehInfo] = useState(false);
const childToParentSafarKharejehInfo = (childdataSafarKharejehInfo) => {
  setDataSafarKharejehInfo(childdataSafarKharejehInfo);
  setDataZabanInfo(false);
  setDatatahsilChoosePage(false);
  setDataAplSelfInfoS1(false);
  setDataCouples(false);
  setDataSoalVisa(false);
  setDataMarketerDist(false);
  setDataMarketerPrice(false);
  setDataOtherCus(false);
  setDataFarzandan(false);
  setDataKhanevadeh(false);
  setDataTahsilInfo(false);
  setDataShoghliInfo(false);
  setDataMaliInfo(false);
  setData(false);
  setDataMessage(false);
  setDataCus(false);
  setDataRefCustomers(false);
  setDataDCCustomers(false);
  setdataCus1e1(false);
  setdataCus1e1e1(false);
  setdataCus1e2(false);
  setdataCus1e3(false);
  setDataCus1(false);
  setDataCus2(false);
  setDataCus3(false);
  setDataCus4(false);
  setDataCus5(false);
  setDataCus6(false);
  setDataCus7(false);
  setDataCus8(false);
  setDataCus9(false);
  setDataCus10(false);
  setDataCus11(false);
  setDataCus12(false);
  setDataAgents(false);
  setDataLawyers(false);
  setDataPrices(false);
  setDataAgentAgent(false);
  setDataAgentDis(false);
  setDataLawyerHome(false);
  setDataAgentHome(false);
  setDataMarketerHome(false);
  setDataCustomerHome(false);
  setDataCusADis(false);
  setDataCusA2A(false);
  setDataAgentPrice(false);
      }

              
  const [datatahsilChoosePage, setDatatahsilChoosePage] = useState(false);
  const childToParenttahsilChoosePage = (childdatatahsilChoosePage) => {
    setDatatahsilChoosePage(childdatatahsilChoosePage);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setData(false);
    setDataMessage(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus1(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataAgents(false);
    setDataLawyers(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
    setDataCusADis(false);
    setDataCusA2A(false);
    setDataAgentPrice(false);
        }


   const [teleUserType , setTeleUserType] = useState(localStorage.getItem("userType"));
   console.log(localStorage.getItem('userType'));
   if(teleUserType!=1){
      // userActive=false;
      userNotLawyer= true;
    //  forceUpdate();
   }
   const [dataCus, setDataCus] = useState(false);
   const childToParentCus = (childdataCus) => {
     setDataCus(childdataCus);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataDCCustomers, setDataDCCustomers] = useState(false);
   const childToParentDCCustomers = (childdataDCCustomers) => {
     setDataDCCustomers(childdataDCCustomers);
     setDataRefCustomers(false);
     setDataCus(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }


   
   const [dataRefCustomers, setDataRefCustomers] = useState(false);
   const childToParentRefCustomers = (childdataRefCustomers) => {
     setDataRefCustomers(childdataRefCustomers);
     setDataDCCustomers(false);
     setDataCus(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   
   const [dataCus1e1, setdataCus1e1] = useState(false);
   const childToParentCus1e1 = (childdataCus1e1) => {
     setdataCus1e1(childdataCus1e1);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDatatahsilChoosePage(false);
     setDataSafarKharejehInfo(false);
     setDataMaliInfo(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
     if(localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2')
      localStorage.setItem('firstTimeCustomer','false');
   }
   const [dataCus1e1e1, setdataCus1e1e1] = useState(false);
   const childToParentCus1e1e1 = (childdataCus1e1e1) => {
     setdataCus1e1e1(childdataCus1e1e1);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataCus1e2, setdataCus1e2] = useState(false);
   const childToParentCus1e2 = (childdataCus1e2) => {
     setdataCus1e2(childdataCus1e2);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e3(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataCus1e3, setdataCus1e3] = useState(false);
   const childToParentCus1e3 = (childdataCus1e3) => {
     setdataCus1e3(childdataCus1e3);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataCusA2, setDataCusA2] = useState(false);
   const childToParentCusA2 = (childdataCusA2) => {
     setDataCusA2(childdataCusA2);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataCusADis, setDataCusADis] = useState(false);
   const childToParentCusADis = (childdataCusADis) => {
     setDataCusADis(childdataCusADis);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusA2(false);
     setDataCusMark(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   
   const [dataAgentPrice, setDataAgentPrice] = useState(false);
   const childToParentAgentPrice = (childdataAgentPrice) => {
     setDataAgentPrice(childdataAgentPrice);
     setDataCusADis(false);
     setDataCusA2A(false);
     setDataCusA2(false);
     setDataCusMark(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataCusA2A, setDataCusA2A] = useState(false);
   const childToParentCusA2A = (childdataCusA2A) => {
     setDataCusA2A(childdataCusA2A);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2(false);
     setDataCusMark(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataCusMark, setDataCusMark] = useState(false);
   const childToParentCusMark = (childdataCusMark) => {
     setDataCusMark(childdataCusMark);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus1(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataLawyer(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataCus1, setDataCus1] = useState(false);
   const childToParentCus1 = (childdataCus1) => {
     setDataCus1(childdataCus1);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataAgents(false);
     setDataLawyers(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataCus2, setDataCus2] = useState(false);
   const childToParentCus2 = (childdataCus2) => {
    
    setDataCus1(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setDataCusA2(false);
    setDataCusADis(false);
    setDataAgentPrice(false);
    setDataCusA2A(false);
    setDataCusMark(false);
    setDataAgents(false);
    setDataLawyers(false);
    setData(false);
    setDataMessage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDatatahsilChoosePage(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);

    

      
  
    setDataCus2(childdataCus2);
  }
  const [dataCus3, setDataCus3] = useState(false);
  const childToParentCus3 = (childdataCus3) => {
   setDataCus3(childdataCus3);
   setDataCus1(false);
   setdataCus1e1(false);
   setdataCus1e1e1(false);
   setdataCus1e2(false);
   setdataCus1e3(false);
   setDataCus2(false);
   setDataCus4(false);
   setDataCus5(false);
   setDataCus6(false);
   setDataCus7(false);
   setDataCus8(false);
   setDataCus9(false);
   setDataCus10(false);
   setDataCus11(false);
   setDataCus12(false);
   setDataCus(false);
   setDataRefCustomers(false);
   setDataDCCustomers(false);
   setDataCusA2(false);
   setDataCusADis(false);
   setDataAgentPrice(false);
   setDataCusA2A(false);
   setDataCusMark(false);
   setDataAgents(false);
   setDataLawyers(false);
   setData(false);
   setDataMessage(false);
   setDataAplSelfInfoS1(false);
   setDataCouples(false);
   setDataSoalVisa(false);
   setDataMarketerDist(false);
   setDataMarketerPrice(false);
   setDataOtherCus(false);
   setDataFarzandan(false);
   setDataKhanevadeh(false);
   setDataZabanInfo(false);
   setDataTahsilInfo(false);
   setDataShoghliInfo(false);
   setDataMaliInfo(false);
   setDataSafarKharejehInfo(false);
   setDatatahsilChoosePage(false);
   setDataPrices(false);
   setDataAgentAgent(false);
   setDataAgentDis(false);
   setDataLawyerHome(false);
   setDataAgentHome(false);
   setDataMarketerHome(false);
   setDataCustomerHome(false);
 }
 const [dataCus4, setDataCus4] = useState(false);
 const childToParentCus4 = (childdataCus4) => {
  setDataCus4(childdataCus4);
  setDataCus1(false);
  setdataCus1e1(false);
  setdataCus1e1e1(false);
  setdataCus1e2(false);
  setdataCus1e3(false);
  setDataCus2(false);
  setDataCus3(false);
  setDataCus5(false);
  setDataCus6(false);
  setDataCus7(false);
  setDataCus8(false);
  setDataCus9(false);
  setDataCus10(false);
  setDataCus11(false);
  setDataCus12(false);
  setDataCus(false);
  setDataRefCustomers(false);
  setDataDCCustomers(false);
  setDataCusA2(false);
  setDataCusADis(false);
  setDataAgentPrice(false);
  setDataCusA2A(false);
  setDataCusMark(false);
  setDataAgents(false);
  setDataLawyers(false);
  setData(false);
  setDataMessage(false);
  setDataAplSelfInfoS1(false);
  setDataCouples(false);
  setDataSoalVisa(false);
  setDataMarketerDist(false);
  setDataMarketerPrice(false);
  setDataOtherCus(false);
  setDataFarzandan(false);
  setDataKhanevadeh(false);
  setDataZabanInfo(false);
  setDataTahsilInfo(false);
  setDataShoghliInfo(false);
  setDataMaliInfo(false);
  setDataSafarKharejehInfo(false);
  setDatatahsilChoosePage(false);
  setDataPrices(false);
  setDataAgentAgent(false);
  setDataAgentDis(false);
  setDataLawyerHome(false);
  setDataAgentHome(false);
  setDataMarketerHome(false);
  setDataCustomerHome(false);
}
 const [dataCus5, setDataCus5] = useState(false);
 const childToParentCus5 = (childdataCus5) => {
  setDataCus5(childdataCus5);
  setDataCus1(false);
  setdataCus1e1(false);
  setdataCus1e1e1(false);
  setdataCus1e2(false);
  setdataCus1e3(false);
  setDataCus2(false);
  setDataCus3(false);
  setDataCus4(false);
  setDataCus6(false);
  setDataCus7(false);
  setDataCus8(false);
  setDataCus9(false);
  setDataCus10(false);
  setDataCus11(false);
  setDataCus12(false);
  setDataCus(false);
  setDataRefCustomers(false);
  setDataDCCustomers(false);
  setDataCusA2(false);
  setDataCusADis(false);
  setDataAgentPrice(false);
  setDataCusA2A(false);
  setDataCusMark(false);
  setDataAgents(false);
  setDataLawyers(false);
  setData(false);
  setDataMessage(false);
  setDataAplSelfInfoS1(false);
  setDataCouples(false);
  setDataSoalVisa(false);
  setDataMarketerDist(false);
  setDataMarketerPrice(false);
  setDataOtherCus(false);
  setDataFarzandan(false);
  setDataKhanevadeh(false);
  setDataZabanInfo(false);
  setDataTahsilInfo(false);
  setDataShoghliInfo(false);
  setDataMaliInfo(false);
  setDataSafarKharejehInfo(false);
  setDatatahsilChoosePage(false);
  setDataPrices(false);
  setDataAgentAgent(false);
  setDataAgentDis(false);
  setDataLawyerHome(false);
  setDataAgentHome(false);
  setDataMarketerHome(false);
  setDataCustomerHome(false);
}
const [dataCus6, setDataCus6] = useState(false);
const childToParentCus6 = (childdataCus6) => {
 setDataCus6(childdataCus6);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus7(false);
 setDataCus8(false);
 setDataCus9(false);
 setDataCus10(false);
 setDataCus11(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus7, setDataCus7] = useState(false);
const childToParentCus7 = (childdataCus7) => {
 setDataCus7(childdataCus7);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus8(false);
 setDataCus9(false);
 setDataCus10(false);
 setDataCus11(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus8, setDataCus8] = useState(false);
const childToParentCus8 = (childdataCus8) => {
 setDataCus8(childdataCus8);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus7(false);
 setDataCus9(false);
 setDataCus10(false);
 setDataCus11(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus9, setDataCus9] = useState(false);
const childToParentCus9 = (childdataCus9) => {
 setDataCus9(childdataCus9);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus7(false);
 setDataCus8(false);
 setDataCus10(false);
 setDataCus11(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus10, setDataCus10] = useState(false);
const childToParentCus10 = (childdataCus10) => {
 setDataCus10(childdataCus10);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus7(false);
 setDataCus8(false);
 setDataCus9(false);
 setDataCus11(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus11, setDataCus11] = useState(false);
const childToParentCus11 = (childdataCus11) => {
 setDataCus11(childdataCus11);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus7(false);
 setDataCus8(false);
 setDataCus9(false);
 setDataCus10(false);
 setDataCus12(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
const [dataCus12, setDataCus12] = useState(false);
const childToParentCus12 = (childdataCus12) => {
 setDataCus12(childdataCus12);
 setDataCus1(false);
 setdataCus1e1(false);
 setdataCus1e1e1(false);
 setdataCus1e2(false);
 setdataCus1e3(false);
 setDataCus2(false);
 setDataCus3(false);
 setDataCus4(false);
 setDataCus5(false);
 setDataCus6(false);
 setDataCus7(false);
 setDataCus8(false);
 setDataCus9(false);
 setDataCus10(false);
 setDataCus11(false);
 setDataCus(false);
 setDataRefCustomers(false);
 setDataDCCustomers(false);
 setDataCusA2(false);
 setDataCusADis(false);
 setDataAgentPrice(false);
 setDataCusA2A(false);
 setDataCusMark(false);
 setDataAgents(false);
 setDataLawyers(false);
 setData(false);
 setDataMessage(false);
 setDataAplSelfInfoS1(false);
 setDataCouples(false);
 setDataSoalVisa(false);
 setDataMarketerDist(false);
 setDataMarketerPrice(false);
 setDataOtherCus(false);
 setDataFarzandan(false);
 setDataKhanevadeh(false);
 setDataZabanInfo(false);
 setDataTahsilInfo(false);
 setDataShoghliInfo(false);
 setDataMaliInfo(false);
 setDataSafarKharejehInfo(false);
 setDatatahsilChoosePage(false);
 setDataPrices(false);
 setDataAgentAgent(false);
 setDataAgentDis(false);
 setDataLawyerHome(false);
 setDataAgentHome(false);
 setDataMarketerHome(false);
 setDataCustomerHome(false);
}
   const [dataAgents, setDataAgents] = useState(false);
   const childToParentAgents = (childdataAgents) => {
     setDataAgents(childdataAgents);
     setDataLawyers(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataPrices(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

  const [dataLawyers, setDataLawyers] = useState(false);
  const childToParentLawyers = (childdataLawyers) => {
    setDataLawyers(childdataLawyers);
    setDataAgents(false);
    setDataCus(false);
    setDataRefCustomers(false);
    setDataDCCustomers(false);
    setDataCusA2(false);
    setDataCusADis(false);
    setDataAgentPrice(false);
    setDataCusA2A(false);
    setDataCusMark(false);
    setDataCus1(false);
    setdataCus1e1(false);
    setdataCus1e1e1(false);
    setdataCus1e2(false);
    setdataCus1e3(false);
    setDataCus2(false);
    setDataCus3(false);
    setDataCus4(false);
    setDataCus5(false);
    setDataCus6(false);
    setDataCus7(false);
    setDataCus8(false);
    setDataCus9(false);
    setDataCus10(false);
    setDataCus11(false);
    setDataCus12(false);
    setData(false);
    setDataMessage(false);
    setDataAplSelfInfoS1(false);
    setDataCouples(false);
    setDataSoalVisa(false);
    setDataMarketerDist(false);
    setDataMarketerPrice(false);
    setDataOtherCus(false);
    setDataFarzandan(false);
    setDataKhanevadeh(false);
    setDataZabanInfo(false);
    setDataTahsilInfo(false);
    setDataShoghliInfo(false);
    setDataMaliInfo(false);
    setDataSafarKharejehInfo(false);
    setDatatahsilChoosePage(false);
    setDataPrices(false);
    setDataAgentAgent(false);
    setDataAgentDis(false);
    setDataLawyerHome(false);
    setDataAgentHome(false);
    setDataMarketerHome(false);
    setDataCustomerHome(false);
  }

   const [dataPrices, setDataPrices] = useState(false);
   const childToParentPrices = (childdataPrices) => {
     setDataPrices(childdataPrices);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataLawyer, setDataLawyer] = useState(false);
   const childToParentLawyer = (childdataLawyer) => {
     setDataLawyer(childdataLawyer);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataLawyerHome(false);
     setDataPrices(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataAgentAgent, setDataAgentAgent] = useState(false);
   const childToParentAgentAgent = (childdataAgentAgent) => {
     setDataAgentAgent(childdataAgentAgent);
     setDataAgentDis(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataLawyerHome(false);
     setDataPrices(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   
   const [dataAgentDis, setDataAgentDis] = useState(false);
   const childToParentAgentDis = (childdataAgentDis) => {
     setDataAgentDis(childdataAgentDis);
     setDataAgentAgent(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataLawyerHome(false);
     setDataPrices(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }

   const [dataLawyerHome, setDataLawyerHome] = useState(false);
   const childToParentLawyerHome = (childdataLawyerHome) => {
     setDataLawyerHome(childdataLawyerHome);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataPrices(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
     setDataCustomerHome(false);
   }
   const [dataAgentHome, setDataAgentHome] = useState(false);
   const childToParentAgentHome = (childdataAgentHome) => {
     setDataAgentHome(childdataAgentHome);
     setDataMarketerHome(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataPrices(false);
     setDataLawyerHome(false);
     setDataCustomerHome(false);
   }

   const [dataMarketerHome, setDataMarketerHome] = useState(false);
   const childToParentMarketerHome = (childdataMarketerHome) => {
     setDataMarketerHome(childdataMarketerHome);
     setDataAgentHome(false)
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataMessage(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataPrices(false);
     setDataLawyerHome(false);
     setDataCustomerHome(false);
   }

   const [dataCustomerHome, setDataCustomerHome] = useState(false);
   const childToParentCustomerHome = (childdataCustomerHome) => {
     setDataCustomerHome(childdataCustomerHome);
     setDataMessage(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataPrices(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
   }

   const [dataMessage, setDataMessage] = useState(false);
   const childToParentMessage = (childdataMessage) => {
     setDataMessage(childdataMessage);
     setDataCustomerHome(false);
     setDataCus(false);
     setDataRefCustomers(false);
     setDataDCCustomers(false);
     setDataCusA2(false);
     setDataCusADis(false);
     setDataAgentPrice(false);
     setDataCusA2A(false);
     setDataCusMark(false);
     setDataCus1(false);
     setdataCus1e1(false);
     setdataCus1e1e1(false);
     setdataCus1e2(false);
     setdataCus1e3(false);
     setDataCus2(false);
     setDataCus3(false);
     setDataCus4(false);
     setDataCus5(false);
     setDataCus6(false);
     setDataCus7(false);
     setDataCus8(false);
     setDataCus9(false);
     setDataCus10(false);
     setDataCus11(false);
     setDataCus12(false);
     setData(false);
     setDataAplSelfInfoS1(false);
     setDataCouples(false);
     setDataSoalVisa(false);
     setDataMarketerDist(false);
     setDataMarketerPrice(false);
     setDataOtherCus(false);
     setDataFarzandan(false);
     setDataKhanevadeh(false);
     setDataZabanInfo(false);
     setDataTahsilInfo(false);
     setDataShoghliInfo(false);
     setDataMaliInfo(false);
     setDataSafarKharejehInfo(false);
     setDatatahsilChoosePage(false);
     setDataAgents(false);
     setDataLawyers(false);
     setDataLawyer(false);
     setDataAgentAgent(false);
     setDataAgentDis(false);
     setDataPrices(false);
     setDataLawyerHome(false);
     setDataAgentHome(false);
     setDataMarketerHome(false);
   }



   if(teleUserType==1)
{
  
  if(!localStorage.getItem('token'))
  {
   history.push('/');
  }
  else{
    fetch('https://telesiness.ir/api/LawyerProfile/EditLawyerProfile' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
          }).then(response => response.json()).then(response=>{
  
                // setIspending(false);
                
              if(response.lawyerCode!=null)
                localStorage.setItem('lawyerCode',response.lawyerCode);
              else
                localStorage.setItem('lawyerCode','');

              if(response.workMail!=null)
              localStorage.setItem('workemail',response.workMail);
              else
              localStorage.setItem('workemail','');

              if(response.firstName!=null)
               localStorage.setItem('firstName',response.firstName);
              else
               localStorage.setItem('firstName','');

              if(response.lastName!=null)
               localStorage.setItem('lastName',response.lastName);
              else
                localStorage.setItem('lastName','');

              if(response.dateOfBirth!=null)
                localStorage.setItem('dateOfBirth',response.dateOfBirth);
              else
                localStorage.setItem('dateOfBirth','2022/05/18');

              if(response.lastEducationalCertificate!=null)
               localStorage.setItem('lastEducationalCertificate',response.lastEducationalCertificate);
              else
                localStorage.setItem('lastEducationalCertificate','');

              if(response.fieldOfStudy!=null)
                localStorage.setItem('fieldOfStudy',response.fieldOfStudy);
              else
                localStorage.setItem('fieldOfStudy','');

              if(response.univeristyName!=null)
                localStorage.setItem('univeristyName',response.univeristyName);
              else
               localStorage.setItem('univeristyName','');

              if(response.certificates!=null)
                localStorage.setItem('certificates',response.certificates);
              else
               localStorage.setItem('certificates','');

              if(response.specializedProfession!=null)
                localStorage.setItem('specializedProfession',response.specializedProfession);
              else
                localStorage.setItem('specializedProfession','');

              if(response.specializedSectionInTheFieldOfImmigration!=null)
                localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializedSectionInTheFieldOfImmigration);
              else
               localStorage.setItem('specializedSectionInTheFieldOfImmigration','');

              if(response.lawyerCountry!=null)
               localStorage.setItem('lawyerCountry',response.lawyerCountry);
              else
               localStorage.setItem('lawyerCountry','');

              if(response.lawyerCity!=null)
                localStorage.setItem('lawyerCity',response.lawyerCity);
              else
               localStorage.setItem('lawyerCity','');

              if(response.countryInWhichYouWork!=null)
                localStorage.setItem('countryInWhichYouWork',response.countryInWhichYouWork);
              else
                localStorage.setItem('countryInWhichYouWork','');

              if(response.theCityWhereYouWork!=null)
                localStorage.setItem('theCityWhereYouWork',response.theCityWhereYouWork);
              else
                localStorage.setItem('theCityWhereYouWork','');

              if(response.lawyerAddress!=null)
               localStorage.setItem('laywerAddress',response.lawyerAddress);
              else
               localStorage.setItem('laywerAddress','');

              if(response.zipCode!=null)
                localStorage.setItem('zipCode',response.zipCode);
              else
                localStorage.setItem('zipCode','');

              if(response.postagePlate!=null)
                localStorage.setItem('postagePlate',response.postagePlate);
              else
               localStorage.setItem('postagePlate','');

              if(response.gender!=null)
                localStorage.setItem('gender',response.gender);
              else
                localStorage.setItem('gender','');

              if(response.phoneNumber!=null)
                localStorage.setItem('phoneNumber',response.phoneNumber);
              else
                localStorage.setItem('phoneNumber','');

              if(response.email!=null)
               localStorage.setItem('email',response.email);
              else
                localStorage.setItem('email','');

              if(response.websiteAddress!=null)
                localStorage.setItem('websiteAddress',response.websiteAddress);
              else
                localStorage.setItem('websiteAddress','');

              if(response.iccrcCode!=null)
                localStorage.setItem('iccrcCode',response.iccrcCode);
              else
               localStorage.setItem('iccrcCode','');

              if(response.lawyerCVName!=null)
                localStorage.setItem('lawyerCVName',response.lawyerCVName);
              else
                localStorage.setItem('lawyerCVName','');

                if(response.cvUpload!=null)
                localStorage.setItem('cvUpload',response.cvUpload);
              else
                localStorage.setItem('cvUpload','');

              if(response.cvFile!=null)
                localStorage.setItem('cvFile',response.cvFile);
              else
                localStorage.setItem('cvFile','');

              if(response.aboutMe!=null)
                localStorage.setItem('aboutMe',response.aboutMe);
              else
                localStorage.setItem('aboutMe','');

              if(response.userName!=null)
                localStorage.setItem('userName',response.userName);
              else
                localStorage.setItem('userName','');

              if(response.avatarName!=null)
                localStorage.setItem('avatarName',response.avatarName);
              else
                localStorage.setItem('avatarName','');

              if(response.registerDate!=null)
                localStorage.setItem('registerDate',response.registerDate);
              else
                localStorage.setItem('registerDate','');

              
              localStorage.setItem('lawyersEditSection1',response.lawyersEditSection1);
              
              localStorage.setItem('lawyersEditSection2',response.lawyersEditSection2);
              
              localStorage.setItem('lawyersEditSection3',response.lawyersEditSection3);
              
              localStorage.setItem('lawyersEditSection4',response.lawyersEditSection4);
               
              localStorage.setItem('statusCode',response.statusCode);
              
              localStorage.setItem('statusMessage',response.statusMessage);

                //  console.log(localStorage.getItem('token'));
                //  console.log(response);
                
              }).catch ((error) => console.error(error));
              // console.log(localStorage.getItem('userAvatar'));
              // console.log(localStorage.getItem('laywerAddress'));
              fetch('https://telesiness.ir/api/Dashboards/LawyerGetNotification' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
              }).then(response => response.json()).then(response=>{
      
                    // setIspending(false);
                    // if(localStorage.getItem('messageNotificationCount')!=0)
                    
                      localStorage.setItem('messageNotificationCount',response.messageNotificationCount);
    
                      // localStorage.setItem('userAvatar',response.userAvatar);
                      if(response.messageNotificationCount && response.messageNotificationCount>0)
                      localStorage.setItem('messageNotificationName1',response.usersMessage[0].firstName + ' ' + 
                      response.usersMessage[0].lastName);
                      if(response.messageNotificationCount && response.messageNotificationCount>1)
                      localStorage.setItem('messageNotificationName2',response.usersMessage[1].firstName + ' ' + 
                      response.usersMessage[1].lastName);
                      if(response.messageNotificationCount && response.messageNotificationCount>2)
                      localStorage.setItem('messageNotificationName3',response.usersMessage[2].firstName + ' ' + 
                      response.usersMessage[2].lastName);
                      if(response.messageNotificationCount && response.messageNotificationCount>3)
                      localStorage.setItem('messageNotificationName4',response.usersMessage[3].firstName + ' ' + 
                      response.usersMessage[3].lastName);
                      if(response.messageNotificationCount && response.messageNotificationCount>4)
                      localStorage.setItem('messageNotificationName5',response.usersMessage[4].firstName + ' ' + 
                      response.usersMessage[4].lastName);
                  
                  // localStorage.setItem('messageNotificationCount',0);
                 

                    //  console.log(localStorage.getItem('token'));
                    //  console.log(response);
                    
                  }).catch ((error) => console.error(error));
  }
}
else if (teleUserType==2)
{
  if(!localStorage.getItem('token'))
  {
   history.push('/');
  }
  else{
    fetch('https://telesiness.ir/api/AgentProfile/AgentEditProfile' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
          }).then(response => response.json()).then(response=>{
  
                // setIspending(false);
              console.log(response);

              localStorage.setItem('AgentCode',response.agentCode);
              // localStorage.setItem('userAvatar',response.userAvatar);

              if(response.workMail!=null)
              localStorage.setItem('workemail',response.workMail);
              else
              localStorage.setItem('workemail','');


              if(response.firstName!=null)
                localStorage.setItem('firstName',response.firstName);
              else
                localStorage.setItem('firstName','');
              
              if(response.lastName!=null)
                localStorage.setItem('lastName',response.lastName);
              else
                localStorage.setItem('lastName','');

              if(response.dateOfBirth!=null)
                localStorage.setItem('dateOfBirth',response.dateOfBirth);
              else
                localStorage.setItem('dateOfBirth','2022/05/18');

              if(response.levelOrDegree!=null)
                localStorage.setItem('lastEducationalCertificate',response.levelOrDegree);
              else
               localStorage.setItem('lastEducationalCertificate','');

              if(response.fieldOfStudy!=null)
              localStorage.setItem('fieldOfStudy',response.fieldOfStudy);
              else
              localStorage.setItem('fieldOfStudy','');

              if(response.instituteOrUniversity!=null)
               localStorage.setItem('univeristyName',response.instituteOrUniversity);
              else
                localStorage.setItem('univeristyName','');

              if(response.certificate!=null)  
                localStorage.setItem('certificates',response.certificate);
              else
               localStorage.setItem('certificates','');

               if(response.jobSpecialty!=null) 
               localStorage.setItem('specializedProfession',response.jobSpecialty);
               else
               localStorage.setItem('specializedProfession','');

               if(response.specializationInImmigration!=null) 
               localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializationInImmigration);
               else
               localStorage.setItem('specializedSectionInTheFieldOfImmigration','');

               if(response.country!=null)
               localStorage.setItem('countryInWhichYouWork',response.country);
             else
               localStorage.setItem('countryInWhichYouWork','');

               if(response.cvUpload!=null)
               localStorage.setItem('cvUpload',response.cvUpload);
             else
               localStorage.setItem('cvUpload','');

             if(response.city!=null)
               localStorage.setItem('theCityWhereYouWork',response.city);
             else
               localStorage.setItem('theCityWhereYouWork','');

              if(response.country!=null) 
                localStorage.setItem('lawyerCountry',response.country);
              else
                localStorage.setItem('lawyerCountry','');

              if(response.city!=null) 
                localStorage.setItem('lawyerCity',response.city);
              else
                localStorage.setItem('lawyerCity','');

              if(response.zipCode!=null) 
                localStorage.setItem('zipCode',response.zipCode);
              else
               localStorage.setItem('zipCode','');

              if(response.number!=null)
              localStorage.setItem('Number',response.number);
              else
              localStorage.setItem('Number','');

              if(response.nationalCode!=null) 
                localStorage.setItem('NationalCode',response.nationalCode);
              else
                localStorage.setItem('NationalCode','');

              if(response.gender!=null) 
                localStorage.setItem('gender',response.gender);
              else
                localStorage.setItem('gender','');

              if(response.phoneNumber!=null) 
                localStorage.setItem('phoneNumber',response.phoneNumber);
              else
                localStorage.setItem('phoneNumber','');

              if(response.email!=null) 
                localStorage.setItem('email',response.email);
              else
                localStorage.setItem('email','');

              if(response.websiteAddress!=null) 
                localStorage.setItem('websiteAddress',response.websiteAddress);
              else
                localStorage.setItem('websiteAddress','');

              if(response.nationalCode!=null) 
                localStorage.setItem('iccrcCode',response.nationalCode);
              else
                localStorage.setItem('iccrcCode','');

              if(response.cvName!=null) 
                localStorage.setItem('cvFile',response.cvName);
              else
                localStorage.setItem('cvFile','');

              if(response.aboutMe!=null) 
                localStorage.setItem('aboutMe',response.aboutMe);
              else
                localStorage.setItem('aboutMe','');

              if(response.username!=null) 
                localStorage.setItem('userName',response.username);
              else
                localStorage.setItem('userName','');

              if(response.registerDate!=null) 
                localStorage.setItem('registerDate',response.registerDate);
              else
                localStorage.setItem('registerDate','');


              localStorage.setItem('AgentEditSection1',response.agentEditStatusStepPersonalInformation);
              
              localStorage.setItem('AgentEditSection2',response.agentEditStatusStepEducationalInformation);

              localStorage.setItem('AgentEditSection3',response.agentEditStatusStepJobInformation);
              

              // localStorage.setItem('statusCode',response.statusCode);
              // localStorage.setItem('statusMessage',response.statusMessage);
                //  console.log(localStorage.getItem('token'));
                //  console.log(response);
                
              }).catch ((error) => console.error(error));
              // console.log(localStorage.getItem('userAvatar'));
              fetch('https://telesiness.ir/api/Dashboards/DistributorGetNotification' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
              }).then(response => response.json()).then(response=>{
      
                    // setIspending(false);
                    // if(localStorage.getItem('messageNotificationCount')!=0)
                    
                      localStorage.setItem('messageNotificationCount',response.distributorMessageCount);
    
                      // localStorage.setItem('userAvatar',response.userAvatar);
                      if(response.distributorMessageCount && response.distributorMessageCount>0)
                      localStorage.setItem('messageNotificationName1',response.distributorMessage[0].firstName + ' ' + 
                      response.distributorMessage[0].lastName);
                      if(response.distributorMessageCount && response.distributorMessageCount>1)
                      localStorage.setItem('messageNotificationName2',response.distributorMessage[1].firstName + ' ' + 
                      response.distributorMessage[1].lastName);
                      if(response.distributorMessageCount && response.distributorMessageCount>2)
                      localStorage.setItem('messageNotificationName3',response.distributorMessage[2].firstName + ' ' + 
                      response.distributorMessage[2].lastName);
                      if(response.distributorMessageCount && response.distributorMessageCount>3)
                      localStorage.setItem('messageNotificationName4',response.distributorMessage[3].firstName + ' ' + 
                      response.distributorMessage[3].lastName);
                      if(response.distributorMessageCount && response.distributorMessageCount>4)
                      localStorage.setItem('messageNotificationName5',response.distributorMessage[4].firstName + ' ' + 
                      response.distributorMessage[4].lastName);
                      if(response.distributorMessageCount && response.distributorMessageCount>0)
                      localStorage.setItem('messageNotificationCount',response.distributorMessageCount);
                    
                      //localStorage.setItem('messageNotificationCount',0);
                    
                 

                    //  console.log(localStorage.getItem('token'));
                    //  console.log(response);
                    
                  }).catch ((error) => console.error(error));
  }
}
else if (teleUserType==3)
{
  if(!localStorage.getItem('token'))
  {
   history.push('/');
  }
  else{
    fetch('https://telesiness.ir/api/CustomerProfile/EditCustomerProfile' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
          }).then(response => response.json()).then(response=>{
  
                // setIspending(false);
              console.log(response);
              console.log(localStorage.getItem('verifyCustomerByDistributor'));
              if(response.customerCode!=null)
              localStorage.setItem('customerCode',response.customerCode);
              else
              localStorage.setItem('customerCode','');

              if(response.firstName!=null)
              localStorage.setItem('firstName',response.firstName);
              else
              localStorage.setItem('firstName','');

              if(response.lastName!=null)
              localStorage.setItem('lastName',response.lastName);
              else
              localStorage.setItem('lastName','');

              if(response.dateOfBirth!=null)
              localStorage.setItem('dateOfBirth',response.dateOfBirth);
              else
              localStorage.setItem('dateOfBirth','2022/05/18');

              if(response.degree!=null &&  response.degree!='null')
              localStorage.setItem('lastEducationalCertificate',response.degree);
              else
              localStorage.setItem('lastEducationalCertificate','');

              if(response.fieldOfStudy!=null)
              localStorage.setItem('fieldOfStudy',response.fieldOfStudy);
              else
              localStorage.setItem('fieldOfStudy','');

              if(response.university!=null)
              localStorage.setItem('univeristyName',response.university);
              else
              localStorage.setItem('univeristyName','');
              // localStorage.setItem('certificates',response.certificates);
              // localStorage.setItem('specializedProfession',response.specializedProfession);
              // localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializedSectionInTheFieldOfImmigration);

              if(response.customerCountry!=null)
              localStorage.setItem('lawyerCountry',response.customerCountry);
              else
              localStorage.setItem('lawyerCountry','');

              if(response.customerCity!=null)
              localStorage.setItem('lawyerCity',response.customerCity);
              else
              localStorage.setItem('lawyerCity','');

              if(response.number!=null)
              localStorage.setItem('postagePlate',response.number);
              else
              localStorage.setItem('postagePlate','');
              // localStorage.setItem('countryInWhichYouWork',response.countryInWhichYouWork);
              // localStorage.setItem('theCityWhereYouWork',response.theCityWhereYouWork);

              if(response.customerAddress!=null)
              localStorage.setItem('laywerAddress',response.customerAddress);
              else
              localStorage.setItem('laywerAddress','');

              if(response.zipCode!=null)
              localStorage.setItem('zipCode',response.zipCode);
              else
              localStorage.setItem('zipCode','');

              if(response.number!=null)
              localStorage.setItem('Number',response.number);
              else
              localStorage.setItem('Number','');

              if(response.gender!=null)
              localStorage.setItem('gender',response.gender);
              else
              localStorage.setItem('gender','');

              if(response.phoneNumber!=null)
              localStorage.setItem('phoneNumber',response.phoneNumber);
              else
              localStorage.setItem('phoneNumber','');

              if(response.email!=null)
              localStorage.setItem('email',response.email);
              else
              localStorage.setItem('email','');
              // localStorage.setItem('websiteAddress',response.websiteAddress);
              // localStorage.setItem('iccrcCode',response.iccrcCode);
              // localStorage.setItem('userAvatar',response.userAvatar);
              // localStorage.setItem('lawyerCVName',response.lawyerCVName);
              // localStorage.setItem('cvFile',response.cvFile);

              if(response.aboutMe!=null)
              localStorage.setItem('aboutMe',response.aboutMe);
              else
              localStorage.setItem('aboutMe','');

              if(response.customerUsername!=null)
              localStorage.setItem('userName',response.customerUsername);
              else
              localStorage.setItem('userName','');

              
              localStorage.setItem('avatarName',response.avatarName);
              // localStorage.setItem('registerDate',response.registerDate);
              localStorage.setItem('CustomerEditSection1',response.customerEditStatusStepPersonalInformation);
              localStorage.setItem('CustomerEditSection2',response.customerEditStatusStepAddressInformation);
              localStorage.setItem('CustomerEditSection3',response.customerEditStatusStepEducationalInformation);
              // localStorage.setItem('statusCode',response.statusCode);
              // localStorage.setItem('statusMessage',response.statusMessage);
                //  console.log(localStorage.getItem('token'));
                //  console.log(response);
                
              }).catch ((error) => console.error(error));
              // console.log(localStorage.getItem('userAvatar'));
              fetch('https://telesiness.ir/api/Dashboards/CustomerGetNotification' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({ Email: localStorage.email3 })
              }).then(response => response.json()).then(response=>{
      
                    // setIspending(false);
                    // if(localStorage.getItem('messageNotificationCount')!=0)
                    
                      localStorage.setItem('messageNotificationCount',response.customerMessageCount);
    
                      // localStorage.setItem('userAvatar',response.userAvatar);
                      if(response.customerMessageCount && response.customerMessageCount>0)
                      localStorage.setItem('messageNotificationName1',response.customerMessages[0].firstName + ' ' + 
                      response.customerMessages[0].lastName);
                      if(response.customerMessageCount && response.customerMessageCount>1)
                      localStorage.setItem('messageNotificationName2',response.customerMessages[1].firstName + ' ' + 
                      response.customerMessages[1].lastName);
                      if(response.customerMessageCount && response.customerMessageCount>2)
                      localStorage.setItem('messageNotificationName3',response.customerMessages[2].firstName + ' ' + 
                      response.customerMessages[2].lastName);
                      if(response.customerMessageCount && response.customerMessageCount>3)
                      localStorage.setItem('messageNotificationName4',response.customerMessages[3].firstName + ' ' + 
                      response.customerMessages[3].lastName);
                      if(response.customerMessageCount && response.customerMessageCount>4)
                      localStorage.setItem('messageNotificationName5',response.customerMessages[4].firstName + ' ' + 
                      response.customerMessages[4].lastName);
                      if(response.customerMessageCount && response.customerMessageCount>0)
                      localStorage.setItem('messageNotificationCount',response.customerMessageCount);
                    
                      //localStorage.setItem('messageNotificationCount',0);
                    
                 

                    //  console.log(localStorage.getItem('token'));
                    //  console.log(response);
                    
                  }).catch ((error) => console.error(error));
  }
}
else if (teleUserType==4)
{
  if(!localStorage.getItem('token'))
  {
   history.push('/');
  }
  else{
    fetch('https://telesiness.ir/api/MarketerProfile/EditMarketerProfile' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
          }).then(response => response.json()).then(response=>{
  
                // setIspending(false);
              
              if(response.marketerCode!=null)
              localStorage.setItem('marketerCode',response.marketerCode);
              else
              localStorage.setItem('marketerCode','');

              if(response.workMail!=null)
              localStorage.setItem('workemail',response.workMail);
              else
              localStorage.setItem('workemail','');

              if(response.firstName!=null)
              localStorage.setItem('firstName',response.firstName);
              else
              localStorage.setItem('firstName','');

              if(response.lastName!=null)
              localStorage.setItem('lastName',response.lastName);
              else
              localStorage.setItem('lastName','');

              if(response.dateOfBirth!=null)
              localStorage.setItem('dateOfBirth',response.dateOfBirth);
              else
              localStorage.setItem('dateOfBirth','2022/05/18');

              if(response.degree!=null)
              localStorage.setItem('lastEducationalCertificate',response.degree);
              else
              localStorage.setItem('lastEducationalCertificate','');

              if(response.fieldOfStudy!=null)
              localStorage.setItem('fieldOfStudy',response.fieldOfStudy);
              else
              localStorage.setItem('fieldOfStudy','');

              if(response.university!=null)
              localStorage.setItem('univeristyName',response.university);
              else
              localStorage.setItem('univeristyName','');

              if(response.marketerCountry!=null)
              localStorage.setItem('lawyerCountry',response.marketerCountry);
              else
              localStorage.setItem('lawyerCountry','');

              if(response.marketerCity!=null)
              localStorage.setItem('lawyerCity',response.marketerCity);
              else
              localStorage.setItem('lawyerCity','');

              if(response.marketerAddress!=null)
              localStorage.setItem('laywerAddress',response.marketerAddress);
              else
              localStorage.setItem('laywerAddress','');

              if(response.zipCode!=null)
              localStorage.setItem('zipCode',response.zipCode);
              else
              localStorage.setItem('zipCode','');

              if(response.number!=null)
              localStorage.setItem('postagePlate',response.number);
              else
              localStorage.setItem('postagePlate','');

              if(response.gender!=null)
              localStorage.setItem('gender',response.gender);
              else
              localStorage.setItem('gender','');

              if(response.phoneNumber!=null)
              localStorage.setItem('phoneNumber',response.phoneNumber);
              else
              localStorage.setItem('phoneNumber','');

              if(response.email!=null)
              localStorage.setItem('email',response.email);
              else
              localStorage.setItem('email','');

              if(response.aboutMe!=null)
              localStorage.setItem('aboutMe',response.aboutMe);
              else
              localStorage.setItem('aboutMe','');

              if(response.marketerUsername!=null)
              localStorage.setItem('userName',response.marketerUsername);
              else
              localStorage.setItem('userName','');


              localStorage.setItem('avatarName',response.avatarName);
              localStorage.setItem('CustomerEditSection1',response.marketerEditStatusStepPersonalInformation);
              localStorage.setItem('CustomerEditSection2',response.marketerEditStatusStepAddressInformation);
              localStorage.setItem('CustomerEditSection3',response.marketerEditStatusStepEducationalInformation);
              //  console.log(response);
                
              }).catch ((error) => console.error(error));
              // console.log(localStorage.getItem('userAvatar'));
              fetch('https://telesiness.ir/api/Dashboards/MarketerGetNotification' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify({ Email: localStorage.email3 })
              }).then(response => response.json()).then(response=>{
      
                    // setIspending(false);
                    // if(localStorage.getItem('messageNotificationCount')!=0)
                    
                      localStorage.setItem('messageNotificationCount',response.marketerMessagesCount);
    
                      // localStorage.setItem('userAvatar',response.userAvatar);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>0)
                      localStorage.setItem('messageNotificationName1',response.marketerMessages[0].firstName + ' ' + 
                      response.marketerMessages[0].lastName);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>1)
                      localStorage.setItem('messageNotificationName2',response.marketerMessages[1].firstName + ' ' + 
                      response.marketerMessages[1].lastName);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>2)
                      localStorage.setItem('messageNotificationName3',response.marketerMessages[2].firstName + ' ' + 
                      response.marketerMessages[2].lastName);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>3)
                      localStorage.setItem('messageNotificationName4',response.marketerMessages[3].firstName + ' ' + 
                      response.marketerMessages[3].lastName);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>4)
                      localStorage.setItem('messageNotificationName5',response.marketerMessages[4].firstName + ' ' + 
                      response.marketerMessages[4].lastName);
                      if(response.marketerMessagesCount && response.marketerMessagesCount>0)
                      localStorage.setItem('messageNotificationCount',response.marketerMessagesCount);
                    
                      //localStorage.setItem('messageNotificationCount',0);
                    
                 

                    //  console.log(localStorage.getItem('token'));
                    //  console.log(response);
                    
                  }).catch ((error) => console.error(error));
  }
}

//**************************************** */

//***************************************** */



const [countProfile2,setCountProfile2] = useState(false);
const handleClickProfile2 = (e) => {
  if(!countProfile2){
    //  setCountProfile(true);
    document.getElementById('messageBox2').style.display='none';
}
else{
    setCountProfile2(false);
    document.getElementById('messageBox2').style.display='flex';
}
}


const [countProfile3,setCountProfile3] = useState(false);
const handleClickProfile3 = (e) => {
  if(!countProfile2){
    //  setCountProfile(true);
    document.getElementById('messageBox1').style.display='none';
}
else{
    setCountProfile3(false);
    document.getElementById('messageBox1').style.display='flex';
}
}


    return ( 
        
     <div className="row mainrow">
       <div className="LeftNavBar col-lg-2 col-md-3 col-12" onClick={()=>{handleClickProfile2(); handleClickProfile3();}}>
           <LeftNavBar 
           childToParentPrices={childToParentPrices} 
           childToParentAgents={childToParentAgents} 
           childToParentLawyers={childToParentLawyers}
           childToParentAgentAgent = {childToParentAgentAgent}
           childToParentAgentDis = {childToParentAgentDis}
           childToParentAgentPrice = {childToParentAgentPrice}
           childToParentLawyer={childToParentLawyer} 
           childToParentCus={childToParentCus} 
           childToParentCusA2={childToParentCusA2}
           childToParentCusADis={childToParentCusADis}
           childToParentCusA2A={childToParentCusA2A}
           childToParentCusMark={childToParentCusMark}
           childToParentCus1={childToParentCus1} 
           childToParentLawyerHome = {childToParentLawyerHome}
           childToParentAgentHome = {childToParentAgentHome}
           childToParentCustomerHome = {childToParentCustomerHome}
           childToParentMarketerHome = {childToParentMarketerHome}
           childToParentMessage = {childToParentMessage}
           childToParentRefCustomers={childToParentRefCustomers}
           childToParentDCCustomers={childToParentDCCustomers}
           childToParentMarketerDist={childToParentMarketerDist}
           childToParentMarketerPrice={childToParentMarketerPrice}
           userActive={userActive}
            />
       </div>
       <div className="cont_apptel col-lg-10 col-md-9 col-12">
           <div className="dashTop col-12">
              <TopMenu childToParent={childToParent} 
               childToParentPrices={childToParentPrices} 
               childToParentAgents={childToParentAgents} 
               childToParentLawyers={childToParentLawyers}
               childToParentAgentAgent = {childToParentAgentAgent}
               childToParentAgentDis = {childToParentAgentDis}
               childToParentAgentPrice = {childToParentAgentPrice}
               childToParentLawyer={childToParentLawyer} 
               childToParentCus={childToParentCus} 
               childToParentCusA2={childToParentCusA2}
               childToParentCusADis={childToParentCusADis}
               childToParentCusA2A={childToParentCusA2A}
               childToParentCusMark={childToParentCusMark}
               childToParentCus1={childToParentCus1} 
               childToParentLawyerHome = {childToParentLawyerHome}
               childToParentAgentHome = {childToParentAgentHome}
               childToParentCustomerHome = {childToParentCustomerHome}
               childToParentMarketerHome = {childToParentMarketerHome}
               childToParentMessage = {childToParentMessage}
               childToParentRefCustomers={childToParentRefCustomers}
               childToParentDCCustomers={childToParentDCCustomers}
               childToParentMarketerDist={childToParentMarketerDist}
               childToParentMarketerPrice={childToParentMarketerPrice}
               userActive={userActive}
              />
           </div>
    
           <div className="mydiv col-12" id="myDivDash" onClick={()=>{handleClickProfile2(); handleClickProfile3();}}>
                <div className="col-12 mainContent">
                    {data && localStorage.getItem('userType')==1 && <UserProfile />}
                    {data && localStorage.getItem('userType')==2 && <UserProfile />}
                    {data && localStorage.getItem('userType')==3 && <UserProfile />}
                    {data && localStorage.getItem('userType')==4 && <UserProfile />}
                    {dataCus && <Customers  
                      childToParentCus1e1={childToParentCus1e1}
                      childToParentMessage={childToParentMessage}/>}

                    {dataRefCustomers && <RefCustomers 
                    childToParentCus1e1={childToParentCus1e1}
                    childToParentMessage={childToParentMessage}/>}

                    {dataDCCustomers && <DCCustomers 
                    childToParentCus1e1={childToParentCus1e1}
                    childToParentMessage={childToParentMessage}/>}

                    {dataCus1 && localStorage.getItem('isCustomerDocumentExist')!='1' &&<Customer1 childToParentCus1e1={childToParentCus1e1}/>}
                    {dataCus1 && localStorage.getItem('isCustomerDocumentExist')=='1' &&<Customer1e1e1 childToParentCus1e1={childToParentCus1e1}/>}
                    {dataCus1e1 && <Customer1e1 
                    childToParentCus2={childToParentCus2}
                    childToParentCus1e3 ={childToParentCus1e3}
                    childToParentCouples = {childToParentCouples}
                    childToParentFarzandan = {childToParentFarzandan}
                    childToParentOtherCus ={childToParentOtherCus}
                    childToParentCus1e1e1 = {childToParentCus1e1e1}
                    />}
                    {dataCus1e1e1 && <Customer1e1e1 childToParentCus1e1={childToParentCus1e1}/>}
                    {dataCus1e2 && <Customer1e2 childToParentCus1e1e1={childToParentCus1e1e1}/>}
                    {dataCus1e3 && <Customer1e3 childToParentCus1e1={childToParentCus1e1}
                    childToParentAplSelfInfoS1={childToParentAplSelfInfoS1}
                    childToParenttahsilChoosePage = {childToParenttahsilChoosePage}
                    childToParentShoghliInfo = {childToParentShoghliInfo}
                    childToParentMaliInfo = {childToParentMaliInfo}
                    childToParentSafarKharejehInfo = {childToParentSafarKharejehInfo}
                    childToParentCus3={childToParentCus3}
                    childToParentKhanevadeh ={childToParentKhanevadeh}
                    childToParentSoalVisa ={childToParentSoalVisa}
                    />}
                    {dataAplSelfInfoS1 && <AplSelfInfoS1  childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataCouples && <Couples  childToParentCus1e1 ={childToParentCus1e1}/>}
                    {dataFarzandan && <Farzandan  childToParentCus1e1 ={childToParentCus1e1}/>}
                    {dataOtherCus && <OtherCus  childToParentCus1e1 ={childToParentCus1e1}/>}
                    {datatahsilChoosePage && <TahsilChoosePage  
                    childToParentCus1e3 ={childToParentCus1e3}
                    childToParentZabanInfo={childToParentZabanInfo}
                    childToParentTahsilInfo={childToParentTahsilInfo}/>}
                    {dataZabanInfo && <ZabanInfo  childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataTahsilInfo && <TahsilInfo  childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataShoghliInfo && <Shoghli  childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataMaliInfo && <Mali childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataKhanevadeh && <Khanevadeh childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataSoalVisa && <SoalVisa childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataSafarKharejehInfo && <SafarKharejeh childToParentCus1e3 ={childToParentCus1e3}/>}
                    {dataCus2 && <Customer2 
                        childToParentCus1={childToParentCus1}
                        childToParentCus1e2={childToParentCus1e2}
                        childToParentCus1e1 ={childToParentCus1e1}/>}
                    {dataCus3 && <Customer3 
                        childToParentCus1e3 ={childToParentCus1e3}
                        />}
                    {dataCus4 && <Customer4 
                        childToParentCus3={childToParentCus3}
                        childToParentCus5={childToParentCus5}
                        />}
                    {dataCus5 && <Customer5 
                        childToParentCus4={childToParentCus4}
                        childToParentCus6={childToParentCus6}
                        />}
                    {dataCus6 && <Customer6 
                        childToParentCus5={childToParentCus5}
                        childToParentCus7={childToParentCus7}
                        />}
                    {dataCus7 && <Customer7 
                        childToParentCus6={childToParentCus6}
                        childToParentCus8={childToParentCus8}
                        />}
                    {dataCus8 && <Customer8 
                        childToParentCus7={childToParentCus7}
                        childToParentCus9={childToParentCus9}
                        />}
                    {dataCus9 && <Customer9 
                        childToParentCus8={childToParentCus8}
                        childToParentCus10={childToParentCus10}
                        />}
                    {dataCus10 && <Customer10 
                        childToParentCus9={childToParentCus9}
                        childToParentCus11={childToParentCus11}
                        />}
                    {dataCus11 && <Customer11 
                        childToParentCus10={childToParentCus10}
                        childToParentCus12={childToParentCus12}
                        />}
                    {dataCus12 && <Customer12 
                        childToParentCus11={childToParentCus11}
                        
                        />}
                    {dataCusA2 && <CustomersA2  
                      childToParentCus1e1={childToParentCus1e1}
                      childToParentMessage={childToParentMessage}/>}
                    {dataCusADis && <CustomersADis  
                      childToParentCus1e1={childToParentCus1e1}
                      childToParentMessage={childToParentMessage}/>}
                    {dataCusA2A && <CustomersA2A  
                      childToParentCus1e1={childToParentCus1e1}
                      childToParentMessage={childToParentMessage}/>}
                    {dataCusMark && <CustomersMark childToParentMessage={childToParentMessage}/>}
                    {dataAgents && <Agents childToParentMessage={childToParentMessage}/>}
                    {dataLawyers && <Lawyers childToParentMessage={childToParentMessage}/>}
                    {dataPrices && <Prices />}
                    {dataMessage && <Message />}
                    {dataMarketerDist && <MarketerDist />}
                    {dataMarketerPrice && <MarketerPrice />}
                    {dataLawyer && <AgentLawyer childToParentMessage={childToParentMessage}/>}
                    {dataAgentPrice && <AgentPrice />}
                    {dataAgentAgent && <AgentAgent childToParentMessage={childToParentMessage}/>}
                    {dataAgentDis && <AgentDis childToParentMessage={childToParentMessage}/>}
                    {dataLawyerHome && !dataCus1e1 &&<LawyerHome childToParentPrices={childToParentPrices} />}
                    {localStorage.getItem('userType')==1 && !data && !dataMessage && !dataLawyerHome && !dataCus && !dataAgents && !dataLawyers && !dataPrices && !dataCus1e1 && !data 
                    && !dataAplSelfInfoS1 && !dataCustomerHome 
                    && !dataCus1 && !dataCus1e1 && !dataCus1e1e1 
                    && !dataCus1e2 && !dataCus1e3 && !dataCus2 
                    && !dataCus3 && !dataCus4 && !dataCus5 
                    && !dataCus6 && !dataCus7 && !dataCus8 
                    && !dataCus9 && !dataCus10 && !dataCus11 
                    && !dataCus12 && !datatahsilChoosePage 
                    && !dataZabanInfo && !dataTahsilInfo 
                    && !dataShoghliInfo && !dataMaliInfo 
                    && !dataSafarKharejehInfo 
                    && !dataCouples && !dataKhanevadeh 
                    && !dataFarzandan && !dataOtherCus 
                    && !dataSoalVisa && !dataMessage 
                    && !dataRefCustomers && !dataDCCustomers && <LawyerHome childToParentPrices={childToParentPrices} />}

                     
                    {dataAgentHome && <AgentHome />}
                   
                   
                    {localStorage.getItem('userType')==2 && 
                    !data && !dataMarketerDist && !dataMessage && 
                    !dataAgentHome && !dataCusA2 && !dataAgentAgent  && !dataCusADis && !dataCusA2A &&
                    !dataAgentDis  && !dataLawyer &&  !dataAplSelfInfoS1 && !dataCustomerHome 
                    && !dataCus1 && !dataCus1e1 && !dataCus1e1e1 
                    && !dataCus1e2 && !dataCus1e3 && !dataCus2 
                    && !dataCus3 && !dataCus4 && !dataCus5 
                    && !dataCus6 && !dataCus7 && !dataCus8 
                    && !dataCus9 && !dataCus10 && !dataCus11 
                    && !dataCus12 && !datatahsilChoosePage 
                    && !dataZabanInfo && !dataTahsilInfo 
                    && !dataShoghliInfo && !dataMaliInfo 
                    && !dataSafarKharejehInfo 
                    && !dataCouples && !dataKhanevadeh 
                    && !dataFarzandan && !dataOtherCus 
                    && !dataSoalVisa && !dataMessage && !dataAgentPrice
                    && !dataRefCustomers &&<AgentHome />}
                   

                    {dataMarketerHome && <MarketerHome />}
                    {localStorage.getItem('userType')==4 && !data && !dataMarketerPrice 
                    && !dataCusMark && !dataMessage && !dataMarketerHome && !dataLawyer 
                    &&<MarketerHome />}

                    {dataCustomerHome && <CustomerHome />}
                    {localStorage.getItem('userType')==3 && !data 
                    && !dataAplSelfInfoS1 && !dataCustomerHome 
                    && !dataCus1 && !dataCus1e1 && !dataCus1e1e1 
                    && !dataCus1e2 && !dataCus1e3 && !dataCus2 
                    && !dataCus3 && !dataCus4 && !dataCus5 
                    && !dataCus6 && !dataCus7 && !dataCus8 
                    && !dataCus9 && !dataCus10 && !dataCus11 
                    && !dataCus12 && !datatahsilChoosePage 
                    && !dataZabanInfo && !dataTahsilInfo 
                    && !dataShoghliInfo && !dataMaliInfo 
                    && !dataSafarKharejehInfo 
                    && !dataCouples && !dataKhanevadeh 
                    && !dataFarzandan && !dataOtherCus 
                    && !dataSoalVisa && !dataMessage &&<CustomerHome />}
{/* 
                {teleUserType==1 && userActive && !data && !dataCus && !dataAgents && !dataPrices &&<img src={MainPic} className="mainPic" />}
                {teleUserType!=1 && <img src={mainDashboardAC} className="mainPic" />} */}
                {/* {!userActive &&  !data &&<img src={mainDashboardNotActive} className="mainPic d-none d-md-block" />}
                {!userActive &&  !data &&<img src={mainDashboardNotActiveM} className="mainPic d-block d-sm-none" />} */}
                </div>
           </div>
           
       </div>
       

    </div>
     );
}
 
export default Dashborad;