import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,ArrowUp2,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import axios from 'axios';

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitPersonalInformationDocument' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
       Email: localStorage.getItem('email'),
      
       CustomerDocumentNumber:localStorage.getItem('documentCode'),
       CustomerPassportNumber:values.PassportNumber,
       CustomerPassportFirstName:values.Name,
       CustomerPassportLastName:values.Family,
       CustomerPassportCreationDate:DateofCreationvalue.toString(),
       CustomerPassportDateOfExpiry:DateofExpirevalue.toString(),
       CustomerPassportCreationPlace:values.PlaceOfCreation,
       CustomerCountryOfResidence:values.CountryOFPass,
       CustomerBirthdayOnThePassport:BirthDayvalue.toString(),
       CustomerNationalIdentityCard:values.IDNumber,
       CustomerPlaceOfNationalIdentityCard:values.PlaceIssuanceNationalCard,
       CustomerBirthCertificateNumber:values.BirthCertificateNumber,
       CustomerFathersNameOnTheBirthCertificate:values.FatherBirthCertificateNumber,
       CustomerMothersNameOnTheBirthCertificate:values.MothersBirthCertificateNumber,
       CustomerAliasNameChangedInTheIdentityCard:values.AliasName,
       CustomerPlaceOfBirth:values.placeofBirth,
       CustomerMilitaryCardNumber:values.MilitaryCardNumber,
       CustomerDateOfIssuanceOfMilitaryCard:DateIssuanceMilitaryCardvalue.toString(),
       CustomerTypeOfMilitaryCard:values.TypeMilitaryCard,
       CustomerPlaceOfMilitaryService:values.PlaceMilitaryService,
       CustomerHeight:values.Height,
       CustomerSkinColor:values.SkinColor,
       CustomerEyeColor:values.EyeColor,
       CustomerPostageCodeOne:values.ZipCode,
       CustomerResidenceAddressOne:values.Address,
       CustomerPostageCodeTwo:values.ZipCode2,
       CustomerResidenceAddressTwo:values.Address2,
       CustomerLandlinePhoneNumberOne:values.proCallNumber,
       CustomerPhoneNumberOne:values.proPhone,
       CustomerWorkPhoneNumberOne:values.WorkplaceCallNumber,
       CustomerLandlinePhoneNumberTwo:values.proCallNumber2,
       CustomerPhoneNumberTwo:values.proPhone2,
       CustomerWorkPhoneNumberTwo:values.WorkplaceCallNumber2,
       CustomerEmailAddress:values.proEmail,
       CustomerTypeOfMarriage:values.TypeMarriage,
       CustomerCountry:values.Country,
       CustomerEvaluationFormTrackingNumber:values.customerEvaluationFormTrackingNumber,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentPersonalInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerPassportNumber:values.PassportNumber,
           CustomerPassportFirstName:values.Name,
           CustomerPassportLastName:values.Family,
           CustomerPassportCreationDate:DateofCreationvalue.toString(),
           CustomerPassportDateOfExpiry:DateofExpirevalue.toString(),
           CustomerPassportCreationPlace:values.PlaceOfCreation,
           CustomerCountryOfResidence:values.CountryOFPass,
           CustomerBirthdayOnThePassport:BirthDayvalue.toString(),
           CustomerNationalIdentityCard:values.IDNumber,
           CustomerPlaceOfNationalIdentityCard:values.PlaceIssuanceNationalCard,
           CustomerBirthCertificateNumber:values.BirthCertificateNumber,
           CustomerFathersNameOnTheBirthCertificate:values.FatherBirthCertificateNumber,
           CustomerMothersNameOnTheBirthCertificate:values.MothersBirthCertificateNumber,
           CustomerAliasNameChangedInTheIdentityCard:values.AliasName,
           CustomerPlaceOfBirth:values.placeofBirth,
           CustomerMilitaryCardNumber:values.MilitaryCardNumber,
           CustomerDateOfIssuanceOfMilitaryCard:DateIssuanceMilitaryCardvalue.toString(),
           CustomerTypeOfMilitaryCard:values.TypeMilitaryCard,
           CustomerPlaceOfMilitaryService:values.PlaceMilitaryService,
           CustomerHeight:values.Height,
           CustomerSkinColor:values.SkinColor,
           CustomerEyeColor:values.EyeColor,
           CustomerPostageCodeOne:values.ZipCode,
           CustomerResidenceAddressOne:values.Address,
           CustomerPostageCodeTwo:values.ZipCode2,
           CustomerResidenceAddressTwo:values.Address2,
           CustomerLandlinePhoneNumberOne:values.proCallNumber,
           CustomerPhoneNumberOne:values.proPhone,
           CustomerWorkPhoneNumberOne:values.WorkplaceCallNumber,
           CustomerLandlinePhoneNumberTwo:values.proCallNumber2,
           CustomerPhoneNumberTwo:values.proPhone2,
           CustomerWorkPhoneNumberTwo:values.WorkplaceCallNumber2,
           CustomerEmailAddress:values.proEmail,
           CustomerTypeOfMarriage:values.TypeMarriage,
           CustomerCountry:values.Country,
           CustomerEvaluationFormTrackingNumber:values.customerEvaluationFormTrackingNumber,
   

   
       })
      }).then(response => response.json()).then(response=>{
   


        localStorage.setItem('CustomerPassportNumber',response.CustomerPassportNumber);
        localStorage.setItem('CustomerPassportFirstName',response.CustomerPassportFirstName);
        localStorage.setItem('CustomerPassportLastName',response.CustomerPassportLastName);
        localStorage.setItem('CustomerPassportCreationDate',response.CustomerPassportCreationDate);
        localStorage.setItem('CustomerPassportDateOfExpiry',response.CustomerPassportDateOfExpiry);
        localStorage.setItem('CustomerPassportCreationPlace',response.CustomerPassportCreationPlace);
        localStorage.setItem('CustomerCountryOfResidence',response.CustomerCountryOfResidence);
        localStorage.setItem('CustomerBirthdayOnThePassport',response.CustomerBirthdayOnThePassport);
        localStorage.setItem('CustomerNationalIdentityCard',response.CustomerNationalIdentityCard);
        localStorage.setItem('CustomerPlaceOfNationalIdentityCard',response.CustomerPlaceOfNationalIdentityCard);
        localStorage.setItem('CustomerBirthCertificateNumber',response.CustomerBirthCertificateNumber);
        localStorage.setItem('CustomerFathersNameOnTheBirthCertificate',response.CustomerFathersNameOnTheBirthCertificate);
        localStorage.setItem('CustomerMothersNameOnTheBirthCertificate',response.CustomerMothersNameOnTheBirthCertificate);
        localStorage.setItem('CustomerAliasNameChangedInTheIdentityCard',response.CustomerAliasNameChangedInTheIdentityCard);
        localStorage.setItem('CustomerPlaceOfBirth',response.CustomerPlaceOfBirth);
        localStorage.setItem('CustomerMilitaryCardNumber',response.CustomerMilitaryCardNumber);
        localStorage.setItem('CustomerDateOfIssuanceOfMilitaryCard',response.CustomerDateOfIssuanceOfMilitaryCard);
        localStorage.setItem('CustomerTypeOfMilitaryCard',response.CustomerTypeOfMilitaryCard);
        localStorage.setItem('CustomerPlaceOfMilitaryService',response.CustomerPlaceOfMilitaryService);
        localStorage.setItem('CustomerHeight',response.CustomerHeight);
        localStorage.setItem('CustomerSkinColor',response.CustomerSkinColor);
        localStorage.setItem('CustomerEyeColor',response.CustomerEyeColor);
        localStorage.setItem('CustomerPostageCodeOne',response.CustomerPostageCodeOne);
        localStorage.setItem('CustomerResidenceAddressOne',response.CustomerResidenceAddressOne);
        localStorage.setItem('CustomerPostageCodeTwo',response.CustomerPostageCodeTwo);
        localStorage.setItem('CustomerResidenceAddressTwo',response.CustomerResidenceAddressTwo);
        localStorage.setItem('CustomerLandlinePhoneNumberOne',response.CustomerLandlinePhoneNumberOne);
        localStorage.setItem('CustomerPhoneNumberOne',response.CustomerPhoneNumberOne);
        localStorage.setItem('CustomerWorkPhoneNumberOne',response.CustomerWorkPhoneNumberOne);
        localStorage.setItem('CustomerLandlinePhoneNumberTwo',response.CustomerLandlinePhoneNumberTwo);
        localStorage.setItem('CustomerPhoneNumberTwo',response.CustomerPhoneNumberTwo);
        localStorage.setItem('CustomerWorkPhoneNumberTwo',response.CustomerWorkPhoneNumberTwo);
        localStorage.setItem('CustomerEmailAddress',response.CustomerEmailAddress);
        localStorage.setItem('CustomerTypeOfMarriage',response.CustomerTypeOfMarriage);
        localStorage.setItem('CustomerCountry',response.CustomerCountry);
        localStorage.setItem('CustomerEvaluationFormTrackingNumber',response.CustomerEvaluationFormTrackingNumber);
        
        

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
    values.PassportNumber =localStorage.getItem('CustomerPassportNumber');
    values.Name =localStorage.getItem('CustomerPassportFirstName');
    values.Family =localStorage.getItem('CustomerPassportLastName');
    values.DateofCreation =localStorage.getItem('CustomerPassportCreationDate');
    values.ExpireDate =localStorage.getItem('CustomerPassportDateOfExpiry');
    values.PlaceOfCreation =localStorage.getItem('CustomerPassportCreationPlace');
    values.CountryOFPass =localStorage.getItem('CustomerCountryOfResidence');
    values.BirthDayOnPass =localStorage.getItem('CustomerBirthdayOnThePassport');
    values.IDNumber =localStorage.getItem('CustomerNationalIdentityCard');
    values.PlaceIssuanceNationalCard =localStorage.getItem('CustomerPlaceOfNationalIdentityCard');
    values.BirthCertificateNumber =localStorage.getItem('CustomerBirthCertificateNumber');
    values.FatherBirthCertificateNumber =localStorage.getItem('CustomerFathersNameOnTheBirthCertificate');
    values.MothersBirthCertificateNumber =localStorage.getItem('CustomerMothersNameOnTheBirthCertificate');
    values.AliasName =localStorage.getItem('CustomerAliasNameChangedInTheIdentityCard');
    values.placeofBirth =localStorage.getItem('CustomerPlaceOfBirth');
    values.MilitaryCardNumber =localStorage.getItem('CustomerMilitaryCardNumber');
    values.DateIssuanceMilitaryCardvalue =localStorage.getItem('CustomerDateOfIssuanceOfMilitaryCard');
    values.TypeMilitaryCard =localStorage.getItem('CustomerTypeOfMilitaryCard');
    values.PlaceMilitaryService =localStorage.getItem('CustomerPlaceOfMilitaryService');
    values.Height =localStorage.getItem('CustomerHeight');
    values.SkinColor =localStorage.getItem('CustomerSkinColor');
    values.EyeColor =localStorage.getItem('CustomerEyeColor');
    values.ZipCode =localStorage.getItem('CustomerPostageCodeOne');
    values.Address =localStorage.getItem('CustomerResidenceAddressOne');
    values.ZipCode2 =localStorage.getItem('CustomerPostageCodeTwo');
    values.Address2 =localStorage.getItem('CustomerResidenceAddressTwo');
    values.proCallNumber =localStorage.getItem('CustomerLandlinePhoneNumberOne');
    values.proPhone =localStorage.getItem('CustomerPhoneNumberOne');
    values.WorkplaceCallNumber =localStorage.getItem('CustomerWorkPhoneNumberOne');
    values.proCallNumber2 =localStorage.getItem('CustomerLandlinePhoneNumberTwo');
    values.proPhone2 =localStorage.getItem('CustomerPhoneNumberTwo');
    values.WorkplaceCallNumber2 =localStorage.getItem('CustomerWorkPhoneNumberTwo');
    values.proEmail =localStorage.getItem('CustomerEmailAddress');
    values.TypeMarriage =localStorage.getItem('CustomerTypeOfMarriage');
    values.Country =localStorage.getItem('CustomerCountry');
    values.CustomerEvaluationFormTrackingNumber=localStorage.getItem('CustomerEvaluationFormTrackingNumber');
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
else if(localStorage.getItem('whoIs')=='sp') {
  if(!localStorage.getItem('EvFormUpdate')){ 
    fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpousePersonalInformation' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({
  //Evaluation Customer form
        //Evaluation Customer Form Personal Information
         Email: localStorage.getItem('email'),
        
         CustomerDocumentNumber:localStorage.getItem('documentCode'),
         CustomerSpousePassportNumber:values.PassportNumber,
         CustomerSpousePassportFirstName:values.Name,
         CustomerSpousePassportLastName:values.Family,
         CustomerSpousePassportCreationDate:DateofCreationvalue.toString(),
         CustomerSpousePassportDateOfExpiry:DateofExpirevalue.toString(),
         CustomerSpousePassportCreationPlace:values.PlaceOfCreation,
         CustomerSpouseCountryOfResidence:values.CountryOFPass,
         CustomerSpouseBirthdayOnThePassport:BirthDayvalue.toString(),
         CustomerSpouseNationalIdentityCard:values.IDNumber,
         CustomerSpousePlaceOfNationalIdentityCard:values.PlaceIssuanceNationalCard,
         CustomerSpouseBirthCertificateNumber:values.BirthCertificateNumber,
         CustomerSpouseFathersNameOnTheBirthCertificate:values.FatherBirthCertificateNumber,
         CustomerSpouseMothersNameOnTheBirthCertificate:values.MothersBirthCertificateNumber,
         CustomerSpouseAliasNameChangedInTheIdentityCard:values.AliasName,
         CustomerSpousePlaceOfBirth:values.placeofBirth,
         CustomerSpouseMilitaryCardNumber:values.MilitaryCardNumber,
         CustomerSpouseDateOfIssuanceOfMilitaryCard:DateIssuanceMilitaryCardvalue.toString(),
         CustomerSpouseTypeOfMilitaryCard:values.TypeMilitaryCard,
         CustomerSpousePlaceOfMilitaryService:values.PlaceMilitaryService,
         CustomerSpouseHeight:values.Height,
         CustomerSpouseSkinColor:values.SkinColor,
         CustomerSpouseEyeColor:values.EyeColor,
         CustomerSpousePostageCodeOne:values.ZipCode,
         CustomerSpouseResidenceAddressOne:values.Address,
         CustomerSpousePostageCodeTwo:values.ZipCode2,
         CustomerSpouseResidenceAddressTwo:values.Address2,
         CustomerSpouseLandlinePhoneNumberOne:values.proCallNumber,
         CustomerSpousePhoneNumberOne:values.proPhone,
         CustomerSpouseWorkPhoneNumberOne:values.WorkplaceCallNumber,
         CustomerSpouseLandlinePhoneNumberTwo:values.proCallNumber2,
         CustomerSpousePhoneNumberTwo:values.proPhone2,
         CustomerSpouseWorkPhoneNumberTwo:values.WorkplaceCallNumber2,
         CustomerSpouseEmailAddress:values.proEmail,
         CustomerSpouseTypeOfMarriage:values.TypeMarriage,
         CustomerSpouseCountry:values.Country,
         CustomerSpouseEvaluationFormTrackingNumber:values.CustomerEvaluationFormTrackingNumber,
    
    })
   }).then(response => response.json()).then(response=>{
  
     if(response.statusCode==400 || response.statusCode==404)
     {
       console.log(response.statusMessage);
       console.log(values.EnglishCheckBox);
      //  setFormError(true);
      //  setHelpStatusMessage(response.statusMessage);
      //  setIspending(false);
     }
     else{
      //  setHelpStatusMessage("");
      //  setIspending(false);
      //  history.push('/regstep6');
     }
       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
  
       // }
     })
    }
    else 
    {
      fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpousePersonalInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
     //Evaluation Customer form
             //Evaluation Customer Form Personal Information
             Email: localStorage.getItem('email'),
             CustomerCode:localStorage.getItem('customerCode'),

             CustomerSpousePassportNumber:values.PassportNumber,
             CustomerSpousePassportFirstName:values.Name,
             CustomerSpousePassportLastName:values.Family,
             CustomerSpousePassportCreationDate:DateofCreationvalue.toString(),
             CustomerSpousePassportDateOfExpiry:DateofExpirevalue.toString(),
             CustomerSpousePassportCreationPlace:values.PlaceOfCreation,
             CustomerSpouseCountryOfResidence:values.CountryOFPass,
             CustomerSpouseBirthdayOnThePassport:BirthDayvalue.toString(),
             CustomerSpouseNationalIdentityCard:values.IDNumber,
             CustomerSpousePlaceOfNationalIdentityCard:values.PlaceIssuanceNationalCard,
             CustomerSpouseBirthCertificateNumber:values.BirthCertificateNumber,
             CustomerSpouseFathersNameOnTheBirthCertificate:values.FatherBirthCertificateNumber,
             CustomerSpouseMothersNameOnTheBirthCertificate:values.MothersBirthCertificateNumber,
             CustomerSpouseAliasNameChangedInTheIdentityCard:values.AliasName,
             CustomerSpousePlaceOfBirth:values.placeofBirth,
             CustomerSpouseMilitaryCardNumber:values.MilitaryCardNumber,
             CustomerSpouseDateOfIssuanceOfMilitaryCard:DateIssuanceMilitaryCardvalue.toString(),
             CustomerSpouseTypeOfMilitaryCard:values.TypeMilitaryCard,
             CustomerSpousePlaceOfMilitaryService:values.PlaceMilitaryService,
             CustomerSpouseHeight:values.Height,
             CustomerSpouseSkinColor:values.SkinColor,
             CustomerSpouseEyeColor:values.EyeColor,
             CustomerSpousePostageCodeOne:values.ZipCode,
             CustomerSpouseResidenceAddressOne:values.Address,
             CustomerSpousePostageCodeTwo:values.ZipCode2,
             CustomerSpouseResidenceAddressTwo:values.Address2,
             CustomerSpouseLandlinePhoneNumberOne:values.proCallNumber,
             CustomerSpousePhoneNumberOne:values.proPhone,
             CustomerSpouseWorkPhoneNumberOne:values.WorkplaceCallNumber,
             CustomerSpouseLandlinePhoneNumberTwo:values.proCallNumber2,
             CustomerSpousePhoneNumberTwo:values.proPhone2,
             CustomerSpouseWorkPhoneNumberTwo:values.WorkplaceCallNumber2,
             CustomerSpouseEmailAddress:values.proEmail,
             CustomerSpouseTypeOfMarriage:values.TypeMarriage,
             CustomerSpouseCountry:values.Country,
             CustomerSpouseEvaluationFormTrackingNumber:values.CustomerEvaluationFormTrackingNumber,
     
  
     
         })
        }).then(response => response.json()).then(response=>{
     
  
  
          localStorage.setItem('CustomerSpousePassportNumber',response.customerSpousePassportNumber);
          localStorage.setItem('CustomerSpousePassportFirstName',response.customerSpousePassportFirstName);
          localStorage.setItem('CustomerSpousePassportLastName',response.customerSpousePassportLastName);
          localStorage.setItem('CustomerSpousePassportCreationDate',response.customerSpousePassportCreationDate);
          localStorage.setItem('CustomerSpousePassportDateOfExpiry',response.customerSpousePassportDateOfExpiry);
          localStorage.setItem('CustomerSpousePassportCreationPlace',response.customerSpousePassportCreationPlace);
          localStorage.setItem('CustomerSpouseCountryOfResidence',response.customerSpouseCountryOfResidence);
          localStorage.setItem('CustomerSpouseBirthdayOnThePassport',response.customerSpouseBirthdayOnThePassport);
          localStorage.setItem('CustomerSpouseNationalIdentityCard',response.customerSpouseNationalIdentityCard);
          localStorage.setItem('CustomerSpousePlaceOfNationalIdentityCard',response.customerSpousePlaceOfNationalIdentityCard);
          localStorage.setItem('CustomerSpouseBirthCertificateNumber',response.customerSpouseBirthCertificateNumber);
          localStorage.setItem('CustomerSpouseFathersNameOnTheBirthCertificate',response.customerSpouseFathersNameOnTheBirthCertificate);
          localStorage.setItem('CustomerSpouseMothersNameOnTheBirthCertificate',response.customerSpouseMothersNameOnTheBirthCertificate);
          localStorage.setItem('CustomerSpouseAliasNameChangedInTheIdentityCard',response.customerSpouseAliasNameChangedInTheIdentityCard);
          localStorage.setItem('CustomerSpousePlaceOfBirth',response.customerSpousePlaceOfBirth);
          localStorage.setItem('CustomerSpouseMilitaryCardNumber',response.customerSpouseMilitaryCardNumber);
          localStorage.setItem('CustomerSpouseDateOfIssuanceOfMilitaryCard',response.customerSpouseDateOfIssuanceOfMilitaryCard);
          localStorage.setItem('CustomerSpouseTypeOfMilitaryCard',response.customerSpouseTypeOfMilitaryCard);
          localStorage.setItem('CustomerSpousePlaceOfMilitaryService',response.customerSpousePlaceOfMilitaryService);
          localStorage.setItem('CustomerSpouseHeight',response.customerSpouseHeight);
          localStorage.setItem('CustomerSpouseSkinColor',response.customerSpouseSkinColor);
          localStorage.setItem('CustomerSpouseEyeColor',response.customerSpouseEyeColor);
          localStorage.setItem('CustomerSpousePostageCodeOne',response.customerSpousePostageCodeOne);
          localStorage.setItem('CustomerSpouseResidenceAddressOne',response.customerSpouseResidenceAddressOne);
          localStorage.setItem('CustomerSpousePostageCodeTwo',response.customerSpousePostageCodeTwo);
          localStorage.setItem('CustomerSpouseResidenceAddressTwo',response.customerSpouseResidenceAddressTwo);
          localStorage.setItem('CustomerSpouseLandlinePhoneNumberOne',response.customerSpouseLandlinePhoneNumberOne);
          localStorage.setItem('CustomerSpousePhoneNumberOne',response.customerSpousePhoneNumberOne);
          localStorage.setItem('CustomerSpouseWorkPhoneNumberOne',response.customerSpouseWorkPhoneNumberOne);
          localStorage.setItem('CustomerSpouseLandlinePhoneNumberTwo',response.customerSpouseLandlinePhoneNumberTwo);
          localStorage.setItem('CustomerSpousePhoneNumberTwo',response.customerSpousePhoneNumberTwo);
          localStorage.setItem('CustomerSpouseWorkPhoneNumberTwo',response.customerSpouseWorkPhoneNumberTwo);
          localStorage.setItem('CustomerSpouseEmailAddress',response.customerSpouseEmailAddress);
          localStorage.setItem('CustomerSpouseTypeOfMarriage',response.customerSpouseTypeOfMarriage);
          localStorage.setItem('CustomerSpouseCountry',response.customerSpouseCountry);
          localStorage.setItem('CustomerSpouseEvaluationFormTrackingNumber',response.customerSpouseEvaluationFormTrackingNumber);
  
          localStorage.setItem('EvFormUpdate',true);
          
          console.log(localStorage.getItem('EvFormUpdate'));
      
      
      values.PassportNumber =localStorage.getItem('CustomerSpousePassportNumber');
      values.Name =localStorage.getItem('CustomerSpousePassportFirstName');
      values.Family =localStorage.getItem('CustomerSpousePassportLastName');
      values.DateofCreation =localStorage.getItem('CustomerSpousePassportCreationDate');
      values.ExpireDate =localStorage.getItem('CustomerSpousePassportDateOfExpiry');
      values.PlaceOfCreation =localStorage.getItem('CustomerSpousePassportCreationPlace');
      values.CountryOFPass =localStorage.getItem('CustomerSpouseCountryOfResidence');
      values.BirthDayOnPass =localStorage.getItem('CustomerSpouseBirthdayOnThePassport');
      values.IDNumber =localStorage.getItem('CustomerSpouseNationalIdentityCard');
      values.PlaceIssuanceNationalCard =localStorage.getItem('CustomerSpousePlaceOfNationalIdentityCard');
      values.BirthCertificateNumber =localStorage.getItem('CustomerSpouseBirthCertificateNumber');
      values.FatherBirthCertificateNumber =localStorage.getItem('CustomerSpouseFathersNameOnTheBirthCertificate');
      values.MothersBirthCertificateNumber =localStorage.getItem('CustomerSpouseMothersNameOnTheBirthCertificate');
      values.AliasName =localStorage.getItem('CustomerSpouseAliasNameChangedInTheIdentityCard');
      values.placeofBirth =localStorage.getItem('CustomerSpousePlaceOfBirth');
      values.MilitaryCardNumber =localStorage.getItem('CustomerSpouseMilitaryCardNumber');
      values.DateIssuanceMilitaryCardvalue =localStorage.getItem('CustomerSpouseDateOfIssuanceOfMilitaryCard');
      values.TypeMilitaryCard =localStorage.getItem('CustomerSpouseTypeOfMilitaryCard');
      values.PlaceMilitaryService =localStorage.getItem('CustomerSpousePlaceOfMilitaryService');
      values.Height =localStorage.getItem('CustomerSpouseHeight');
      values.SkinColor =localStorage.getItem('CustomerSpouseSkinColor');
      values.EyeColor =localStorage.getItem('CustomerSpouseEyeColor');
      values.ZipCode =localStorage.getItem('CustomerSpousePostageCodeOne');
      values.Address =localStorage.getItem('CustomerSpouseResidenceAddressOne');
      values.ZipCode2 =localStorage.getItem('CustomerSpousePostageCodeTwo');
      values.Address2 =localStorage.getItem('CustomerSpouseResidenceAddressTwo');
      values.proCallNumber =localStorage.getItem('CustomerSpouseLandlinePhoneNumberOne');
      values.proPhone =localStorage.getItem('CustomerSpousePhoneNumberOne');
      values.WorkplaceCallNumber =localStorage.getItem('CustomerSpouseWorkPhoneNumberOne');
      values.proCallNumber2 =localStorage.getItem('CustomerSpouseLandlinePhoneNumberTwo');
      values.proPhone2 =localStorage.getItem('CustomerSpousePhoneNumberTwo');
      values.WorkplaceCallNumber2 =localStorage.getItem('CustomerSpouseWorkPhoneNumberTwo');
      values.proEmail =localStorage.getItem('CustomerSpouseEmailAddress');
      values.TypeMarriage =localStorage.getItem('CustomerSpouseTypeOfMarriage');
      values.Country =localStorage.getItem('CustomerSpouseCountry');
      values.CustomerEvaluationFormTrackingNumber = localStorage.getItem('CustomerEvaluationFormTrackingNumber');
      // values. =localStorage.getItem('CustomerSpouseEvaluationFormTrackingNumber');
  
      
      
  
  
  
  
          if(response.statusCode==400 || response.statusCode==404)
          {
            console.log(response.statusMessage);
            console.log(values.EnglishCheckBox);
           //  setFormError(true);
           //  setHelpStatusMessage(response.statusMessage);
           //  setIspending(false);
          }
          else{
           //  setHelpStatusMessage("");
           //  setIspending(false);
           //  history.push('/regstep6');
          }
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
        })
    
    }
}
}
 
  const [dataC, setDataC] = useState([]);
  const fetchData = () => {
    fetch("https://telesiness.ir/api/Countries/AllCountery")
      .then((res) => res.json())
      .then((result) => setDataC(result))
      .catch((err) => console.log("error"));
      
  };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }
  useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);
  const [values, setValues] = useState({
    // proFirstName: 'kambiz',
    // proLastName: 'fakhr',
    // Gender: 'male',
    // birthDate: new Date(),
    // proEmail: 'kambiz.fakhr@yahoo.com',
    // proPhone:'3352258774',
    // proAbout:'I am Sofware Engineer',
    // firstTimeEdit:true,
    // firstTimeEditJob:true,
    // firstTimeEditEducational:true,
    // firstTimeEditAddress:true,
    // firstTimeEditPersonal:true,
    ProPic:'',
    // Country:'Iran',
    // City:'Tehran',
    // Address:'Iran - Tehran - Vanak - Zafar ',
    // ZipCode:'5174875988',
    // proNo:'61',
    // Level:'',
    // FieldOfStudy:'',
    // University:'',
    // JobCountry:'USA',
    // JobCity:'',
    // Jobspecialty:'',
    JobUpload:'',
    // iccrc:'',
    // website:'',
    // Specialization:'',
    // Certificate:'',
    // userNameLast:'setin',
    // showPassword: false,
    CustomerEvaluationFormTrackingNumber:'',
    proFirstName: '',
    proLastName: '',
    Gender: '',
    IDNumber:'',
    NationalCode:'',
    birthDate: '',
    proEmail: '',
    proPhone: '',
    proCallNumber:"",
    proPhone2: '',
    proCallNumber2:"",
    proAbout: '',
    firstTimeEdit:'',
    firstTimeEditJob:'',
    firstTimeEditEducational:'',
    firstTimeEditAddress:true,
    firstTimeEditPersonal:true,
    // ProPic: '',
    Country: '',
    City: '',
    Address: '',
    ZipCode: '',
    Address2: '',
    ZipCode2: '',
    placeofBirth:'',
    proNo:'61',
    Level: '',
    FieldOfStudy: '',
    University: '',
    JobCountry: '',
    JobCity:'',
    Jobspecialty: '',
    PassportNumber: '',
    Name:'',
    Family:'',
    DateofCreation: '',
    ExpireDate:'',
    PlaceOfCreation:'',
    CountryOFPass:'',
    BirthDayOnPass:'',
    BirthCertificateNumber:'',
    PlaceIssuanceNationalCard:'',
    FatherBirthCertificateNumber:'',
    MothersBirthCertificateNumber:'',
    AliasName:'',
    MilitaryCardNumber:'',
    TypeMilitaryCard:'',
    PlaceMilitaryService:'',
    Height:'',
    SkinColor:'',
    EyeColor:'',
    WorkplaceCallNumber:'',
    WorkplaceCallNumber2:'',
    FormTrackingNumber:'',
    TypeMarriage:'',
  });

 
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;





//*************************************** */
console.log(localStorage.getItem('whoIs'));
//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentPersonalInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  localStorage.setItem('CustomerPassportNumber',response.customerPassportNumber);
  localStorage.setItem('CustomerPassportFirstName',response.customerPassportFirstName);
  localStorage.setItem('CustomerPassportLastName',response.customerPassportLastName);
  localStorage.setItem('CustomerPassportCreationDate',response.customerPassportCreationDate);
  localStorage.setItem('CustomerPassportDateOfExpiry',response.customerPassportDateOfExpiry);
  localStorage.setItem('CustomerPassportCreationPlace',response.customerPassportCreationPlace);
  localStorage.setItem('CustomerCountryOfResidence',response.customerCountryOfResidence);
  localStorage.setItem('CustomerBirthdayOnThePassport',response.customerBirthdayOnThePassport);
  localStorage.setItem('CustomerNationalIdentityCard',response.customerNationalIdentityCard);
  localStorage.setItem('CustomerPlaceOfNationalIdentityCard',response.customerPlaceOfNationalIdentityCard);
  localStorage.setItem('CustomerBirthCertificateNumber',response.customerBirthCertificateNumber);
  localStorage.setItem('CustomerFathersNameOnTheBirthCertificate',response.customerFathersNameOnTheBirthCertificate);
  localStorage.setItem('CustomerMothersNameOnTheBirthCertificate',response.customerMothersNameOnTheBirthCertificate);
  localStorage.setItem('CustomerAliasNameChangedInTheIdentityCard',response.customerAliasNameChangedInTheIdentityCard);
  localStorage.setItem('CustomerPlaceOfBirth',response.customerPlaceOfBirth);
  localStorage.setItem('CustomerMilitaryCardNumber',response.customerMilitaryCardNumber);
  localStorage.setItem('CustomerDateOfIssuanceOfMilitaryCard',response.customerDateOfIssuanceOfMilitaryCard);
  localStorage.setItem('CustomerTypeOfMilitaryCard',response.customerTypeOfMilitaryCard);
  localStorage.setItem('CustomerPlaceOfMilitaryService',response.customerPlaceOfMilitaryService);
  localStorage.setItem('CustomerHeight',response.customerHeight);
  localStorage.setItem('CustomerSkinColor',response.customerSkinColor);
  localStorage.setItem('CustomerEyeColor',response.customerEyeColor);
  localStorage.setItem('CustomerPostageCodeOne',response.customerPostageCodeOne);
  localStorage.setItem('CustomerResidenceAddressOne',response.customerResidenceAddressOne);
  localStorage.setItem('CustomerPostageCodeTwo',response.customerPostageCodeTwo);
  localStorage.setItem('CustomerResidenceAddressTwo',response.customerResidenceAddressTwo);
  localStorage.setItem('CustomerLandlinePhoneNumberOne',response.customerLandlinePhoneNumberOne);
  localStorage.setItem('CustomerPhoneNumberOne',response.customerPhoneNumberOne);
  localStorage.setItem('CustomerWorkPhoneNumberOne',response.customerWorkPhoneNumberOne);
  localStorage.setItem('CustomerLandlinePhoneNumberTwo',response.customerLandlinePhoneNumberTwo);
  localStorage.setItem('CustomerPhoneNumberTwo',response.customerPhoneNumberTwo);
  localStorage.setItem('CustomerWorkPhoneNumberTwo',response.customerWorkPhoneNumberTwo);
  localStorage.setItem('CustomerEmailAddress',response.CustomerEmailAddress);
  localStorage.setItem('CustomerTypeOfMarriage',response.customerTypeOfMarriage);
  localStorage.setItem('CustomerCountry',response.customerCountry);
  localStorage.setItem('CustomerEvaluationFormTrackingNumber',response.customerEvaluationFormTrackingNumber);

  localStorage.setItem('customerNationalCardImageFront',response.customerNationalCardImageFront);
   

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentPersonalInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
  
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('CustomerPassportNumber',response.customerPassportNumber);
      localStorage.setItem('CustomerPassportFirstName',response.customerPassportFirstName);
      localStorage.setItem('CustomerPassportLastName',response.customerPassportLastName);
      localStorage.setItem('CustomerPassportCreationDate',response.customerPassportCreationDate);
      localStorage.setItem('CustomerPassportDateOfExpiry',response.customerPassportDateOfExpiry);
      localStorage.setItem('CustomerPassportCreationPlace',response.customerPassportCreationPlace);
      localStorage.setItem('CustomerCountryOfResidence',response.customerCountryOfResidence);
      localStorage.setItem('CustomerBirthdayOnThePassport',response.customerBirthdayOnThePassport);
      localStorage.setItem('CustomerNationalIdentityCard',response.customerNationalIdentityCard);
      localStorage.setItem('CustomerPlaceOfNationalIdentityCard',response.customerPlaceOfNationalIdentityCard);
      localStorage.setItem('CustomerBirthCertificateNumber',response.customerBirthCertificateNumber);
      localStorage.setItem('CustomerFathersNameOnTheBirthCertificate',response.customerFathersNameOnTheBirthCertificate);
      localStorage.setItem('CustomerMothersNameOnTheBirthCertificate',response.customerMothersNameOnTheBirthCertificate);
      localStorage.setItem('CustomerAliasNameChangedInTheIdentityCard',response.customerAliasNameChangedInTheIdentityCard);
      localStorage.setItem('CustomerPlaceOfBirth',response.customerPlaceOfBirth);
      localStorage.setItem('CustomerMilitaryCardNumber',response.customerMilitaryCardNumber);
      localStorage.setItem('CustomerDateOfIssuanceOfMilitaryCard',response.customerDateOfIssuanceOfMilitaryCard);
      localStorage.setItem('CustomerTypeOfMilitaryCard',response.customerTypeOfMilitaryCard);
      localStorage.setItem('CustomerPlaceOfMilitaryService',response.customerPlaceOfMilitaryService);
      localStorage.setItem('CustomerHeight',response.customerHeight);
      localStorage.setItem('CustomerSkinColor',response.customerSkinColor);
      localStorage.setItem('CustomerEyeColor',response.customerEyeColor);
      localStorage.setItem('CustomerPostageCodeOne',response.customerPostageCodeOne);
      localStorage.setItem('CustomerResidenceAddressOne',response.customerResidenceAddressOne);
      localStorage.setItem('CustomerPostageCodeTwo',response.customerPostageCodeTwo);
      localStorage.setItem('CustomerResidenceAddressTwo',response.customerResidenceAddressTwo);
      localStorage.setItem('CustomerLandlinePhoneNumberOne',response.customerLandlinePhoneNumberOne);
      localStorage.setItem('CustomerPhoneNumberOne',response.customerPhoneNumberOne);
      localStorage.setItem('CustomerWorkPhoneNumberOne',response.customerWorkPhoneNumberOne);
      localStorage.setItem('CustomerLandlinePhoneNumberTwo',response.customerLandlinePhoneNumberTwo);
      localStorage.setItem('CustomerPhoneNumberTwo',response.customerPhoneNumberTwo);
      localStorage.setItem('CustomerWorkPhoneNumberTwo',response.customerWorkPhoneNumberTwo);
      localStorage.setItem('CustomerEmailAddress',response.CustomerEmailAddress);
      localStorage.setItem('CustomerTypeOfMarriage',response.customerTypeOfMarriage);
      localStorage.setItem('CustomerCountry',response.customerCountry);
      localStorage.setItem('CustomerEvaluationFormTrackingNumber',response.customerEvaluationFormTrackingNumber);

      localStorage.setItem('customerNationalCardImageFront',response.customerNationalCardImageFront);
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('CustomerPassportNumber','');
      localStorage.setItem('CustomerPassportFirstName','');
      localStorage.setItem('CustomerPassportLastName','');
      localStorage.setItem('CustomerPassportCreationDate','');
      localStorage.setItem('CustomerPassportDateOfExpiry','');
      localStorage.setItem('CustomerPassportCreationPlace','');
      localStorage.setItem('CustomerCountryOfResidence','');
      localStorage.setItem('CustomerBirthdayOnThePassport','');
      localStorage.setItem('CustomerNationalIdentityCard','');
      localStorage.setItem('CustomerPlaceOfNationalIdentityCard','');
      localStorage.setItem('CustomerBirthCertificateNumber','');
      localStorage.setItem('CustomerFathersNameOnTheBirthCertificate','');
      localStorage.setItem('CustomerMothersNameOnTheBirthCertificate','');
      localStorage.setItem('CustomerAliasNameChangedInTheIdentityCard','');
      localStorage.setItem('CustomerPlaceOfBirth','');
      localStorage.setItem('CustomerMilitaryCardNumber','');
      localStorage.setItem('CustomerDateOfIssuanceOfMilitaryCard','');
      localStorage.setItem('CustomerTypeOfMilitaryCard','');
      localStorage.setItem('CustomerPlaceOfMilitaryService','');
      localStorage.setItem('CustomerHeight','');
      localStorage.setItem('CustomerSkinColor','');
      localStorage.setItem('CustomerEyeColor','');
      localStorage.setItem('CustomerPostageCodeOne','');
      localStorage.setItem('CustomerResidenceAddressOne','');
      localStorage.setItem('CustomerPostageCodeTwo','');
      localStorage.setItem('CustomerResidenceAddressTwo','');
      localStorage.setItem('CustomerLandlinePhoneNumberOne','');
      localStorage.setItem('CustomerPhoneNumberOne','');
      localStorage.setItem('CustomerWorkPhoneNumberOne','');
      localStorage.setItem('CustomerLandlinePhoneNumberTwo','');
      localStorage.setItem('CustomerPhoneNumberTwo','');
      localStorage.setItem('CustomerWorkPhoneNumberTwo','');
      localStorage.setItem('CustomerEmailAddress','');
      localStorage.setItem('CustomerTypeOfMarriage','');
      localStorage.setItem('CustomerCountry','');
      localStorage.setItem('CustomerEvaluationFormTrackingNumber','');

      localStorage.setItem('customerNationalCardImageFront');
   
    })
    })
  }
  else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpousePersonalInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  localStorage.setItem('CustomerSpousePassportNumber',response.customerSpousePassportNumber);
  localStorage.setItem('CustomerSpousePassportFirstName',response.customerSpousePassportFirstName);
  localStorage.setItem('CustomerSpousePassportLastName',response.customerSpousePassportLastName);
  localStorage.setItem('CustomerSpousePassportCreationDate',response.customerSpousePassportCreationDate);
  localStorage.setItem('CustomerSpousePassportDateOfExpiry',response.customerSpousePassportDateOfExpiry);
  localStorage.setItem('CustomerSpousePassportCreationPlace',response.customerSpousePassportCreationPlace);
  localStorage.setItem('CustomerSpouseCountryOfResidence',response.customerSpouseCountryOfResidence);
  localStorage.setItem('CustomerSpouseBirthdayOnThePassport',response.customerSpouseBirthdayOnThePassport);
  localStorage.setItem('CustomerSpouseNationalIdentityCard',response.customerSpouseNationalIdentityCard);
  localStorage.setItem('CustomerSpousePlaceOfNationalIdentityCard',response.customerSpousePlaceOfNationalIdentityCard);
  localStorage.setItem('CustomerSpouseBirthCertificateNumber',response.customerSpouseBirthCertificateNumber);
  localStorage.setItem('CustomerSpouseFathersNameOnTheBirthCertificate',response.customerSpouseFathersNameOnTheBirthCertificate);
  localStorage.setItem('CustomerSpouseMothersNameOnTheBirthCertificate',response.customerSpouseMothersNameOnTheBirthCertificate);
  localStorage.setItem('CustomerSpouseAliasNameChangedInTheIdentityCard',response.customerSpouseAliasNameChangedInTheIdentityCard);
  localStorage.setItem('CustomerSpousePlaceOfBirth',response.customerSpousePlaceOfBirth);
  localStorage.setItem('CustomerSpouseMilitaryCardNumber',response.customerSpouseMilitaryCardNumber);
  localStorage.setItem('CustomerSpouseDateOfIssuanceOfMilitaryCard',response.customerSpouseDateOfIssuanceOfMilitaryCard);
  localStorage.setItem('CustomerSpouseTypeOfMilitaryCard',response.customerSpouseTypeOfMilitaryCard);
  localStorage.setItem('CustomerSpousePlaceOfMilitaryService',response.customerSpousePlaceOfMilitaryService);
  localStorage.setItem('CustomerSpouseHeight',response.customerSpouseHeight);
  localStorage.setItem('CustomerSpouseSkinColor',response.customerSpouseSkinColor);
  localStorage.setItem('CustomerSpouseEyeColor',response.customerSpouseEyeColor);
  localStorage.setItem('CustomerSpousePostageCodeOne',response.customerSpousePostageCodeOne);
  localStorage.setItem('CustomerSpouseResidenceAddressOne',response.customerSpouseResidenceAddressOne);
  localStorage.setItem('CustomerSpousePostageCodeTwo',response.customerSpousePostageCodeTwo);
  localStorage.setItem('CustomerSpouseResidenceAddressTwo',response.customerSpouseResidenceAddressTwo);
  localStorage.setItem('CustomerSpouseLandlinePhoneNumberOne',response.customerSpouseLandlinePhoneNumberOne);
  localStorage.setItem('CustomerSpousePhoneNumberOne',response.customerSpousePhoneNumberOne);
  localStorage.setItem('CustomerSpouseWorkPhoneNumberOne',response.customerSpouseWorkPhoneNumberOne);
  localStorage.setItem('CustomerSpouseLandlinePhoneNumberTwo',response.customerSpouseLandlinePhoneNumberTwo);
  localStorage.setItem('CustomerSpousePhoneNumberTwo',response.customerSpousePhoneNumberTwo);
  localStorage.setItem('CustomerSpouseWorkPhoneNumberTwo',response.customerSpouseWorkPhoneNumberTwo);
  localStorage.setItem('CustomerSpouseEmailAddress',response.customerSpouseEmailAddress);
  localStorage.setItem('CustomerSpouseTypeOfMarriage',response.customerSpouseTypeOfMarriage);
  localStorage.setItem('CustomerSpouseCountry',response.customerSpouseCountry);
  localStorage.setItem('CustomerSpouseEvaluationFormTrackingNumber',response.customerSpouseEvaluationFormTrackingNumber);
   

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpousePersonalInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
  
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('CustomerSpousePassportNumber',response.customerSpousePassportNumber);
      localStorage.setItem('CustomerSpousePassportFirstName',response.customerSpousePassportFirstName);
      localStorage.setItem('CustomerSpousePassportLastName',response.customerSpousePassportLastName);
      localStorage.setItem('CustomerSpousePassportCreationDate',response.customerSpousePassportCreationDate);
      localStorage.setItem('CustomerSpousePassportDateOfExpiry',response.customerSpousePassportDateOfExpiry);
      localStorage.setItem('CustomerSpousePassportCreationPlace',response.customerSpousePassportCreationPlace);
      localStorage.setItem('CustomerSpouseCountryOfResidence',response.customerSpouseCountryOfResidence);
      localStorage.setItem('CustomerSpouseBirthdayOnThePassport',response.customerSpouseBirthdayOnThePassport);
      localStorage.setItem('CustomerSpouseNationalIdentityCard',response.customerSpouseNationalIdentityCard);
      localStorage.setItem('CustomerSpousePlaceOfNationalIdentityCard',response.customerSpousePlaceOfNationalIdentityCard);
      localStorage.setItem('CustomerSpouseBirthCertificateNumber',response.customerSpouseBirthCertificateNumber);
      localStorage.setItem('CustomerSpouseFathersNameOnTheBirthCertificate',response.customerSpouseFathersNameOnTheBirthCertificate);
      localStorage.setItem('CustomerSpouseMothersNameOnTheBirthCertificate',response.customerSpouseMothersNameOnTheBirthCertificate);
      localStorage.setItem('CustomerSpouseAliasNameChangedInTheIdentityCard',response.customerSpouseAliasNameChangedInTheIdentityCard);
      localStorage.setItem('CustomerSpousePlaceOfBirth',response.customerSpousePlaceOfBirth);
      localStorage.setItem('CustomerSpouseMilitaryCardNumber',response.customerSpouseMilitaryCardNumber);
      localStorage.setItem('CustomerSpouseDateOfIssuanceOfMilitaryCard',response.customerSpouseDateOfIssuanceOfMilitaryCard);
      localStorage.setItem('CustomerSpouseTypeOfMilitaryCard',response.customerSpouseTypeOfMilitaryCard);
      localStorage.setItem('CustomerSpousePlaceOfMilitaryService',response.customerSpousePlaceOfMilitaryService);
      localStorage.setItem('CustomerSpouseHeight',response.customerSpouseHeight);
      localStorage.setItem('CustomerSpouseSkinColor',response.customerSpouseSkinColor);
      localStorage.setItem('CustomerSpouseEyeColor',response.customerSpouseEyeColor);
      localStorage.setItem('CustomerSpousePostageCodeOne',response.customerSpousePostageCodeOne);
      localStorage.setItem('CustomerSpouseResidenceAddressOne',response.customerSpouseResidenceAddressOne);
      localStorage.setItem('CustomerSpousePostageCodeTwo',response.customerSpousePostageCodeTwo);
      localStorage.setItem('CustomerSpouseResidenceAddressTwo',response.customerSpouseResidenceAddressTwo);
      localStorage.setItem('CustomerSpouseLandlinePhoneNumberOne',response.customerSpouseLandlinePhoneNumberOne);
      localStorage.setItem('CustomerSpousePhoneNumberOne',response.customerSpousePhoneNumberOne);
      localStorage.setItem('CustomerSpouseWorkPhoneNumberOne',response.customerSpouseWorkPhoneNumberOne);
      localStorage.setItem('CustomerSpouseLandlinePhoneNumberTwo',response.customerSpouseLandlinePhoneNumberTwo);
      localStorage.setItem('CustomerSpousePhoneNumberTwo',response.customerSpousePhoneNumberTwo);
      localStorage.setItem('CustomerSpouseWorkPhoneNumberTwo',response.customerSpouseWorkPhoneNumberTwo);
      localStorage.setItem('CustomerSpouseEmailAddress',response.customerSpouseEmailAddress);
      localStorage.setItem('CustomerSpouseTypeOfMarriage',response.customerSpouseTypeOfMarriage);
      localStorage.setItem('CustomerSpouseCountry',response.customerSpouseCountry);
      localStorage.setItem('CustomerSpouseEvaluationFormTrackingNumber',response.customerSpouseEvaluationFormTrackingNumber);
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('CustomerSpousePassportNumber','');
      localStorage.setItem('CustomerSpousePassportFirstName','');
      localStorage.setItem('CustomerSpousePassportLastName','');
      localStorage.setItem('CustomerSpousePassportCreationDate','');
      localStorage.setItem('CustomerSpousePassportDateOfExpiry','');
      localStorage.setItem('CustomerSpousePassportCreationPlace','');
      localStorage.setItem('CustomerSpouseCountryOfResidence','');
      localStorage.setItem('CustomerSpouseBirthdayOnThePassport','');
      localStorage.setItem('CustomerSpouseNationalIdentityCard','');
      localStorage.setItem('CustomerSpousePlaceOfNationalIdentityCard','');
      localStorage.setItem('CustomerSpouseBirthCertificateNumber','');
      localStorage.setItem('CustomerSpouseFathersNameOnTheBirthCertificate','');
      localStorage.setItem('CustomerSpouseMothersNameOnTheBirthCertificate','');
      localStorage.setItem('CustomerSpouseAliasNameChangedInTheIdentityCard','');
      localStorage.setItem('CustomerSpousePlaceOfBirth','');
      localStorage.setItem('CustomerSpouseMilitaryCardNumber','');
      localStorage.setItem('CustomerSpouseDateOfIssuanceOfMilitaryCard','');
      localStorage.setItem('CustomerSpouseTypeOfMilitaryCard','');
      localStorage.setItem('CustomerSpousePlaceOfMilitaryService','');
      localStorage.setItem('CustomerSpouseHeight','');
      localStorage.setItem('CustomerSpouseSkinColor','');
      localStorage.setItem('CustomerSpouseEyeColor','');
      localStorage.setItem('CustomerSpousePostageCodeOne','');
      localStorage.setItem('CustomerSpouseResidenceAddressOne','');
      localStorage.setItem('CustomerSpousePostageCodeTwo','');
      localStorage.setItem('CustomerSpouseResidenceAddressTwo','');
      localStorage.setItem('CustomerSpouseLandlinePhoneNumberOne','');
      localStorage.setItem('CustomerSpousePhoneNumberOne','');
      localStorage.setItem('CustomerSpouseWorkPhoneNumberOne','');
      localStorage.setItem('CustomerSpouseLandlinePhoneNumberTwo','');
      localStorage.setItem('CustomerSpousePhoneNumberTwo','');
      localStorage.setItem('CustomerSpouseWorkPhoneNumberTwo','');
      localStorage.setItem('CustomerSpouseEmailAddress','');
      localStorage.setItem('CustomerSpouseTypeOfMarriage','');
      localStorage.setItem('CustomerSpouseCountry','');
      localStorage.setItem('CustomerSpouseEvaluationFormTrackingNumber','');
   
    })
    })
  }
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }
      if(localStorage.getItem('whoIs')=='sp'){
      values.PassportNumber =localStorage.getItem('CustomerSpousePassportNumber');
      values.Name =localStorage.getItem('CustomerSpousePassportFirstName');
      values.Family =localStorage.getItem('CustomerSpousePassportLastName');
      values.DateofCreation =localStorage.getItem('CustomerSpousePassportCreationDate');
      values.ExpireDate =localStorage.getItem('CustomerSpousePassportDateOfExpiry');
      values.PlaceOfCreation =localStorage.getItem('CustomerSpousePassportCreationPlace');
      values.CountryOFPass =localStorage.getItem('CustomerSpouseCountryOfResidence');
      values.BirthDayOnPass =localStorage.getItem('CustomerSpouseBirthdayOnThePassport');
      values.IDNumber =localStorage.getItem('CustomerSpouseNationalIdentityCard');
      values.PlaceIssuanceNationalCard =localStorage.getItem('CustomerSpousePlaceOfNationalIdentityCard');
      values.BirthCertificateNumber =localStorage.getItem('CustomerSpouseBirthCertificateNumber');
      values.FatherBirthCertificateNumber =localStorage.getItem('CustomerSpouseFathersNameOnTheBirthCertificate');
      values.MothersBirthCertificateNumber =localStorage.getItem('CustomerSpouseMothersNameOnTheBirthCertificate');
      values.AliasName =localStorage.getItem('CustomerSpouseAliasNameChangedInTheIdentityCard');
      values.placeofBirth =localStorage.getItem('CustomerSpousePlaceOfBirth');
      values.MilitaryCardNumber =localStorage.getItem('CustomerSpouseMilitaryCardNumber');
      values.DateIssuanceMilitaryCardvalue =localStorage.getItem('CustomerSpouseDateOfIssuanceOfMilitaryCard');
      values.TypeMilitaryCard =localStorage.getItem('CustomerSpouseTypeOfMilitaryCard');
      values.PlaceMilitaryService =localStorage.getItem('CustomerSpousePlaceOfMilitaryService');
      values.Height =localStorage.getItem('CustomerSpouseHeight');
      values.SkinColor =localStorage.getItem('CustomerSpouseSkinColor');
      values.EyeColor =localStorage.getItem('CustomerSpouseEyeColor');
      values.ZipCode =localStorage.getItem('CustomerSpousePostageCodeOne');
      values.Address =localStorage.getItem('CustomerSpouseResidenceAddressOne');
      values.ZipCode2 =localStorage.getItem('CustomerSpousePostageCodeTwo');
      values.Address2 =localStorage.getItem('CustomerSpouseResidenceAddressTwo');
      values.proCallNumber =localStorage.getItem('CustomerSpouseLandlinePhoneNumberOne');
      values.proPhone =localStorage.getItem('CustomerSpousePhoneNumberOne');
      values.WorkplaceCallNumber =localStorage.getItem('CustomerSpouseWorkPhoneNumberOne');
      values.proCallNumber2 =localStorage.getItem('CustomerSpouseLandlinePhoneNumberTwo');
      values.proPhone2 =localStorage.getItem('CustomerSpousePhoneNumberTwo');
      values.WorkplaceCallNumber2 =localStorage.getItem('CustomerSpouseWorkPhoneNumberTwo');
      values.proEmail =localStorage.getItem('CustomerSpouseEmailAddress');
      values.TypeMarriage =localStorage.getItem('CustomerSpouseTypeOfMarriage');
      values.Country =localStorage.getItem('CustomerSpouseCountry');
      
      setDateofCreationValue(localStorage.getItem('CustomerSpousePassportCreationDate'))
      setDateofExpireValue(localStorage.getItem('CustomerSpousePassportDateOfExpiry'))
      setBirthDayValue(localStorage.getItem('CustomerSpouseBirthdayOnThePassport'))
      
      setDateIssuanceMilitaryCardValue(localStorage.getItem('CustomerSpouseDateOfIssuanceOfMilitaryCard'))
    }
    else if(localStorage.getItem('whoIs')=='ap'){
      values.PassportNumber =localStorage.getItem('CustomerPassportNumber');
      values.Name =localStorage.getItem('CustomerPassportFirstName');
      values.Family =localStorage.getItem('CustomerPassportLastName');
      values.DateofCreation =localStorage.getItem('CustomerPassportCreationDate');
      values.ExpireDate =localStorage.getItem('CustomerPassportDateOfExpiry');
      values.PlaceOfCreation =localStorage.getItem('CustomerPassportCreationPlace');
      values.CountryOFPass =localStorage.getItem('CustomerCountryOfResidence');
      values.BirthDayOnPass =localStorage.getItem('CustomerBirthdayOnThePassport');
      values.IDNumber =localStorage.getItem('CustomerNationalIdentityCard');
      values.PlaceIssuanceNationalCard =localStorage.getItem('CustomerPlaceOfNationalIdentityCard');
      values.BirthCertificateNumber =localStorage.getItem('CustomerBirthCertificateNumber');
      values.FatherBirthCertificateNumber =localStorage.getItem('CustomerFathersNameOnTheBirthCertificate');
      values.MothersBirthCertificateNumber =localStorage.getItem('CustomerMothersNameOnTheBirthCertificate');
      values.AliasName =localStorage.getItem('CustomerAliasNameChangedInTheIdentityCard');
      values.placeofBirth =localStorage.getItem('CustomerPlaceOfBirth');
      values.MilitaryCardNumber =localStorage.getItem('CustomerMilitaryCardNumber');
      values.DateIssuanceMilitaryCardvalue =localStorage.getItem('CustomerDateOfIssuanceOfMilitaryCard');
      values.TypeMilitaryCard =localStorage.getItem('CustomerTypeOfMilitaryCard');
      values.PlaceMilitaryService =localStorage.getItem('CustomerPlaceOfMilitaryService');
      values.Height =localStorage.getItem('CustomerHeight');
      values.SkinColor =localStorage.getItem('CustomerSkinColor');
      values.EyeColor =localStorage.getItem('CustomerEyeColor');
      values.ZipCode =localStorage.getItem('CustomerPostageCodeOne');
      values.Address =localStorage.getItem('CustomerResidenceAddressOne');
      values.ZipCode2 =localStorage.getItem('CustomerPostageCodeTwo');
      values.Address2 =localStorage.getItem('CustomerResidenceAddressTwo');
      values.proCallNumber =localStorage.getItem('CustomerLandlinePhoneNumberOne');
      values.proPhone =localStorage.getItem('CustomerPhoneNumberOne');
      values.WorkplaceCallNumber =localStorage.getItem('CustomerWorkPhoneNumberOne');
      values.proCallNumber2 =localStorage.getItem('CustomerLandlinePhoneNumberTwo');
      values.proPhone2 =localStorage.getItem('CustomerPhoneNumberTwo');
      values.WorkplaceCallNumber2 =localStorage.getItem('CustomerWorkPhoneNumberTwo');
      values.proEmail =localStorage.getItem('CustomerEmailAddress');
      values.TypeMarriage =localStorage.getItem('CustomerTypeOfMarriage');
      values.Country =localStorage.getItem('CustomerCountry');
      values.CustomerEvaluationFormTrackingNumber = localStorage.getItem('CustomerEvaluationFormTrackingNumber');

      setDateofCreationValue(localStorage.getItem('CustomerPassportCreationDate'))
      setDateofExpireValue(localStorage.getItem('CustomerPassportDateOfExpiry'))
      setBirthDayValue(localStorage.getItem('CustomerBirthdayOnThePassport'))
      
      setDateIssuanceMilitaryCardValue(localStorage.getItem('CustomerDateOfIssuanceOfMilitaryCard'))

      setImage(localStorage.getItem('customerNationalCardImageFront'));
      if(image!='')
      {
        document.getElementById('target').style.display ="block";
        document.getElementById('MyGrayBox').style.display= "none";
        forceUpdate();
      }
    }
        return () => clearTimeout(timer);
        
      }
    }, [state]);

//********************************************************** */
//********************************************************** */



const [image , setImage] = useState(null);
const [selectedFile,setSelectedFile] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              localStorage.setItem('PDFUpload1',event.target.files[0]);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
              //*************************upload with API */
              
              
              console.log(event.target.files[0]);
              setSelectedFile(event.target.files[0]);
              const formData = new FormData();
              formData.append('CustomerNationalCardImageFront',event.target.files[0]);
              formData.append('CustomerCode',localStorage.getItem('customerCode'));
              const headers = { 
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar'
              };
              
              if(localStorage.getItem('userType')==3)
              if(event.target.files[0].size > 4000000){
                // document.getElementById('files2').innerHTML =  '<b>' + 'File Must be Lower than 4MB'+ '</b> ';
                values.files2 = 1;
                forceUpdate();
              }else
                axios.post('https://telesiness.ir/api/CustomerDocumentsAttachment/UploadNationalCardImageFront',formData,{
                  onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
                    
                     localStorage.setItem('userAvatar',res.data.attachmentFile);
                     console.log(res.data.attachmentFile,res.data,res);
              
                     if(localStorage.getItem('userType')==3)
                     {
                       var name = event.target.files[0]; 
                       var totalFileSize = 0;
                     
                         //ACCESS THE SIZE PROPERTY OF THE ITEM OBJECT IN FILES COLLECTION. IN THIS WAY ALSO GET OTHER PROPERTIES LIKE FILENAME AND FILETYPE
                        //  var fsize = name.size;
                        //  totalFileSize = totalFileSize + fsize;
                        //  document.getElementById('files').innerHTML =
                        //  document.getElementById('files').innerHTML
                        //  +
                        //  '<b>' + name.name
                        //  +
                        //   '</b> ' + 'and Size is <b>' + Math.round((fsize / 1024)) //DEFAULT SIZE IS IN BYTES SO WE DIVIDING BY 1024 TO CONVERT IT IN KB
                        //   +
                        //   '</b> KB and File Type is <b>' + name.type + "</b>.";
                        //   values.files2 = 0;
                     
                     }
                    forceUpdate();
                  })
                 
              //****************************************** */
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }
        
        const handleExportImage1 = () =>{
          var urlPDF = 'https://telesiness.ir/CusomerDocuments/PersonalInformationAttachment/UploadNationalCardImageFront/'+image;
          window.open(urlPDF);
        }

        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


              const [image13 , setImage13] = useState(null);
              const   onImage13Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader13 = new FileReader();
                    reader13.onload = (e) => {
                      setImage13(e.target.result);
                      document.getElementById('target13').style.display ="block";
                      document.getElementById('MyGrayBox13').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader13.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage13 = () =>{
                  document.getElementById('target13').style.display ="none";
                  document.getElementById('MyGrayBox13').style.display= "flex";
                }

        const [DateofCreationvalue, setDateofCreationValue] = useState(localStorage.getItem('CustomerPassportCreationDate'))
        const [DateofExpirevalue, setDateofExpireValue] = useState(localStorage.getItem('CustomerPassportDateOfExpiry'))
        const [BirthDayvalue, setBirthDayValue] = useState(localStorage.getItem('CustomerBirthdayOnThePassport'))
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState(localStorage.getItem('CustomerDateOfIssuanceOfMilitaryCard'))

        const handleScrollUp = () =>{
          window.scroll({ behavior: 'smooth' }, 0)
         }


       
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal} className="">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 1')} :</p><p className="Cus2Step1BlackP">{t('Complete Basic Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo"> <p>{t('Personal Information')}</p> </div>
          <div className="Cus2Inputs">
          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PassportNumber" 
                value={values.PassportNumber}
                type="text"
                placeholder={t('Passport Number')}
                onChange={handleChange('PassportNumber')}
                />
                <label htmlFor="PassportNumber">{t('Enter the applicant’s Passport Number')}</label>
            </div>
            
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Name" 
                value={values.Name}
                type="text"
                placeholder={t('Name')}
                onChange={handleChange('Name')}
                />
                <label htmlFor="Name">{t('Enter the applicant’s Name in the Passport')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Family" 
                value={values.Family}
                type="text"
                placeholder={t('Family')}
                onChange={handleChange('Family')}
                />
                <label htmlFor="Family">{t('Enter the applicant’s Family in the Passport')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofCreationvalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Creation Date')}
              id="DateofCreation"
              onChange={setDateofCreationValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Passport Creation Date')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
            <div className="inputAndlabel col-lg-4 col-12">
            { <DatePicker 
              value={DateofExpirevalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Expiration Date')}
              id="ExpireDate"
              onChange={setDateofExpireValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Passport Expiration Date')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PlaceOfCreation" 
                value={values.PlaceOfCreation}
                type="text"
                placeholder={t('Passport Creation Place')}
                onChange={handleChange('PlaceOfCreation')}
                />
                <label htmlFor="PlaceOfCreation">{t('Enter the applicant’s Passport Creation Place')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="CountryOFPass"
                  value={values.CountryOFPass}
                  label={t('CountryOFPass')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('CountryOFPass')}  
                  placeholder={t('Country of Residence')}
                  >
                    <option  value="">
                    {t('Country of Residence')}
                  </option >
                  {dataC &&
                    dataC.map((element) => (
                      <option  value={element.countery_ID}>{element.countery_Name}</option >
                  ))}
                  {/* <option  value="">
                  </option >
                  <option  value='1'>{t('Iran')}</option >
                  <option  value='2'>{t('England')}</option >
                  <option  value='3'>{t('Turkey')}</option >
                  <option  value='4'>{t('UAE')}</option >
                  <option  value='5'>{t('USA')}</option > */}
                </select >
                <label htmlFor="CountryOFPass">{t('Country of residence stated in the passport')}</label>
            </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="CountryOFPass" 
                value={values.CountryOFPass}
                type="text"
                placeholder={t('Country of Residence')}
                onChange={handleChange('CountryOFPass')}
                />
                <label htmlFor="CountryOFPass">{t('Country of residence stated in the passport')}</label>
            </div> */}


            <div className="inputAndlabel col-lg-4 col-12">
            { <DatePicker 
              value={BirthDayvalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of birth')}
              id="BirthDayOnPass"
              onChange={setBirthDayValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Date of birth On Passport')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proFirstName" 
                value={values.proFirstName}
                type="text"
                placeholder={t('First Name')}
                onChange={handleChange('proFirstName')}
                />
                <label htmlFor="proFirstName">{t('Enter the applicant’s First Name in Persian')}</label>
            </div>
            
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proLastName" 
                value={values.proLastName}
                type="text"
                placeholder={t('Last Name')}
                onChange={handleChange('proLastName')}
                />
                <label htmlFor="proLastName">{t('Enter the applicant’s Last Name in Persian')}</label>
            </div>

            <div className="inputAndlabelRadio col-4">
              <p>{t('Gender')}</p>
              <input type="radio" id="mail" name="fav_language" value={'male'} onChange={handleChange('Gender')}/>
              <label htmlFor="mail">{t('Male')}</label>
              <input type="radio" id="female" name="fav_language" value={'female'} onChange={handleChange('Gender')}/>
              <label htmlFor="female">{t('Female')}</label>
            </div> */}
            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="IDNumber2" 
                value={values.IDNumber}
                type="text"
                placeholder={t('ID Number')}
                onChange={handleChange('IDNumber')}
                pattern="[1-9]{1}[0-9]{9}"
                title="Please Enter 10 digit proper Iran National Country code!"
                />
                <label htmlFor="IDNumber2">{t('Enter the applicant’s ID Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PlaceIssuanceNationalCard" 
                value={values.PlaceIssuanceNationalCard}
                type="text"
                placeholder={t('Place of Issuance of National Card')}
                onChange={handleChange('PlaceIssuanceNationalCard')}
                />
                <label htmlFor="PlaceIssuanceNationalCard">{t('Enter the applicant’s Place of Issuance of National Card')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="BirthCertificateNumber" 
                value={values.BirthCertificateNumber}
                type="text"
                placeholder={t('Birth Certificate Number')}
                onChange={handleChange('BirthCertificateNumber')}
                />
                <label htmlFor="BirthCertificateNumber">{t('Enter the applicant’s Birth Certificate Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherBirthCertificateNumber" 
                value={values.FatherBirthCertificateNumber}
                type="text"
                placeholder={t('Father’s Name on the Birth Certificate')}
                onChange={handleChange('FatherBirthCertificateNumber')}
                />
                <label htmlFor="FatherBirthCertificateNumber">{t('Enter the applicant’s Fathers Name on the Birth Certificate')}</label>
            </div> <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersBirthCertificateNumber" 
                value={values.MothersBirthCertificateNumber}
                type="text"
                placeholder={t('Mother’s Name on the Birth Certificate')}
                onChange={handleChange('MothersBirthCertificateNumber')}
                />
                <label htmlFor="MothersBirthCertificateNumber">{t('Enter the applicant’s Mothers Name on the Birth Certificate')}</label>
            </div> <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="AliasName" 
                value={values.AliasName}
                type="text"
                placeholder={t('Alias Name or Surname or changed in the identity card')}
                onChange={handleChange('AliasName')}
                />
                <label htmlFor="AliasName">{t('Applicant’s Alias Name,Surname or changed in the identity card')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="placeofBirth" 
                value={values.placeofBirth}
                type="text"
                placeholder={t('place of Birth')}
                onChange={handleChange('placeofBirth')}
                
                />
                <label htmlFor="placeofBirth">{t('Enter the applicant’s place of birth')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
            <br />
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MilitaryCardNumber" 
                value={values.MilitaryCardNumber}
                type="text"
                placeholder={t('Military Card Number')}
                onChange={handleChange('MilitaryCardNumber')}
                
                />
                <label htmlFor="MilitaryCardNumber">{t('Enter the applicant’s Military Card Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateIssuanceMilitaryCardvalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Issuance of Military Card')}
              id="DateIssuanceMilitaryCard"
              onChange={setDateIssuanceMilitaryCardValue} 
              className="col-12"
            />}
              <label htmlFor="DateIssuanceMilitaryCard">{t('Enter the applicant’s Passport Creation Date')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateIssuanceMilitaryCard" 
                value={values.DateIssuanceMilitaryCard}
                type="text"
                placeholder={t('Date of Issuance of Military Card')}
                onChange={handleChange('DateIssuanceMilitaryCard')}
                
                />
                <label htmlFor="DateIssuanceMilitaryCard">{t('Enter the applicant’s Date of Issuance of Military Card')}</label>
            </div> */}
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeMilitaryCard" 
                value={values.TypeMilitaryCard}
                type="text"
                placeholder={t('Type of Military Card')}
                onChange={handleChange('TypeMilitaryCard')}
                
                />
                <label htmlFor="TypeMilitaryCard">{t('Enter the applicant’s Type of Military Card')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PlaceMilitaryService" 
                value={values.PlaceMilitaryService}
                type="text"
                placeholder={t('Place of Military Service')}
                onChange={handleChange('PlaceMilitaryService')}
                
                />
                <label htmlFor="PlaceMilitaryService">{t('Enter the applicant’s Place of Military Service')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Height" 
                value={values.Height}
                type="text"
                placeholder={t('Height')}
                onChange={handleChange('Height')}
                
                />
                <label htmlFor="Height">{t('Enter the applicant’s Height')}</label>
            </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="SkinColor" 
                value={values.SkinColor}
                type="text"
                placeholder={t('Skin Color')}
                onChange={handleChange('SkinColor')}
                
                />
                <label htmlFor="SkinColor">{t('Enter the applicant’s Skin Color')}</label>
            </div> */}
            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SkinColor"
                  value={values.SkinColor}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SkinColor')}  
                  placeholder={t('Skin Color')}
                  >
                    <option  value="">
                    {t('Skin Color')}
                  </option >
                  <option  value='1'>{t('Yellow')}</option >
                  <option  value='2'>{t('White')}</option >
                  <option  value='3'>{t('Red')}</option >
                  <option  value='4'>{t('Brunette')}</option >
                  <option  value='5'>{t('Tan')}</option > 
                  <option  value='6'>{t('Black')}</option > 
                </select >
                <label htmlFor="SkinColor">{t('Enter the applicant’s Skin Color')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EyeColor"
                  value={values.EyeColor}
                  label={t('Eye Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EyeColor')}  
                  placeholder={t('Eye Color')}
                  >
                    <option  value="">
                    {t('Eye Color')}
                  </option >
                  <option  value='1'>{t('Green')}</option >
                  <option  value='2'>{t('Blue')}</option >
                  <option  value='3'>{t('Brown')}</option >
                  <option  value='4'>{t('Amber')}</option >
                  <option  value='5'>{t('Honey-Colored')}</option >
                  <option  value='6'>{t('Black')}</option > 
                  <option  value='7'>{t('Gray')}</option > 
                  <option  value='8'>{t('Red')}</option > 
                </select >
                <label htmlFor="EyeColor">{t('Enter the applicant’s Eye Color')}</label>
            </div>
                    <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ZipCode" 
                value={values.ZipCode}
                type="text"
                placeholder={t('Postal Code One')}
                onChange={handleChange('ZipCode')}
                
                />
                <label htmlFor="ZipCode">{t('Enter the applicant’s Postal Code (Place One - Required)')}</label>
            </div>
            <div className="inputAndlabel col-8">
              <input
                id="Address" 
                value={values.Address}
                type="text"
                placeholder={t('Address One')}
                onChange={handleChange('Address')}
                
                />
                <label htmlFor="Address">{t('Enter the applicant’s address (Place One - Required)')}</label>
            </div>
                    <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ZipCode2" 
                value={values.ZipCode2}
                type="text"
                placeholder={t('Postal Code Two')}
                onChange={handleChange('ZipCode2')}
                
                />
                <label htmlFor="ZipCode2">{t('Enter the applicant’s Postal Code (Place Two)')}</label>
            </div>
            <div className="inputAndlabel col-8">
              <input
                id="Address2" 
                value={values.Address2}
                type="text"
                placeholder={t('Address Two')}
                onChange={handleChange('Address2')}
                
                />
                <label htmlFor="Address2">{t('Enter the applicant’s address (Place Two)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proCallNumber" 
                value={values.proCallNumber}
                type="text"
                placeholder={t('Call Number')}
                onChange={handleChange('proCallNumber')}
                
                />
                <label htmlFor="proCallNumber">{t('Enter the applicant’s Call number')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proPhone" 
                value={values.proPhone}
                type="text"
                placeholder={t('Phone Number')}
                onChange={handleChange('proPhone')}
                
                />
                <label htmlFor="proPhone">{t('Enter the applicant’s Phone number')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="WorkplaceCallNumber" 
                value={values.WorkplaceCallNumber}
                type="text"
                placeholder={t('Workplace Call Number')}
                onChange={handleChange('WorkplaceCallNumber')}
                
                />
                <label htmlFor="WorkplaceCallNumber">{t('Enter the applicant’s Workplace Call Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proCallNumber2" 
                value={values.proCallNumber2}
                type="text"
                placeholder={t('Call Number (Two)')}
                onChange={handleChange('proCallNumber2')}
                
                />
                <label htmlFor="proCallNumber2">{t('Enter the applicant’s Call number (Second Number Optional)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proPhone2" 
                value={values.proPhone2}
                type="text"
                placeholder={t('Phone Number (Two)')}
                onChange={handleChange('proPhone2')}
                
                />
                <label htmlFor="proPhone2">{t('Enter the applicant’s Phone number (Second Number Optional)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="WorkplaceCallNumber2" 
                value={values.WorkplaceCallNumber2}
                type="text"
                placeholder={t('Workplace Call Number (Two)')}
                onChange={handleChange('WorkplaceCallNumber2')}
                />
                <label htmlFor="WorkplaceCallNumber2">{t('Enter the applicant’s Workplace Call Number (Optional)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="proEmail" 
                value={values.proEmail}
                type="text"
                placeholder={t('E-mail')}
                onChange={handleChange('proEmail')}
                
                />
                <label htmlFor="proEmail">{t('Enter the applicant’s Email address')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="TypeMarriage"
                  value={values.TypeMarriage}
                  label={t('Type of Marriage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('TypeMarriage')}  
                  placeholder={t('Type of Marriage')}
                  >
                    <option  value="">
                    {t('Type of Marriage')}
                  </option >
                  <option  value='1'>{t('Married')}</option >
                  <option  value='2'>{t('Single')}</option >

                </select >
                <label htmlFor="TypeMarriage">{t('Enter the applicant’s Type of Marriage')}</label>
            </div>
            

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="Country"
                  value={values.Country}
                  label={t('Country')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('Country')}  
                  placeholder={t('Select the applicant’s Country')}
                  >
                    <option  value="">
                    {t('Select the applicant’s Country')}
                  </option >
                  {dataC &&
                    dataC.map((element) => (
                      <option  value={element.countery_ID}>{element.countery_Name}</option >
                  ))}
                  {/* <option  value="">
                  </option >
                  <option  value='1'>{t('Iran')}</option >
                  <option  value='2'>{t('England')}</option >
                  <option  value='3'>{t('Turkey')}</option >
                  <option  value='4'>{t('UAE')}</option >
                  <option  value='5'>{t('USA')}</option > */}
                </select >
                <label htmlFor="Country">{t('Enter the applicant’s Country')}</label>
            </div>
           
            {/* <div className="inputAndlabel col-8">
              <input
                id="Address" 
                value={values.Address}
                type="text"
                placeholder={t('Address')}
                onChange={handleChange('Address')}
                
                />
                <label htmlFor="Address">{t('Enter the applicant’s address')}</label>
            </div> */}

            {localStorage.getItem('whoIs')=='ap' && <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FormTrackingNumber" 
                value={values.FormTrackingNumber}
                type="text"
                placeholder={t('Evaluation Form Tracking Number')}
                onChange={handleChange('FormTrackingNumber')}
                
                />
                <label htmlFor="FormTrackingNumber">{t('Enter the applicant’s Evaluation Form Tracking Number')}</label>
            </div>}

            

            {/* <div className="inputAndlabel col-lg-4 col-12">
            
              { (document.body.getAttribute('Lang')== 'fa') && <DatePicker 
                
                value={Dvalue}
                format="YYYY/MM/DD "
                calendar="persian"
                calendar={persian}
                locale={persian_fa}
                id="birthDate"
                onChange={setDValue} 
                
              
              />}
              { (document.body.getAttribute('Lang')== 'en') && <DatePicker 
                
                value={Dvalue}
                format="YYYY/MM/DD "
                calendar="persian"
                calendar={gregorian}
                locale={gregorian_en}
                id="birthDate"
                onChange={setDValue} 
                
              
              />}
             
                <label htmlFor="birthDate">{t('Enter the applicant’s date of birth')}</label>
            </div> */}


           


          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Identical Documents')}</p> 
          
          </div>
          
   <div className="MyFileUploadRow">
        <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox">
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept=".pdf"
                 />
                 <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
              {/* <img id="target" src={image} className="MyCusImageUploadPreview"/> */}
              <embed id="target" src={'https://telesiness.ir/CusomerDocuments/PersonalInformationAttachment/UploadNationalCardImageFront/'+image} className="MyCusPDFUploadPreview"/>
              <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               {/* <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/> */}
               <ExportSquare size="24" color="#E60000" onClick={handleExportImage1}/>
              </div>
            </div>
            <p>{t('National Card Image(Front)')}</p> 
          </div>
        </div>


        <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox2">
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept=".pdf"
                 />
                 <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
              <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
              <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('National Card Image(Behind)')}</p> 
          </div>
        </div>


        <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Identity card Image(First Page)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Identity card Image(Second Page)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Identity card Image(Last Page)')}</p> 
          </div>
          </div>

          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox6">
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage6Change} 
                 id="group_image"
                 accept=".pdf"
                 />
                 <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Passport Image')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox7">
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage7Change} 
                 id="group_image"
                 accept=".pdf"
                 />
                 <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Personal Picture in Pass')}</p> 
            </div>
            </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox8">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage8Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Sample Passport Signature')}</p> 
          </div>
          </div>

          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox9">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage9Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Military service card')}</p> 
          </div>
          </div>

          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox10">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage10Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Lack of background')}</p> 
          </div>
          </div>

         <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox11">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage11Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Certificate or any other ID Card')}</p> 
          </div>
          </div>
          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox12">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage12Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Evaluation Form')}</p> 
          </div>
          </div>
          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
          <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox13">
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage13Change} 
                id="group_image"
                accept=".pdf"
                />
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Documents')}</p> 
          </div>
          </div>

   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
              <button id="ScrollUp" onClick={handleScrollUp} className="ScrollUp d-lg-none"><ArrowUp2 size="20" color="#313131" variant="Outline"/></button>
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>
       
       {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
          <div className="col-6">
             <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
             <button id="ScrollUp" onClick={handleScrollUp} className="d-lg-none"><ArrowUp2 size="20" color="#313131"/></button>
          </div>
          <div className="botCusDraftCol col-6">
            
            {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
          </div>
         
        </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;