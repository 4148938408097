import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LoginLocale from './Login.local';

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      ...LoginLocale // merge Home locale to i18n resource
      // add other translation to the array
      
    },
    Login: {
      'Sign In to Telesiness': 'Sign In to Telesiness',
      'Email':'Email',
      'Remember me':'Remember me',
      'Forgot Password':'Forgot Password',
      'Login':'Login',
      'or':'or',
      'Sign Up Now':'Sign Up Now',
      'Services':'Services',
      'About':'About',
      'About us':'About us',
      'Collaborations':'Collaborations',
      'Terms':'Terms',
      'Help':'Help',
      'Contact Us':'Contact Us',
      'Reset Your Password': 'Reset Your Password',
      'Send Link':'Send Link',
      '< Back to Home page':'< Back to Home page',
      'Submit':'Submit',
      'Update':'Update',
      'Changes were registered ':'Changes were registered ',
      'Login now':'Login now',
      'New Password':'New Password',
      'Confirm New Password':'Confirm New Password',
      'Sign Up To Telesiness':'Sign Up To Telesiness',
      'Step 1':'Step 1',
      'Step 2':'Step 2',
      'Step 3':'Step 3',
      'Step 4':'Step 4',
      'Step 5':'Step 5',
      'Choose Your Account Type':'Choose Your Account Type',
      'Complete your Personal information':'Complete your Personal information',
      'Complete your Educational information':'Complete your Educational information',
      'Complete your Job information':'Complete your Job information',
      'Choose Your Password':'Choose Your Password',
      '1 of 5':'1 of 5',
      '2 of 5':'2 of 5',
      '3 of 5':'3 of 5',
      '4 of 5':'4 of 5',
      '5 of 5':'5 of 5',
      'Lawyer':'Lawyer',
      'Agent':'Agent',
      'Customer':'Customer',
      'First Name':'First Name',
      'Last Name':'Last Name',
      'Phone Number':'Phone Number',
      'Date of Birth:':'Date of Birth:',
      'about':'about',
      'Day':'Day',
      'Month':'Month',
      'Year':'Year',
      'Next Step':'Next Step',
      'Back':'Back',
      'January':'January',
      'February':'February',
      'March':'March',
      'April':'April',
      'May':'May',
      'June':'June',
      'July':'July',
      'August':'August',
      'September':'September',
      'October':'October',
      'November':'November',
      'December':'December',
      'Field of Study':'Field of Study',
      'Institute / University':'Institute / University',
      'Level / Degree of education':'Level / Degree of education',
      'Less than high school diploma':'Less than high school diploma',
      'High school diploma':'High school diploma',
      'Diploma':'Diploma',
      'Pre-university':'Pre-university',
      'Some college, no degree':'Some college, no degree',
      'Associate degree':'Associate degree',
      'Bachelor degree':'Bachelor degree',
      'Master degree':'Master degree',
      'Professional  degree':'Professional  degree',
      'Doctoral degree':'Doctoral degree',
      'Country':'Country',
      'City':'City',
      'Iran':'Iran',
      'England':'England',
      'Turkey':'Turkey',
      'UAE':'UAE',
      'USA':'USA',
      'Canada':'Canada',
      'Paris':'Paris',
      'Tehran':'Tehran',
      'California':'California',
      'Toronto':'Toronto',
      'Istanbul':'Istanbul',
      'Job Specialty':'Job Specialty',
      'Specialization Field in Immigration Issues':'Specialization Field in Immigration Issues',
      'Law society number/ICCRC Code':'Law society number/ICCRC Code',
      'Personal or company website':'Personal or company website',
      'Certificate':'Certificate',
      'Upload your Resume/CV':'Upload your Resume/CV',
      'Welcome':'Welcome',
      'Setin Set':'Setin Set',
      'Home':'Home',
      'Customers':'Customers',
      'Agents':'Agents',
      'My Prices':'My Prices',
      'Messages':'Messages',
      'Search here':'Search here',
      'Gender':'Gender',
      'Date of birth':'Date of birth',
      'Phone':'Phone',
      'Address Information':'Address Information',
      'Save':'Save',
      'Cancel':'Cancel',
      'No':'No',
      'Address':'Address',
      'Postal Code':'Postal Code',
      'Educational Information':'Educational Information',
      'Level / Degree':'Level / Degree',
      'Job Information':'Job Information',
      'Upload Resume/CV':'Upload Resume/CV',
      'Website Address':'Website Address',
      'Account Information':'Account Information',
      'Username':'Username',
      'Change Password':'Change Password',
      'You changed these fields before ... ':'You changed these fields before ... ',
      'Search here':'Search here',
      'Help Center':'Help Center',
      'ID':'ID',
      'Phone Number':'Phone Number',
      'Status':'Status',
      'Created':'Created',
      '+ Add New Customer':'+ Add New Customer',
      'View Profile':'View Profile',
      'Coming Soon':'Coming Soon',
      'FAQs':'FAQs',
      'Settings':'Settings',
      'Sign Out':'Sign Out',
      'Deactive':'Deactive',
      'Active':'Active',
      'Active Users':'Active Users',
      'Deactive Users':'Deactive Users',
      'Why do You want to De-active this case?':'Why do You want to De-active this case?',
      'Please select the reason for deactivating this case.':'Please select the reason for deactivating this case.',
      'Visa':'Visa',
      'Rejected':'Rejected',
      'Declined':'Declined',
      'Lawyer List':'Lawyer List',
      'Customers Name':'Customers Name',
      'Clients Name':'Clients Name',
      'Are You Sure?':'Are You Sure?',
      'Certificate':'Certificate',
      'Please Pay Attention dear user!':'Please Pay Attention dear user!',
      'You Can edit your information JUST ONE TIME!!!':'You Can edit your information JUST ONE TIME!!!',
      'We will send a link to ':'We will send a link to ',
      ' for reset your password':' for reset your password',
      'Username':'Username',
      'Clients':'Clients',
      '+ Add New Agent':'+ Add New Agent',
      'Prices Information':'Prices Information',
      'Service':'Service',
      'Offer':'Offer',
      'Public Offer':'Public Offer',
      'Price':'Price',
      'Type a price here...':'Type a price here...',
      'Fee...':'Fee...',
      '+ Add Service':'+ Add Service',
      'Type about your service...':'Type about your service...',
      '$':'$',
      'Password':'Password',
      'type your password here':'type your password here',
      'type your email':'type your email',
      'You are almost there!':'You are almost there!',
      'Check Your Email':'Check Your Email',
      'Done!':'Done!',
      '2 of 3':'2 of 3',
      'Passport No / National Number / ID Num':'Passport No / National Number / ID Num',
      '3 of 3':'3 of 3',
      'Enter your username and password in box below to log in And have access to a world of immigration facilities':'Enter your username and password in box below to log in And have access to a world of immigration facilities',
      'Enter your email address signed up below in the box below to receive a link to reset your password.':'Enter your email address signed up below in the box below to receive a link to reset your password.',
      'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.',
      'Your password has been successfully changed. Now you can enter with your new password.':'Your password has been successfully changed. Now you can enter with your new password.',
      'Access your specific features by selecting appropriate user type.':'Access your specific features by selecting appropriate user type.',
      'Please help know you better by completing your personal information.':'Please help know you better by completing your personal information.',
      'Please help us know you better by completing your educational information.':'Please help us know you better by completing your educational information.',
      'Please help us know you better by completing your job information.':'Please help us know you better by completing your job information.',
      'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.',
      'Your registration was successful. You already have access to a variety of immigration opportunities.':'Your registration was successful. You already have access to a variety of immigration opportunities.',
      'Go to main page':'Go to main page',
      'Dear user, your information has been successfully registered in our database':'Dear user, your information has been successfully registered in our database',
      'Our experts will contact you as soon as possible.':'Our experts will contact you as soon as possible.',
      'Certificate':'Certificate',
      'License':'License',
      'Distributor':'Distributor',
      'Your Code is Valid.':'Your Code is Valid.',
      'Your Code is Invalid, It is not registered in our database.':'Your Code is Invalid, It is not registered in our database.',
      'Full name: ':'Full name: ',
      'Position: ':'Position: ',
      'I got it.':'I got it.',
      'I Got it':'I Got it',
      'Search by Telesiness Code':'Search by Telesiness Code',
      'Find':'Find',
      'Inquiry':'Inquiry',
      'Lawyer or Distributor':'Lawyer or Distributor',
      'Menu Item Will Be Active After Reviewing Your Profile By Our Experts':'Menu Item Will Be Active After Reviewing Your Profile By Our Experts',
      'My Requests':'My Requests',
      'You choose the selection of lawyer by Telesiness':'You choose the selection of lawyer by Telesiness',
      'You choose the lawyer by ':'You choose the lawyer by ',
      'Send Request':'Send Request',
      'Advocacy Code':'Advocacy Code',
      'Agent commission':'Agent commission',
      'Distributer commission':'Distributer commission',
      'Agent Percent':'Agent Percent',
      'Percent':'Percent',
      'See more':'See more',
      'Please enter the email of the lawyer you want to cooperate with.':'Please enter the email of the lawyer you want to cooperate with.',
      'Please enter the email of the Distributer you want to cooperate with.':'Please enter the email of the Distributer you want to cooperate with.',
      'Please enter the email of the Agent you want to cooperate with.':'Please enter the email of the Agent you want to cooperate with.',
      'Please enter the email of the Customer you want to cooperate with.':'Please enter the email of the Customer you want to cooperate with.',
      'Please Select Your Co-Lawyers for Your Customer.':'Please Select Your Co-Lawyers for Your Customer.',
      'ADD BY Email Address':'ADD BY Email Address',
      'Lawyers Name':'Lawyers Name',
      'Adding a Lawyer for Customer':'Adding a Lawyer for Customer',
      'Enter the applicants Lawyers':'Enter the applicants Lawyers',
      'Enter the applicants Lawyers Num 1':'Enter the applicants Lawyers Num 1',
      'Enter the applicants Lawyers Num 2':'Enter the applicants Lawyers Num 2',
      'Enter the applicants Lawyers Num 3':'Enter the applicants Lawyers Num 3',
      'Enter the applicants Lawyers Num 4':'Enter the applicants Lawyers 4',
      'Enter the applicants Lawyers Num 5':'Enter the applicants Lawyers 5',
      'Enter the applicants Lawyers Num 6':'Enter the applicants Lawyers 6',
      '+ Add New Distributer':'+ Add New Distributer',
      '+ Lawyer':'+ Lawyer',
      'Add Lawyer':'Add Lawyer',
      'Lawyer Name':'Lawyer Name',
      'Lawyer 1 Name...':'Lawyer 1 Name...',
      'Lawyer 2 Name...':'Lawyer 2 Name...',
      'Lawyer 3 Name...':'Lawyer 3 Name...',
      'Lawyer 4 Name...':'Lawyer 4 Name...',
      'Lawyer 5 Name...':'Lawyer 5 Name...',
      'Lawyer 6 Name...':'Lawyer 6 Name...',
      'Download Form':'Download Form',
      'Customer Code':'Customer Code',
      'All Done!':'All Done!',
      'Applicant Personal Information':'Applicant Personal Information',
      'Applicant Language Information':'Applicant Language Information',
      'Email Address':'Email Address',
      'Type your Email address...':'Type your Email address...',
      '+ Add New Lawyer':'+ Add New Lawyer',
      'Completed Files':'Completed Files',
      'Canceled Files':'Canceled Files',
      'In Progress Files':'In Progress Files',
      'Latest Customers':'Latest Customers',
      'Latest Agents':'Latest Agents',
      'Adding a new Customer':'Adding a new Customer',
      'Adding a new Agent':'Adding a new Agent',
      'Agency Code':'Agency Code',
      'Latest Lawyers':'Latest Lawyers',
      'Adding a New Lawyer':'Adding a New Lawyer',
      'Registery Date':'Registery Date',
      'Lawyer Information':'Lawyer Information',
      'There is no information to display':'There is no information to display',
      'You don’t have any request yet':'You don’t have any request yet',
      '+ Register new Request':'+ Register new Request',
      'Submit a request':'Submit a request',
      'Fill in all the required information carefully to follow-up your request process':'Fill in all the required information carefully to follow-up your request process',
      'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.':'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.',
      'Basic Information':'Basic Information',
      'Applicant Information':'Applicant Information',
      'Spouse Information':'Spouse Information',
      'Couples Information':'Couples Information',
      'Children Information':'Children Information',
      'Children':'Children',
      'Other':'Other',
      'back to My Requests':'back to My Requests',
      'Male':'Male',
      'Female':'Female',
      'Complete Basic Information':'Complete Basic Information',
      'Personal Information':'Personal Information',
      'Enter the applicant’s First Name in Persian':'Enter the applicant’s First Name in Persian',
      'Enter the applicant’s Last Name in Persian':'Enter the applicant’s Last Name in Persian',
      'ID Number':'ID Number',
      'Enter the applicant’s ID Number':'Enter the applicant’s ID Number',
      'National Code':'National Code',
      'Enter the applicant’s national code':'Enter the applicant’s national code',
      'E-mail':'E-mail',
      'Enter the applicant’s Email address':'Enter the applicant’s Email address',
      'Call Number':'Call Number',
      'Enter the applicant’s Call number':'Enter the applicant’s Call number',
      'Enter the applicant’s Phone number':'Enter the applicant’s Phone number',
      'Select the applicant’s Country':'Select the applicant’s Country',
      'Enter the applicant’s Country':'Enter the applicant’s Country',
      'Enter the applicant’s address':'Enter the applicant’s address',
      'Postal Code':'Postal Code',
      'Enter the applicant’s Postal Code':'Enter the applicant’s Postal Code',
      'place of Birth':'place of Birth',
      'Enter the applicant’s place of birth':'Enter the applicant’s place of birth',
      'Enter the applicant’s date of birth':'Enter the applicant’s date of birth',
      'Your file has been successfully registered':'Your file has been successfully registered',
      'Please be patient until Your information is reviewed and approved by our experts, and then complete your information.':'Please be patient until Your information is reviewed and approved by our experts, and then complete your information.',
      'Tracking Code':'Tracking Code',
      'Go to requests':'Go to requests',
      'Visa Type':'Visa Type',
      'Situation':'Situation',
      'Destination':'Destination',
      'Code':'Code',
      'Creation Date':'Creation Date',
      'Back to Submitting a request':'Back to Submitting a request',
      'Needs to be completed':'Needs to be completed',
      'Completed':'Completed',
      'In progress':'In progress',
      'Passport Information':'Passport Information',
      'Financial Information':'Financial Information',
      'Foreign travel Information':'Foreign travel Information',
      'Military Information':'Military Information',
      'Marriage Information':'Marriage Information',
      'Family Information':'Family Information',
      'Requested Visa Information':'Requested Visa Information',
      'Identical Information':'Identical Information',
      'National Card Image(Front)':'National Card Image(Front)',
      'National Card Image(Behind)':'National Card Image(Behind)',
      'Identity card Image(First Page)':'Identity card Image(First Page)',
      'Identity card Image(Second Page)':'Identity card Image(Second Page)',
      'Identity card Image(Last Page)':'Identity card Image(Last Page)',
      'Complete Passport Information':'Complete Passport Information',
      'Pasport Information':'Pasport Information',
      'Passport Number':'Passport Number',
      'Enter the applicant’s Passport Number':'Enter the applicant’s Passport Number',
      'Name':'Name',
      'Enter the applicant’s Name in the Passport':'Enter the applicant’s Name in the Passport',
      'Family':'Family',
      'Enter the applicant’s Family in the Passport':'Enter the applicant’s Family in the Passport',
      'Enter the applicant’s Passport Creation Date':'Enter the applicant’s Passport Creation Date',
      'Expiration Date':'Expiration Date',
      'Enter the applicant’s Passport Expiration Date':'Enter the applicant’s Passport Expiration Date',
      'Passport Creation Place':'Passport Creation Place',
      'Enter the applicant’s Passport Creation Place':'Enter the applicant’s Passport Creation Place',
      'Country of Residence':'Country of Residence',
      'Country of residence stated in the passport':'Country of residence stated in the passport',
      'Enter the applicant’s Date of birth On Passport':'Enter the applicant’s Date of birth On Passport',
      'Passport Documentations':'Passport Documentations',
      'Passport Image':'Passport Image',
      'Personal Picture in Pass':'Personal Picture in Pass',
      'Sample Passport Signature':'Sample Passport Signature',
      'Place of Issuance of National Card':'Place of Issuance of National Card',
      'Enter the applicant’s Place of Issuance of National Card':'Enter the applicant’s Place of Issuance of National Card',
      'Birth Certificate Number':'Birth Certificate Number',
      'Enter the applicant’s Birth Certificate Number':'Enter the applicant’s Birth Certificate Number',
      'Father’s Name on the Birth Certificate':'Father’s Name on the Birth Certificate',
      'Enter the applicant’s Fathers Name on the Birth Certificate':'Enter the applicant’s Fathers Name on the Birth Certificate',
      'Mother’s Name on the Birth Certificate':'Mother’s Name on the Birth Certificate',
      'Enter the applicant’s Mothers Name on the Birth Certificate':'Enter the applicant’s Mothers Name on the Birth Certificate',
      'Alias Name or Surname or changed in the identity card':'Alias Name or Surname or changed in the identity card',
      'Applicant’s Alias Name,Surname or changed in the identity card':'Applicant’s Alias Name,Surname or changed in the identity card',
      'Military Card Number':'Military Card Number',
      'Enter the applicant’s Military Card Number':'Enter the applicant’s Military Card Number',
      'Date of Issuance of Military Card':'Date of Issuance of Military Card',
      'Enter the applicant’s Passport Creation Date':'Enter the applicant’s Passport Creation Date',
      'Type of Military Card':'Type of Military Card',
      'Enter the applicant’s Type of Military Card':'Enter the applicant’s Type of Military Card',
      'Place of Military Service':'Place of Military Service',
      'Enter the applicant’s Place of Military Service':'Enter the applicant’s Place of Military Service',
      'Height':'Height',
      'Enter the applicant’s Height':'Enter the applicant’s Height',
      'Skin Color':'Skin Color',
      'Enter the applicant’s Skin Color':'Enter the applicant’s Skin Color',
      'Eye Color':'Eye Color',
      'Enter the applicant’s Eye Color':'Enter the applicant’s Eye Color',
      'Postal Code One':'Postal Code One' ,
      'Enter the applicant’s Postal Code (Place One - Required)':'Enter the applicant’s Postal Code (Place One - Required)',
      'Address One':'Address One',
      'Enter the applicant’s address (Place One - Required)':'Enter the applicant’s address (Place One - Required)',
      'Address Two':'Address Two',
      'Enter the applicant’s address (Place Two)':'Enter the applicant’s address (Place Two)',
      'Postal Code Two':'Postal Code Two',
      'Enter the applicant’s Postal Code (Place Two)':'Enter the applicant’s Postal Code (Place Two)',
      'Workplace Call Number':'Workplace Call Number',
      'Enter the applicant’s Workplace Call Number':'Enter the applicant’s Workplace Call Number',
      'Type of Marriage':'Type of Marriage',
      'Enter the applicant’s Type of Marriage':'Enter the applicant’s Type of Marriage',
      'Evaluation Form Tracking Number':'Evaluation Form Tracking Number',
      'Enter the applicant’s Evaluation Form Tracking Number':'Enter the applicant’s Evaluation Form Tracking Number',
      'Call Number (Two)':'Call Number (Two)',
      'Enter the applicant’s Call number (Second Number Optional)':'Enter the applicant’s Call number (Second Number Optional)',
      'Phone Number (Two)':'Phone Number (Two)',
      'Enter the applicant’s Phone number (Second Number Optional)':'Enter the applicant’s Phone number (Second Number Optional)',
      'Workplace Call Number (Two)':'Workplace Call Number (Two)',
      'Enter the applicant’s Workplace Call Number (Optional)':'Enter the applicant’s Workplace Call Number (Optional)',
      'Yellow':'Yellow',
      'White':'White',
      'Red':'Red',
      'Brunette':'Brunette',
      'Tan':'Tan',
      'Black':'Black',
      'Green':'Green',
      'Gray':'Gray',
      'Blue':'Blue',
      'Brown':'Brown',
      'Amber':'Amber',
      'Honey-Colored':'Honey-Colored',
      'Black':'Black',
      'Married':'Married',
      'Single':'Single',
      'Identical Documents':'Identical Documents',
      'Military service card':'Military service card',
      'Lack of background':'Lack of background',
      'Certificate or any other ID Card':'Certificate or any other ID Card',
      'Evaluation Form':'Evaluation Form',
      'Other Documents':'Other Documents',
      'Step 2-1':'Step 2-1',
      'Applicant Foreign Language Information':'Applicant Foreign Language Information',
      'Language Certifications':'Language Certifications',
      'English Language Status':'English Language Status',
      'I Have':'I Have',
      'I do not have':'I do not have',
      'I am reading':'I am reading',
      'I will be able to get a degree in the next 6 months':'I will be able to get a degree in the next 6 months',
      'I will not be able to get a degree for the next 8 months':'I will not be able to get a degree for the next 8 months',
      'Enter the applicant’s English Language Status':'Enter the applicant’s English Language Status',
      'IELTS':'IELTS',
      'Date of IELTS degree':'Date of IELTS degree',
      'Enter the applicant’s Date of IELTS degree':'Enter the applicant’s Date of IELTS degree',
      'The total score of the degree':'The total score of the degree',
      'Enter the applicant’s total score of the degree':'Enter the applicant’s total score of the degree',
      'IELTS Reading Score':'IELTS Reading Score',
      'Enter the applicant’s IELTS Reading Score':'Enter the applicant’s IELTS Reading Score',
      'IELTS Writing Score':'IELTS Writing Score',
      'Enter the applicant’s IELTS Writing Score':'Enter the applicant’s IELTS Writing Score',
      'IELTS Listening Score':'IELTS Listening Score',
      'Enter the applicant’s IELTS Listening Score':'Enter the applicant’s IELTS Listening Score',
      'IELTS Speacking Score':'IELTS Speacking Score',
      'Enter the applicant’s IELTS Speacking Score':'Enter the applicant’s IELTS Speacking Score',
      'TOEFL':'TOEFL',
      'Enter the applicant’s Date of Other Language degree':'Enter the applicant’s Date of Other Language degree',
      'Date of TOEFL degree':'Date of TOEFL degree',
      "Enter the applicant’s Field of Study":"Enter the applicant’s Field of Study",
      'Enter the applicant’s Date of TOEFL degree':'Enter the applicant’s Date of TOEFL degree',
      'TOEFL Reading Score':'TOEFL Reading Score',
      'Enter the applicant’s TOEFL Reading Score':'Enter the applicant’s TOEFL Reading Score',
      'TOEFL Writing Score':'TOEFL Writing Score',
      'Enter the applicant’s TOEFL Writing Score':'Enter the applicant’s TOEFL Writing Score',
      'TOEFL Listening Score':'TOEFL Listening Score',
      'Enter the applicant’s TOEFL Listening Score':'Enter the applicant’s TOEFL Listening Score',
      'TOEFL Speacking Score':'TOEFL Speacking Score',
      'Enter the applicant’s TOEFL Speacking Score':'Enter the applicant’s TOEFL Speacking Score',
      'Other Language (1)':'Other Language (1)',
      'The total score of the Other Language degree(1)':'The total score of the Other Language degree(1)',
      'Enter the applicant’s total score of the Other Language degree(1)':'Enter the applicant’s total score of the Other Language degree(1)',
      'Other Language Reading Score(1)':'Other Language Reading Score(1)',
      'Enter the applicant’s Other Language Reading Score(1)':'Enter the applicant’s Other Language Reading Score(1)',
      'Other Language Writing Score(1)':'Other Language Writing Score(1)',
      'Enter the applicant’s Other Language Writing Score(1)':'Enter the applicant’s Other Language Writing Score(1)',
      'Other Language Listening Score(1)':'Other Language Listening Score(1)',
      'Enter the applicant’s Other Language Listening Score(1)':'Enter the applicant’s Other Language Listening Score(1)',
      'Other Language Speacking Score(1)':'Other Language Speacking Score(1)',
      'Enter the applicant’s Other Language Speacking Score(1)':'Enter the applicant’s Other Language Speacking Score(1)',
      'Other Language (2)':'Other Language (2)',
      'The total score of the Other Language degree(2)':'The total score of the Other Language degree(2)',
      'Enter the applicant’s total score of the Other Language degree(2)':'Enter the applicant’s total score of the Other Language degree(2)',
      'Other Language Reading Score(2)':'Other Language Reading Score(2)',
      'Enter the applicant’s Other Language Reading Score(2)':'Enter the applicant’s Other Language Reading Score(2)',
      'Other Language Writing Score(2)':'Other Language Writing Score(2)',
      'Enter the applicant’s Other Language Writing Score(2)':'Enter the applicant’s Other Language Writing Score(2)',
      'Other Language Listening Score(2)':'Other Language Listening Score(2)',
      'Enter the applicant’s Other Language Listening Score(2)':'Enter the applicant’s Other Language Listening Score(2)',
      'Other Language Speacking Score(2)':'Other Language Speacking Score(2)',
      'Enter the applicant’s Other Language Speacking Score(2)':'Enter the applicant’s Other Language Speacking Score(2)',
      'Other Language (3)':'Other Language (3)',
      'The total score of the Other Language degree(3)':'The total score of the Other Language degree(3)',
      'Enter the applicant’s total score of the Other Language degree(3)':'Enter the applicant’s total score of the Other Language degree(3)',
      'Other Language Reading Score(3)':'Other Language Reading Score(3)',
      'Enter the applicant’s Other Language Reading Score(3)':'Enter the applicant’s Other Language Reading Score(3)',
      'Other Language Writing Score(3)':'Other Language Writing Score(3)',
      'Enter the applicant’s Other Language Writing Score(3)':'Enter the applicant’s Other Language Writing Score(3)',
      'Other Language Listening Score(3)':'Other Language Listening Score(3)',
      'Enter the applicant’s Other Language Listening Score(3)':'Enter the applicant’s Other Language Listening Score(3)',
      'Other Language Speacking Score(3)':'Other Language Speacking Score(3)',
      'Enter the applicant’s Other Language Speacking Score(3)':'Enter the applicant’s Other Language Speacking Score(3)',
      'Date of Other Language degree (1)':'Date of Other Language degree (1)',
      'Date of Other Language degree (2)':'Date of Other Language degree (2)',
      'Date of Other Language degree (3)':'Date of Other Language degree (3)',
      'Foreign Language Documents':'Foreign Language Documents',
      'Other Language(1)':'Other Language(1)',
      'Other Language(2)':'Other Language(2)',
      'Other Language(3)':'Other Language(3)',
      'Language Certificates':'Language Certificates',
      'Back to Applicant information':'Back to Applicant information',
      'Step 2-2':'Step 2-2',
      'Applicant Educational Information':'Applicant Educational Information',
      'Last Degree and Field of Study':'Last Degree and Field of Study',
      'Last Educational Certificate':'Last Educational Certificate',
      'Enter the applicant’s Last Educational Certificate':'Enter the applicant’s Last Educational Certificate',
      '(Required)':'(Required)',
      'Enter the applicant’s Field of Study':'Enter the applicant’s Field of Study',
      'High School':'High School',
      'Date of Entering High School':'Date of Entering High School',
      'Enter the applicant’s Date of Entering High School':'Enter the applicant’s Date of Entering High School',
      'Graduation Date':'Graduation Date',
      'Enter the applicant’s Graduation Date':'Enter the applicant’s Graduation Date',
      'Field of Study in Diploma':'Field of Study in Diploma',
      'Enter the applicant’s Field of Study in Diploma':'Enter the applicant’s Field of Study in Diploma',
      'Associate Degree':'Associate Degree',
      'Date of Entering University':'Date of Entering University',
      'Enter the applicant’s Date of Entering University':'Enter the applicant’s Date of Entering University',
      'University Name':'University Name',
      'Enter the applicant’s University Name':'Enter the applicant’s University Name',
      'University Plcae':'University Plcae',
      'Enter the applicant’s University Plcae':'Enter the applicant’s University Plcae',
      'Bachelor Degree':'Bachelor Degree',
      'Masters Degree':'Masters Degree',
      'P.H.D Degree':'P.H.D Degree',
      'Post-Doctoral Degree':'Post-Doctoral Degree',
      'Non-university Certificate':'Non-university Certificate',
      'Non-university Certificate Name(1)':'Non-university Certificate Name(1)',
      'Enter the applicant’s Non-university Certificate Name(1)':'Enter the applicant’s Non-university Certificate Name(1)',
      'Non-university Certificate Name(2)':'Non-university Certificate Name(2)',
      'Enter the applicant’s Non-university Certificate Name(2)':'Enter the applicant’s Non-university Certificate Name(2)',
      'Non-university Certificate Name(3)':'Non-university Certificate Name(3)',
      'Enter the applicant’s Non-university Certificate Name(3)':'Enter the applicant’s Non-university Certificate Name(3)',
      'Non-university Certificate Name(4)':'Non-university Certificate Name(4)',
      'Enter the applicant’s Non-university Certificate Name(4)':'Enter the applicant’s Non-university Certificate Name(4)',
      'Non-university Certificate Name(5)':'Non-university Certificate Name(5)',
      'Enter the applicant’s Non-university Certificate Name(5)':'Enter the applicant’s Non-university Certificate Name(5)',
      'Non-university Certificate Name(6)':'Non-university Certificate Name(6)',
      'Enter the applicant’s Non-university Certificate Name(6)':'Enter the applicant’s Non-university Certificate Name(6)',
      'Educational Documents':'Educational Documents',
      'Pre-High School Documents (1)':'Pre-High School Documents (1)',
      'Pre-High School Documents (2)':'Pre-High School Documents (2)',
      'Pre-High School Documents (3)':'Pre-High School Documents (3)',
      'High School (transcripts) 1th':'High School (transcripts) 1th',
      'High School (transcripts) 2th':'High School (transcripts) 2th',
      'High School (transcripts) 3th':'High School (transcripts) 3th',
      'High School (transcripts) 4th':'High School (transcripts) 4th',
      'Diploma Certificate':'Diploma Certificate',
      'Associate Diploma Transcripts':'Associate Diploma Transcripts',
      'Associate Certificate':'Associate Certificate',
      'Bachlor Transcripts':'Bachlor Transcripts',
      'Bachlor Certificate':'Bachlor Certificate',
      'Masters Transcripts':'Masters Transcripts',
      'Masters Certificate':'Masters Certificate',
      'P.H.D Transcripts':'P.H.D Transcripts',
      'P.H.D Certificate':'P.H.D Certificate',
      'Post-Doctoral Transcripts':'Post-Doctoral Transcripts',
      'Post-Doctoral Certificate':'Post-Doctoral Certificate',
      'ISI Papers':'ISI Papers',
      'CV':'CV',
      'Motivation Letter':'Motivation Letter',
      'Non-University Degree (1)':'Non-University Degree (1)',
      'Non-University Degree (2)':'Non-University Degree (2)',
      'Non-University Degree (3)':'Non-University Degree (3)',
      'Non-University Degree (4)':'Non-University Degree (4)',
      'Non-University Degree (5)':'Non-University Degree (5)',
      'Non-University Degree (6)':'Non-University Degree (6)',
      'Scientific Documents of Awards or Festivals':'Scientific Documents of Awards or Festivals',
      'Applicant Job Information':'Applicant Job Information',
      'Work Experience':'Work Experience',
      'Work Experience by year':'Work Experience by year',
      'Enter the applicant’s Work experience by year':'Enter the applicant’s Work experience by year',
      'Job':'Job',
      'Date of First Job':'Date of First Job',
      'Enter the applicant’s Date of First Job':'Enter the applicant’s Date of First Job',
      'Date of 2nd Job':'Date of 2nd Job',
      'Enter the applicant’s Date of 2nd Job':'Enter the applicant’s Date of 2nd Job',
      'Date of 3rd Job':'Date of 3rd Job',
      'Enter the applicant’s Date of 3rd Job':'Enter the applicant’s Date of 3rd Job',
      'Date of 4th Job':'Date of 4th Job',
      'Enter the applicant’s Date of 4th Job':'Enter the applicant’s Date of 4th Job',
      'Date of 5th Job':'Date of 5th Job',
      'Enter the applicant’s Date of 5th Job':'Enter the applicant’s Date of 5th Job',
      'Date of 6th Job':'Date of 6th Job',
      'Enter the applicant’s Date of 6th Job':'Enter the applicant’s Date of 6th Job',
      'Post (1)':'Post (1)',
      'Enter the applicant’s Post in Job (1)':'Enter the applicant’s Post in Job (1)', 
      'Post (2)':'Post (2)',
      'Enter the applicant’s Post in Job (2)':'Enter the applicant’s Post in Job (2)', 
      'Post (3)':'Post (3)',
      'Enter the applicant’s Post in Job (3)':'Enter the applicant’s Post in Job (3)', 
      'Post (4)':'Post (4)',
      'Enter the applicant’s Post in Job (4)':'Enter the applicant’s Post in Job (4)', 
      'Post (5)':'Post (5)',
      'Enter the applicant’s Post in Job (5)':'Enter the applicant’s Post in Job (5)', 
      'Post (6)':'Post (6)',
      'Enter the applicant’s Post in Job (6)':'Enter the applicant’s Post in Job (6)', 
      'Workplace (1)':'Workplace (1)',
      'Enter the applicant’s Workplace (1)':'Enter the applicant’s Workplace (1)',
      'Workplace (2)':'Workplace (2)',
      'Enter the applicant’s Workplace (2)':'Enter the applicant’s Workplace (2)',
      'Workplace (3)':'Workplace (3)',
      'Enter the applicant’s Workplace (3)':'Enter the applicant’s Workplace (3)',
      'Workplace (4)':'Workplace (4)',
      'Enter the applicant’s Workplace (4)':'Enter the applicant’s Workplace (4)',
      'Workplace (5)':'Workplace (5)',
      'Enter the applicant’s Workplace (5)':'Enter the applicant’s Workplace (5)',
      'Workplace (6)':'Workplace (6)',
      'Enter the applicant’s Workplace (6)':'Enter the applicant’s Workplace (6)',
      'Permissions':'Permissions',
      'Date of Business License (1)':'Date of Business License (1)',
      'Enter the applicant’s Date of business license (1)':'Enter the applicant’s Date of business license (1)',
      'Date of Business License (2)':'Date of Business License (2)',
      'Enter the applicant’s Date of business license (2)':'Enter the applicant’s Date of business license (2)',
      'Date of Business License (3)':'Date of Business License (3)',
      'Enter the applicant’s Date of business license (3)':'Enter the applicant’s Date of business license (3)',
      'Date of Business License (4)':'Date of Business License (4)',
      'Enter the applicant’s Date of business license (4)':'Enter the applicant’s Date of business license (4)',
      'Date of Business License (5)':'Date of Business License (5)',
      'Enter the applicant’s Date of business license (5)':'Enter the applicant’s Date of business license (5)',
      'Date of Business License (6)':'Date of Business License (6)',
      'Enter the applicant’s Date of business license (6)':'Enter the applicant’s Date of business license (6)',
      'Type and Name of License (1)':'Type and Name of License (1)',
      'Enter the applicant’s Type and Name of License (1)':'Enter the applicant’s Type and Name of License (1)',
      'Type and Name of License (2)':'Type and Name of License (2)',
      'Enter the applicant’s Type and Name of License (2)':'Enter the applicant’s Type and Name of License (2)',
      'Type and Name of License (3)':'Type and Name of License (3)',
      'Enter the applicant’s Type and Name of License (3)':'Enter the applicant’s Type and Name of License (3)',
      'Type and Name of License (4)':'Type and Name of License (4)',
      'Enter the applicant’s Type and Name of License (4)':'Enter the applicant’s Type and Name of License (4)',
      'Type and Name of License (5)':'Type and Name of License (5)',
      'Enter the applicant’s Type and Name of License (5)':'Enter the applicant’s Type and Name of License (5)',
      'Type and Name of License (6)':'Type and Name of License (6)',
      'Enter the applicant’s Type and Name of License (6)':'Enter the applicant’s Type and Name of License (6)',
      'License Expiration Date (1)':'License Expiration Date (1)',
      'Enter the applicant’s License Expiration Date (1)':'Enter the applicant’s License Expiration Date (1)',
      'License Expiration Date (2)':'License Expiration Date (2)',
      'Enter the applicant’s License Expiration Date (2)':'Enter the applicant’s License Expiration Date (2)',
      'License Expiration Date (3)':'License Expiration Date (3)',
      'Enter the applicant’s License Expiration Date (3)':'Enter the applicant’s License Expiration Date (3)',
      'License Expiration Date (4)':'License Expiration Date (4)',
      'Enter the applicant’s License Expiration Date (4)':'Enter the applicant’s License Expiration Date (4)',
      'License Expiration Date (5)':'License Expiration Date (5)',
      'Enter the applicant’s License Expiration Date (5)':'Enter the applicant’s License Expiration Date (5)',
      'License Expiration Date (6)':'License Expiration Date (6)',
      'Enter the applicant’s License Expiration Date (6)':'Enter the applicant’s License Expiration Date (6)',
      'Job qualifications':'Job qualifications',
      'Certificate of Employment':'Certificate of Employment',
      'Insurance':'Insurance',
      'Business License':'Business License',
      'Other Permissions':'Other Permissions',
      'Business Cards':'Business Cards',
      'Company Documents':'Company Documents',
      'Factory Documents':'Factory Documents',
      'CV':'CV',
      'Tax Returns':'Tax Returns',
      'Payroll':'Payroll',
      'Employment Warrant':'Employment Warrant',
      'Mission Warrant':'Mission Warrant',
      'Responsibility Warrant':'Responsibility Warrant',
      'Applicant Financial Information':'Applicant Financial Information',
      'Turnover Number':'Turnover Number',
      'Turnover':'Turnover',
      'Enter the applicant’s Turnover':'Enter the applicant’s Turnover',
      'Start Date of Turnover':'Start Date of Turnover',
      'Enter the applicant’s Start Date of Turnover':'Enter the applicant’s Start Date of Turnover',
      'End Date of Turnover':'End Date of Turnover',
      'Enter the applicant’s End Date of Turnover':'Enter the applicant’s End Date of Turnover',
      'Financial Ability':'Financial Ability',
      'Enter the applicant’s Financial Ability':'Enter the applicant’s Financial Ability',
      'Date of Financial':'Date of Financial',
      'Enter the applicant’s Date of Financial':'Enter the applicant’s Date of Financial',
      'Financial Currency Unit':'Financial Currency Unit',
      'Enter the applicant’s Financial Currency Unit':'Enter the applicant’s Financial Currency Unit',
      'Total Assets Amount':'Total Assets Amount',
      'Total Assets Amount in Number':'Total Assets Amount in Number',
      'Enter the applicant’s Total Assets Amount in Number':'Enter the applicant’s Total Assets Amount in Number',
      'Calculated Currency':'Calculated Currency',
      'Enter the applicant’s Calculated Currency':'Enter the applicant’s Calculated Currency',
      'Financial Documents':'Financial Documents',
      'Turnover (1)':'Turnover (1)',
      'Turnover (2)':'Turnover (2)',
      'Financial Ability (1)':'Financial Ability (1)',
      'Financial Ability (2)':'Financial Ability (2)',
      'Property Document (1)':'Property Document (1)',
      'Property Document (2)':'Property Document (2)',
      'Property Document (3)':'Property Document (3)',
      'Factory Document':'Factory Document',
      'Shop Document':'Shop Document',
      'Certificate of Stock Exchange':'Certificate of Stock Exchange',
      'Lease':'Lease',
      'Financial Supporter Letter (1)':'Financial Supporter Letter (1)',
      'Financial Supporter Letter (2)':'Financial Supporter Letter (2)',
      'Applicant Spouse Information':'Applicant Spouse Information',
      'Applicant Spouse Job Information':'Applicant Spouse Job Information',
      'Applicants Spouse Financial Information':'Applicants Spouse Financial Information',
      'Applicants Spouse Educational Information':'Applicants Spouse Educational Information',
      'Applicant Spouse Foreign Language Information':'Applicant Spouse Foreign Language Information',
      'Marriage Certificate Number':'Marriage Certificate Number',
      'Enter the applicants Marriage Certificate Number':'Enter the applicants Marriage Certificate Number',
      'Date of Marriage':'Date of Marriage',
      'Enter the applicant’s Date of Marriage':'Enter the applicant’s Date of Marriage',
      'Date of Divorce or Death':'Date of Divorce or Death',
      'Enter the applicant’s Date of Divorce or Death':'Enter the applicant’s Date of Divorce or Death',
      'Couples Documents':'Couples Documents',
      'Marriage Certificate':'Marriage Certificate',
      'Exit Permission':'Exit Permission',
      'Divorce or Death Letter':'Divorce or Death Letter',
      'Applicant Travel Information':'Applicant Travel Information',
      'Foreign Trips':'Foreign Trips',
      'Number of Foreign Trips':'Number of Foreign Trips',
      'Enter the applicant’s Number of Foreign Trips':'Enter the applicant’s Number of Foreign Trips',
      'Step 6':'Step 6',
      'Applicant Family Information':'Applicant Family Information',
      'Applicants Spouse Family Information':'Applicants Spouse Family Information',
      'Prime Family Information':'Prime Family Information',
      'Father':'Father',
      'Father’s Name':'Father’s Name',
      'Enter the applicant’s Father Name':'Enter the applicant’s Father Name',
      'Father’s Family':'Father’s Family',
      'Enter the applicant’s Father Family':'Enter the applicant’s Father Family',
      'Father’s Job':'Father’s Job',
      'Enter the applicant’s Father Job':'Enter the applicant’s Father Job',
      'Father’s Birth Day':'Father’s Birth Day',
      'Enter the applicant’s Father Birth Day':'Enter the applicant’s Father Birth Day',
      'Father’s Residence Address':'Father’s Residence Address',
      'Enter the applicant’s Fathers Residence Address':'Enter the applicant’s Fathers Residence Address',
      'Father’s Postal Code':'Father’s Postal Code',
      'Enter the applicant’s Father Postal Code':'Enter the applicant’s Father Postal Code',
      'Father’s Phone Call':'Father’s Phone Call',
      'Enter the applicant’s Father Phone Call':'Enter the applicant’s Father Phone Call',
      'Father’s Education':'Father’s Education',
      'Enter the applicant’s Father’s Education':'Enter the applicant’s Father’s Education',
      'Father’s Financial Ability':'Father’s Financial Ability',
      'Enter the applicant’s Father’s Financial Ability':'Enter the applicant’s Father’s Financial Ability',
      'Father’s Date of Divorce':'Father’s Date of Divorce',
      'Enter the applicant’s Father’s Date of Divorce':'Enter the applicant’s Father’s Date of Divorce',
      'Father’s Date of Death':'Father’s Date of Death',
      'Enter the applicant’s Father’s Date of Death':'Enter the applicant’s Father’s Date of Death',
      'Father’s Foreign Trip (1)':'Father’s Foreign Trip (1)',
      'Father’s Foreign Trip (2)':'Father’s Foreign Trip (2)',
      'Father’s Foreign Trip (3)':'Father’s Foreign Trip (3)',
      'Father’s Foreign Trip (4)':'Father’s Foreign Trip (4)',
      'Father’s Foreign Trip (5)':'Father’s Foreign Trip (5)',
      'Father’s Foreign Trip (6)':'Father’s Foreign Trip (6)',
      'Enter the applicant’s Father’s Foreign Trip':'Enter the applicant’s Father’s Foreign Trip',

      'Mother':'Mother',
      'Mother’s Name':'Mother’s Name',
      'Enter the applicant’s Mother Name':'Enter the applicant’s Mother Name',
      'Mother’s Family':'Mother’s Family',
      'Enter the applicant’s Mother Family':'Enter the applicant’s Mother Family',
      'Mother’s Job':'Mother’s Job',
      'Enter the applicant’s Mother Job':'Enter the applicant’s Mother Job',
      'Mother’s Birth Day':'Mother’s Birth Day',
      'Enter the applicant’s Mother Birth Day':'Enter the applicant’s Mother Birth Day',
      'Mother’s Residence Address':'Mother’s Residence Address',
      'Enter the applicant’s Mothers Residence Address':'Enter the applicant’s Mothers Residence Address',
      'Mother’s Postal Code':'Mother’s Postal Code',
      'Enter the applicant’s Mother Postal Code':'Enter the applicant’s Mother Postal Code',
      'Mother’s Phone Call':'Mother’s Phone Call',
      'Enter the applicant’s Mother Phone Call':'Enter the applicant’s Mother Phone Call',
      'Mother’s Education':'Mother’s Education',
      'Enter the applicant’s Mother’s Education':'Enter the applicant’s Mother’s Education',
      'Mother’s Financial Ability':'Mother’s Financial Ability',
      'Enter the applicant’s Mother’s Financial Ability':'Enter the applicant’s Mother’s Financial Ability',
      'Mother’s Date of Divorce':'Mother’s Date of Divorce',
      'Enter the applicant’s Mother’s Date of Divorce':'Enter the applicant’s Mother’s Date of Divorce',
      'Mother’s Date of Death':'Mother’s Date of Death',
      'Enter the applicant’s Mother’s Date of Death':'Enter the applicant’s Mother’s Date of Death',
      'Mother’s Foreign Trip (1)':'Mother’s Foreign Trip (1)',
      'Mother’s Foreign Trip (2)':'Mother’s Foreign Trip (2)',
      'Mother’s Foreign Trip (3)':'Mother’s Foreign Trip (3)',
      'Mother’s Foreign Trip (4)':'Mother’s Foreign Trip (4)',
      'Mother’s Foreign Trip (5)':'Mother’s Foreign Trip (5)',
      'Mother’s Foreign Trip (6)':'Mother’s Foreign Trip (6)',
      'Enter the applicant’s Mother’s Foreign Trip':'Enter the applicant’s Mother’s Foreign Trip',

      'Brother (1)':'Brother (1)',
      '1th Brother’s Name':'1th Brother’s Name',
      'Enter the applicant’s 1th Brother Name':'Enter the applicant’s 1th Brother Name',
      '1th Brother’s Family':'1th Brother’s Family',
      'Enter the applicant’s 1th Brother Family':'Enter the applicant’s 1th Brother Family',
      '1th Brother’s Job':'1th Brother’s Job',
      'Enter the applicant’s 1th Brother Job':'Enter the applicant’s 1th Brother Job',
      '1th Brother’s Birth Day':'1th Brother’s Birth Day',
      'Enter the applicant’s 1th Brother Birth Day':'Enter the applicant’s 1th Brother Birth Day',
      '1th Brother’s Reside nce Address':'1th Brother’s Reside nce Address',
      'Enter the applicant’s 1th Brothers Residence Address':'Enter the applicant’s 1th Brothers Residence Address',
      '1th Brother’s Postal Code':'1th Brother’s Postal Code',
      'Enter the applicant’s 1th Brother Postal Code':'Enter the applicant’s 1th Brother Postal Code',
      '1th Brother’s Phone Call':'1th Brother’s Phone Call',
      'Enter the applicant’s 1th Brother Phone Call':'Enter the applicant’s 1th Brother Phone Call',
      '1th Brother’s Education':'1th Brother’s Education',
      'Enter the applicant’s 1th Brother’s Education':'Enter the applicant’s 1th Brother’s Education',
      '1th Brother’s Financial Ability':'1th Brother’s Financial Ability',
      'Enter the applicant’s 1th Brother’s Financial Ability':'Enter the applicant’s 1th Brother’s Financial Ability',
      '1th Brother’s Date of Divorce':'1th Brother’s Date of Divorce',
      'Enter the applicant’s 1th Brother’s Date of Divorce':'Enter the applicant’s 1th Brother’s Date of Divorce',
      '1th Brother’s Date of Death':'1th Brother’s Date of Death',
      'Enter the applicant’s 1th Brother’s Date of Death':'Enter the applicant’s 1th Brother’s Date of Death',
      '1th Brother’s Foreign Trip (1)':'1th Brother’s Foreign Trip (1)',
      '1th Brother’s Foreign Trip (2)':'1th Brother’s Foreign Trip (2)',
      '1th Brother’s Foreign Trip (3)':'1th Brother’s Foreign Trip (3)',
      '1th Brother’s Foreign Trip (4)':'1th Brother’s Foreign Trip (4)',
      '1th Brother’s Foreign Trip (5)':'1th Brother’s Foreign Trip (5)',
      '1th Brother’s Foreign Trip (6)':'1th Brother’s Foreign Trip (6)',
      'Enter the applicant’s 1th Brother’s Foreign Trip':'Enter the applicant’s 1th Brother’s Foreign Trip',

      'Brother (2)':'Brother (2)',
      '2nd Brother’s Name':'2nd Brother’s Name',
      'Enter the applicant’s 2nd Brother Name':'Enter the applicant’s 2nd Brother Name',
      '2nd Brother’s Family':'2nd Brother’s Family',
      'Enter the applicant’s 2nd Brother Family':'Enter the applicant’s 2nd Brother Family',
      '2nd Brother’s Job':'2nd Brother’s Job',
      'Enter the applicant’s 2nd Brother Job':'Enter the applicant’s 2nd Brother Job',
      '2nd Brother’s Birth Day':'2nd Brother’s Birth Day',
      'Enter the applicant’s 2nd Brother Birth Day':'Enter the applicant’s 2nd Brother Birth Day',
      '2nd Brother’s Reside nce Address':'2nd Brother’s Reside nce Address',
      'Enter the applicant’s 2nd Brothers Residence Address':'Enter the applicant’s 2nd Brothers Residence Address',
      '2nd Brother’s Postal Code':'2nd Brother’s Postal Code',
      'Enter the applicant’s 2nd Brother Postal Code':'Enter the applicant’s 2nd Brother Postal Code',
      '2nd Brother’s Phone Call':'2nd Brother’s Phone Call',
      'Enter the applicant’s 2nd Brother Phone Call':'Enter the applicant’s 2nd Brother Phone Call',
      '2nd Brother’s Education':'2nd Brother’s Education',
      'Enter the applicant’s 2nd Brother’s Education':'Enter the applicant’s 2nd Brother’s Education',
      '2nd Brother’s Financial Ability':'2nd Brother’s Financial Ability',
      'Enter the applicant’s 2nd Brother’s Financial Ability':'Enter the applicant’s 2nd Brother’s Financial Ability',
      '2nd Brother’s Date of Divorce':'2nd Brother’s Date of Divorce',
      'Enter the applicant’s 2nd Brother’s Date of Divorce':'Enter the applicant’s 2nd Brother’s Date of Divorce',
      '2nd Brother’s Date of Death':'2nd Brother’s Date of Death',
      'Enter the applicant’s 2nd Brother’s Date of Death':'Enter the applicant’s 2nd Brother’s Date of Death',
      '2nd Brother’s Foreign Trip (1)':'2nd Brother’s Foreign Trip (1)',
      '2nd Brother’s Foreign Trip (2)':'2nd Brother’s Foreign Trip (2)',
      '2nd Brother’s Foreign Trip (3)':'2nd Brother’s Foreign Trip (3)',
      '2nd Brother’s Foreign Trip (4)':'2nd Brother’s Foreign Trip (4)',
      '2nd Brother’s Foreign Trip (5)':'2nd Brother’s Foreign Trip (5)',
      '2nd Brother’s Foreign Trip (6)':'2nd Brother’s Foreign Trip (6)',
      'Enter the applicant’s 2nd Brother’s Foreign Trip':'Enter the applicant’s 2nd Brother’s Foreign Trip',

      'Brother (3)':'Brother (3)',
      '3rd Brother’s Name':'3rd Brother’s Name',
      'Enter the applicant’s 3rd Brother Name':'Enter the applicant’s 3rd Brother Name',
      '3rd Brother’s Family':'3rd Brother’s Family',
      'Enter the applicant’s 3rd Brother Family':'Enter the applicant’s 3rd Brother Family',
      '3rd Brother’s Job':'3rd Brother’s Job',
      'Enter the applicant’s 3rd Brother Job':'Enter the applicant’s 3rd Brother Job',
      '3rd Brother’s Birth Day':'3rd Brother’s Birth Day',
      'Enter the applicant’s 3rd Brother Birth Day':'Enter the applicant’s 3rd Brother Birth Day',
      '3rd Brother’s Reside nce Address':'3rd Brother’s Reside nce Address',
      'Enter the applicant’s 3rd Brothers Residence Address':'Enter the applicant’s 3rd Brothers Residence Address',
      '3rd Brother’s Postal Code':'3rd Brother’s Postal Code',
      'Enter the applicant’s 3rd Brother Postal Code':'Enter the applicant’s 3rd Brother Postal Code',
      '3rd Brother’s Phone Call':'3rd Brother’s Phone Call',
      'Enter the applicant’s 3rd Brother Phone Call':'Enter the applicant’s 3rd Brother Phone Call',
      '3rd Brother’s Education':'3rd Brother’s Education',
      'Enter the applicant’s 3rd Brother’s Education':'Enter the applicant’s 3rd Brother’s Education',
      '3rd Brother’s Financial Ability':'3rd Brother’s Financial Ability',
      'Enter the applicant’s 3rd Brother’s Financial Ability':'Enter the applicant’s 3rd Brother’s Financial Ability',
      '3rd Brother’s Date of Divorce':'3rd Brother’s Date of Divorce',
      'Enter the applicant’s 3rd Brother’s Date of Divorce':'Enter the applicant’s 3rd Brother’s Date of Divorce',
      '3rd Brother’s Date of Death':'3rd Brother’s Date of Death',
      'Enter the applicant’s 3rd Brother’s Date of Death':'Enter the applicant’s 3rd Brother’s Date of Death',
      '3rd Brother’s Foreign Trip (1)':'3rd Brother’s Foreign Trip (1)',
      '3rd Brother’s Foreign Trip (2)':'3rd Brother’s Foreign Trip (2)',
      '3rd Brother’s Foreign Trip (3)':'3rd Brother’s Foreign Trip (3)',
      '3rd Brother’s Foreign Trip (4)':'3rd Brother’s Foreign Trip (4)',
      '3rd Brother’s Foreign Trip (5)':'3rd Brother’s Foreign Trip (5)',
      '3rd Brother’s Foreign Trip (6)':'3rd Brother’s Foreign Trip (6)',
      'Enter the applicant’s 3rd Brother’s Foreign Trip':'Enter the applicant’s 3rd Brother’s Foreign Trip',


      'Sister (1)':'Sister (1)',
      '1th Sister’s Name':'1th Sister’s Name',
      'Enter the applicant’s 1th Sister Name':'Enter the applicant’s 1th Sister Name',
      '1th Sister’s Family':'1th Sister’s Family',
      'Enter the applicant’s 1th Sister Family':'Enter the applicant’s 1th Sister Family',
      '1th Sister’s Job':'1th Sister’s Job',
      'Enter the applicant’s 1th Sister Job':'Enter the applicant’s 1th Sister Job',
      '1th Sister’s Birth Day':'1th Sister’s Birth Day',
      'Enter the applicant’s 1th Sister Birth Day':'Enter the applicant’s 1th Sister Birth Day',
      '1th Sister’s Reside nce Address':'1th Sister’s Reside nce Address',
      'Enter the applicant’s 1th Sisters Residence Address':'Enter the applicant’s 1th Sisters Residence Address',
      '1th Sister’s Postal Code':'1th Sister’s Postal Code',
      'Enter the applicant’s 1th Sister Postal Code':'Enter the applicant’s 1th Sister Postal Code',
      '1th Sister’s Phone Call':'1th Sister’s Phone Call',
      'Enter the applicant’s 1th Sister Phone Call':'Enter the applicant’s 1th Sister Phone Call',
      '1th Sister’s Education':'1th Sister’s Education',
      'Enter the applicant’s 1th Sister’s Education':'Enter the applicant’s 1th Sister’s Education',
      '1th Sister’s Financial Ability':'1th Sister’s Financial Ability',
      'Enter the applicant’s 1th Sister’s Financial Ability':'Enter the applicant’s 1th Sister’s Financial Ability',
      '1th Sister’s Date of Divorce':'1th Sister’s Date of Divorce',
      'Enter the applicant’s 1th Sister’s Date of Divorce':'Enter the applicant’s 1th Sister’s Date of Divorce',
      '1th Sister’s Date of Death':'1th Sister’s Date of Death',
      'Enter the applicant’s 1th Sister’s Date of Death':'Enter the applicant’s 1th Sister’s Date of Death',
      '1th Sister’s Foreign Trip (1)':'1th Sister’s Foreign Trip (1)',
      '1th Sister’s Foreign Trip (2)':'1th Sister’s Foreign Trip (2)',
      '1th Sister’s Foreign Trip (3)':'1th Sister’s Foreign Trip (3)',
      '1th Sister’s Foreign Trip (4)':'1th Sister’s Foreign Trip (4)',
      '1th Sister’s Foreign Trip (5)':'1th Sister’s Foreign Trip (5)',
      '1th Sister’s Foreign Trip (6)':'1th Sister’s Foreign Trip (6)',
      'Enter the applicant’s 1th Sister’s Foreign Trip':'Enter the applicant’s 1th Sister’s Foreign Trip',

      'Sister (2)':'Sister (2)',
      '2nd Sister’s Name':'2nd Sister’s Name',
      'Enter the applicant’s 2nd Sister Name':'Enter the applicant’s 2nd Sister Name',
      '2nd Sister’s Family':'2nd Sister’s Family',
      'Enter the applicant’s 2nd Sister Family':'Enter the applicant’s 2nd Sister Family',
      '2nd Sister’s Job':'2nd Sister’s Job',
      'Enter the applicant’s 2nd Sister Job':'Enter the applicant’s 2nd Sister Job',
      '2nd Sister’s Birth Day':'2nd Sister’s Birth Day',
      'Enter the applicant’s 2nd Sister Birth Day':'Enter the applicant’s 2nd Sister Birth Day',
      '2nd Sister’s Reside nce Address':'2nd Sister’s Reside nce Address',
      'Enter the applicant’s 2nd Sisters Residence Address':'Enter the applicant’s 2nd Sisters Residence Address',
      '2nd Sister’s Postal Code':'2nd Sister’s Postal Code',
      'Enter the applicant’s 2nd Sister Postal Code':'Enter the applicant’s 2nd Sister Postal Code',
      '2nd Sister’s Phone Call':'2nd Sister’s Phone Call',
      'Enter the applicant’s 2nd Sister Phone Call':'Enter the applicant’s 2nd Sister Phone Call',
      '2nd Sister’s Education':'2nd Sister’s Education',
      'Enter the applicant’s 2nd Sister’s Education':'Enter the applicant’s 2nd Sister’s Education',
      '2nd Sister’s Financial Ability':'2nd Sister’s Financial Ability',
      'Enter the applicant’s 2nd Sister’s Financial Ability':'Enter the applicant’s 2nd Sister’s Financial Ability',
      '2nd Sister’s Date of Divorce':'2nd Sister’s Date of Divorce',
      'Enter the applicant’s 2nd Sister’s Date of Divorce':'Enter the applicant’s 2nd Sister’s Date of Divorce',
      '2nd Sister’s Date of Death':'2nd Sister’s Date of Death',
      'Enter the applicant’s 2nd Sister’s Date of Death':'Enter the applicant’s 2nd Sister’s Date of Death',
      '2nd Sister’s Foreign Trip (1)':'2nd Sister’s Foreign Trip (1)',
      '2nd Sister’s Foreign Trip (2)':'2nd Sister’s Foreign Trip (2)',
      '2nd Sister’s Foreign Trip (3)':'2nd Sister’s Foreign Trip (3)',
      '2nd Sister’s Foreign Trip (4)':'2nd Sister’s Foreign Trip (4)',
      '2nd Sister’s Foreign Trip (5)':'2nd Sister’s Foreign Trip (5)',
      '2nd Sister’s Foreign Trip (6)':'2nd Sister’s Foreign Trip (6)',
      'Enter the applicant’s 2nd Sister’s Foreign Trip':'Enter the applicant’s 2nd Sister’s Foreign Trip',

      'Sister (3)':'Sister (3)',
      '3rd Sister’s Name':'3rd Sister’s Name',
      'Enter the applicant’s 3rd Sister Name':'Enter the applicant’s 3rd Sister Name',
      '3rd Sister’s Family':'3rd Sister’s Family',
      'Enter the applicant’s 3rd Sister Family':'Enter the applicant’s 3rd Sister Family',
      '3rd Sister’s Job':'3rd Sister’s Job',
      'Enter the applicant’s 3rd Sister Job':'Enter the applicant’s 3rd Sister Job',
      '3rd Sister’s Birth Day':'3rd Sister’s Birth Day',
      'Enter the applicant’s 3rd Sister Birth Day':'Enter the applicant’s 3rd Sister Birth Day',
      '3rd Sister’s Reside nce Address':'3rd Sister’s Reside nce Address',
      'Enter the applicant’s 3rd Sisters Residence Address':'Enter the applicant’s 3rd Sisters Residence Address',
      '3rd Sister’s Postal Code':'3rd Sister’s Postal Code',
      'Enter the applicant’s 3rd Sister Postal Code':'Enter the applicant’s 3rd Sister Postal Code',
      '3rd Sister’s Phone Call':'3rd Sister’s Phone Call',
      'Enter the applicant’s 3rd Sister Phone Call':'Enter the applicant’s 3rd Sister Phone Call',
      '3rd Sister’s Education':'3rd Sister’s Education',
      'Enter the applicant’s 3rd Sister’s Education':'Enter the applicant’s 3rd Sister’s Education',
      '3rd Sister’s Financial Ability':'3rd Sister’s Financial Ability',
      'Enter the applicant’s 3rd Sister’s Financial Ability':'Enter the applicant’s 3rd Sister’s Financial Ability',
      '3rd Sister’s Date of Divorce':'3rd Sister’s Date of Divorce',
      'Enter the applicant’s 3rd Sister’s Date of Divorce':'Enter the applicant’s 3rd Sister’s Date of Divorce',
      '3rd Sister’s Date of Death':'3rd Sister’s Date of Death',
      'Enter the applicant’s 3rd Sister’s Date of Death':'Enter the applicant’s 3rd Sister’s Date of Death',
      '3rd Sister’s Foreign Trip (1)':'3rd Sister’s Foreign Trip (1)',
      '3rd Sister’s Foreign Trip (2)':'3rd Sister’s Foreign Trip (2)',
      '3rd Sister’s Foreign Trip (3)':'3rd Sister’s Foreign Trip (3)',
      '3rd Sister’s Foreign Trip (4)':'3rd Sister’s Foreign Trip (4)',
      '3rd Sister’s Foreign Trip (5)':'3rd Sister’s Foreign Trip (5)',
      '3rd Sister’s Foreign Trip (6)':'3rd Sister’s Foreign Trip (6)',
      'Enter the applicant’s 3rd Sister’s Foreign Trip':'Enter the applicant’s 3rd Sister’s Foreign Trip',
      'Prime Family Documents':'Prime Family Documents',
      'Passport (1)':'Passport (1)',
      'Passport (2)':'Passport (2)',
      'Passport (3)':'Passport (3)',
      'Passport (4)':'Passport (4)',
      'Passport (5)':'Passport (5)',
      'Passport (6)':'Passport (6)',
      'Passport (7)':'Passport (7)',
      'Passport (8)':'Passport (8)',
      'Applicant Travel Information':'Applicant Travel Information',
      'Applicant Sopuse Travel Information':'Applicant Sopuse Travel Information',
      'Foreign Trips':'Foreign Trips',
      'Number of Foreign Trips':'Number of Foreign Trips',
      'Enter the applicant’s Number of Foreign Trips':'Enter the applicant’s Number of Foreign Trips',
      'Arrival Travel Date (1)':'Arrival Travel Date (1)',
      'Enter the applicant’s Arrival Travel Date (1)':'Enter the applicant’s Arrival Travel Date (1)',
      'Departure Travel Date (1)':'Departure Travel Date (1)',
      'Enter the applicant’s Departure Travel Date (1)':'Enter the applicant’s Departure Travel Date (1)',
      'Travel Location (1)':'Travel Location (1)',
      'Enter the applicant’s Travel Location (1)':'Enter the applicant’s Travel Location (1)',
      'Staying Time (1)':'Staying Time (1)',
      'Enter the applicant’s Staying Time (1)':'Enter the applicant’s Staying Time (1)',
      'Reason for Travel (1)':'Reason for Travel (1)',
      'Enter the applicant’s Reason for Travel (1) ':'Enter the applicant’s Reason for Travel (1) ',
      'Cities visited in the destination country (1)':'Cities visited in the destination country (1)',
      'Enter the applicant’s Cities visited in the destination country (1)':'Enter the applicant’s Cities visited in the destination country (1)',

      'Arrival Travel Date (2)':'Arrival Travel Date (2)',
      'Enter the applicant’s Arrival Travel Date (2)':'Enter the applicant’s Arrival Travel Date (2)',
      'Departure Travel Date (2)':'Departure Travel Date (2)',
      'Enter the applicant’s Departure Travel Date (2)':'Enter the applicant’s Departure Travel Date (2)',
      'Travel Location (2)':'Travel Location (2)',
      'Enter the applicant’s Travel Location (2)':'Enter the applicant’s Travel Location (2)',
      'Staying Time (2)':'Staying Time (2)',
      'Enter the applicant’s Staying Time (2)':'Enter the applicant’s Staying Time (2)',
      'Reason for Travel (2)':'Reason for Travel (2)',
      'Enter the applicant’s Reason for Travel (2) ':'Enter the applicant’s Reason for Travel (2) ',
      'Cities visited in the destination country (2)':'Cities visited in the destination country (2)',
      'Enter the applicant’s Cities visited in the destination country (2)':'Enter the applicant’s Cities visited in the destination country (2)',

      'Arrival Travel Date (3)':'Arrival Travel Date (3)',
      'Enter the applicant’s Arrival Travel Date (3)':'Enter the applicant’s Arrival Travel Date (3)',
      'Departure Travel Date (3)':'Departure Travel Date (3)',
      'Enter the applicant’s Departure Travel Date (3)':'Enter the applicant’s Departure Travel Date (3)',
      'Travel Location (3)':'Travel Location (3)',
      'Enter the applicant’s Travel Location (3)':'Enter the applicant’s Travel Location (3)',
      'Staying Time (3)':'Staying Time (3)',
      'Enter the applicant’s Staying Time (3)':'Enter the applicant’s Staying Time (3)',
      'Reason for Travel (3)':'Reason for Travel (3)',
      'Enter the applicant’s Reason for Travel (3) ':'Enter the applicant’s Reason for Travel (3) ',
      'Cities visited in the destination country (3)':'Cities visited in the destination country (3)',
      'Enter the applicant’s Cities visited in the destination country (3)':'Enter the applicant’s Cities visited in the destination country (3)',

      'Arrival Travel Date (4)':'Arrival Travel Date (4)',
      'Enter the applicant’s Arrival Travel Date (4)':'Enter the applicant’s Arrival Travel Date (4)',
      'Departure Travel Date (4)':'Departure Travel Date (4)',
      'Enter the applicant’s Departure Travel Date (4)':'Enter the applicant’s Departure Travel Date (4)',
      'Travel Location (4)':'Travel Location (4)',
      'Enter the applicant’s Travel Location (4)':'Enter the applicant’s Travel Location (4)',
      'Staying Time (4)':'Staying Time (4)',
      'Enter the applicant’s Staying Time (4)':'Enter the applicant’s Staying Time (4)',
      'Reason for Travel (4)':'Reason for Travel (4)',
      'Enter the applicant’s Reason for Travel (4) ':'Enter the applicant’s Reason for Travel (4) ',
      'Cities visited in the destination country (4)':'Cities visited in the destination country (4)',
      'Enter the applicant’s Cities visited in the destination country (4)':'Enter the applicant’s Cities visited in the destination country (4)',
      'Schengen':'Schengen',
      'Schengen Number':'Schengen Number',
      'Enter the applicant’s Schengen Number':'Enter the applicant’s Schengen Number',
      'Schengen Issuance Date':'Schengen Issuance Date',
      'Enter the applicant’s Schengen Issuance Date':'Enter the applicant’s Schengen Issuance Date',

      'Special Visa':'Special Visa',
      'Special Visa Number (1)':'Special Visa Number (1)',
      'Enter the applicant’s Special Visa Number':'Enter the applicant’s Special Visa Number',
      'Visa Name (1)':'Visa Name (1)',
      'Enter the applicant’s Visa Name':'Enter the applicant’s Visa Name',

      'Special Visa Number (2)':'Special Visa Number (2)',
      'Visa Name (2)':'Visa Name (2)',
      'Special Visa Number (3)':'Special Visa Number (3)',
      'Visa Name (3)':'Visa Name (3)',
      'Special Visa Number (4)':'Special Visa Number (4)',
      'Visa Name (4)':'Visa Name (4)',
      'Special Visa Number (5)':'Special Visa Number (5)',
      'Visa Name (5)':'Visa Name (5)',
      'Special Visa Number (6)':'Special Visa Number (6)',
      'Visa Name (6)':'Visa Name (6)',

      '- in 20 Character':'- in 20 Character',

      'Residence of The Destination Country':'Residence of The Destination Country',
      'Expiry date of residence of the destination country (1)':'Expiry date of residence of the destination country (1)',
      'Enter the applicant’s Expiry date of residence of the destination country':'Enter the applicant’s Expiry date of residence of the destination country',
      'Expiry date of residence of the destination country (2)':'Expiry date of residence of the destination country (2)',
      'Expiry date of residence of the destination country (3)':'Expiry date of residence of the destination country (3)',
      
      'Rejected Visa':'Rejected Visa',
      'Prior Rejection Date (1)':'Prior Rejection Date (1)',
      'Enter the applicants Prior Rejection Date':'Enter the applicants Prior Rejection Date',
      'The country you rejected (1)':'The country you rejected (1)',
      'Enter the applicants The Country You Rejected':'Enter the applicants The Country You Rejected',
      'Rejected Visa Type (1)':'Rejected Visa Type (1)',
      'Enter the applicant’s Rejected Visa Type':'Enter the applicant’s Rejected Visa Type',
      'Date of Application for Rejected Visa (1)':'Date of Application for Rejected Visa (1)',
      'Enter the applicant’s Date of Application for Rejected Visa':'Enter the applicant’s Date of Application for Rejected Visa',
      'Reason for Rejection (1)':'Reason for Rejection (1)',
      'Enter the applicant’s Reason for Rejection':'Enter the applicant’s Reason for Rejection',

      'Prior Rejection Date (2)':'Prior Rejection Date (2)',
      'The country you rejected (2)':'The country you rejected (2)',
      'Rejected Visa Type (2)':'Rejected Visa Type (2)',
      'Date of Application for Rejected Visa (2)':'Date of Application for Rejected Visa (2)',
      'Reason for Rejection (2)':'Reason for Rejection (2)',

      'Prior Rejection Date (3)':'Prior Rejection Date (3)',
      'The country you rejected (3)':'The country you rejected (3)',
      'Rejected Visa Type (3)':'Rejected Visa Type (3)',
      'Date of Application for Rejected Visa (3)':'Date of Application for Rejected Visa (3)',
      'Reason for Rejection (3)':'Reason for Rejection (3)',

      'Prior Rejection Date (4)':'Prior Rejection Date (4)',
      'The country you rejected (4)':'The country you rejected (4)',
      'Rejected Visa Type (4)':'Rejected Visa Type (4)',
      'Date of Application for Rejected Visa (4)':'Date of Application for Rejected Visa (4)',
      'Reason for Rejection (4)':'Reason for Rejection (4)',

      'Prior Rejection Date (5)':'Prior Rejection Date (5)',
      'The country you rejected (5)':'The country you rejected (5)',
      'Rejected Visa Type (5)':'Rejected Visa Type (5)',
      'Date of Application for Rejected Visa (5)':'Date of Application for Rejected Visa (5)',
      'Reason for Rejection (5)':'Reason for Rejection (5)',

      'Prior Rejection Date (6)':'Prior Rejection Date (6)',
      'The country you rejected (6)':'The country you rejected (6)',
      'Rejected Visa Type (6)':'Rejected Visa Type (6)',
      'Date of Application for Rejected Visa (6)':'Date of Application for Rejected Visa (6)',
      'Reason for Rejection (6)':'Reason for Rejection (6)',

      'Applicants Travel Documents':'Applicants Travel Documents',
      'Applicants Spouse Travel Documents':'Applicants Spouse Travel Documents',
      'Old Passport (1)':'Old Passport (1)',
      'Old Passport (2)':'Old Passport (2)',
      'Old Passport (3)':'Old Passport (3)',

      'Special or Revoked Visa':'Special or Revoked Visa',
      'New Visa in The New Passport':'New Visa in The New Passport',
      'Residence documents of a specific country':'Residence documents of a specific country',
      'Documents related to the rejected file (1)':'Documents related to the rejected file (1)',
      'Documents related to the rejected file (2)':'Documents related to the rejected file (2)',
      'Documents related to the rejected file (3)':'Documents related to the rejected file (3)',
      'Documents related to the rejected file (4)':'Documents related to the rejected file (4)',
      'Documents related to the rejected file (5)':'Documents related to the rejected file (5)',
      'Documents related to the rejected file (6)':'Documents related to the rejected file (6)',

      'Details of Foreign Trips':'Details of Foreign Trips',

      'Other Information':'Other Information',
      'applicant’s Information’s Title (1)':'applicant’s Information’s Title (1)',
      'applicant’s Information (1)':'applicant’s Information (1)',
      'applicant’s Information’s Title (2)':'applicant’s Information’s Title (2)',
      'applicant’s Information (2)':'applicant’s Information (2)',
      'applicant’s Information’s Title (3)':'applicant’s Information’s Title (3)',
      'applicant’s Information (3)':'applicant’s Information (3)',
      'Enter the applicant’s Information’s Title':'Enter the applicant’s Information’s Title',
      'Enter the applicant’s Information':'Enter the applicant’s Information',

      'Other Documents of the Applicant, Spouse and Companion':'Other Documents of the Applicant, Spouse and Companion',
      'Patent':'Patent',
      'Ideas Submission':'Ideas Submission',
      'Other Qualifications':'Other Qualifications',
      'Other Job Qualifications':'Other Job Qualifications',
      'Other Financial Documents':'Other Financial Documents',
      'Motivation Letter':'Motivation Letter',
      'Other Documents (1)':'Other Documents (1)',

      'Applicant Children Information':'Applicant Children Information',
      'Applicants Spouse Children Information':'Applicants Spouse Children Information',
      'Number of Children':'Number of Children',
      'Enter the applicant’s Number of Children':'Enter the applicant’s Number of Children',
      'Profile of Children':'Profile of Children',
      'Boy':'Boy',
      'Girl':'Girl',

      'Child Name (1)':'Child Name (1)',
      'Child Family (1)':'Child Family (1)',
      'Child Age (1)':'Child Age (1)',

      'Child Name (2)':'Child Name (2)',
      'Child Family (2)':'Child Family (2)',
      'Child Age (2)':'Child Age (2)',

      'Child Name (3)':'Child Name (3)',
      'Child Family (3)':'Child Family (3)',
      'Child Age (3)':'Child Age (3)',

      'Child Name (4)':'Child Name (4)',
      'Child Family (4)':'Child Family (4)',
      'Child Age (4)':'Child Age (4)',

      'Child Name (5)':'Child Name (5)',
      'Child Family (5)':'Child Family (5)',
      'Child Age (5)':'Child Age (5)',

      'Child Name (6)':'Child Name (6)',
      'Child Family (6)':'Child Family (6)',
      'Child Age (6)':'Child Age (6)',

      'Child Name (7)':'Child Name (7)',
      'Child Family (7)':'Child Family (7)',
      'Child Age (7)':'Child Age (7)',

      'Child Name (8)':'Child Name (8)',
      'Child Family (8)':'Child Family (8)',
      'Child Age (8)':'Child Age (8)',

      'Child Name (9)':'Child Name (9)',
      'Child Family (9)':'Child Family (9)',
      'Child Age (9)':'Child Age (9)',

      'Child Name (10)':'Child Name (10)',
      'Child Family (10)':'Child Family (10)',
      'Child Age (10)':'Child Age (10)',

      'Child Name (11)':'Child Name (11)',
      'Child Family (11)':'Child Family (11)',
      'Child Age (11)':'Child Age (11)',

      'Child Name (12)':'Child Name (12)',
      'Child Family (12)':'Child Family (12)',
      'Child Age (12)':'Child Age (12)',

      'Enter the applicant’s Child Name':'Enter the applicant’s Child Name',
      'Enter the applicant’s Child Family':'Enter the applicant’s Child Family',
      'Child’s Gender':'Child’s Gender',
      'Enter the applicant’s Child’s Gender':'Enter the applicant’s Child’s Gender',
      'Enter the applicant’s Child Age':'Enter the applicant’s Child Age',

      'Children Documents':'Children Documents',
      'First child Passport':'First child Passport',
      'First child National Card':'First child National Card',
      'First child Birth Certificate':'First child Birth Certificate',
      'First child Exit Permission':'First child Exit Permission',
      'First child Financial Ability':'First child Financial Ability',
      'First child Certificate of Education':'First child Certificate of Education',
      'First child Other Documents':'First child Other Documents',

      'Visa Information':'Visa Information',
      'Why did you  choose destination country?':'Why did you  choose destination country?',
      'Input Your Answer Here...':'Input Your Answer Here...',
      'Why did you choose this type of visa?':'Why did you choose this type of visa?',
      'Why did you choose the desired university?':'Why did you choose the desired university?',
      'Why did you choose the desired field?':'Why did you choose the desired field?',
      'What work and study did you do in your country before the visa?':'What work and study did you do in your country before the visa?',
      'What is your goal in the destination country after the visa?':'What is your goal in the destination country after the visa?',
      'How do you cover the costs of the destination country?':'How do you cover the costs of the destination country?',
      'What is your goal after returning to your home country?':'What is your goal after returning to your home country?',
      'Do you have relatives or acquaintances or friends in the destination country?':'Do you have relatives or acquaintances or friends in the destination country?',
      'What is the idea for a startup visa and who designed it?':'What is the idea for a startup visa and who designed it?',
      'What are the sources of funding for a startup visa?':'What are the sources of funding for a startup visa?',
      'What is your goal in choosing a startup visa or business visa?':'What is your goal in choosing a startup visa or business visa?',
      'Are you seeking asylum?':'Are you seeking asylum?',
      'Do you have any refugee relatives in your chosen country?':'Do you have any refugee relatives in your chosen country?',
      'Do you already have investments in the destination country?':'Do you already have investments in the destination country?',
      'Have you traveled to your chosen country before?':'Have you traveled to your chosen country before?',
      'What was your goal in obtaining a Schengen visa?':'What was your goal in obtaining a Schengen visa?',

      'Step 2-7':'Step 2-7',
      '- in 500 Character':'- in 500 Character',

      'State':'State',
      'Enter Your State':'Enter Your State',
      'military service situation':'military service situation',
      'Enter the military service situation':'Enter the military service situation',
      'Applicant Age':'Applicant Age',
      'Enter the Applicant’s Age':'Enter the Applicant’s Age',
      'Scientific Documents of Awards or Festivals(1)':'Scientific Documents of Awards or Festivals(1)',
      'Enter the Applicant’s Scientific Documents of Awards or Festivals(1)':'Enter the Applicant’s Scientific Documents of Awards or Festivals(1)',
      'Research and Studies(1)':'Research and Studies(1)',
      'Enter the Applicant’s Research and Studies(1)':'Enter the Applicant’s Research and Studies(1)',
      'Scientific Documents of Awards or Festivals(2)':'Scientific Documents of Awards or Festivals(2)',
      'Enter the Applicant’s Scientific Documents of Awards or Festivals(2)':'Enter the Applicant’s Scientific Documents of Awards or Festivals(2)',
      'Research and Studies(2)':'Research and Studies(2)',
      'Enter the Applicant’s Research and Studies(2)':'Enter the Applicant’s Research and Studies(2)',
      'Scientific Documents of Awards or Festivals(3)':'Scientific Documents of Awards or Festivals(3)',
      'Enter the Applicant’s Scientific Documents of Awards or Festivals(3)':'Enter the Applicant’s Scientific Documents of Awards or Festivals(3)',
      'Research and Studies(3)':'Research and Studies(3)',
      'Enter the Applicant’s Research and Studies(3)':'Enter the Applicant’s Research and Studies(3)',
      'What languages can you speak?':'What languages can you speak?',
      'English':'English',
      'Persian':'Persian',
      'Familiarity with the chosen language':'Familiarity with the chosen language',
      'Excellent':'Excellent',
      'Good':'Good',
      'Medium':'Medium',
      'Weak':'Weak',
      'Very weak':'Very weak',
      'Enter the Applicant’s Familiarity with the chosen language':'Enter the Applicant’s Familiarity with the chosen language',
      'English Score':'English Score',
      'Enter the Applicant’s English Score':'Enter the Applicant’s English Score',
      'French':'French',
      'French Score':'French Score',
      'Enter the Applicant’s French Score':'Enter the Applicant’s French Score',
      'German':'German',
      'German Score':'German Score',
      'Enter the Applicant’s German Score':'Enter the Applicant’s German Score',
      'Danish':'Danish',
      'Danish Score':'Danish Score',
      'Enter the Applicant’s Danish Score':'Enter the Applicant’s Danish Score',
      'Arabic':'Arabic',
      'Arabic Score':'Arabic Score',
      'Enter the Applicant’s Arabic Score':'Enter the Applicant’s Arabic Score',
      'Spanish':'Spanish',
      'Spanish Score':'Spanish Score',
      'Enter the Applicant’s Spanish Score':'Enter the Applicant’s Spanish Score',
      'Chinese':'Chinese',
      'Chinese Score':'Chinese Score',
      'Enter the Applicant’s Chinese Score':'Enter the Applicant’s Chinese Score',
      'Korean':'Korean',
      'Korean Score':'Korean Score',
      'Enter the Applicant’s Korean Score':'Enter the Applicant’s Korean Score',
      'Turnover Number':'Turnover Number',
      'Enter the applicant’s Turnover Number':'Enter the applicant’s Turnover Number',
      '(In past 10 years)':'(In past 10 years)',
      'Job Title (1)':'Job Title (1)',
      'Enter the Job Title (1)':'Enter the Job Title (1)',
      'Insurance history or duration of activity in your job (1)':'Insurance history or duration of activity in your job (1)',
      'Enter the Insurance history or duration of activity in your job (1)':'Enter the Insurance history or duration of activity in your job (1)',
      'Job Post (1)':'Job Post (1)',
      'Enter the Applicant’s Job Post (1)':'Enter the Applicant’s Job Post (1)',

      'Job Title (2)':'Job Title (2)',
      'Enter the Job Title (2)':'Enter the Job Title (2)',
      'Insurance history or duration of activity in your job (2)':'Insurance history or duration of activity in your job (2)',
      'Enter the Insurance history or duration of activity in your job (2)':'Enter the Insurance history or duration of activity in your job (2)',
      'Job Post (2)':'Job Post (2)',
      'Enter the Applicant’s Job Post (2)':'Enter the Applicant’s Job Post (2)',

      'Job Title (3)':'Job Title (3)',
      'Enter the Job Title (3)':'Enter the Job Title (3)',
      'Insurance history or duration of activity in your job (3)':'Insurance history or duration of activity in your job (3)',
      'Enter the Insurance history or duration of activity in your job (3)':'Enter the Insurance history or duration of activity in your job (3)',
      'Job Post (3)':'Job Post (3)',
      'Enter the Applicant’s Job Post (3)':'Enter the Applicant’s Job Post (3)',

      'Liquidity':'Liquidity',
      'More than 70 thousand dollars':'More than 70 thousand dollars',
      'to':'to',
      'Less than $ 30,000':'Less than $ 30,000',
      'Enter the Applicant’s Liquidity':'Enter the Applicant’s Liquidity',
      'Enter the Applicant’s Total Assets Amount':'Enter the Applicant’s Total Assets Amount',
      'More than 600 thousand dollars':'More than 600 thousand dollars',
      'Less than $ 50,000':'Less than $ 50,000',
      'More than 25 thousand dollars':'More than 25 thousand dollars',
      'Less than $ 5,000':'Less than $ 5,000',
      'Further Information':'Further Information',

      'Do you have a child under 4 years old?':'Do you have a child under 4 years old?',
      'Yes':'Yes',
      'No':'No',
      'Number of children under 4 Years Old ':'Number of children under 4 Years Old ',
      'Enter the applicant’s Number of children under 4 Years Old ':'Enter the applicant’s Number of children under 4 Years Old ',
      'age of applicant’s children under 4 Years Old':'age of applicant’s children under 4 Years Old',
      'Enter the age of applicant’s children under 4 Years Old':'Enter the age of applicant’s children under 4 Years Old',
      'Gender of Under 4 Years Old children':'Gender of Under 4 Years Old children',
      'Enter the applicant’s Gender of Under 4 Years Old children':'Enter the applicant’s Gender of Under 4 Years Old children',
      'Do you have children between 4 and 17 years old?':'Do you have children between 4 and 17 years old?',
      'Number of children between 4 and 17 Old':'Number of children between 4 and 17 Old',
      'Enter the applicant’s Number of children between 4 and 17 Old':'Enter the applicant’s Number of children between 4 and 17 Old',
      'Age of children between 4 and 17 Old Child':'Age of children between 4 and 17 Old Child',
      'Enter the applicant’s Age of children between 4 and 17 Old':'Enter the applicant’s Age of children between 4 and 17 Old',
      'Gender of children between 4 and 17 Old':'Gender of children between 4 and 17 Old',
      'Enter the applicant’s Gender of children between 4 and 17 Old':'Enter the applicant’s Gender of children between 4 and 17 Old',
      'Do you have children over 18?':'Do you have children over 18?',
      'Number of children Over 18 Years Old ':'Number of children Over 18 Years Old ',
      'Enter the applicant’s Number of children Over 18 Years Old ':'Enter the applicant’s Number of children Over 18 Years Old ',
      'Age of children over 18 Years Old':'Age of children over 18 Years Old',
      'Enter the applicant’s Age of children over 18 Years Old':'Enter the applicant’s Age of children over 18 Years Old',
      'Gender of children over 18 Years Old':'Gender of children over 18 Years Old',
      'Enter the applicant’s Gender of children over 18 Years Old':'Enter the applicant’s Gender of children over 18 Years Old',

      'Do you have a companion? (Father - mother - grandfather - grandmother)':'Do you have a companion? (Father - mother - grandfather - grandmother)',
      'Country of immigration':'Country of immigration',
      'Sweden':'Sweden',
      'Germany':'Germany',
      'United Kingdom':'United Kingdom',
      'Denmark':'Denmark',
      'Austria':'Austria',
      'Spain':'Spain',
      'Australia':'Australia',
      'UAS':'UAS',
      'Ukraine':'Ukraine',
      'Oman':'Oman',
      'Type of Requested Visa':'Type of Requested Visa',
      'Tourist':'Tourist',
      'Educational':'Educational',
      'Student':'Student',
      'Work':'Work',
      'Business':'Business',
      'Company Registration':'Company Registration',
      'Buy real estate and companies':'Buy real estate and companies',
      'Entrepreneurship':'Entrepreneurship',
      'Startup':'Startup',
      'Express Entry':'Express Entry',
      'Provincial':'Provincial',
      'Companion - Parents':'Companion - Parents',
      'Rejection - Court':'Rejection - Court',

      'The purpose of migration':'The purpose of migration',
      'Enter the applicant’s purpose of migration':'Enter the applicant’s purpose of migration',
      'Traveled to a Schengen area, Canada,...':'Traveled to a Schengen area, Canada,...',
      'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?':'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?',
      'Names of countries you have visited':'Names of countries you have visited',
      'Enter the Names of countries you have visited':'Enter the Names of countries you have visited',
      'Has the applicant ever had a history of rejection from a country?':'Has the applicant ever had a history of rejection from a country?',
      'Names of countries you have been rejected so far':'Names of countries you have been rejected so far',
      'Enter the Names of countries you have been rejected since 5 years':'Enter the Names of countries you have been rejected since 5 years',
      'Do you have first and second degree relatives in your chosen country?':'Do you have first and second degree relatives in your chosen country?',
      'Explain...':'Explain...',
      'Enter the applicant’s Explain':'Enter the applicant’s Explain',

      'Does the applicant have a financial supporter?':'Does the applicant have a financial supporter?',
      'Explain if you have a financial supporter':'Explain if you have a financial supporter',
      'Enter Explain if you have a financial supporter':'Enter Explain if you have a financial supporter',
      'Spouse’s Name':'Spouse’s Name',
      'Enter Your Spouse’s Name':'Enter Your Spouse’s Name',
      'Spouse’s Last Name':'Spouse’s Last Name',
      'Enter Your Spouse’s Last Name':'Enter Your Spouse’s Last Name',

      'Applicant Accompany Information':'Applicant Accompany Information',
      'Accompany Post':'Accompany Post',
      'Enter the applicant’s Accompany Post':'Enter the applicant’s Accompany Post',
      'Applicant Descriptions':'Applicant Descriptions',
      'Descriptions':'Descriptions',
      'Enter Your Descriptions':'Enter Your Descriptions',
      'Included':'Included',
      'Served':'Served',
      'Conscription Exempt':'Conscription Exempt',
      'Purchased Service':'Purchased Service',
      'SORRY, PAGE NOT FOUND':'SORRY, PAGE NOT FOUND',
      'Return to Home Page':'Return to Home Page',
      'Your Cash is:':'Your Cash is:',
      '1500$':'1500$',
      '1000$':'1000$',
      '500$':'500$',
      'This Page is Under Construction, It will be available as soon as possible.':'This Page is Under Construction, It will be available as soon as possible.',

      'Lawyers':'Lawyers',
      'Pending':'Pending',
      '+ Services':'+ Services',
      'Enter the applicants Service Name':'Enter the applicants Service Name',
      'Messages':'Messages',
      'Service Name...':'Service Name...',
      'Messaging System is Online tools for communication between our users, also you can send message to anybody.':'Messaging System is Online tools for communication between our users, also you can send message to anybody.',
      'To':'To',
      'Subject':'Subject',
      'Type here...':'Type here...',
      'Enter Your message in the box.':'Enter Your message in the box.',
      'Send Message':'Send Message',
      'Type here your message':'Type here your message',

      'Type Your Desired Name for Service and Then click (Create)':'Type Your Desired Name for Service and Then click (Create)',
      
      'Select Your User Type':'Select Your User Type',

      'My Services':'My Services',
       
      'Lawyers List':'Lawyers List',
      'Agents List':'Agents List',
      'Customers List':'Customers List',

      'Service Owner':'Service Owner',
      'Choose Your Business Service':'Choose Your Business Service',
      'selecting your user Service type.':'selecting your user Service type.',
      '< Back to Account page':'< Back to Account page',
      'Distributers':'Distributers',

      'Dear user, welcome to your registration section':'Dear user, welcome to your registration section',
      'Select the appropriate option to register depending on the following conditions:':'Select the appropriate option to register depending on the following conditions:',
      '1- If you have an invitation code, select the option to register with the code':'1- If you have an invitation code, select the option to register with the code',
      '2- If you intend to cooperate and you think you have the conditions to obtain the code, Contact Us and select the option to Contact Us.':'2- If you intend to cooperate and you think you have the conditions to obtain the code, Contact Us and select the option to Contact Us.',
      '3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Agent option, and after obtaining the necessary points, you can reach the stage of obtaining a code.':'3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Agent option, and after obtaining the necessary points, you can reach the stage of obtaining a code.',
      '4- If none of the conditions are suitable, return to the previous page to display more options.':'4- If none of the conditions are suitable, return to the previous page to display more options.',
      'Choose Your Registration Type':'Choose Your Registration Type',
      'Registration with Code':'Registration with Code',
      'Registered as a Agent':'Registered as a Agent',
      'Doctor':'Doctor',
      'Car Service':'Car Service',
      'Real Estate':'Real Estate',
      'Trade':'Trade',
      'Digital  Services':'Digital  Services',
      'Beauty Services':'Beauty Services',
      'Gold and Jewelry':'Gold and Jewelry',
      
      'Latest Distributers':'Latest Distributers',

      'Message':'Message',
      'View':'View',
      'View Information':'View Information',
      'Related Distributers':'Related Distributers',
      'Related Distributers List':'Related Distributers List',
      'DS Customers':'DS Customers',
      'DS Shared Customers List':'DS Shared Customers List',
      'DC Customers':'DS Customers',
      'DC Shared Customers List':'DS Shared Customers List',
      'Agent Customers':'Agent Customers',
      'Agent Customers List':'Agent Customers List',
      'Distributer ':'Distributer ',
      'Distributers List':'Distributers List',

      'Choose Your Service':'Choose Your Service',
      'other':'other',
      'Type Service Name here':'Type Service Name here',
      'Service Name':'Service Name',
      'Service Price':'Service Price',
      'DS Commission':'DS Commission',
      'DC Commission':'DC Commission',
      'DC share':'DC share',
      'Distributer Commission':'Distributer Commission',
      'Agent Commission':'Agent Commission',
      'About Service':'About Service',
      'Enter the Description About Your Service':'Enter the Description About Your Service',
      'Accept':'Accept',
      'Educational Visa':'Educational Visa',
      'Enter Your Offer':'Enter Your Offer',
      'You Assigned Before':'You Assigned Before',
      'Coming Soon':'Coming Soon',
      'Soon!':'Soon!',
      'Our Help Desk':'Our Help Desk',
      'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You don’t have Access to Change Fees or Commissions!':'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You don’t have Access to Change Fees or Commissions!',
      'Services Never Change After Adding to Your List':'Services Never Change After Adding to Your List',
      'How To Become A Premium DS?':'How To Become A Premium DS?',
      'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.':'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.',
      'Dear user, these services will be available soon!':'Dear user, these services will be available soon!',
      'Our experts are working on it.':'Our experts are working on it.',
      'If your power of attorney code is not verified, your account will be deleted.':'If your power of attorney code is not verified, your account will be deleted.',
      'As Lawyer':'As Lawyer',
      'Date of birth':'Date of birth',
      'User Name Start with Alphabet and its Minimum length is 5':'User Name Start with Alphabet and its Minimum length is 5',
      'password':'password',
      'Confirm password':'Confirm password',
      'As Distributor':'As Distributor',
      'As Customer':'As Customer',
      'As Agent':'As Agent',
      'What service do you want?':'What service do you want?',
      'Your host Telens support team':'Your host Telens support team',
      'Way to communicate with us':'Way to communicate with us',
      'To Contact Us, just send us an email from the address below':'To Contact Us, just send us an email from the address below',
      'Follow us on social media':'Follow us on social media',
      'Can we help you?':'Can we help you?',
      'Joining the Telsins family':'Joining the Telsins family',
      'The name of the service is here':'The name of the service is here',
      'Referral Code':'Referral Code',
      'Select Your User Type':'Select Your User Type',
      'Select Your User':'Select Your User',
      'Telesiness Support':'Telesiness Support',
      'Select Service Name':'Select Service Name',
      'Required Documents':'Required Documents',
      'Enter the Customers Required Documents for This Service':'Enter the Customers Required Documents for This Service',
      'Visa Process':'Visa Process',
      'Enter the Process of This Service':'Enter the Process of This Service',
      'Referral Customers':'Referral Customers',
      'Referral Customer List':'Referral Customer List',
      'Customers List':'Customers List',
      'logging in...':'logging in...',
      'Service Country':'Service Country',
      '+ Add New Distributer':'+ Add New Distributer',
      'Hindi':'Hindi',
      'mandarin':'mandarin',
      'Dutch':'Dutch',
      'French':'French',
      'Bengali':'Bengali',
      'Russian':'Russian',
      'Portuguese':'Portuguese',
      'Indonesian':'Indonesian',
      'Japanese':'Japanese',
      'Turkish':'Turkish',
      'Italian':'Italian',
      'Romanian':'Romanian',
      'Thai':'Thai',
      'Nepali':'Nepali',
      'Gujarati':'Gujarati',
      'Your Customer Activated':'Your Customer Activated',
      'Your Customer Activated Successfully.':'Your Customer Activated Successfully.',
      'OK, I Got It':'OK, I Got It',
      'View Services':'View Services',
      'DS':'DS',
      'Assign a Services':'Assign a Services',
      'Your Assigned Services to Agent':'Your Assigned Services to Agent',
      'Agent Code':'Agent Code',
      'Your Assigned offer':'Your Assigned offer',
      'offer you':'offer you',
      'Suggested Services':'Suggested Services',
      'Save changes':'Save changes',
      'Assign by Telesiness':'Assign by Telesiness',
      'Adding a new Distributer':'Adding a new Distributer',
      'Type Distributer Code...':'Type Distributer Code ...',
      'Add DS!!!':'Add DS!!!',
      'Type DS Code Here...':'Type DS Code Here...',
      'ADD BY DS Code':'ADD BY DS Code',
      'DS Code':'DS Code',
      'Distributer Code':'Distributer Code',
      'List of Tariffs For Lawyer:':'List of Tariffs For Lawyer:',
      'Adding a new CO-DS':'Adding a new CO-DS',
      'Add a Distributer that want to cooperate with in your work':'Add a Distributer that want to cooperate with in your work',
      'Adding a Service to Customer':'Adding a Service to Customer',
      'Please Select a Service to customer.':'Please Select a Service to customer.',
      'Type an Email adress...':'Type an Email adress....',
      'DS Information':'DS Information',
      'Enter the applicant Gender':'Enter the applicant Gender',
      'Enter Your City':'Enter Your City',
      'please input your first name':'please input your first name',
      'please input your Last Name':'please input your Last Name',
      'please input your Phone number':'please input your Phone number',
      'please input your Email':'please input your Email',
      'please Select your Country':'please Select your Country',
      'please input your ICCRC':'please input your ICCRC',
      'UnEqual Password...':'UnEqual Password...',
      'DS Services':'DS Services',
      'This Option Will Be Active After You improve Panel to Pro Version!':'This Option Will Be Active After You improve Panel to Pro Version!',
      'Are you sure about the set tariff?':'Are you sure about the set tariff?',
      'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval':'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval',
      'please input your ID Number':'please input your ID Number',
      'You can upgrade your account to the professional version, click for more information!':'You can upgrade your account to the professional version, click for more information!',
      'UpLoad your Cv/Sop/evaluation-form':'UpLoad your Cv/Sop/evaluation-form',
      'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload':'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload',
      'You Have Not Code OR UnEqual Password...':'You Have Not Code OR UnEqual Password...',
      'Pay attention You can Approve Customer Account once, and after that you cant change status!':'Pay attention You can Approve Customer Account once, and after that you cant change status!',
      'Distributer Customers List':'Distributer Customers List',

      'Home Page Guide of Business Owner':'Home Page Guide of Business Owner',
      'Home Page':'Home Page',
      'Profile menu':'Profile menu',
      'Second icon':'Second icon',
      'Third icon':'Third icon',
      'Fourth icon':'Fourth icon',
      'Fifth section':'Fifth section',
      'Second part:':'Second part:',
      'Third part:':'Third part:',
      'In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.':'In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.',
      'In this section, you will see the number of successful, canceled and current files.':'In this section, you will see the number of successful, canceled and current files.',
      'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.':'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.',
      'By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.':'By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.',
      'By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.':'By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.',
      'By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.':'By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.',
      'Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.':`Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.`,
      'You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.':`You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.`,
      'Scoring system:':'Scoring system:',
      'Third part: Distributor points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.':'Third part: Distributor points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.',
      'First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.':'First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.',
      'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 60% of your total point.':'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 60% of your total point.',
      'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of three parts:':'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of three parts:',
      'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful business owners and distributors will select you and to work with you.':'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful business owners and distributors will select you and to work with you.',
      'The world of Telesiness Network Marketing helps you to increase your points to have more customers.':'The world of Telesiness Network Marketing helps you to increase your points to have more customers.',
      'At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.':'At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.',
      'Page of Customers:':'Page of Customers:',
      'In this section, you can completely control your customers’ files and also check your list of new customers.':'In this section, you can completely control your customers’ files and also check your list of new customers.',
      'In this section, you can completely control your customers’ files and also check your list of new customers. The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.':'In this section, you can completely control your customers’ files and also check your list of new customers. The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.',
      'By entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.':'By entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.',
      'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.':'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.',
      'If the customer is introduced by your distributor:':'If the customer is introduced by your distributor:',
      'The file is suspended so that you can determine the type and direction of the applicant’s request. If the file is not completed after 7 days, a message will be displayed next to the list of the desired customer as a reminder, and if you do not pay attention after 14 days, the file will be removed from your home page and will be referred to another service owner.':'The file is suspended so that you can determine the type and direction of the applicant’s request. If the file is not completed after 7 days, a message will be displayed next to the list of the desired customer as a reminder, and if you do not pay attention after 14 days, the file will be removed from your home page and will be referred to another service owner.',
      'After determining the path of the file, a message will be sent to your distributor so that he can proceed with the next administrative affairs with the customer, and after the approval of the distributor, the file of customer will be displayed for you completely. The customer will also be informed about the choice and his service owner.':'After determining the path of the file, a message will be sent to your distributor so that he can proceed with the next administrative affairs with the customer, and after the approval of the distributor, the file of customer will be displayed for you completely. The customer will also be informed about the choice and his service owner.',
      'By choosing your service owner and colleague, you can share a specific file with them. Accordingly, the customer and the distributor will not be informed and they will only know you. In this way, you can benefit from the support of your colleague in the path of a case.':'By choosing your service owner and colleague, you can share a specific file with them. Accordingly, the customer and the distributor will not be informed and they will only know you. In this way, you can benefit from the support of your colleague in the path of a case.',
      'Your chosen service owner can only see the details and documents of the customer. He cannot access to files (adding or deleting) and cannot send a direct message to the customer.':'Your chosen service owner can only see the details and documents of the customer. He cannot access to files (adding or deleting) and cannot send a direct message to the customer.',
      'Distributors will never have access to customer information, documents and records in any part of the program or file.':'Distributors will never have access to customer information, documents and records in any part of the program or file.',
      'Page of distributors:':'Page of distributors:',
      'Distributors play a key role in your business. In fact, more distributors equal more customers. Distributors will enter the Telesiness Network with special, complex conditions and strict rules, at the discretion of the service owners and at their own risk, and establish a working relationship with the page home and service owner of the customer. Distributors have limited access to their customers and can also have a large network of agents by attracting and training agents to attract a large number of customers. In fact, distributors are large marketing companies and complexes that market to business owners with different ways.':'Distributors play a key role in your business. In fact, more distributors equal more customers. Distributors will enter the Telesiness Network with special, complex conditions and strict rules, at the discretion of the service owners and at their own risk, and establish a working relationship with the page home and service owner of the customer. Distributors have limited access to their customers and can also have a large network of agents by attracting and training agents to attract a large number of customers. In fact, distributors are large marketing companies and complexes that market to business owners with different ways.',
      'If you wish, you can have a marketing network with the home page distributor for your company providing it to the employee, colleague or IT department. This is only possible for business owners to access the marketing department and save on advertising costs. For more information about this section, you can contact the support section of Telesiness.':'If you wish, you can have a marketing network with the home page distributor for your company providing it to the employee, colleague or IT department. This is only possible for business owners to access the marketing department and save on advertising costs. For more information about this section, you can contact the support section of Telesiness.',
      'In this section, you can view the list of your distributors and communicate with them and their customers. You will also see the number of employees or their agents.':'In this section, you can view the list of your distributors and communicate with them and their customers. You will also see the number of employees or their agents.',
      'Distributors can be attracted to your home page and cooperate with the list of services and tariffs. Therefore, be sure to complete your services section with the best and fairest numbers and commissions.':'Distributors can be attracted to your home page and cooperate with the list of services and tariffs. Therefore, be sure to complete your services section with the best and fairest numbers and commissions.',
      'By clicking on the button + and adding it, you can invite your experienced and trustworthy people to cooperate.':'By clicking on the button + and adding it, you can invite your experienced and trustworthy people to cooperate.',
      'Page of Business Owner:':'Page of Business Owner:',
      'In this section, you will see the list of business owners that you invited. Collaborating with other business owners allows you to share a case or attract customers who are not in your field of work. You can also appoint managers on your files with the help of other colleagues in this section.':'In this section, you will see the list of business owners that you invited. Collaborating with other business owners allows you to share a case or attract customers who are not in your field of work. You can also appoint managers on your files with the help of other colleagues in this section.',
      ' My Services Page:':' My Services Page:',
      'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors. The pricing part and commissions are displayed only to distributors and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.':'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors. The pricing part and commissions are displayed only to distributors and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.',
      'The cost of your services is highly competitive and can affect the attraction of distributors and customers. Also, the commission you consider for distributors is highly competitive and will greatly affect your willingness to work with them.':'The cost of your services is highly competitive and can affect the attraction of distributors and customers. Also, the commission you consider for distributors is highly competitive and will greatly affect your willingness to work with them.',
      'The smarter you define “my service department”, the more customers you will be able to attract.':'The smarter you define “my service department”, the more customers you will be able to attract.',
      'Note: The costs and prices that you define in this section will not change under any conditions. Therefore, you should be careful when defining costs.':'Note: The costs and prices that you define in this section will not change under any conditions. Therefore, you should be careful when defining costs.',
      'Sharing the page of customers with colleague service owners:':'Sharing the page of customers with colleague service owners:',
      'If you share a file, their list can be seen here. Be sure the files you share on another home page of the service owner are for showing without any purpose, and he or she cannot delete or add information, or even communicate with the customer.':'If you share a file, their list can be seen here. Be sure the files you share on another home page of the service owner are for showing without any purpose, and he or she cannot delete or add information, or even communicate with the customer.',
      'In fact, this section is for managing customers, by managers or other service owners due to your opinion. Make sure that your colleagues or service owners are only persons that can view the files of the customers that you have shared with them and they cannot change your files and information of the customers.':'In fact, this section is for managing customers, by managers or other service owners due to your opinion. Make sure that your colleagues or service owners are only persons that can view the files of the customers that you have shared with them and they cannot change your files and information of the customers.',
      'Page of customers referred by distributors:':'Page of customers referred by distributors:',
      'In this section you can see the list of referred customers from your distributors and manage them.':'In this section you can see the list of referred customers from your distributors and manage them.',
      'Page of Messenger:':'Page of Messenger:',
      'Your page of messenger is defined smartly in order that you can set the time and quality of your performance. In this section you will see your contact list. The limitations imposed are the result of research and experience of your colleagues. So that the customers can send you a message once. After receiving a reply from you, they can send message again. Distributors can only text you three times continuously, and after the third message they have to wait for your reply to open the messages for them.':'Your page of messenger is defined smartly in order that you can set the time and quality of your performance. In this section you will see your contact list. The limitations imposed are the result of research and experience of your colleagues. So that the customers can send you a message once. After receiving a reply from you, they can send message again. Distributors can only text you three times continuously, and after the third message they have to wait for your reply to open the messages for them.',
      'You can send six continuous messages to your distributors and nine continuous messages to your customers. You can send unlimited messages to your service owner and colleague.':'You can send six continuous messages to your distributors and nine continuous messages to your customers. You can send unlimited messages to your service owner and colleague.',
      'Page of Services:':'Page of Services:',
      'In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.':'In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.',
      'Home Page Guide of Distributors':'Home Page Guide of Distributors',
      'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of four parts:':'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of four parts:',
      'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 40% of your total point.':'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 40% of your total point.',
      'Third part: Agent points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.':'Third part: Agent points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.',
      'Fourth part: The scores or points of distributor or service owner who give you points according to the amount of cooperation between each other and it will cover 20% of your total point in the calculations.':'Fourth part: The scores or points of distributor or service owner who give you points according to the amount of cooperation between each other and it will cover 20% of your total point in the calculations.',
      'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful distributors and the business owners will select you and to work with you.':'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful distributors and the business owners will select you and to work with you.',
      'The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also, you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.':'The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also, you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.',
      'After entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.':'After entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.',
      'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.':'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.',
      'Page of Service Owners:':'Page of Service Owners:',
      'In this section, you will see the list of service owner that you invited. This section is very important because if you have suitable and experienced service owners, you can attract more customers and expand your business.  So, you should invite the appropriate service owners and start a good interaction with them.':'In this section, you will see the list of service owner that you invited. This section is very important because if you have suitable and experienced service owners, you can attract more customers and expand your business.  So, you should invite the appropriate service owners and start a good interaction with them.',
      'In this section you will see a list of your distributors. You can have a list of partners in your network by attracting and training successful groups. They can introduce more customers to you by forming a network and attracting more agents.':'In this section you will see a list of your distributors. You can have a list of partners in your network by attracting and training successful groups. They can introduce more customers to you by forming a network and attracting more agents.',
      'Page of Agents:':'Page of Agents:',
      'Agents play a key role in your business. The more agents you have, the more customers you will have. You can also have a large network of agents by attracting and training the agents to attract a large number of customers.':'Agents play a key role in your business. The more agents you have, the more customers you will have. You can also have a large network of agents by attracting and training the agents to attract a large number of customers.',
      'Page of colleague distributor:':'Page of colleague distributor:',
      'In this section, you can specify services, administrative affairs and financial resources with your colleagues.':'In this section, you can specify services, administrative affairs and financial resources with your colleagues.',
      'Page of referred customers by distributor:':'Page of referred customers by distributor:',
      'In this section, you can see the list of agents referred by the distributors.':'In this section, you can see the list of agents referred by the distributors.',
      'Page of referred customers by agents:':'Page of referred customers by agents:',
      'In this section, you can see the list of customers referred by your agents.':'In this section, you can see the list of customers referred by your agents.',
      'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors and agents. The pricing part and commissions are displayed only to distributors and agents, and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.':'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors and agents. The pricing part and commissions are displayed only to distributors and agents, and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.',
      'The cost of your services is highly competitive and can affect the attraction of distributors and agents. Also, the commission you consider for distributors and agents is highly competitive and will greatly affect your willingness to work with them.':'The cost of your services is highly competitive and can affect the attraction of distributors and agents. Also, the commission you consider for distributors and agents is highly competitive and will greatly affect your willingness to work with them.',
      'Home Page Guide of Agents':'Home Page Guide of Agents',
      'Second part: It is the score or point that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 30% of your total point.':'Second part: It is the score or point that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 30% of your total point.',
      'Third part: It is the point of distributor giving you due to the services you provide for them and these points will cover 50% of your total points.':'Third part: It is the point of distributor giving you due to the services you provide for them and these points will cover 50% of your total points.',
      'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful agents and the business owners will select you and to work with you.':'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful agents and the business owners will select you and to work with you.',
      'In this section, you can completely see your customers’ files and also check your list of new customers.':'In this section, you can completely see your customers’ files and also check your list of new customers.',
      'Page of Service of Distributors:':'Page of Service of Distributors:',
      'In this section you will see the list of services and tarrif and introduce the customers by forming a network and attracting more agents.':'In this section you will see the list of services and tarrif and introduce the customers by forming a network and attracting more agents.',
      'You can send messages to your distributors and customers. You can send messages to the Passport section of Telesiness.':'You can send messages to your distributors and customers. You can send messages to the Passport section of Telesiness.',
      'Guide of Home Page for the Customer':'Guide of Home Page for the Customer',
      'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owner rests with you and the application will only help your relationship.':'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owner rests with you and the application will only help your relationship.',
      'In this part, you will see the agent and service owner related to your file.':'In this part, you will see the agent and service owner related to your file.',
      'In this part, the process of the file and list of documents depending on the type of you request are displayed at the bottom of the page and you can see them.':'In this part, the process of the file and list of documents depending on the type of you request are displayed at the bottom of the page and you can see them.',
      'My Request Page:':'My Request Page:',
      'In this section you can create your own immigration file':'In this section you can create your own immigration file',
      'First of all, you should create a file and enter you basic and general information. After the approval of the relevant service owner and determining the type of visa or your immigration route, the other facilities of departments will be activated for you.':'First of all, you should create a file and enter you basic and general information. After the approval of the relevant service owner and determining the type of visa or your immigration route, the other facilities of departments will be activated for you.',
      'In the following, after the service owner approves your file and documents, you will give your personal and legal documents to the service owner and he will access to your personal and legal documents by completing the required information. All your information and documents will be sent to the service owner, so you should be careful when completing the file and sending the documents.':'In the following, after the service owner approves your file and documents, you will give your personal and legal documents to the service owner and he will access to your personal and legal documents by completing the required information. All your information and documents will be sent to the service owner, so you should be careful when completing the file and sending the documents.',
      'Please upload the documents accurately and regularly in the specified locations. Failure to complete the forms and upload the documents in this section will be delayed the process of your file by the chosen service owner.':'Please upload the documents accurately and regularly in the specified locations. Failure to complete the forms and upload the documents in this section will be delayed the process of your file by the chosen service owner.',
      '1- All documents should be in PDF format.':'1- All documents should be in PDF format.',
      '2- All documents should be scanned correctly (Mobile scanning in not accepted).':'2- All documents should be scanned correctly (Mobile scanning in not accepted).',
      '3- All documents should have a maximum size of 4 MB.':'3- All documents should have a maximum size of 4 MB.',
      '4- All documents should be officially translated.':'4- All documents should be officially translated.',
      'Each document, regardless of the number of pages, must be in a file with the above conditions.':'Each document, regardless of the number of pages, must be in a file with the above conditions.',
      'By using the messenger section of the program, you can share your question or problem with your agent or service owner. After sending the message, you have to wait for his response. After replying, you can send the message again.':'By using the messenger section of the program, you can share your question or problem with your agent or service owner. After sending the message, you have to wait for his response. After replying, you can send the message again.',
      'In this section, you have access to all services and businesses and you can use any of the items that you need. In this section, you can also get acquainted with other business owners and cooperate with them.':'In this section, you have access to all services and businesses and you can use any of the items that you need. In this section, you can also get acquainted with other business owners and cooperate with them.',
      'All Customers':'All Customers',
      'Agents Customers':'Agents Customers',
      'DCs':'DCs',
      'Select a DS':'Select a DS',
      'Work Email':'Work Email',
      'Related Distributor Name':'Related Distributor Name',
      'Agent Name':'Agent Name',
      'Related lawyers':'Related lawyers',
      'Distributers Customers':'Distributers Customers',
      'Service Code':'Service Code',
      'Process Of Service':'Process Of Service',
      'Documents':'Documents',
      'Submit Date':'Submit Date',
      'Distributor Service Code':'Distributor Service Code',
      'Distributer Information':'Distributer Information',
      'Congratulations! your visa has been approved':'Congratulations! your visa has been approved',
      'your reqiured document is:':'your reqiured document is:',
      'Opss! We try with other Lawyer':'Opss! We try with other Lawyer',
      'Congratulations! your visa process Started be in touch':'Congratulations! your visa process Started be in touch',
      'your visa name is:':'your visa name is:',
      'your visa process is:':'your visa process is:',
      'Congratulations! Your Visa Accepted.':'Congratulations! Your Visa Accepted.',
      'Opss! Your Visa Rejected.':'Opss! Your Visa Rejected.',
      'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.':'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.',
      'Your New Collaboration Requests':'Your New Collaboration Requests',
      'DS: ':'DS: ',
      'DC offered to me:':'DC offered to me:',
      'My Offered to DC:':'My Offered to DC:',
      'I rejected:':'I rejected:',
      'DC rejected:':'DC rejected:',
      'List of Tariffs For Related DCs':'List of Tariffs For Related DCs',
      'MyOfferToOtherDis':'MyOfferToOtherDis',
      'Congratulations! Your Visa Accepted.(Telesiness Will be with you)':'Congratulations! Your Visa Accepted.(Telesiness Will be with you)',
      'Opss! Your Visa Rejected.(Telesiness Will be with you)':'Opss! Your Visa Rejected.(Telesiness Will be with you)',
      'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.(Telesiness Will be with you)':'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.(Telesiness Will be with you)',
      'Visitor Visa':'Visitor Visa',
      'Student Visa (University/ College)':'Student Visa (University/ College)',
      'Student Visa (Primary School, High School)':'Student Visa (Primary School, High School)',
      'Work Visa':'Work Visa',
      'Express Entry':'Express Entry',
      'Skilled Worker':'Skilled Worker',
      'Canadian Experience':'Canadian Experience',
      'Family Sponsership':'Family Sponsership',
      'Provincial Nominees':'Provincial Nominees',
      'Atlantic Immigration Program':'Atlantic Immigration Program',
      'Start-up Visa':'Start-up Visa',
      'Business Visa':'Business Visa',
      'Nurse Visa':'Nurse Visa',
      'Physician Visa':'Physician Visa',
      'Self Employed (Athletes and Artists,…)':'Self Employed (Athletes and Artists,…)',
      'Refugee':'Refugee',
      'Permanent Residency':'Permanent Residency',
      'Invitation Letter/ Conference/ Seminar':'Invitation Letter/ Conference/ Seminar',
      'Lawyer service information':'Lawyer service information',
      'Name of Service':'Name of Service',
      'There is no service assigned yet.':'There is no service assigned yet.',
      'Services assigned by the lawyer':'Services assigned by the lawyer',
      'Your Score in Telesiness Shows Your Activity':'Your Score in Telesiness Shows Your Activity',
      'Please be careful in filling the required fields of the two sections of personal information and further information':'Please be careful in filling the required fields of the two sections of personal information and further information',
      'Profile':'Profile',
      'Settings & Privacy':'Settings & Privacy',
      'Language':'Language',
      'Management':'Management',
      'Messenger Setting':'Messenger Setting',
      'Dashboard Setting':'Dashboard Setting',
      'Account Setting':'Account Setting',
      'Accounting':'Accounting',
      'Offers':'Offers',
      'According to this section of the guide, you can manage all sections.':'According to this section of the guide, you can manage all sections.',
      'You need to know that if all parts of your dashboard are not completed, your ability to cooperate and earn income will be greatly impacted reduced.':'You need to know that if all parts of your dashboard are not completed, your ability to cooperate and earn income will be greatly impacted reduced.',
      '1- Ask your lawyers or your distributors to specify the services that can be provided.':'1- Ask your lawyers or your distributors to specify the services that can be provided.',
      'a. In this case, you can define goals for your agents regarding the types of services and tariffs specified or give advice to customers in that field.':'a. In this case, you can define goals for your agents regarding the types of services and tariffs specified or give advice to customers in that field.',
      'b. By clicking the Vie Service button, you can see all the services provided by them from the main menu of the lawyer’s or distributor’s profile.':'b. By clicking the Vie Service button, you can see all the services provided by them from the main menu of the lawyer’s or distributor’s profile.',
      '2- After having a complete list of services of lawyers or distributors, you can enter the My Services section from the main menu.':'2- After having a complete list of services of lawyers or distributors, you can enter the My Services section from the main menu.',
      'a. In this section, you are free to enter the services provided with the same name and price or change them according to your marketing conditions (However, distributors and agents of your subcategory will see the list of your services and will not have access to the services provided by lawyers or developers that you are working with.)':'a. In this section, you are free to enter the services provided with the same name and price or change them according to your marketing conditions (However, distributors and agents of your subcategory will see the list of your services and will not have access to the services provided by lawyers or developers that you are working with.)',
      'b. Import your services and specify a rate and tariff for each one. According to your services, other distributors and agents will cooperate with you.':'b. Import your services and specify a rate and tariff for each one. According to your services, other distributors and agents will cooperate with you.',
      'c. Specify the commission of your agents. Keep in mind that all agents will see the entered commission in the same way. If you are satisfied with a specific agent, you can consider a higher commission for that specific agent from the main menu of the agent section. This process will only apply to that agent and other agents will not see this offer.':'c. Specify the commission of your agents. Keep in mind that all agents will see the entered commission in the same way. If you are satisfied with a specific agent, you can consider a higher commission for that specific agent from the main menu of the agent section. This process will only apply to that agent and other agents will not see this offer.',
      '3- Customer section':'3- Customer section',
      'a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.':'a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.',
      'b. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.':'b. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.',
      'c. Eighth button of the main menu (agent customers) is the list of customers who have been introduced to you by agents.':'c. Eighth button of the main menu (agent customers) is the list of customers who have been introduced to you by agents.',
      '1. In general, every customer who enters your dashboard, you have the right to refer them to the right lawyer according to the general conditions and the customer’s request, or if you don’t have a lawyer, refer them to your distributor.':'1. In general, every customer who enters your dashboard, you have the right to refer them to the right lawyer according to the general conditions and the customer’s request, or if you don’t have a lawyer, refer them to your distributor.',
      'a. Please note that the customer’s account will not be activated until the lawyer makes a final decision for the customer, and until activated, the lawyer will not have direct access to your customer’s contact information, nor will the customer see the lawyer’s information.':'a. Please note that the customer’s account will not be activated until the lawyer makes a final decision for the customer, and until activated, the lawyer will not have direct access to your customer’s contact information, nor will the customer see the lawyer’s information.',
      'b. After the initial approval of the lawyer, you can proceed with your work process to ensure the financial issues, then by pressing the approval button, both parties can communicate directly with each other.':'b. After the initial approval of the lawyer, you can proceed with your work process to ensure the financial issues, then by pressing the approval button, both parties can communicate directly with each other.',
      '4- Finally, try to increase the number of your connections. As the number of lawyers is higher, the number of services and the possibility of selling more products will increase. The more distributors and agents you have, the more customers you can attract and the higher your income.':'4- Finally, try to increase the number of your connections. As the number of lawyers is higher, the number of services and the possibility of selling more products will increase. The more distributors and agents you have, the more customers you can attract and the higher your income.',
      '5- Distributors can work directly with lawyers, but agents can only work with their own distributor and can only introduce customers to their distributor’s services. So, be careful about recruitment or the scope of authority of each person and act according to the conditions and rules set by Telesiness.':'5- Distributors can work directly with lawyers, but agents can only work with their own distributor and can only introduce customers to their distributor’s services. So, be careful about recruitment or the scope of authority of each person and act according to the conditions and rules set by Telesiness.',
      '6- Complete your profile. Increase the number of your users and teach your subordinates how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts':'6- Complete your profile. Increase the number of your users and teach your subordinates how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts',
      'Instructions':'Instructions',
      'c. You should also specify your service list at first.':'c. You should also specify your service list at first.',
      '2- After having a complete list of your services or services of lawyers or distributors, you can enter the My Services section from the main menu.':'2- After having a complete list of your services or services of lawyers or distributors, you can enter the My Services section from the main menu.',
      'a. In this section, you can enter a complete list of your partners or your services so that other distributors can market them for you (consider that all distributors will see the list of services and costs entered in the same way)':'a. In this section, you can enter a complete list of your partners or your services so that other distributors can market them for you (consider that all distributors will see the list of services and costs entered in the same way)',
      'b. According to the list of services, fees and commission rates, more distributors will be attracted to cooperate with you.':'b. According to the list of services, fees and commission rates, more distributors will be attracted to cooperate with you.',
      'a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.':'a.	Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.',
      'b. Sixth button of the main menu (Referral Customer) is the list of customers that you have shared with other lawyers.':'b. Sixth button of the main menu (Referral Customer) is the list of customers that you have shared with other lawyers.',
      'c. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.':'c. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.',
      '1. In general, every customer who enters your dashboard, you have the right to choose a service for them according to the general conditions and customer requests. When you choose a service for a customer, the description of that service will appear for the customer, and with this action, you will specify the type of customer’s visa.':'1. In general, every customer who enters your dashboard, you have the right to choose a service for them according to the general conditions and customer requests. When you choose a service for a customer, the description of that service will appear for the customer, and with this action, you will specify the type of customer’s visa.',
      'a. Consider that if the customer is from your distributor’s side, after choosing the service, you must wait for their approval.':'a. Consider that if the customer is from your distributor’s side, after choosing the service, you must wait for their approval.',
      'b. After your distributor approves, the customer’s contact information will appear for you. Also, your contact information will be known to the customer.':'b. After your distributor approves, the customer’s contact information will appear for you. Also, your contact information will be known to the customer.',
      'c. If you wish, you can share the client with your lawyer and colleague. In this case, you must add your colleague to the list of lawyers.':'c. If you wish, you can share the client with your lawyer and colleague. In this case, you must add your colleague to the list of lawyers.',
      '4- Finally, try to increase the number of your connections. The higher the number of your distributors, the higher the chance of attracting customers. Distributors can attract agents to sell your services. So, by giving enough information to the distributors, it is possible that the number of your marketers will be more and as a result you will have more customers.':'4- Finally, try to increase the number of your connections. The higher the number of your distributors, the higher the chance of attracting customers. Distributors can attract agents to sell your services. So, by giving enough information to the distributors, it is possible that the number of your marketers will be more and as a result you will have more customers.',
      '5- Complete your profile. Increase the number of your users and teach your employees how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts.':'5- Complete your profile. Increase the number of your users and teach your employees how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts.',
      '':'',
    },
    lng: 'en_US', // default language
    fallbackLng: 'en_US',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });
export default i18n;