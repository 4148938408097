export default {
    fa_IR: {
      Login: {
        'Sign In to Telesiness': 'ورود به تلزینس',
        'Email':'پست الکترونیک',
        'Remember me':'مرا به خاطر بسپار',
        'Forgot Password':'رمز عبور خود را فراموش کردید؟',
        'Login':'ورود',
        'or':'یا',
        'Sign Up Now':'ثبت نام کنید',
        'Services':'خدمات',
        'About':'درباره ما',
        'About us':'درباره ما',
        'Collaborations':'همکاری با ما',
        'Terms':'قوانین',
        'Help':'راهنما',
        'Contact Us':'تماس با ما',
        'Reset Your Password': 'بازیابی رمز عبور',
        'Send Link':'ارسال لینک',
        '< Back to Home page':' < بازگشت به صفحه اصلی',
        'Your messages: {{message_count}}': 'تعداد پیام‌های شما: {{message_count}}',
        'Submit':'ثبت',
        'Update':'بروزرسانی',
        'Changes were registered ':'تغییرات ثبت گردید! ',
        'Login now':'وارد شوید',
        'New Password':'رمز عبور جدید',
        'Confirm New Password':'تکرار رمز عبور جدید',
        'Sign Up To Telesiness':'ثبت نام در پنل کاری تلزینس',
        'Step 1':'مرحله 1',
        'Step 2':'مرحله 2',
        'Step 3':'مرحله 3',
        'Step 4':'مرحله 4',
        'Step 5':'مرحله 5',
        'Choose Your Account Type':'نوع اکانت خود را انتخاب کنید',
        'Complete your Personal information':'اطلاعات شخصی خود را کامل کنید',
        'Complete your Educational information':'اطلاعات تحصیلی خود را کامل کنید',
        'Complete your Job information':'اطلاعات شغلی خود را کامل کنید',
        'Choose Your Password':'برای حساب کاربری خود رمز عبور انتخاب کنید',
        '1 of 5':'1 از 5',
        '2 of 5':'2 از 5',
        '3 of 5':'3 از 5',
        '4 of 5':'4 از 5',
        '5 of 5':'5 از 5',
        'Lawyer':'وکیل',
        'Agent':'نماینده',
        'Customer':'مشتری',
        'First Name':'نام',
        'Last Name':'نام خانوادگی',
        'Phone Number':'تلفن همراه ',
        'Date of Birth:':'تاریخ تولد:',
        'about':'درباره ما',
        'Day':'روز',
        'Month':'ماه',
        'Year':'سال',
        'Next Step':'مرحله بعد',
        'Back':'برگشت',
        'January':'فروردین',
        'February':'اردیبشهت',
        'March':'خرداد',
        'April':'تیر',
        'May':'مرداد',
        'June':'شهریور',
        'July':'مهر',
        'August':'آبان',
        'September':'آذر',
        'October':'دی',
        'November':'بهمن',
        'December':'اسفند',
        'Field of Study':'رشته تحصیلی',
        'Institute / University':'موسسه/دانشگاه',
        'Level / Degree of education':'سطح/مدرک تحصیلی',
        'Less than high school diploma':'زیردیپلم',
        'Diploma':'دیپلم',
        'Pre-university':'پیش دانشگاهی',
        'High school diploma':'دیپلم متوسطه',
        'Some college, no degree':'تحصیلات دانشگاهی بدون مدرک',
        'Associate degree':'کاردانی/فوق دیپلم',
        'Bachelor degree':'لیسانس/کارشناسی',
        'Master degree':'فوق لیسانس/کارشناسی ارشد',
        'Professional  degree':'مدرک حرفه ای',
        'Doctoral degree':'دکتری',
        'Country':'کشور',
        'City':'شهر',
        'Iran':'ایران',
        'England':'انگلستان',
        'Turkey':'ترکیه',
        'UAE':'امارات متحد عربی',
        'USA':'آیالت متحده آمریکا',
        'Canada':'کانادا',
        'Paris':'پاریس',
        'Tehran':'تـــــهران',
        'California':'کالیفرنیا',
        'Toronto':'تورنتو',
        'Istanbul':'استانبول',
        'Job Specialty':'زمینه کاری',
        'Specialization Field in Immigration Issues':'زمینه ی تخصصی در امور مهاجرت',
        'Law society number/ICCRC Code':'کد وکالت',
        'Personal or company website':'آدرس وبسایت شخصی یا کاری',
        'Certificate':'گواهینامه',
        'Certificate...':'گواهینامه...',
        'Upload your Resume/CV':'رزومه ی خود را بارگذاری کنید',
        'Welcome':'خوش آمدید',
        'Home':'صفحه اصلی',
        'Customers':'مشتریان',
        'Agents':'نمایندگان',
        'My Prices':'تعرفه های من',
        'Messages':'پیام ها',
        'Gender':'جنسیت',
        'Date of birth':'تاریخ تولد',
        'Phone':'شماره تلفن',
        'Address Information':'اطلاعات محل سکونت',
        'Save':'ذخیره',
        'Cancel':'لغو',
        'No':'پلاک',
        'Address':'آدرس',
        'Postal Code':'کدپستی',
        'Educational Information':'اطلاعات تحصیلی',
        'Level / Degree':'آخرین مدرک تحصیلی',
        'Job Information':'اطلاعات شغلی',
        'Upload Resume/CV':'بارگذاری رزومه',
        'Website Address':'آدرس وب سایت',
        'Account Information':'اطلاعات حساب کاربری',
        'Username':'نام کاربری',
        'Change Password':'تغییر رمز عبور',
        'You changed these fields before ... ':'شما قبلا یکبار این فیلدها را تغییر داده اید',
        'Search here':'جستجو...',
        'Help Center':'مرکز پشیبانی',
        'ID':'کد',
        'Phone Number':'شماره تماس',
        'Status':'وضعیت',
        'Created':'تاریخ',
        '+ Add New Customer':'+ افزودن مشتری',
        'View Profile':'مشاهده پروفایل',
        'Coming Soon':'به زودی',
        'FAQs':'سوالات متداول',
        'Settings':'تنظیمات',
        'Sign Out':'خروج',
        'Deactive':'غیرفعال',
        'Active':'فعال',
        'Active Users':'کاربران فعال',
        'Deactive Users':'کاربران غیرفعال',
        'Why do You want to De-active this case?':'به چه علت قصد غیرفعال کردن این پرونده را دارید؟',
        'Please select the reason for deactivating this case.':'لطفا دلیل غیرفعالسازی این پرونده را انتخاب نمایید.',
        'Visa':'ویزا',
        'Rejected':'رد شده',
        'Declined':'انصراف از پرونده',
        'Lawyer List':'لیست وکلا',
        'Customers Name':'نام مشتریان',
        'Clients Name':'نام مشتریان',
        'Please Pay Attention dear user!':'کاربر عزیز توجه کنید!',
        'You Can edit your information JUST ONE TIME!!!':'شما فقط یک بار می توانید اطلاعات خود را ویرایش نمایید!!!',
        'We will send a link to ':' ما یک ایمیل برای تغییر رمز عبور به ',
        ' for reset your password':' ارسال میکنیم ',
        'Username':'نام کاربری',
        'Clients':'مشتری',
        '+ Add New Agent':'+ افزودن نماینده',
        'Prices Information':'اطلاعات تعرفه ها',
        'Service':'خدمات',
        'Offer':'درصد',
        'Public Offer':'درصد عمومی',
        'Price':'تعرفه',
        'Type a price here...':'...تعرفه را وارد کنید',
        'Fee...':'سهم تلزینس',
        '+ Add Service':'افزودن +',
        'Type about your service...':'...توضیحات خدمات خود را وارد کنید',
        '$':'ریال',
        'Password':'رمز عبور...',
        'type your password here...':'رمز عبور...',
        'type your email...':'ایمیل خود را وارد کنید...',
        'You are almost there!':'فرآیند ثبت نام تقریبا به اتمام رسید',
        'Check Your Email':'!صندوق پست الکترونیکی خود را بررسی نمایید',
        'Done!':'!تکمیل شد',
        '2 of 3':'2 از 3',
        'Passport No / National Number / ID Num':'شماره پاسپورت/ کد ملی',
        '3 of 3':'3 از 3',
        'Enter your username and password in box below to log in And have access to a world of immigration facilities':'نام کاربری و رمز عبور خود را در کادر زیر وارد کنید تا به تلزینس وارد شوید و به دنیایی از امکانات مهاجرت دسترسی داشته باشید',
        'Enter your email address signed up below in the box below to receive a link to reset your password.':'آدرس پست الکترونیکی که با آن در تلزینس ثبت نام کردید را برای دریافت لینک بازیابی رمزعبور در کادر زیر وارد کنید',
        'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'رمز عبور باید حداقل 8 کاراکتر باشد. به یاد داشته باشید که استفاده مجدد از نام کاربری و رمز عبور برای خدمات و وب سایت های مختلف توصیه نمی شود',
        'Your password has been successfully changed. Now you can enter with your new password.':'رمز عبور شما با موفقیت تغییر یافت. اکنون می توانید با رمز عبور جدید وارد شوید',
        'Access your specific features by selecting appropriate user type.':'با انتخاب نوع کاربری صحیح به امکانات اختصاصی خود دسترسی داشته باشید',
        'Please help know you better by completing your personal information.':'با تکمیل اطلاعات شخصی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us know you better by completing your educational information.':'با تکمیل اطلاعات تحصیلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us know you better by completing your job information.':'با تکمیل اطلاعات شغلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'برای ورود به پروفایل خود نام کاربری و رمز عبور مناسب انتخاب کنید تا بتوانید به راحتی به داشبورد تلزینس دسترسی داشته باشید',
        'Your registration was successful. You already have access to a variety of immigration opportunities.':'ثبت نام شما با موفقیت انجام شد. شما از الان به دنیایی از امکانات مهاجرتی دسترسی دارید',
        'Go to main page':'برو به صفحه اصلی',
        'Dear user, your information has been successfully registered in our database':'کاربر گرامی اطلاعات شما با موفقیت در بانک اطلاعاتی ما ثبت گردید',
        'Our experts will contact you as soon as possible.':'کارشناسان ما پس از بررسی در اسرع وقت با شما تماس خواهند گرفت',
        'Certificate':'گواهی نامه ',
        'License':'مجوز کار ',
        'Distributor':'توسعه دهنده',
        'Your Code is Valid, It is registered in the system':'کد معتبر می باشد. اطلاعات در سامانه ثبت گردیده است',
        'Your Code is Invalid, It is not registered in our database.':'کد نا معتبر است. اطلاعات در سامانه ثبت نگردیده است',
        'Full name: ':'نام کامل : ',
        'Position: ':'سمت : ',
        'I got it.':'متوجه شدم',
        'I Got it':'متوجه شدم',
        'Search by Telesiness Code':'استعلام کد تلزینس',
        'Find':'جستجو',
        'Inquiry':'استعلام',
        'Lawyer or Distributor':'وکیل یا توسعه دهنده',
        'Menu Item Will Be Active After Reviewing Your Profile By Our Experts':'گزینه های منو بعد از فعال شدن اکانت شما نمایش داده خواهند شد',
        'My Requests':'درخواستهای من',
        'Are You Sure?':'آیا اطمینان دارید؟',
        'You choose the selection of lawyer by Telesiness':'شما انتخاب وکیل توسط تلزینس را برگزیدید ',
        'You choose the lawyer by ':'وکیل شما ',
        'Send Request':'ارسال درخواست',
        'Advocacy Code':'کد وکالت',
        'Agent commission':'سهم نماینده',
        'Distributer commission':'سهم توسعه دهنده',
        'See more':'اعلان های بیشتر',
        'Agent Percent':'درصد نماینده',
        'Percent':'درصد',
        'Please enter the email of the lawyer you want to cooperate with.':'لطفا ایمیل وکیل موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Distributer you want to cooperate with.':'لطفا ایمیل توسعه دهنده موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Agent you want to cooperate with.':'لطفا ایمیل نماینده موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Customer you want to cooperate with.':'لطفا ایمیل مشتری موردنظر را جهت همکاری وارد نمایید.',
        'Please Select Your Co-Lawyers for Your Customer.':'لطفاً وکلای همکار خود را برای مشتری خود انتخاب کنید.',
        'ADD BY Email Address':'افزودن با آدرس ایمیل',
        'Lawyers Name':'نام وکلا',
        'Adding a Lawyer for Customer':'افزودن وکیل برای مشتری',
        'Enter the applicants Lawyers':'وکلا متقاضی را وارد کنید',
        'Enter the applicants Lawyers Num 1':'متقاضیان وکلای شماره 1 را وارد کنید',
        'Enter the applicants Lawyers Num 2':'متقاضیان وکلای شماره 2 را وارد کنید',
        'Enter the applicants Lawyers Num 3':'متقاضیان وکلای شماره 3 را وارد کنید',
        'Enter the applicants Lawyers Num 4':'متقاضیان وکلای شماره 4 را وارد کنید',
        'Enter the applicants Lawyers Num 5':'متقاضیان وکلای شماره 5 را وارد کنید',
        'Enter the applicants Lawyers Num 6':'متقاضیان وکلای شماره 6 را وارد کنید',
        '+ Add New Distributer':'+ افزودن توسعه دهنده',
        '+ Lawyer':'+ وکیل',
        'Add Lawyer':'افزودن وکیل',
        'Lawyer Name':'نام وکیل',
        'Lawyer 1 Name...':'نام وکیل شماره 1...',
        'Lawyer 2 Name...':'نام وکیل شماره 2...',
        'Lawyer 3 Name...':'نام وکیل شماره 3...',
        'Lawyer 4 Name...':'نام وکیل شماره 4...',
        'Lawyer 5 Name...':'نام وکیل شماره 5...',
        'Lawyer 6 Name...':'نام وکیل شماره 6...',
        'Download Form':'فرم دانلود',
        'Customer Code':'کد مشتری',
        'All Done!':'همه انجام شد!',
        'Applicant Personal Information':'اطلاعات شخصی متقاضی',
        'Applicant Language Information':'اطلاعات زبان متقاضی',
        'Email Address':'آدرس ایمیل',
        'Type your Email address...':'ایمیل آدرس تان را بنویسید...',
        '+ Add New Lawyer':'+ افزودن وکیل',
        'Completed Files':'پرونده های انجام شده',
        'Canceled Files':'پرونده های لغو شده',
        'In Progress Files':'پرونده های در حال انجام',
        'Latest Customers':'مشتریان اخیر',
        'Latest Agents':'نمایندگان اخیر',
        'Adding a new Customer':'افزودن مشتری',
        'Adding a new Agent':'افزودن نماینده',
        'Agency Code':'کد نمایندگی',
        'Latest Lawyers':'وکلا اخیر',
        'Adding a New Lawyer':'افزودن وکیل',
        'Registery Date':'تاریخ عضویت',
        'Lawyer Information':'اطلاعات وکیل',
        'There is no information to display':'اطلاعاتی برای نمایش وجود ندارد!',
        'You don’t have any request yet':'شما هنوز درخواستی ندارید',
        '+ Register new Request':'+ ثبت درخواست جدید',
        'Submit a request':'تشکیل پرونده',
        'Fill in all the required information carefully to follow-up your request process':'برای پیگیری بهتر روند درخواست خود، اطلاعات زیر را با دقت تکمیل نمایید',
        'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.':'در قدم اول برای تشکیل پرونده ی خود، اطلاعات اولیه را با دقت تکمیل نمایید، سپس بعد از تایید اطلاعات توسط کارشناسان ما برای تکمیل پرونده ی خود اقدام نمایید',
        'Basic Information':'اطلاعات اولیه',
        'Applicant Information':'اطلاعات متقاضی',
        'Spouse Information':'اطلاعات همســر',
        'Couples Information':'اطلاعات زوجین',
        'Children Information':'اطلاعات فرزندان',
        'Children':'فرزندان',
        'Other':'اطلاعات دیگر',
        'back to My Requests':'بازگشت به لیست درخواست ها',
        'Male':'مرد',
        'Female':'زن',
        'Complete Basic Information':'تکمیل اطلاعات اولیه',
        'Personal Information':'اطلاعات شخصی',
        'Enter the applicant’s First Name in Persian':'نام متقاضی را به فارسی وارد نمایید',
        'Enter the applicant’s Last Name in Persian':'نام خانوادگی متقاضی را به فارسی وارد نمایید',
        'ID Number':'کدملی',
        'Enter the applicant’s ID Number':'کد ملی متقاضی را وارد نمایید',
        'National Code':'شماره شناسنامه',
        'Enter the applicant’s national code':'شماره شناسنامه متقاضی را وارد نمایید',
        'E-mail':'ایمیل',
        'Enter the applicant’s Email address':'ایمیل متقاضی را وارد نمایید',
        'Call Number':'شماره ثابت',
        'Enter the applicant’s Call number':'شماره تلفن ثابت متقاضی را وارد نمایید',
        'Enter the applicant’s Phone number':'شماره همراه متقاضی را وارد نمایید',
        'Select the applicant’s Country':'کشور متقاضی را انتخاب نمایید',
        'Enter the applicant’s Country':'کشور متقاضی را وارد نمایید',
        'Enter the applicant’s address':'آدرس محل سکونت متقاضی را وارد نمایید',
        'Postal Code':'کدپستی',
        'Enter the applicant’s Postal Code':'کدپستی محل سکونت متقاضی را وارد نمایید',
        'place of Birth':'محل تولد',
        'Enter the applicant’s place of birth':'محل تولد متقاضی را وارد نمایید',
        'Enter the applicant’s date of birth':'تاریخ تولد متقاضی را به هجری شمسی وارد نمایید',
        'Your file has been successfully registered':'پرونده ی شما با موفقیت ثبت شد',
        'Please be patient until Your information is reviewed and approved by our experts, and then complete your information.':'لطفا تا زمان بررسی و تایید اطلاعات خود توسط کارشناسان ما صبور باشید و پس از آن نسبت به تکمیل اطلاعات خود اقدام نمایید',
        'Tracking Code':'کدپیگیری',
        'Go to requests':'برو به درخواست ها',
        'Visa Type':'نوع ویزا',
        'Situation':'وضعیت',
        'Destination':'مقصد',
        'Code':'کد پیگیری',
        'Creation Date':'تاریخ ایجاد',
        'Back to Submitting a request':'بازگشت به تشکیل پرونده',
        'Needs to be completed':'نیازمند تکمیل',
        'Completed':'تکمیل شده',
        'In progress':'در حال تکمیل',
        'Passport Information':'اطلاعات پاسپورت',
        'Financial Information':'اطلاعات مالی',
        'Foreign travel Information':'اطلاعات سفر های خارجی',
        'Military Information':'اطلاعات پایان خدمت',
        'Marriage Information':'اطلاعات ازدواج',
        'Family Information':'اطلاعات خانواده',
        'Requested Visa Information':'اطلاعات ویزای درخواستی',
        'Identical Information':'مدارک هویتی',
        'National Card Image(Front)':'تصویر روی کارت ملی',
        'National Card Image(Behind)':'تصویر پشت کارت ملی',
        'Identity card Image(First Page)':'صفحه اول شناسنامه',
        'Identity card Image(Second Page)':'صفحه دوم شناسنامه',
        'Identity card Image(Last Page)':'صفحه آخر شناسنامه',
        'Complete Passport Information':'اطلاعات پاسپورت را تکمیل نمایید',
        'Pasport Information':'اطلاعات پاسپورت',
        'Passport Number':'شماره پاسپورت',
        'Enter the applicant’s Passport Number':'شماره پاسپورت متقاضی را وارد نمایید',
        'Name':'نام',
        'Enter the applicant’s Name in the Passport':'نام درج شده در پاسپورت را به انگلیسی وارد نمایید',
        'Family':'نام خانوادگی',
        'Enter the applicant’s Family in the Passport':'نام خانوادگی درج شده در پاسپورت را به انگلیسی وارد نمایید',
        'Enter the applicant’s Passport Creation Date':'تاریخ میلادی صدور پاسپورت را وارد نمایید',
        'Expiration Date':'تاریخ انقضا',
        'Enter the applicant’s Passport Expiration Date':'تاریخ میلادی خاتمه پاسپورت متقاضی را وارد نمایید',
        'Passport Creation Place':'محل صدور پاسپورت',
        'Enter the applicant’s Passport Creation Place':'محل صدور پاسپورت متقاضی را وارد نمایید',
        'Country of Residence':'کشور اقامت',
        'Country of residence stated in the passport':'کشوری که در پاسپورت محل اقامت شما است',
        'Enter the applicant’s Date of birth On Passport':'تاریخ تولد میلادی قید شده در پاسپورت',
        'Passport Documentations':'مدارک پاسپورت',
        'Passport Image':'تصویر پاسپورت',
        'Personal Picture in Pass':'عکس پرسنلی داخل پاسپورت',
        'Sample Passport Signature':'نمونه امضا پاسپورت',
        'Place of Issuance of National Card':'محل صدور کارت ملی',
        'Enter the applicant’s Place of Issuance of National Card':'محل صدور کارت ملی متقاضی را وارد نمایید',
        'Birth Certificate Number':'شماره شناسنامه',
        'Enter the applicant’s Birth Certificate Number':'شماره شناسنامه متقاضی را وارد نمایید',
        'Father’s Name on the Birth Certificate':'نام پدر در شناسنامه',
        'Enter the applicant’s Fathers Name on the Birth Certificate':'نام پدر متقاضی را مطابق با شناسنامه وارد نمایید',
        'Mother’s Name on the Birth Certificate':'نام مادر در شناسنامه',
        'Enter the applicant’s Mothers Name on the Birth Certificate':'نام مادر متقاضی را مطابق با شناسنامه وارد نمایید',
        'Alias Name or Surname or changed in the identity card':'نام یا نام خانوادگی مستعار یا تغییر داده شده در شناسنامه',
        'Applicant’s Alias Name,Surname or changed in the identity card':'نام یا نام خانوادگی مستعار یا تغییر داده شده در شناسنامه را وارد نمایید',
        'Military Card Number':'شماره کارت پایان خدمت',
        'Enter the applicant’s Military Card Number':'شماره کارت پایان خدمت متقاضی را وارد نمایید',
        'Date of Issuance of Military Card':'تاریخ صدور پایان خدمت',
        'Enter the applicant’s Passport Creation Date':'تاریخ صدور پایان خدمت متقاضی را انتخاب نمایید.',
        'Type of Military Card':'نوع پایان خدمت',
        'Enter the applicant’s Type of Military Card':'نوع پایان خدمت متقاضی را وارد نمایید.',
        'Place of Military Service':'محل خدمت',
        'Enter the applicant’s Place of Military Service':'محل خدمت متقاضی را وارد نمایید',
        'Height':'قد',
        'Enter the applicant’s Height':'قد متقاضی را به سانتی متر وارد نمایید.',
        'Skin Color':'رنگ پوست',
        'Enter the applicant’s Skin Color':'رنگ پوست متقاضی را انتخاب نمایید',
        'Eye Color':'رنگ چشم',
        'Enter the applicant’s Eye Color':'رنگ چشم متقاضی را انتخاب نمایید',
        'Postal Code One':'کدپستی (1)' ,
        'Enter the applicant’s Postal Code (Place One - Required)':'کدپستی اصلی متقاضی را وارد نمایید.',
        'Address One':'آدرس (1)',
        'Enter the applicant’s address (Place One - Required)':'آدرس اصلی متقاضی را وارد نمایید (1)',
        'Address Two':'آدرس (2)',
        'Enter the applicant’s address (Place Two)':'آدرس متقاضی را وارد نمایید(2)',
        'Postal Code Two':'کدپستی (2)',
        'Enter the applicant’s Postal Code (Place Two)':'کدپستی متقاضی را وارد نمایید (2)',
        'Workplace Call Number':'شماره تلفن محل کار',
        'Enter the applicant’s Workplace Call Number':'شماره تلفن محل کار متقاضی را وارد نمایید',
        'Type of Marriage':'وضعیت تاهل',
        'Enter the applicant’s Type of Marriage':'نوع تاهل متقاضی را انتخاب نمایید',
        'Evaluation Form Tracking Number':'شماره رهگیری فرم ارزیابی',
        'Enter the applicant’s Evaluation Form Tracking Number':'کدرهگیری موجود در فرم ارزیابی را وارد نمایید',
        'Call Number (Two)':'شماره تلفن ثابت (2)',
        'Enter the applicant’s Call number (Second Number Optional)':'شماره تماس دیگری از متقاضی در صورت وجود وارد نمایید',
        'Phone Number (Two)':'شماره تلفن همراه (2)',
        'Enter the applicant’s Phone number (Second Number Optional)':'در صورت وجود شماره همراه دوم متقاضی را وارد نمایید.',
        'Workplace Call Number (Two)':'شماره تماس محل کار (2)',
        'Enter the applicant’s Workplace Call Number (Optional)':'شماره دیگری از محل کار متقاضی در صورت وجود وارد نمایید.',
        'Yellow':'زرد',
        'White':'سفید',
        'Red':'قرمز',
        'Brunette':'سبزه',
        'Tan':'برنزه',
        'Black':'سیاه',
        'Green':'سبز',
        'Gray':'خاکستری',
        'Blue':'آبی',
        'Brown':'قهوه ای',
        'Amber':'کهربایی',
        'Honey-Colored':'عسلی',
        'Married':'متاهل',
        'Single':'مجرد',
        'Identical Documents':'اسناد هویتی',
        'Military service card':'کارت پایان خدمت',
        'Lack of background':'فرم عدم سوء پیشینه',
        'Certificate or any other ID Card':'گواهینامه یا هر مدرک شناسایی دیگر',
        'Evaluation Form':'فرم ارزیابی',
        'Step 2-1':'مرحله 1 از 2',
        'Applicant Foreign Language Information':'اطلاعات زبان خارجه متقاضی',
        'Language Certifications':'مدارک زبان اخذ شده',
        'English Language Status':'وضعیت زبان انگلیسی',
        'I Have':'دارم',
        'I do not have':'ندارم',
        'I am reading':'در حال خواندن هستم',
        'I will be able to get a degree in the next 6 months':'توانایی اخذ مدرک تا ۶ ماه آینده را دارم',
        'I will not be able to get a degree for the next 8 months':'توانایی اخذ مدرک تا ۸ ماه آینده را ندارم',
        'Enter the applicant’s English Language Status':'وضعیت زبان متقاضی را انتخاب نمایید',
        'IELTS':'آیلتس',
        'Date of IELTS degree':'تاریخ اخذ آیلتس',
        'Enter the applicant’s Date of IELTS degree':'تاریخ اخذ مدرک آیلتس متقاضی را وارد نمایید.',
        'The total score of the degree':'نمره کل اخذ شده',
        'Enter the applicant’s total score of the degree':'نمره کل آزمون آیلتس را وارد نمایید',
        'IELTS Reading Score':'نمره ریدینگ آیلتس',
        'Enter the applicant’s IELTS Reading Score':'نمره ریدینگ آیلتس را وارد نمایید',
        'IELTS Writing Score':'نمره رایتینگ آیلتس',
        'Enter the applicant’s IELTS Writing Score':'نمره رایتینگ آیلتس را وارد نمایید',
        'IELTS Listening Score':'نمره لیسنینگ آیلتس',
        'Enter the applicant’s IELTS Listening Score':'نمره لیسنینگ آیلتس را وارد نمایید.',
        'IELTS Speacking Score':'نمره اسپیکینگ',
        'Enter the applicant’s IELTS Speacking Score':'نمره اسپیکینگ آیلتس را وارد نمایید',
        'TOEFL':'تافل',
        'Enter the applicant’s Date of Other Language degree':'تاریخ اخذ مدرک را وارد نمایید',
        'Date of TOEFL degree':'تاریخ اخذ مدرک تافل',
        "Enter the applicant’s Field of Study":"رشته تحصیلی متقاضی را وارد کنید",
        'Enter the applicant’s Date of TOEFL degree':'تاریخی که مدرک تافل گرفته اید را وارد نمایید.',
        'The total score of the degree':'نمره کل اخذ شده',
        'Enter the applicant’s total score of the degree':'نمره کل آزمون تافل را وارد نمایید',
        'TOEFL Reading Score':'نمره ریدینگ تافل',
        'Enter the applicant’s TOEFL Reading Score':'نمره ریدینگ تافل را وارد نمایید',
        'TOEFL Writing Score':'نمره رایتینگ تافل',
        'Enter the applicant’s TOEFL Writing Score':'نمره رایتینگ تافل را وارد نمایید',
        'TOEFL Listening Score':'نمره لیسنینگ تافل',
        'Enter the applicant’s TOEFL Listening Score':'نمره لیسنینگ تافل را وارد نمایید.',
        'TOEFL Speacking Score':'نمره اسپیکینگ',
        'Enter the applicant’s TOEFL Speacking Score':'نمره اسپیکینگ تافل را وارد نمایید',
        'Other Language (1)':'مدرک زبان دیگر (1)',
        'The total score of the Other Language degree(1)':'نمره کل مدرک زبان دیگر (1)',
        'Enter the applicant’s total score of the Other Language degree(1)':'نمره کل مدرک زبان متقاضی را وارد نمایید (1)',
        'Other Language Reading Score(1)':'نمره ریدینگ زبان دیگر (1)',
        'Enter the applicant’s Other Language Reading Score(1)':'نمره ریدینگ زبان متقاضی را وارد نمایید (1)',
        'Other Language Writing Score(1)':' نمره رایتینگ زبان دیگر (1)',
        'Enter the applicant’s Other Language Writing Score(1)':'نمره رایتینگ زبان متقاضی را وارد نمایید (1)',
        'Other Language Listening Score(1)':'نمره لیسینینگ زبان دیگر (1)',
        'Enter the applicant’s Other Language Listening Score(1)':'نمره لیسینینگ زبان متقاضی را وارد نمایید (1)',
        'Other Language Speacking Score(1)':'نمره اسپیکینگ زبان دیگر (1)',
        'Enter the applicant’s Other Language Speacking Score(1)':'نمره اسپیکینگ زبان متقاضی را وارد نمایید (1)',
        'Other Language (2)':'مدرک دیگر (2)',
        'The total score of the Other Language degree(2)':'نمره کل مدرک زبان دیگر (2)',
        'Enter the applicant’s total score of the Other Language degree(2)':'نمره کل مدرک زبان متقاضی را وارد نمایید (2)',
        'Other Language Reading Score(2)':'نمره ریدینگ زبان دیگر (2)',
        'Enter the applicant’s Other Language Reading Score(2)':'نمره ریدینگ زبان متقاضی را وارد نمایید (2)',
        'Other Language Writing Score(2)':' نمره رایتینگ زبان دیگر (2)',
        'Enter the applicant’s Other Language Writing Score(2)':'نمره رایتینگ زبان متقاضی را وارد نمایید (2)',
        'Other Language Listening Score(2)':'نمره لیسینینگ زبان دیگر (2)',
        'Enter the applicant’s Other Language Listening Score(2)':'نمره لیسینینگ زبان متقاضی را وارد نمایید (2)',
        'Other Language Speacking Score(2)':'نمره اسپیکینگ زبان دیگر (2)',
        'Enter the applicant’s Other Language Speacking Score(2)':'نمره اسپیکینگ زبان متقاضی را وارد نمایید (2)',
        'Other Language (3)':'مدرک دیگر (3)',
        'The total score of the Other Language degree(3)':'نمره کل مدرک زبان دیگر (3)',
        'Enter the applicant’s total score of the Other Language degree(3)':'نمره کل مدرک زبان متقاضی را وارد نمایید (3)',
        'Other Language Reading Score(3)':'نمره ریدینگ زبان دیگر (3)',
        'Enter the applicant’s Other Language Reading Score(3)':'نمره ریدینگ زبان متقاضی را وارد نمایید (3)',
        'Other Language Writing Score(3)':' نمره رایتینگ زبان دیگر (3)',
        'Enter the applicant’s Other Language Writing Score(3)':'نمره رایتینگ زبان متقاضی را وارد نمایید (3)',
        'Other Language Listening Score(3)':'نمره لیسینینگ زبان دیگر (3)',
        'Enter the applicant’s Other Language Listening Score(3)':'نمره لیسینینگ زبان متقاضی را وارد نمایید (3)',
        'Other Language Speacking Score(3)':'نمره اسپیکینگ زبان دیگر (3)',
        'Enter the applicant’s Other Language Speacking Score(3)':'نمره اسپیکینگ زبان متقاضی را وارد نمایید (3)',
        'Date of Other Language degree (1)':'تاریخ اخذ مدرک زبان (1)',
        'Date of Other Language degree (2)':'تاریخ اخذ مدرک زبان (2)',
        'Date of Other Language degree (3)':'تاریخ اخذ مدرک زبان (3)',
        'Foreign Language Documents':'مدارک زبان خارجه',
        'Other Language(1)':'مدرک دیگر زبان (1)',
        'Other Language(2)':'مدرک دیگر زبان (2)',
        'Other Language(3)':'مدرک دیگر زبان (3)',
        'Language Certificates':'گواهینامه های زبان',
        'Back to Applicant information':'برگشت به قسمت اطلاعات متقاضی',
        'Step 2-2':'مرحله 2 از 2',
        'Applicant Educational Information':'اطلاعات تحصیلی متقاضی',
        'Last Degree and Field of Study':'آخرین مدرک و رشته تحصیلی',
        'Last Educational Certificate':'آخرین مدرک تحصیلی',
        'Enter the applicant’s Last Educational Certificate':'آخرین مدرک تحصیلی متقاضی را وارد نمایید',
        '(Required)':'(الزامی)',
        'Enter the applicant’s Field of Study':'رشته تحصیلی متقاضی را وارد نمایید.',
        'High School':'دبیرستان',
        'Date of Entering High School':'تاریخ ورود به دبیرستان',
        'Enter the applicant’s Date of Entering High School':'تاریخ ورود به دبیرستان را وارد نمایید.',
        'Graduation Date':'تاریخ فارغ التحصیلی',
        'Enter the applicant’s Graduation Date':'تاریخ فارغ التحصیلی متقاضی را وارد نمایید',
        'Field of Study in Diploma':'رشته تحصیلی دیپلم',
        'Enter the applicant’s Field of Study in Diploma':'رشته تحصیلی دیپلم متقاضی را وارد نمایید.',
        'Associate Degree':'فوق دیپلم',
        'Date of Entering University':'تاریخ ورود به دانشگاه',
        'Enter the applicant’s Date of Entering University':'تاریخ ورود به دانشگاه را وارد نمایید',
        'University Name':'نام دانشگاه',
        'Enter the applicant’s University Name':'نام دانشگاه متقاضی را وارد نمایید',
        'University Plcae':'محل دانشگاه',
        'Enter the applicant’s University Plcae':'محل دانشگاه متقاضی را وارد نمایید',
        'Bachelor Degree':'لیسانس',
        'Masters Degree':'فوق لیسانس',
        'P.H.D Degree':'دکتری',
        'Post-Doctoral Degree':'فوق دکتری',
        'Non-university Certificate':'مدارک غیر دانشگاهی',
        'Non-university Certificate Name(1)':'نام مدرک غیر دانشگاهی (1)',
        'Enter the applicant’s Non-university Certificate Name(1)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (1)',
        'Non-university Certificate Name(2)':'نام مدرک غیر دانشگاهی (2)',
        'Enter the applicant’s Non-university Certificate Name(2)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (2)',
        'Non-university Certificate Name(3)':'نام مدرک غیر دانشگاهی (3)',
        'Enter the applicant’s Non-university Certificate Name(3)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (3)',
        'Non-university Certificate Name(4)':'نام مدرک غیر دانشگاهی (4)',
        'Enter the applicant’s Non-university Certificate Name(4)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (4)',
        'Non-university Certificate Name(5)':'نام مدرک غیر دانشگاهی (5)',
        'Enter the applicant’s Non-university Certificate Name(5)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (5)',
        'Non-university Certificate Name(6)':'نام مدرک غیر دانشگاهی (6)',
        'Enter the applicant’s Non-university Certificate Name(6)':'نام مدرک غیردانشگاهی متقاضی را وارد نمایید (6)',
        'Educational Documents':'مدارک تحصیلی - آموزشی',
        'Pre-High School Documents (1)':'مدارک قبل از دبیرستان (1)',
        'Pre-High School Documents (2)':'مدارک قبل از دبیرستان (2)',
        'Pre-High School Documents (3)':'مدارک قبل از دبیرستان (3)',
        'High School (transcripts) 1th':'ریز نمرات سال اول دبیرستان',
        'High School (transcripts) 2th':'ریز نمرات سال دوم دبیرستان',
        'High School (transcripts) 3th':'ریز نمرات سال سوم دبیرستان',
        'High School (transcripts) 4th':'ریز نمرات سال چهارم دبیرستان',
        'Diploma Certificate':'دیپلم',
        'Associate Diploma Transcripts':'ریز نمرات فوق دیپلم',
        'Associate Certificate':'فوق دیپلم',
        'Bachlor Transcripts':'ریز نمرات لیسانس',
        'Bachlor Certificate':'لیسانس',
        'Masters Transcripts':'ریز نمرات کاشناسی ارشد',
        'Masters Certificate':'فوق لیسانس',
        'P.H.D Transcripts':'ریز نمرات دکتری',
        'P.H.D Certificate':'دکتری',
        'Post-Doctoral Transcripts':'ریز نمرات فوق دکتری',
        'Post-Doctoral Certificate':'فوق دکتری',
        'ISI Papers':'مقاله یا ISI',
        'CV':'رزومه یا cv',
        'Motivation Letter':'انگیزش نامه یا sop',
        'Non-University Degree (1)':'مدرک غیر دانشگاهی (1)',
        'Non-University Degree (2)':'مدرک غیر دانشگاهی (2)',
        'Non-University Degree (3)':'مدرک غیر دانشگاهی (3)',
        'Non-University Degree (4)':'مدرک غیر دانشگاهی (4)',
        'Non-University Degree (5)':'مدرک غیر دانشگاهی (5)',
        'Non-University Degree (6)':'مدرک غیر دانشگاهی (6)',
        'Scientific Documents of Awards or Festivals':'مدارک علمی جوایز یا جشنواره ',
        'Other Documents':'مدارک دیگر',
        'Applicant Job Information':'اطلاعات شغلی متقاضی',
        'Work Experience':'سابقه شغلی',
        'Work Experience by year':'سابقه شغلی بر حسب سال',
        'Enter the applicant’s Work experience by year':'سابقه شغلی متقاضی را بر حسب سال وارد نمایید',
        'Job':'شغل',
        'Date of First Job':'تاریخ اولین شغل',
        'Enter the applicant’s Date of First Job':'تاریخ اولین شغل متقاضی را وارد نمایید',
        'Date of 2nd Job':'تاریخ دومین شغل',
        'Enter the applicant’s Date of 2nd Job':'تاریخ دومین شغل متقاضی را وارد نمایید',
        'Date of 3rd Job':'تاریخ سومین شغل',
        'Enter the applicant’s Date of 3rd Job':'تاریخ سومین شغل متقاضی را وارد نمایید',
        'Date of 4th Job':'تاریخ چهارمین شغل',
        'Enter the applicant’s Date of 4th Job':'تاریخ چهارمین شغل متقاضی را وارد نمایید',
        'Date of 5th Job':'تاریخ پنجمین شغل',
        'Enter the applicant’s Date of 5th Job':'تاریخ پنجمین متقاضی را وارد نمایید',
        'Date of 6th Job':'تاریخ ششمین شغل',
        'Enter the applicant’s Date of 6th Job':'تاریخ ششمین شغل متقاضی را وارد نمایید',
        'Post (1)':'سمت شغلی (1)',
        'Enter the applicant’s Post in Job (1)':'سمت شغلی متقاضی را وارد نمایید. (1)', 
        'Post (2)':'سمت شغلی (2)',
        'Enter the applicant’s Post in Job (2)':'سمت شغلی متقاضی را وارد نمایید. (2)', 
        'Post (3)':'سمت شغلی (3)',
        'Enter the applicant’s Post in Job (3)':'سمت شغلی متقاضی را وارد نمایید. (3)', 
        'Post (4)':'سمت شغلی (4)',
        'Enter the applicant’s Post in Job (4)':'سمت شغلی متقاضی را وارد نمایید. (4)', 
        'Post (5)':'سمت شغلی (5)',
        'Enter the applicant’s Post in Job (5)':'سمت شغلی متقاضی را وارد نمایید. (5)', 
        'Post (6)':'سمت شغلی (6)',
        'Enter the applicant’s Post in Job (6)':'سمت شغلی متقاضی را وارد نمایید. (6)', 
        'Workplace (1)':'محل کار (1)',
        'Enter the applicant’s Workplace (1)':'محل کار متقاضی را وارد نمایید (1)',
        'Workplace (2)':'محل کار (2)',
        'Enter the applicant’s Workplace (2)':'محل کار متقاضی را وارد نمایید (2)',
        'Workplace (3)':'محل کار (3)',
        'Enter the applicant’s Workplace (3)':'محل کار متقاضی را وارد نمایید (3)',
        'Workplace (4)':'محل کار (4)',
        'Enter the applicant’s Workplace (4)':'محل کار متقاضی را وارد نمایید (4)',
        'Workplace (5)':'محل کار (5)',
        'Enter the applicant’s Workplace (5)':'محل کار متقاضی را وارد نمایید (5)',
        'Workplace (6)':'محل کار (6)',
        'Enter the applicant’s Workplace (6)':'محل کار متقاضی را وارد نمایید (6)',
        'Permissions':'مجوزها',
        'Date of Business License (1)':'تاریخ صدور جواز کسب(1)',
        'Enter the applicant’s Date of business license (1)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (1)',
        'Date of Business License (2)':'تاریخ صدور جواز کسب(2)',
        'Enter the applicant’s Date of business license (2)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (2)',
        'Date of Business License (3)':'تاریخ صدور جواز کسب(3)',
        'Enter the applicant’s Date of business license (3)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (3)',
        'Date of Business License (4)':'تاریخ صدور جواز کسب(4)',
        'Enter the applicant’s Date of business license (4)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (4)',
        'Date of Business License (5)':'تاریخ صدور جواز کسب(5)',
        'Enter the applicant’s Date of business license (5)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (5)',
        'Date of Business License (6)':'تاریخ صدور جواز کسب(6)',
        'Enter the applicant’s Date of business license (6)':'تاریخ صدور مجوز کسب متقاضی را وارد نمایید (6)',
        'Type and Name of License (1)':'نوع و نام مجوز کسب (1)',
        'Enter the applicant’s Type and Name of License (1)':'نوع مجوز و نام آن را وارد نمایید (1)',
        'Type and Name of License (2)':'نوع و نام مجوز کسب (2)',
        'Enter the applicant’s Type and Name of License (2)':'نوع مجوز و نام آن را وارد نمایید (2)',
        'Type and Name of License (3)':'نوع و نام مجوز کسب (3)',
        'Enter the applicant’s Type and Name of License (3)':'نوع مجوز و نام آن را وارد نمایید (3)',
        'Type and Name of License (4)':'نوع و نام مجوز کسب (4)',
        'Enter the applicant’s Type and Name of License (4)':'نوع مجوز و نام آن را وارد نمایید (4)',
        'Type and Name of License (5)':'نوع و نام مجوز کسب (5)',
        'Enter the applicant’s Type and Name of License (5)':'نوع مجوز و نام آن را وارد نمایید (5)',
        'Type and Name of License (6)':'نوع و نام مجوز کسب (6)',
        'Enter the applicant’s Type and Name of License (6)':'نوع مجوز و نام آن را وارد نمایید (6)',
        'License Expiration Date (1)':'تاریخ اتمام مجوز (1)',
        'Enter the applicant’s License Expiration Date (1)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (1)',
        'License Expiration Date (2)':'تاریخ اتمام مجوز (2)',
        'Enter the applicant’s License Expiration Date (2)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (2)',
        'License Expiration Date (3)':'تاریخ اتمام مجوز (3)',
        'Enter the applicant’s License Expiration Date (3)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (3)',
        'License Expiration Date (4)':'تاریخ اتمام مجوز (4)',
        'Enter the applicant’s License Expiration Date (4)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (4)',
        'License Expiration Date (5)':'تاریخ اتمام مجوز (5)',
        'Enter the applicant’s License Expiration Date (5)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (5)',
        'License Expiration Date (6)':'تاریخ اتمام مجوز (6)',
        'Enter the applicant’s License Expiration Date (6)':'تاریخ اتمام مجوز متقاضی را وارد نمایید (6)',
        'Job qualifications':'مدارک شغلی',
        'Certificate of Employment':'گواهی اشتغال به کار',
        'Insurance':'بیمه',
        'Business License':'جواز کسب',
        'Other Permissions':'مجوزهای دیگر',
        'Business Cards':'کارت بازرگانی',
        'Company Documents':'مدارک شرکت',
        'Factory Documents':'مدارک تولیدی یا کارخانه',
        'CV':'رزومه یا cv',
        'Tax Returns':'اظهارنامه مالیاتی',
        'Payroll':'فیش حقوقی',
        'Employment Warrant':'حکم کارگزینی',
        'Mission Warrant':'حکم ماموریتی',
        'Responsibility Warrant':'حکم مسئولیتی',
        'Applicant Financial Information':'اطلاعات مالی فرد متقاضی',
        'Turnover Number':'عدد گردش مالی',
        'Turnover':'گردش مالی',
        'Enter the applicant’s Turnover':'گردش مالی متقاضی را وارد نمایید',
        'Start Date of Turnover':'تاریخ ابتدای گردش مالی',
        'Enter the applicant’s Start Date of Turnover':'تاریخ ابتدای گردش مالی را وارد نمایید.',
        'End Date of Turnover':'تاریخ انتهای گردش مالی',
        'Enter the applicant’s End Date of Turnover':'تاریخ انتهای گردش مالی را وارد نمایید.',
        'Financial Ability':'تمکن مالی',
        'Enter the applicant’s Financial Ability':'تمکن مالی متقاضی را وارد نمایید.',
        'Date of Financial':'تاریخ تمکن مالی',
        'Enter the applicant’s Date of Financial':'تاریخ تمکن مالی را وارد نمایید.',
        'Financial Currency Unit':'واحد پولی تمکن مالی',
        'Enter the applicant’s Financial Currency Unit':'واحد پولی تمکن مالی راوارد نمایید',
        'Total Assets Amount':'مجموع کل دارایی',
        'Total Assets Amount in Number':'حدود مجموع دارایی به عدد',
        'Enter the applicant’s Total Assets Amount in Number':'حدود مجموع دارایی را به عدد وارد نمایید',
        'Calculated Currency':'واحد پولی محاسبه شده',
        'Enter the applicant’s Calculated Currency':'واحد پولی محاسبه شده را وارد نمایید',
        'Financial Documents':'اسناد مالی',
        'Turnover (1)':'گردش حساب (1)',
        'Turnover (2)':'گردش حساب (2)',
        'Financial Ability (1)':'تمکن مالی (1)',
        'Financial Ability (2)':'تمکن مالی (2)',
        'Property Document (1)':'سند ملکی (1)',
        'Property Document (2)':'سند ملکی (2)',
        'Property Document (3)':'سند ملکی (3)',
        'Factory Document':'سند کارخانه',
        'Shop Document':'سند مغازه',
        'Certificate of Stock Exchange':'گواهینامه یا سند بورسی',
        'Lease':'اجاره نامه',
        'Financial Supporter Letter (1)':'نامه ساپورتر مالی (1)',
        'Financial Supporter Letter (2)':'نامه ساپورتر مالی (2)',
        'Applicant Spouse Information':'اطلاعات همســـــــــــر متقاضی',
        'Applicant Spouse Job Information':'اطلاعات شغلی همســــــر متقاضی',
        'Applicants Spouse Financial Information':'اطلاعات مالی همســـــــر متقاضی',
        'Applicants Spouse Educational Information':'اطلاعات تحصیلی همســـــر متقاضی',
        'Applicant Spouse Foreign Language Information':'اطلاعات زبان خارجه همســـــر متقاضی',
        'Marriage Certificate Number':'شماره سند ازدواج',
        'Enter the applicants Marriage Certificate Number':'شماره سند ازدواج متقاضی را وارد نمایید',
        'Date of Marriage':'تاریخ ازدواج',
        'Enter the applicant’s Date of Marriage':'تاریخ ازدواج متقاضی را وارد نمایید',
        'Date of Divorce or Death':'تاریخ طلاق یا فوت همسر',
        'Enter the applicant’s Date of Divorce or Death':'تاریخ طلاق یا فوت همسر متقاضی را وارد نمایید',
        'Couples Documents':'مدارک زوجین',
        'Marriage Certificate':'سند ازدواج',
        'Exit Permission':'اجازه خروج',
        'Divorce or Death Letter':'نامه یا سند فوت یا طلاق',
        'Step 6':'مرحله 6',
        'Applicant Family Information':'اطلاعات خانواده متقاضی',
        'Applicants Spouse Family Information':'اطلاعات خانواده همسر متقاضی',
        'Prime Family Information':'اطلاعات خانواده درجه یک',
        'Father':'پدر',
        'Father’s Name':'نام پدر',
        'Enter the applicant’s Father Name':'نام پدر متقاضی را وارد نمایید',
        'Father’s Family':'نام خانوادگی پدر',
        'Enter the applicant’s Father Family':'نام خانوادگی پدر متقاضی را وارد نمایید',
        'Father’s Job':'شغل پدر',
        'Enter the applicant’s Father Job':'شغل پدر متقاضی را وارد نمایید',
        'Father’s Birth Day':'تاریخ تولد پدر',
        'Enter the applicant’s Father Birth Day':'تاریخ تولد پدر متقاضی را وارد نمایید',
        'Father’s Residence Address':'آدرس محل سکونت پدر',
        'Enter the applicant’s Fathers Residence Address':'آدرس محل سکونت پدر متقاضی را وارد نمایید',
        'Father’s Postal Code':'کد پستی پدر',
        'Enter the applicant’s Father Postal Code':'کد پستی پدر متقاضی را وارد نمایید',
        'Father’s Phone Call':'تلفن تماس پدر',
        'Enter the applicant’s Father Phone Call':'تلفن تماس پدر متقاضی را وارد نمایید',
        'Father’s Education':'تحصیلات پدر',
        'Enter the applicant’s Father’s Education':'تحصیلات پدر متقاضی را وارد نمایید',
        'Father’s Financial Ability':'تمکن مالی پدر',
        'Enter the applicant’s Father’s Financial Ability':'تمکن مالی پدر متقاضی را وارد نمایید',
        'Father’s Date of Divorce':'تاریخ طلاق پدر',
        'Enter the applicant’s Father’s Date of Divorce':'تاریخ طلاق پدر متقاضی را وارد نمایید',
        'Father’s Date of Death':'تاریخ فوت پدر',
        'Enter the applicant’s Father’s Date of Death':'تاریخ فوت پدر متقاضی را وارد نمایید',
        'Father’s Foreign Trip (1)':'سفر خارجه پدر (1)',
        'Father’s Foreign Trip (2)':'سفر خارجه پدر (2)',
        'Father’s Foreign Trip (3)':'سفر خارجه پدر (3)',
        'Father’s Foreign Trip (4)':'سفر خارجه پدر (4)',
        'Father’s Foreign Trip (5)':'سفر خارجه پدر (5)',
        'Father’s Foreign Trip (6)':'سفر خارجه پدر (6)',
        'Father’s Foreign Trip (7)':'سفر خارجه پدر (7)',
        'Father’s Foreign Trip (8)':'سفر خارجه پدر (8)',
        'Father’s Foreign Trip (9)':'سفر خارجه پدر (9)',
        'Enter the applicant’s Father’s Foreign Trip':'سفرهای خارجه پدر متقاضی را وارد نمایید',

        'Mother':'مادر',
        'Mother’s Name':'نام مادر',
        'Enter the applicant’s Mother Name':'نام مادر متقاضی را وارد نمایید',
        'Mother’s Family':'نام خانوادگی مادر',
        'Enter the applicant’s Mother Family':'نام خانوادگی مادر متقاضی را وارد نمایید',
        'Mother’s Job':'شغل مادر',
        'Enter the applicant’s Mother Job':'شغل مادر متقاضی را وارد نمایید',
        'Mother’s Birth Day':'تاریخ تولد مادر',
        'Enter the applicant’s Mother Birth Day':'تاریخ تولد مادر متقاضی را وارد نمایید',
        'Mother’s Residence Address':'آدرس محل سکونت مادر',
        'Enter the applicant’s Mothers Residence Address':'آدرس محل سکونت مادر متقاضی را وارد نمایید',
        'Mother’s Postal Code':'کد پستی مادر',
        'Enter the applicant’s Mother Postal Code':'کد پستی مادر متقاضی را وارد نمایید',
        'Mother’s Phone Call':'تلفن تماس مادر',
        'Enter the applicant’s Mother Phone Call':'تلفن تماس مادر متقاضی را وارد نمایید',
        'Mother’s Education':'تحصیلات مادر',
        'Enter the applicant’s Mother’s Education':'تحصیلات مادر متقاضی را وارد نمایید',
        'Mother’s Financial Ability':'تمکن مالی مادر',
        'Enter the applicant’s Mother’s Financial Ability':'تمکن مالی مادر متقاضی را وارد نمایید',
        'Mother’s Date of Divorce':'تاریخ طلاق مادر',
        'Enter the applicant’s Mother’s Date of Divorce':'تاریخ طلاق مادر متقاضی را وارد نمایید',
        'Mother’s Date of Death':'تاریخ فوت مادر',
        'Enter the applicant’s Mother’s Date of Death':'تاریخ فوت مادر متقاضی را وارد نمایید',
        'Mother’s Foreign Trip (1)':'سفر خارجه مادر (1)',
        'Mother’s Foreign Trip (2)':'سفر خارجه مادر (2)',
        'Mother’s Foreign Trip (3)':'سفر خارجه مادر (3)',
        'Mother’s Foreign Trip (4)':'سفر خارجه مادر (4)',
        'Mother’s Foreign Trip (5)':'سفر خارجه مادر (5)',
        'Mother’s Foreign Trip (6)':'سفر خارجه مادر (6)',
        'Mother’s Foreign Trip (7)':'سفر خارجه مادر (7)',
        'Mother’s Foreign Trip (8)':'سفر خارجه مادر (8)',
        'Mother’s Foreign Trip (9)':'سفر خارجه مادر (9)',
        'Enter the applicant’s Mother’s Foreign Trip':'سفرهای خارجه مادر متقاضی را وارد نمایید',

        'Brother (1)':'برادر (1)',
        '1th Brother’s Name':'نام اولین برادر',
        'Enter the applicant’s 1th Brother Name':'نام اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Family':'نام خانوادگی اولین برادر',
        'Enter the applicant’s 1th Brother Family':'نام خانوادگی اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Job':'شغل اولین برادر',
        'Enter the applicant’s 1th Brother Job':'شغل اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Birth Day':'تاریخ تولد اولین برادر',
        'Enter the applicant’s 1th Brother Birth Day':'تاریخ تولد اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Reside nce Address':'آدرس محل سکونت اولین برادر',
        'Enter the applicant’s 1th Brothers Residence Address':'آدرس محل سکونت اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Postal Code':'کد پستی اولین برادر',
        'Enter the applicant’s 1th Brother Postal Code':'کد پستی اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Phone Call':'تلفن تماس اولین برادر',
        'Enter the applicant’s 1th Brother Phone Call':'تلفن تماس اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Education':'تحصیلات اولین برادر',
        'Enter the applicant’s 1th Brother’s Education':'تحصیلات اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Financial Ability':'تمکن مالی اولین برادر',
        'Enter the applicant’s 1th Brother’s Financial Ability':'تمکن مالی اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Date of Divorce':'تاریخ طلاق اولین برادر',
        'Enter the applicant’s 1th Brother’s Date of Divorce':'تاریخ طلاق اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Date of Death':'تاریخ فوت اولین برادر',
        'Enter the applicant’s 1th Brother’s Date of Death':'تاریخ فوت اولین برادر متقاضی را وارد نمایید',
        '1th Brother’s Foreign Trip (1)':'سفر خارجه اولین برادر (1)',
        '1th Brother’s Foreign Trip (2)':'سفر خارجه اولین برادر (2)',
        '1th Brother’s Foreign Trip (3)':'سفر خارجه اولین برادر (3)',
        '1th Brother’s Foreign Trip (4)':'سفر خارجه اولین برادر (4)',
        '1th Brother’s Foreign Trip (5)':'سفر خارجه اولین برادر (5)',
        '1th Brother’s Foreign Trip (6)':'سفر خارجه اولین برادر (6)',
        '1th Brother’s Foreign Trip (7)':'سفر خارجه اولین برادر (7)',
        '1th Brother’s Foreign Trip (8)':'سفر خارجه اولین برادر (8)',
        '1th Brother’s Foreign Trip (9)':'سفر خارجه اولین برادر (9)',
        'Enter the applicant’s 1th Brother’s Foreign Trip':'سفرهای خارجه اولین برادر متقاضی را وارد نمایید',

        'Brother (2)':'برادر (2)',
        '2nd Brother’s Name':'نام دومین برادر',
        'Enter the applicant’s 2nd Brother Name':'نام دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Family':'نام خانوادگی دومین برادر',
        'Enter the applicant’s 2nd Brother Family':'نام خانوادگی دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Job':'شغل دومین برادر',
        'Enter the applicant’s 2nd Brother Job':'شغل دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Birth Day':'تاریخ تولد دومین برادر',
        'Enter the applicant’s 2nd Brother Birth Day':'تاریخ تولد دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Reside nce Address':'آدرس محل سکونت دومین برادر',
        'Enter the applicant’s 2nd Brothers Residence Address':'آدرس محل سکونت دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Postal Code':'کد پستی دومین برادر',
        'Enter the applicant’s 2nd Brother Postal Code':'کد پستی دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Phone Call':'تلفن تماس دومین برادر',
        'Enter the applicant’s 2nd Brother Phone Call':'تلفن تماس دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Education':'تحصیلات دومین برادر',
        'Enter the applicant’s 2nd Brother’s Education':'تحصیلات دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Financial Ability':'تمکن مالی دومین برادر',
        'Enter the applicant’s 2nd Brother’s Financial Ability':'تمکن مالی دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Date of Divorce':'تاریخ طلاق دومین برادر',
        'Enter the applicant’s 2nd Brother’s Date of Divorce':'تاریخ طلاق دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Date of Death':'تاریخ فوت دومین برادر',
        'Enter the applicant’s 2nd Brother’s Date of Death':'تاریخ فوت دومین برادر متقاضی را وارد نمایید',
        '2nd Brother’s Foreign Trip (1)':'سفر خارجه دومین برادر (1)',
        '2nd Brother’s Foreign Trip (2)':'سفر خارجه دومین برادر (2)',
        '2nd Brother’s Foreign Trip (3)':'سفر خارجه دومین برادر (3)',
        '2nd Brother’s Foreign Trip (4)':'سفر خارجه دومین برادر (4)',
        '2nd Brother’s Foreign Trip (5)':'سفر خارجه دومین برادر (5)',
        '2nd Brother’s Foreign Trip (6)':'سفر خارجه دومین برادر (6)',
        '2nd Brother’s Foreign Trip (7)':'سفر خارجه دومین برادر (7)',
        '2nd Brother’s Foreign Trip (8)':'سفر خارجه دومین برادر (8)',
        '2nd Brother’s Foreign Trip (9)':'سفر خارجه دومین برادر (9)',
        'Enter the applicant’s 2nd Brother’s Foreign Trip':'سفرهای خارجه دومین برادر متقاضی را وارد نمایید',

        'Brother (3)':'برادر (3)',
        '3rd Brother’s Name':'نام سومین برادر',
        'Enter the applicant’s 3rd Brother Name':'نام سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Family':'نام خانوادگی سومین برادر',
        'Enter the applicant’s 3rd Brother Family':'نام خانوادگی سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Job':'شغل سومین برادر',
        'Enter the applicant’s 3rd Brother Job':'شغل سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Birth Day':'تاریخ تولد سومین برادر',
        'Enter the applicant’s 3rd Brother Birth Day':'تاریخ تولد سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Reside nce Address':'آدرس محل سکونت سومین برادر',
        'Enter the applicant’s 3rd Brothers Residence Address':'آدرس محل سکونت سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Postal Code':'کد پستی سومین برادر',
        'Enter the applicant’s 3rd Brother Postal Code':'کد پستی سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Phone Call':'تلفن تماس سومین برادر',
        'Enter the applicant’s 3rd Brother Phone Call':'تلفن تماس سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Education':'تحصیلات سومین برادر',
        'Enter the applicant’s 3rd Brother’s Education':'تحصیلات سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Financial Ability':'تمکن مالی سومین برادر',
        'Enter the applicant’s 3rd Brother’s Financial Ability':'تمکن مالی سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Date of Divorce':'تاریخ طلاق سومین برادر',
        'Enter the applicant’s 3rd Brother’s Date of Divorce':'تاریخ طلاق سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Date of Death':'تاریخ فوت سومین برادر',
        'Enter the applicant’s 3rd Brother’s Date of Death':'تاریخ فوت سومین برادر متقاضی را وارد نمایید',
        '3rd Brother’s Foreign Trip (1)':'سفر خارجه سومین برادر (1)',
        '3rd Brother’s Foreign Trip (2)':'سفر خارجه سومین برادر (2)',
        '3rd Brother’s Foreign Trip (3)':'سفر خارجه سومین برادر (3)',
        '3rd Brother’s Foreign Trip (4)':'سفر خارجه سومین برادر (4)',
        '3rd Brother’s Foreign Trip (5)':'سفر خارجه سومین برادر (5)',
        '3rd Brother’s Foreign Trip (6)':'سفر خارجه سومین برادر (6)',
        '3rd Brother’s Foreign Trip (7)':'سفر خارجه سومین برادر (7)',
        '3rd Brother’s Foreign Trip (8)':'سفر خارجه سومین برادر (8)',
        '3rd Brother’s Foreign Trip (9)':'سفر خارجه سومین برادر (9)',
        'Enter the applicant’s 3rd Brother’s Foreign Trip':'سفرهای خارجه سومین برادر متقاضی را وارد نمایید',


        'Sister (1)':'خواهر (1)',
        '1th Sister’s Name':'نام اولین خواهر',
        'Enter the applicant’s 1th Sister Name':'نام اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Family':'نام خانوادگی اولین خواهر',
        'Enter the applicant’s 1th Sister Family':'نام خانوادگی اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Job':'شغل اولین خواهر',
        'Enter the applicant’s 1th Sister Job':'شغل اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Birth Day':'تاریخ تولد اولین خواهر',
        'Enter the applicant’s 1th Sister Birth Day':'تاریخ تولد اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Reside nce Address':'آدرس محل سکونت اولین خواهر',
        'Enter the applicant’s 1th Sisters Residence Address':'آدرس محل سکونت اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Postal Code':'کد پستی اولین خواهر',
        'Enter the applicant’s 1th Sister Postal Code':'کد پستی اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Phone Call':'تلفن تماس اولین خواهر',
        'Enter the applicant’s 1th Sister Phone Call':'تلفن تماس اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Education':'تحصیلات اولین خواهر',
        'Enter the applicant’s 1th Sister’s Education':'تحصیلات اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Financial Ability':'تمکن مالی اولین خواهر',
        'Enter the applicant’s 1th Sister’s Financial Ability':'تمکن مالی اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Date of Divorce':'تاریخ طلاق اولین خواهر',
        'Enter the applicant’s 1th Sister’s Date of Divorce':'تاریخ طلاق اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Date of Death':'تاریخ فوت اولین خواهر',
        'Enter the applicant’s 1th Sister’s Date of Death':'تاریخ فوت اولین خواهر متقاضی را وارد نمایید',
        '1th Sister’s Foreign Trip (1)':'سفر خارجه اولین خواهر (1)',
        '1th Sister’s Foreign Trip (2)':'سفر خارجه اولین خواهر (2)',
        '1th Sister’s Foreign Trip (3)':'سفر خارجه اولین خواهر (3)',
        '1th Sister’s Foreign Trip (4)':'سفر خارجه اولین خواهر (4)',
        '1th Sister’s Foreign Trip (5)':'سفر خارجه اولین خواهر (5)',
        '1th Sister’s Foreign Trip (6)':'سفر خارجه اولین خواهر (6)',
        '1th Sister’s Foreign Trip (7)':'سفر خارجه اولین خواهر (7)',
        '1th Sister’s Foreign Trip (8)':'سفر خارجه اولین خواهر (8)',
        '1th Sister’s Foreign Trip (9)':'سفر خارجه اولین خواهر (9)',
        'Enter the applicant’s 1th Sister’s Foreign Trip':'سفرهای خارجه اولین خواهر متقاضی را وارد نمایید',

        'Sister (2)':'خواهر (2)',
        '2nd Sister’s Name':'نام دومین خواهر',
        'Enter the applicant’s 2nd Sister Name':'نام دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Family':'نام خانوادگی دومین خواهر',
        'Enter the applicant’s 2nd Sister Family':'نام خانوادگی دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Job':'شغل دومین خواهر',
        'Enter the applicant’s 2nd Sister Job':'شغل دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Birth Day':'تاریخ تولد دومین خواهر',
        'Enter the applicant’s 2nd Sister Birth Day':'تاریخ تولد دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Reside nce Address':'آدرس محل سکونت دومین خواهر',
        'Enter the applicant’s 2nd Sisters Residence Address':'آدرس محل سکونت دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Postal Code':'کد پستی دومین خواهر',
        'Enter the applicant’s 2nd Sister Postal Code':'کد پستی دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Phone Call':'تلفن تماس دومین خواهر',
        'Enter the applicant’s 2nd Sister Phone Call':'تلفن تماس دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Education':'تحصیلات دومین خواهر',
        'Enter the applicant’s 2nd Sister’s Education':'تحصیلات دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Financial Ability':'تمکن مالی دومین خواهر',
        'Enter the applicant’s 2nd Sister’s Financial Ability':'تمکن مالی دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Date of Divorce':'تاریخ طلاق دومین خواهر',
        'Enter the applicant’s 2nd Sister’s Date of Divorce':'تاریخ طلاق دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Date of Death':'تاریخ فوت دومین خواهر',
        'Enter the applicant’s 2nd Sister’s Date of Death':'تاریخ فوت دومین خواهر متقاضی را وارد نمایید',
        '2nd Sister’s Foreign Trip (1)':'سفر خارجه دومین خواهر (1)',
        '2nd Sister’s Foreign Trip (2)':'سفر خارجه دومین خواهر (2)',
        '2nd Sister’s Foreign Trip (3)':'سفر خارجه دومین خواهر (3)',
        '2nd Sister’s Foreign Trip (4)':'سفر خارجه دومین خواهر (4)',
        '2nd Sister’s Foreign Trip (5)':'سفر خارجه دومین خواهر (5)',
        '2nd Sister’s Foreign Trip (6)':'سفر خارجه دومین خواهر (6)',
        '2nd Sister’s Foreign Trip (7)':'سفر خارجه دومین خواهر (7)',
        '2nd Sister’s Foreign Trip (8)':'سفر خارجه دومین خواهر (8)',
        '2nd Sister’s Foreign Trip (9)':'سفر خارجه دومین خواهر (9)',
        'Enter the applicant’s 2nd Sister’s Foreign Trip':'سفرهای خارجه دومین خواهر متقاضی را وارد نمایید',

        'Sister (3)':'خواهر (3)',
        '3rd Sister’s Name':'نام سومین خواهر',
        'Enter the applicant’s 3rd Sister Name':'نام سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Family':'نام خانوادگی سومین خواهر',
        'Enter the applicant’s 3rd Sister Family':'نام خانوادگی سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Job':'شغل سومین خواهر',
        'Enter the applicant’s 3rd Sister Job':'شغل سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Birth Day':'تاریخ تولد سومین خواهر',
        'Enter the applicant’s 3rd Sister Birth Day':'تاریخ تولد سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Reside nce Address':'آدرس محل سکونت سومین خواهر',
        'Enter the applicant’s 3rd Sisters Residence Address':'آدرس محل سکونت سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Postal Code':'کد پستی سومین خواهر',
        'Enter the applicant’s 3rd Sister Postal Code':'کد پستی سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Phone Call':'تلفن تماس سومین خواهر',
        'Enter the applicant’s 3rd Sister Phone Call':'تلفن تماس سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Education':'تحصیلات سومین خواهر',
        'Enter the applicant’s 3rd Sister’s Education':'تحصیلات سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Financial Ability':'تمکن مالی سومین خواهر',
        'Enter the applicant’s 3rd Sister’s Financial Ability':'تمکن مالی سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Date of Divorce':'تاریخ طلاق سومین خواهر',
        'Enter the applicant’s 3rd Sister’s Date of Divorce':'تاریخ طلاق سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Date of Death':'تاریخ فوت سومین خواهر',
        'Enter the applicant’s 3rd Sister’s Date of Death':'تاریخ فوت سومین خواهر متقاضی را وارد نمایید',
        '3rd Sister’s Foreign Trip (1)':'سفر خارجه سومین خواهر (1)',
        '3rd Sister’s Foreign Trip (2)':'سفر خارجه سومین خواهر (2)',
        '3rd Sister’s Foreign Trip (3)':'سفر خارجه سومین خواهر (3)',
        '3rd Sister’s Foreign Trip (4)':'سفر خارجه سومین خواهر (4)',
        '3rd Sister’s Foreign Trip (5)':'سفر خارجه سومین خواهر (5)',
        '3rd Sister’s Foreign Trip (6)':'سفر خارجه سومین خواهر (6)',
        '3rd Sister’s Foreign Trip (7)':'سفر خارجه سومین خواهر (7)',
        '3rd Sister’s Foreign Trip (8)':'سفر خارجه سومین خواهر (8)',
        '3rd Sister’s Foreign Trip (9)':'سفر خارجه سومین خواهر (9)',
        'Enter the applicant’s 3rd Sister’s Foreign Trip':'سفرهای خارجه سومین خواهر متقاضی را وارد نمایید',
        'Prime Family Documents':'مدارک خانواده درجه یک',
        'Passport (1)':'پاسپورت (1)',
        'Passport (2)':'پاسپورت (2)',
        'Passport (3)':'پاسپورت (3)',
        'Passport (4)':'پاسپورت (4)',
        'Passport (5)':'پاسپورت (5)',
        'Passport (6)':'پاسپورت (6)',
        'Passport (7)':'پاسپورت (7)',
        'Passport (8)':'پاسپورت (8)',
        'Applicant Travel Information':'اطلاعات سفر متقاضی',
        'Applicant Sopuse Travel Information':'اطلاعات سفر همســــــر متقاضی',
        'Foreign Trips':'سفرهای خارجه',
        'Number of Foreign Trips':'تعداد مسافرت های خارجه',
        'Enter the applicant’s Number of Foreign Trips':'تعداد سفرهای خارج از کشور متقاضی را وارد نمایید',
        'Arrival Travel Date (1)':'تاریخ ورود (1)',
        'Enter the applicant’s Arrival Travel Date (1)':'تاریخ ورود متقاضی را وارد نمایید (1)',
        'Departure Travel Date (1)':'تاریخ خروج (1)',
        'Enter the applicant’s Departure Travel Date (1)':'تاریخ خروج متقاضی را وارد نمایید (1)',
        'Travel Location (1)':'کشور مقصد (1)',
        'Enter the applicant’s Travel Location (1)':'کشور مقصد متقاضی را وارد نمایید (1)',
        'Staying Time (1)':'مدت اقامت (1)',
        'Enter the applicant’s Staying Time (1)':'مدت اقامت متقاضی را وارد نمایید (1)',
        'Reason for Travel (1)':'دلیل مسافرت (1)',
        'Enter the applicant’s Reason for Travel (1) ':'دلیل مسافرت متقاضی را وارد نمایید (1)',
        'Cities visited in the destination country (1)':'شهرهای ملاقات شده در کشور مقصد (1)',
        'Enter the applicant’s Cities visited in the destination country (1)':'شهرهای که در کشور مقصد ملاقات نموده اید را وارد نمایید (1)',

        'Arrival Travel Date (2)':'تاریخ ورود (2)',
        'Enter the applicant’s Arrival Travel Date (2)':'تاریخ ورود متقاضی را وارد نمایید (2)',
        'Departure Travel Date (2)':'تاریخ خروج (2)',
        'Enter the applicant’s Departure Travel Date (2)':'تاریخ خروج متقاضی را وارد نمایید (2)',
        'Travel Location (2)':'کشور مقصد (2)',
        'Enter the applicant’s Travel Location (2)':'کشور مقصد متقاضی را وارد نمایید (2)',
        'Staying Time (2)':'مدت اقامت (2)',
        'Enter the applicant’s Staying Time (2)':'مدت اقامت متقاضی را وارد نمایید (2)',
        'Reason for Travel (2)':'دلیل مسافرت (2)',
        'Enter the applicant’s Reason for Travel (2) ':'دلیل مسافرت متقاضی را وارد نمایید (2)',
        'Cities visited in the destination country (2)':'شهرهای ملاقات شده در کشور مقصد (2)',
        'Enter the applicant’s Cities visited in the destination country (2)':'شهرهای که در کشور مقصد ملاقات نموده اید را وارد نمایید (2)',

        'Arrival Travel Date (3)':'تاریخ ورود (3)',
        'Enter the applicant’s Arrival Travel Date (3)':'تاریخ ورود متقاضی را وارد نمایید (3)',
        'Departure Travel Date (3)':'تاریخ خروج (3)',
        'Enter the applicant’s Departure Travel Date (3)':'تاریخ خروج متقاضی را وارد نمایید (3)',
        'Travel Location (3)':'کشور مقصد (3)',
        'Enter the applicant’s Travel Location (3)':'کشور مقصد متقاضی را وارد نمایید (3)',
        'Staying Time (3)':'مدت اقامت (3)',
        'Enter the applicant’s Staying Time (3)':'مدت اقامت متقاضی را وارد نمایید (3)',
        'Reason for Travel (3)':'دلیل مسافرت (3)',
        'Enter the applicant’s Reason for Travel (3) ':'دلیل مسافرت متقاضی را وارد نمایید (3)',
        'Cities visited in the destination country (3)':'شهرهای ملاقات شده در کشور مقصد (3)',
        'Enter the applicant’s Cities visited in the destination country (3)':'شهرهای که در کشور مقصد ملاقات نموده اید را وارد نمایید (3)',

        'Arrival Travel Date (4)':'تاریخ ورود (4)',
        'Enter the applicant’s Arrival Travel Date (4)':'تاریخ ورود متقاضی را وارد نمایید (4)',
        'Departure Travel Date (4)':'تاریخ خروج (4)',
        'Enter the applicant’s Departure Travel Date (4)':'تاریخ خروج متقاضی را وارد نمایید (4)',
        'Travel Location (4)':'کشور مقصد (4)',
        'Enter the applicant’s Travel Location (4)':'کشور مقصد متقاضی را وارد نمایید (4)',
        'Staying Time (4)':'مدت اقامت (4)',
        'Enter the applicant’s Staying Time (4)':'مدت اقامت متقاضی را وارد نمایید (4)',
        'Reason for Travel (4)':'دلیل مسافرت (4)',
        'Enter the applicant’s Reason for Travel (4) ':'دلیل مسافرت متقاضی را وارد نمایید (4)',
        'Cities visited in the destination country (4)':'شهرهای ملاقات شده در کشور مقصد (4)',
        'Enter the applicant’s Cities visited in the destination country (4)':'شهرهای که در کشور مقصد ملاقات نموده اید را وارد نمایید (4)',

        'Schengen':'شنگن',
        'Schengen Number':'شماره شنگن',
        'Enter the applicant’s Schengen Number':'شماره شنگن متقاضی را وارد نمایید',
        'Schengen Issuance Date':'تاریخ صدور شنگن',
        'Enter the applicant’s Schengen Issuance Date':'تاریخ صدور شنگن متقاضی را وارد نمایید',

        'Special Visa':'ویزای خاص',
        'Special Visa Number (1)':'شماره ویزای خاص (1)',
        'Enter the applicant’s Special Visa Number':'شماره ویزای خاص را وارد نمایید',
        'Visa Name (1)':'نام ویزا (1)',
        'Enter the applicant’s Visa Name':'نام ویزای متقاضی را وارد نمایید',

        'Special Visa Number (2)':'شماره ویزای خاص (2)',
        'Visa Name (2)':'نام ویزا (2)',
        'Special Visa Number (3)':'شماره ویزای خاص (3)',
        'Visa Name (3)':'نام ویزا (3)',
        'Special Visa Number (4)':'شماره ویزای خاص (4)',
        'Visa Name (4)':'نام ویزا (4)',
        'Special Visa Number (5)':'شماره ویزای خاص (5)',
        'Visa Name (5)':'نام ویزا (5)',
        'Special Visa Number (6)':'شماره ویزای خاص (6)',
        'Visa Name (6)':'نام ویزا (6)',

        '- in 20 Character':'- حداکثر 20 کاراکتر',

        'Residence of The Destination Country':'اقامت در کشور مقصد',
        'Expiry date of residence of the destination country (1)':'تاریخ انقضا اقامت در کشور مقصد (1)',
        'Enter the applicant’s Expiry date of residence of the destination country':'تاریخ انقضا اقامت در کشور مقصد را وارد نمایید',
        'Expiry date of residence of the destination country (2)':'تاریخ انقضا اقامت در کشور مقصد (2)',
        'Expiry date of residence of the destination country (3)':'تاریخ انقضا اقامت در کشور مقصد (3)',
        
        'Rejected Visa':'ویزای ریجکتی',
        'Prior Rejection Date (1)':'تاریخ ریجکتی قبلی (1)',
        'Enter the applicants Prior Rejection Date':'تاریخ ریجکتی قبلی را وارد نمایید',
        'The country you rejected (1)':'نام کشور ریجکتی (1)',
        'Enter the applicants The Country You Rejected':'نام کشور ریجکتی را وارد نمایید',
        'Rejected Visa Type (1)':'نوع ویزای ریجکتی (1)',
        'Enter the applicant’s Rejected Visa Type':'نوع ویزای ریجکتی را وارد نمایید',
        'Date of Application for Rejected Visa (1)':'تاریخ اقدام برای ویزای ریجکتی (1)',
        'Enter the applicant’s Date of Application for Rejected Visa':'تاریخ اقدام برای ویزای ریجکتی را وارد نمایید',
        'Reason for Rejection (1)':'دلیل ریجکتی (1)',
        'Enter the applicant’s Reason for Rejection':'دلیل ریجکتی را وارد نمایید',

        'Prior Rejection Date (2)':'تاریخ ریجکتی قبلی (2)',
        'The country you rejected (2)':'نام کشور ریجکتی (2)',
        'Rejected Visa Type (2)':'نوع ویزای ریجکتی (2)',
        'Date of Application for Rejected Visa (2)':'تاریخ اقدام برای ویزای ریجکتی (2)',
        'Reason for Rejection (2)':'دلیل ریجکتی (2)',

        'Prior Rejection Date (3)':'تاریخ ریجکتی قبلی (3)',
        'The country you rejected (3)':'نام کشور ریجکتی (3)',
        'Rejected Visa Type (3)':'نوع ویزای ریجکتی (3)',
        'Date of Application for Rejected Visa (3)':'تاریخ اقدام برای ویزای ریجکتی (3)',
        'Reason for Rejection (3)':'دلیل ریجکتی (3)',

        'Prior Rejection Date (4)':'تاریخ ریجکتی قبلی (4)',
        'The country you rejected (4)':'نام کشور ریجکتی (4)',
        'Rejected Visa Type (4)':'نوع ویزای ریجکتی (4)',
        'Date of Application for Rejected Visa (4)':'تاریخ اقدام برای ویزای ریجکتی (4)',
        'Reason for Rejection (4)':'دلیل ریجکتی (4)',

        'Prior Rejection Date (5)':'تاریخ ریجکتی قبلی (5)',
        'The country you rejected (5)':'نام کشور ریجکتی (5)',
        'Rejected Visa Type (5)':'نوع ویزای ریجکتی (5)',
        'Date of Application for Rejected Visa (5)':'تاریخ اقدام برای ویزای ریجکتی (5)',
        'Reason for Rejection (5)':'دلیل ریجکتی (5)',

        'Prior Rejection Date (6)':'تاریخ ریجکتی قبلی (6)',
        'The country you rejected (6)':'نام کشور ریجکتی (6)',
        'Rejected Visa Type (6)':'نوع ویزای ریجکتی (6)',
        'Date of Application for Rejected Visa (6)':'تاریخ اقدام برای ویزای ریجکتی (6)',
        'Reason for Rejection (6)':'دلیل ریجکتی (6)',

        'Applicants Travel Documents':'مدارک مسافرتی متقاضی',
        'Applicants Spouse Travel Documents':'مدارک مسافرتی همســـــــر متقاضی',
        'Old Passport (1)':'پاسپورت قدیمی (1)',
        'Old Passport (2)':'پاسپورت قدیمی (2)',
        'Old Passport (3)':'پاسپورت قدیمی (3)',

        'Special or Revoked Visa':'ویزای خاص یا باطل شده',
        'New Visa in The New Passport':'ویزای جدید در پاسپورت جدید',
        'Residence documents of a specific country':'مدارک اقامتی کشور خاص',
        'Documents related to the rejected file (1)':'مدارک پرونده ریجکت شده (1)',
        'Documents related to the rejected file (2)':'مدارک پرونده ریجکت شده (2)',
        'Documents related to the rejected file (3)':'مدارک پرونده ریجکت شده (3)',
        'Documents related to the rejected file (4)':'مدارک پرونده ریجکت شده (4)',
        'Documents related to the rejected file (5)':'مدارک پرونده ریجکت شده (5)',
        'Documents related to the rejected file (6)':'مدارک پرونده ریجکت شده (6)',
        
        'Details of Foreign Trips':'جزئیات مسافرت های خارجه',

        'Other Information':'اطلاعات دیگر',
        'applicant’s Information’s Title (1)':'عنوان اطلاعات متقاضی (1)',
        'applicant’s Information (1)':'اطلاعات متقاضی (1)',
        'applicant’s Information’s Title (2)':'عنوان اطلاعات متقاضی (2)',
        'applicant’s Information (2)':'اطلاعات متقاضی (2)',
        'applicant’s Information’s Title (3)':'عنوان اطلاعات متقاضی (3)',
        'applicant’s Information (3)':'اطلاعات متقاضی (3)',
        'Enter the applicant’s Information’s Title':'عنوان اطلاعات متقاضی را وارد نمایید',
        'Enter the applicant’s Information':'اطلاعات متقاضی را وارد نمایید',
        'Other Documents of the Applicant, Spouse and Companion':'مدارک دیگر متقاضی ، همسر و همراه ',
        'Patent':'ثبت اختراع',
        'Ideas Submission':'ثبت ایده',
        'Other Qualifications':'مدارک تحصیلی دیگر',
        'Other Job Qualifications':'مدارک شغلی دیگر',
        'Other Financial Documents':'مدارک مالی دیگر',
        'Motivation Letter':'انگیزش نامه یا sop',
        'Other Documents (1)':'مدارک دیگر (1)',
        'Other Documents (2)':'مدارک دیگر (2)',
        'Other Documents (3)':'مدارک دیگر (3)',

        'Applicant Children Information':'اطلاعات فرزندان متقاضی',
        'Applicants Spouse Children Information':'اطلاعات فرزندان همسر متقاضی',
        'Number of Children':'تعداد فرزندان',
        'Enter the applicant’s Number of Children':'تعداد فرزندان متقاضی را وارد نمایید',
        'Profile of Children':'اطلاعات فرزندان',
        'Boy':'پسر',
        'Girl':'دختر',

        'Child Name (1)':'نام فرزند (1)',
        'Child Family (1)':'نام خانوادگی فرزند (1)',
        'Child Age (1)':'سن فرزند (1)',

        'Child Name (2)':'نام فرزند (2)',
        'Child Family (2)':'نام خانوادگی فرزند (2)',
        'Child Age (2)':'سن فرزند (2)',

        'Child Name (3)':'نام فرزند (3)',
        'Child Family (3)':'نام خانوادگی فرزند (3)',
        'Child Age (3)':'سن فرزند (3)',

        'Child Name (4)':'نام فرزند (4)',
        'Child Family (4)':'نام خانوادگی فرزند (4)',
        'Child Age (4)':'سن فرزند (4)',

        'Child Name (5)':'نام فرزند (5)',
        'Child Family (5)':'نام خانوادگی فرزند (5)',
        'Child Age (5)':'سن فرزند (5)',

        'Child Name (6)':'نام فرزند (6)',
        'Child Family (6)':'نام خانوادگی فرزند (6)',
        'Child Age (6)':'سن فرزند (6)',

        'Child Name (7)':'نام فرزند (7)',
        'Child Family (7)':'نام خانوادگی فرزند (7)',
        'Child Age (7)':'سن فرزند (7)',

        'Child Name (8)':'نام فرزند (8)',
        'Child Family (8)':'نام خانوادگی فرزند (8)',
        'Child Age (8)':'سن فرزند (8)',

        'Child Name (9)':'نام فرزند (9)',
        'Child Family (9)':'نام خانوادگی فرزند (9)',
        'Child Age (9)':'سن فرزند (9)',

        'Child Name (10)':'نام فرزند (10)',
        'Child Family (10)':'نام خانوادگی فرزند (10)',
        'Child Age (10)':'سن فرزند (10)',

        'Child Name (11)':'نام فرزند (11)',
        'Child Family (11)':'نام خانوادگی فرزند (11)',
        'Child Age (11)':'سن فرزند (11)',

        'Child Name (12)':'نام فرزند (12)',
        'Child Family (12)':'نام خانوادگی فرزند (12)',
        'Child Age (12)':'سن فرزند (12)',
  
        'Enter the applicant’s Child Name':'نام فرزند متقاضی را وارد نمایید',
        'Enter the applicant’s Child Family':'نام خانوادگی فرزند ود را وارد نمایید',
        'Child’s Gender':'جنسیت فرزند',
        'Enter the applicant’s Child’s Gender':'جنسیت فرزند متقاضی را وارد نمایید',
        'Enter the applicant’s Child Age':'سن فرزند متقاضی را وارد نمایید',

        'Children Documents':'مدارک فرزندان',
        'First child Passport':'پاسپورت فرزند اول',
        'First child National Card':'کارت ملی فرزند اول',
        'First child Birth Certificate':'شناسنامه فرزند اول',
        'First child Exit Permission':'اجازه خروج فرزند اول',
        'First child Financial Ability':'تمکن مالی فرزند اول',
        'First child Certificate of Education':'مدارک تحصیلی فرزند اول',
        'First child Other Documents':'مدارک دیگر فرزند اول',

        '2nd Child Passport':'پاسپورت فرزند دوم',
        '2nd Child National Card':'کارت ملی فرزند دوم',
        '2nd Child Birth Certificate':'شناسنامه فرزند دوم',
        '2nd Child Exit Permission':'اجازه خروج فرزند دوم',
        '2nd Child Financial Ability':'تمکن مالی فرزند دوم',
        '2nd Child Certificate of Education':'مدارک تحصیلی فرزند دوم',
        '2nd Child Other Documents':'مدارک دیگر فرزند دوم',

        '3rd Child Passport':'پاسپورت فرزند سوم',
        '3rd Child National Card':'کارت ملی فرزند سوم',
        '3rd Child Birth Certificate':'شناسنامه فرزند سوم',
        '3rd Child Exit Permission':'اجازه خروج فرزند سوم',
        '3rd Child Financial Ability':'تمکن مالی فرزند سوم',
        '3rd Child Certificate of Education':'مدارک تحصیلی فرزند سوم',
        '3rd Child Other Documents':'مدارک دیگر فرزند سوم',

        '4th Child Passport':'پاسپورت فرزند چهارم',
        '4th Child National Card':'کارت ملی فرزند چهارم',
        '4th Child Birth Certificate':'شناسنامه فرزند چهارم',
        '4th Child Exit Permission':'اجازه خروج فرزند چهارم',
        '4th Child Financial Ability':'تمکن مالی فرزند چهارم',
        '4th Child Certificate of Education':'مدارک تحصیلی فرزند چهارم',
        '4th Child Other Documents':'مدارک دیگر فرزند چهارم',

        'Visa Information':'اطلاعات ویزا',
        'Why did you  choose destination country?':'چرا کشور مقصد مورد نظر را انتخاب کردی؟',
        'Input Your Answer Here...':'پاسخ را در اینجا بنویسید...',
        'Why did you choose this type of visa?':'چرا این نوع ویزا را انتخاب کردی؟',
        'Why did you choose the desired university?':'چرا این دانشگاه مورد نظر را انتخاب کردی؟',
        'Why did you choose the desired field?':'چرا این رشته مورد نظر را انتخاب کردی؟',
        'What work and study did you do in your country before the visa?':'قبل از ویزا در کشور خود مشغول به چه کار و تحصیلی بوده اید؟',
        'What is your goal in the destination country after the visa?':'بعد از ویزا چه هدفی در کشور مقصد دارید؟',
        'How do you cover the costs of the destination country?':'چگونه هزینه های کشور مقصد را فراهم می کنید؟',
        'What is your goal after returning to your home country?':'پس از بازگشت به کشور خود، چه هدفی دارید؟',
        'Do you have relatives or acquaintances or friends in the destination country?':'آیا در کشور مقصد اقوام یا دوستانی دارید؟',
        'What is the idea for a startup visa and who designed it?':'ایده مورد نظر جهت ویزای استارت اپ چه موردی است و چه کسی آن را طراحی کرده است؟',
        'What are the sources of funding for a startup visa?':'تامین بودجه جهت ویزای استارت اپ از چه منابعی است؟',
        'What is your goal in choosing a startup visa or business visa?':'هدف شما از انتخاب ویزای استارت اپ یا ویزای بیزینسی چه چیزی است ؟',
        'Are you seeking asylum?':'آیا هدف پناهندگی دارید؟',
        'Do you have any refugee relatives in your chosen country?':'آیا اقوام پناهنده شده در کشور انتخابی دارید؟',
        'Do you already have investments in the destination country?':'آیا در حال حاضر سرمایه گذاری در کشور مقصد دارید؟',
        'Have you traveled to your chosen country before?':'آیا از قبل به کشور انتخابی سفر کرده اید؟',
        'What was your goal in obtaining a Schengen visa?':'هدفتان از اخذ ویزای شنگن چه بوده است؟',

        'Step 2-7':'مرحله2 از 7',
        '- in 500 Character':'- حداکثر 500 کاراکتر',
        

        'State':'استان',
        'Enter Your State':'استان را وارد نمایید',
        'military service situation':'وضعیت نظام وظیفه',
        'Enter the military service situation':'وضعیت نظام وظیفه متقاضی را مشخص کنید',
        'Applicant Age':'سن متقاضی',
        'Enter the Applicant’s Age':'سن متقاضی را وارد نمایید',
        'Scientific Documents of Awards or Festivals(1)':'گواهی، تقدیرنامه و مقام هایی که اخذ شده(1)',
        'Enter the Applicant’s Scientific Documents of Awards or Festivals(1)':'نام گواهی، تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(1)',
        'Research and Studies(1)':'تحقیقات و پژوهش های انجام شده (1)',
        'Enter the Applicant’s Research and Studies(1)':'تحقیقات و پژوهش های انجام شده را وارد نمایید (1)',
        'What languages can you speak?':'به چه زبان هایی قادر به مکالمه هستید؟',
        'Scientific Documents of Awards or Festivals(2)':'گواهی، تقدیرنامه و مقام هایی که اخذ شده(2)',
        'Enter the Applicant’s Scientific Documents of Awards or Festivals(2)':'نام گواهی، تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(2)',
        'Research and Studies(2)':'تحقیقات و پژوهش های انجام شده (2)',
        'Enter the Applicant’s Research and Studies(2)':'تحقیقات و پژوهش های انجام شده را وارد نمایید (2)',
        'Scientific Documents of Awards or Festivals(3)':'گواهی، تقدیرنامه و مقام هایی که اخذ شده(3)',
        'Enter the Applicant’s Scientific Documents of Awards or Festivals(3)':'نام گواهی، تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(3)',
        'Research and Studies(3)':'تحقیقات و پژوهش های انجام شده (3)',
        'Enter the Applicant’s Research and Studies(3)':'تحقیقات و پژوهش های انجام شده را وارد نمایید (3)',
        'English':'انگلیسی',
        'Persian':'فارسی',
        'Familiarity with the chosen language':'میزان آشنایی با زبان انتخاب شده',
        'Excellent':'عالی',
        'Good':'خوب',
        'Medium':'متوسط',
        'Weak':'ضعیف',
        'Very weak':'بسیار ضعیف',
        'Enter the Applicant’s Familiarity with the chosen language':'میزان آشنایی متقاضی را وارد نمایید',
        'English Score':'نمره انگلیسی',
        'Enter the Applicant’s English Score':'نمره انگلیسی متقاضی را وارد نمایید',
        'French':'فرانسه',
        'French Score':'نمره فرانسه',
        'Enter the Applicant’s French Score':'نمره فرانسه متقاضی را وارد نمایید',
        'German':'آلمانی',
        'German Score':'نمره آلمانی',
        'Enter the Applicant’s German Score':'نمره آلمانی متقاضی را وارد نمایید',
        'Danish':'دانمارکی',
        'Danish Score':'نمره دانمارکی',
        'Enter the Applicant’s Danish Score':'نمره دانمارکی متقاضی را وارد نمایید',
        'Arabic':'عربی',
        'Arabic Score':'نمره عربی',
        'Enter the Applicant’s Arabic Score':'نمره عربی متقاضی را وارد نمایید',
        'Spanish':'اسپانیایی',
        'Spanish Score':'نمره اسپانیایی',
        'Enter the Applicant’s Spanish Score':'نمره اسپانیایی متقاضی را وارد نمایید',
        'Chinese':'چینی',
        'Chinese Score':'نمره چینی',
        'Enter the Applicant’s Chinese Score':'نمره چینی متقاضی را وارد نمایید',
        'Korean':'کره ای',
        'Korean Score':'نمره کره ای',
        'Enter the Applicant’s Korean Score':'نمره کره ای متقاضی را وارد نمایید',
        'Turnover Number':'میزان گردش مالی',
        'Enter the applicant’s Turnover Number':'عدد گردش مالی متقاضی را وارد نمایید',
        '(In past 10 years)':'(در 10 سال گذشته)',
        'Job Title (1)':'عنوان شغلی (1)',
        'Enter the Job Title (1)':'عنوان شغلی را وارد نمایید (1)',
        'Insurance history or duration of activity in your job (1)':'سابقه بیمه یا مدت زمان فعالیت در شغل (1)',
        'Enter the Insurance history or duration of activity in your job (1)':'سابقه بیمه یا مدت زمان فعالیت در این شغل را وارد نمایید (1)',
        'Job Post (1)':'سمت شغلی (1)',
        'Enter the Applicant’s Job Post (1)':'سمت شغلی را وارد نمایید (1)',

        'Job Title (2)':'عنوان شغلی (2)',
        'Enter the Job Title (2)':'عنوان شغلی را وارد نمایید (2)',
        'Insurance history or duration of activity in your job (2)':'سابقه بیمه یا مدت زمان فعالیت در شغل (2)',
        'Enter the Insurance history or duration of activity in your job (2)':'سابقه بیمه یا مدت زمان فعالیت در این شغل را وارد نمایید (2)',
        'Job Post (2)':'سمت شغلی (2)',
        'Enter the Applicant’s Job Post (2)':'سمت شغلی را وارد نمایید (2)',

        'Job Title (3)':'عنوان شغلی (3)',
        'Enter the Job Title (3)':'عنوان شغلی را وارد نمایید (3)',
        'Insurance history or duration of activity in your job (3)':'سابقه بیمه یا مدت زمان فعالیت در شغل (3)',
        'Enter the Insurance history or duration of activity in your job (3)':'سابقه بیمه یا مدت زمان فعالیت در این شغل را وارد نمایید (3)',
        'Job Post (3)':'سمت شغلی (3)',
        'Enter the Applicant’s Job Post (3)':'سمت شغلی را وارد نمایید (3)',

        'Liquidity':'نقدینگی',
        'More than 70 thousand dollars':'بیشتر از 70,000 دلار',
        'to':'الی',
        'Less than $ 30,000':'کمتر از 30,000 دلار',
        'Enter the Applicant’s Liquidity':'میزان نقدینگی متقاضی را وارد نمایید',
        'Enter the Applicant’s Total Assets Amount':'مجموع دارایی متقاضی را وارد نمایید',
        'More than 600 thousand dollars':'بیشتر از 600,000 دلار',
        'Less than $ 50,000':'کمتر از 50,000 دلار',
        'More than 25 thousand dollars':'بیشتر از 25 هزار دلار',
        'Less than $ 5,000':'کمتر از 5 هزار دلار',
        'Further Information':'اطلاعات تکمیلی',

        'Do you have a child under 4 years old?':'آیا فرزند زیر 4 سال دارید؟',
        'Yes':'بله',
        'No':'خیر',
        'Number of children under 4 Years Old ':'تعداد فرزندان زیر 4 سال',
        'Enter the applicant’s Number of children under 4 Years Old ':'تعداد فرزندان زیر 4 سال متقاضی را وارد نمایید',
        'age of applicant’s children under 4 Years Old':'سن فرزندان زیر 4 سال',
        'Enter the age of applicant’s children under 4 Years Old':'سن فرزندان زیر 4 سال متقاضی را وارد نمایید',
        'Gender of Under 4 Years Old children':'جنسیت فرزندان زیر 4 سال',
        'Enter the applicant’s Gender of Under 4 Years Old children':'جنسیت فرزندان زیر 4 سال متقاضی را وارد نمایید',
        'Do you have children between 4 and 17 years old?':'آیا فرزند بین 4 تا 17 سال دارید؟',
        'Number of children between 4 and 17 Old':'تعداد فرزند بین 4 تا 17 سال',
        'Enter the applicant’s Number of children between 4 and 17 Old':'تعداد فرزند بین 4 تا 17 سال متقاضی را وارد نمایید',
        'Age of children between 4 and 17 Old Child':'سن فرزند بین 4 تا 17 سال',
        'Enter the applicant’s Age of children between 4 and 17 Old':'سن فرزند بین 4 تا 17 سال متقاضی را وارد نمایید',
        'Gender of children between 4 and 17 Old':'جنسیت فرزند بین 4 تا 17 سال',
        'Enter the applicant’s Gender of children between 4 and 17 Old':'جنسیت فرزند بین 4 تا 17 سال متقاضی را وارد نمایید',
        'Do you have children over 18?':'آیا فرزند بالای 18 سال دارید؟',
        'Number of children Over 18 Years Old ':'تعداد فرزندان بالای 18 سال',
        'Enter the applicant’s Number of children Over 18 Years Old ':'تعداد فرزندان بالای 18 سال متقاضی را وارد نمایید',
        'Age of children over 18 Years Old':'سن فرزندان بالای 18 سال',
        'Enter the applicant’s Age of children over 18 Years Old':'سن فرزندان بالای 18 سال متقاضی را وارد نمایید',
        'Gender of children over 18 Years Old':'جنسیت فرزندان بالای 18 سال',
        'Enter the applicant’s Gender of children over 18 Years Old':'جنسیت فرزندان بالای 18 سال متقاضی را وارد نمایید',
        
        'Do you have a companion? (Father - mother - grandfather - grandmother)':'آیا همراه دارید؟ (پدر - مادر - پدربزرگ - مادربزرگ)',
        'Country of immigration':'کشور مورد نظر جهت مهاجرت ',
        'Sweden':'سوئد',
        'Germany':'آلمان',
        'United Kingdom':'انگلستان',
        'Denmark':'دانمارک',
        'Austria':'اتریش',
        'Spain':'اسپانیا',
        'Australia':'استرالیا',
        'UAS':'آمریکا',
        'Ukraine':'اکراین',
        'Oman':'عمان',
        'Type of Requested Visa':'نوع ویزای درخواستی',
        'Tourist':'توریستی ',
        'Educational':'تحصیلی',
        'Student':'دانش آموزی',
        'Work':'کاری',
        'Business':'بیزینسی',
        'Company Registration':'ثبت شرکت',
        'Buy real estate and companies':'خرید املاک و شرکت ها',
        'Entrepreneurship':'کارآفرینی',
        'Startup':'استارت آپ',
        'Express Entry':'اکسپرس اینتری',
        'Provincial':'استانی',
        'Companion - Parents':'همراه – والدین',
        'Rejection - Court':'ریجکتی – دادگاهی',

        'The purpose of migration':'هدف از مهاجرت',
        'Enter the applicant’s purpose of migration':'هدف از مهاجرت متقاضی را شرح دهید',
        'Traveled to a Schengen area, Canada,...':'مسافرت به یکی از کشورهای حوزه شنگن، کانادا، انگلستان، آمریکا، استرالیا و...',
        'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?':'آیا خودتان یا همسرتان به یکی از کشورهای حوزه شنگن، کانادا، انگلستان، آمریکا، استرالیا سفر کرده اید ؟',
        'Names of countries you have visited':'نام کشورهایی که به آنجا سفر کرده اید',
        'Enter the Names of countries you have visited':'نام کشورهایی که به آنجا سفر کرده اید را وارد نمایید',
        'Has the applicant ever had a history of rejection from a country?':'آیا متقاضی تاکنون سابقه ریجکتی از کشوری داشته است؟',
        'Names of countries you have been rejected so far':'نام کشورهایی که تا کنون ریجکت شده',
        'Enter the Names of countries you have been rejected since 5 years':'در صورت ریجکتی در 5سال گذشته، نام کشور را ذکر نمایید',
        'Do you have first and second degree relatives in your chosen country?':'آیا در کشور منتخبتان اقوام درجه یک و دو دارید؟ ',
        'Explain...':'توضیح دهید',
        'Enter the applicant’s Explain':'توضیحات متقاضی را وارد نمایید',

        'Does the applicant have a financial supporter?':'آیا متقاضی ساپورتر مالی دارد؟ ',
        'Explain if you have a financial supporter':'در صورت داشتن ساپورتر مالی توضیح دهید ',
        'Enter Explain if you have a financial supporter':'در صورت داشتن ساپورتر مالی توضیح دهید ',
        'Spouse’s Name':'نام همسر',
        'Enter Your Spouse’s Name':'نام همسر را وارد نمایید',
        'Spouse’s Last Name':'نام خانوادگی همسر',
        'Enter Your Spouse’s Last Name':'نام خانوادگی همسر را وارد نمایید',
  
        'Applicant Accompany Information':'اطلاعات همراه متقاضی',
        'Accompany Post':'سمت همراه',
        'Enter the applicant’s Accompany Post':'سمت همراه متقاضی را وارد نمایید',
        'Applicant Descriptions':'توضیحات متقاضی',
        'Descriptions':'توضیحات',
        'Enter Your Descriptions':'توضیحات مهم دیگر متقاضی را در این قسمت وارد نمایید',
        'Included':'مشمول',
        'Served':'پایان خدمت ',
        'Conscription Exempt':'معاف از خدمت',
        'Purchased Service':'خرید خدمت',
        'SORRY, PAGE NOT FOUND':'متاسفانه صفحه مورد نظر یافت نشد',
        'Return to Home Page':'برگشت به صفحه اول',

        'Your Cash is:':'موجودی کیف پول شما:',
        '1500$':'1500 دلار',
        '1000$':'1000 دلار',
        '500$':'500 دلار',
        'This Page is Under Construction, It will be available as soon as possible.':'این صفحه به زودی کامل خواهد شد',
        'Lawyers':'وکلا',
        'Pending':'در انتظار',
        '+ Services':'+ خدمات',
        'Enter the applicants Service Name':'نام خدمات متقاضی را وارد کنید',
        'Messages':'پیام ها',
        'Service Name...':'نام خدمات ...',
        'Messaging System is Online tools for communication between our users, also you can send message to anybody.':'سیستم پیام رسان، یک ابزار برای ارتباط بین کاربران است. همچنین شما میتوانید از طریق این ابزار با کاربران مرتبط با خود در ارتباط یاشید.',
        'To':'به',
        'Subject':'موضوع',
        'Type here...':'تایپ نمایید...',
        'Enter Your message in the box.':'متن پیام خود را در باکس بالا تایپ نمایید.',
        'Send Message':'ارسال پیام',
        'Type here your message':'پیام خود را اینجا تایپ کنید',
        'Type Your Desired Name for Service and Then click (Create)':'برای ایجاد خدمات جدید نام دلخواه خدمات را تایپ نموده و create را بزنید',
        'Select Your User Type':'نوع کاربر خود را تعیین کنید',
        'My Services':'خدمات من',
        'Lawyers List':'لیست وکلا',
        'Agents List':'لیست نمایندگان',
        'Customers List':'لیست مشتریان',
        'Service Owner':'صاحب خدمات',
        'Choose Your Business Service':'نوع خدمات بیزینس خود را انتخاب نمایید',
        'selecting your user Service type.':'انتخاب نوع خدمات کاربر شما',
        '< Back to Account page':'< برگشت به صفحه انتخاب نوع کاربر',
        'Distributers':'توسعه دهندگان',

        'Dear user, welcome to your registration section':'کاربر گرامی به بخش ثبت نام خوش آمدید',
        'Select the appropriate option to register depending on the following conditions:':'برای ثبت نام بسته به شرایط زیر گزینه مناسب را انتخاب نمایید:',
        '1- If you have an invitation code, select the option to register with the code':'۱- اگر کد دعوت نامه دارید گزینه ثبت نام با کد را انتخاب نمایید',
        '2- If you intend to cooperate and you think you have the conditions to obtain the code, Contact Us and select the option to Contact Us.':'۲- اگر قصد همکاری دارید و فکر میکنید شرایط اخذ کد دارید با ما در ارتباط باشید و گزینه ارتباط با ما را انتخاب نمایید',
        '3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Agent option, and after obtaining the necessary points, you can reach the stage of obtaining a code.':'۳- اگر شرایط اخذ کد ندارید اما میخواهید همکاری کنید میتوایند ابتدا با انتخاب گزینه نماینده ثبت نام خود را شروع نمایید و پس از اخذ امتیازات لازم به مرحله اخذ کد برسید',
        '4- If none of the conditions are suitable, return to the previous page to display more options.':'۴- اگر هیچ یک از شرایط برای شما مناسب نیست به صفحه قبل بازگردید تا گزینه های بیشتری نمایش داده شود.',
        'Choose Your Registration Type':'نوع ثبت نام خود راانتخاب نمایید',
        'Registration with Code':'ثبت نام با کد',
        'Registered as a Agent':'ثبت به عنوان نماینده',
        'Doctor':'پزشک',
        'Car Service':'خودرو',
        'Real Estate':'مشاور املاک',
        'Trade':'بورس و ارز دیجیتال',
        'Digital  Services':'خدمات دیجیتالی',
        'Beauty Services':'آرایشی بهداشتی',
        'Gold and Jewelry':'طلا و جواهرات',
        'Latest Distributers':'توسعه دهندگان اخیر',
        'Message':'پیام',
        'View Information':'مشاهده اطلاعات',
        'View':'مشاهده',
        'Related Distributers':'توسعه دهندگان همکار',
        'Related Distributers List':'لیست توسعه دهندگان همکار',
        'DS Customers':'مشتریان توسعه دهنده',
        'DC Customers':'مشتریان توسعه دهنده',
        'DS Shared Customers List':'لیست مشتریان توسعه دهنده',
        'DC Shared Customers List':'لیست مشتریان توسعه دهنده',
        'Agent Customers':'مشتریان نماینده',
        'Agent Customers List':'لیست مشتریان نماینده',
        'Distributer':'توسعه دهنده',
        'Distributers List':'لیست توسعه دهندگان',
        'Choose Your Service':'خدمات خود را انتخاب نمایید',
        'other':'دیگر',
        'Type Service Name here':'نام خدمات را اینجا تایپ نمایید',
        'Service Name':'نام خدمات',
        'Service Price':'مبلغ خدمات',
        'DS Commission':'درصد توسعه دهنده',
        'DC Commission':'درصد توسعه دهنده',
        'DC share':'سهم توسعه دهنده',
        'Distributer Commission':'درصد توسعه دهنده',
        'Agent Commission':'درصد نماینده',
        'About Service':'درباره خدمات',
        'Enter the Description About Your Service':'شرح خدمات خود را در کادر بالا بنویسید',
        'Accept':'تایید',
        'Educational Visa':'ویزای تحصیلی',
        'Enter Your Offer':'پیشنهاد خود را وارد نمایید',
        'You Assigned Before':'قبلاثبت نموده اید',
        'Coming Soon':'به زودی فعال می شود',
        'Soon!':'به زودی!',
        'Our Help Desk':'مرکز پشتیبانی ما',
        'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You don’t have Access to Change Fees or Commissions!':'وکیل عزیز توجه نمایید، پس از ثبت خدمات شما قابل تغییر نیست و دیگر به هزینه ها یا کمیسیون های دسترسی نخواهید داشت!',
        'Services Never Change After Adding to Your List':'خدمات پس از افزودن به لیست شما هرگز قابل تغییر نمی باشد',
        'How To Become A Premium DS?':'چگونه به یک DS پلاس تبدیل شویم؟',
        'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.':'در قوانین ما راه هایی وجود دارد که می توانید حساب خود را به DS+ ارتقا دهید، پس از آن می توانید از خدمات ویژه ای استفاده کنید. برای اطلاعات بیشتر لطفا از مرکز پشتیبانی ما دیدن کنید.',
        'Dear user, these services will be available soon!':'کاربر گرامی، خدمات انتخابی به زودی فعال می گردد!',
        'Our experts are working on it.':'در صورت احراز نشدن کد وکالت شما، اکانت شما حذف خواهد شد.',
        'If your power of attorney code is not verified, your account will be deleted.':'کارشناسان ما در حال ارتقا آن هستند.',
        'As Lawyer':'به عنوان وکیل',
        'Date of birth':'تاریخ تولد',
        'User Name Start with Alphabet and its Minimum length is 5':'نام کاربری باید با حرف شروع شود و طول آن حداقل 5 کاراکتر باشد',
        'password':'کلمه عبور',
        'Confirm password':'تایید کلمه عبور',
        'As Distributor':'به عنوان توسعه دهنده',
        'As Customer':'به عنوان مشتری',
        'As Agent':'به عنوان نماینده',
        'What service do you want?':'خدمات مورد نظر شما کدام است؟',
        'Your host Telens support team':'تیم پشتیبانی تلزینس میزبان شما',
        'Way to communicate with us':'راه ارتباطی با ما',
        'To Contact Us, just send us an email from the address below':'برای ارتباط با ما کافیست از طریق آدرس روبرو برای ما ایمیل ارسال کنید',
        'Follow us on social media':'ما را در شبکه های اجتماعی دنبال کنید',
        'Can we help you?':'می توانیم به شما کمک نماییم؟',
        'Joining the Telsins family':'پیوستن به خانواده ی تلزینس',
        'The name of the service is here':'نام خدمات در اینجا قرار میگیرد',
        'Referral Code':'کد معرف',
        'Select Your User Type':'نوع کاربر را انتخاب نمایید',
        'Select Your User':'نام کاربری',
        'Telesiness Support':'پشتیبانی تلزینس',
        'Select Service Name':'نام خدمات را انتخاب نمایید',
        'Required Documents':'مدارک مورد نیاز',
        'Enter the Customers Required Documents for This Service':'مدارکی که برای خدمات نیاز است را وارد نمایید',
        'Visa Process':'فرآیند ویزا',
        'Enter the Process of This Service':'فرآیند اخذ ویزا برای خدمات خود را وارد نمایید',
        'Referral Customers':'مشتریان ارجاعی',
        'Referral Customer List':'لیست مشتریان ارجاعی',
        'Customers List':'لیست مشتریان',
        'logging in...':'در حال ورود...',
        'Service Country':'کشور خدمات',
        '+ Add New Distributer':'+ افزودن توسعه دهنده',
        'Hindi':'هندی',
        'Mandarin':'ماندارین',
        'Dutch':'هلندی',
        'French':'فرانسوی',
        'Bengali':'بنگالی',
        'Russian':'روسی',
        'Portuguese':'پرتغالی',
        'Indonesian':'اندونزیایی',
        'Japanese':'ژاپنی',
        'Turkish':'ترکی',
        'Italian':'ایتالیایی',
        'Romanian':'رومانیایی',
        'Thai':'تایلندی',
        'Nepali':'نپالی',
        'Gujarati':'گجراتی',
        'Your Customer Activated':'مشتری فعال شد',
        'Your Customer Activated Successfully.':'مشتری شما با موفقیت فعال شد.',
        'OK, I Got It':'باشه فهمیدم',
        'View Services':'مشاهده خدمات',
        'DS':'توسعه دهنده',
        'Assign a Services':'اختصاص خدمات',
        'Your Assigned Services to Agent':'خدمات اختصاص داده شده شما به نماینده',
        'Agent Code':'کد نماینده',
        'Your Assigned offer':'پیشنهاد تعیین شده شما',
        'offer you':'پیشنهاد شما',
        'Suggested Services':'خدمات پیشنهادی',
        'Save changes':'ذخیره تغییرات',
        'Assign by Telesiness':'تخصیص توسط تلزینس',
        'Adding a new Distributer':'افزودن توسعه دهنده جدید',
        'Type Distributer Code...':'کد توزیع کننده را تایپ کنید...',
        'Add DS!!!':'افزودن توسعه دهنده!!!',
        'Type DS Code Here...':'کد توسعه دهنده را در اینجا تایپ کنید...',
        'ADD BY DS Code':'افزودن با کد توسعه دهنده',
        'DS Code':'کد توسعه دهنده',
        'Distributer Code':'کد توسعه دهنده',
        'List of Tariffs For Lawyer:':'لیستی از تعرفه های وکیل:',
        'Adding a new CO-DS':'افزودن توسعه دهنده همکار',
        'Add a Distributer that want to cooperate with in your work':'یک توسعه دهنده را برای همکاری اضافه نمایید.',
        'Adding a Service to Customer':'افزودن خدمات به مشتری',
        'Please Select a Service to customer.':'لطفاً خدماتی را برای مشتری انتخاب کنید.',
        'Type an Email adress...':'یک آدرس ایمیل تایپ کنید...',
        'DS Information':'اطلاعات توسعه دهنده',
        'Enter the applicant’s Gender':'جنسیت متقاضی را وارد کنید',
        'Enter Your City':'شهر خود را وارد کنید',
        'please input your first name':'لطفا نام خود را وارد کنید',
        'please input your Last Name':'لطفا نام خانوادگی خود را وارد نمایید',
        'please input your Phone number':'لطفا شماره تلفن را وارد نمایید',
        'please input your Email':'لطفا ایمیل خود را وارد نمایید',
        'please Select your Country':'لطفا کشور خود را انتخاب نمایید',
        'please input your ICCRC':'لطفا کد وکالت خود را وارد نمایید',
        'UnEqual Password...':'تکرار پسورد صحیح نیست...',
        'DS Services':'خدمات توسعه دهنده',
        'This Option Will Be Active After You improve Panel to Pro Version!':'این گزینه پس از ارتقاء پنل به نسخه حرفه ای فعال خواهد شد!',
        'Are you sure about the set tariff?':'آیا از تعرفه تعیین شده مطمئن هستید؟',
        'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval':'لطفا از نرخ تعیین شده به عنوان درصد مطمئن شوید. پس از تایید نهایی امکان تغییر تعرفه وجود ندارد',
        'please input your ID Number':'لطفا کد ملی خود را وارد نمایید',
        'You can upgrade your account to the professional version, click for more information!':'می توانید حساب خود را به نسخه حرفه ای ارتقا دهید، برای اطلاعات بیشتر به راهنما مراجعه کنید!',
        'UpLoad your Cv/Sop/evaluation-form':'آپلود رزومه/انگیزش نامه/فرم ارزیابی',
        'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload':'در صورت داشتن هر کدام از موارد زیر، می توانید فایل آن را در این قسمت آپلود نمایید. برای آپلود بیش از یک فایل، ابتدا آنها را به صورت یک فایل pdf ادغام نموده، سپس بارگذاری نمایید',
        'You Have Not Code OR UnEqual Password...':'کد معرف یا تکرار رمز اشتباه است',
        'Pay attention You can Approve Customer Account once, and after that you cant change status!':'توجه نمایید شما فقط یک بار می توانید حساب مشتری را تایید کنید و پس از آن دیگر قادر به تغییر آن نخواهید بود!',
        'Distributer Customers List':'لیست مشتریان توسعه دهنده',

        'Home Page Guide of Business Owner':'راهنمای پنل وکیل',
        'Home Page':'پیشخوان',
        'Profile menu':'منوی پروفایل',
        'Second icon':'دومین آیکن',
        'Third icon':'سومین آیکن',
        'Fourth icon':'چهارمین آیکن',
        'Fifth section':'پنجمین قسمت',
        'Second part:':'قسمت دوم',
        'Third part:':'قسمت سوم',
        'In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.':'شما در این بخش لیست کاملی از ارتباطات خودتان را خواهید دید. لیست مشتریان، لیست توسعه دهندگان، لیست وکلای همکار با شما و لیست سرویس های شما. همچنین میتوانید از همین بخش مواردی را به این لیست ها اضافه کنید و مشخصات کوتاهی از آنها را ببینید.',
        'In this section, you will see the number of successful, canceled and current files.':'در این قسمت تعداد پرونده های موفق، کنسل شده و جاری خودتان را رویت میکنید.',
        'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.':'در بخش پنجم لیست موفق ترین توسعه دهندگان و صاحبین بیزینس بر اساس کارکرد، امتیاز و مقدار رضایت مشتریان از آنها برای شما نمایش داده میشود. این لیست کاملا رقابتی بوده و پس از آنالیز شرایط خاص کاربر، سیستم به صورت اتوماتیک آنها را در این بخش قرار میدهد. شما با مشاهده امتیاز و مشخصات آنها میتوانید درخواست دهید و با آنها همکاری نمایید. تمامی مسئولیت مذاکرات و ارتباط کاری بین شما و توسعه دهندگان یا صاحبین بیزینس به شخص خود شما مربوط می باشد و اپلیکشن تنها به ارتباط شما کمک خواهد کرد.',
        'By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.':'از بخش چهارم میتوانید راهنمای کلی سایت را مطالعه نمایید و در صورت هرگونه مشکل یا سوال با بخش پشتیبانی ارتباط برقرار نمایید.',
        'By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.':'از بخش سوم میتوانید تمام اعلان ها یا اخطار ها یا هر گونه پیامی که نیاز به بررسی دارد را مشاهده نمایید.',
        'By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.':'از بخش دوم میتوانید حساب مالی خودتان را رویت کنید. همچنین میتوانید صورت حساب مالی خودتان را با جزئیات بررسی نمایید. بخش مالی این برنامه کاملا منحصر به فرد جهت حسابرسی مالی شما می باشد و برای داشبورد شما تنظیم شده است. با کمک آن میتوانید جهت حسابرسی به شرکت یا مجموعه تان خارج از این اپلیکیشن استفاده نمایید.',
        'Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.':`پیشخوان شامل یک نمای کلی از اطلاعات ضروری و مورد نیاز شما می باشد.
        قسمت بالای پیشخوان شامل چهار آیکن (منوی پروفایل، بخش حسابداری، اعلان (نوتیفیکیشن) و راهنما) و لیستی از موفق ترین توسعه دهندگان و صاحبین بیزینس می باشد که با انتخاب هر یک از آنها می توانید فعالیت و همکاری خودتان را با آنها آغاز نمایید.
        `,
        'You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.':`از گزینه مشاهده پروفایل می توانید اطلاعات حساب کاربری خود را تغییر و تنظیم نمایید. (لازم به ذکر است تکمیل دقیق پروفایل طبق قوانین الزامی بوده و شما تا یک ماه برای آن زمان دارید. در صورت عدم تکمیل اطلاعات در این بازه زمانی پروفایل شما مسدود خواهد شد). همچنین میتوانید قوانین اپلیکیشن را مرور کنید، تنظیمات آن را تغییر دهید و یا از برنامه خارج شوید.`,
        'Scoring system:':'سیستم امتیازدهی',
        'Third part: Distributor points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.':'بخش سوم :امتیاز توسعه دهندگان است که نسبت به تعرفه و خدماتی که شما به آنها ارائه کردید محاسبه خواهد شد و 20% از کل امتیاز شما را پوشش خواهد داد.',
        'First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.':'بخش اول :در ابتدا تلزینس 720 امتیاز را به شما اهدا میکند که این امتیاز تنها 20% از کل امتیاز شما می باشد.',
        'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 60% of your total point.':'بخش دوم :امتیازی است که مشتریان به شما میدهند. مشتریان شما از 1 تا 5 ستاره را با توجه به رضایتشان انتخاب کرده و به شما خواهند داد که برای هر ستاره امتیازی در نظر گرفته شده است. این امتیازها نسبت به نتیجه ارائه خدمات شما محاسبه خواهد شد. در نهایت امتیاز مشتری 60% از کل امتیاز شما را شامل میشود.',
        'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of three parts:':'سیستم امتیازدهی این برنامه از اهمیت بالایی برخوردار است. چرا که معیار عملکرد شما و کیفیت خدمات شما به آن بستگی دارد و میتواند در روابط کاری شما تأثیر بگذارد. همچنین تعداد مشتریان شما میتواند با این امتیازات کم و زیاد شود. این سیستم از سه بخش تشکیل شده:',
        'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful business owners and distributors will select you and to work with you.':'در نهایت مجموع امتیازها با تعداد کاربران و پرونده های موفق محاسبه و عدد نهایی را به شما نمایش خواهیم داد. این امتیاز را تمامی کاربران در طول مسیر رویت خواهند کرد و در نهایت با توجه به امتیازات شما در لیست موفق ترین وکلا قرار خواهید گرفت تا توسعه دهندگان شما را برگزیده و با شما همکاری نمایند.',
        'The world of Telesiness Network Marketing helps you to increase your points to have more customers.':'جهان نتورک مارکتینگ تلزینس به شما کمک میکند تا امتیاز خودتان را بالا ببرید تا از مشتریان بیشتری برخوردار شوید.',
        'At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.':'در انتها بالاترین امتیاز کسب شده توسط برترین وکیل در این شبکه به شما نمایش داده خواهد شد تا بتوانید تشخیص دهید چه میزان با نفر اول فاصله دارید.',
        'Page of Customers:':'منو مشتریان',
        'In this section, you can completely control your customers’ files and also check your list of new customers.':'در این بخش شما به طور کامل میتوانید پرونده های مشتریان خود را کنترل کنید. همچنین لیست مشتریان جدید خود را بررسی نمایید.',
        'In this section, you can completely control your customers’ files and also check your list of new customers. The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.':'در این بخش شما به طور کامل میتوانید پرونده های مشتریان خود را کنترل کنید. همچنین لیست مشتریان جدید خود را بررسی نمایید. عملکرد این بخش هنگام معرفی یک مشتری به صورت در حال انتظار می باشد. شما باید پس از بررسی مشخصات و اطلاعات آنها جهت نوع ویزا تصمیم بگیرید. پس از بررسی اولیه میتوانید نوع ویزای آنها را انتخاب نمایید تا سیستم شما را به مرحله بعدی راهنمایی کند. همچنین در نظر داشته باشید که پس از انتخاب نوع ویزا دیگر امکان تغییر ندارید و تنها راه ویرایش آن مردود کردنش خواهد بود. بعد از اعلام مردودی مشتری باید از ابتدا درخواست داده تا مراحل طی شود.',
        'By entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.':'با ورود به داشبورد مشتری میتوانید به تمامی اطلاعات و مدارک وی دسترسی پیدا کنید. در صورت نیاز میتوانید پرونده مشتری را با همکاران خود به اشتراک بگذارید یا مدیرانی جهت پیگیری پرونده مشخص نمایید.',
        'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.':'همچنین از این بخش میتوانید مستقیم به مشتری مورد نظر پیام ارسال کرده و اطلاعات ضروری را به آنها انتقال دهید. پس از اتمام هر پرونده میتوانید نتیجه آن را اعلام نمایید و در صورت پایان یا فسخ قرارداد میتوانید آن را مردود نمایید.',
        'If the customer is introduced by your distributor:':'در صورتی که مشتری از طرف توسعه دهنده شما معرفی شده باشد:',
        'The file is suspended so that you can determine the type and direction of the applicant’s request. If the file is not completed after 7 days, a message will be displayed next to the list of the desired customer as a reminder, and if you do not pay attention after 14 days, the file will be removed from your home page and will be referred to another service owner.':'پرونده در حالت معلق قرار میگیرد تا شما نوع و مسیر درخواست متقاضی را تعیین نمایید. چنانچه پس از 7 روز پرونده تعیین تکیف نشود، پیغامی کنار لیست مشتری مورد نظر جهت یادآوری نمایش داده میشود و در صورت عدم توجه پس از 14 روز، پرونده از داشبورد شما حذف خواهد شد و به وکیل دیگری ارجاع داده خواهد شد.',
        'After determining the path of the file, a message will be sent to your distributor so that he can proceed with the next administrative affairs with the customer, and after the approval of the distributor, the file of customer will be displayed for you completely. The customer will also be informed about the choice and his service owner.':'پس از تعیین مسیر پرونده، پیغامی به توسعه دهنده شما ارسال خواهد شد تا بتواند امور اداری بعدی را با مشتری پیش ببرد و پس از تأیید توسعه دهنده، پرونده مشتری به طور کامل برای شما به نمایش گذاشته میشود. همچنین مشتری از انتخاب مسیر و وکیل خود آگاه خواهد شد.',
        'By choosing your service owner and colleague, you can share a specific file with them. Accordingly, the customer and the distributor will not be informed and they will only know you. In this way, you can benefit from the support of your colleague in the path of a case.':'با انتخاب وکیل منتخب و همکار خود میتوانید پرونده مشتری خاصی را با وی به اشتراک بگذارید. بدین روش مشتری و توسعه دهنده از این امر با خبر نخواهند شد و در واقع تنها شما را میشناسند. با این روش میتوانید از همراهی همکار خود در مسیر یک پرونده بهره‌مند شوید.',
        'Your chosen service owner can only see the details and documents of the customer. He cannot access to files (adding or deleting) and cannot send a direct message to the customer.':'وکیل منتخب شما تنها میتواند مشخصات و مدارک مشتری را ببیند، دسترسی وی به حذف یا افزودن پرونده مسدود بوده و امکان ارسال مستقیم پیام به مشتری را ندارد.',
        'Distributors will never have access to customer information, documents and records in any part of the program or file.':'توسعه دهندگان هیچ گاه دسترسی به اطلاعات، مدارک و پرونده مشتری ها در هیچ بخش از برنامه یا مسیر پرونده نخواهند داشت.',
        'Page of distributors:':'منو توسعه دهندگان',
        'Distributors play a key role in your business. In fact, more distributors equal more customers. Distributors will enter the Telesiness Network with special, complex conditions and strict rules, at the discretion of the service owners and at their own risk, and establish a working relationship with the page home and service owner of the customer. Distributors have limited access to their customers and can also have a large network of agents by attracting and training agents to attract a large number of customers. In fact, distributors are large marketing companies and complexes that market to business owners with different ways.':'توسعه دهندگان نقش کلیدی برای کسب و کار شما ایفا میکنند. در واقع توسعه دهنده بیشتری مساوی است با مشتری بیشتر. توسعه دهندگان با شرایط خاص، پیچیده و قوانین محکمی وارد شبکه تلزینس خواهند شد، با صلاح دید وکلا و با مسئولیت خود وکیل میزبان وارد تلزینس میشوند و ارتباط کاری با داشبورد وکیل میزبان را برقرار میکنند. توسعه دهندگان دسترسی محدودی به مشتریان خود دارند و همچنین میتوانند با جذب و آموزش نمایندگی، شبکه بزرگی از مارکترها را داشته باشند تا تعداد زیادی از مشتریان را جذب نمایند. در حقیقت توسعه دهندگان مجموعه یا شرکت های مارکتینگ بزرگی هستند که با روش های مختلف برای صاحبین بیزینس بازاریابی میکنند.',
        'If you wish, you can have a marketing network with the home page distributor for your company providing it to the employee, colleague or IT department. This is only possible for business owners to access the marketing department and save on advertising costs. For more information about this section, you can contact the support section of Telesiness.':'در صورت تمایل شما میتوانید با داشتن یک داشبورد توسعه دهنده برای مجموعه خودتان و قرار دادن آن در اختیار کارمند، همکار یا بخش IT مجموعه خودتان، شبکه مارکتینگ داشته باشید. این امکان تنها برای صاحبین بیزینس فراهم شده تا بتوانند به بخش مارکتینگ دسترسی داشته و با کمک آن در هزینه های تبلیغاتی صرفه جویی کنند. جهت راهنمایی بیشتر درمورد این بخش میتوانید با پشتیبانی تلزینس تماس حاصل فرمایید.',
        'In this section, you can view the list of your distributors and communicate with them and their customers. You will also see the number of employees or their agents.':'در این بخش قابلیت مشاهده لیست توسعه دهندگان خود را دارید و با آنها و مشتریانشان در ارتباط هستید. همچنین تعداد کارمندان یا نمایندگان آنها را مشاهده خواهید کرد.',
        'Distributors can be attracted to your home page and cooperate with the list of services and tariffs. Therefore, be sure to complete your services section with the best and fairest numbers and commissions.':'توسعه دهندگان نسبت به لیست خدمات و تعرفه های در نظر گرفته شده میتوانند جذب داشبورد شما شده و وارد همکاری شوند. لذا حتما بخش سرویس های خودتان را با بهترین و منصفانه ترین اعداد و پورسانت ها تکمیل نمایید.',
        'By clicking on the button + and adding it, you can invite your experienced and trustworthy people to cooperate.':'با کلیک بر روی دکمه + و افزودن میتوانید از افراد با تجربه و امین خود دعوت به همکاری نمایید.',
        'Page of Business Owner:':'منو وکلا',
        'In this section, you will see the list of business owners that you invited. Collaborating with other business owners allows you to share a case or attract customers who are not in your field of work. You can also appoint managers on your files with the help of other colleagues in this section.':'در این بخش لیست وکیل هایی که دعوت کردید را مشاهده خواهید کرد. همکاری با وکلای دیگر به شما این امکان را میدهد تا یک پرونده را به اشتراک بگذارید و یا مشتریانی را جذب نمایید که در حیطه کاری شما نمیباشند. همچنین میتوانید با کمک گرفتن از همکاران دیگر در این بخش، مدیرانی روی پرونده های خودتان تعیین نمایید.',
        ' My Services Page:':'منو خدمات من',
        'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors. The pricing part and commissions are displayed only to distributors and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.':'این بخش از اهمیت بالایی برخوردار است. شما در این بخش باید سرویس های خود را به طور کامل تکمیل نمایید. لیست خدمات شما برای توسعه دهندگان به نمایش گذاشته خواهد شد. بخش قیمت گذاری و پورسانت توسعه دهندگان تنها برای آنها نمایش داده میشود و مشتری به هیچ عنوان از آنها با خبر نخواهد بود. برای مشتریان تنها بخش تکمیلی خدمات به مشتریان نمایش داده خواهد شد تا بتوانند پرونده خودشان را تکمیل نمایند.',
        'The cost of your services is highly competitive and can affect the attraction of distributors and customers. Also, the commission you consider for distributors is highly competitive and will greatly affect your willingness to work with them.':'هزینه خدمات شما به شدت رقابتی می باشد و میتواند در جذب توسعه دهندگان و مشتریان تاثیر بگذارد. همچنین پورسانتی که برای توسعه دهندگان در نظر میگیرید به شدت رقابتی بوده و تأثیر زیادی بر اشتیاق همکاری با شما خواهد گذاشت.',
        'The smarter you define “my service department”, the more customers you will be able to attract.':'هر چه بخش خدمات من را هوشمندانه‌تر تعریف کنید، امکان جذب مشتری بیشتری خواهید داشت. ',
        'Note: The costs and prices that you define in this section will not change under any conditions. Therefore, you should be careful when defining costs.':'توجه: هزینه و قیمت هایی که در این بخش تعریف میکنید تحت هیچ شرایطی تغییر نخواهد کرد. لذا در تعریف هزینه ها دقت کافی داشته باشید.',
        'Sharing the page of customers with colleague service owners:':'منو مشتریان مشترک با وکلای همکار',
        'If you share a file, their list can be seen here. Be sure the files you share on another home page of the service owner are for showing without any purpose, and he or she cannot delete or add information, or even communicate with the customer.':'در صورت به اشتراک گذاشتن پرونده ای، لیست آنها در این قسمت قابل مشاهده است. اطمینان داشته باشید،  پرونده های به اشتراک گذاشته شده توسط شما روی داشبورد وکیل دیگر صرفا جهت نمایش بوده و او نمیتواند اطلاعاتی را حذف یا اضافه نماید و یا حتی نمیتواند با مشتری در ارتباط باشد',
        'In fact, this section is for managing customers, by managers or other service owners due to your opinion. Make sure that your colleagues or service owners are only persons that can view the files of the customers that you have shared with them and they cannot change your files and information of the customers.':'در حقیقت این بخش جهت مدیریت مشتریان، توسط مدیران یا وکلای دیگر با صلاح دید شما می باشد. اطمینان داشته باشید همکاران یا وکلای در نظر گرفته شده تنها قابلیت رویت پرونده مشتری هایی را دارند که شما با آنها به اشتراک گذاشتید و نمیتوانند تغییری در مدارک و اطلاعات مشتری شما بوجود آورند.',
        'Page of customers referred by distributors:':'منو مشتریان ارجاع شده توسط توسعه دهندگان',
        'In this section you can see the list of referred customers from your distributors and manage them.':'در این بخش میتوانید لیستی از مشتریان ارجاع شده از توسعه دهندگان خودتان را ببینید و آنها را مدیریت نمایید.',
        'Page of Messenger:':'منو پیام‌رسان',
        'Your page of messenger is defined smartly in order that you can set the time and quality of your performance. In this section you will see your contact list. The limitations imposed are the result of research and experience of your colleagues. So that the customers can send you a message once. After receiving a reply from you, they can send message again. Distributors can only text you three times continuously, and after the third message they have to wait for your reply to open the messages for them.':'صفحه پیام‌رسان شما به طور هوشمند تعریف شده است تا کمکی جهت تنظیم وقت و کیفیت عملکرد شما باشد. شما در این بخش لیست مخاطبین خود را میبینید. محدودیت های اعمال شده نتیجه تحقیقات و تجربیات همکاران شما بوده است. به طوری که: مشتریان فقط میتوانند یک بار پیام برای شما ارسال نمایند. پس از دریافت پاسخ از طرف شما، مجدد میتوانند یک بار دیگر پیام دهند. توسعه دهندگان میتوانند فقط سه بار به شما به طور متوالی پیام دهند و پس از پیام سوم باید منتظر پاسخ شما باشند تا پیام‌رسان برایشان باز شود.',
        'You can send six continuous messages to your distributors and nine continuous messages to your customers. You can send unlimited messages to your service owner and colleague.':'شما میتوانید به توسعه دهندگان خود شش پیام متوالی و به مشتریان نه پیام متوالی ارسال نمایید. شما میتوانید به طور نامحدود به وکیل و همکار خود پیام ارسال نمایید.',
        'Page of Services:':'منو خدمات',
        'In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.':'در این بخش شما دسترسی به کلیه سرویس ها و بیزینس ها دارید و میتوانید از هر کدام که مورد نیازتان باشد استفاده نمایید. همچنین از این بخش میتوانید با صاحبین مشاغل دیگر آشنا شوید و با آنها همکاری داشته باشید.',
        'Home Page Guide of Distributors':'راهنمای پنل توسعه دهندگان',
        'The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of four parts:':'سیستم امتیاز دهی این برنامه از اهمیت بالایی برخوردار است. چرا که معیار عملکرد شما و کیفیت خدمات شما به آن بستگی دارد و میتواند در روابط کاری شما تاثیر بگذارد. همچنین تعداد مشتریان شما میتواند با این امتیازات کم و زیاد شود. این سیستم از چهار بخش تشکیل شده:',
        'Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 40% of your total point.':'بخش دوم :امتیازی است که مشتریان به شما میدهند. مشتریان شما از 1 تا 5 ستاره را با توجه به رضایتشان انتخاب کرده و به شما خواهند داد که برای هر ستاره امتیازی در نظر گرفته شده است. این امتیازها نسبت به نتیجه ارائِه خدمات شما محاسبه خواهد شد. در نهایت امتیاز مشتری 40% از کل امتیاز شما را شامل میشود.',
        'Third part: Agent points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.':'بخش سوم :امتیاز نمایندگان است که نسبت به خدمات شما، آنها امتیاز میدهند و در محاسبه کلی 20% از کل امتیاز شما را پوشش خواهد داد.',
        'Fourth part: The scores or points of distributor or service owner who give you points according to the amount of cooperation between each other and it will cover 20% of your total point in the calculations.':'بخش چهارم :امتیاز توسعه دهندگان یا وکلایی است که نسبت به همکاری بین شما امتیاز خواهند داد و در محاسبات 20% از کل امتیاز شما را پوشش خواهد داد.',
        'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful distributors and the business owners will select you and to work with you.':'در نهایت مجموع امتیاز ها با تعداد کاربران محاسبه و عدد نهایی را به شما نمایش خواهیم داد. این امتیاز را تمامی کاربران در طول مسیر رویت خواهند کرد و در نهایت با توجه به امتیازات، شما در لیست موفق ترین توسعه دهندگان قرار خواهید گرفت تا صاحبین کسب و کار شما را برگزیده و با شما همکاری نمایند.',
        'The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also, you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.':'عملکرد این بخش هنگام معرفی یک مشتری به صورت در حال انتظار می باشد تا شما پس از بررسی مشخصات و اطلاعات آنها جهت نوع ویزا تصمیم بگیرید و آن مشتری را به وکیل مورد نظر متصل کنید. پس از بررسی اولیه مدارک و اطلاعات اولیه مشتری توسط وکیل مربوطه، فعالیت وکیل روی موکل جدید مشخص خواهد شد و شما با رویت فعال شدن پرونده مشتری به رنگ سبز متوجه تایید وکیل و مسیر پرونده مشتری خواهید شد.',
        'After entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.':'سپس شما میتوانید بعد از تکمیل امور اداری تایید نهایی را بزنید که در این حالت راه های ارتباطی با مشتری برای وکیل پرونده باز خواهد شد و همچنین مشتری وکیل پرونده خود را خواهد دید و این ارتباط بین آنها شکل خواهد گرفت.',
        'From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.':'با ورود به داشبورد مشتری میتوانید به بخشی از اطلاعات اولیه مشتری دسترسی داشته باشید. در صورت نیاز میتوانید پرونده مشتری را با توسعه دهندگان دیگر به اشتراک بگذارید تا آنها بتوانند وکیل مناسبی را پیدا کنند. همچنین از این بخش میتوانید مستقیم به مشتری مورد نظر پیام ارسال نمایید.',
        'Page of Service Owners:':'منو وکلا',
        'In this section, you will see the list of service owner that you invited. This section is very important because if you have suitable and experienced service owners, you can attract more customers and expand your business.  So, you should invite the appropriate service owners and start a good interaction with them.':'در این بخش لیست وکیل هایی که دعوت کردید را میبینید. این بخش از اهمیت خاصی برخوردار میباشد. چرا که با داشتن وکلای مناسب و مجرب میتوانید مشتریان بیشتری را جذب نمایید و کسب و کار خودتان را گسترش دهید. پس وکلای مناسب را دعوت کنید و با آنها تعامل خوبی را آغاز کنید.',
        'In this section you will see a list of your distributors. You can have a list of partners in your network by attracting and training successful groups. They can introduce more customers to you by forming a network and attracting more agents.':'در این بخش لیست توسعه دهندگان خود را میبینید. شما میتوانید با جذب و آموزش مجموعه های موفق لیستی از همکاران را در شبکه خود داشته باشید. آنها با تشکیل شبکه و جذب نمایندگان بیشتر میتوانند مشتریان بیشتری به شما معرفی نمایند.',
        'Page of Agents:':'منو نمایندگان',
        'Agents play a key role in your business. The more agents you have, the more customers you will have. You can also have a large network of agents by attracting and training the agents to attract a large number of customers.':'نمایندگان نقش کلیدی برای کسب و کار شما ایفا میکنند. هر چه نماینده بیشتری داشته باشید، در نتیجه مشتری بیشتری خواهید داشت. همچنین میتوانید با جذب و آموزش دهی به نماینده، شبکه بزرگی از مارکترها را داشته باشید تا تعداد زیادی از مشتریان را جذب نمایید.',
        'Page of colleague distributor:':'منو توسعه دهنده همکار',
        'In this section, you can specify services, administrative affairs and financial resources with your colleagues.':'در این بخش میتوانید با همکاران خود سرویس ها، امور اداری و منابع مالی را مشخص نمایید.',
        'Page of referred customers by distributor:':'منو مشتریان ارجا شده از سمت توسعه دهنده',
        'In this section, you can see the list of agents referred by the distributors.':'در این بخش میتوانید لیستی از مشتریان ارجاع شده از سمت توسعه دهندگان را مشاهده نمایید.',
        'Page of referred customers by agents:':'منو مشتریان ارجاع شده از سمت نماینده',
        'In this section, you can see the list of customers referred by your agents.':'در این بخش میتوانید لیستی از مشتریان ارجاع شده از سمت نمایندگان خودتان را مشاهده نمایید.',
        'This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors and agents. The pricing part and commissions are displayed only to distributors and agents, and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.':'این بخش از اهمیت بالایی برخوردار است. شما در این بخش باید سرویس های خود را به طور کامل تکمیل نمایید. لیست خدمات شما برای توسعه دهندگان و نمایندگان به نمایش گذاشته خواهد شد. بخش قیمت گذاری و پورسانت توسعه دهندگان و نمایندگان تنها برای آنها نمایش داده میشود و مشتری به هیچ عنوان از آنها با خبر نمیشود. تنها بخش تکمیلی خدمات به مشتریان نمایش داده خواهد شد تا بتوانند پرونده خودشان را تکمیل نمایند.',
        'The cost of your services is highly competitive and can affect the attraction of distributors and agents. Also, the commission you consider for distributors and agents is highly competitive and will greatly affect your willingness to work with them.':'هزینه خدمات شما به شدت رقابتی می باشد و میتواند در جذب توسعه دهندگان و نمایندگان تاثیر بگذارد. همچنین پورسانتی که برای توسعه دهندگان و نمایندگان در نظر میگیرید به شدت رقابتی بوده و تاثیر بسزایی بر اشتیاق همکاری با شما میگذارد.',
        'Home Page Guide of Agents':'راهنمای پنل نماینده',
        'Second part: It is the score or point that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 30% of your total point.':'بخش دوم :امتیازی است که مشتریان به شما میدهند. مشتریان شما از 1 تا 5 ستاره را با توجه به رضایتشان انتخاب کرده و به شما خواهند داد که برای هر ستاره امتیازی در نظر گرفته شده است. این امتیازها نسبت به نتیجه ارائِه خدمات شما محاسبه خواهد شد. در نهایت امتیاز مشتری 30% از کل امتیاز شما را شامل میشود.',
        'Third part: It is the point of distributor giving you due to the services you provide for them and these points will cover 50% of your total points.':'بخش سوم :امتیاز توسعه دهنده است که نسبت به خدمات شما، آنها امتیاز میدهند و در محاسبه کلی 50% از کل امتیاز شما را پوشش خواهد داد.',
        'Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful agents and the business owners will select you and to work with you.':'در نهایت مجموع امتیاز ها با تعداد کاربران محاسبه و عدد نهایی را به شما نمایش خواهیم داد. این امتیاز را تمامی کاربران در طول مسیر رویت خواهند کرد و در نهایت با توجه به امتیازات، شما در لیست موفق ترین نمایندگان قرار خواهید گرفت تا صاحبین کسب و کار شما را برگزیده و با شما همکاری نمایند.',
        'In this section, you can completely see your customers’ files and also check your list of new customers.':'در این بخش شما به طور مختصر میتوانید پرونده های مشتریان خود را رویت کرده، لیست مشتریان جدید خود را بررسی نمایید و همچنین میتوانید مستقیم به مشتریان پیام ارسال نمایید.',
        'Page of Service of Distributors:':'منو خدمات توسعه دهنده',
        'In this section you will see the list of services and tarrif and introduce the customers by forming a network and attracting more agents.':'در این بخش میتوانید لیست خدمات و تعرفه ها را مشاهده کرده تا توسط این لیست مشتریان خود را معرفی نمایید.',
        'You can send messages to your distributors and customers. You can send messages to the Passport section of Telesiness.':'در این بخش میتوانید به توسعه دهنده یا مشتریان خود پیام ارسال کرده و با بخش ساپورت تلزینس در تماس باشید.',
        'Guide of Home Page for the Customer':'راهنمای پنل مشتری',
        'The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owner rests with you and the application will only help your relationship.':'در بخش پنجم لیست موفق ترین صاحبین مشاغل بر اساس کارکرد، امتیاز و رضایت مشتریان دیگر برای شما نمایش داده میشود. این لیست کاملا رقابتی بوده و پس از آنالیز شرایط خاص کاربر، سیستم به صورت اتوماتیک آنها را در این بخش قرار میدهد. شما با مشاهده امتیاز و مشخصات آنها میتوانید درخواست خود را ثبت نمایید و با آنها همکاری نمایید. تمامی مسئولیت مذاکرات و ارتباط کاری بین شما و صاحبین مشاغل به شخص خود شما مربوط بوده و اپلیکشن تنها راهی جهت ارتباط بین شما و کسب و کارها می باشد.',
        'In this part, you will see the agent and service owner related to your file.':'در این صفحه شما نماینده و وکیل مربوط به پرونده خودتان را مشاهده می‌کنید.',
        'In this part, the process of the file and list of documents depending on the type of you request are displayed at the bottom of the page and you can see them.':'همچنین روند پرونده و لیست مدارک نسبت به نوع درخواست شما در انتهای صفحه نمایش داده میشود.',
        'My Request Page:':'منو درخواست من',
        'In this section you can create your own immigration file':'در این بخش شما میتوانید پرونده مهاجرتی خودتان را ایجاد نمایید.',
        'First of all, you should create a file and enter you basic and general information. After the approval of the relevant service owner and determining the type of visa or your immigration route, the other facilities of departments will be activated for you.':'ابتدا با تشکیل پرونده اطلاعات اولیه و عمومی خودتان را ثبت کرده، پس از تایید وکیل مربوطه و تعیین نوع ویزا یا مسیر مهاجرتی شما، امکانات دیگر بخش ها برای شما فعال خواهد شد.',
        'In the following, after the service owner approves your file and documents, you will give your personal and legal documents to the service owner and he will access to your personal and legal documents by completing the required information. All your information and documents will be sent to the service owner, so you should be careful when completing the file and sending the documents.':'در ادامه پس از تایید وکیل، شما با ارسال مدارک و تکمیل اطلاعات مورد نیاز، دسترسی مدارک شخصی و حقوقی خودتان را به وکیل پرونده‌تان خواهید داد. تمامی اطلاعات و مدارک شما عینا برای وکیل مورد نظر ارسال خواهد شد، لذا در تکمیل دقیق پرونده، ارسال مدارک و صحت آنها دقت نمایید.',
        'Please upload the documents accurately and regularly in the specified locations. Failure to complete the forms and upload the documents in this section will be delayed the process of your file by the chosen service owner.':'لطفا به طور دقیق و منظم مدارک را در جایگاه های مشخص شد بارگذاری نمایید. عدم دقت در تکمیل فرم ها و بارگذاری مدارک در این بخش باعث میشود روند پرونده شما از سوی وکیل منتخب به تاخیر بی‌افتد.',
        '1- All documents should be in PDF format.':'1-	تمامی مدارک باید با فرمت PDF باشد',
        '2- All documents should be scanned correctly (Mobile scanning in not accepted).':'2-	تمامی مدارک باید به درستی اسکن شده باشند. (اسکن موبایل مورد قبول نمیباشد)',
        '3- All documents should have a maximum size of 4 MB.':'3-	تمامی مدارک باید حداکثر حجم 4 مگابایت  را دارا باشند.',
        '4- All documents should be officially translated.':'4-	تمامی مدارک باید ترجمه رسمی شده باشند.',
        'Each document, regardless of the number of pages, must be in a file with the above conditions.':'هر کدام از مدارک صرفه نظر از تعداد صفحات، باید در یک فایل با شرایط فوق قرار گیرد.',
        'By using the messenger section of the program, you can share your question or problem with your agent or service owner. After sending the message, you have to wait for his response. After replying, you can send the message again.':'از بخش پیام‌رسان برنامه شما میتوانید سوال یا مشکلی که دارید را با نماینده یا وکیل خود درمیان بگذارید. پس از ارسال پیام باید منتظر پاسخ آنها بمانید. پس از پاسخگویی میتوانید مجدد پیام ارسال نمایید.',
        'In this section, you have access to all services and businesses and you can use any of the items that you need. In this section, you can also get acquainted with other business owners and cooperate with them.':'در این بخش شما دسترسی به کلیه سرویس ها و بیزینس ها دارید و میتوانید از هر کدام که مورد نیازتان باشد استفاده نمایید. همچنین از این بخش میتوانید با صاحبین مشاغل دیگر آشنا شوید و با آنها همکاری داشته باشید.',
        'All Customers':'همه مشتریان',
        'Agents Customers':'مشتریان نماینده',
        'DCs':'توسعه دهندگان',
        'Select a DS':'انتخاب توسعه دهنده',
        'Work Email':'ایمیل کاری',
        'Related Distributor Name':'نام توسعه دهنده همکار',
        'Agent Name':'نام نماینده',
        'Related lawyers':'وکلای همکار',
        'Distributers Customers':'مشتریان توسعه دهنده',
        'Service Code':'کد خدمات',
        'Process Of Service':'فرآیند خدمات',
        'Documents':'مدارک',
        'Submit Date':'تاریخ ایجاد',
        'Distributor Service Code':'کد خدمات توسعه دهنده',
        'Distributer Information':'اطلاعات توسعه دهنده',
        'Congratulations! your visa has been approved':'تبریک! ویزای شما تایید شده است',
        'your reqiured document is:':'مدارک موردنیاز:',
        'Opss! We try with other Lawyer':'اوه! ما با وکیل دیگری تلاش می کنیم',
        'Congratulations! your visa process Started be in touch':'تبریک! مراحل ویزای شما شروع شده است در تماس باشید',
        'your visa name is:':'نام ویزا:',
        'your visa process is:':'فرآیند ویزا:',
        'Congratulations! Your Visa Accepted.':'تبریک! ویزای شما پذیرفته شد',
        'Opss! Your Visa Rejected.':'اوه! ویزای شما رد شد',
        'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.':'اوه! ویزای شما توسط وکیل رد شده است، ما با وکیل دیگری تلاش خواهیم کرد.',
        'Your New Collaboration Requests':'درخواست های همکاری جدید شما',
        'DS: ':'توسعه دهنده: ',
        'DC offered to me:':'پیشنهاد توسعه دهنده به من:',
        'My Offered to DC:':'پیشنهاد من به توسعه دهنده:',
        'I rejected:':'رد شده توسط من:',
        'DC rejected:':'رد شده توسط توسعه دهنده:',
        'List of Tariffs For Related DCs':'لیست خدمات توسعه دهنده همکار',
        'MyOfferToOtherDis':'پیشنهاد من به توسعه دهنده دیگر',
        'Congratulations! Your Visa Accepted.(Telesiness Will be with you)':'تبریک می گویم! ویزای شما پذیرفته شد (Telesiness همراه شما خواهد بود)',
        'Opss! Your Visa Rejected.(Telesiness Will be with you)':'اوه! ویزای شما رد شد. (Telesiness همراه شما خواهد بود)',
        'Opss! Your Visa Declined byLawyer,We will Try With other Lawyer.(Telesiness Will be with you)':'اوه! ویزای شما توسط وکیل رد شد، با وکیل دیگری امتحان خواهیم کرد. (Telesiness با شما خواهد بود)',
        'Visitor Visa':'ویزای توریستی',
        'Student Visa (University/ College)':'ویزای تحصیلی',
        'Student Visa (Primary School, High School)':'ویزای دانش آموزی',
        'Work Visa':'ویزای کاری',
        'Express Entry':'ورود سریع',
        'Skilled Worker':'کارگر ماهر',
        'Canadian Experience':'تجربه ی کانادایی',
        'Family Sponsership':'اسپانسرشیپ خانواده',
        'Provincial Nominees':'نامزدهای استانی',
        'Atlantic Immigration Program':'برنامه ی مهاجرتی آتلانتیک',
        'Start-up Visa':'ویزای استارت آپ',
        'Business Visa':'ویزای بیزینسی',
        'Nurse Visa':'ویزای پرستاران',
        'Physician Visa':'ویزای پزشکان',
        'Self Employed (Athletes and Artists,…)':'ویزای خوداشتغالی-هنرمندان و ورزشکاران',
        'Refugee':'پناهندگی',
        'Permanent Residency':'اقدام به اقامت دائم',
        'Invitation Letter/ Conference/ Seminar':'دعوت نامه/همایش/سمینار',
        'Lawyer service information':'اطلاعات خدمات وکیل',
        'Name of Service':'نام خدمات',
        'There is no service assigned yet.':'هنوز هیچ سرویسی تعیین نشده است.',
        'Services assigned by the lawyer':'خدمات اختصاص داده شده توسط وکیل',
        'Your Score in Telesiness Shows Your Activity':'امتیاز شما در تلزینس نمایانگر کیفیت و میزان فعالیت شما می باشد',
        'Please be careful in filling the required fields of the two sections of personal information and further information':'لطفا در پر کردن فیلد های الزامی دو بخش اطلاعات شخصی و اطلاعات تکمیلی دقت نمایید',
        'Profile':'پروفایل',
        'Settings & Privacy':'تنظیمات و حریم خصوصی',
        'Language':'زبان',
        'Management':'مدیریت',
        'Messenger Setting':'تنظیمات پیام رسان',
        'Dashboard Setting':'تنظیمات داشبورد',
        'Account Setting':'تنظیمات حساب کاربری',
        'Accounting':'حسابداری',
        'Offers':'پیشنهادات',
        'According to this section of the guide, you can manage all sections.':'با توجه به این بخش از راهنما میتوانید تمامی قسمت های داشبورد را مدیریت نمایید.',
        'You need to know that if all parts of your dashboard are not completed, your ability to cooperate and earn income will be greatly impacted reduced.':'ابتدا بدانید اگر تمامی بخش های داشبورد شما کامل نشود ، توانایی همکاری و کسب درآمد شما به شدت کاهش میابد.',
        '1- Ask your lawyers or your distributors to specify the services that can be provided.':'1- از وکلای خود یا توسعه دهندگان خود بخواهید تا سرویس های قابل ارائه را مشخص نمایند.',
        'a. In this case, you can define goals for your agents regarding the types of services and tariffs specified or give advice to customers in that field.':'a. در این صورت شما میتوانید نسبت به انواع سرویس ها و تعرفه های مشخص شده ، برای نمایندگان خود هدف تعریف کنید و یا به مشتریان در آن زمینه مشاوره دهید.',
        'b. By clicking the Vie Service button, you can see all the services provided by them from the main menu of the lawyer’s or distributor’s profile.':'b. شما میتوانید از منوی اصلی بخش وکلا یا توسعه دهندگان با زدم دکمه مشاهده سرویس ، تمامی سرویس های ارائه شده از سمت انها را ببینید.',
        '2- After having a complete list of services of lawyers or distributors, you can enter the My Services section from the main menu.':'2- پس از داشتن لیست کامل سرویس ها از طریق وکلا یا توسعه دهندگان ، میتوانید وارد بخش سرویس های من از منوی اصلی شوید.',
        'a. In this section, you are free to enter the services provided with the same name and price or change them according to your marketing conditions (However, distributors and agents of your subcategory will see the list of your services and will not have access to the services provided by lawyers or developers that you are working with.)':'a. در این بخش شما مختارید سرویس های ارائه شده را با همان نام و قیمت وارد کنید و یا آنها را نسبت به شرایط مارکتینگ خودتان تغییر دهید ((به هر حال توسعه دهندگان و نمایندگان زیرمجموعه شما ، لیست سرویس های شما را خواهند دید و دسترسی به سرویس های ارائه شده از سمت وکلا یا توسعه دهنده شما را نخواهند داشت))',
        'b. Import your services and specify a rate and tariff for each one. According to your services, other distributors and agents will cooperate with you.':'b. سرویس های خودتان را وارید کنید و برای هر کدام نرخ و تعرفه ای مشخص نمایید. با توجه به سرویس های شما ، توسعه دهندگان دیگر و نمایندگان با شما همکاری خواهند داشت.',
        'c. Specify the commission of your agents. Keep in mind that all agents will see the entered commission in the same way. If you are satisfied with a specific agent, you can consider a higher commission for that specific agent from the main menu of the agent section. This process will only apply to that agent and other agents will not see this offer.':'c. پورسات نمایندگان خودتان را مشخص نمایید. در نظر داشته باشید تعرفه های وارد شده را به طور یکسان تمامی نمایندگان خواهند دید. در صورت داشتن رضایت کافی از نماینده خاص میتوانید از منوی اصلی بخش نمایندگان ، برای نماینده خاص ، پورسانت بیشتری در نظر بگیرید . این فرایند فقط برای آن نماینده اعمال خواهد شد و نمایندگان دیگر این پیشنهاد را نخواهند دید.',
        '3- Customer section':'3- بخش مشتری',
        'a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard':'a. منوی اصلی ، دکمه دوم (مشتریان) لیست مشتری هایی هستند که مستقیم خود شما وارد داشبوردتان کرده اید',
        'b. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.':'b. منوی اصلی ، دکمه هفتم (مشتریان توسعه دهنده) لیست مشتریانی است که از سمت توسعه دهندگان به شما معرفی شده است',
        'c. Eighth button of the main menu (agent customers) is the list of customers who have been introduced to you by agents.':'c. منوی اصلی ، دکمه هشتم (مشتریان نماینده ) لیست مشتریانی است که از سمت نمایندگان به شما معرفی شده است',
        '1. In general, every customer who enters your dashboard, you have the right to refer them to the right lawyer according to the general conditions and the customer’s request, or if you don’t have a lawyer, refer them to your distributor.':'1. در مجموع هر مشتری که وارد داشبورد شما شود ، شما اختیار دارید با توجه به شرایط عمومی و درخواست مشتری ، آن را به وکیل مناسب ارجاع دهید و یا در صورت نداشتن وکیل آن را به توسعه دهنده خود ارجاع دهید.',
        'a. Please note that the customer’s account will not be activated until the lawyer makes a final decision for the customer, and until activated, the lawyer will not have direct access to your customer’s contact information, nor will the customer see the lawyer’s information.':'a. در نظر بگیرید تا زمانی که وکیل پرونده تصمیم نهایی را برای مشتری نگیرد ، حساب کاربری مشتری فعال نخواهد شد و تا زمان فعال شدن ، وکیل دسترسی مستقیم به اطلاعات تماس با مشتری شما را نخواهد داشت ، همچنین مشتری اطلاعات وکیل را نخواهد دید.',
        'b. After the initial approval of the lawyer, you can proceed with your work process to ensure the financial issues, then by pressing the approval button, both parties can communicate directly with each other.':'b. پس از تایید اولیه وکیل ، شما میتوانید فرایند کاری خود را جهت اطمینان از مسائل مالی پیش ببرید ، سپس با زدن دکمه تایید هر دو طرف میتوانند به طور مستقیم با یکدیگر ارتباط برقرار کنند.',
        '4- Finally, try to increase the number of your connections. As the number of lawyers is higher, the number of services and the possibility of selling more products will increase. The more distributors and agents you have, the more customers you can attract and the higher your income.':'4- در نهایت سعی کنید تعداد ارتباطات خودتان را افزایش دهید. هرچه تعداد وکلا بالاتر باشد نتیجتا تعداد سرویس ها و امکان فروش محصولات بیشتر ، افزایش خواهد یافت.  هرچه تعداد توسعه دهنده و نماینده شما بیشتر شود ، امکان جذب مشتری بیشتر و کسب درآمد بالاتر خواهد بود.',
        '5- Distributors can work directly with lawyers, but agents can only work with their own distributor and can only introduce customers to their distributor’s services. So, be careful about recruitment or the scope of authority of each person and act according to the conditions and rules set by Telesiness.':'5- توسعه دهندگان میتوانند مستقیم با وکلا هم همکاری کنند ولی نمایندگان فقط با توسعه دهنده خودشان میتوانند همکاری کنند و فقط میتوانن مشتری نسبت به خدمات توسعه دهنده خود معرفی کنند. پس نسبت به جذب نیرو یا حیطه اختیارات هر فرد محتاط باشید و نسبت به شرایط و قوانین گذاشته شده از سمت تلزینس اقدام نمایید.',
        '6- Complete your profile. Increase the number of your users and teach your subordinates how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts':'6- پروفایل خودتان را تکمیل نمایید ، تعداد کاربران خودتان را افزایش دهید و نحوه کار با داشبورد را به زیرمجموعه خود آموزش دهید ، به زودی بخش های دیگر اپلیکیشن برای کسب درآمد بالاتر باز خواهد شد که شما باید به امتیازات مدنظر نسبت به فعالیتتان رسیده باشید تا بتوانید از آن بخش ها استفاده نمایید.',
        'Instructions':'دستورالعمل ها',
        'c. You should also specify your service list at first.':'c. همچنین در ابتدا باید لیست خدمات خودتان را هم مشخص نمایید.',
        '2- After having a complete list of your services or services of lawyers or distributors, you can enter the My Services section from the main menu.':'2- پس از داشتن لیست کامل سرویس های خودتان یا وکلا یا توسعه دهندگان ، میتوانید وارد بخش سرویس های من از منوی اصلی شوید.',
        'a. In this section, you can enter a complete list of your partners or your services so that other distributors can market them for you (consider that all distributors will see the list of services and costs entered in the same way)':'a. در این بخش شما میتوانید لیست کاملی از خدمات خودتان یا همکارتان وارد نمایید تا دیگر توسعه دهندگان بتوانند نسبت به آن برای شما مارکتینگ نمایند ( در نظر بگیرید که لیست خدمات و هزینه های وارد شده را تمامی توسعه دهندگان به طور یکسان خواهند دید)',
        'b. According to the list of services, fees and commission rates, more distributors will be attracted to cooperate with you.':'b. با توجه به لیست خدمات ، هزینه ها و نرخ پورسانت ، تعداد بیشتری از توسعه دهندگان جذب همکاری با شما میشوند.',
        'a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.':'a. منوی اصلی دکمه دوم (مشتریان) لیست مشتری هایی هستند که مستقیم خود شما وارد داشبوردتان کرده اید.',
        'b. Sixth button of the main menu (Referral Customer) is the list of customers that you have shared with other lawyers.':'b. منوی اصلی دکمه ششم (Referral Customer مشتریان ارجاعی) لیست مشتریانی است که شما با وکلای دیگر به اشتراک گذاشته اید.',
        'c. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.':'c. منوی اصلی دکمه هفتم (مشتریان توسعه دهنده ) لیست مشتریانی است که از سمت توسعه دهندگان به شما معرفی شده است.',
        '1. In general, every customer who enters your dashboard, you have the right to choose a service for them according to the general conditions and customer requests. When you choose a service for a customer, the description of that service will appear for the customer, and with this action, you will specify the type of customer’s visa.':'1. در مجموع هر مشتری که وارد داشبورد شما شود ، شما اختیار دارید با توجه به شرایط عمومی و درخواست مشتری ، سرویسی برای آن انتخاب نمایید . زمانی که یک سرویس برای مشتری انتخاب میکنید ، شرح آن سرویس برای مشتری نمایان میشود و با این عمل شما نوع ویزای مشتری را مشخص خواهید کرد.',
        'a. Consider that if the customer is from your distributor’s side, after choosing the service, you must wait for their approval.':'a. در نظر بگیرید اگر مشتری از سمت توسعه دهنده شما باشد ، پس از انتخاب خدمات باید منتظر تایید وی باشید.',
        'b. After your distributor approves, the customer’s contact information will appear for you. Also, your contact information will be known to the customer.':'b. پس از تایید توسعه دهنده شما ، اطلاعات تماس مشتری برای شما ظاهر خواهد شد . همچنین اطلاعات تماس شما برای مشتری مشخص خواهد شد.',
        'c. If you wish, you can share the client with your lawyer and colleague. In this case, you must add your colleague to the list of lawyers.':'c. در صورت تمایل میتوانید مشتری را با وکیل و همکار خود به اشتراک بگذارید. در این صورت باید از قبل همکار خود را به لیست وکلا اضافه نمایید.',
        '4- Finally, try to increase the number of your connections. The higher the number of your distributors, the higher the chance of attracting customers. Distributors can attract agents to sell your services. So, by giving enough information to the distributors, it is possible that the number of your marketers will be more and as a result you will have more customers.':'4- در نهایت سعی کنید تعداد ارتباطات خودتان را افزایش دهید. هرچه تعداد توسعه دهنده شما بالا باشد ، شانس جذب مشتری بیشتر خواهد شد. توسعه دهندگان امکان جذب نماینده ها جهت فروش خدمات شما را دارند . پس با دادن اطلاعات کافی به توسعه دهندگان ، این امکان به وجود میاد که تعداد بازاریابان شما بیشتر و نتیجتا مشتری بیشتری خواهید داشت. ',
        '5- Complete your profile. Increase the number of your users and teach your employees how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts.':'5- پروفایل خودتان را تکمیل نمایید ، تعداد کاربران خودتان را افزایش دهید و نحوه کار با داشبورد را به کارمندان خود آموزش دهید ، به زودی بخش های دیگر اپلیکیشن برای کسب درآمد بالاتر باز خواهد شد که شما باید به امتیازات مدنظر نسبت به فعالیتتان رسیده باشید تا بتوانید از آن بخش ها استفاده نمایید.',
        '':'',
      }
      
    },
    // add other language to the object
  };