import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2,MessageText1,Star1} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = ({childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const handleSendMsgReDirect = (CustomerName) => {
        localStorage.setItem('CusNameMsg',CustomerName);
        
    }

    const [people , setPeople] = useState([
        {AgentPic:'CusPic.png', 
        AgentName:'',
        AgentPhone:'',
        AgentStatus:'Active',
        AgentClients:'24 ',
        AgentsCount:'4', 
        Email:'',
        isDistributorPlus:'',
        peopleA:[''],
        key:'129121'},
        
       
      ]);

      const [finalRespList , setfinalRespList] = useState([
        {AgentPic:'CusPic.png', 
        AgentName:'',
        AgentPhone:'',
        AgentStatus:'Active',
        AgentClients:'24 ', 
        Email:'',
        key:'129121', 
        AdvocacyCode:'',
        City:'',
        Country:'',
        Address:'',
        Clients:'',
        customerList:[{}],
    },
       
      ]);
    // const [peopleA , setPeopleA] = useState([
    // {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ',AgentsCount:'4', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
    
    // ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.AgentClients-b.AgentClients)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }
    const [values, setValues] = useState({
        Email: '',
        password: '',
        checked: '',
        refCode: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorsByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DistributorEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(response=>{
                setIsOpen2(!isOpen2);
                values.Email='';
            })
    }
    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey) =>{
        if(isOpenRow){
           
           
            document.getElementById(itemKey).style.display="flex";
            setisOpenRow(!isOpenRow);
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen72, setIsOpen72] = useState(true);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }
    const togglePopup72 = () => {
    setIsOpen72(!isOpen72);
    }

      const [respFinal , setrespFinal] = useState([]);
      const [resp2, setresp2]=useState([]) ;
      const [resp21, setresp21]=useState([]) ;
      const [dep1, setdep1]=useState(false) ;
      const [dep2, setdep2]=useState(false) ;
      const [dep3, setdep3]=useState(false) ;
      const [dep31, setdep31]=useState(false) ;
      const [dep21, setdep21]=useState(false) ;
      const [dep11, setdep11]=useState(false) ;
      const [dep4, setdep4]=useState(false) ;

      const fetchDatarespFinel = () => {
        fetch('https://telesiness.ir/api/Dashboards/LawyerDashboardDistributorsList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
            
              // console.log(response[0]);
              setrespFinal(response);
              setdep1(true);
              setdep11(true);
              togglePopup72();
              
          });
          if(people.length<2)
                 people.pop();
          // if(finalRespList.length<2)
          // finalRespList.pop();
      }

      const fetchDatarespFinel2 = () => {

        var j1 = 0;
        var j = 0;
        var nmeLocal='';
        for (j in respFinal.distributorList){
                    
                       fetch('https://telesiness.ir/api/Dashboards/DistributorCustomersNameAndCount' , {
                           method: 'POST',
                           headers: { 
                            'Content-Type': 'application/json' ,
                            'Cache-Control': 'no-cache'
                        },
                           body: JSON.stringify({ Email: localStorage.getItem('email') ,DistributorCode:respFinal.distributorList[j].distributorCode })
                           }).then(response2 => response2.json()).then(response2=>{
                            
                            nmeLocal = 'resp213'+j1;
                            localStorage.setItem(nmeLocal,JSON.stringify(response2));
                        
                            // setfinalRespList(response2);
                            // finalRespList.push( {
                            //     Clients:JSON.parse(localStorage.getItem('resp213'+j1)).lawyerCounts,
                            //     lawyerTariffsList:JSON.parse(localStorage.getItem('resp213'+j1)).lawyerTariffsList,
                            //     key:(parseInt(j)+1).toString(),
                            //     AgentPic:'CusPic.png', 
                            //     AgentName:'',
                            //     AgentPhone:'',
                            //     AgentStatus:'Active',
                            //     AgentClients:'24 ', 
                            //     Email:'',
                            //     AdvocacyCode:'',
                            //     City:'',
                            //     Country:'',
                            //     Address:'',

                            
                            // },)
                            
      
                            j1++;
                            
                           })

        }

        

        setdep2(true);
 
    }


    const fetchDatarespFinel3 = () => {
        var i =0;
        var myActDec = '';
        var nmeLocal='';
        try{
        if(dep2)
        for (i in respFinal.distributorList){
            nmeLocal = 'resp213'+i;
            console.log(JSON.parse(localStorage.getItem('resp213'+i))); 
            if(respFinal!=undefined)
            if(respFinal.distributorList[i].lawyerStatus == false)
            myActDec = "Deactive";
            else
            myActDec = "Active";
            //    resp21= (JSON.parse(localStorage.getItem('resp21')))
            people.push({
            AgentPic:respFinal.distributorList[i].distributorAvatar, 
            AgentName:respFinal.distributorList[i].distributorFirstName + " " + respFinal.distributorList[i].distributorLastName,
            AgentPhone:respFinal.distributorList[i].distributorPhoneNumber,
            AgentStatus:myActDec,
            AgentClients:respFinal.distributorList[i].clientsCount, 
            AgentsCount:respFinal.distributorList[i].clientsCount,
            Email:respFinal.distributorList[i].distributorWorkMail,
            key:((parseInt(i))+2).toString(),
            AgentCity:respFinal.distributorList[i].distributorCity,
            AgentCountry:respFinal.distributorList[i].distributorCountry,
            isDistributorPlus:respFinal.distributorList[i].isDistributorPlus,
            peopleA: JSON.parse(localStorage.getItem('resp213'+i)).customerList,
             dcCode: respFinal.distributorList[i].distributorCode,
            distributorCount:JSON.parse(localStorage.getItem('resp213'+i)).distributorCount,
            countOfDcLawyers:JSON.parse(localStorage.getItem('resp213'+i)).countOfDcLawyers,
            countOfDcInvitedByDc:JSON.parse(localStorage.getItem('resp213'+i)).countOfDcInvitedByDc,
            countOfDcAgents:JSON.parse(localStorage.getItem('resp213'+i)).countOfDcAgents,
            },)
            // people.push( {
            // AgentClients:respFinal.distributorList[i].customerVisaName,
            // AgentPic:'CusPic.png', 
            // AgentName:respFinal.distributorList[i].lawyerFirstName 
            // + " " + 
            // respFinal.distributorList[i].lawyerLastName,
            // AgentPhone:respFinal.distributorList[i].lawyerPhoneNumber,
            // AgentStatus:myActDec,
            // AdvocacyCode:respFinal.distributorList[i].lawyerCode,
            // City:respFinal.distributorList[i].lawyerCity,
            // Country:respFinal.distributorList[i].lawyerCountry,
            // Address:respFinal.distributorList[i].lawyerAddress,
            // Email:respFinal.distributorList[i].lawyerEmailAddress,
            // Clients:JSON.parse(localStorage.getItem('resp21'+i)).lawyerCounts,
            // lawyerTariffsList:JSON.parse(localStorage.getItem('resp21'+i)).lawyerTariffsList,
            // key:(parseInt(i)+2).toString(),},)
            
                
                            }
                        //  setdep3(true);
                        } catch{}  
                            
                        for (i in respFinal.distributorList){
                            localStorage.removeItem('resp213'+i); 
                        }            
                            setdep3(true);
                            console.log(finalRespList);
        }
        
        const fetchDatarespFinel4 = () => {
            setdep4(true);
            console.log(finalRespList);
            
        }



        const fetchDatarespFinel21 = () => {


            var i =0;
            var j1 = 0;
            var j = 0;
            var nmeLocal='';

                    for (j in respFinal.lawyerBeforeDistributor){
                    

                       fetch('https://telesiness.ir/api/Dashboards/DistributorCustomersNameAndCount' , {
                           method: 'POST',
                           headers: { 'Content-Type': 'application/json' },
                           body: JSON.stringify({ Email: localStorage.getItem('email') ,DistributorCode:respFinal.lawyerBeforeDistributor[j].distributorCode })
                           }).then(response21 => response21.json()).then(response21=>{
                            nmeLocal = 'resp214'+j1;


                                nmeLocal = 'resp214'+j1;
                                localStorage.setItem(nmeLocal,JSON.stringify(response21));
                                console.log(j1);
                            console.log(respFinal.lawyerBeforeDistributor[j1].distributorCode);
                            console.log(respFinal.lawyerBeforeDistributor[j1]);
                            console.log(respFinal);
                            console.log(JSON.parse(localStorage.getItem('resp214'+j1)));
                                // setfinalRespList(response2);
                               
                                
          
                                j1++;
                                
                               })
    
            }
    
            setdep21(true);
     
        }

        const fetchDatarespFinel31 = () => {
            var k =0;
            var k1 =0;
            var k12 =0;
            var myActDec = '';
            var nmeLocal='';
            try{
            if(dep2)
            for (k in respFinal.lawyerBeforeDistributor){
                nmeLocal = 'resp214'+k1;
                console.log(nmeLocal);
                console.log(k1);
                console.log(k);
                console.log(JSON.parse(localStorage.getItem('resp214'+k))); 
                // if(respFinal!=undefined)
                if(respFinal.lawyerBeforeDistributor[k].lawyerStatus == false)
                myActDec = "Deactive";
                else
                myActDec = "Active";
                // resp21= (JSON.parse(localStorage.getItem('resp21')))
                people.push({AgentPic:respFinal.lawyerBeforeDistributor[k].distributorAvatar, 
                AgentName:respFinal.lawyerBeforeDistributor[k].distributorFirstName + " " + respFinal.lawyerBeforeDistributor[k].distributorLastName,
                AgentPhone:respFinal.lawyerBeforeDistributor[k].distributorPhoneNumber,
                AgentStatus:myActDec,
                AgentClients:respFinal.lawyerBeforeDistributor[k].clientsCount, 
                AgentsCount:respFinal.lawyerBeforeDistributor[k].clientsCount,
                Email:respFinal.lawyerBeforeDistributor[k].distributorWorkMail,
                key:(parseInt(k)+1).toString(),
                AgentCity:respFinal.lawyerBeforeDistributor[k].distributorCity,
                AgentCountry:respFinal.lawyerBeforeDistributor[k].distributorCountry,
                isDistributorPlus:respFinal.lawyerBeforeDistributor[k].isDistributorPlus,
                peopleA: JSON.parse(localStorage.getItem('resp214'+k1)).customerList,
                dcCode: respFinal.lawyerBeforeDistributor[k].distributorCode,
                distributorCount:JSON.parse(localStorage.getItem('resp214'+k1)).distributorCount,
                countOfDcLawyers:JSON.parse(localStorage.getItem('resp214'+k1)).countOfDcLawyers,
                countOfDcInvitedByDc:JSON.parse(localStorage.getItem('resp214'+k1)).countOfDcInvitedByDc,
                countOfDcAgents:JSON.parse(localStorage.getItem('resp214'+k1)).countOfDcAgents,
                    //peopleA: [''],
                },)
                
                    k1++;
                                }
                            //  setdep3(true);
                        } catch{}        
                        for (k12 in respFinal.lawyerBeforeDistributor){
                          localStorage.removeItem('resp214'+k12); 
                        }
                                setdep31(true);
                                console.log(finalRespList);
            }


    //   fetch('https://telesiness.ir/api/Dashboards/LawyerDashboardDistributorsList' , {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ Email: localStorage.getItem('email') })
    //     }).then(response => response.json()).then(response=>{
    //          var i =0;
    //          var j =0;
    //          try{ 

    //             if(people.length<2)
    //             {
    //                 // people.pop();
    //                 var MyStatus
    //                 if(response.distributorList[i].distributorStatus==true)
    //                  MyStatus = 'Active';
    //                  else
    //                  MyStatus = 'Deactive';
                     
    //                  for (j in response.lawyerBeforeDistributor){
                     

    //                     fetch('https://telesiness.ir/api/Dashboards/DistributorCustomersNameAndCount' , {
    //                         method: 'POST',
    //                         headers: { 'Content-Type': 'application/json' },
    //                         body: JSON.stringify({ Email: localStorage.getItem('email') ,DistributorCode:response.lawyerBeforeDistributor[j].distributorCode })
    //                         }).then(response => response.json()).then(response2=>{

                            

    //                     people.push({AgentPic:response.lawyerBeforeDistributor[j].distributorAvatar, 
    //                     AgentName:response.lawyerBeforeDistributor[j].distributorFirstName + " " + response.lawyerBeforeDistributor[j].distributorLastName,
    //                     AgentPhone:response.lawyerBeforeDistributor[j].distributorPhoneNumber,
    //                     AgentStatus:MyStatus,
    //                     AgentClients:response.lawyerBeforeDistributor[j].clientsCount, 
    //                     AgentsCount:response.lawyerBeforeDistributor[j].clientsCount,
    //                     Email:response.lawyerBeforeDistributor[j].distributorWorkMail,
    //                     key:(parseInt(j)+1).toString(),
    //                     AgentCity:response.lawyerBeforeDistributor[j].distributorCity,
    //                     AgentCountry:response.lawyerBeforeDistributor[j].distributorCountry,
    //                     isDistributorPlus:response.lawyerBeforeDistributor[j].isDistributorPlus,
    //                     peopleA: response2.customerList,
    //                     dcCode: response.lawyerBeforeDistributor[i].distributorCode,

    //                     countOfDcLawyers:response2.countOfDcLawyer,
    //                     countOfDcInvitedByDc:response2.countOfDcInvitedByD,
    //                     countOfDcAgents:response2.countOfDcAgent,
    //                     distributorCount:response2.distributorCount,
    //                     countOfDcLawyers:response2.countOfDcLawyers,
    //                     countOfDcInvitedByDc:response2.countOfDcInvitedByDc,
    //                     countOfDcAgents:response2.countOfDcAgents,
    //                     //peopleA: [''],
    //                 },)
    //             });
    //                 }
    //             //     for (i in response.distributorList){


    //             //         fetch('https://telesiness.ir/api/Dashboards/DistributorCustomersNameAndCount' , {
    //             //             method: 'POST',
    //             //             headers: { 'Content-Type': 'application/json' },
    //             //             body: JSON.stringify({ Email: localStorage.getItem('email') ,DistributorCode:response.distributorList[i].distributorCode })
    //             //             }).then(response => response.json()).then(response2=>{


    //             //         people.push({AgentPic:response.distributorList[i].distributorAvatar, 
    //             //         AgentName:response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName,
    //             //         AgentPhone:response.distributorList[i].distributorPhoneNumber,
    //             //         AgentStatus:MyStatus,
    //             //         AgentClients:response.distributorList[i].clientsCount, 
    //             //         AgentsCount:response.distributorList[i].clientsCount,
    //             //         Email:response.distributorList[i].distributorWorkMail,
    //             //         key:((parseInt(j)+1 + parseInt(i))+1).toString(),
    //             //         AgentCity:response.distributorList[i].distributorCity,
    //             //         AgentCountry:response.distributorList[i].distributorCountry,
    //             //         isDistributorPlus:response.distributorList[i].isDistributorPlus,
    //             //         peopleA: response2.customerList,
    //             //         dcCode: response.distributorList[i].distributorCode,
    //             //         distributorCount:response2.distributorCount,
    //             //         countOfDcLawyers:response2.countOfDcLawyers,
    //             //         countOfDcInvitedByDc:response2.countOfDcInvitedByDc,
    //             //         countOfDcAgents:response2.countOfDcAgents,
    //             //     },)
    //             // });
    //             //     }
                    
    //             }
    //             // if(peopleA.length<2)
    //             // {
    //             //     peopleA.pop();
    //             //     for (i in response.distributorList.clientsName){
     
    //             //         peopleA.push({
    //             //         AgentName:response.distributorList.clientsName[i].customerFirstName + " " + response.distributorList.clientsName[i].customerLastName,
    //             //         Email:response.distributorList.clientsName[i].email,
    //             //         key:response.distributorList.clientsName[i].customerCode,
    //             //         userId:response.distributorList.clientsName[i].userId,
    //             //        },)
    //             //     }
    //             // }
              
                
    //          }
  
    //         catch{}
    //         // console.log(response.distributorList[0].distributorFirstName);
    //         //   forceUpdate();
    //         // window.location.reload(false);
            
    //     });

       useEffect(() => {
    fetchDatarespFinel();
    // console.log(data[0].countery_Name);
  }, []);
const [staterespFinel, setStaterespFinel] = useState({ num: 0 });
const counterrespFinel = useRef(0);

useEffect(() => {
    fetchDatarespFinel21();
    // console.log(data[0].countery_Name);
  }, [dep1]);

  useEffect(() => {
    fetchDatarespFinel31();
    // console.log(data[0].countery_Name);
  }, [respFinal.lawyerBeforeDistributor]);

useEffect(() => {
    fetchDatarespFinel2();
    // console.log(data[0].countery_Name);
  }, [dep1]);

  useEffect(() => {
    fetchDatarespFinel3();
    // console.log(data[0].countery_Name);
  }, [respFinal.distributorList]);



  useEffect(() => {
    fetchDatarespFinel4();
    
    // console.log(data[0].countery_Name);
  }, [dep3]); 

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 1) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
      
            return () => clearTimeout(timer);
          }
        }, [state]);
      
    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Distributers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                        <p className="pricesInfo">{t('Distributers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Distributer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                {/* <div className="col-1"><p>
                <input type="checkbox"></input>
                    </p> </div> */}
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Distributer')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Phone Number')}</p></div>
                <div className="col-3"><p>{t('Email')}</p></div>
                <div className="col-2"><p>{t('Send Message')}</p></div>
               
                {/* <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-1"><p>{t('Clients')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div> */}
                </div>
            <hr className="CustomerLine"/>
        </div><div className="mainRowCus col-12">
                {people.map(item =>(
                    <div className="itemBox2 col-12" key={item.key}>
                        <div className="agentCusRow col-12">
                        <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                            <div className="col-1 id_agentCus"><p>{item.key}</p> </div>
                            <div className="CusPic2 col-lg-3 col-12">
                                <div className='img_agentCus'>
                                    {item.isDistributorPlus=='1' &&<Star1 className='star_ofdcpluslist' size="20" color="#007eee" variant="Bold"/>}
                                    {item.AgentPic!='' &&<img src={'https://telesiness.ir/AgentsAvatar/'+ item.AgentPic} alt="Avatar" />}
                                    {item.AgentPic==''&&<img src={`${baseUrl + 'CusPic.png'}`} alt="" />}
                                </div>
                                <div className='detail_agentCus'>
                                    <p>
                                        {item.AgentName} 
                                    </p>
                                    <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                </div>
                            </div>
                            <div className="col-3 phone_agentCus"><p>{item.AgentPhone}</p></div>
                            <div className="col-3 mail_agentCus"><p>{item.Email}</p></div>
                            <div className="Send_Message">
                                <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.AgentName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.key);
                                localStorage.setItem('dcCode',item.dcCode);
                                localStorage.setItem('MsgUserNewType',2);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.AgentName)}}/>
                                <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                            </div>
                            {/* {item.AgentStatus=='Active' && <div className="col-lg-2 col-6 de_active_agentCus">
                                <button  className="btn-sm active">
                                    {t('Active')}
                                </button></div>}

                            {item.AgentStatus=='Deactive' && <div className="col-lg-2 col-6 de_active_agentCus">
                                <button  className="btn-sm deactive">
                                    {t('Deactive')}
                                </button>                              
                                </div>}
                            <div className="col-1 count_agentCus"><p>{item.AgentClients}</p></div> */}
                        </a>
                    </div>  
                   {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                            <hr className="linebetitem col-12"/>
                            <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.AgentName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.key);
                                localStorage.setItem('MsgUserNewType',2);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.AgentName)}}/>
                            <div className="col-lg-3 col-12 hidenItem">
                                    <span>{t('Clients')}:</span>
                                    <p>{item.distributorCount}
                                    </p>
                                </div>
                                <div className="col-lg-3 col-12 hidenItem">
                                    <span>{t('Lawyers')}:</span>
                                    <p>{item.countOfDcLawyers}
                                    </p>
                                </div>
                                <div className="col-lg-3 col-12 hidenItem">
                                    <span>{t('Distributers')}:</span>
                                    <p>{item.countOfDcInvitedByDc}
                                    </p>
                                </div>
                                <div className="col-lg-3 col-12 hidenItem">
                                    <span>{t('Agents')}:</span>
                                    <p>{item.countOfDcAgents}
                                    </p>
                                </div>
                            <div className="col-lg-4 col-12 hidenItem">
                                <select
                                    aria-label="Default"
                                    // labelId="demo-simple-select-Degree-helper-label"
                                    id="ChineseFamiliarityS"
                                    value={values.ChineseFamiliarityS}
                                    label={t('Type of Marriage')}
                                    // MenuProps={MenuProps}
                                    onChange={handleChange('ChineseFamiliarityS')}  
                                    placeholder={t('Familiarity with the chosen language')}
                                    >
                                        <option  value="">
                                        {t('Customers Name')}
                                    </option >
                                     {item.peopleA.map(item2 =>(
                                         <option value={item2.customerCode}>{item2.customerFirstName + " " + item2.customerLastName}</option >
                                    ))}

                                </select >
                            </div>
                            <div className="d-lg-none col-12 phone_agentCusm hidenItem"><span>{t('Phone Number')}:</span><p>{item.AgentPhone}</p></div>
                            <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('Country')}:</span> <p>{item.AgentCountry}</p> </div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('City')}:</span> <p>{item.AgentCity}</p> </div>
                            {/* <div className="col-lg-4 col-12 hidenItem"><span>{t('Agents')}:</span> <p>{item.AgentsCount}</p> </div> */}
                        </div>
                    </div>}
                    <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                </div>
                        ))}
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why do You want to De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}

{isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
    </>
        
    );
}
 
export default Agents;