import {SearchNormal,Notification,
    ArrowDown2,HambergerMenu,ArrowLeft,Wallet1,
    Facebook,Whatsapp,CallCalling,Instagram,Youtube
    ,Home2,TickSquare,I24Support,Star1} from 'iconsax-react';
import { useState , useEffect , useRef} from 'react';
import avatarPicture from './images/Avatar.png';
import { useTranslation } from 'react-i18next'; // multi language package
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import LeftNavBar from '../LeftNavBar/LeftNavBar';
import Popup from './Popup';
import Popup2 from './Popup2';
import Popup3 from './Popup3';
import mainPic from './404.png';
import teleLogo from './teleLogo.png';
import RegisterLogo from './teleLogo.png';
import HelpIcon from '@mui/icons-material/Help';
import { blue, grey } from '@mui/material/colors';


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
  }


const TopMenu = ({userActive , childToParent ,
        childToParentCus,
        childToParentCusA2 , 
        childToParentCusADis , 
        childToParentCusA2A , 
        childToParentCus1 ,
        childToParentAgents ,
        childToParentPrices , 
        childToParentLawyer,
        childToParentLawyers,
        childToParentAgentAgent,
        childToParentLawyerHome,
        childToParentAgentHome,
        childToParentCustomerHome,
        childToParentMarketerHome,
        childToParentCusMark,
        childToParentMessage,
        childToParentRefCustomers,
        childToParentMarketerDist ,
        childToParentAgentDis,
        childToParentAgentPrice,
        childToParentMarketerPrice,
        childToParentDCCustomers,
}) => {
    const forceUpdate = useForceUpdate();
    const [countNotification,setCountNotification] = useState(true);
    const [countProfile,setCountProfile] = useState(true);
    const [values, setValues] = useState({
        Email: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        SelectLanguage:localStorage.getItem('langV'),
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       
      };
      const {t, i18n} = useTranslation(['Login']);
      const  handelIR = (e) => {
          // e.preventDefault();
          i18n.changeLanguage('fa_IR');
          document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
          document.body.setAttribute('lang', 'fa');
  }
      const  handelUS = (e) => {
          // e.preventDefault();
          i18n.changeLanguage('en_US');
          document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
          document.body.setAttribute('lang', 'en');
          // localStorage.getItem('userType');
  }
  if(localStorage.getItem('langL')){
  
  }
  else
  {
    localStorage.setItem('langL' , "English");
    localStorage.setItem('langV' , 1);
  }
  
  const [selectValue , setSelectValue] = useState({ label: localStorage.getItem('langL'), value: localStorage.getItem('langV') });
  useEffect(() => {
    if(values.SelectLanguage != 2)
    {
      handelUS();
      localStorage.setItem('langV' , 1);
      localStorage.setItem('langL' , "English");
    }
     
    else
    {
      handelIR()
      localStorage.setItem('langV' , 2);
      localStorage.setItem('langL' , "Persian");
    }
      
  }, [values.SelectLanguage]);
    const history = useHistory();
    const handleClickNotification = (e) => {
        if(!countNotification){
            setCountNotification(true);
            document.getElementById('messageBox1').style.display='none';
        }
        else{
            setCountNotification(false);
            document.getElementById('messageBox1').style.display='flex';
        }
    }
    const handleClickNotification2 = (e) => {
            document.getElementById('messageBox1').style.display='none';
            setCountNotification(true);
    }
    
    const handlemessageBox2Blur = (e) => {
       
            document.getElementById('messageBox2').style.display='none';
       
    }
    const handleClickProfile = (e) => {
        if(!countProfile){
            setCountProfile(true);
            document.getElementById('messageBox2').style.display='none';
        }
        else{
            setCountProfile(false);
            document.getElementById('messageBox2').style.display='block';
        }
    }
    const handleClickProfile2 = (e) => {
            document.getElementById('messageBox2').style.display='none';
            setCountProfile(true);
    }
    const handleClickSerachBox = (e) => {
        document.getElementById('SerachBox').style.display='flex';
}
const handleClickSerachBoxBlur = (e) => {
    document.getElementById('SerachBox').style.display='none';
}
// const handleLftMenuM = (e) => {
//     document.getElementById('SerachBox').style.display='none';
// }
// sign out
const handleSingOut = (e) => {
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('userType');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAgentActive'); 
    localStorage.removeItem('token');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('distributorPlusCode');
    localStorage.removeItem('messageNotificationName1');
    localStorage.removeItem('messageNotificationName2');
    localStorage.removeItem('messageNotificationName3');
    localStorage.removeItem('messageNotificationName4');
    localStorage.removeItem('messageNotificationName5');
    localStorage.removeItem('messageNotificationCount');
    fetch('https://telesiness.ir/api/LogOut' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
    }).then().then(()=>{
        // window.localStorage.clear();
        history.push('/')
    });
    // history.push('/');
}

// *********** popUp Left Menu Mobile ****
const [isOpen, setIsOpen] = useState(false);
const [isOpen2, setIsOpen2] = useState(false);

const [isOpen3, setIsOpen3] = useState(false);


useEffect(() => {

    if(((document.getElementById('MobileLeftNavPro') || document.getElementById('MobileLeftNavHelpCenter')) && window.innerWidth<750)){
        document.getElementById('leftNavMobileMenu').style.removeProperty('visibility');
        document.getElementById('leftNavMobileMenu').style.visibility="visible";
        setIsOpen(!isOpen);
        
      }


  }, [document.getElementById('MobileLeftNavPro')]);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);
useEffect(() => {
    if (counter.current < 2000) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
    


    return () => clearTimeout(timer);
    }
    }, [state]);



const togglePopup11 = () => {
  
  if(!isOpen && !(document.getElementById('MobileLeftNavPro') || document.getElementById('MobileLeftNavHelpCenter'))){
    document.getElementById('leftNavMobileMenu').style.visibility="hidden";
    setIsOpen(!isOpen);
  }
  else if(window.innerWidth<750){

    document.getElementById('leftNavMobileMenu').style.removeProperty('visibility');
    document.getElementById('leftNavMobileMenu').style.visibility="visible";
    setIsOpen(!isOpen);
  }
  forceUpdate();
  //console.log(isOpen);

} 
const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
}

const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
}

// forceUpdate();
//********** to dashboard */
const data = true;

//*********** */
return (  
    <div className="col-12 myRowDashMob">
        <div className="tpMenu col-lg-8 col-md-7 col-12">
            <div className="form-group has-success has-feedback col-12" id="searchbox">
                <input 
                autoComplete='none'
                onBlur={handleClickSerachBoxBlur} 
                onClick={handleClickSerachBox} 
                placeholder={t('Coming Soon')}
                disabled
                type="text" 
                className="form-control" 
                id="inputSuccess2"/>
                {/* <SearchNormal className="d-none d-md-block" size="18" variant="Bold" color="#000000"/> */}
                <HambergerMenu onClick={togglePopup11} className="d-flex d-md-none" size="24"  color="#000000"/>
            </div>
        </div>
        {/* <div className="SerachBox" id="SerachBox">
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Customers
            </a>
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Agents
            </a>
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Customers
            </a>
        </div>   */}
        <div className="tpHelp col-lg-4 col-md-5 col-4">
            {/* <a className="help_center col-xl-4 col-md-5 aNonDecorM APADING disabled" href="#">{t('Help Center')}</a> */}
            <div className="notification col-md-8 col-7">
                <Link className="help_center" onClick={togglePopup3} to='/Dashboard'><HelpIcon size="29" sx={{ fontSize: 29 , color: grey[900] }}/> </Link>
                {/* onClick={handleClickNotification} */}
                <a className="notif_center disabled"  href="#">
                    <Notification size="29" variant="Bold" color="#616161"/>
                    {/* {((parseInt(localStorage.getItem('messageNotificationCount'))!=0)&&localStorage.getItem('messageNotificationCount')) &&<span className="button__badge"><b>{ localStorage.getItem('messageNotificationCount')}</b></span>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))==0 &&<span><b>{ localStorage.getItem('messageNotificationCount')}</b></span>} */}
                </a>
                <Link onClick={togglePopup2} to='/Dashboard'><Wallet1 size="29" variant="Bold" color="#000000"/> </Link> 
                <div className="messageBox" id="messageBox1">
                    {parseInt(localStorage.getItem('messageNotificationCount'))>0 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="#" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName1')}</a> 
                    </p>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))>1 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName2')}</a> 
                    </p>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))>2 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName3')}</a> 
                    </p>}
                    <br />
                    <p className="aNonDecor">
                    <a href="#" onClick={(data)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            try{
                                document.getElementById('homeBtn').style.backgroundColor='transparent';
                                document.getElementById('homeBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                                document.getElementById('PeopleBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                            }
                            catch{}
                            
                            try{
                                document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
                                document.getElementById('PercentageSquareBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('PercentageSquareUserBtn').style.backgroundColor='transparent';
                                document.getElementById('PercentageSquareUserBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('GraphBtn').style.backgroundColor='transparent';
                                document.getElementById('GraphBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('Notepad2Btn').style.backgroundColor='transparent';
                                document.getElementById('Notepad2BtnP').style.color='white';
                            }
                            catch{}
                            
                            try{
                                document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
                                document.getElementById('BriefcaseBtnPLaw').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
                                document.getElementById('BriefcaseBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileAddBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('CommandBtn').style.backgroundColor='transparent';
                                document.getElementById('CommandBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileTickBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('CommandSquareBtn').style.backgroundColor='transparent';
                                document.getElementById('CommandSquareBtnP').style.color='white';
                            }
                            catch{}
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            childToParentMessage(data);
                            localStorage.setItem('messageNotificationCount',0);
                            handleClickNotification2();}} className="aDecor">{t('See more')}</a>
                    </p>
                </div>
            </div>
            
            <div className="avatarStyle col-md-4 col-5" >
                <div className="img_avatarStyle" onClick={handleClickProfile}>
                    {localStorage.getItem('userAvatar')=='UserAvatar.png' &&<img src={avatarPicture} alt="Avatar" />}
                    {localStorage.getItem('userType')==2 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                    {localStorage.getItem('userType')==1 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                    {localStorage.getItem('userType')==3 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                    {localStorage.getItem('userType')==4 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                </div>
                <div className="arrow_avatarStyle">
                    <div onClick={handleClickProfile} href="">
                    <ArrowDown2 size="18" color="#000000"/>
                    </div>
                </div>
                <div className="msg2Box">
                    <div className="messageBox2" id="messageBox2" onClick={handleClickProfile}>
                        <div className="top_mnuprof">
                            <div className="user_mnuprof">
                               <div className="img_mnuprof">
                                    {localStorage.getItem('userAvatar')=='UserAvatar.png' &&<img src={avatarPicture} alt="Avatar" />}
                                    {localStorage.getItem('userType')==2 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                                    {localStorage.getItem('userType')==1 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                                    {localStorage.getItem('userType')==3 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                                    {localStorage.getItem('userType')==4 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                               </div>
                               <div className="infouser_mnuprof">       
                                    <h3>
                                        {localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName')}
                                    </h3>
                                    <span>
                                        {localStorage.getItem('userType')=='1' && localStorage.getItem('lawyerCode')}
                                        {(localStorage.getItem('distributorPlusCode') && localStorage.getItem('distributorPlusCode')!='null' ) && <Star1  className="star_dcplusm" size="18" color="#007eee" variant="Bold"/>}
                                          {localStorage.getItem('userType')=='2' && localStorage.getItem('isDistributorPlus')!=1 && localStorage.getItem('agentCode')}
                                          {localStorage.getItem('userType')=='2' && localStorage.getItem('isDistributorPlus')==1 && localStorage.getItem('distributorPlusCode')}
                                        {localStorage.getItem('userType')=='3' && localStorage.getItem('customerCode')}
                                        {localStorage.getItem('userType')=='4' && localStorage.getItem('marketerCode')}
                                    </span>
                               </div>
                            </div>
                            <div className="btns_mnuprof">
                                {userActive && <button onClick={() => childToParent(data)} className="btn_mnprofile aNonDecor">
                                    {t('View Profile')} 
                                </button>}
                                <a href="#" className="btn_mnprofile">
                                    {t('Services')}
                                </a>
                            </div>
                        </div>
                        <div className="center_mnuprof">
                            <div className="secti_mnuprof">
                                <strong>{t('Profile')}</strong>
                                <a href="#" className="aNonDecor">
                                    {t('Services')}
                                </a>
                                <a href="#" className="aNonDecor">
                                    {t('Settings & Privacy')}
                                </a>
                                <Link to="/TermsLogin" className="aNonDecor">
                                    {t('Terms')}
                                </Link>
                            </div>
                            <div className="secti_mnuprof">
                                <strong>{t('Management')}</strong>
                                <a href="#" className="aNonDecor">
                                    {t('Messenger Setting')}
                                </a>
                                <a href="#" className="aNonDecor">
                                    {t('Dashboard Setting')}
                                </a>
                                <a href="#" className="aNonDecor">
                                    {t('Account Setting')}
                                </a>
                                <a href="#" className="aNonDecor">
                                    {t('Accounting')}
                                </a>
                                <a href="#" className="aNonDecor">
                                    {t('Offers')}
                                </a>
                            </div>
                        </div>
                        <div className="signout_mnuprof">
                            <a href="#" className="btn_mnprofile aNonDecor" onClick={handleSingOut}>
                            {t('Sign Out')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        {<Popup
  content={<>
  <div className="PboxMainRow">
  <ArrowLeft onClick={togglePopup11} className="close_navbar" size="20" color="#313131"/>
        <div className="PboxFirstRow">
            {/* <ShieldSecurity size="48" color="#313131" variant="Bold"/> */}
        </div>
        {/* <div className="PboxSecondRow">
        <h4>{t('Are You Sure?')}</h4>
        </div> */}
        <div>
        <LeftNavBar 
           childToParentPrices={childToParentPrices} 
           childToParentAgents={childToParentAgents} 
           childToParentAgentAgent = {childToParentAgentAgent}
           childToParentLawyers = {childToParentLawyers}
           childToParentLawyer={childToParentLawyer} 
           childToParentCus={childToParentCus} 
           childToParentCusA2={childToParentCusA2}
           childToParentCus1={childToParentCus1} 
           childToParentLawyerHome = {childToParentLawyerHome}
           childToParentAgentHome = {childToParentAgentHome}
           childToParentCustomerHome = {childToParentCustomerHome}
           childToParentMarketerHome = {childToParentMarketerHome}
           childToParentCusMark = {childToParentCusMark}
           childToParentMessage = {childToParentMessage}
           childToParentRefCustomers ={childToParentRefCustomers}
           childToParentDCCustomers={childToParentDCCustomers}
           childToParentMarketerDist  ={childToParentMarketerDist }
           childToParentCusADis={childToParentCusADis}
           childToParentCusA2A={childToParentCusA2A}
           childToParentAgentDis = {childToParentAgentDis}
           childToParentAgentPrice = {childToParentAgentPrice}
           childToParentMarketerPrice = {childToParentMarketerPrice}
           userActive={userActive}

           
            />
           
        </div>
        <div className="inputAndlabel col-lg-6 col-6">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='6'>{t('French')}</option >
                  <option  value='21'>{t('Chinese')}</option >
                  <option  value='5'>{t('Spanish')}</option >
                  <option  value='22'>{t('German')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicant’s Skin Color')}</label> */}
            </div>
        {/* <div className="PboxThirdRow">
            <p>{t('Menu Item Will Be Active After Reviewing Your Profile By Our Experts')}</p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={togglePopup} className="col-3 btn btn-dark">{t('I Got it')}</button>
        </div> */}
    
    </div>
  </>}
  handleClose={togglePopup11}
/>}


{isOpen2 && <Popup2
      content={<>
            {/* <div className="teleLogocom col-12">
                <img src={teleLogo} alt="" />
            </div>
            <div className="box_comsoon">          
                <div className='maintxt_comsoon col-12'>
                    <img src={mainPic} alt="" />
                </div>
            </div>
            {localStorage.getItem('userType')=='1'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('1500$')}</b></p> </div>}
            {localStorage.getItem('userType')=='2'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('1000$')}</b></p> </div>}
            {localStorage.getItem('userType')=='4'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('500$')}</b></p> </div>}
            <div className="txt_comsoon"> <p>{t('This Page is Under Construction, It will be available as soon as possible.')}</p> </div>
            <div className="setupNew btn_comsoon">
                <Link className='btn btn-dark' to='/Dashboard' onClick={togglePopup2}>{t('Return to Home Page')}</Link>
            </div> */}
            {/* <div className="Social_comsoon">
                <Facebook className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Whatsapp className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Youtube className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Instagram className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <CallCalling className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
            </div> */}
            {/* <div className="leftCircle404-01"></div>
            <div className="leftCircle404-02"></div>
            <div className="leftCircle404-03"></div> */}
              <>
    
      
          <div className="mainRegisterheader col-xxl-10 col-12">
            <div className="subRegisterrightheader col-md-8 col-12">
              <label className="form-check-forgot font-face-gm">
                {/* <Link className="font-face-gm backtohome txt_backhome" to='/'> {t('< Back to Home page')}</Link> */}
              </label>
              <h4 className="card-title font-face-gm">{t('Coming Soon')}</h4>
            </div>
            

          </div>     
          <div className="progressRowM6 serv_page col-12">
            <div className="myshapes">
              <div className="mycircle">
                <h6>{t('Soon!')}</h6>
              </div>
              <div className="myProCirlce5"></div>
            </div>
            {/* {localStorage.getItem('userType')=='1'&& <p  className="RegisterHeaderp txt_comsoonP d-none d-sm-block">{t('Your Cash is:')} <b> {t('1500$')}</b></p> } */}
            {/* {localStorage.getItem('userType')=='2'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('1000$')}</b></p>} */}
            {/* {localStorage.getItem('userType')=='4'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('500$')}</b></p> } */}
            <p className="RegisterHeaderp">{t('Dear user, these services will be available soon!')}</p>
                {/* <p className="RegisterHeaderp d-none d-sm-block">{t('Our experts are working on it.')}</p> */}
                  <Link to="/Dashboard" onClick={togglePopup2} className="btn btn-dark login-btnStep6 col-4">{t('Go to main page')}</Link>
                  
              
          </div>
          <div className="progressStyle col-xxl-10 col-12">
          </div>
      
   
  </>
      </>}
      handleClose={togglePopup2}
    />}

{isOpen3 && <Popup3
      content={<>
  
        <div className='body_homecenter col-12'>
            <div className="head_helpcenter col-12">
                <h4 className="font-face-gm">{t('Help Center')}</h4>
            </div>
            {/* Lawyer Help Center Contetnts */}
            {localStorage.getItem('userType') == 1 && <div className='total_services col-12'>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Home Page Guide of Business Owner')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Home Page')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Profile menu')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fourth icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fifth section')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see the number of successful, canceled and current files.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.')}</p>
                        </div>
                    </div>
                </div>
                
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Scoring system:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of three parts:`)}</p>
                            <p className="font-face-gm">{t(`First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 60% of your total point.`)}</p>
                            <p className="font-face-gm">{t(`Third part: Distributor points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful business owners and distributors will select you and to work with you.`)}</p>
                            <p className="font-face-gm">{t(`The world of Telesiness Network Marketing helps you to increase your points to have more customers.`)}</p>
                            <p className="font-face-gm">{t(`At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Customers:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can completely control your customers’ files and also check your list of new customers. The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.`)}</p>
                            <p className="font-face-gm">{t(`By entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.`)}</p>
                            <p className="font-face-gm">{t(`From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.`)}</p>
                            <p className="font-face-gm">{t(`If the customer is introduced by your distributor:`)}</p>
                            <p className="font-face-gm">{t(`The file is suspended so that you can determine the type and direction of the applicant’s request. If the file is not completed after 7 days, a message will be displayed next to the list of the desired customer as a reminder, and if you do not pay attention after 14 days, the file will be removed from your home page and will be referred to another service owner.`)}</p>
                            <p className="font-face-gm">{t(`After determining the path of the file, a message will be sent to your distributor so that he can proceed with the next administrative affairs with the customer, and after the approval of the distributor, the file of customer will be displayed for you completely. The customer will also be informed about the choice and his service owner.`)}</p>
                            <p className="font-face-gm">{t(`By choosing your service owner and colleague, you can share a specific file with them. Accordingly, the customer and the distributor will not be informed and they will only know you. In this way, you can benefit from the support of your colleague in the path of a case.`)}</p>
                            <p className="font-face-gm">{t(`Your chosen service owner can only see the details and documents of the customer. He cannot access to files (adding or deleting) and cannot send a direct message to the customer.`)}</p>
                            <p className="font-face-gm">{t(`Distributors will never have access to customer information, documents and records in any part of the program or file.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of distributors:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`Distributors play a key role in your business. In fact, more distributors equal more customers. Distributors will enter the Telesiness Network with special, complex conditions and strict rules, at the discretion of the service owners and at their own risk, and establish a working relationship with the page home and service owner of the customer. Distributors have limited access to their customers and can also have a large network of agents by attracting and training agents to attract a large number of customers. In fact, distributors are large marketing companies and complexes that market to business owners with different ways.`)}</p>
                            <p className="font-face-gm">{t(`If you wish, you can have a marketing network with the home page distributor for your company providing it to the employee, colleague or IT department. This is only possible for business owners to access the marketing department and save on advertising costs. For more information about this section, you can contact the support section of Telesiness.`)}</p>
                            <p className="font-face-gm">{t(`In this section, you can view the list of your distributors and communicate with them and their customers. You will also see the number of employees or their agents.`)}</p>
                            <p className="font-face-gm">{t(`Distributors can be attracted to your home page and cooperate with the list of services and tariffs. Therefore, be sure to complete your services section with the best and fairest numbers and commissions.`)}</p>
                            <p className="font-face-gm">{t(`By clicking on the button + and adding it, you can invite your experienced and trustworthy people to cooperate.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Business Owner:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you will see the list of business owners that you invited. Collaborating with other business owners allows you to share a case or attract customers who are not in your field of work. You can also appoint managers on your files with the help of other colleagues in this section.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t(` My Services Page:`)}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors. The pricing part and commissions are displayed only to distributors and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.`)}</p>
                            <p className="font-face-gm">{t(`The cost of your services is highly competitive and can affect the attraction of distributors and customers. Also, the commission you consider for distributors is highly competitive and will greatly affect your willingness to work with them.`)}</p>
                            <p className="font-face-gm">{t(`The smarter you define “my service department”, the more customers you will be able to attract.`)}</p>
                            <p className="font-face-gm">{t(`Note: The costs and prices that you define in this section will not change under any conditions. Therefore, you should be careful when defining costs.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Sharing the page of customers with colleague service owners:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`If you share a file, their list can be seen here. Be sure the files you share on another home page of the service owner are for showing without any purpose, and he or she cannot delete or add information, or even communicate with the customer.`)}</p>
                            <p className="font-face-gm">{t(`In fact, this section is for managing customers, by managers or other service owners due to your opinion. Make sure that your colleagues or service owners are only persons that can view the files of the customers that you have shared with them and they cannot change your files and information of the customers.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of customers referred by distributors:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section you can see the list of referred customers from your distributors and manage them.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Messenger:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`Your page of messenger is defined smartly in order that you can set the time and quality of your performance. In this section you will see your contact list. The limitations imposed are the result of research and experience of your colleagues. So that the customers can send you a message once. After receiving a reply from you, they can send message again. Distributors can only text you three times continuously, and after the third message they have to wait for your reply to open the messages for them.`)}</p>
                            <p className="font-face-gm">{t(`You can send six continuous messages to your distributors and nine continuous messages to your customers. You can send unlimited messages to your service owner and colleague.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Services:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.`)}</p>
                        </div>
                    </div>
                </div>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Instructions')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`According to this section of the guide, you can manage all sections.`)}</p>
                            <p className="font-face-gm">{t(`You need to know that if all parts of your dashboard are not completed, your ability to cooperate and earn income will be greatly impacted reduced.`)}</p>
                            <p className="font-face-gm">{t(`1- Ask your lawyers or your distributors to specify the services that can be provided.`)}</p>
                            <p className="font-face-gm">{t(`a. In this case, you can define goals for your agents regarding the types of services and tariffs specified or give advice to customers in that field.`)}</p>
                            <p className="font-face-gm">{t(`b. By clicking the Vie Service button, you can see all the services provided by them from the main menu of the lawyer’s or distributor’s profile.`)}</p>
                            <p className="font-face-gm">{t(`c. You should also specify your service list at first.`)}</p>
                            <p className="font-face-gm">{t(`2- After having a complete list of your services or services of lawyers or distributors, you can enter the My Services section from the main menu.`)}</p>
                            <p className="font-face-gm">{t(`a. In this section, you can enter a complete list of your partners or your services so that other distributors can market them for you (consider that all distributors will see the list of services and costs entered in the same way)`)}</p>
                            <p className="font-face-gm">{t(`b. According to the list of services, fees and commission rates, more distributors will be attracted to cooperate with you.`)}</p>
                            <p className="font-face-gm">{t(`3- Customer section`)}</p>
                            <p className="font-face-gm">{t(`a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.`)}</p>
                            <p className="font-face-gm">{t(`b. Sixth button of the main menu (Referral Customer) is the list of customers that you have shared with other lawyers.`)}</p>
                            <p className="font-face-gm">{t(`c. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.`)}</p>
                            <p className="font-face-gm">{t(`1. In general, every customer who enters your dashboard, you have the right to choose a service for them according to the general conditions and customer requests. When you choose a service for a customer, the description of that service will appear for the customer, and with this action, you will specify the type of customer’s visa.`)}</p>
                            <p className="font-face-gm">{t(`a. Consider that if the customer is from your distributor’s side, after choosing the service, you must wait for their approval.`)}</p>
                            <p className="font-face-gm">{t(`b. After your distributor approves, the customer’s contact information will appear for you. Also, your contact information will be known to the customer.`)}</p>
                            <p className="font-face-gm">{t(`c. If you wish, you can share the client with your lawyer and colleague. In this case, you must add your colleague to the list of lawyers.`)}</p>
                            <p className="font-face-gm">{t(`4- Finally, try to increase the number of your connections. The higher the number of your distributors, the higher the chance of attracting customers. Distributors can attract agents to sell your services. So, by giving enough information to the distributors, it is possible that the number of your marketers will be more and as a result you will have more customers.`)}</p>
                            <p className="font-face-gm">{t(`5- Complete your profile. Increase the number of your users and teach your employees how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts.`)}</p>
                        </div>
                    </div>
                </div>

            </div>}
            {/* *************************************** */}

            {/* DC Help Center Contetnts */}
            {localStorage.getItem('userType') == 2 && <div className='total_services col-12'>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Home Page Guide of Distributors')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Home Page')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Profile menu')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fourth icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fifth section')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see the number of successful, canceled and current files.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.')}</p>
                        </div>
                    </div>
                </div>
                
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Scoring system:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of four parts:`)}</p>
                            <p className="font-face-gm">{t(`First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Second part: It is the privilege that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 40% of your total point.`)}</p>
                            <p className="font-face-gm">{t(`Third part: Agent points are calculated due to the tariffs and services you provide to them and these points will cover 20% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Fourth part: The scores or points of distributor or service owner who give you points according to the amount of cooperation between each other and it will cover 20% of your total point in the calculations.`)}</p>
                            <p className="font-face-gm">{t(`Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful distributors and the business owners will select you and to work with you.`)}</p>
                            <p className="font-face-gm">{t(`The world of Telesiness Network Marketing helps you to increase your points to have more customers.`)}</p>
                            <p className="font-face-gm">{t(`At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Customers:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can completely control your customers’ files and also check your list of new customers.`)}</p>
                            <p className="font-face-gm">{t(`The function of this section is to introduce a customer while waiting. You need to decide on the type of visa after reviewing their details and information. After the initial check, you can choose the type of visa to guide your system to the next step. Also, you should keep in mind that after choosing the type of visa you cannot change it and the only way to edit is to reject it. After announcing the rejection, the customer must request from the beginning to go through the steps.`)}</p>
                            <p className="font-face-gm">{t(`After entering the home page of the customer, you can access all his information and documents. You can share the file of the customer with your colleagues or specify managers to follow up the file, if needed.`)}</p>
                            <p className="font-face-gm">{t(`From this section, you can also send a message directly to the desired customer and transfer the necessary information to him. After the completion of each file, you can announce the result and you can reject it in case of termination of the contract.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Service Owners:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you will see the list of service owner that you invited. This section is very important because if you have suitable and experienced service owners, you can attract more customers and expand your business.  So, you should invite the appropriate service owners and start a good interaction with them.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of distributors:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section you will see a list of your distributors. You can have a list of partners in your network by attracting and training successful groups. They can introduce more customers to you by forming a network and attracting more agents.`)}</p>
                            <p className="font-face-gm">{t(`By clicking on the button + and adding it, you can invite your experienced and trustworthy people to cooperate.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Agents:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`Agents play a key role in your business. The more agents you have, the more customers you will have. You can also have a large network of agents by attracting and training the agents to attract a large number of customers.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of colleague distributor:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can specify services, administrative affairs and financial resources with your colleagues.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of referred customers by distributor:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can see the list of agents referred by the distributors.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of referred customers by agents:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can see the list of customers referred by your agents.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t(' My Services Page:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`This section is very important. In this section, you must complete your services completely. Your list of services will be displayed to distributors and agents. The pricing part and commissions are displayed only to distributors and agents, and the customer will not be aware of them at all. For customers, only the supplementary part of customer service will be displayed so that they can complete their file.`)}</p>
                            <p className="font-face-gm">{t(`The cost of your services is highly competitive and can affect the attraction of distributors and agents. Also, the commission you consider for distributors and agents is highly competitive and will greatly affect your willingness to work with them.`)}</p>
                            <p className="font-face-gm">{t(`The smarter you define “my service department”, the more customers you will be able to attract.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Messenger:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`Your page of messenger is defined smartly in order that you can set the time and quality of your performance. In this section you will see your contact list. The limitations imposed are the result of research and experience of your colleagues. So that the customers can send you a message once. After receiving a reply from you, they can send message again. Distributors can only text you three times continuously, and after the third message they have to wait for your reply to open the messages for them.`)}</p>
                            <p className="font-face-gm">{t(`You can send six continuous messages to your distributors and nine continuous messages to your customers. You can send unlimited messages to your service owner and colleague.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Services:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.`)}</p>
                        </div>
                    </div>
                </div>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Instructions')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`According to this section of the guide, you can manage all sections.`)}</p>
                            <p className="font-face-gm">{t(`You need to know that if all parts of your dashboard are not completed, your ability to cooperate and earn income will be greatly impacted reduced.`)}</p>
                            <p className="font-face-gm">{t(`1- Ask your lawyers or your distributors to specify the services that can be provided.`)}</p>
                            <p className="font-face-gm">{t(`a. In this case, you can define goals for your agents regarding the types of services and tariffs specified or give advice to customers in that field.`)}</p>
                            <p className="font-face-gm">{t(`b. By clicking the Vie Service button, you can see all the services provided by them from the main menu of the lawyer’s or distributor’s profile.`)}</p>
                            <p className="font-face-gm">{t(`2- After having a complete list of services of lawyers or distributors, you can enter the My Services section from the main menu.`)}</p>
                            <p className="font-face-gm">{t(`a. In this section, you are free to enter the services provided with the same name and price or change them according to your marketing conditions (However, distributors and agents of your subcategory will see the list of your services and will not have access to the services provided by lawyers or developers that you are working with.)`)}</p>
                            <p className="font-face-gm">{t(`b. Import your services and specify a rate and tariff for each one. According to your services, other distributors and agents will cooperate with you.`)}</p>
                            <p className="font-face-gm">{t(`c. Specify the commission of your agents. Keep in mind that all agents will see the entered commission in the same way. If you are satisfied with a specific agent, you can consider a higher commission for that specific agent from the main menu of the agent section. This process will only apply to that agent and other agents will not see this offer.`)}</p>
                            <p className="font-face-gm">{t(`3- Customer section`)}</p>
                            <p className="font-face-gm">{t(`a. Second button of the main menu (customers) is the list of customers that you directly entered into your dashboard.`)}</p>
                            <p className="font-face-gm">{t(`b. Seventh button of the main menu (distributor clients) is the list of clients introduced to you by the distributors.`)}</p>
                            <p className="font-face-gm">{t(`c. Eighth button of the main menu (agent customers) is the list of customers who have been introduced to you by agents.`)}</p>
                            <p className="font-face-gm">{t(`1. In general, every customer who enters your dashboard, you have the right to refer them to the right lawyer according to the general conditions and the customer’s request, or if you don’t have a lawyer, refer them to your distributor.`)}</p>
                            <p className="font-face-gm">{t(`a. Please note that the customer’s account will not be activated until the lawyer makes a final decision for the customer, and until activated, the lawyer will not have direct access to your customer’s contact information, nor will the customer see the lawyer’s information.`)}</p>
                            <p className="font-face-gm">{t(`b. After the initial approval of the lawyer, you can proceed with your work process to ensure the financial issues, then by pressing the approval button, both parties can communicate directly with each other.`)}</p>
                            <p className="font-face-gm">{t(`4- Finally, try to increase the number of your connections. As the number of lawyers is higher, the number of services and the possibility of selling more products will increase. The more distributors and agents you have, the more customers you can attract and the higher your income.`)}</p>
                            <p className="font-face-gm">{t(`5- Distributors can work directly with lawyers, but agents can only work with their own distributor and can only introduce customers to their distributor’s services. So, be careful about recruitment or the scope of authority of each person and act according to the conditions and rules set by Telesiness.`)}</p>
                            <p className="font-face-gm">{t(`6- Complete your profile. Increase the number of your users and teach your subordinates how to work with the dashboard. Soon other parts of the application will be opened to earn higher income, and you must have reached the desired points for your activity, so that you can use those parts`)}</p>
                        </div>
                    </div>
                </div>
                
            </div>}
            {/* *************************************** */}

            {/* Marketer Help Center Contetnts */}
            {localStorage.getItem('userType') == 4 && <div className='total_services col-12'>
            <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Home Page Guide of Agents')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Home Page')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Profile menu')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fourth icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fifth section')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owners rests with you and the application will only help your relationship.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see the number of successful, canceled and current files.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this section, you will see a complete list of your communications. List of customers, list of distributors, list of business owners working with you and list of your services. You can also add items to these lists from this section and see short descriptions of them.')}</p>
                        </div>
                    </div>
                </div>
                
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Scoring system:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`The scoring system of this program is very important. Because the criterion of your performance and the quality of your services depends on it and it can affect your working relationships. Also, the number of your customers can be increased or decreased with these points. This system consists of four parts:`)}</p>
                            <p className="font-face-gm">{t(`First part: First of all, Telesiness will give you 720 points, which is only 20% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Second part: It is the score or point that customers give you. Your customers will choose from 1 to 5 stars according to their satisfaction and they will give you a point for each star. These points will be calculated according to the result of your services. Finally, the customer point includes 30% of your total point.`)}</p>
                            <p className="font-face-gm">{t(`Third part: It is the point of distributor giving you due to the services you provide for them and these points will cover 50% of your total points.`)}</p>
                            <p className="font-face-gm">{t(`Finally, we will calculate the total points with the number of successful users and files and show you the final number. This point will be seen by all users along the way, and finally, according to your points, you will be on the list of the most successful agents and the business owners will select you and to work with you.`)}</p>
                            <p className="font-face-gm">{t(`The world of Telesiness Network Marketing helps you to increase your points to have more customers.`)}</p>
                            <p className="font-face-gm">{t(`At the end, you will be shown the highest points obtained by the top business owner in this network so that you can determine how far you are from the first person.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Customers:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you can completely see your customers’ files and also check your list of new customers.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Service of Distributors:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section you will see the list of services and tarrif and introduce the customers by forming a network and attracting more agents.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Messenger:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`You can send messages to your distributors and customers. You can send messages to the Passport section of Telesiness.`)}</p>
                        </div>
                    </div>
                </div>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Services:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you have access to all services and businesses and you can use them if you need. In this section, you can also get acquainted with other business owners and cooperate with them.`)}</p>
                        </div>
                    </div>
                </div>


            </div>}
            {/* *************************************** */}

            {/* Customer Help Center Contetnts */}
            {localStorage.getItem('userType') == 3 && <div className='total_services col-12'>
            <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Guide of Home Page for the Customer')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Home Page')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('Home page contains an overall view of essential information that you need. The top of the home page includes four icons (profile menu, accounting section, notification and help) and a list of the most successful distributors and business owners, by selecting any of them, you can start your activity and cooperate with them.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Profile menu')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('You can change and adjust your account information by observing the profile option (It is necessary to say that you should complete the profile according to law and you have one month for it. If you do not complete the information within this period, your profile will be blocked). You can also browse the application rules, change its settings or exit the application.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the second icon, you can see your financial account. You can also check your financial statements in detail. The financial part of this program is completely unique for your financial audit and it is set for your home page. You can use it, outside the application, to audit your company or system.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the third icon, you can see all the announcements or warnings or any message that needs to be checked.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fourth icon')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('By using the fourth icon, you can read general guide of the site and contact the support department, if you have any problems or questions.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Fifth section')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('The fifth icon lists the most successful distributors and business owners based on their performance, score and customer satisfaction. This list is quite competitive and after analyzing the specific conditions of the user, the system automatically places them in this section. You can request and cooperate with them by viewing their points and specifications. All responsibility for the negotiations and business relationship between you and the distributors or business owner rests with you and the application will only help your relationship.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Second part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this part, you will see the agent and service owner related to your file.')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('Third part:')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('In this part, the process of the file and list of documents depending on the type of you request are displayed at the bottom of the page and you can see them.')}</p>
                        </div>
                    </div>
                </div>
                
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('My Request Page:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section you can create your own immigration file`)}</p>
                            <p className="font-face-gm">{t(`First of all, you should create a file and enter you basic and general information. After the approval of the relevant service owner and determining the type of visa or your immigration route, the other facilities of departments will be activated for you.`)}</p>
                            <p className="font-face-gm">{t(`In the following, after the service owner approves your file and documents, you will give your personal and legal documents to the service owner and he will access to your personal and legal documents by completing the required information. All your information and documents will be sent to the service owner, so you should be careful when completing the file and sending the documents.`)}</p>
                            <p className="font-face-gm">{t(`Please upload the documents accurately and regularly in the specified locations. Failure to complete the forms and upload the documents in this section will be delayed the process of your file by the chosen service owner.`)}</p>
                            <p className="font-face-gm">{t(`1- All documents should be in PDF format.`)}</p>
                            <p className="font-face-gm">{t(`2- All documents should be scanned correctly (Mobile scanning in not accepted).`)}</p>
                            <p className="font-face-gm">{t(`3- All documents should have a maximum size of 4 MB.`)}</p>
                            <p className="font-face-gm">{t(`4- All documents should be officially translated.`)}</p>
                            <p className="font-face-gm">{t(`Each document, regardless of the number of pages, must be in a file with the above conditions.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Messenger:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`By using the messenger section of the program, you can share your question or problem with your agent or service owner. After sending the message, you have to wait for his response. After replying, you can send the message again.`)}</p>
                        </div>
                    </div>
                </div>

                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('Page of Services:')}</h3>
                </div>
                <div className='box_servlog col-12'>
                    <div className='servlog'>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t(`In this section, you have access to all services and businesses and you can use any of the items that you need. In this section, you can also get acquainted with other business owners and cooperate with them.`)}</p>
                        </div>
                    </div>
                </div>



            </div>}
            {/* *************************************** */}

            {/* <Link to="/Dashboard" onClick={togglePopup3} className="btn btn-dark login-btnStep6">{t('Go to main page')}</Link> */}
        </div>
  </>
     }
      handleClose={togglePopup3}
    />}
    </div>
    
);
}
 
export default TopMenu;