import { useState, useEffect, useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentTravelInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),

      CustomerNumberForeignTrips: values.NumberForeignTrips,
      CustomerArrivalTravelDateNumberOne: ArrivalTravelDate1.toString(),
      CustomerDepartureTravelDateNumberOne: DepartureTravelDate1.toString(),
      CustomerTravelLocationNumberOne: values.TravelLocation1,
      CustomerStayingTimeNumberOne: values.StayingTime1,
      CustomerVisitedCitiesNumberOne: values.VisitedCities1,
      CustomerTravelReasonNumberOne: values.ReasonTravel1,
      CustomerArrivalTravelDateNumberTwo: ArrivalTravelDate2.toString(),
      CustomerDepartureTravelDateNumberTwo: DepartureTravelDate2.toString(),
      CustomerTravelLocationNumberTwo: values.TravelLocation2,
      CustomerStayingTimeNumberTwo: values.StayingTime2,
      CustomerVisitedCitiesNumberTwo: values.VisitedCities2,
      CustomerTravelReasonNumberTwo: values.ReasonTravel2,
      CustomerArrivalTravelDateNumberThree: ArrivalTravelDate3.toString(),
      CustomerDepartureTravelDateNumberThree: DepartureTravelDate3.toString(),
      CustomerTravelLocationNumberThree: values.TravelLocation3,
      CustomerStayingTimeNumberThree: values.StayingTime3,
      CustomerVisitedCitiesNumberThree: values.VisitedCities3,
      CustomerTravelReasonNumberThree: values.ReasonTravel3,
      CustomerArrivalTravelDateNumberFour: ArrivalTravelDate4.toString(),
      CustomerDepartureTravelDateNumberFour: DepartureTravelDate4.toString(),
      CustomerTravelLocationNumberFour: values.TravelLocation4,
      CustomerStayingTimeNumberFour: values.StayingTime4,
      CustomerVisitedCitiesNumberFour: values.VisitedCities4,
      CustomerTravelReasonNumberFour: values.ReasonTravel4,
      CustomerSchengenNumber: values.SchengenNumber,
      CustomerSchengenIssuanceDate: SchengenIssuanceDate.toString(),
      CustomerSpecialVisaNumberNumberOne: values.SpecialVisaNumber1,
      CustomerSpecialVisaNameNumberOne: values.VisaName1,
      CustomerSpecialVisaNumberNumberTwo: values.SpecialVisaNumber2,
      CustomerSpecialVisaNameNumberTwo: values.VisaName2,
      CustomerSpecialVisaNumberNumberThree: values.SpecialVisaNumber3,
      CustomerSpecialVisaNameNumberThree: values.VisaName3,
      CustomerSpecialVisaNumberNumberFour: values.SpecialVisaNumber4,
      CustomerSpecialVisaNameNumberFour: values.VisaName4,
      CustomerSpecialVisaNumberNumberFive: values.SpecialVisaNumber5,
      CustomerSpecialVisaNameNumberFive: values.VisaName5,
      CustomerSpecialVisaNumberNumberSix: values.SpecialVisaNumber6,
      CustomerSpecialVisaNameNumberSix: values.VisaName6,
      CustomerExpiryDateResidenceDestinationNumberOne: ExpiryDateResidenceDestination1.toString(),
      CustomerExpiryDateResidenceDestinationNumberTwo: ExpiryDateResidenceDestination2.toString(),
      CustomerExpiryDateResidenceDestinationNumberThree: ExpiryDateResidenceDestination3.toString(),
      CustomerPriorRejectionDateNumberOne: PriorRejectionDate1.toString(),
      CustomerTheCountryRejectedNumberOne: values.TheCountryRejected1,
      CustomerRejectVisaTypeNumberOne: values.RejectVisaType1,
      CustomerDateApplicationRejectedVisaNumberOne: DateApplicationRejectedVisa1.toString(),
      CustomerReasonRejectionNumberOne: values.ReasonRejection1,
      CustomerPriorRejectionDateNumberTwo: PriorRejectionDate2.toString(),
      CustomerTheCountryRejectedNumberTwo: values.TheCountryRejected2,
      CustomerRejectVisaTypeNumberTwo: values.RejectVisaType2,
      CustomerDateApplicationRejectedVisaNumberTwo: DateApplicationRejectedVisa2.toString(),
      CustomerReasonRejectionNumberTwo: values.ReasonRejection2,
      CustomerPriorRejectionDateNumberThree: PriorRejectionDate3.toString(),
      CustomerTheCountryRejectedNumberThree: values.TheCountryRejected3,
      CustomerRejectVisaTypeNumberThree: values.RejectVisaType3,
      CustomerDateApplicationRejectedVisaNumberThree: DateApplicationRejectedVisa3.toString(),
      CustomerReasonRejectionNumberThree: values.ReasonRejection3,
      CustomerPriorRejectionDateNumberFour: PriorRejectionDate4.toString(),
      CustomerTheCountryRejectedNumberFour: values.TheCountryRejected4,
      CustomerRejectVisaTypeNumberFour: values.RejectVisaType4,
      CustomerDateApplicationRejectedVisaNumberFour: DateApplicationRejectedVisa4.toString(),
      CustomerReasonRejectionNumberFour: values.ReasonRejection4,
      CustomerPriorRejectionDateNumberFive: PriorRejectionDate5.toString(),
      CustomerTheCountryRejectedNumberFive: values.TheCountryRejected5,
      CustomerRejectVisaTypeNumberFive: values.RejectVisaType5,
      CustomerDateApplicationRejectedVisaNumberFive: DateApplicationRejectedVisa5.toString(),
      CustomerReasonRejectionNumberFive: values.ReasonRejection5,
      CustomerPriorRejectionDateNumberSix: PriorRejectionDate6.toString(),
      CustomerTheCountryRejectedNumberSix: values.TheCountryRejected6,
      CustomerRejectVisaTypeNumberSix: values.RejectVisaType6,
      CustomerDateApplicationRejectedVisaNumberSix: DateApplicationRejectedVisa6.toString(),
      CustomerReasonRejectionNumberSix: values.ReasonRejection6,

  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentTravelInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerNumberForeignTrips: values.NumberForeignTrips,
           CustomerArrivalTravelDateNumberOne: ArrivalTravelDate1.toString(),
           CustomerDepartureTravelDateNumberOne: DepartureTravelDate1.toString(),
           CustomerTravelLocationNumberOne: values.TravelLocation1,
           CustomerStayingTimeNumberOne: values.StayingTime1,
           CustomerVisitedCitiesNumberOne: values.VisitedCities1,
           CustomerTravelReasonNumberOne: values.ReasonTravel1,
           CustomerArrivalTravelDateNumberTwo: ArrivalTravelDate2.toString(),
           CustomerDepartureTravelDateNumberTwo: DepartureTravelDate2.toString(),
           CustomerTravelLocationNumberTwo: values.TravelLocation2,
           CustomerStayingTimeNumberTwo: values.StayingTime2,
           CustomerVisitedCitiesNumberTwo: values.VisitedCities2,
           CustomerTravelReasonNumberTwo: values.ReasonTravel2,
           CustomerArrivalTravelDateNumberThree: ArrivalTravelDate3.toString(),
           CustomerDepartureTravelDateNumberThree: DepartureTravelDate3.toString(),
           CustomerTravelLocationNumberThree: values.TravelLocation3,
           CustomerStayingTimeNumberThree: values.StayingTime3,
           CustomerVisitedCitiesNumberThree: values.VisitedCities3,
           CustomerTravelReasonNumberThree: values.ReasonTravel3,
           CustomerArrivalTravelDateNumberFour: ArrivalTravelDate4.toString(),
           CustomerDepartureTravelDateNumberFour: DepartureTravelDate4.toString(),
           CustomerTravelLocationNumberFour: values.TravelLocation4,
           CustomerStayingTimeNumberFour: values.StayingTime4,
           CustomerVisitedCitiesNumberFour: values.VisitedCities4,
           CustomerTravelReasonNumberFour: values.ReasonTravel4,
           CustomerSchengenNumber: values.SchengenNumber,
           CustomerSchengenIssuanceDate: SchengenIssuanceDate.toString(),
           CustomerSpecialVisaNumberNumberOne: values.SpecialVisaNumber1,
           CustomerSpecialVisaNameNumberOne: values.VisaName1,
           CustomerSpecialVisaNumberNumberTwo: values.SpecialVisaNumber2,
           CustomerSpecialVisaNameNumberTwo: values.VisaName2,
           CustomerSpecialVisaNumberNumberThree: values.SpecialVisaNumber3,
           CustomerSpecialVisaNameNumberThree: values.VisaName3,
           CustomerSpecialVisaNumberNumberFour: values.SpecialVisaNumber4,
           CustomerSpecialVisaNameNumberFour: values.VisaName4,
           CustomerSpecialVisaNumberNumberFive: values.SpecialVisaNumber5,
           CustomerSpecialVisaNameNumberFive: values.VisaName5,
           CustomerSpecialVisaNumberNumberSix: values.SpecialVisaNumber6,
           CustomerSpecialVisaNameNumberSix: values.VisaName6,
           CustomerExpiryDateResidenceDestinationNumberOne: ExpiryDateResidenceDestination1.toString(),
           CustomerExpiryDateResidenceDestinationNumberTwo: ExpiryDateResidenceDestination2.toString(),
           CustomerExpiryDateResidenceDestinationNumberThree: ExpiryDateResidenceDestination3.toString(),
           CustomerPriorRejectionDateNumberOne: PriorRejectionDate1.toString(),
           CustomerTheCountryRejectedNumberOne: values.TheCountryRejected1,
           CustomerRejectVisaTypeNumberOne: values.RejectVisaType1,
           CustomerDateApplicationRejectedVisaNumberOne: DateApplicationRejectedVisa1.toString(),
           CustomerReasonRejectionNumberOne: values.ReasonRejection1,
           CustomerPriorRejectionDateNumberTwo: PriorRejectionDate2.toString(),
           CustomerTheCountryRejectedNumberTwo: values.TheCountryRejected2,
           CustomerRejectVisaTypeNumberTwo: values.RejectVisaType2,
           CustomerDateApplicationRejectedVisaNumberTwo: DateApplicationRejectedVisa2.toString(),
           CustomerReasonRejectionNumberTwo: values.ReasonRejection2,
           CustomerPriorRejectionDateNumberThree: PriorRejectionDate3.toString(),
           CustomerTheCountryRejectedNumberThree: values.TheCountryRejected3,
           CustomerRejectVisaTypeNumberThree: values.RejectVisaType3,
           CustomerDateApplicationRejectedVisaNumberThree: DateApplicationRejectedVisa3.toString(),
           CustomerReasonRejectionNumberThree: values.ReasonRejection3,
           CustomerPriorRejectionDateNumberFour: PriorRejectionDate4.toString(),
           CustomerTheCountryRejectedNumberFour: values.TheCountryRejected4,
           CustomerRejectVisaTypeNumberFour: values.RejectVisaType4,
           CustomerDateApplicationRejectedVisaNumberFour: DateApplicationRejectedVisa4.toString(),
           CustomerReasonRejectionNumberFour: values.ReasonRejection4,
           CustomerPriorRejectionDateNumberFive: PriorRejectionDate5.toString(),
           CustomerTheCountryRejectedNumberFive: values.TheCountryRejected5,
           CustomerRejectVisaTypeNumberFive: values.RejectVisaType5,
           CustomerDateApplicationRejectedVisaNumberFive: DateApplicationRejectedVisa5.toString(),
           CustomerReasonRejectionNumberFive: values.ReasonRejection5,
           CustomerPriorRejectionDateNumberSix: PriorRejectionDate6.toString(),
           CustomerTheCountryRejectedNumberSix: values.TheCountryRejected6,
           CustomerRejectVisaTypeNumberSix: values.RejectVisaType6,
           CustomerDateApplicationRejectedVisaNumberSix: DateApplicationRejectedVisa6.toString(),
           CustomerReasonRejectionNumberSix: values.ReasonRejection6,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerNumberForeignTrips',response.customerNumberForeignTrips);
        localStorage.setItem('customerArrivalTravelDateNumberOne',response.customerArrivalTravelDateNumberOne);
        localStorage.setItem('customerDepartureTravelDateNumberOne',response.customerDepartureTravelDateNumberOne);
        localStorage.setItem('customerTravelLocationNumberOne',response.customerTravelLocationNumberOne);
        localStorage.setItem('customerStayingTimeNumberOne',response.customerStayingTimeNumberOne);
        localStorage.setItem('customerVisitedCitiesNumberOne',response.customerVisitedCitiesNumberOne);
        localStorage.setItem('customerTravelReasonNumberOne',response.customerTravelReasonNumberOne);
        localStorage.setItem('customerArrivalTravelDateNumberTwo',response.customerArrivalTravelDateNumberTwo);
        localStorage.setItem('customerDepartureTravelDateNumberTwo',response.customerDepartureTravelDateNumberTwo);
        localStorage.setItem('customerTravelLocationNumberTwo',response.customerTravelLocationNumberTwo);
        localStorage.setItem('customerStayingTimeNumberTwo',response.customerStayingTimeNumberTwo);
        localStorage.setItem('customerVisitedCitiesNumberTwo',response.customerVisitedCitiesNumberTwo);
        localStorage.setItem('customerTravelReasonNumberTwo',response.customerTravelReasonNumberTwo);
        localStorage.setItem('customerArrivalTravelDateNumberThree',response.customerArrivalTravelDateNumberThree);
        localStorage.setItem('customerDepartureTravelDateNumberThree',response.customerDepartureTravelDateNumberThree);
        localStorage.setItem('customerTravelLocationNumberThree',response.customerTravelLocationNumberThree);
        localStorage.setItem('customerStayingTimeNumberThree',response.customerStayingTimeNumberThree);
        localStorage.setItem('customerVisitedCitiesNumberThree',response.customerVisitedCitiesNumberThree);
        localStorage.setItem('customerTravelReasonNumberThree',response.customerTravelReasonNumberThree);
        localStorage.setItem('customerArrivalTravelDateNumberFour',response.customerArrivalTravelDateNumberFour);
        localStorage.setItem('customerDepartureTravelDateNumberFour',response.customerDepartureTravelDateNumberFour);
        localStorage.setItem('customerTravelLocationNumberFour',response.customerTravelLocationNumberFour);
        localStorage.setItem('customerStayingTimeNumberFour',response.customerStayingTimeNumberFour);
        localStorage.setItem('customerVisitedCitiesNumberFour',response.customerVisitedCitiesNumberFour);
        localStorage.setItem('customerTravelReasonNumberFour',response.customerTravelReasonNumberFour);
        localStorage.setItem('customerSchengenNumber',response.customerSchengenNumber);
        localStorage.setItem('customerSchengenIssuanceDate',response.customerSchengenIssuanceDate);
        localStorage.setItem('customerSpecialVisaNumberNumberOne',response.customerSpecialVisaNumberNumberOne);
        localStorage.setItem('customerSpecialVisaNameNumberOne',response.customerSpecialVisaNameNumberOne);
        localStorage.setItem('customerSpecialVisaNumberNumberTwo',response.customerSpecialVisaNumberNumberTwo);
        localStorage.setItem('customerSpecialVisaNameNumberTwo',response.customerSpecialVisaNameNumberTwo);
        localStorage.setItem('customerSpecialVisaNumberNumberThree',response.customerSpecialVisaNumberNumberThree);
        localStorage.setItem('customerSpecialVisaNameNumberThree',response.customerSpecialVisaNameNumberThree);
        localStorage.setItem('customerSpecialVisaNumberNumberFour',response.customerSpecialVisaNumberNumberFour);
        localStorage.setItem('customerSpecialVisaNameNumberFour',response.customerSpecialVisaNameNumberFour);
        localStorage.setItem('customerSpecialVisaNumberNumberFive',response.customerSpecialVisaNumberNumberFive);
        localStorage.setItem('customerSpecialVisaNameNumberFive',response.customerSpecialVisaNameNumberFive);
        localStorage.setItem('customerSpecialVisaNumberNumberSix',response.customerSpecialVisaNumberNumberSix);
        localStorage.setItem('customerSpecialVisaNameNumberSix',response.customerSpecialVisaNameNumberSix);
        localStorage.setItem('customerExpiryDateResidenceDestinationNumberOne',response.customerExpiryDateResidenceDestinationNumberOne);
        localStorage.setItem('customerExpiryDateResidenceDestinationNumberTwo',response.customerExpiryDateResidenceDestinationNumberTwo);
        localStorage.setItem('customerExpiryDateResidenceDestinationNumberThree',response.customerExpiryDateResidenceDestinationNumberThree);
        localStorage.setItem('customerPriorRejectionDateNumberOne',response.customerPriorRejectionDateNumberOne);
        localStorage.setItem('customerTheCountryRejectedNumberOne',response.customerTheCountryRejectedNumberOne);
        localStorage.setItem('customerRejectVisaTypeNumberOne',response.customerRejectVisaTypeNumberOne);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberOne',response.customerDateApplicationRejectedVisaNumberOne);
        localStorage.setItem('customerReasonRejectionNumberOne',response.customerReasonRejectionNumberOne);
        localStorage.setItem('customerPriorRejectionDateNumberTwo',response.customerPriorRejectionDateNumberTwo);
        localStorage.setItem('customerTheCountryRejectedNumberTwo',response.customerTheCountryRejectedNumberTwo);
        localStorage.setItem('customerRejectVisaTypeNumberTwo',response.customerRejectVisaTypeNumberTwo);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberTwo',response.customerDateApplicationRejectedVisaNumberTwo);
        localStorage.setItem('customerReasonRejectionNumberTwo',response.customerReasonRejectionNumberTwo);
        localStorage.setItem('customerPriorRejectionDateNumberThree',response.customerPriorRejectionDateNumberThree);
        localStorage.setItem('customerTheCountryRejectedNumberThree',response.customerTheCountryRejectedNumberThree);
        localStorage.setItem('customerRejectVisaTypeNumberThree',response.customerRejectVisaTypeNumberThree);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberThree',response.customerDateApplicationRejectedVisaNumberThree);
        localStorage.setItem('customerReasonRejectionNumberThree',response.customerReasonRejectionNumberThree);
        localStorage.setItem('customerPriorRejectionDateNumberFour',response.customerPriorRejectionDateNumberFour);
        localStorage.setItem('customerTheCountryRejectedNumberFour',response.customerTheCountryRejectedNumberFour);
        localStorage.setItem('customerRejectVisaTypeNumberFour',response.customerRejectVisaTypeNumberFour);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberFour',response.customerDateApplicationRejectedVisaNumberFour);
        localStorage.setItem('customerReasonRejectionNumberFour',response.customerReasonRejectionNumberFour);
        localStorage.setItem('customerPriorRejectionDateNumberFive',response.customerPriorRejectionDateNumberFive);
        localStorage.setItem('customerTheCountryRejectedNumberFive',response.customerTheCountryRejectedNumberFive);
        localStorage.setItem('customerRejectVisaTypeNumberFive',response.customerRejectVisaTypeNumberFive);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberFive',response.customerDateApplicationRejectedVisaNumberFive);
        localStorage.setItem('customerReasonRejectionNumberFive',response.customerReasonRejectionNumberFive);
        localStorage.setItem('customerPriorRejectionDateNumberSix',response.customerPriorRejectionDateNumberSix);
        localStorage.setItem('customerTheCountryRejectedNumberSix',response.customerTheCountryRejectedNumberSix);
        localStorage.setItem('customerRejectVisaTypeNumberSix',response.customerRejectVisaTypeNumberSix);
        localStorage.setItem('customerDateApplicationRejectedVisaNumberSix',response.customerDateApplicationRejectedVisaNumberSix);
        localStorage.setItem('customerReasonRejectionNumberSix',response.customerReasonRejectionNumberSix);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        values.NumberForeignTrips = localStorage.getItem('customerNumberForeignTrips');
      
      
        values.TravelLocation1 = localStorage.getItem('customerTravelLocationNumberOne');
        values.StayingTime1 = localStorage.getItem('customerStayingTimeNumberOne');
        values.VisitedCities1 = localStorage.getItem('customerVisitedCitiesNumberOne');
        values.ReasonTravel1 = localStorage.getItem('customerTravelReasonNumberOne');
        
        
        values.TravelLocation2 = localStorage.getItem('customerTravelLocationNumberTwo');
        values.StayingTime2 = localStorage.getItem('customerStayingTimeNumberTwo');
        values.VisitedCities2 = localStorage.getItem('customerVisitedCitiesNumberTwo');
        values.ReasonTravel2 = localStorage.getItem('customerTravelReasonNumberTwo');
        
        
        values.TravelLocation3 = localStorage.getItem('customerTravelLocationNumberThree');
        values.StayingTime3 = localStorage.getItem('customerStayingTimeNumberThree');
        values.VisitedCities3 = localStorage.getItem('customerVisitedCitiesNumberThree');
        values.ReasonTravel3 = localStorage.getItem('customerTravelReasonNumberThree');
        
        
        values.TravelLocation4 = localStorage.getItem('customerTravelLocationNumberFour');
        values.StayingTime4 = localStorage.getItem('customerStayingTimeNumberFour');
        values.VisitedCities4 = localStorage.getItem('customerVisitedCitiesNumberFour');
        values.ReasonTravel4 = localStorage.getItem('customerTravelReasonNumberFour');
  
        values.SchengenNumber = localStorage.getItem('customerSchengenNumber');
        
        values.SpecialVisaNumber1 = localStorage.getItem('customerSpecialVisaNumberNumberOne');
        values.VisaName1 = localStorage.getItem('customerSpecialVisaNameNumberOne');
        values.SpecialVisaNumber2 = localStorage.getItem('customerSpecialVisaNumberNumberTwo');
        values.VisaName2 = localStorage.getItem('customerSpecialVisaNameNumberTwo');
        values.SpecialVisaNumber3 = localStorage.getItem('customerSpecialVisaNumberNumberThree');
        values.VisaName3 = localStorage.getItem('customerSpecialVisaNameNumberThree');
        values.SpecialVisaNumber4 = localStorage.getItem('customerSpecialVisaNumberNumberFour');
        values.VisaName4 = localStorage.getItem('customerSpecialVisaNameNumberFour');
        values.SpecialVisaNumber5 = localStorage.getItem('customerSpecialVisaNumberNumberFive');
        values.VisaName5 = localStorage.getItem('customerSpecialVisaNameNumberFive');
        values.SpecialVisaNumber6 = localStorage.getItem('customerSpecialVisaNumberNumberSix');
        values.VisaName6 = localStorage.getItem('customerSpecialVisaNameNumberSix');
        
        
        
        
        values.TheCountryRejected1 = localStorage.getItem('customerTheCountryRejectedNumberOne');
        values.RejectVisaType1 = localStorage.getItem('customerRejectVisaTypeNumberOne');
        values.ReasonRejection1 = localStorage.getItem('customerReasonRejectionNumberOne');
        
        values.TheCountryRejected2 = localStorage.getItem('customerTheCountryRejectedNumberTwo');
        values.RejectVisaType2 = localStorage.getItem('customerRejectVisaTypeNumberTwo');
        values.ReasonRejection2 = localStorage.getItem('customerReasonRejectionNumberTwo');
        
        values.TheCountryRejected3 = localStorage.getItem('customerTheCountryRejectedNumberThree');
        values.RejectVisaType3 = localStorage.getItem('customerRejectVisaTypeNumberThree');
        values.ReasonRejection3 = localStorage.getItem('customerReasonRejectionNumberThree');
        
        values.TheCountryRejected4 = localStorage.getItem('customerTheCountryRejectedNumberFour');
        values.RejectVisaType4 = localStorage.getItem('customerRejectVisaTypeNumberFour');
        values.ReasonRejection4 = localStorage.getItem('customerReasonRejectionNumberFour');
        
        values.TheCountryRejected5 = localStorage.getItem('customerTheCountryRejectedNumberFive');
        values.RejectVisaType5 = localStorage.getItem('customerRejectVisaTypeNumberFive');
        values.ReasonRejection5 = localStorage.getItem('customerReasonRejectionNumberFive');
        
        values.TheCountryRejected6 = localStorage.getItem('customerTheCountryRejectedNumberSix');
        values.RejectVisaType6 = localStorage.getItem('customerRejectVisaTypeNumberSix');
        values.ReasonRejection6 = localStorage.getItem('customerReasonRejectionNumberSix');
  
        setArrivalTravelDate1(localStorage.getItem('customerArrivalTravelDateNumberOne'));
        setDepartureTravelDate1(localStorage.getItem('customerDepartureTravelDateNumberOne'));
        setArrivalTravelDate2(localStorage.getItem('customerArrivalTravelDateNumberTwo'));
        setDepartureTravelDate2(localStorage.getItem('customerDepartureTravelDateNumberTwo'));
        setArrivalTravelDate3(localStorage.getItem('customerArrivalTravelDateNumberThree'));
        setDepartureTravelDate3(localStorage.getItem('customerDepartureTravelDateNumberThree'));
        setArrivalTravelDate4(localStorage.getItem('customerArrivalTravelDateNumberFour'));
        setDepartureTravelDate4(localStorage.getItem('customerDepartureTravelDateNumberFour'));
        setSchengenIssuanceDate(localStorage.getItem('customerSchengenIssuanceDate'));
        setExpiryDateResidenceDestination1(localStorage.getItem('customerExpiryDateResidenceDestinationNumberOne'));
        setExpiryDateResidenceDestination2(localStorage.getItem('customerExpiryDateResidenceDestinationNumberTwo'));
        setExpiryDateResidenceDestination3(localStorage.getItem('customerExpiryDateResidenceDestinationNumberThree'));
        setDateApplicationRejectedVisa1(localStorage.getItem('customerDateApplicationRejectedVisaNumberOne'));
        setDateApplicationRejectedVisa2(localStorage.getItem('customerDateApplicationRejectedVisaNumberTwo'));
        setDateApplicationRejectedVisa3(localStorage.getItem('customerDateApplicationRejectedVisaNumberThree'));
        setDateApplicationRejectedVisa4(localStorage.getItem('customerDateApplicationRejectedVisaNumberFour'));
        setDateApplicationRejectedVisa5(localStorage.getItem('customerDateApplicationRejectedVisaNumberFive'));
        setDateApplicationRejectedVisa6(localStorage.getItem('customerDateApplicationRejectedVisaNumberSix'));
        setPriorRejectionDate2(localStorage.getItem('customerPriorRejectionDateNumberTwo'));
        setPriorRejectionDate3(localStorage.getItem('customerPriorRejectionDateNumberThree'));
        setPriorRejectionDate4(localStorage.getItem('customerPriorRejectionDateNumberFour'));
        setPriorRejectionDate5(localStorage.getItem('customerPriorRejectionDateNumberFive'));
        setPriorRejectionDate6(localStorage.getItem('customerPriorRejectionDateNumberSix'));
        setPriorRejectionDate1(localStorage.getItem('customerPriorRejectionDateNumberOne'));
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
} else if(localStorage.getItem('whoIs')=='sp'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpouseTravelInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),

      CustomerSpouseNumberForeignTrips: values.NumberForeignTrips,
      CustomerSpouseArrivalTravelDateNumberOne: ArrivalTravelDate1.toString(),
      CustomerSpouseDepartureTravelDateNumberOne: DepartureTravelDate1.toString(),
      CustomerSpouseTravelLocationNumberOne: values.TravelLocation1,
      CustomerSpouseStayingTimeNumberOne: values.StayingTime1,
      CustomerSpouseVisitedCitiesNumberOne: values.VisitedCities1,
      CustomerSpouseTravelReasonNumberOne: values.ReasonTravel1,
      CustomerSpouseArrivalTravelDateNumberTwo: ArrivalTravelDate2.toString(),
      CustomerSpouseDepartureTravelDateNumberTwo: DepartureTravelDate2.toString(),
      CustomerSpouseTravelLocationNumberTwo: values.TravelLocation2,
      CustomerSpouseStayingTimeNumberTwo: values.StayingTime2,
      CustomerSpouseVisitedCitiesNumberTwo: values.VisitedCities2,
      CustomerSpouseTravelReasonNumberTwo: values.ReasonTravel2,
      CustomerSpouseArrivalTravelDateNumberThree: ArrivalTravelDate3.toString(),
      CustomerSpouseDepartureTravelDateNumberThree: DepartureTravelDate3.toString(),
      CustomerSpouseTravelLocationNumberThree: values.TravelLocation3,
      CustomerSpouseStayingTimeNumberThree: values.StayingTime3,
      CustomerSpouseVisitedCitiesNumberThree: values.VisitedCities3,
      CustomerSpouseTravelReasonNumberThree: values.ReasonTravel3,
      CustomerSpouseArrivalTravelDateNumberFour: ArrivalTravelDate4.toString(),
      CustomerSpouseDepartureTravelDateNumberFour: DepartureTravelDate4.toString(),
      CustomerSpouseTravelLocationNumberFour: values.TravelLocation4,
      CustomerSpouseStayingTimeNumberFour: values.StayingTime4,
      CustomerSpouseVisitedCitiesNumberFour: values.VisitedCities4,
      CustomerSpouseTravelReasonNumberFour: values.ReasonTravel4,
      CustomerSpouseSchengenNumber: values.SchengenNumber,
      CustomerSpouseSchengenIssuanceDate: SchengenIssuanceDate.toString(),
      CustomerSpouseSpecialVisaNumberNumberOne: values.SpecialVisaNumber1,
      CustomerSpouseSpecialVisaNameNumberOne: values.VisaName1,
      CustomerSpouseSpecialVisaNumberNumberTwo: values.SpecialVisaNumber2,
      CustomerSpouseSpecialVisaNameNumberTwo: values.VisaName2,
      CustomerSpouseSpecialVisaNumberNumberThree: values.SpecialVisaNumber3,
      CustomerSpouseSpecialVisaNameNumberThree: values.VisaName3,
      CustomerSpouseSpecialVisaNumberNumberFour: values.SpecialVisaNumber4,
      CustomerSpouseSpecialVisaNameNumberFour: values.VisaName4,
      CustomerSpouseSpecialVisaNumberNumberFive: values.SpecialVisaNumber5,
      CustomerSpouseSpecialVisaNameNumberFive: values.VisaName5,
      CustomerSpouseSpecialVisaNumberNumberSix: values.SpecialVisaNumber6,
      CustomerSpouseSpecialVisaNameNumberSix: values.VisaName6,
      CustomerSpouseExpiryDateResidenceDestinationNumberOne: ExpiryDateResidenceDestination1.toString(),
      CustomerSpouseExpiryDateResidenceDestinationNumberTwo: ExpiryDateResidenceDestination2.toString(),
      CustomerSpouseExpiryDateResidenceDestinationNumberThree: ExpiryDateResidenceDestination3.toString(),
      CustomerSpousePriorRejectionDateNumberOne: PriorRejectionDate1.toString(),
      CustomerSpouseTheCountryRejectedNumberOne: values.TheCountryRejected1,
      CustomerSpouseRejectVisaTypeNumberOne: values.RejectVisaType1,
      CustomerSpouseDateApplicationRejectedVisaNumberOne: DateApplicationRejectedVisa1.toString(),
      CustomerSpouseReasonRejectionNumberOne: values.ReasonRejection1,
      CustomerSpousePriorRejectionDateNumberTwo: PriorRejectionDate2.toString(),
      CustomerSpouseTheCountryRejectedNumberTwo: values.TheCountryRejected2,
      CustomerSpouseRejectVisaTypeNumberTwo: values.RejectVisaType2,
      CustomerSpouseDateApplicationRejectedVisaNumberTwo: DateApplicationRejectedVisa2.toString(),
      CustomerSpouseReasonRejectionNumberTwo: values.ReasonRejection2,
      CustomerSpousePriorRejectionDateNumberThree: PriorRejectionDate3.toString(),
      CustomerSpouseTheCountryRejectedNumberThree: values.TheCountryRejected3,
      CustomerSpouseRejectVisaTypeNumberThree: values.RejectVisaType3,
      CustomerSpouseDateApplicationRejectedVisaNumberThree: DateApplicationRejectedVisa3.toString(),
      CustomerSpouseReasonRejectionNumberThree: values.ReasonRejection3,
      CustomerSpousePriorRejectionDateNumberFour: PriorRejectionDate4.toString(),
      CustomerSpouseTheCountryRejectedNumberFour: values.TheCountryRejected4,
      CustomerSpouseRejectVisaTypeNumberFour: values.RejectVisaType4,
      CustomerSpouseDateApplicationRejectedVisaNumberFour: DateApplicationRejectedVisa4.toString(),
      CustomerSpouseReasonRejectionNumberFour: values.ReasonRejection4,
      CustomerSpousePriorRejectionDateNumberFive: PriorRejectionDate5.toString(),
      CustomerSpouseTheCountryRejectedNumberFive: values.TheCountryRejected5,
      CustomerSpouseRejectVisaTypeNumberFive: values.RejectVisaType5,
      CustomerSpouseDateApplicationRejectedVisaNumberFive: DateApplicationRejectedVisa5.toString(),
      CustomerSpouseReasonRejectionNumberFive: values.ReasonRejection5,
      CustomerSpousePriorRejectionDateNumberSix: PriorRejectionDate6.toString(),
      CustomerSpouseTheCountryRejectedNumberSix: values.TheCountryRejected6,
      CustomerSpouseRejectVisaTypeNumberSix: values.RejectVisaType6,
      CustomerSpouseDateApplicationRejectedVisaNumberSix: DateApplicationRejectedVisa6.toString(),
      CustomerSpouseReasonRejectionNumberSix: values.ReasonRejection6,

  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpouseTravelInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerSpouseNumberForeignTrips: values.NumberForeignTrips,
           CustomerSpouseArrivalTravelDateNumberOne: ArrivalTravelDate1.toString(),
           CustomerSpouseDepartureTravelDateNumberOne: DepartureTravelDate1.toString(),
           CustomerSpouseTravelLocationNumberOne: values.TravelLocation1,
           CustomerSpouseStayingTimeNumberOne: values.StayingTime1,
           CustomerSpouseVisitedCitiesNumberOne: values.VisitedCities1,
           CustomerSpouseTravelReasonNumberOne: values.ReasonTravel1,
           CustomerSpouseArrivalTravelDateNumberTwo: ArrivalTravelDate2.toString(),
           CustomerSpouseDepartureTravelDateNumberTwo: DepartureTravelDate2.toString(),
           CustomerSpouseTravelLocationNumberTwo: values.TravelLocation2,
           CustomerSpouseStayingTimeNumberTwo: values.StayingTime2,
           CustomerSpouseVisitedCitiesNumberTwo: values.VisitedCities2,
           CustomerSpouseTravelReasonNumberTwo: values.ReasonTravel2,
           CustomerSpouseArrivalTravelDateNumberThree: ArrivalTravelDate3.toString(),
           CustomerSpouseDepartureTravelDateNumberThree: DepartureTravelDate3.toString(),
           CustomerSpouseTravelLocationNumberThree: values.TravelLocation3,
           CustomerSpouseStayingTimeNumberThree: values.StayingTime3,
           CustomerSpouseVisitedCitiesNumberThree: values.VisitedCities3,
           CustomerSpouseTravelReasonNumberThree: values.ReasonTravel3,
           CustomerSpouseArrivalTravelDateNumberFour: ArrivalTravelDate4.toString(),
           CustomerSpouseDepartureTravelDateNumberFour: DepartureTravelDate4.toString(),
           CustomerSpouseTravelLocationNumberFour: values.TravelLocation4,
           CustomerSpouseStayingTimeNumberFour: values.StayingTime4,
           CustomerSpouseVisitedCitiesNumberFour: values.VisitedCities4,
           CustomerSpouseTravelReasonNumberFour: values.ReasonTravel4,
           CustomerSpouseSchengenNumber: values.SchengenNumber,
           CustomerSpouseSchengenIssuanceDate: SchengenIssuanceDate.toString(),
           CustomerSpouseSpecialVisaNumberNumberOne: values.SpecialVisaNumber1,
           CustomerSpouseSpecialVisaNameNumberOne: values.VisaName1,
           CustomerSpouseSpecialVisaNumberNumberTwo: values.SpecialVisaNumber2,
           CustomerSpouseSpecialVisaNameNumberTwo: values.VisaName2,
           CustomerSpouseSpecialVisaNumberNumberThree: values.SpecialVisaNumber3,
           CustomerSpouseSpecialVisaNameNumberThree: values.VisaName3,
           CustomerSpouseSpecialVisaNumberNumberFour: values.SpecialVisaNumber4,
           CustomerSpouseSpecialVisaNameNumberFour: values.VisaName4,
           CustomerSpouseSpecialVisaNumberNumberFive: values.SpecialVisaNumber5,
           CustomerSpouseSpecialVisaNameNumberFive: values.VisaName5,
           CustomerSpouseSpecialVisaNumberNumberSix: values.SpecialVisaNumber6,
           CustomerSpouseSpecialVisaNameNumberSix: values.VisaName6,
           CustomerSpouseExpiryDateResidenceDestinationNumberOne: ExpiryDateResidenceDestination1.toString(),
           CustomerSpouseExpiryDateResidenceDestinationNumberTwo: ExpiryDateResidenceDestination2.toString(),
           CustomerSpouseExpiryDateResidenceDestinationNumberThree: ExpiryDateResidenceDestination3.toString(),
           CustomerSpousePriorRejectionDateNumberOne: PriorRejectionDate1.toString(),
           CustomerSpouseTheCountryRejectedNumberOne: values.TheCountryRejected1,
           CustomerSpouseRejectVisaTypeNumberOne: values.RejectVisaType1,
           CustomerSpouseDateApplicationRejectedVisaNumberOne: DateApplicationRejectedVisa1.toString(),
           CustomerSpouseReasonRejectionNumberOne: values.ReasonRejection1,
           CustomerSpousePriorRejectionDateNumberTwo: PriorRejectionDate2.toString(),
           CustomerSpouseTheCountryRejectedNumberTwo: values.TheCountryRejected2,
           CustomerSpouseRejectVisaTypeNumberTwo: values.RejectVisaType2,
           CustomerSpouseDateApplicationRejectedVisaNumberTwo: DateApplicationRejectedVisa2.toString(),
           CustomerSpouseReasonRejectionNumberTwo: values.ReasonRejection2,
           CustomerSpousePriorRejectionDateNumberThree: PriorRejectionDate3.toString(),
           CustomerSpouseTheCountryRejectedNumberThree: values.TheCountryRejected3,
           CustomerSpouseRejectVisaTypeNumberThree: values.RejectVisaType3,
           CustomerSpouseDateApplicationRejectedVisaNumberThree: DateApplicationRejectedVisa3.toString(),
           CustomerSpouseReasonRejectionNumberThree: values.ReasonRejection3,
           CustomerSpousePriorRejectionDateNumberFour: PriorRejectionDate4.toString(),
           CustomerSpouseTheCountryRejectedNumberFour: values.TheCountryRejected4,
           CustomerSpouseRejectVisaTypeNumberFour: values.RejectVisaType4,
           CustomerSpouseDateApplicationRejectedVisaNumberFour: DateApplicationRejectedVisa4.toString(),
           CustomerSpouseReasonRejectionNumberFour: values.ReasonRejection4,
           CustomerSpousePriorRejectionDateNumberFive: PriorRejectionDate5.toString(),
           CustomerSpouseTheCountryRejectedNumberFive: values.TheCountryRejected5,
           CustomerSpouseRejectVisaTypeNumberFive: values.RejectVisaType5,
           CustomerSpouseDateApplicationRejectedVisaNumberFive: DateApplicationRejectedVisa5.toString(),
           CustomerSpouseReasonRejectionNumberFive: values.ReasonRejection5,
           CustomerSpousePriorRejectionDateNumberSix: PriorRejectionDate6.toString(),
           CustomerSpouseTheCountryRejectedNumberSix: values.TheCountryRejected6,
           CustomerSpouseRejectVisaTypeNumberSix: values.RejectVisaType6,
           CustomerSpouseDateApplicationRejectedVisaNumberSix: DateApplicationRejectedVisa6.toString(),
           CustomerSpouseReasonRejectionNumberSix: values.ReasonRejection6,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerSpouseNumberForeignTrips',response.customerSpouseNumberForeignTrips);
        localStorage.setItem('customerSpouseArrivalTravelDateNumberOne',response.customerSpouseArrivalTravelDateNumberOne);
        localStorage.setItem('customerSpouseDepartureTravelDateNumberOne',response.customerSpouseDepartureTravelDateNumberOne);
        localStorage.setItem('customerSpouseTravelLocationNumberOne',response.customerSpouseTravelLocationNumberOne);
        localStorage.setItem('customerSpouseStayingTimeNumberOne',response.customerSpouseStayingTimeNumberOne);
        localStorage.setItem('customerSpouseVisitedCitiesNumberOne',response.customerSpouseVisitedCitiesNumberOne);
        localStorage.setItem('customerSpouseTravelReasonNumberOne',response.customerSpouseTravelReasonNumberOne);
        localStorage.setItem('customerSpouseArrivalTravelDateNumberTwo',response.customerSpouseArrivalTravelDateNumberTwo);
        localStorage.setItem('customerSpouseDepartureTravelDateNumberTwo',response.customerSpouseDepartureTravelDateNumberTwo);
        localStorage.setItem('customerSpouseTravelLocationNumberTwo',response.customerSpouseTravelLocationNumberTwo);
        localStorage.setItem('customerSpouseStayingTimeNumberTwo',response.customerSpouseStayingTimeNumberTwo);
        localStorage.setItem('customerSpouseVisitedCitiesNumberTwo',response.customerSpouseVisitedCitiesNumberTwo);
        localStorage.setItem('customerSpouseTravelReasonNumberTwo',response.customerSpouseTravelReasonNumberTwo);
        localStorage.setItem('customerSpouseArrivalTravelDateNumberThree',response.customerSpouseArrivalTravelDateNumberThree);
        localStorage.setItem('customerSpouseDepartureTravelDateNumberThree',response.customerSpouseDepartureTravelDateNumberThree);
        localStorage.setItem('customerSpouseTravelLocationNumberThree',response.customerSpouseTravelLocationNumberThree);
        localStorage.setItem('customerSpouseStayingTimeNumberThree',response.customerSpouseStayingTimeNumberThree);
        localStorage.setItem('customerSpouseVisitedCitiesNumberThree',response.customerSpouseVisitedCitiesNumberThree);
        localStorage.setItem('customerSpouseTravelReasonNumberThree',response.customerSpouseTravelReasonNumberThree);
        localStorage.setItem('customerSpouseArrivalTravelDateNumberFour',response.customerSpouseArrivalTravelDateNumberFour);
        localStorage.setItem('customerSpouseDepartureTravelDateNumberFour',response.customerSpouseDepartureTravelDateNumberFour);
        localStorage.setItem('customerSpouseTravelLocationNumberFour',response.customerSpouseTravelLocationNumberFour);
        localStorage.setItem('customerSpouseStayingTimeNumberFour',response.customerSpouseStayingTimeNumberFour);
        localStorage.setItem('customerSpouseVisitedCitiesNumberFour',response.customerSpouseVisitedCitiesNumberFour);
        localStorage.setItem('customerSpouseTravelReasonNumberFour',response.customerSpouseTravelReasonNumberFour);
        localStorage.setItem('customerSpouseSchengenNumber',response.customerSpouseSchengenNumber);
        localStorage.setItem('customerSpouseSchengenIssuanceDate',response.customerSpouseSchengenIssuanceDate);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberOne',response.customerSpouseSpecialVisaNumberNumberOne);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberOne',response.customerSpouseSpecialVisaNameNumberOne);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberTwo',response.customerSpouseSpecialVisaNumberNumberTwo);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberTwo',response.customerSpouseSpecialVisaNameNumberTwo);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberThree',response.customerSpouseSpecialVisaNumberNumberThree);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberThree',response.customerSpouseSpecialVisaNameNumberThree);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberFour',response.customerSpouseSpecialVisaNumberNumberFour);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberFour',response.customerSpouseSpecialVisaNameNumberFour);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberFive',response.customerSpouseSpecialVisaNumberNumberFive);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberFive',response.customerSpouseSpecialVisaNameNumberFive);
        localStorage.setItem('customerSpouseSpecialVisaNumberNumberSix',response.customerSpouseSpecialVisaNumberNumberSix);
        localStorage.setItem('customerSpouseSpecialVisaNameNumberSix',response.customerSpouseSpecialVisaNameNumberSix);
        localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberOne',response.customerSpouseExpiryDateResidenceDestinationNumberOne);
        localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberTwo',response.customerSpouseExpiryDateResidenceDestinationNumberTwo);
        localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberThree',response.customerSpouseExpiryDateResidenceDestinationNumberThree);
        localStorage.setItem('customerSpousePriorRejectionDateNumberOne',response.customerSpousePriorRejectionDateNumberOne);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberOne',response.customerSpouseTheCountryRejectedNumberOne);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberOne',response.customerSpouseRejectVisaTypeNumberOne);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberOne',response.customerSpouseDateApplicationRejectedVisaNumberOne);
        localStorage.setItem('customerSpouseReasonRejectionNumberOne',response.customerSpouseReasonRejectionNumberOne);
        localStorage.setItem('customerSpousePriorRejectionDateNumberTwo',response.customerSpousePriorRejectionDateNumberTwo);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberTwo',response.customerSpouseTheCountryRejectedNumberTwo);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberTwo',response.customerSpouseRejectVisaTypeNumberTwo);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberTwo',response.customerSpouseDateApplicationRejectedVisaNumberTwo);
        localStorage.setItem('customerSpouseReasonRejectionNumberTwo',response.customerSpouseReasonRejectionNumberTwo);
        localStorage.setItem('customerSpousePriorRejectionDateNumberThree',response.customerSpousePriorRejectionDateNumberThree);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberThree',response.customerSpouseTheCountryRejectedNumberThree);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberThree',response.customerSpouseRejectVisaTypeNumberThree);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberThree',response.customerSpouseDateApplicationRejectedVisaNumberThree);
        localStorage.setItem('customerSpouseReasonRejectionNumberThree',response.customerSpouseReasonRejectionNumberThree);
        localStorage.setItem('customerSpousePriorRejectionDateNumberFour',response.customerSpousePriorRejectionDateNumberFour);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberFour',response.customerSpouseTheCountryRejectedNumberFour);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberFour',response.customerSpouseRejectVisaTypeNumberFour);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFour',response.customerSpouseDateApplicationRejectedVisaNumberFour);
        localStorage.setItem('customerSpouseReasonRejectionNumberFour',response.customerSpouseReasonRejectionNumberFour);
        localStorage.setItem('customerSpousePriorRejectionDateNumberFive',response.customerSpousePriorRejectionDateNumberFive);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberFive',response.customerSpouseTheCountryRejectedNumberFive);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberFive',response.customerSpouseRejectVisaTypeNumberFive);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFive',response.customerSpouseDateApplicationRejectedVisaNumberFive);
        localStorage.setItem('customerSpouseReasonRejectionNumberFive',response.customerSpouseReasonRejectionNumberFive);
        localStorage.setItem('customerSpousePriorRejectionDateNumberSix',response.customerSpousePriorRejectionDateNumberSix);
        localStorage.setItem('customerSpouseTheCountryRejectedNumberSix',response.customerSpouseTheCountryRejectedNumberSix);
        localStorage.setItem('customerSpouseRejectVisaTypeNumberSix',response.customerSpouseRejectVisaTypeNumberSix);
        localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberSix',response.customerSpouseDateApplicationRejectedVisaNumberSix);
        localStorage.setItem('customerSpouseReasonRejectionNumberSix',response.customerSpouseReasonRejectionNumberSix);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        values.NumberForeignTrips = localStorage.getItem('customerSpouseNumberForeignTrips');
      
      
        values.TravelLocation1 = localStorage.getItem('customerSpouseTravelLocationNumberOne');
        values.StayingTime1 = localStorage.getItem('customerSpouseStayingTimeNumberOne');
        values.VisitedCities1 = localStorage.getItem('customerSpouseVisitedCitiesNumberOne');
        values.ReasonTravel1 = localStorage.getItem('customerSpouseTravelReasonNumberOne');
        
        
        values.TravelLocation2 = localStorage.getItem('customerSpouseTravelLocationNumberTwo');
        values.StayingTime2 = localStorage.getItem('customerSpouseStayingTimeNumberTwo');
        values.VisitedCities2 = localStorage.getItem('customerSpouseVisitedCitiesNumberTwo');
        values.ReasonTravel2 = localStorage.getItem('customerSpouseTravelReasonNumberTwo');
        
        
        values.TravelLocation3 = localStorage.getItem('customerSpouseTravelLocationNumberThree');
        values.StayingTime3 = localStorage.getItem('customerSpouseStayingTimeNumberThree');
        values.VisitedCities3 = localStorage.getItem('customerSpouseVisitedCitiesNumberThree');
        values.ReasonTravel3 = localStorage.getItem('customerSpouseTravelReasonNumberThree');
        
        
        values.TravelLocation4 = localStorage.getItem('customerSpouseTravelLocationNumberFour');
        values.StayingTime4 = localStorage.getItem('customerSpouseStayingTimeNumberFour');
        values.VisitedCities4 = localStorage.getItem('customerSpouseVisitedCitiesNumberFour');
        values.ReasonTravel4 = localStorage.getItem('customerSpouseTravelReasonNumberFour');
  
        values.SchengenNumber = localStorage.getItem('customerSpouseSchengenNumber');
        
        values.SpecialVisaNumber1 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberOne');
        values.VisaName1 = localStorage.getItem('customerSpouseSpecialVisaNameNumberOne');
        values.SpecialVisaNumber2 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberTwo');
        values.VisaName2 = localStorage.getItem('customerSpouseSpecialVisaNameNumberTwo');
        values.SpecialVisaNumber3 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberThree');
        values.VisaName3 = localStorage.getItem('customerSpouseSpecialVisaNameNumberThree');
        values.SpecialVisaNumber4 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberFour');
        values.VisaName4 = localStorage.getItem('customerSpouseSpecialVisaNameNumberFour');
        values.SpecialVisaNumber5 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberFive');
        values.VisaName5 = localStorage.getItem('customerSpouseSpecialVisaNameNumberFive');
        values.SpecialVisaNumber6 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberSix');
        values.VisaName6 = localStorage.getItem('customerSpouseSpecialVisaNameNumberSix');
        
        
        
        
        values.TheCountryRejected1 = localStorage.getItem('customerSpouseTheCountryRejectedNumberOne');
        values.RejectVisaType1 = localStorage.getItem('customerSpouseRejectVisaTypeNumberOne');
        values.ReasonRejection1 = localStorage.getItem('customerSpouseReasonRejectionNumberOne');
        
        values.TheCountryRejected2 = localStorage.getItem('customerSpouseTheCountryRejectedNumberTwo');
        values.RejectVisaType2 = localStorage.getItem('customerSpouseRejectVisaTypeNumberTwo');
        values.ReasonRejection2 = localStorage.getItem('customerSpouseReasonRejectionNumberTwo');
        
        values.TheCountryRejected3 = localStorage.getItem('customerSpouseTheCountryRejectedNumberThree');
        values.RejectVisaType3 = localStorage.getItem('customerSpouseRejectVisaTypeNumberThree');
        values.ReasonRejection3 = localStorage.getItem('customerSpouseReasonRejectionNumberThree');
        
        values.TheCountryRejected4 = localStorage.getItem('customerSpouseTheCountryRejectedNumberFour');
        values.RejectVisaType4 = localStorage.getItem('customerSpouseRejectVisaTypeNumberFour');
        values.ReasonRejection4 = localStorage.getItem('customerSpouseReasonRejectionNumberFour');
        
        values.TheCountryRejected5 = localStorage.getItem('customerSpouseTheCountryRejectedNumberFive');
        values.RejectVisaType5 = localStorage.getItem('customerSpouseRejectVisaTypeNumberFive');
        values.ReasonRejection5 = localStorage.getItem('customerSpouseReasonRejectionNumberFive');
        
        values.TheCountryRejected6 = localStorage.getItem('customerSpouseTheCountryRejectedNumberSix');
        values.RejectVisaType6 = localStorage.getItem('customerSpouseRejectVisaTypeNumberSix');
        values.ReasonRejection6 = localStorage.getItem('customerSpouseReasonRejectionNumberSix');
  
        setArrivalTravelDate1(localStorage.getItem('customerSpouseArrivalTravelDateNumberOne'));
        setDepartureTravelDate1(localStorage.getItem('customerSpouseDepartureTravelDateNumberOne'));
        setArrivalTravelDate2(localStorage.getItem('customerSpouseArrivalTravelDateNumberTwo'));
        setDepartureTravelDate2(localStorage.getItem('customerSpouseDepartureTravelDateNumberTwo'));
        setArrivalTravelDate3(localStorage.getItem('customerSpouseArrivalTravelDateNumberThree'));
        setDepartureTravelDate3(localStorage.getItem('customerSpouseDepartureTravelDateNumberThree'));
        setArrivalTravelDate4(localStorage.getItem('customerSpouseArrivalTravelDateNumberFour'));
        setDepartureTravelDate4(localStorage.getItem('customerSpouseDepartureTravelDateNumberFour'));
        setSchengenIssuanceDate(localStorage.getItem('customerSpouseSchengenIssuanceDate'));
        setExpiryDateResidenceDestination1(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberOne'));
        setExpiryDateResidenceDestination2(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberTwo'));
        setExpiryDateResidenceDestination3(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberThree'));
        setDateApplicationRejectedVisa1(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberOne'));
        setDateApplicationRejectedVisa2(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberTwo'));
        setDateApplicationRejectedVisa3(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberThree'));
        setDateApplicationRejectedVisa4(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberFour'));
        setDateApplicationRejectedVisa5(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberFive'));
        setDateApplicationRejectedVisa6(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberSix'));
        setPriorRejectionDate2(localStorage.getItem('customerSpousePriorRejectionDateNumberTwo'));
        setPriorRejectionDate3(localStorage.getItem('customerSpousePriorRejectionDateNumberThree'));
        setPriorRejectionDate4(localStorage.getItem('customerSpousePriorRejectionDateNumberFour'));
        setPriorRejectionDate5(localStorage.getItem('customerSpousePriorRejectionDateNumberFive'));
        setPriorRejectionDate6(localStorage.getItem('customerSpousePriorRejectionDateNumberSix'));
        setPriorRejectionDate1(localStorage.getItem('customerSpousePriorRejectionDateNumberOne'));
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({

          NumberForeignTrips: '',
          TravelLocation1: '',
          StayingTime1: '',
          ReasonTravel1: '',
          VisitedCities1: '',
          TravelLocation2: '',
          StayingTime2: '',
          ReasonTravel2: '',
          VisitedCities2: '',
          TravelLocation3: '',
          StayingTime3: '',
          ReasonTravel3: '',
          VisitedCities3: '',
          TravelLocation4: '',
          StayingTime4: '',
          ReasonTravel4: '',
          VisitedCities4: '',
          SchengenNumber: '',
          SpecialVisaNumber1: '',
          VisaName1: '',
          SpecialVisaNumber2: '',
          VisaName2: '',
          SpecialVisaNumber3: '',
          VisaName3: '',
          SpecialVisaNumber4: '',
          VisaName4: '',
          SpecialVisaNumber5: '',
          VisaName5: '',
          SpecialVisaNumber6: '',
          VisaName6: '',
          TheCountryRejected1:'',
          RejectVisaType1:'',
          ReasonRejection1: '',
          TheCountryRejected2:'',
          RejectVisaType2:'',
          ReasonRejection2: '',
          TheCountryRejected3:'',
          RejectVisaType3:'',
          ReasonRejection3: '',
          TheCountryRejected4:'',
          RejectVisaType4:'',
          ReasonRejection4: '',
          TheCountryRejected5:'',
          RejectVisaType5:'',
          ReasonRejection5: '',
          TheCountryRejected6:'',
          RejectVisaType6:'',
          ReasonRejection6: '',
          proEmail: '',
          proPhone: '',
          proCallNumber:"",
          proPhone2: '',
          proCallNumber2:"",
          proAbout: '',
          firstTimeEdit:'',
          firstTimeEditJob:'',
          firstTimeEditEducational:'',
          firstTimeEditAddress:true,
          firstTimeEditPersonal:true,
          // ProPic: '',
          Post1: '',
          Workplace1: '',
          Post2: '',
          Workplace2: '',
          Post3: '',
          Workplace3: '',
          Post4: '',
          Workplace4: '',
          Post5: '',
          Workplace5: '',
          Post6: '',
          Workplace6: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          WorkExperience:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
          BirthCertificateNumber:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentTravelInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  

  localStorage.setItem('customerNumberForeignTrips',response.customerNumberForeignTrips);
  localStorage.setItem('customerArrivalTravelDateNumberOne',response.customerArrivalTravelDateNumberOne);
  localStorage.setItem('customerDepartureTravelDateNumberOne',response.customerDepartureTravelDateNumberOne);
  localStorage.setItem('customerTravelLocationNumberOne',response.customerTravelLocationNumberOne);
  localStorage.setItem('customerStayingTimeNumberOne',response.customerStayingTimeNumberOne);
  localStorage.setItem('customerVisitedCitiesNumberOne',response.customerVisitedCitiesNumberOne);
  localStorage.setItem('customerTravelReasonNumberOne',response.customerTravelReasonNumberOne);
  localStorage.setItem('customerArrivalTravelDateNumberTwo',response.customerArrivalTravelDateNumberTwo);
  localStorage.setItem('customerDepartureTravelDateNumberTwo',response.customerDepartureTravelDateNumberTwo);
  localStorage.setItem('customerTravelLocationNumberTwo',response.customerTravelLocationNumberTwo);
  localStorage.setItem('customerStayingTimeNumberTwo',response.customerStayingTimeNumberTwo);
  localStorage.setItem('customerVisitedCitiesNumberTwo',response.customerVisitedCitiesNumberTwo);
  localStorage.setItem('customerTravelReasonNumberTwo',response.customerTravelReasonNumberTwo);
  localStorage.setItem('customerArrivalTravelDateNumberThree',response.customerArrivalTravelDateNumberThree);
  localStorage.setItem('customerDepartureTravelDateNumberThree',response.customerDepartureTravelDateNumberThree);
  localStorage.setItem('customerTravelLocationNumberThree',response.customerTravelLocationNumberThree);
  localStorage.setItem('customerStayingTimeNumberThree',response.customerStayingTimeNumberThree);
  localStorage.setItem('customerVisitedCitiesNumberThree',response.customerVisitedCitiesNumberThree);
  localStorage.setItem('customerTravelReasonNumberThree',response.customerTravelReasonNumberThree);
  localStorage.setItem('customerArrivalTravelDateNumberFour',response.customerArrivalTravelDateNumberFour);
  localStorage.setItem('customerDepartureTravelDateNumberFour',response.customerDepartureTravelDateNumberFour);
  localStorage.setItem('customerTravelLocationNumberFour',response.customerTravelLocationNumberFour);
  localStorage.setItem('customerStayingTimeNumberFour',response.customerStayingTimeNumberFour);
  localStorage.setItem('customerVisitedCitiesNumberFour',response.customerVisitedCitiesNumberFour);
  localStorage.setItem('customerTravelReasonNumberFour',response.customerTravelReasonNumberFour);
  localStorage.setItem('customerSchengenNumber',response.customerSchengenNumber);
  localStorage.setItem('customerSchengenIssuanceDate',response.customerSchengenIssuanceDate);
  localStorage.setItem('customerSpecialVisaNumberNumberOne',response.customerSpecialVisaNumberNumberOne);
  localStorage.setItem('customerSpecialVisaNameNumberOne',response.customerSpecialVisaNameNumberOne);
  localStorage.setItem('customerSpecialVisaNumberNumberTwo',response.customerSpecialVisaNumberNumberTwo);
  localStorage.setItem('customerSpecialVisaNameNumberTwo',response.customerSpecialVisaNameNumberTwo);
  localStorage.setItem('customerSpecialVisaNumberNumberThree',response.customerSpecialVisaNumberNumberThree);
  localStorage.setItem('customerSpecialVisaNameNumberThree',response.customerSpecialVisaNameNumberThree);
  localStorage.setItem('customerSpecialVisaNumberNumberFour',response.customerSpecialVisaNumberNumberFour);
  localStorage.setItem('customerSpecialVisaNameNumberFour',response.customerSpecialVisaNameNumberFour);
  localStorage.setItem('customerSpecialVisaNumberNumberFive',response.customerSpecialVisaNumberNumberFive);
  localStorage.setItem('customerSpecialVisaNameNumberFive',response.customerSpecialVisaNameNumberFive);
  localStorage.setItem('customerSpecialVisaNumberNumberSix',response.customerSpecialVisaNumberNumberSix);
  localStorage.setItem('customerSpecialVisaNameNumberSix',response.customerSpecialVisaNameNumberSix);
  localStorage.setItem('customerExpiryDateResidenceDestinationNumberOne',response.customerExpiryDateResidenceDestinationNumberOne);
  localStorage.setItem('customerExpiryDateResidenceDestinationNumberTwo',response.customerExpiryDateResidenceDestinationNumberTwo);
  localStorage.setItem('customerExpiryDateResidenceDestinationNumberThree',response.customerExpiryDateResidenceDestinationNumberThree);
  localStorage.setItem('customerPriorRejectionDateNumberOne',response.customerPriorRejectionDateNumberOne);
  localStorage.setItem('customerTheCountryRejectedNumberOne',response.customerTheCountryRejectedNumberOne);
  localStorage.setItem('customerRejectVisaTypeNumberOne',response.customerRejectVisaTypeNumberOne);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberOne',response.customerDateApplicationRejectedVisaNumberOne);
  localStorage.setItem('customerReasonRejectionNumberOne',response.customerReasonRejectionNumberOne);
  localStorage.setItem('customerPriorRejectionDateNumberTwo',response.customerPriorRejectionDateNumberTwo);
  localStorage.setItem('customerTheCountryRejectedNumberTwo',response.customerTheCountryRejectedNumberTwo);
  localStorage.setItem('customerRejectVisaTypeNumberTwo',response.customerRejectVisaTypeNumberTwo);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberTwo',response.customerDateApplicationRejectedVisaNumberTwo);
  localStorage.setItem('customerReasonRejectionNumberTwo',response.customerReasonRejectionNumberTwo);
  localStorage.setItem('customerPriorRejectionDateNumberThree',response.customerPriorRejectionDateNumberThree);
  localStorage.setItem('customerTheCountryRejectedNumberThree',response.customerTheCountryRejectedNumberThree);
  localStorage.setItem('customerRejectVisaTypeNumberThree',response.customerRejectVisaTypeNumberThree);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberThree',response.customerDateApplicationRejectedVisaNumberThree);
  localStorage.setItem('customerReasonRejectionNumberThree',response.customerReasonRejectionNumberThree);
  localStorage.setItem('customerPriorRejectionDateNumberFour',response.customerPriorRejectionDateNumberFour);
  localStorage.setItem('customerTheCountryRejectedNumberFour',response.customerTheCountryRejectedNumberFour);
  localStorage.setItem('customerRejectVisaTypeNumberFour',response.customerRejectVisaTypeNumberFour);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberFour',response.customerDateApplicationRejectedVisaNumberFour);
  localStorage.setItem('customerReasonRejectionNumberFour',response.customerReasonRejectionNumberFour);
  localStorage.setItem('customerPriorRejectionDateNumberFive',response.customerPriorRejectionDateNumberFive);
  localStorage.setItem('customerTheCountryRejectedNumberFive',response.customerTheCountryRejectedNumberFive);
  localStorage.setItem('customerRejectVisaTypeNumberFive',response.customerRejectVisaTypeNumberFive);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberFive',response.customerDateApplicationRejectedVisaNumberFive);
  localStorage.setItem('customerReasonRejectionNumberFive',response.customerReasonRejectionNumberFive);
  localStorage.setItem('customerPriorRejectionDateNumberSix',response.customerPriorRejectionDateNumberSix);
  localStorage.setItem('customerTheCountryRejectedNumberSix',response.customerTheCountryRejectedNumberSix);
  localStorage.setItem('customerRejectVisaTypeNumberSix',response.customerRejectVisaTypeNumberSix);
  localStorage.setItem('customerDateApplicationRejectedVisaNumberSix',response.customerDateApplicationRejectedVisaNumberSix);
  localStorage.setItem('customerReasonRejectionNumberSix',response.customerReasonRejectionNumberSix);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentTravelInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerNumberForeignTrips',response.customerNumberForeignTrips);
      localStorage.setItem('customerArrivalTravelDateNumberOne',response.customerArrivalTravelDateNumberOne);
      localStorage.setItem('customerDepartureTravelDateNumberOne',response.customerDepartureTravelDateNumberOne);
      localStorage.setItem('customerTravelLocationNumberOne',response.customerTravelLocationNumberOne);
      localStorage.setItem('customerStayingTimeNumberOne',response.customerStayingTimeNumberOne);
      localStorage.setItem('customerVisitedCitiesNumberOne',response.customerVisitedCitiesNumberOne);
      localStorage.setItem('customerTravelReasonNumberOne',response.customerTravelReasonNumberOne);
      localStorage.setItem('customerArrivalTravelDateNumberTwo',response.customerArrivalTravelDateNumberTwo);
      localStorage.setItem('customerDepartureTravelDateNumberTwo',response.customerDepartureTravelDateNumberTwo);
      localStorage.setItem('customerTravelLocationNumberTwo',response.customerTravelLocationNumberTwo);
      localStorage.setItem('customerStayingTimeNumberTwo',response.customerStayingTimeNumberTwo);
      localStorage.setItem('customerVisitedCitiesNumberTwo',response.customerVisitedCitiesNumberTwo);
      localStorage.setItem('customerTravelReasonNumberTwo',response.customerTravelReasonNumberTwo);
      localStorage.setItem('customerArrivalTravelDateNumberThree',response.customerArrivalTravelDateNumberThree);
      localStorage.setItem('customerDepartureTravelDateNumberThree',response.customerDepartureTravelDateNumberThree);
      localStorage.setItem('customerTravelLocationNumberThree',response.customerTravelLocationNumberThree);
      localStorage.setItem('customerStayingTimeNumberThree',response.customerStayingTimeNumberThree);
      localStorage.setItem('customerVisitedCitiesNumberThree',response.customerVisitedCitiesNumberThree);
      localStorage.setItem('customerTravelReasonNumberThree',response.customerTravelReasonNumberThree);
      localStorage.setItem('customerArrivalTravelDateNumberFour',response.customerArrivalTravelDateNumberFour);
      localStorage.setItem('customerDepartureTravelDateNumberFour',response.customerDepartureTravelDateNumberFour);
      localStorage.setItem('customerTravelLocationNumberFour',response.customerTravelLocationNumberFour);
      localStorage.setItem('customerStayingTimeNumberFour',response.customerStayingTimeNumberFour);
      localStorage.setItem('customerVisitedCitiesNumberFour',response.customerVisitedCitiesNumberFour);
      localStorage.setItem('customerTravelReasonNumberFour',response.customerTravelReasonNumberFour);
      localStorage.setItem('customerSchengenNumber',response.customerSchengenNumber);
      localStorage.setItem('customerSchengenIssuanceDate',response.customerSchengenIssuanceDate);
      localStorage.setItem('customerSpecialVisaNumberNumberOne',response.customerSpecialVisaNumberNumberOne);
      localStorage.setItem('customerSpecialVisaNameNumberOne',response.customerSpecialVisaNameNumberOne);
      localStorage.setItem('customerSpecialVisaNumberNumberTwo',response.customerSpecialVisaNumberNumberTwo);
      localStorage.setItem('customerSpecialVisaNameNumberTwo',response.customerSpecialVisaNameNumberTwo);
      localStorage.setItem('customerSpecialVisaNumberNumberThree',response.customerSpecialVisaNumberNumberThree);
      localStorage.setItem('customerSpecialVisaNameNumberThree',response.customerSpecialVisaNameNumberThree);
      localStorage.setItem('customerSpecialVisaNumberNumberFour',response.customerSpecialVisaNumberNumberFour);
      localStorage.setItem('customerSpecialVisaNameNumberFour',response.customerSpecialVisaNameNumberFour);
      localStorage.setItem('customerSpecialVisaNumberNumberFive',response.customerSpecialVisaNumberNumberFive);
      localStorage.setItem('customerSpecialVisaNameNumberFive',response.customerSpecialVisaNameNumberFive);
      localStorage.setItem('customerSpecialVisaNumberNumberSix',response.customerSpecialVisaNumberNumberSix);
      localStorage.setItem('customerSpecialVisaNameNumberSix',response.customerSpecialVisaNameNumberSix);
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberOne',response.customerExpiryDateResidenceDestinationNumberOne);
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberTwo',response.customerExpiryDateResidenceDestinationNumberTwo);
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberThree',response.customerExpiryDateResidenceDestinationNumberThree);
      localStorage.setItem('customerPriorRejectionDateNumberOne',response.customerPriorRejectionDateNumberOne);
      localStorage.setItem('customerTheCountryRejectedNumberOne',response.customerTheCountryRejectedNumberOne);
      localStorage.setItem('customerRejectVisaTypeNumberOne',response.customerRejectVisaTypeNumberOne);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberOne',response.customerDateApplicationRejectedVisaNumberOne);
      localStorage.setItem('customerReasonRejectionNumberOne',response.customerReasonRejectionNumberOne);
      localStorage.setItem('customerPriorRejectionDateNumberTwo',response.customerPriorRejectionDateNumberTwo);
      localStorage.setItem('customerTheCountryRejectedNumberTwo',response.customerTheCountryRejectedNumberTwo);
      localStorage.setItem('customerRejectVisaTypeNumberTwo',response.customerRejectVisaTypeNumberTwo);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberTwo',response.customerDateApplicationRejectedVisaNumberTwo);
      localStorage.setItem('customerReasonRejectionNumberTwo',response.customerReasonRejectionNumberTwo);
      localStorage.setItem('customerPriorRejectionDateNumberThree',response.customerPriorRejectionDateNumberThree);
      localStorage.setItem('customerTheCountryRejectedNumberThree',response.customerTheCountryRejectedNumberThree);
      localStorage.setItem('customerRejectVisaTypeNumberThree',response.customerRejectVisaTypeNumberThree);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberThree',response.customerDateApplicationRejectedVisaNumberThree);
      localStorage.setItem('customerReasonRejectionNumberThree',response.customerReasonRejectionNumberThree);
      localStorage.setItem('customerPriorRejectionDateNumberFour',response.customerPriorRejectionDateNumberFour);
      localStorage.setItem('customerTheCountryRejectedNumberFour',response.customerTheCountryRejectedNumberFour);
      localStorage.setItem('customerRejectVisaTypeNumberFour',response.customerRejectVisaTypeNumberFour);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberFour',response.customerDateApplicationRejectedVisaNumberFour);
      localStorage.setItem('customerReasonRejectionNumberFour',response.customerReasonRejectionNumberFour);
      localStorage.setItem('customerPriorRejectionDateNumberFive',response.customerPriorRejectionDateNumberFive);
      localStorage.setItem('customerTheCountryRejectedNumberFive',response.customerTheCountryRejectedNumberFive);
      localStorage.setItem('customerRejectVisaTypeNumberFive',response.customerRejectVisaTypeNumberFive);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberFive',response.customerDateApplicationRejectedVisaNumberFive);
      localStorage.setItem('customerReasonRejectionNumberFive',response.customerReasonRejectionNumberFive);
      localStorage.setItem('customerPriorRejectionDateNumberSix',response.customerPriorRejectionDateNumberSix);
      localStorage.setItem('customerTheCountryRejectedNumberSix',response.customerTheCountryRejectedNumberSix);
      localStorage.setItem('customerRejectVisaTypeNumberSix',response.customerRejectVisaTypeNumberSix);
      localStorage.setItem('customerDateApplicationRejectedVisaNumberSix',response.customerDateApplicationRejectedVisaNumberSix);
      localStorage.setItem('customerReasonRejectionNumberSix',response.customerReasonRejectionNumberSix);

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerNumberForeignTrips','');
      localStorage.setItem('customerArrivalTravelDateNumberOne','');
      localStorage.setItem('customerDepartureTravelDateNumberOne','');
      localStorage.setItem('customerTravelLocationNumberOne','');
      localStorage.setItem('customerStayingTimeNumberOne','');
      localStorage.setItem('customerVisitedCitiesNumberOne','');
      localStorage.setItem('customerTravelReasonNumberOne','');
      localStorage.setItem('customerArrivalTravelDateNumberTwo','');
      localStorage.setItem('customerDepartureTravelDateNumberTwo','');
      localStorage.setItem('customerTravelLocationNumberTwo','');
      localStorage.setItem('customerStayingTimeNumberTwo','');
      localStorage.setItem('customerVisitedCitiesNumberTwo','');
      localStorage.setItem('customerTravelReasonNumberTwo','');
      localStorage.setItem('customerArrivalTravelDateNumberThree','');
      localStorage.setItem('customerDepartureTravelDateNumberThree','');
      localStorage.setItem('customerTravelLocationNumberThree','');
      localStorage.setItem('customerStayingTimeNumberThree','');
      localStorage.setItem('customerVisitedCitiesNumberThree','');
      localStorage.setItem('customerTravelReasonNumberThree','');
      localStorage.setItem('customerArrivalTravelDateNumberFour','');
      localStorage.setItem('customerDepartureTravelDateNumberFour','');
      localStorage.setItem('customerTravelLocationNumberFour','');
      localStorage.setItem('customerStayingTimeNumberFour','');
      localStorage.setItem('customerVisitedCitiesNumberFour','');
      localStorage.setItem('customerTravelReasonNumberFour','');
      localStorage.setItem('customerSchengenNumber','');
      localStorage.setItem('customerSchengenIssuanceDate','');
      localStorage.setItem('customerSpecialVisaNumberNumberOne','');
      localStorage.setItem('customerSpecialVisaNameNumberOne','');
      localStorage.setItem('customerSpecialVisaNumberNumberTwo','');
      localStorage.setItem('customerSpecialVisaNameNumberTwo','');
      localStorage.setItem('customerSpecialVisaNumberNumberThree','');
      localStorage.setItem('customerSpecialVisaNameNumberThree','');
      localStorage.setItem('customerSpecialVisaNumberNumberFour','');
      localStorage.setItem('customerSpecialVisaNameNumberFour','');
      localStorage.setItem('customerSpecialVisaNumberNumberFive','');
      localStorage.setItem('customerSpecialVisaNameNumberFive','');
      localStorage.setItem('customerSpecialVisaNumberNumberSix','');
      localStorage.setItem('customerSpecialVisaNameNumberSix','');
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberOne','');
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberTwo','');
      localStorage.setItem('customerExpiryDateResidenceDestinationNumberThree','');
      localStorage.setItem('customerPriorRejectionDateNumberOne','');
      localStorage.setItem('customerTheCountryRejectedNumberOne','');
      localStorage.setItem('customerRejectVisaTypeNumberOne','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberOne','');
      localStorage.setItem('customerReasonRejectionNumberOne','');
      localStorage.setItem('customerPriorRejectionDateNumberTwo','');
      localStorage.setItem('customerTheCountryRejectedNumberTwo','');
      localStorage.setItem('customerRejectVisaTypeNumberTwo','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberTwo','');
      localStorage.setItem('customerReasonRejectionNumberTwo','');
      localStorage.setItem('customerPriorRejectionDateNumberThree','');
      localStorage.setItem('customerTheCountryRejectedNumberThree','');
      localStorage.setItem('customerRejectVisaTypeNumberThree','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberThree','');
      localStorage.setItem('customerReasonRejectionNumberThree','');
      localStorage.setItem('customerPriorRejectionDateNumberFour','');
      localStorage.setItem('customerTheCountryRejectedNumberFour','');
      localStorage.setItem('customerRejectVisaTypeNumberFour','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberFour','');
      localStorage.setItem('customerReasonRejectionNumberFour','');
      localStorage.setItem('customerPriorRejectionDateNumberFive','');
      localStorage.setItem('customerTheCountryRejectedNumberFive','');
      localStorage.setItem('customerRejectVisaTypeNumberFive','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberFive','');
      localStorage.setItem('customerReasonRejectionNumberFive','');
      localStorage.setItem('customerPriorRejectionDateNumberSix','');
      localStorage.setItem('customerTheCountryRejectedNumberSix','');
      localStorage.setItem('customerRejectVisaTypeNumberSix','');
      localStorage.setItem('customerDateApplicationRejectedVisaNumberSix','');
      localStorage.setItem('customerReasonRejectionNumberSix','');
  
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
  } else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseTravelInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  

  localStorage.setItem('customerSpouseNumberForeignTrips',response.customerSpouseNumberForeignTrips);
  localStorage.setItem('customerSpouseArrivalTravelDateNumberOne',response.customerSpouseArrivalTravelDateNumberOne);
  localStorage.setItem('customerSpouseDepartureTravelDateNumberOne',response.customerSpouseDepartureTravelDateNumberOne);
  localStorage.setItem('customerSpouseTravelLocationNumberOne',response.customerSpouseTravelLocationNumberOne);
  localStorage.setItem('customerSpouseStayingTimeNumberOne',response.customerSpouseStayingTimeNumberOne);
  localStorage.setItem('customerSpouseVisitedCitiesNumberOne',response.customerSpouseVisitedCitiesNumberOne);
  localStorage.setItem('customerSpouseTravelReasonNumberOne',response.customerSpouseTravelReasonNumberOne);
  localStorage.setItem('customerSpouseArrivalTravelDateNumberTwo',response.customerSpouseArrivalTravelDateNumberTwo);
  localStorage.setItem('customerSpouseDepartureTravelDateNumberTwo',response.customerSpouseDepartureTravelDateNumberTwo);
  localStorage.setItem('customerSpouseTravelLocationNumberTwo',response.customerSpouseTravelLocationNumberTwo);
  localStorage.setItem('customerSpouseStayingTimeNumberTwo',response.customerSpouseStayingTimeNumberTwo);
  localStorage.setItem('customerSpouseVisitedCitiesNumberTwo',response.customerSpouseVisitedCitiesNumberTwo);
  localStorage.setItem('customerSpouseTravelReasonNumberTwo',response.customerSpouseTravelReasonNumberTwo);
  localStorage.setItem('customerSpouseArrivalTravelDateNumberThree',response.customerSpouseArrivalTravelDateNumberThree);
  localStorage.setItem('customerSpouseDepartureTravelDateNumberThree',response.customerSpouseDepartureTravelDateNumberThree);
  localStorage.setItem('customerSpouseTravelLocationNumberThree',response.customerSpouseTravelLocationNumberThree);
  localStorage.setItem('customerSpouseStayingTimeNumberThree',response.customerSpouseStayingTimeNumberThree);
  localStorage.setItem('customerSpouseVisitedCitiesNumberThree',response.customerSpouseVisitedCitiesNumberThree);
  localStorage.setItem('customerSpouseTravelReasonNumberThree',response.customerSpouseTravelReasonNumberThree);
  localStorage.setItem('customerSpouseArrivalTravelDateNumberFour',response.customerSpouseArrivalTravelDateNumberFour);
  localStorage.setItem('customerSpouseDepartureTravelDateNumberFour',response.customerSpouseDepartureTravelDateNumberFour);
  localStorage.setItem('customerSpouseTravelLocationNumberFour',response.customerSpouseTravelLocationNumberFour);
  localStorage.setItem('customerSpouseStayingTimeNumberFour',response.customerSpouseStayingTimeNumberFour);
  localStorage.setItem('customerSpouseVisitedCitiesNumberFour',response.customerSpouseVisitedCitiesNumberFour);
  localStorage.setItem('customerSpouseTravelReasonNumberFour',response.customerSpouseTravelReasonNumberFour);
  localStorage.setItem('customerSpouseSchengenNumber',response.customerSpouseSchengenNumber);
  localStorage.setItem('customerSpouseSchengenIssuanceDate',response.customerSpouseSchengenIssuanceDate);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberOne',response.customerSpouseSpecialVisaNumberNumberOne);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberOne',response.customerSpouseSpecialVisaNameNumberOne);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberTwo',response.customerSpouseSpecialVisaNumberNumberTwo);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberTwo',response.customerSpouseSpecialVisaNameNumberTwo);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberThree',response.customerSpouseSpecialVisaNumberNumberThree);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberThree',response.customerSpouseSpecialVisaNameNumberThree);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberFour',response.customerSpouseSpecialVisaNumberNumberFour);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberFour',response.customerSpouseSpecialVisaNameNumberFour);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberFive',response.customerSpouseSpecialVisaNumberNumberFive);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberFive',response.customerSpouseSpecialVisaNameNumberFive);
  localStorage.setItem('customerSpouseSpecialVisaNumberNumberSix',response.customerSpouseSpecialVisaNumberNumberSix);
  localStorage.setItem('customerSpouseSpecialVisaNameNumberSix',response.customerSpouseSpecialVisaNameNumberSix);
  localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberOne',response.customerSpouseExpiryDateResidenceDestinationNumberOne);
  localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberTwo',response.customerSpouseExpiryDateResidenceDestinationNumberTwo);
  localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberThree',response.customerSpouseExpiryDateResidenceDestinationNumberThree);
  localStorage.setItem('customerSpousePriorRejectionDateNumberOne',response.customerSpousePriorRejectionDateNumberOne);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberOne',response.customerSpouseTheCountryRejectedNumberOne);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberOne',response.customerSpouseRejectVisaTypeNumberOne);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberOne',response.customerSpouseDateApplicationRejectedVisaNumberOne);
  localStorage.setItem('customerSpouseReasonRejectionNumberOne',response.customerSpouseReasonRejectionNumberOne);
  localStorage.setItem('customerSpousePriorRejectionDateNumberTwo',response.customerSpousePriorRejectionDateNumberTwo);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberTwo',response.customerSpouseTheCountryRejectedNumberTwo);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberTwo',response.customerSpouseRejectVisaTypeNumberTwo);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberTwo',response.customerSpouseDateApplicationRejectedVisaNumberTwo);
  localStorage.setItem('customerSpouseReasonRejectionNumberTwo',response.customerSpouseReasonRejectionNumberTwo);
  localStorage.setItem('customerSpousePriorRejectionDateNumberThree',response.customerSpousePriorRejectionDateNumberThree);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberThree',response.customerSpouseTheCountryRejectedNumberThree);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberThree',response.customerSpouseRejectVisaTypeNumberThree);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberThree',response.customerSpouseDateApplicationRejectedVisaNumberThree);
  localStorage.setItem('customerSpouseReasonRejectionNumberThree',response.customerSpouseReasonRejectionNumberThree);
  localStorage.setItem('customerSpousePriorRejectionDateNumberFour',response.customerSpousePriorRejectionDateNumberFour);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberFour',response.customerSpouseTheCountryRejectedNumberFour);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberFour',response.customerSpouseRejectVisaTypeNumberFour);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFour',response.customerSpouseDateApplicationRejectedVisaNumberFour);
  localStorage.setItem('customerSpouseReasonRejectionNumberFour',response.customerSpouseReasonRejectionNumberFour);
  localStorage.setItem('customerSpousePriorRejectionDateNumberFive',response.customerSpousePriorRejectionDateNumberFive);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberFive',response.customerSpouseTheCountryRejectedNumberFive);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberFive',response.customerSpouseRejectVisaTypeNumberFive);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFive',response.customerSpouseDateApplicationRejectedVisaNumberFive);
  localStorage.setItem('customerSpouseReasonRejectionNumberFive',response.customerSpouseReasonRejectionNumberFive);
  localStorage.setItem('customerSpousePriorRejectionDateNumberSix',response.customerSpousePriorRejectionDateNumberSix);
  localStorage.setItem('customerSpouseTheCountryRejectedNumberSix',response.customerSpouseTheCountryRejectedNumberSix);
  localStorage.setItem('customerSpouseRejectVisaTypeNumberSix',response.customerSpouseRejectVisaTypeNumberSix);
  localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberSix',response.customerSpouseDateApplicationRejectedVisaNumberSix);
  localStorage.setItem('customerSpouseReasonRejectionNumberSix',response.customerSpouseReasonRejectionNumberSix);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseTravelInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerSpouseNumberForeignTrips',response.customerSpouseNumberForeignTrips);
      localStorage.setItem('customerSpouseArrivalTravelDateNumberOne',response.customerSpouseArrivalTravelDateNumberOne);
      localStorage.setItem('customerSpouseDepartureTravelDateNumberOne',response.customerSpouseDepartureTravelDateNumberOne);
      localStorage.setItem('customerSpouseTravelLocationNumberOne',response.customerSpouseTravelLocationNumberOne);
      localStorage.setItem('customerSpouseStayingTimeNumberOne',response.customerSpouseStayingTimeNumberOne);
      localStorage.setItem('customerSpouseVisitedCitiesNumberOne',response.customerSpouseVisitedCitiesNumberOne);
      localStorage.setItem('customerSpouseTravelReasonNumberOne',response.customerSpouseTravelReasonNumberOne);
      localStorage.setItem('customerSpouseArrivalTravelDateNumberTwo',response.customerSpouseArrivalTravelDateNumberTwo);
      localStorage.setItem('customerSpouseDepartureTravelDateNumberTwo',response.customerSpouseDepartureTravelDateNumberTwo);
      localStorage.setItem('customerSpouseTravelLocationNumberTwo',response.customerSpouseTravelLocationNumberTwo);
      localStorage.setItem('customerSpouseStayingTimeNumberTwo',response.customerSpouseStayingTimeNumberTwo);
      localStorage.setItem('customerSpouseVisitedCitiesNumberTwo',response.customerSpouseVisitedCitiesNumberTwo);
      localStorage.setItem('customerSpouseTravelReasonNumberTwo',response.customerSpouseTravelReasonNumberTwo);
      localStorage.setItem('customerSpouseArrivalTravelDateNumberThree',response.customerSpouseArrivalTravelDateNumberThree);
      localStorage.setItem('customerSpouseDepartureTravelDateNumberThree',response.customerSpouseDepartureTravelDateNumberThree);
      localStorage.setItem('customerSpouseTravelLocationNumberThree',response.customerSpouseTravelLocationNumberThree);
      localStorage.setItem('customerSpouseStayingTimeNumberThree',response.customerSpouseStayingTimeNumberThree);
      localStorage.setItem('customerSpouseVisitedCitiesNumberThree',response.customerSpouseVisitedCitiesNumberThree);
      localStorage.setItem('customerSpouseTravelReasonNumberThree',response.customerSpouseTravelReasonNumberThree);
      localStorage.setItem('customerSpouseArrivalTravelDateNumberFour',response.customerSpouseArrivalTravelDateNumberFour);
      localStorage.setItem('customerSpouseDepartureTravelDateNumberFour',response.customerSpouseDepartureTravelDateNumberFour);
      localStorage.setItem('customerSpouseTravelLocationNumberFour',response.customerSpouseTravelLocationNumberFour);
      localStorage.setItem('customerSpouseStayingTimeNumberFour',response.customerSpouseStayingTimeNumberFour);
      localStorage.setItem('customerSpouseVisitedCitiesNumberFour',response.customerSpouseVisitedCitiesNumberFour);
      localStorage.setItem('customerSpouseTravelReasonNumberFour',response.customerSpouseTravelReasonNumberFour);
      localStorage.setItem('customerSpouseSchengenNumber',response.customerSpouseSchengenNumber);
      localStorage.setItem('customerSpouseSchengenIssuanceDate',response.customerSpouseSchengenIssuanceDate);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberOne',response.customerSpouseSpecialVisaNumberNumberOne);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberOne',response.customerSpouseSpecialVisaNameNumberOne);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberTwo',response.customerSpouseSpecialVisaNumberNumberTwo);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberTwo',response.customerSpouseSpecialVisaNameNumberTwo);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberThree',response.customerSpouseSpecialVisaNumberNumberThree);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberThree',response.customerSpouseSpecialVisaNameNumberThree);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberFour',response.customerSpouseSpecialVisaNumberNumberFour);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberFour',response.customerSpouseSpecialVisaNameNumberFour);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberFive',response.customerSpouseSpecialVisaNumberNumberFive);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberFive',response.customerSpouseSpecialVisaNameNumberFive);
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberSix',response.customerSpouseSpecialVisaNumberNumberSix);
      localStorage.setItem('customerSpouseSpecialVisaNameNumberSix',response.customerSpouseSpecialVisaNameNumberSix);
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberOne',response.customerSpouseExpiryDateResidenceDestinationNumberOne);
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberTwo',response.customerSpouseExpiryDateResidenceDestinationNumberTwo);
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberThree',response.customerSpouseExpiryDateResidenceDestinationNumberThree);
      localStorage.setItem('customerSpousePriorRejectionDateNumberOne',response.customerSpousePriorRejectionDateNumberOne);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberOne',response.customerSpouseTheCountryRejectedNumberOne);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberOne',response.customerSpouseRejectVisaTypeNumberOne);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberOne',response.customerSpouseDateApplicationRejectedVisaNumberOne);
      localStorage.setItem('customerSpouseReasonRejectionNumberOne',response.customerSpouseReasonRejectionNumberOne);
      localStorage.setItem('customerSpousePriorRejectionDateNumberTwo',response.customerSpousePriorRejectionDateNumberTwo);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberTwo',response.customerSpouseTheCountryRejectedNumberTwo);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberTwo',response.customerSpouseRejectVisaTypeNumberTwo);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberTwo',response.customerSpouseDateApplicationRejectedVisaNumberTwo);
      localStorage.setItem('customerSpouseReasonRejectionNumberTwo',response.customerSpouseReasonRejectionNumberTwo);
      localStorage.setItem('customerSpousePriorRejectionDateNumberThree',response.customerSpousePriorRejectionDateNumberThree);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberThree',response.customerSpouseTheCountryRejectedNumberThree);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberThree',response.customerSpouseRejectVisaTypeNumberThree);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberThree',response.customerSpouseDateApplicationRejectedVisaNumberThree);
      localStorage.setItem('customerSpouseReasonRejectionNumberThree',response.customerSpouseReasonRejectionNumberThree);
      localStorage.setItem('customerSpousePriorRejectionDateNumberFour',response.customerSpousePriorRejectionDateNumberFour);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberFour',response.customerSpouseTheCountryRejectedNumberFour);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberFour',response.customerSpouseRejectVisaTypeNumberFour);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFour',response.customerSpouseDateApplicationRejectedVisaNumberFour);
      localStorage.setItem('customerSpouseReasonRejectionNumberFour',response.customerSpouseReasonRejectionNumberFour);
      localStorage.setItem('customerSpousePriorRejectionDateNumberFive',response.customerSpousePriorRejectionDateNumberFive);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberFive',response.customerSpouseTheCountryRejectedNumberFive);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberFive',response.customerSpouseRejectVisaTypeNumberFive);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFive',response.customerSpouseDateApplicationRejectedVisaNumberFive);
      localStorage.setItem('customerSpouseReasonRejectionNumberFive',response.customerSpouseReasonRejectionNumberFive);
      localStorage.setItem('customerSpousePriorRejectionDateNumberSix',response.customerSpousePriorRejectionDateNumberSix);
      localStorage.setItem('customerSpouseTheCountryRejectedNumberSix',response.customerSpouseTheCountryRejectedNumberSix);
      localStorage.setItem('customerSpouseRejectVisaTypeNumberSix',response.customerSpouseRejectVisaTypeNumberSix);
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberSix',response.customerSpouseDateApplicationRejectedVisaNumberSix);
      localStorage.setItem('customerSpouseReasonRejectionNumberSix',response.customerSpouseReasonRejectionNumberSix);

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerSpouseNumberForeignTrips','');
      localStorage.setItem('customerSpouseArrivalTravelDateNumberOne','');
      localStorage.setItem('customerSpouseDepartureTravelDateNumberOne','');
      localStorage.setItem('customerSpouseTravelLocationNumberOne','');
      localStorage.setItem('customerSpouseStayingTimeNumberOne','');
      localStorage.setItem('customerSpouseVisitedCitiesNumberOne','');
      localStorage.setItem('customerSpouseTravelReasonNumberOne','');
      localStorage.setItem('customerSpouseArrivalTravelDateNumberTwo','');
      localStorage.setItem('customerSpouseDepartureTravelDateNumberTwo','');
      localStorage.setItem('customerSpouseTravelLocationNumberTwo','');
      localStorage.setItem('customerSpouseStayingTimeNumberTwo','');
      localStorage.setItem('customerSpouseVisitedCitiesNumberTwo','');
      localStorage.setItem('customerSpouseTravelReasonNumberTwo','');
      localStorage.setItem('customerSpouseArrivalTravelDateNumberThree','');
      localStorage.setItem('customerSpouseDepartureTravelDateNumberThree','');
      localStorage.setItem('customerSpouseTravelLocationNumberThree','');
      localStorage.setItem('customerSpouseStayingTimeNumberThree','');
      localStorage.setItem('customerSpouseVisitedCitiesNumberThree','');
      localStorage.setItem('customerSpouseTravelReasonNumberThree','');
      localStorage.setItem('customerSpouseArrivalTravelDateNumberFour','');
      localStorage.setItem('customerSpouseDepartureTravelDateNumberFour','');
      localStorage.setItem('customerSpouseTravelLocationNumberFour','');
      localStorage.setItem('customerSpouseStayingTimeNumberFour','');
      localStorage.setItem('customerSpouseVisitedCitiesNumberFour','');
      localStorage.setItem('customerSpouseTravelReasonNumberFour','');
      localStorage.setItem('customerSpouseSchengenNumber','');
      localStorage.setItem('customerSpouseSchengenIssuanceDate','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberOne','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberOne','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberTwo','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberTwo','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberThree','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberThree','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberFour','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberFour','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberFive','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberFive','');
      localStorage.setItem('customerSpouseSpecialVisaNumberNumberSix','');
      localStorage.setItem('customerSpouseSpecialVisaNameNumberSix','');
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberOne','');
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberTwo','');
      localStorage.setItem('customerSpouseExpiryDateResidenceDestinationNumberThree','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberOne','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberOne','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberOne','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberOne','');
      localStorage.setItem('customerSpouseReasonRejectionNumberOne','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberTwo','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberTwo','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberTwo','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberTwo','');
      localStorage.setItem('customerSpouseReasonRejectionNumberTwo','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberThree','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberThree','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberThree','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberThree','');
      localStorage.setItem('customerSpouseReasonRejectionNumberThree','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberFour','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberFour','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberFour','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFour','');
      localStorage.setItem('customerSpouseReasonRejectionNumberFour','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberFive','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberFive','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberFive','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberFive','');
      localStorage.setItem('customerSpouseReasonRejectionNumberFive','');
      localStorage.setItem('customerSpousePriorRejectionDateNumberSix','');
      localStorage.setItem('customerSpouseTheCountryRejectedNumberSix','');
      localStorage.setItem('customerSpouseRejectVisaTypeNumberSix','');
      localStorage.setItem('customerSpouseDateApplicationRejectedVisaNumberSix','');
      localStorage.setItem('customerSpouseReasonRejectionNumberSix','');
  
    
   
    })
    else{
      // localStorage.setItem('customerSpouseEnglishLanguageStatus','');
      // localStorage.setItem('customerSpouseTurnover','');
      // localStorage.setItem('customerSpouseStartDateOfTurnover','');
      // localStorage.setItem('customerSpouseEndDateOfTurnover','');
      // localStorage.setItem('customerSpouseFinancialAbility','');
      // localStorage.setItem('customerSpouseDateOfFinancial','');
      // localStorage.setItem('customerSpouseFinancialCurrencyUnit','');
      // localStorage.setItem('customerSpouseTotalAssetsAmountNumber','');
      // localStorage.setItem('customerSpouseCalculatedCurrency','');
    }
    })
  }
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }

      if(localStorage.getItem('whoIs')=='ap'){
      values.NumberForeignTrips = localStorage.getItem('customerNumberForeignTrips');
      
      
      values.TravelLocation1 = localStorage.getItem('customerTravelLocationNumberOne');
      values.StayingTime1 = localStorage.getItem('customerStayingTimeNumberOne');
      values.VisitedCities1 = localStorage.getItem('customerVisitedCitiesNumberOne');
      values.ReasonTravel1 = localStorage.getItem('customerTravelReasonNumberOne');
      
      
      values.TravelLocation2 = localStorage.getItem('customerTravelLocationNumberTwo');
      values.StayingTime2 = localStorage.getItem('customerStayingTimeNumberTwo');
      values.VisitedCities2 = localStorage.getItem('customerVisitedCitiesNumberTwo');
      values.ReasonTravel2 = localStorage.getItem('customerTravelReasonNumberTwo');
      
      
      values.TravelLocation3 = localStorage.getItem('customerTravelLocationNumberThree');
      values.StayingTime3 = localStorage.getItem('customerStayingTimeNumberThree');
      values.VisitedCities3 = localStorage.getItem('customerVisitedCitiesNumberThree');
      values.ReasonTravel3 = localStorage.getItem('customerTravelReasonNumberThree');
      
      
      values.TravelLocation4 = localStorage.getItem('customerTravelLocationNumberFour');
      values.StayingTime4 = localStorage.getItem('customerStayingTimeNumberFour');
      values.VisitedCities4 = localStorage.getItem('customerVisitedCitiesNumberFour');
      values.ReasonTravel4 = localStorage.getItem('customerTravelReasonNumberFour');

      values.SchengenNumber = localStorage.getItem('customerSchengenNumber');
      
      values.SpecialVisaNumber1 = localStorage.getItem('customerSpecialVisaNumberNumberOne');
      values.VisaName1 = localStorage.getItem('customerSpecialVisaNameNumberOne');
      values.SpecialVisaNumber2 = localStorage.getItem('customerSpecialVisaNumberNumberTwo');
      values.VisaName2 = localStorage.getItem('customerSpecialVisaNameNumberTwo');
      values.SpecialVisaNumber3 = localStorage.getItem('customerSpecialVisaNumberNumberThree');
      values.VisaName3 = localStorage.getItem('customerSpecialVisaNameNumberThree');
      values.SpecialVisaNumber4 = localStorage.getItem('customerSpecialVisaNumberNumberFour');
      values.VisaName4 = localStorage.getItem('customerSpecialVisaNameNumberFour');
      values.SpecialVisaNumber5 = localStorage.getItem('customerSpecialVisaNumberNumberFive');
      values.VisaName5 = localStorage.getItem('customerSpecialVisaNameNumberFive');
      values.SpecialVisaNumber6 = localStorage.getItem('customerSpecialVisaNumberNumberSix');
      values.VisaName6 = localStorage.getItem('customerSpecialVisaNameNumberSix');
      
      
      
      
      values.TheCountryRejected1 = localStorage.getItem('customerTheCountryRejectedNumberOne');
      values.RejectVisaType1 = localStorage.getItem('customerRejectVisaTypeNumberOne');
      values.ReasonRejection1 = localStorage.getItem('customerReasonRejectionNumberOne');
      
      values.TheCountryRejected2 = localStorage.getItem('customerTheCountryRejectedNumberTwo');
      values.RejectVisaType2 = localStorage.getItem('customerRejectVisaTypeNumberTwo');
      values.ReasonRejection2 = localStorage.getItem('customerReasonRejectionNumberTwo');
      
      values.TheCountryRejected3 = localStorage.getItem('customerTheCountryRejectedNumberThree');
      values.RejectVisaType3 = localStorage.getItem('customerRejectVisaTypeNumberThree');
      values.ReasonRejection3 = localStorage.getItem('customerReasonRejectionNumberThree');
      
      values.TheCountryRejected4 = localStorage.getItem('customerTheCountryRejectedNumberFour');
      values.RejectVisaType4 = localStorage.getItem('customerRejectVisaTypeNumberFour');
      values.ReasonRejection4 = localStorage.getItem('customerReasonRejectionNumberFour');
      
      values.TheCountryRejected5 = localStorage.getItem('customerTheCountryRejectedNumberFive');
      values.RejectVisaType5 = localStorage.getItem('customerRejectVisaTypeNumberFive');
      values.ReasonRejection5 = localStorage.getItem('customerReasonRejectionNumberFive');
      
      values.TheCountryRejected6 = localStorage.getItem('customerTheCountryRejectedNumberSix');
      values.RejectVisaType6 = localStorage.getItem('customerRejectVisaTypeNumberSix');
      values.ReasonRejection6 = localStorage.getItem('customerReasonRejectionNumberSix');

      setArrivalTravelDate1(localStorage.getItem('customerArrivalTravelDateNumberOne'));
      setDepartureTravelDate1(localStorage.getItem('customerDepartureTravelDateNumberOne'));
      setArrivalTravelDate2(localStorage.getItem('customerArrivalTravelDateNumberTwo'));
      setDepartureTravelDate2(localStorage.getItem('customerDepartureTravelDateNumberTwo'));
      setArrivalTravelDate3(localStorage.getItem('customerArrivalTravelDateNumberThree'));
      setDepartureTravelDate3(localStorage.getItem('customerDepartureTravelDateNumberThree'));
      setArrivalTravelDate4(localStorage.getItem('customerArrivalTravelDateNumberFour'));
      setDepartureTravelDate4(localStorage.getItem('customerDepartureTravelDateNumberFour'));
      setSchengenIssuanceDate(localStorage.getItem('customerSchengenIssuanceDate'));
      setExpiryDateResidenceDestination1(localStorage.getItem('customerExpiryDateResidenceDestinationNumberOne'));
      setExpiryDateResidenceDestination2(localStorage.getItem('customerExpiryDateResidenceDestinationNumberTwo'));
      setExpiryDateResidenceDestination3(localStorage.getItem('customerExpiryDateResidenceDestinationNumberThree'));
      setDateApplicationRejectedVisa1(localStorage.getItem('customerDateApplicationRejectedVisaNumberOne'));
      setDateApplicationRejectedVisa2(localStorage.getItem('customerDateApplicationRejectedVisaNumberTwo'));
      setDateApplicationRejectedVisa3(localStorage.getItem('customerDateApplicationRejectedVisaNumberThree'));
      setDateApplicationRejectedVisa4(localStorage.getItem('customerDateApplicationRejectedVisaNumberFour'));
      setDateApplicationRejectedVisa5(localStorage.getItem('customerDateApplicationRejectedVisaNumberFive'));
      setDateApplicationRejectedVisa6(localStorage.getItem('customerDateApplicationRejectedVisaNumberSix'));
      setPriorRejectionDate2(localStorage.getItem('customerPriorRejectionDateNumberTwo'));
      setPriorRejectionDate3(localStorage.getItem('customerPriorRejectionDateNumberThree'));
      setPriorRejectionDate4(localStorage.getItem('customerPriorRejectionDateNumberFour'));
      setPriorRejectionDate5(localStorage.getItem('customerPriorRejectionDateNumberFive'));
      setPriorRejectionDate6(localStorage.getItem('customerPriorRejectionDateNumberSix'));
      setPriorRejectionDate1(localStorage.getItem('customerPriorRejectionDateNumberOne'));
      }
      else if(localStorage.getItem('whoIs')=='sp'){
        values.NumberForeignTrips = localStorage.getItem('customerSpouseNumberForeignTrips');
      
      
        values.TravelLocation1 = localStorage.getItem('customerSpouseTravelLocationNumberOne');
        values.StayingTime1 = localStorage.getItem('customerSpouseStayingTimeNumberOne');
        values.VisitedCities1 = localStorage.getItem('customerSpouseVisitedCitiesNumberOne');
        values.ReasonTravel1 = localStorage.getItem('customerSpouseTravelReasonNumberOne');
        
        
        values.TravelLocation2 = localStorage.getItem('customerSpouseTravelLocationNumberTwo');
        values.StayingTime2 = localStorage.getItem('customerSpouseStayingTimeNumberTwo');
        values.VisitedCities2 = localStorage.getItem('customerSpouseVisitedCitiesNumberTwo');
        values.ReasonTravel2 = localStorage.getItem('customerSpouseTravelReasonNumberTwo');
        
        
        values.TravelLocation3 = localStorage.getItem('customerSpouseTravelLocationNumberThree');
        values.StayingTime3 = localStorage.getItem('customerSpouseStayingTimeNumberThree');
        values.VisitedCities3 = localStorage.getItem('customerSpouseVisitedCitiesNumberThree');
        values.ReasonTravel3 = localStorage.getItem('customerSpouseTravelReasonNumberThree');
        
        
        values.TravelLocation4 = localStorage.getItem('customerSpouseTravelLocationNumberFour');
        values.StayingTime4 = localStorage.getItem('customerSpouseStayingTimeNumberFour');
        values.VisitedCities4 = localStorage.getItem('customerSpouseVisitedCitiesNumberFour');
        values.ReasonTravel4 = localStorage.getItem('customerSpouseTravelReasonNumberFour');
  
        values.SchengenNumber = localStorage.getItem('customerSpouseSchengenNumber');
        
        values.SpecialVisaNumber1 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberOne');
        values.VisaName1 = localStorage.getItem('customerSpouseSpecialVisaNameNumberOne');
        values.SpecialVisaNumber2 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberTwo');
        values.VisaName2 = localStorage.getItem('customerSpouseSpecialVisaNameNumberTwo');
        values.SpecialVisaNumber3 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberThree');
        values.VisaName3 = localStorage.getItem('customerSpouseSpecialVisaNameNumberThree');
        values.SpecialVisaNumber4 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberFour');
        values.VisaName4 = localStorage.getItem('customerSpouseSpecialVisaNameNumberFour');
        values.SpecialVisaNumber5 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberFive');
        values.VisaName5 = localStorage.getItem('customerSpouseSpecialVisaNameNumberFive');
        values.SpecialVisaNumber6 = localStorage.getItem('customerSpouseSpecialVisaNumberNumberSix');
        values.VisaName6 = localStorage.getItem('customerSpouseSpecialVisaNameNumberSix');
        
        
        
        
        values.TheCountryRejected1 = localStorage.getItem('customerSpouseTheCountryRejectedNumberOne');
        values.RejectVisaType1 = localStorage.getItem('customerSpouseRejectVisaTypeNumberOne');
        values.ReasonRejection1 = localStorage.getItem('customerSpouseReasonRejectionNumberOne');
        
        values.TheCountryRejected2 = localStorage.getItem('customerSpouseTheCountryRejectedNumberTwo');
        values.RejectVisaType2 = localStorage.getItem('customerSpouseRejectVisaTypeNumberTwo');
        values.ReasonRejection2 = localStorage.getItem('customerSpouseReasonRejectionNumberTwo');
        
        values.TheCountryRejected3 = localStorage.getItem('customerSpouseTheCountryRejectedNumberThree');
        values.RejectVisaType3 = localStorage.getItem('customerSpouseRejectVisaTypeNumberThree');
        values.ReasonRejection3 = localStorage.getItem('customerSpouseReasonRejectionNumberThree');
        
        values.TheCountryRejected4 = localStorage.getItem('customerSpouseTheCountryRejectedNumberFour');
        values.RejectVisaType4 = localStorage.getItem('customerSpouseRejectVisaTypeNumberFour');
        values.ReasonRejection4 = localStorage.getItem('customerSpouseReasonRejectionNumberFour');
        
        values.TheCountryRejected5 = localStorage.getItem('customerSpouseTheCountryRejectedNumberFive');
        values.RejectVisaType5 = localStorage.getItem('customerSpouseRejectVisaTypeNumberFive');
        values.ReasonRejection5 = localStorage.getItem('customerSpouseReasonRejectionNumberFive');
        
        values.TheCountryRejected6 = localStorage.getItem('customerSpouseTheCountryRejectedNumberSix');
        values.RejectVisaType6 = localStorage.getItem('customerSpouseRejectVisaTypeNumberSix');
        values.ReasonRejection6 = localStorage.getItem('customerSpouseReasonRejectionNumberSix');
  
        setArrivalTravelDate1(localStorage.getItem('customerSpouseArrivalTravelDateNumberOne'));
        setDepartureTravelDate1(localStorage.getItem('customerSpouseDepartureTravelDateNumberOne'));
        setArrivalTravelDate2(localStorage.getItem('customerSpouseArrivalTravelDateNumberTwo'));
        setDepartureTravelDate2(localStorage.getItem('customerSpouseDepartureTravelDateNumberTwo'));
        setArrivalTravelDate3(localStorage.getItem('customerSpouseArrivalTravelDateNumberThree'));
        setDepartureTravelDate3(localStorage.getItem('customerSpouseDepartureTravelDateNumberThree'));
        setArrivalTravelDate4(localStorage.getItem('customerSpouseArrivalTravelDateNumberFour'));
        setDepartureTravelDate4(localStorage.getItem('customerSpouseDepartureTravelDateNumberFour'));
        setSchengenIssuanceDate(localStorage.getItem('customerSpouseSchengenIssuanceDate'));
        setExpiryDateResidenceDestination1(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberOne'));
        setExpiryDateResidenceDestination2(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberTwo'));
        setExpiryDateResidenceDestination3(localStorage.getItem('customerSpouseExpiryDateResidenceDestinationNumberThree'));
        setDateApplicationRejectedVisa1(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberOne'));
        setDateApplicationRejectedVisa2(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberTwo'));
        setDateApplicationRejectedVisa3(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberThree'));
        setDateApplicationRejectedVisa4(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberFour'));
        setDateApplicationRejectedVisa5(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberFive'));
        setDateApplicationRejectedVisa6(localStorage.getItem('customerSpouseDateApplicationRejectedVisaNumberSix'));
        setPriorRejectionDate2(localStorage.getItem('customerSpousePriorRejectionDateNumberTwo'));
        setPriorRejectionDate3(localStorage.getItem('customerSpousePriorRejectionDateNumberThree'));
        setPriorRejectionDate4(localStorage.getItem('customerSpousePriorRejectionDateNumberFour'));
        setPriorRejectionDate5(localStorage.getItem('customerSpousePriorRejectionDateNumberFive'));
        setPriorRejectionDate6(localStorage.getItem('customerSpousePriorRejectionDateNumberSix'));
        setPriorRejectionDate1(localStorage.getItem('customerSpousePriorRejectionDateNumberOne'));
      }
        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


            const [image13 , setImage13] = useState(null);
            const   onImage13Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader13 = new FileReader();
                  reader13.onload = (e) => {
                    setImage13(e.target.result);
                    document.getElementById('target13').style.display ="block";
                    document.getElementById('MyGrayBox13').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader13.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage13 = () =>{
                document.getElementById('target13').style.display ="none";
                document.getElementById('MyGrayBox13').style.display= "flex";
              }


          const [image14 , setImage14] = useState(null);
        const   onImage14Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader14 = new FileReader();
              reader14.onload = (e) => {
                setImage14(e.target.result);
                document.getElementById('target14').style.display ="block";
                document.getElementById('MyGrayBox14').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader14.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage14 = () =>{
            document.getElementById('target14').style.display ="none";
            document.getElementById('MyGrayBox14').style.display= "flex";
          }
                
        const [DateFirstJob, setDateFirstJob] = useState()
        const [Date2Job, setDate2Job] = useState()
        const [Date3Job, setDate3Job] = useState()
        const [Date4Job, setDate4Job] = useState()
        const [Date5Job, setDate5Job] = useState()
        const [Date6Job, setDate6Job] = useState()
        const [ArrivalTravelDate1, setArrivalTravelDate1] = useState()
        const [DepartureTravelDate1, setDepartureTravelDate1] = useState()
        const [ArrivalTravelDate2, setArrivalTravelDate2] = useState()
        const [DepartureTravelDate2, setDepartureTravelDate2] = useState()
        const [ArrivalTravelDate3, setArrivalTravelDate3] = useState()
        const [DepartureTravelDate3, setDepartureTravelDate3] = useState()
        const [ArrivalTravelDate4, setArrivalTravelDate4] = useState()
        const [DepartureTravelDate4, setDepartureTravelDate4] = useState()
        const [SchengenIssuanceDate, setSchengenIssuanceDate] = useState()
        const [ExpiryDateResidenceDestination1, setExpiryDateResidenceDestination1] = useState()
        const [ExpiryDateResidenceDestination2, setExpiryDateResidenceDestination2] = useState()
        const [ExpiryDateResidenceDestination3, setExpiryDateResidenceDestination3] = useState()
        const [DateApplicationRejectedVisa1, setDateApplicationRejectedVisa1] = useState()
        const [DateApplicationRejectedVisa2, setDateApplicationRejectedVisa2] = useState()
        const [DateApplicationRejectedVisa3, setDateApplicationRejectedVisa3] = useState()
        const [DateApplicationRejectedVisa4, setDateApplicationRejectedVisa4] = useState()
        const [DateApplicationRejectedVisa5, setDateApplicationRejectedVisa5] = useState()
        const [DateApplicationRejectedVisa6, setDateApplicationRejectedVisa6] = useState()
        const [PriorRejectionDate2, setPriorRejectionDate2] = useState()
        const [PriorRejectionDate3, setPriorRejectionDate3] = useState()
        const [PriorRejectionDate4, setPriorRejectionDate4] = useState()
        const [PriorRejectionDate5, setPriorRejectionDate5] = useState()
        const [PriorRejectionDate6, setPriorRejectionDate6] = useState()
        const [PriorRejectionDate1, setPriorRejectionDate1] = useState()
        const [LicenseExpirationDate1, setLicenseExpirationDate1] = useState()
        const [LicenseExpirationDate2, setLicenseExpirationDate2] = useState()
        const [LicenseExpirationDate3, setLicenseExpirationDate3] = useState()
        const [LicenseExpirationDate4, setLicenseExpirationDate4] = useState()
        const [LicenseExpirationDate5, setLicenseExpirationDate5] = useState()
        const [LicenseExpirationDate6, setLicenseExpirationDate6] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 5')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Travel Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Sopuse Travel Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Foreign Trips')}</p> </div>
          
          <div className="Cus2Inputs">

          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NumberForeignTrips" 
                value={values.NumberForeignTrips}
                type="text"
                placeholder={t('Number of Foreign Trips')}
                onChange={handleChange('NumberForeignTrips')}
                required
                />
                <label htmlFor="NumberForeignTrips">{t('Enter the applicant’s Number of Foreign Trips')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
            
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Details of Foreign Trips')}</p> 
          
          </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (1)')}
              id="ArrivalTravelDate1"
              onChange={setArrivalTravelDate1} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate1">{t('Enter the applicant’s Arrival Travel Date (1)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (1)')}
              id="DepartureTravelDate1"
              onChange={setDepartureTravelDate1} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate1">{t('Enter the applicant’s Departure Travel Date (1)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation1" 
                value={values.TravelLocation1}
                type="text"
                placeholder={t('Travel Location (1)')}
                onChange={handleChange('TravelLocation1')}
                />
                <label htmlFor="TravelLocation1">{t('Enter the applicant’s Travel Location (1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime1" 
                value={values.StayingTime1}
                type="text"
                placeholder={t('Staying Time (1)')}
                onChange={handleChange('StayingTime1')}
                />
                <label htmlFor="StayingTime1">{t('Enter the applicant’s Staying Time (1)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities1" 
                value={values.VisitedCities1}
                type="text"
                placeholder={t('Cities visited in the destination country (1)')}
                onChange={handleChange('VisitedCities1')}
                />
                <label htmlFor="VisitedCities1">{t('Enter the applicant’s Cities visited in the destination country (1)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel1" 
                value={values.ReasonTravel1}
                type="text"
                placeholder={t('Reason for Travel (1)')}
                onChange={handleChange('ReasonTravel1')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel1">{t('Enter the applicant’s Reason for Travel (1) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (2)')}
              id="ArrivalTravelDate2"
              onChange={setArrivalTravelDate2} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate2">{t('Enter the applicant’s Arrival Travel Date (2)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (2)')}
              id="DepartureTravelDate2"
              onChange={setDepartureTravelDate2} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate2">{t('Enter the applicant’s Departure Travel Date (2)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation2" 
                value={values.TravelLocation2}
                type="text"
                placeholder={t('Travel Location (2)')}
                onChange={handleChange('TravelLocation2')}
                />
                <label htmlFor="TravelLocation2">{t('Enter the applicant’s Travel Location (2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime2" 
                value={values.StayingTime2}
                type="text"
                placeholder={t('Staying Time (2)')}
                onChange={handleChange('StayingTime2')}
                />
                <label htmlFor="StayingTime2">{t('Enter the applicant’s Staying Time (2)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities2" 
                value={values.VisitedCities2}
                type="text"
                placeholder={t('Cities visited in the destination country (2)')}
                onChange={handleChange('VisitedCities2')}
                />
                <label htmlFor="VisitedCities2">{t('Enter the applicant’s Cities visited in the destination country (1)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel2" 
                value={values.ReasonTravel2}
                type="text"
                placeholder={t('Reason for Travel (2)')}
                onChange={handleChange('ReasonTravel2')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel2">{t('Enter the applicant’s Reason for Travel (2) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (3)')}
              id="ArrivalTravelDate3"
              onChange={setArrivalTravelDate3} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate3">{t('Enter the applicant’s Arrival Travel Date (3)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (3)')}
              id="DepartureTravelDate3"
              onChange={setDepartureTravelDate3} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate3">{t('Enter the applicant’s Departure Travel Date (3)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation3" 
                value={values.TravelLocation3}
                type="text"
                placeholder={t('Travel Location (3)')}
                onChange={handleChange('TravelLocation3')}
                />
                <label htmlFor="TravelLocation3">{t('Enter the applicant’s Travel Location (3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime3" 
                value={values.StayingTime3}
                type="text"
                placeholder={t('Staying Time (3)')}
                onChange={handleChange('StayingTime3')}
                />
                <label htmlFor="StayingTime3">{t('Enter the applicant’s Staying Time (3)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities3" 
                value={values.VisitedCities3}
                type="text"
                placeholder={t('Cities visited in the destination country (3)')}
                onChange={handleChange('VisitedCities3')}
                />
                <label htmlFor="VisitedCities3">{t('Enter the applicant’s Cities visited in the destination country (3)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel3" 
                value={values.ReasonTravel3}
                type="text"
                placeholder={t('Reason for Travel (3)')}
                onChange={handleChange('ReasonTravel3')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel3">{t('Enter the applicant’s Reason for Travel (3) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (4)')}
              id="ArrivalTravelDate4"
              onChange={setArrivalTravelDate4} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate4">{t('Enter the applicant’s Arrival Travel Date (4)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (4)')}
              id="DepartureTravelDate4"
              onChange={setDepartureTravelDate4} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate4">{t('Enter the applicant’s Departure Travel Date (4)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation4" 
                value={values.TravelLocation4}
                type="text"
                placeholder={t('Travel Location (4)')}
                onChange={handleChange('TravelLocation4')}
                />
                <label htmlFor="TravelLocation4">{t('Enter the applicant’s Travel Location (4)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime4" 
                value={values.StayingTime4}
                type="text"
                placeholder={t('Staying Time (4)')}
                onChange={handleChange('StayingTime4')}
                />
                <label htmlFor="StayingTime4">{t('Enter the applicant’s Staying Time (4)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities4" 
                value={values.VisitedCities4}
                type="text"
                placeholder={t('Cities visited in the destination country (4)')}
                onChange={handleChange('VisitedCities4')}
                />
                <label htmlFor="VisitedCities4">{t('Enter the applicant’s Cities visited in the destination country (4)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel4" 
                value={values.ReasonTravel4}
                type="text"
                placeholder={t('Reason for Travel (4)')}
                onChange={handleChange('ReasonTravel4')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel4">{t('Enter the applicant’s Reason for Travel (4) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>


            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Schengen')}</p> 
          
          </div>

          <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SchengenNumber" 
                value={values.SchengenNumber}
                type="text"
                placeholder={t('Schengen Number')}
                onChange={handleChange('SchengenNumber')}
                />
                <label htmlFor="SchengenNumber">{t('Enter the applicant’s Schengen Number')}</label>
            </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={SchengenIssuanceDate}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Schengen Issuance Date')}
              id="SchengenIssuanceDate"
              onChange={setSchengenIssuanceDate} 
              className="col-12"
            />}
              <label htmlFor="SchengenIssuanceDate">{t('Enter the applicant’s Schengen Issuance Date')}</label>
          </div>
          



          <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Special Visa')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber1" 
                value={values.SpecialVisaNumber1}
                type="text"
                placeholder={t('Special Visa Number (1)')}
                onChange={handleChange('SpecialVisaNumber1')}
                />
                <label htmlFor="SpecialVisaNumber1">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName1" 
                value={values.VisaName1}
                type="text"
                placeholder={t('Visa Name (1)')}
                onChange={handleChange('VisaName1')}
                />
                <label htmlFor="VisaName1">{t('Enter the applicant’s Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber2" 
                value={values.SpecialVisaNumber2}
                type="text"
                placeholder={t('Special Visa Number (2)')}
                onChange={handleChange('SpecialVisaNumber2')}
                />
                <label htmlFor="SpecialVisaNumber2">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName2" 
                value={values.VisaName2}
                type="text"
                placeholder={t('Visa Name (2)')}
                onChange={handleChange('VisaName2')}
                />
                <label htmlFor="VisaName2">{t('Enter the applicant’s Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber3" 
                value={values.SpecialVisaNumber3}
                type="text"
                placeholder={t('Special Visa Number (3)')}
                onChange={handleChange('SpecialVisaNumber3')}
                />
                <label htmlFor="SpecialVisaNumber3">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName3" 
                value={values.VisaName3}
                type="text"
                placeholder={t('Visa Name (3)')}
                onChange={handleChange('VisaName3')}
                />
                <label htmlFor="VisaName3">{t('Enter the applicant’s Visa Name')}</label>
            </div>


            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber4" 
                value={values.SpecialVisaNumber4}
                type="text"
                placeholder={t('Special Visa Number (4)')}
                onChange={handleChange('SpecialVisaNumber4')}
                />
                <label htmlFor="SpecialVisaNumber4">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName4" 
                value={values.VisaName4}
                type="text"
                placeholder={t('Visa Name (4)')}
                onChange={handleChange('VisaName4')}
                />
                <label htmlFor="VisaName4">{t('Enter the applicant’s Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber5" 
                value={values.SpecialVisaNumber5}
                type="text"
                placeholder={t('Special Visa Number (5)')}
                onChange={handleChange('SpecialVisaNumber5')}
                />
                <label htmlFor="SpecialVisaNumber5">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName5" 
                value={values.VisaName5}
                type="text"
                placeholder={t('Visa Name (5)')}
                onChange={handleChange('VisaName5')}
                />
                <label htmlFor="VisaName5">{t('Enter the applicant’s Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber6" 
                value={values.SpecialVisaNumber6}
                type="text"
                placeholder={t('Special Visa Number (6)')}
                onChange={handleChange('SpecialVisaNumber6')}
                />
                <label htmlFor="SpecialVisaNumber6">{t('Enter the applicant’s Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName6" 
                value={values.VisaName6}
                type="text"
                placeholder={t('Visa Name (6)')}
                onChange={handleChange('VisaName6')}
                />
                <label htmlFor="VisaName6">{t('Enter the applicant’s Visa Name')}</label>
            </div>


            <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Residence of The Destination Country')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (1)')}
              id="ExpiryDateResidenceDestination1"
              onChange={setExpiryDateResidenceDestination1} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination1">{t('Enter the applicant’s Expiry date of residence of the destination country')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (2)')}
              id="ExpiryDateResidenceDestination2"
              onChange={setExpiryDateResidenceDestination2} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination1">{t('Enter the applicant’s Expiry date of residence of the destination country')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (3)')}
              id="ExpiryDateResidenceDestination3"
              onChange={setExpiryDateResidenceDestination3} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination3">{t('Enter the applicant’s Expiry date of residence of the destination country')}</label>
          </div>


          <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Rejected Visa')}</p> 
          
          </div>

           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (1)')}
              id="PriorRejectionDate1"
              onChange={setPriorRejectionDate1} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate1">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected1" 
                value={values.TheCountryRejected1}
                type="text"
                placeholder={t('The country you rejected (1)')}
                onChange={handleChange('TheCountryRejected1')}
                />
                <label htmlFor="TheCountryRejected1">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType1" 
                value={values.RejectVisaType1}
                type="text"
                placeholder={t('Rejected Visa Type (1)')}
                onChange={handleChange('RejectVisaType1')}
                />
                <label htmlFor="RejectVisaType1">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (1)')}
              id="DateApplicationRejectedVisa1"
              onChange={setDateApplicationRejectedVisa1} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa1">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection1" 
                value={values.ReasonRejection1}
                type="text"
                placeholder={t('Reason for Rejection (1)')}
                onChange={handleChange('ReasonRejection1')}
                />
                <label htmlFor="ReasonRejection1">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (2)')}
              id="PriorRejectionDate2"
              onChange={setPriorRejectionDate2} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate2">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected2" 
                value={values.TheCountryRejected2}
                type="text"
                placeholder={t('The country you rejected (2)')}
                onChange={handleChange('TheCountryRejected2')}
                />
                <label htmlFor="TheCountryRejected2">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType2" 
                value={values.RejectVisaType2}
                type="text"
                placeholder={t('Rejected Visa Type (2)')}
                onChange={handleChange('RejectVisaType2')}
                />
                <label htmlFor="RejectVisaType2">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (2)')}
              id="DateApplicationRejectedVisa2"
              onChange={setDateApplicationRejectedVisa2} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa2">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection2" 
                value={values.ReasonRejection2}
                type="text"
                placeholder={t('Reason for Rejection (2)')}
                onChange={handleChange('ReasonRejection2')}
                />
                <label htmlFor="ReasonRejection2">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (3)')}
              id="PriorRejectionDate3"
              onChange={setPriorRejectionDate3} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate3">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected3" 
                value={values.TheCountryRejected3}
                type="text"
                placeholder={t('The country you rejected (3)')}
                onChange={handleChange('TheCountryRejected3')}
                />
                <label htmlFor="TheCountryRejected3">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType3" 
                value={values.RejectVisaType3}
                type="text"
                placeholder={t('Rejected Visa Type (3)')}
                onChange={handleChange('RejectVisaType3')}
                />
                <label htmlFor="RejectVisaType3">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (3)')}
              id="DateApplicationRejectedVisa3"
              onChange={setDateApplicationRejectedVisa3} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa3">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection3" 
                value={values.ReasonRejection3}
                type="text"
                placeholder={t('Reason for Rejection (3)')}
                onChange={handleChange('ReasonRejection3')}
                />
                <label htmlFor="ReasonRejection3">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (4)')}
              id="PriorRejectionDate4"
              onChange={setPriorRejectionDate4} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate4">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected4" 
                value={values.TheCountryRejected4}
                type="text"
                placeholder={t('The country you rejected (4)')}
                onChange={handleChange('TheCountryRejected4')}
                />
                <label htmlFor="TheCountryRejected4">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType4" 
                value={values.RejectVisaType4}
                type="text"
                placeholder={t('Rejected Visa Type (4)')}
                onChange={handleChange('RejectVisaType4')}
                />
                <label htmlFor="RejectVisaType4">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (4)')}
              id="DateApplicationRejectedVisa4"
              onChange={setDateApplicationRejectedVisa4} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa4">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection4" 
                value={values.ReasonRejection4}
                type="text"
                placeholder={t('Reason for Rejection (4)')}
                onChange={handleChange('ReasonRejection4')}
                />
                <label htmlFor="ReasonRejection4">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate5}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (5)')}
              id="PriorRejectionDate5"
              onChange={setPriorRejectionDate5} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate5">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected5" 
                value={values.TheCountryRejected5}
                type="text"
                placeholder={t('The country you rejected (5)')}
                onChange={handleChange('TheCountryRejected5')}
                />
                <label htmlFor="TheCountryRejected5">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType5" 
                value={values.RejectVisaType5}
                type="text"
                placeholder={t('Rejected Visa Type (5)')}
                onChange={handleChange('RejectVisaType5')}
                />
                <label htmlFor="RejectVisaType5">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa5}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (5)')}
              id="DateApplicationRejectedVisa5"
              onChange={setDateApplicationRejectedVisa5} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa5">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection5" 
                value={values.ReasonRejection5}
                type="text"
                placeholder={t('Reason for Rejection (5)')}
                onChange={handleChange('ReasonRejection5')}
                />
                <label htmlFor="ReasonRejection5">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate6}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (6)')}
              id="PriorRejectionDate6"
              onChange={setPriorRejectionDate6} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate6">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected6" 
                value={values.TheCountryRejected6}
                type="text"
                placeholder={t('The country you rejected (6)')}
                onChange={handleChange('TheCountryRejected6')}
                />
                <label htmlFor="TheCountryRejected6">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType6" 
                value={values.RejectVisaType6}
                type="text"
                placeholder={t('Rejected Visa Type (6)')}
                onChange={handleChange('RejectVisaType6')}
                />
                <label htmlFor="RejectVisaType6">{t('Enter the applicant’s Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa6}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (6)')}
              id="DateApplicationRejectedVisa6"
              onChange={setDateApplicationRejectedVisa6} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa6">{t('Enter the applicant’s Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection6" 
                value={values.ReasonRejection6}
                type="text"
                placeholder={t('Reason for Rejection (6)')}
                onChange={handleChange('ReasonRejection6')}
                />
                <label htmlFor="ReasonRejection6">{t('Enter the applicant’s Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>

          </div>
          <div className="Cus2PersonalInfo col-12Midline"> 
          <p>{localStorage.getItem('whoIs')=='ap' && t('Applicants Travel Documents')}
          {localStorage.getItem('whoIs')=='sp' && t('Applicants Spouse Travel Documents')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target" src={image} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox2">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (3)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Special or Revoked Visa')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('New Visa in The New Passport')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox6">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage6Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Residence documents of a specific country')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox7">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage7Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox8">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage8Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox9">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage9Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (3)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox10">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage10Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (4)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox11">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage11Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (5)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox12">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage12Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (6)')}</p> 
          </div>
          </div>


          {/* <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox13">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage13Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> 
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Responsibility Warrant')}</p> 
          </div>
          </div> */}


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox14">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage14Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Documents')}</p> 
          </div>
          </div>


          
         

   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
        </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;