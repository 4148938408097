import React from "react";
 
const Popup = props => {
  return (
    <div className="popup-box">
      <div className="Pbox3">
        {/* <div className="close-icon" onClick={props.handleClose}><span>&times;</span></div> */}
        {props.content}
      </div>
    </div>
  );
};
 
export default Popup;