import {ArrowUp2,ArrowDown2,Filter,ProfileAdd,InfoCircle,ShieldSecurity,TickCircle,MessageText1} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import Popup2 from './Popup2';
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Customers = ({childToParentCus1e1,childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {visa:'',CustomerPic:'', CustomerName:'',CustomerPhone:'+',CustomerStatus:'',CustomerCreated:'', CustomerAgent:'',key:'1'},
      
      ]);

const handleSendMsgReDirect = (CustomerName) => {
localStorage.setItem('CusNameMsg',CustomerName);

}
localStorage.setItem('firstTimeCustomer',true);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserCustomer = (itemKey) =>{
        
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].CustomerStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName > b.CustomerName) ? 1 : ((b.CustomerName > a.CustomerName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName < b.CustomerName) ? 1 : ((b.CustomerName < a.CustomerName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated > b.CustomerCreated) ? 1 : ((b.CustomerCreated > a.CustomerCreated) ? -1 : 0)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated < b.CustomerCreated) ? 1 : ((b.CustomerCreated < a.CustomerCreated) ? -1 : 0)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.EmailC ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{
                setIsOpen2(!isOpen2);
                values.EmailC='';
            })
        setIsOpen2(!isOpen2);
    }
    
    const handleAcceptByDc = (a,b,c) =>{
        fetch('https://telesiness.ir/api/Dashboards/VerifyCustomerByDistributor/25' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                Email: localStorage.getItem('email') ,
                UserId:a, 
                VerifyCustomerDocumentId:c,
                CustomerCode:b,
                VerifyCustomerByDistributor:'1',
            })
            }).then().then();
        setIsOpen3(!isOpen3);
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.CustomerStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.CustomerStatus == 'Deactive').length)));
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen72, setIsOpen72] = useState(true);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }
    const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    }
    const togglePopup4 = () => {
        setIsOpen4(!isOpen4);
        }
    const togglePopup7 = () => {
        setIsOpen7(!isOpen7);
    }
    const togglePopup72 = () => {
        setIsOpen72(!isOpen72);
        }
// const [values, setValues] = useState({
//     lawyer: '',
//   });
//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//     if (values.lawyer=='Assign by Telesiness')
//     setIsOpen2(!isOpen2);
//     console.log(values.lawyer);
//   }
const [lawyer , setLawyer] = useState(0);
  const handleChange2 = (a,b,c) =>{
   console.log(a,b,c);
   
    try {
        console.log(a,b,c);
        fetch('https://telesiness.ir/api/Dashboards/AddLawyerToCustomerDocumentByDistributor/' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                Email: localStorage.getItem('email'),
                CustomerDocumentId:parseInt(c),
                CustomerCode:a,
                LawyerCode:b})
            }).then(response => response.json()).then(response=>{
               
                 try
                 {
                   
                    
                 }
    
                catch{
                    console.log('error');
                }
                // console.log(response[0]);
                
            });
    } catch (error) {
        
    }

    togglePopup();
  }
  const handleChange3 = (a,b,c,d) =>{
    console.log(a,b,c,d);
    
     try {
         console.log(a,b,c,d);
         fetch('https://telesiness.ir/api/Dashboards/ShareCustomersWithDistributor/' , {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ 
                 Email: localStorage.getItem('email'),
                //  UserId:parseInt(c),
                 CustomerCode:a,
                 CustomerDocumentCode:d,
                 DistributorReferenceCode:b,})
             }).then(response => response.json()).then(response=>{
                
                  try
                  {
                    
                     
                  }
     
                 catch{
                     console.log('error');
                 }
                 // console.log(response[0]);
                 
             });
     } catch (error) {
         
     }
 
     togglePopup4();
   }

   const handleServiceInformation = (a,b,c) =>{
    fetch('https://telesiness.ir/api/Dashboards/GetLawyerServicesByDistributor' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email') ,
            LawyerCode:a, 
            CustomerServiceCode:b,
            CustomerCode:c,
        })
        }).then(response => response.json()).then(response=>{
            localStorage.setItem('lawyerTariffsList',JSON.stringify(response.lawyerTariffsList));
            setIsOpen7(!isOpen7);
        });
    
}

  const [isOpenRow, setisOpenRow] = useState(true);

  const handleAgentRowClick = (itemKey) =>{
      if(isOpenRow){
          document.getElementById(itemKey).style.display="flex";
          setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
          setisOpenRow(!isOpenRow);
      }
    }
    const handleClickSelect = (itemKey) =>{
         if(!isOpenRow){
          document.getElementById(itemKey).style.display="flex";
        //   setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
        //   setisOpenRow(!isOpenRow);
      }
    }
    useEffect(() => {
        fetch('https://telesiness.ir/api/Dashboards/GetMarketersCustomerList' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email') })
            }).then(response => response.json()).then(response=>{
                togglePopup72();
                 var i =0;
                 try
                 {
                    
                    if(people.length<2)
                    {
                        people.pop();
                        var myActDec = ''
                    for (i in response.customerList){
                        // people[i].CustomerName = response.customerList[i].customerFirstName + " " + response.customerList[i].customerLastName;
                        // people[i].CustomerCreated = response.customerList[i].customerRegisterDate;
                        if(response.customerList[i].verifyCustomerByDistributor == 2)
                        {
                        
                        myActDec = "Deactive";
                        }
                        
                        else  if(response.customerList[i].verifyCustomerByDistributor == 1)
                        {
                        
                        myActDec = "Active";
                        }
                        else
                        {
                        
                        myActDec = "Pending"
                        }
                        
                        people.push( {
                        visa:response.customerList[i].customerVisaName,
                        CustomerPic:response.customerList[i].userAvatar, 
                        CustomerName:response.customerList[i].customerFirstName 
                        + " " + 
                        response.customerList[i].customerLastName,
                        CustomerPhone:response.customerList[i].customerPhoneNumber,
                        CustomerStatus:myActDec,
                        CustomerCreated:response.customerList[i].submitDocumentDate,
                        CustomerAgent:response.customerList[i].lawyerFirstName 
                        + " " + 
                        response.customerList[i].lawyerLastName,
                        CustomerAgent2:response.customerList[i].distributorFirstName 
                        + " " + 
                        response.customerList[i].distributorLastName,
                        Email:response.customerList[i].customerEmailAddress,
                        Destination:response.customerList[i].customerVisaDestination,
                        CustomerCode:response.customerList[i].customerCode,
                        customerDocumentId : response.customerList[i].customerDocumentId,
                        agentFirstName:response.customerList[i].agentFirstName,
                        agentLastName:response.customerList[i].agentLastName,
                        UserId: response.customerList[i].userId,
                        LawyerCode:response.customerList[i].lawyerCode,
                        customerServiceCode:response.customerList[i].customerServiceCode,
                        key:(parseInt(i)+1).toString(),},)
                        
                                     }
                     }
                 }
    
                catch{
    
                }
                //console.log(people);
                
            });
      }, []);
    

const [data2, setData2] = useState([]);

const fetchData2 = () => {
    fetch('https://telesiness.ir/api/Dashboards/RelatedDistributorList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                if(localStorage.getItem('isDistributorPlus')!=1)
                setData2(response.distributorsList)
                else
                setData2(response.distributorPlusList) 
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};

const [data, setData] = useState([]);
const [dataR , setDataR] = useState([]);
const [dataR2 , setDataR2] = useState([]);
const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    setDataR2(response.distributorList);
                    setDataR(response.lawyerList);
                  
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};


console.log(localStorage.getItem('email'));
useEffect(() => {
    fetchData();
    fetchData2();
    // console.log(data[0].countery_Name);
  }, []);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
if (counter.current < 1) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

return () => clearTimeout(timer);
}
}, [state]);

const [values, setValues] = useState({

    LawyerCode: '',
    EmailC: '',

  });
  const [SLawyerCode,setSLawyerCode] = useState([]);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }
    return (  
        <> <div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Agent Customers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Agent Customers List')}</p>
                    </div>
                </div>
                {/* <button onClick={handleAddNewUserCustomer} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Customer')}<ProfileAdd size="24" color="#fff" /></button> */}
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Customer')} <a onClick={handleUpArrowCustomer}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowCustomer}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Lawyer')}</p></div>
                <div className="col-3 twocoltop">
                    <div className="col-6 Col-2Status">
                        <p>{t('Status')} </p><div className="FilterIcon" onClick={handleFilterStatus}><Filter  size="16"  color="#313131"/></div> 
                        <div id="FilterStatusBoxID" className="FilterStatusBox">
                            <div className="checkBoxAndLabel">
                                <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                                <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                            </div>
                            <div className="checkBoxAndLabel">
                                <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                                <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                            </div>
                        </div>
                    </div>
                    <div className="col-6"><p>{t('DS')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
                </div>
                
                <div className="col-3"><p>{t('View Information')}</p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
            {people.map(item =>(       
            <div className="itemBox2 col-12"  key={item.key}>
                <div className="agentCusRow col-12">
                    <a className="col-lg-4 col-8 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        <div className="col-3 id_agentCus"><p>{item.key}</p></div>
                        <div className="CusPic2 col-lg-9 col-12">
                        <div className='img_agentCus'><img src={'https://telesiness.ir/CustomersAvatar/'+ item.CustomerPic} alt="" /></div>
                            <div className='detail_agentCus'>
                                <p>{item.CustomerName}</p>
                                <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                            </div>
                        </div>
                    </a>
                    {item.CustomerStatus!='Active' && <div className="col-2 lawyer_agentCus">
                        <select
                        // value={lawyer}
                        value={values.LawyerCode[item.key]}
                        className=''
                        id={'AgCuSelect' + item.key} 
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect' + item.key).value);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.customerDocumentId)
                            togglePopup();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                            <option  value={item.CustomerAgent}>{item.CustomerAgent}</option>
                            {dataR &&
                                dataR.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                      ))}
                       {dataR2 &&
                                dataR2.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                      ))}
                            <option value={t('Assign by Telesiness')}>{t('Assign by Telesiness')}</option>
                        </select>
                        {(item.CustomerAgent == 'Assign by Telesiness' || item.CustomerAgent == 'اختصاص وکیل توسط تلزینس' ) &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>}
                    {item.CustomerStatus=='Active' && <div className="col-2 lawyer_agentCus">
                        <select
                        disabled
                        // value={lawyer}
                        value={values.LawyerCode[item.key]}
                        className=''
                        id={'AgCuSelect' + item.key} 
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect' + item.key).value);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.customerDocumentId)
                            togglePopup();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                            <option  value={item.CustomerAgent}>{item.CustomerAgent}</option>
                            {dataR &&
                                dataR.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                      ))}
                       {dataR2 &&
                                dataR2.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                      ))}
                            <option value={t('Assign by Telesiness')}>{t('Assign by Telesiness')}</option>
                        </select>
                        {(item.CustomerAgent == 'Assign by Telesiness' || item.CustomerAgent == 'اختصاص وکیل توسط تلزینس' ) &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>}
                    <a className="col-lg-1 col-4 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        {item.CustomerStatus=='Active' && <div className="col-12 de_active_agentCus">
                        <button className="active">
                            {t('Active') }
                            <div id={item.key} className="ChoosBox"> 
                                <p className='disabled'>{t('Active')}</p>  <br />
                                <p >{t('Deactive')}</p>
                            </div>
                        </button></div>}

                        {item.CustomerStatus=='Deactive' && <div className="col-12 de_active_agentCus">
                            <button  className="deactive">
                                {t('Deactive') }
                                <div id={item.key} className="ChoosBox"> 
                                    <p >{t('Active')}</p>  <br />
                                    <p className='disabled'>{t('Deactive')}</p>
                                </div>
                            </button>
                            {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                            </div>} 
                            <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div>
                        
                        </div>}
                        {item.CustomerStatus=='Pending' && <div className="col-12 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                        
                        </div>}
   
                    </a>
                    {item.CustomerStatus!='Active' &&<div className="col-2 lawyer_agentCus">
                        <select
                        // value={lawyer}
                        value={values.LawyerCode[item.key]}
                        className=''
                        id={'AgCuSelect2' + item.key} 
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect2' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect2' + item.key).value);
                            localStorage.setItem('customerDocumentCode',item.customerDocumentId);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.customerDocumentId)
                            if(document.getElementById('AgCuSelect2' + item.key).value!=1000)
                            togglePopup4();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                            {/* <option  value={1000}>{'Select a DS'}</option> */}
                            <option  value={item.CustomerAgent2}>{item.CustomerAgent2}</option>
                            {data2 &&
                                data2.map((element) => (
                          <option  value={element.distributorCode}>{element.distributorFirstName + ' ' + element.distributorLastName}</option >
                         
                      ))}
                            {/* <option value={t('Assign by Telesiness')}>{t('Assign by Telesiness')}</option> */}
                        </select>
                        {(item.CustomerAgent == 'Assign by Telesiness' || item.CustomerAgent == 'اختصاص وکیل توسط تلزینس' ) &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>}
                    {item.CustomerStatus=='Active' &&<div className="col-2 lawyer_agentCus">
                        <select
                        disabled
                        // value={lawyer}
                        value={values.LawyerCode[item.key]}
                        className=''
                        id={'AgCuSelect2' + item.key} 
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect2' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect2' + item.key).value);
                            localStorage.setItem('customerDocumentCode',item.customerDocumentId);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.customerDocumentId)
                            if(document.getElementById('AgCuSelect2' + item.key).value!=1000)
                            togglePopup4();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                            {/* <option  value={1000}>{'Select a DS'}</option> */}
                            <option  value={item.CustomerAgent2}>{item.CustomerAgent2}</option>
                            {data2 &&
                                data2.map((element) => (
                          <option  value={element.distributorCode}>{element.distributorFirstName + ' ' + element.distributorLastName}</option >
                         
                      ))}
                            {/* <option value={t('Assign by Telesiness')}>{t('Assign by Telesiness')}</option> */}
                        </select>
                        {(item.CustomerAgent == 'Assign by Telesiness' || item.CustomerAgent == 'اختصاص وکیل توسط تلزینس' ) &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>}
                    <div className="col-3 view_infocus">
                        <div onClick={(dataCustomerHome) => {
                            if(item.CustomerStatus=='Active'){
                                localStorage.setItem('verifyCustomerByDistributor',1);
                            } else if(item.CustomerStatus=='Pending'){
                                localStorage.setItem('verifyCustomerByDistributor',0);
                            }
                            if(item.CustomerStatus=='Deactive'){
                                localStorage.setItem('verifyCustomerByDistributor',2);
                            }
                            localStorage.setItem('customerCode',item.CustomerCode);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('firstTimeCustomer2',item.CustomerStatus)
                            childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark btn_infoLawCus">
                                {t('View')}</div> 
                        {item.CustomerStatus=='Pending' &&<button  onClick={() => {
                            console.log(item.UserId);
                            console.log(item.CustomerCode);
                            handleAcceptByDc(item.UserId,item.CustomerCode,item.customerDocumentId);}} className="btn btn-secondary btn_infoLawCus">{t('Accept')}</button >}  
                        {(item.CustomerStatus=='Deactive'|| item.CustomerStatus=='Active') &&<button disabled onClick={() => {handleAcceptByDc(item.UserID,item.CustomerCode,item.customerDocumentId);}} className="btn btn-secondary btn_infoLawCus ">{t('Accept')}</button >}   
                    </div>
                    <div className="Send_Message">
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                            document.getElementById('PeopleBtn').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.CustomerCode);
                            localStorage.setItem('MsgUserNewType',3);
                            childToParentMessage(dataMessage);
                            handleSendMsgReDirect(item.CustomerName)}}/>
                        <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                        {/* <div className='btn btn-dark btn_addLawCus' >{t('Send Message')}</div> */}
                    </div> 
                </div>
                {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                    <div className="hidenItemRow">
                        <hr className="linebetitem col-12"/>
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                            document.getElementById('PeopleBtn').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.CustomerCode);
                            localStorage.setItem('MsgUserNewType',3);
                            childToParentMessage(dataMessage);
                            handleSendMsgReDirect(item.CustomerName)}}/>
                        <div className="d-lg-none col-12 hidenItem lawyer_agentCusm">
                            <span>{t('Lawyer')}:</span>
                            <select
                            // value={lawyer}
                            className=''
                            id={'AgCuSelect' + item.key} 
                            onChange={handleChange(values.LawyerCode[item.key])}
                            onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                            >
                                <option value={item.CustomerAgent}>{item.CustomerAgent}</option>
                                {dataR &&
                                dataR.map((element) => (
                          <option   value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                                 ))}
                                  {dataR2 &&
                                dataR2.map((element) => (
                          <option   value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                         
                                 ))}
                                <option value={t('Assign by Telesiness')}>{t('Assign by Telesiness')}</option>
                            </select>
                            {(item.CustomerAgent == 'Assign by Telesiness' || item.CustomerAgent == 'اختصاص وکیل توسط تلزینس' ) &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                        </div>
                        <div className="d-lg-none col-12 hidenItem"><span>{t('DS')}:</span><p>{item.CustomerCreated.split('T',1)}</p></div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Phone Number')}:</span><p>{item.CustomerPhone}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Visa')}:</span><p>{item.visa}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Destination')}:</span><p>{item.Destination}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Agent Name')}:</span><p>{item.agentFirstName + ' ' + item.agentLastName}</p> </div>

                        <div className="btn_servlawcus col-lg-12 col-6">
                        <button  onClick={() => {
                            if(item.CustomerAgent2 ==' ')
                                values.itemDCname = true;
                            else
                                values.itemDCname = false;
                           
                            handleServiceInformation(item.LawyerCode,item.customerServiceCode,item.CustomerCode);
                            }} className="btn btn-dark btn_infoLawCus">{t('Lawyer service information')}</button >
                        </div>

                        <div className="d-lg-none col-6 hidenItem all_btnslawcus">
                            <div onClick={(dataCustomerHome) => {
                                localStorage.setItem('customerCode',item.CustomerCode);
                                localStorage.setItem('emailCus',item.Email);
                                childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark btn_infoLawCus">{t('View Information')}</div>
                            {item.CustomerStatus=='Active' &&<div onClick={() => {handleAcceptByDc(item.UserID,item.CustomerCode,item.customerDocumentId);}} className="btn btn-secondary btn_infoLawCus ">{t('Accept')}</div>}    
                        </div>
                    </div>
                </div>}
                <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
            </div>  
            ))}
        </div>
            {isOpen && <Popup
       content={<>
              <div className="PboxFirstRow">
                  <ShieldSecurity size="48" color="#313131" variant="Bold"/>
              </div>
              <div className="PboxSecondRowAgCus">
                <h2>{t('Are You Sure?')}</h2>
              <h4>{t('You choose the lawyer by ') + ' '} <b>{localStorage.getItem('CusLawName')} </b></h4>
              {(localStorage.getItem('CusLawCode') == 'Assign by Telesiness' || localStorage.getItem('CusLawCode') == 'اختصاص وکیل توسط تلزینس' ) && 
              <h4>{t('Pay attention Telesinees has no responsibility for the lawyer')}</h4>}
              </div>
              <div className="PboxForthRowSendMail col-12">
                  <button onClick={togglePopup} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                  <button onClick={() => {handleChange2(localStorage.getItem('CusCustomerCode')
                                                        ,localStorage.getItem('CusLawCode'),
                                                        localStorage.getItem('UserID'))}} 
                                                         className="col-md-3 col-6 btn btn-dark">{t('Send Request')}</button>
              </div>
        </>}
      handleClose={togglePopup}
    />}
     {isOpen4 && <Popup
       content={<>
              <div className="PboxFirstRow">
                  <ShieldSecurity size="48" color="#313131" variant="Bold"/>
              </div>
              <div className="PboxSecondRowAgCus">
                <h2>{t('Are You Sure?')}</h2>
              <h4>{t('You choose the DS by ') + ' '} <b>{localStorage.getItem('CusLawName')} </b></h4>
              {(localStorage.getItem('CusLawCode') == 'Assign by Telesiness' || localStorage.getItem('CusLawCode') == 'اختصاص وکیل توسط تلزینس' ) && 
              <h4>{t('Pay attention Telesinees has no responsibility for the DS')}</h4>}
              </div>
              <div className="PboxForthRowSendMail col-12">
                  <button onClick={togglePopup4} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                  <button onClick={() => {handleChange3(localStorage.getItem('CusCustomerCode')
                                                        ,localStorage.getItem('CusLawCode'),
                                                        localStorage.getItem('UserID'),
                                                        localStorage.getItem('customerDocumentCode'))}} 
                                                         className="col-md-3 col-6 btn btn-dark">{t('Send Request')}</button>
              </div>
        </>}
      handleClose={togglePopup4}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}

{isOpen3 && <Popup
      content={<>
            <div className="PboxFirstRow"> 
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
                <h4>{t('Your Customer Activated')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Pay attention You can Approve Customer Account once, and after that you cant change status!')}</p>
            </div>
            <div className="Pbox_headagentCus PboxSecondRow col-12">
                 <button onClick={togglePopup3} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                 <button onClick={togglePopup3} className="btn btn-dark" type="button" id="button-addon2">{t('OK, I Got It')}</button>
            </div>
      </>}
      handleClose={togglePopup3}
    />}
  {isOpen7 && <Popup2
      content={<>
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Services assigned by the lawyer')}</h4>
            </div>
           
            <div className="box_HomeLightBoxRowTwo col-12">
                        <div className="HomeLightBoxRowTwo col-12">
                            {/* <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                <AddSquare className='AddUserHome' onClick={childToParentPrices} size="40" color="#000000" variant="Bold"/>
                            </div>                              */}
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                {JSON.parse(localStorage.getItem('lawyerTariffsList')).length==0 &&
                                <div className="servassignlaw col-12">
                                    <div className="subLawyerTerafiDcCusRow noservlaw col-12">
                                        <p>{t('There is no service assigned yet.')}</p> 
                                    </div>
                           </div>}  
                                {JSON.parse(localStorage.getItem('lawyerTariffsList')).map(item =>(
                    <div  key={item.key}>
                        
                       <div className="servassignlaw col-12">
                      
                          <div className="LawyerTerafiDcCusRow col-12">
                           {/* {<div className="col-1 id_tariff"><p>{item.key+1}</p> </div>} */}
                           {<div className="subLawyerTerafiDcCusRow col-12"><p>{t('Name of Service') + " : "}</p><p>{item.nameOfVisa}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                {/* <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}
                            </div>}
                            {values.itemDCname &&<div className="subLawyerTerafiDcCusRow col-12"><p>{t('Price') + " : "}</p><p>{"$ " +item.wageFigure}</p></div>}
                            {values.itemDCname && <div className="subLawyerTerafiDcCusRow col-12"><p>{t('DC Commission') + " : "}</p><p>{"% " +item.percentageOfCommission}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Service Code') + " : "}</p><p>{"# " +item.lawyerTariffsCode}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Required Documents') + " : "}</p><p>{item.requiredDocuments}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('Visa Process') + " : "}</p><p>{item.visaProcess}</p></div>}
                            { <div className="subLawyerTerafiDcCusRow col-12"><p>{t('About Service') + " : "}</p><p>{item.aboutService}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
        
      </>}
      handleClose={togglePopup7}
    />}

{isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
    </>
        
    );
}
 
export default Customers;