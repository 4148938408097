import { useState , useEffect } from 'react';
import cus1Pic from './images/cus1.png';
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {TickCircle,Clock,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import ProgressBar from 'react-bootstrap/ProgressBar'

const Cus1 = ({childToParentCus1e1,
    childToParentAplSelfInfoS1,
    childToParentCus3 , 
    childToParenttahsilChoosePage,
    childToParentShoghliInfo,
    childToParentMaliInfo,
    childToParentSafarKharejehInfo,
    childToParentKhanevadeh,
    childToParentSoalVisa}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);  
    //*********************************** */
const dataCus2 = true;
const DocumentTextnow = 60;
const Profilenow = 80;
const weddingnow = 10;
const Profile2Usernow = 35;
const childnow = 68;
const Clipboardnow = 87;
var firstTimeCustomer = true;
firstTimeCustomer = localStorage.getItem('firstTimeCustomer');
console.log(localStorage.getItem('firstTimeCustomer'));
return ( 
         
    <div className="mainRowPro col-12">
      <form  className="">
      <div className="personalInfo col-12">
        <div className="Step1e1">
            <p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Spouse Information')}</p><a className="back_pagelink" onClick={(dataCus1e1)=>{childToParentCus1e1(dataCus1e1)}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a> </div>
        <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
        <div className="Cus2Inputs22">

            <div className="MyCusButtons col-lg-4 col-6" onClick={(dataAplSelfInfoS1)=>{childToParentAplSelfInfoS1(dataAplSelfInfoS1)}}>
                {localStorage.getItem('BasicInfo')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/49</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Personal Information')}</p>
                </div></>}
                {localStorage.getItem('BasicInfo')==49 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>49/49</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Personal Information')}</p>
                </div></>}
                {localStorage.getItem('BasicInfo')!=0 && localStorage.getItem('BasicInfo')!=49 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('BasicInfo')}/49</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Personal Information')}</p>
                </div></>}
            </div>


            {/* <div className="MyCusButtons col-lg-4 col-6" onClick={(dataCus3)=>{childToParentCus3(dataCus3)}}>
                {localStorage.getItem('Passport')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Passport Information')}</p>
                </div></>}
                {localStorage.getItem('Passport')==12 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>12/12</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Passport Information')}</p>
                </div></>}
                {localStorage.getItem('Passport')!=0 && localStorage.getItem('Passport')!=12 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Passport')}/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Passport Information')}</p>
                </div></>}
            </div> */}


            <div className="MyCusButtons col-lg-4 col-6" onClick={(datatahsilChoosePage)=>{childToParenttahsilChoosePage(datatahsilChoosePage)}}>
                {localStorage.getItem('Educational')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/102</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Educational Information')}</p>
                </div></>}
                {localStorage.getItem('Educational')==102 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>102/102</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Educational Information')}</p>
                </div></>}
                {localStorage.getItem('Educational')!=0 && localStorage.getItem('Educational')!=102 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Educational')}/102</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Educational Information')}</p>
                </div></>}
            </div>


            <div className="MyCusButtons col-lg-4 col-6" onClick={(dataShoghli)=>{childToParentShoghliInfo(dataShoghli)}}>
                {localStorage.getItem('Job')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/51</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Job Information')}</p>
                </div></>}
                {localStorage.getItem('Job')==51 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>51/51</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Job Information')}</p>
                </div></>}
                {localStorage.getItem('Job')!=0 && localStorage.getItem('Job')!=51 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Job')}/51</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Job Information')}</p>
                </div></>}
            </div>


            <div className="MyCusButtons col-lg-4 col-6" onClick={(dataMali)=>{childToParentMaliInfo(dataMali)}}>
                {localStorage.getItem('Financial')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/23</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Financial Information')}</p>
                </div></>}
                {localStorage.getItem('Financial')==23 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>23/23</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Financial Information')}</p>
                </div></>}
                {localStorage.getItem('Financial')!=0 && localStorage.getItem('Financial')!=23 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Financial')}/23</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Financial Information')}</p>
                </div></>}
            </div>

            
            <div className="MyCusButtons col-lg-4 col-6" onClick={(dataSafar)=>{childToParentSafarKharejehInfo(dataSafar)}}>
                {localStorage.getItem('Foregin')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/85</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Foreign travel Information')}</p>
                </div></>}
                {localStorage.getItem('Foregin')==85 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>85/85</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Foreign travel Information')}</p>
                </div></>}
                {localStorage.getItem('Foregin')!=0 && localStorage.getItem('Foregin')!=85 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Foregin')}/85</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Foreign travel Information')}</p>
                </div></>}
            </div>



            {/* <div className="MyCusButtons col-lg-4 col-6">
                {localStorage.getItem('Military')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Military Information')}</p>
                </div></>}
                {localStorage.getItem('Military')==12 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>12/12</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Military Information')}</p>
                </div></>}
                {localStorage.getItem('Military')!=0 && localStorage.getItem('Military')!=12 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Military')}/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Military Information')}</p>
                </div></>}
            </div> */}



            {/* <div className="MyCusButtons col-lg-4 col-6">
                {localStorage.getItem('Marriage')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Marriage Information')}</p>
                </div></>}
                {localStorage.getItem('Marriage')==12 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>12/12</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Marriage Information')}</p>
                </div></>}
                {localStorage.getItem('Marriage')!=0 && localStorage.getItem('Marriage')!=12 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Marriage')}/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Marriage Information')}</p>
                </div></>}
            </div> */}



            {/* <div className="MyCusButtons col-lg-4 col-6">
                {localStorage.getItem('Children')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Children Information')}</p>
                </div></>}
                {localStorage.getItem('Children')==12 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>12/12</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Children Information')}</p>
                </div></>}
                {localStorage.getItem('Children')!=0 && localStorage.getItem('Children')!=12 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Children')}/12</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Children Information')}</p>
                </div></>}
            </div> */}



            <div className="MyCusButtons col-lg-4 col-6" onClick={(dataKhanevadeh)=>{childToParentKhanevadeh(dataKhanevadeh)}}>
                {localStorage.getItem('Family')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/162</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Family Information')}</p>
                </div></>}
                {localStorage.getItem('Family')==162 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>162/162</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Family Information')}</p>
                </div></>}
                {localStorage.getItem('Family')!=0 && localStorage.getItem('Family')!=162 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Family')}/162</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Family Information')}</p>
                </div></>}
            </div>


            
            {localStorage.getItem('whoIs')=='ap' &&<div className="MyCusButtons col-lg-4 col-6" onClick={(dataSoalVisa)=>{childToParentSoalVisa(dataSoalVisa)}}>
                {localStorage.getItem('Visa')==0 &&<><div className="MyCusBtnLabel col-12">
                <p>{t('Needs to be completed')}</p>
                <p>0/17</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Requested Visa Information')}</p>
                </div></>}
                {localStorage.getItem('Visa')==17 &&<><div className="MyCusBtnLabelCompleted col-12">
                <p className='MyCusCompletedgreenP'><TickCircle  size="24" variant="Bold" color="#3EC729"/>{t('Completed')}</p>
                <p className='MyCusCompletedgreenP'>17/17</p>
            </div><div className="MyCusBtnBlack col-12">
                    <p>{t('Requested Visa Information')}</p>
                </div></>}
                {localStorage.getItem('Visa')!=0 && localStorage.getItem('Visa')!=17 &&<><div className="MyCusBtnLabelProgress col-12">
                <p className='MyCusProgressP'><Clock  size="24" variant="Bold" color="#D3A281"/>{t('In progress')}</p>
                <p className='MyCusProgressP'>{localStorage.getItem('Visa')}/17</p>
            </div><div className="MyCusBtn col-12">
                    <p>{t('Requested Visa Information')}</p>
                </div></>}
            </div>}
        </div>
       
        {/* <div className="cusS1SaveCol col-12">
          <div className="col-6">
             <button   className="btn col-12 btn-outline-light ">{t('Previous')}</button >
          </div>
          <div className="botCusDraftCol col-6">
            <button className="btn col-6 btn-outline-light ">
              <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
            </button >
            {<button   className="btn btn-dark">{t('Next Step')}</button>}
          </div>
         
        </div> */}
      </div>
      </form>
      
     
{/*         
      {isOpen && <Popup
    content={<>
    <div className="PboxMainRow">
          <div className="PboxFirstRow">
              <ShieldSecurity size="48" color="#313131" variant="Bold"/>
          </div>
          <div className="PboxSecondRow">
          <h4>{t('Are You Sure?')}</h4>
          </div>
          <div className="PboxThirdRow">
              <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
          </div>
          <div className="PboxForthRowSendMail col-12">
              
              <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
              <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
          </div>
      
      </div>
    </>}
    handleClose={togglePopup}
  />}
*/}

   </div>
    );
}
 
export default Cus1;