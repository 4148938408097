import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentRequestedVisaInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      
      CustomerQuestionNumberOne: values.MyQues1,
      CustomerQuestionNumberTwo: values.MyQues2,
      CustomerQuestionNumberThree: values.MyQues3,
      CustomerQuestionNumberFour: values.MyQues4,
      CustomerQuestionNumberFive: values.MyQues5,
      CustomerQuestionNumberSix: values.MyQues6,
      CustomerQuestionNumberSeven: values.MyQues7,
      CustomerQuestionNumberEight: values.MyQues8,
      CustomerQuestionNumberNine: values.MyQues9,
      CustomerQuestionNumberTen: values.MyQues10,
      CustomerQuestionNumberEleven: values.MyQues11,
      CustomerQuestionNumberTwelve: values.MyQues12,
      CustomerQuestionNumberThirteen: values.MyQues13,
      CustomerQuestionNumberFourteen: values.MyQues14,
      CustomerQuestionNumberFifteen: values.MyQues15,
      CustomerQuestionNumberSixteen: values.MyQues16,
      CustomerQuestionNumberSeventeen: values.MyQues17,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentRequestedVisaInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerQuestionNumberOne: values.MyQues1,
           CustomerQuestionNumberTwo: values.MyQues2,
           CustomerQuestionNumberThree: values.MyQues3,
           CustomerQuestionNumberFour: values.MyQues4,
           CustomerQuestionNumberFive: values.MyQues5,
           CustomerQuestionNumberSix: values.MyQues6,
           CustomerQuestionNumberSeven: values.MyQues7,
           CustomerQuestionNumberEight: values.MyQues8,
           CustomerQuestionNumberNine: values.MyQues9,
           CustomerQuestionNumberTen: values.MyQues10,
           CustomerQuestionNumberEleven: values.MyQues11,
           CustomerQuestionNumberTwelve: values.MyQues12,
           CustomerQuestionNumberThirteen: values.MyQues13,
           CustomerQuestionNumberFourteen: values.MyQues14,
           CustomerQuestionNumberFifteen: values.MyQues15,
           CustomerQuestionNumberSixteen: values.MyQues16,
           CustomerQuestionNumberSeventeen: values.MyQues17,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('CustomerQuestionNumberOne',response.customerQuestionNumberOne);
        localStorage.setItem('CustomerQuestionNumberTwo',response.customerQuestionNumberTwo);
        localStorage.setItem('CustomerQuestionNumberThree',response.customerQuestionNumberThree);
        localStorage.setItem('CustomerQuestionNumberFour',response.customerQuestionNumberFour);
        localStorage.setItem('CustomerQuestionNumberFive',response.customerQuestionNumberFive);
        localStorage.setItem('CustomerQuestionNumberSix',response.customerQuestionNumberSix);
        localStorage.setItem('CustomerQuestionNumberSeven',response.customerQuestionNumberSeven);
        localStorage.setItem('CustomerQuestionNumberEight',response.customerQuestionNumberEight);
        localStorage.setItem('CustomerQuestionNumberNine',response.customerQuestionNumberNine);
        localStorage.setItem('CustomerQuestionNumberTen',response.customerQuestionNumberTen);
        localStorage.setItem('CustomerQuestionNumberEleven',response.customerQuestionNumberEleven);
        localStorage.setItem('CustomerQuestionNumberTwelve',response.customerQuestionNumberTwelve);
        localStorage.setItem('CustomerQuestionNumberThirteen',response.customerQuestionNumberThirteen);
        localStorage.setItem('CustomerQuestionNumberFourteen',response.customerQuestionNumberFourteen);
        localStorage.setItem('CustomerQuestionNumberFifteen',response.customerQuestionNumberFifteen);
        localStorage.setItem('CustomerQuestionNumberSixteen',response.customerQuestionNumberSixteen);
        localStorage.setItem('CustomerQuestionNumberSeventeen',response.customerQuestionNumberSeventeen);
        

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        values.MyQues1 = localStorage.getItem('CustomerQuestionNumberOne');
        values.MyQues2 = localStorage.getItem('CustomerQuestionNumberTwo');
        values.MyQues3 = localStorage.getItem('CustomerQuestionNumberThree');
        values.MyQues4 = localStorage.getItem('CustomerQuestionNumberFour');
        values.MyQues5 = localStorage.getItem('CustomerQuestionNumberFive');
        values.MyQues6 = localStorage.getItem('CustomerQuestionNumberSix');
        values.MyQues7 = localStorage.getItem('CustomerQuestionNumberSeven');
        values.MyQues8 = localStorage.getItem('CustomerQuestionNumberEight');
        values.MyQues9 = localStorage.getItem('CustomerQuestionNumberNine');
        values.MyQues10 = localStorage.getItem('CustomerQuestionNumberTen');
        values.MyQues11 = localStorage.getItem('CustomerQuestionNumberEleven');
        values.MyQues12 = localStorage.getItem('CustomerQuestionNumberTwelve');
        values.MyQues13 = localStorage.getItem('CustomerQuestionNumberThirteen');
        values.MyQues14 = localStorage.getItem('CustomerQuestionNumberFourteen');
        values.MyQues15 = localStorage.getItem('CustomerQuestionNumberFifteen');
        values.MyQues16 = localStorage.getItem('CustomerQuestionNumberSixteen');
        values.MyQues17 = localStorage.getItem('CustomerQuestionNumberSeventeen');
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          MyQues1:'',
          MyQues2:'',
          MyQues3:'',
          MyQues4:'',
          MyQues5:'',
          MyQues6:'',
          MyQues7:'',
          MyQues8:'',
          MyQues9:'',
          MyQues10:'',
          MyQues11:'',
          MyQues12:'',
          MyQues13:'',
          MyQues14:'',
          MyQues15:'',
          MyQues16:'',
          MyQues17:'',

          // ProPic: '',
          Country: '',
          City: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          MarriageCertificateNumber:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
          BirthCertificateNumber:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
        };
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
//*************************************** */
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentRequestedVisaInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  

  localStorage.setItem('CustomerQuestionNumberOne',response.customerQuestionNumberOne);
  localStorage.setItem('CustomerQuestionNumberTwo',response.customerQuestionNumberTwo);
  localStorage.setItem('CustomerQuestionNumberThree',response.customerQuestionNumberThree);
  localStorage.setItem('CustomerQuestionNumberFour',response.customerQuestionNumberFour);
  localStorage.setItem('CustomerQuestionNumberFive',response.customerQuestionNumberFive);
  localStorage.setItem('CustomerQuestionNumberSix',response.customerQuestionNumberSix);
  localStorage.setItem('CustomerQuestionNumberSeven',response.customerQuestionNumberSeven);
  localStorage.setItem('CustomerQuestionNumberEight',response.customerQuestionNumberEight);
  localStorage.setItem('CustomerQuestionNumberNine',response.customerQuestionNumberNine);
  localStorage.setItem('CustomerQuestionNumberTen',response.customerQuestionNumberTen);
  localStorage.setItem('CustomerQuestionNumberEleven',response.customerQuestionNumberEleven);
  localStorage.setItem('CustomerQuestionNumberTwelve',response.customerQuestionNumberTwelve);
  localStorage.setItem('CustomerQuestionNumberThirteen',response.customerQuestionNumberThirteen);
  localStorage.setItem('CustomerQuestionNumberFourteen',response.customerQuestionNumberFourteen);
  localStorage.setItem('CustomerQuestionNumberFifteen',response.customerQuestionNumberFifteen);
  localStorage.setItem('CustomerQuestionNumberSixteen',response.customerQuestionNumberSixteen);
  localStorage.setItem('CustomerQuestionNumberSeventeen',response.customerQuestionNumberSeventeen);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentFinancialInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('CustomerQuestionNumberOne',response.customerQuestionNumberOne);
      localStorage.setItem('CustomerQuestionNumberTwo',response.customerQuestionNumberTwo);
      localStorage.setItem('CustomerQuestionNumberThree',response.customerQuestionNumberThree);
      localStorage.setItem('CustomerQuestionNumberFour',response.customerQuestionNumberFour);
      localStorage.setItem('CustomerQuestionNumberFive',response.customerQuestionNumberFive);
      localStorage.setItem('CustomerQuestionNumberSix',response.customerQuestionNumberSix);
      localStorage.setItem('CustomerQuestionNumberSeven',response.customerQuestionNumberSeven);
      localStorage.setItem('CustomerQuestionNumberEight',response.customerQuestionNumberEight);
      localStorage.setItem('CustomerQuestionNumberNine',response.customerQuestionNumberNine);
      localStorage.setItem('CustomerQuestionNumberTen',response.customerQuestionNumberTen);
      localStorage.setItem('CustomerQuestionNumberEleven',response.customerQuestionNumberEleven);
      localStorage.setItem('CustomerQuestionNumberTwelve',response.customerQuestionNumberTwelve);
      localStorage.setItem('CustomerQuestionNumberThirteen',response.customerQuestionNumberThirteen);
      localStorage.setItem('CustomerQuestionNumberFourteen',response.customerQuestionNumberFourteen);
      localStorage.setItem('CustomerQuestionNumberFifteen',response.customerQuestionNumberFifteen);
      localStorage.setItem('CustomerQuestionNumberSixteen',response.customerQuestionNumberSixteen);
      localStorage.setItem('CustomerQuestionNumberSeventeen',response.customerQuestionNumberSeventeen);
 

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('CustomerQuestionNumberOne','');
      localStorage.setItem('CustomerQuestionNumberTwo','');
      localStorage.setItem('CustomerQuestionNumberThree','');
      localStorage.setItem('CustomerQuestionNumberFour','');
      localStorage.setItem('CustomerQuestionNumberFive','');
      localStorage.setItem('CustomerQuestionNumberSix','');
      localStorage.setItem('CustomerQuestionNumberSeven','');
      localStorage.setItem('CustomerQuestionNumberEight','');
      localStorage.setItem('CustomerQuestionNumberNine','');
      localStorage.setItem('CustomerQuestionNumberTen','');
      localStorage.setItem('CustomerQuestionNumberEleven','');
      localStorage.setItem('CustomerQuestionNumberTwelve','');
      localStorage.setItem('CustomerQuestionNumberThirteen','');
      localStorage.setItem('CustomerQuestionNumberFourteen','');
      localStorage.setItem('CustomerQuestionNumberFifteen','');
      localStorage.setItem('CustomerQuestionNumberSixteen','');
      localStorage.setItem('CustomerQuestionNumberSeventeen','');

  
    console.log(localStorage.getItem('email'));
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }


      values.MyQues1 = localStorage.getItem('CustomerQuestionNumberOne');
      values.MyQues2 = localStorage.getItem('CustomerQuestionNumberTwo');
      values.MyQues3 = localStorage.getItem('CustomerQuestionNumberThree');
      values.MyQues4 = localStorage.getItem('CustomerQuestionNumberFour');
      values.MyQues5 = localStorage.getItem('CustomerQuestionNumberFive');
      values.MyQues6 = localStorage.getItem('CustomerQuestionNumberSix');
      values.MyQues7 = localStorage.getItem('CustomerQuestionNumberSeven');
      values.MyQues8 = localStorage.getItem('CustomerQuestionNumberEight');
      values.MyQues9 = localStorage.getItem('CustomerQuestionNumberNine');
      values.MyQues10 = localStorage.getItem('CustomerQuestionNumberTen');
      values.MyQues11 = localStorage.getItem('CustomerQuestionNumberEleven');
      values.MyQues12 = localStorage.getItem('CustomerQuestionNumberTwelve');
      values.MyQues13 = localStorage.getItem('CustomerQuestionNumberThirteen');
      values.MyQues14 = localStorage.getItem('CustomerQuestionNumberFourteen');
      values.MyQues15 = localStorage.getItem('CustomerQuestionNumberFifteen');
      values.MyQues16 = localStorage.getItem('CustomerQuestionNumberSixteen');
      values.MyQues17 =localStorage.getItem('CustomerQuestionNumberSeventeen');

        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


              const [image13 , setImage13] = useState(null);
              const   onImage13Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader13 = new FileReader();
                    reader13.onload = (e) => {
                      setImage13(e.target.result);
                      document.getElementById('target13').style.display ="block";
                      document.getElementById('MyGrayBox13').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader13.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage13 = () =>{
                  document.getElementById('target13').style.display ="none";
                  document.getElementById('MyGrayBox13').style.display= "flex";
                }

        const [DateofIELTSdegreevalue, setDateofIELTSdegreeValue] = useState();
        const [DateofMarriage, setDateofMarriage] = useState();
        const [DateofDivorceorDeath, setDateofDivorceorDeath] = useState();
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState();
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState();
        const [DateofExpirevalue, setDateofExpireValue] = useState();
        const [BirthDayvalue, setBirthDayValue] = useState();
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 2-7')} :</p><p className="Cus2Step1BlackP">{t('Requested Visa Information')}</p>  <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Visa Information')}</p> </div>
          
          <div className="Cus2Inputs">

            
          <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Why did you  choose destination country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues1" 
                value={values.MyQues1}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues1')}
                />
            </div>
           
            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Why did you choose this type of visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues2" 
                value={values.MyQues2}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues2')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Why did you choose the desired university?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues3" 
                value={values.MyQues3}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues3')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Why did you choose the desired field?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues4" 
                value={values.MyQues4}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues4')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What work and study did you do in your country before the visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues5" 
                value={values.MyQues5}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues5')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What is your goal in the destination country after the visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues6" 
                value={values.MyQues6}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues6')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('How do you cover the costs of the destination country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues7" 
                value={values.MyQues7}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues7')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What is your goal after returning to your home country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues8" 
                value={values.MyQues8}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues8')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Do you have relatives or acquaintances or friends in the destination country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues9" 
                value={values.MyQues9}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues9')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What is the idea for a startup visa and who designed it?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues10" 
                value={values.MyQues10}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues10')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What are the sources of funding for a startup visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues11" 
                value={values.MyQues11}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues11')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What is your goal in choosing a startup visa or business visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues12" 
                value={values.MyQues12}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues12')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Are you seeking asylum?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues13" 
                value={values.MyQues13}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues13')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Do you have any refugee relatives in your chosen country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues14" 
                value={values.MyQues14}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues14')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Do you already have investments in the destination country?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues15" 
                value={values.MyQues15}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues15')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('Have you traveled to your chosen country before?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues16" 
                value={values.MyQues16}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues16')}
                />
            </div>

            <div className="inputAndlabel col-12">
           <div className="My500Char"> <p className="SoalVisaLabel">{t('What was your goal in obtaining a Schengen visa?')}</p><p className="ReqiredCus">{t('- in 500 Character')}</p></div> 
              <textarea
                id="MyQues17" 
                value={values.MyQues17}
                type="text"
                placeholder={t('Input Your Answer Here...')}
                onChange={handleChange('MyQues17')}
                />
            </div>
           
          </div>
          


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;