import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,ExportSquare,Trash} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
         childToParentCus1e3(dataCus1);
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          PlaceOfCreation:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
  
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [DateofCreationvalue, setDateofCreationValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        const dataCus4 = true;
        const dataCus1 = true;


        const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 2')} :</p><p className="Cus2Step1BlackP">{t('Complete Passport Information')}</p> </div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo"> <p>{t('Pasport Information')}</p> </div>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PassportNumber" 
                value={values.PassportNumber}
                type="text"
                placeholder={t('Passport Number')}
                onChange={handleChange('PassportNumber')}
                />
                <label htmlFor="PassportNumber">{t('Enter the applicant’s Passport Number')}</label>
            </div>
            
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Name" 
                value={values.Name}
                type="text"
                placeholder={t('Name')}
                onChange={handleChange('Name')}
                />
                <label htmlFor="Name">{t('Enter the applicant’s Name in the Passport')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Family" 
                value={values.Family}
                type="text"
                placeholder={t('Family')}
                onChange={handleChange('Family')}
                />
                <label htmlFor="Family">{t('Enter the applicant’s Family in the Passport')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofCreationvalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Creation Date')}
              id="DateofCreation"
              onChange={setDateofCreationValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Passport Creation Date')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
            <div className="inputAndlabel col-lg-4 col-12">
            { <DatePicker 
              value={DateofExpirevalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Expiration Date')}
              id="ExpireDate"
              onChange={setDateofExpireValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Passport Expiration Date')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="PlaceOfCreation" 
                value={values.PlaceOfCreation}
                type="text"
                placeholder={t('Passport Creation Place')}
                onChange={handleChange('PlaceOfCreation')}
                />
                <label htmlFor="PlaceOfCreation">{t('Enter the applicant’s Passport Creation Place')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="CountryOFPass" 
                value={values.CountryOFPass}
                type="text"
                placeholder={t('Country of Residence')}
                onChange={handleChange('CountryOFPass')}
                />
                <label htmlFor="CountryOFPass">{t('Country of residence stated in the passport')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            { <DatePicker 
              value={BirthDayvalue}
              format="YYYY/MM/DD "
              
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of birth')}
              id="BirthDayOnPass"
              onChange={setBirthDayValue} 
              className="col-12"
            />}
              <label htmlFor="DateofCreation">{t('Enter the applicant’s Date of birth On Passport')}</label>
          </div>
         </div>
         <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Passport Documentations')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">
   <div className="MyCusUploadReactangle col-2">
            <div className="MyCusUploadGray col-10" id="MyGrayBox">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target" src={image} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans col-10" id="MyGrayBoxTrans">
               <Trash  size="32" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
               <ExportSquare  size="32" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Passport Image')}</p> 
          </div>


          <div className="MyCusUploadReactangle col-2">
            <div className="MyCusUploadGray col-10" id="MyGrayBox2">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans col-10" id="MyGrayBoxTrans">
               <Trash  size="32" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare  size="32" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Personal Picture in Pass')}</p> 
          </div>


          <div className="MyCusUploadReactangle col-2">
            <div className="MyCusUploadGray col-10" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans col-10" id="MyGrayBoxTrans">
               <Trash  size="32" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare  size="32" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Sample Passport Signature')}</p> 
          </div>


   </div>

         <div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {<button className="btn btn-dark">{t('Submit')}</button>}
            </div>
           
          </div>

         
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;