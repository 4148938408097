import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import RegisterLogo from './images/LogoRegister.png'
import { ArrowLeft2, Home2,TickSquare} from 'iconsax-react';
import Logo from './images/logo-tele.png';
//*******************end of UI Materials */

import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country
//******************************** */
const ResetPassword = () => {
    //********************************fetching API */
    const [formError , setFormError] = useState(true);
    const [helppassword,setHelppassword] = useState('');
    const [helpConfirm, setHelpConfirm] = useState();
    const [helpUniversity, setHelpUniversity] = useState();
    const [helpPhone, setHelpPhone] = useState();
    const [body, setBody] = useState();
    const [author, setAuthor] = useState('kafa');
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    const handleSubmit = (e) => {
         e.preventDefault();
         fetch('https://telesiness.ir/Api/LawyerRegister/LawyerRegister' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({

          LawyerCode: "",
          AgentReferenceCode: "",
          FullName: localStorage.getItem('FirstName').toString() +" " +localStorage.getItem('LastName').toString(),
          DateOfBirth: localStorage.getItem('myDate').toString(),
          LastEducationalCertificate: localStorage.getItem('Degree').toString(),
          FieldOfStudy: localStorage.getItem('FieldOfStudy').toString(),
          UniveristyName: localStorage.getItem('University').toString(),
          Certificates: localStorage.getItem('Certificate').toString(),
          SpecializedProfession: localStorage.getItem('Job').toString(),
          SpecializedSectionInTheFieldOfImmigration: localStorage.getItem('Specialization').toString(),
          Email: localStorage.getItem('Email').toString(),
          CountryInWhichYouWork: localStorage.getItem('Country').toString(),
          TheCityWhereYouWork: localStorage.getItem('City').toString(),
          LaywerAddress: " ",
          PhoneNumber: localStorage.getItem('Phone').toString(),
          WebsiteAddress: localStorage.getItem('website').toString(),
          IccrcCode: localStorage.getItem('iccrc').toString(),
          LawyerCVName: "",
          AboutMe: localStorage.getItem('About').toString(),
          Username: values.UserName.toString(),
          Password: values.password.toString(),
          RePassword: values.Confirm.toString()

        
         })
        }).then(response => response.json()).then(response=>{

            setIspending(false);
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
          })
            history.push('/');
       
    }
    //***************************************** */

   // ui materials functions...
        const [values, setValues] = useState({
          password: '',
          Confirm: '',
          UserName: '',
          Email: '',
          About: '',
          myDate: '',
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if(!(document.getElementById('inputConfirm').value=="") && 
          !(document.getElementById('inputpassword').value == document.getElementById('inputConfirm').value)){
            //  document.getElementById('errorLabelDegree').style.display="flex";
             setHelppassword('UnEqual Password...');
             setFormError(true);
            }
            else if(!(document.getElementById('inputpassword').value=="")&&
            (document.getElementById('inputpassword').value == document.getElementById('inputConfirm').value)){
              setHelppassword('')
              setFormError(false);
            }

            if(document.activeElement.id=='inputUserName')
              {
                document.getElementById('MyUserNameplaceholder').style.color="transparent";
              }
               if(values.UserName!="")
              {
                 document.getElementById('inputUserName').style.color="#313131";
              }
            
          
            if(document.activeElement.id=='inputpassword')
            {
              document.getElementById('Mypasswordplaceholder').style.color="transparent";
            }
              if(values.password!="")
            {
                document.getElementById('inputpassword').style.color="#313131";
            }

            
            if(document.activeElement.id=='inputConfirm')
            {
              document.getElementById('MyConfirmplaceholder').style.color="transparent";
            }
              if(values.Confirm!="")
            {
                document.getElementById('inputConfirm').style.color="#313131";
            }
        };
      
    
       

// end of ui material functions
const [progress, setProgress] = useState(20);

useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 20 ? 21 : prevProgress + 20));
  }, 10);

  return () => {
    clearInterval(timer);
  };
}, []); 

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    e.preventDefault();
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    document.getElementById('main-row-2id').style.direction="rtl";
}
const  handelUS = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    // document.getElementsByClassName('main-row').style.direction="ltr";
    document.getElementById('main-row-2id').style.direction="ltr";
}

 const handleinputpasswordBlur = (e) =>{
   if(!(document.getElementById('inputpassword').value=="")){
    document.getElementById('errorLabelpassword').style.display="flex";
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
    document.getElementById('MypasswordSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelpassword').style.display="none";
    document.getElementById('Mypasswordplaceholder').style.color="gray";
    document.getElementById('MypasswordSapn').style.color="red";
   }
 }

 const handleinputConfirmBlur = (e) =>{
  if(!(document.getElementById('inputConfirm').value=="")){
   document.getElementById('errorLabelConfirm').style.display="flex";
   document.getElementById('MyConfirmplaceholder').style.color="transparent";
   document.getElementById('MyConfirmSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelConfirm').style.display="none";
   document.getElementById('MyConfirmplaceholder').style.color="gray";
   document.getElementById('MyConfirmSapn').style.color="red";
  }
}

const handleinputUserNameBlur = (e) =>{
  if(!(document.getElementById('inputUserName').value=="")){
   document.getElementById('errorLabelUserName').style.display="flex";
   document.getElementById('MyUserNameplaceholder').style.color="transparent";
   document.getElementById('MyUserNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelUserName').style.display="none";
   document.getElementById('MyUserNameplaceholder').style.color="gray";
   document.getElementById('MyUserNameSapn').style.color="red";
  }
}

// console.log( 
//  localStorage.getItem('Country').toString(),
//  localStorage.getItem('City').toString(),
//  localStorage.getItem('Job').toString(),
//  localStorage.getItem('iccrc').toString(),
//  localStorage.getItem('Certificate').toString(),
//  localStorage.getItem('Specialization').toString(),
//  localStorage.getItem('website').toString(),
//   localStorage.getItem('Upload').toString(),
//  localStorage.getItem('FieldOfStudy').toString(),
//  localStorage.getItem('University').toString(),
//  localStorage.getItem('Degree').toString(),
//   localStorage.getItem('FirstName').toString(),
//  localStorage.getItem('LastName').toString(),
//  localStorage.getItem('Phone').toString(),
//   localStorage.getItem('Email').toString(),
//  localStorage.getItem('About').toString(),
//  localStorage.getItem('myDate').toString(),
//   localStorage.getItem('userType').toString(),
//   values.password , values.Confirm,
// );
//***************************************/
    return (
       
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
          <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
              <div className="mainRegisterheader col-xxl-12 col-12">
              <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting appropriate user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
              </div>
              <div className="progressRowM6 message_sucssesreg col-xxl-10 col-12">
                <div className="myshapes d-none d-sm-flex">
                  <div className="mycircle">
                    <h6>{t('Check Your Email!')}</h6>
                  </div>
                  <div className="myProCirlce5"></div>
                </div>
                <TickSquare size="54" color="#ffffff" variant="Bold" className="d-block d-sm-none"/>
                <div className="mytext6">
                  <h5 className="choosText d-flex d-sm-none">{t('Done! Check Your Email!')}</h5>
                  {/* <h5 className="step1 d-none d-sm-block">{t('You are almost there!')}</h5>
                  <h6 className="choosText">{t('Check Your Email')}</h6> */}
                </div>
                <div className="LastStepRegText">
                    <p className="RegisterHeaderp d-none d-sm-block">{t('Dear user, your information has been successfully registered in our database')}</p>
                    {/* <p className="RegisterHeaderp d-none d-sm-block">{t('Our experts will contact you as soon as possible.')}</p> */}
                    <p className="RegisterHeaderp d-none d-sm-block">{t('To Continue Your Registeration Process By Checking Your Email And Clicking on Activation.')}</p>
                </div>
                <p className="RegisterHeaderp d-none d-sm-block"></p>
                <p className="RegisterHeaderp checkmailmessage d-block d-sm-none">{t('Check Your Email')}</p>
                      <Link to="/" className="btn btn-dark login-btnStep6 col-4">{t('Go to main page')}</Link>
                      
                  
              </div>
              <div className="progressStyle col-xxl-10 col-12">
              </div>
              <div className="myForm col-xxl-9 col-xl-10 col-lg-11 col-12">
                <form onSubmit={handleSubmit}>
                </form>
              </div>
          </div>
          <div className="footerDasilav col-12">
            <div className="footer-copy">
              Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
            <div className="footer-copy2">
              <br />
              Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
          </div>
        </div>
      </>

   
    );
}
 
export default ResetPassword;