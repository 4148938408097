import { useState, useEffect,useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentFinancialInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      

      CustomerTurnover : values.Turnover,
      CustomerStartDateOfTurnover : StartDateofTurnover.toString(),
      CustomerEndDateOfTurnover : EndDateofTurnover.toString(),
      CustomerFinancialAbility : values.FinancialAbility,
      CustomerDateOfFinancial : DateofFinancial.toString(),
      CustomerFinancialCurrencyUnit : values.FinancialCurrencyUnit,
      CustomerTotalAssetsAmountNumber : values.TotalAssetsAmountNumber,
      CustomerCalculatedCurrency : values.CalculatedCurrency,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentFinancialInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerTurnover : values.Turnover,
           CustomerStartDateOfTurnover : StartDateofTurnover.toString(),
           CustomerEndDateOfTurnover : EndDateofTurnover.toString(),
           CustomerFinancialAbility : values.FinancialAbility,
           CustomerDateOfFinancial : DateofFinancial.toString(),
           CustomerFinancialCurrencyUnit : values.FinancialCurrencyUnit,
           CustomerTotalAssetsAmountNumber : values.TotalAssetsAmountNumber,
           CustomerCalculatedCurrency : values.CalculatedCurrency,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerTurnover',response.customerTurnover);
        localStorage.setItem('customerStartDateOfTurnover',response.customerStartDateOfTurnover);
        localStorage.setItem('customerEndDateOfTurnover',response.customerEndDateOfTurnover);
        localStorage.setItem('customerFinancialAbility',response.customerFinancialAbility);
        localStorage.setItem('customerDateOfFinancial',response.customerDateOfFinancial);
        localStorage.setItem('customerFinancialCurrencyUnit',response.customerFinancialCurrencyUnit);
        localStorage.setItem('customerTotalAssetsAmountNumber',response.customerTotalAssetsAmountNumber);
        localStorage.setItem('customerCalculatedCurrency',response.customerCalculatedCurrency);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
        values.Turnover = localStorage.getItem('customerTurnover');
        values.FinancialAbility = localStorage.getItem('customerFinancialAbility');
        values.FinancialCurrencyUnit = localStorage.getItem('customerFinancialCurrencyUnit');
        values.TotalAssetsAmountNumber = localStorage.getItem('customerTotalAssetsAmountNumber');
        values.CalculatedCurrency = localStorage.getItem('customerCalculatedCurrency');
  
        setStartDateofTurnover(localStorage.getItem('customerStartDateOfTurnover'));
        setEndDateofTurnover(localStorage.getItem('customerEndDateOfTurnover'));
        setDateofFinancial(localStorage.getItem('customerDateOfFinancial'));
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
if(localStorage.getItem('whoIs')=='sp'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpouseFinancialInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      

      CustomerSpouseTurnover : values.Turnover,
      CustomerSpouseStartDateOfTurnover : StartDateofTurnover.toString(),
      CustomerSpouseEndDateOfTurnover : EndDateofTurnover.toString(),
      CustomerSpouseFinancialAbility : values.FinancialAbility,
      CustomerSpouseDateOfFinancial : DateofFinancial.toString(),
      CustomerSpouseFinancialCurrencyUnit : values.FinancialCurrencyUnit,
      CustomerSpouseTotalAssetsAmountNumber : values.TotalAssetsAmountNumber,
      CustomerSpouseCalculatedCurrency : values.CalculatedCurrency,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpouseFinancialInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerSpouseTurnover : values.Turnover,
           CustomerSpouseStartDateOfTurnover : StartDateofTurnover.toString(),
           CustomerSpouseEndDateOfTurnover : EndDateofTurnover.toString(),
           CustomerSpouseFinancialAbility : values.FinancialAbility,
           CustomerSpouseDateOfFinancial : DateofFinancial.toString(),
           CustomerSpouseFinancialCurrencyUnit : values.FinancialCurrencyUnit,
           CustomerSpouseTotalAssetsAmountNumber : values.TotalAssetsAmountNumber,
           CustomerSpouseCalculatedCurrency : values.CalculatedCurrency,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerSpouseTurnover',response.customerSpouseTurnover);
        localStorage.setItem('customerSpouseStartDateOfTurnover',response.customerSpouseStartDateOfTurnover);
        localStorage.setItem('customerSpouseEndDateOfTurnover',response.customerSpouseEndDateOfTurnover);
        localStorage.setItem('customerSpouseFinancialAbility',response.customerSpouseFinancialAbility);
        localStorage.setItem('customerSpouseDateOfFinancial',response.customerSpouseDateOfFinancial);
        localStorage.setItem('customerSpouseFinancialCurrencyUnit',response.customerSpouseFinancialCurrencyUnit);
        localStorage.setItem('customerSpouseTotalAssetsAmountNumber',response.customerSpouseTotalAssetsAmountNumber);
        localStorage.setItem('customerSpouseCalculatedCurrency',response.customerSpouseCalculatedCurrency);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
        values.Turnover = localStorage.getItem('customerSpouseTurnover');
        values.FinancialAbility = localStorage.getItem('customerSpouseFinancialAbility');
        values.FinancialCurrencyUnit = localStorage.getItem('customerSpouseFinancialCurrencyUnit');
        values.TotalAssetsAmountNumber = localStorage.getItem('customerSpouseTotalAssetsAmountNumber');
        values.CalculatedCurrency = localStorage.getItem('customerSpouseCalculatedCurrency');
  
        setStartDateofTurnover(localStorage.getItem('customerSpouseStartDateOfTurnover'));
        setEndDateofTurnover(localStorage.getItem('customerSpouseEndDateOfTurnover'));
        setDateofFinancial(localStorage.getItem('customerSpouseDateOfFinancial'));
   
    // values. =localStorage.getItem('CustomerSpouseEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({

          Turnover:'',
          WorkplaceCallNumber2:'',
          FinancialAbility:'',
          FinancialCurrencyUnit:'',
          TotalAssetsAmountNumber:'',
          CalculatedCurrency:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;


//*************************************** */
//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentFinancialInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  
  localStorage.setItem('customerTurnover',response.customerTurnover);
  localStorage.setItem('customerStartDateOfTurnover',response.customerStartDateOfTurnover);
  localStorage.setItem('customerEndDateOfTurnover',response.customerEndDateOfTurnover);
  localStorage.setItem('customerFinancialAbility',response.customerFinancialAbility);
  localStorage.setItem('customerDateOfFinancial',response.customerDateOfFinancial);
  localStorage.setItem('customerFinancialCurrencyUnit',response.customerFinancialCurrencyUnit);
  localStorage.setItem('customerTotalAssetsAmountNumber',response.customerTotalAssetsAmountNumber);
  localStorage.setItem('customerCalculatedCurrency',response.customerCalculatedCurrency);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentFinancialInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerTurnover',response.customerTurnover);
      localStorage.setItem('customerStartDateOfTurnover',response.customerStartDateOfTurnover);
      localStorage.setItem('customerEndDateOfTurnover',response.customerEndDateOfTurnover);
      localStorage.setItem('customerFinancialAbility',response.customerFinancialAbility);
      localStorage.setItem('customerDateOfFinancial',response.customerDateOfFinancial);
      localStorage.setItem('customerFinancialCurrencyUnit',response.customerFinancialCurrencyUnit);
      localStorage.setItem('customerTotalAssetsAmountNumber',response.customerTotalAssetsAmountNumber);
      localStorage.setItem('customerCalculatedCurrency',response.customerCalculatedCurrency);

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerTurnover','');
      localStorage.setItem('customerStartDateOfTurnover','');
      localStorage.setItem('customerEndDateOfTurnover','');
      localStorage.setItem('customerFinancialAbility','');
      localStorage.setItem('customerDateOfFinancial','');
      localStorage.setItem('customerFinancialCurrencyUnit','');
      localStorage.setItem('customerTotalAssetsAmountNumber','');
      localStorage.setItem('customerCalculatedCurrency','');
  
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
  } else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseFinancialInformation' , {
          method: 'POST',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
          body: JSON.stringify({ Email: localStorage.getItem('email')})
        }).then(response => response.json()).then(response=>{
    
          // setIspending(false);
      //  setValues();
      
      
      localStorage.setItem('customerSpouseTurnover',response.customerSpouseTurnover);
      localStorage.setItem('customerSpouseStartDateOfTurnover',response.customerSpouseStartDateOfTurnover);
      localStorage.setItem('customerSpouseEndDateOfTurnover',response.customerSpouseEndDateOfTurnover);
      localStorage.setItem('customerSpouseFinancialAbility',response.customerSpouseFinancialAbility);
      localStorage.setItem('customerSpouseDateOfFinancial',response.customerSpouseDateOfFinancial);
      localStorage.setItem('customerSpouseFinancialCurrencyUnit',response.customerSpouseFinancialCurrencyUnit);
      localStorage.setItem('customerSpouseTotalAssetsAmountNumber',response.customerSpouseTotalAssetsAmountNumber);
      localStorage.setItem('customerSpouseCalculatedCurrency',response.customerSpouseCalculatedCurrency);
      
    
        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        }).catch(()=>{
          if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
          fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseFinancialInformation' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
          }).then(response => response.json()).then(response=>{
            
            // setIspending(false);
         if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
         {
          console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
          
          localStorage.setItem('customerSpouseTurnover',response.customerSpouseTurnover);
          localStorage.setItem('customerSpouseStartDateOfTurnover',response.customerSpouseStartDateOfTurnover);
          localStorage.setItem('customerSpouseEndDateOfTurnover',response.customerSpouseEndDateOfTurnover);
          localStorage.setItem('customerSpouseFinancialAbility',response.customerSpouseFinancialAbility);
          localStorage.setItem('customerSpouseDateOfFinancial',response.customerSpouseDateOfFinancial);
          localStorage.setItem('customerSpouseFinancialCurrencyUnit',response.customerSpouseFinancialCurrencyUnit);
          localStorage.setItem('customerSpouseTotalAssetsAmountNumber',response.customerSpouseTotalAssetsAmountNumber);
          localStorage.setItem('customerSpouseCalculatedCurrency',response.customerSpouseCalculatedCurrency);
    
         
      
        }
         
        }).catch(()=>{
          
          localStorage.setItem('customerSpouseTurnover','');
          localStorage.setItem('customerSpouseStartDateOfTurnover','');
          localStorage.setItem('customerSpouseEndDateOfTurnover','');
          localStorage.setItem('customerSpouseFinancialAbility','');
          localStorage.setItem('customerSpouseDateOfFinancial','');
          localStorage.setItem('customerSpouseFinancialCurrencyUnit','');
          localStorage.setItem('customerSpouseTotalAssetsAmountNumber','');
          localStorage.setItem('customerSpouseCalculatedCurrency','');
      
        
       
        })
        else{
          // localStorage.setItem('customerSpouseEnglishLanguageStatus','');
          // localStorage.setItem('customerSpouseTurnover','');
          // localStorage.setItem('customerSpouseStartDateOfTurnover','');
          // localStorage.setItem('customerSpouseEndDateOfTurnover','');
          // localStorage.setItem('customerSpouseFinancialAbility','');
          // localStorage.setItem('customerSpouseDateOfFinancial','');
          // localStorage.setItem('customerSpouseFinancialCurrencyUnit','');
          // localStorage.setItem('customerSpouseTotalAssetsAmountNumber','');
          // localStorage.setItem('customerSpouseCalculatedCurrency','');
        }
        })
      } 
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }
      if(localStorage.getItem('whoIs')=='ap'){
      values.Turnover = localStorage.getItem('customerTurnover');
      values.FinancialAbility = localStorage.getItem('customerFinancialAbility');
      values.FinancialCurrencyUnit = localStorage.getItem('customerFinancialCurrencyUnit');
      values.TotalAssetsAmountNumber = localStorage.getItem('customerTotalAssetsAmountNumber');
      values.CalculatedCurrency = localStorage.getItem('customerCalculatedCurrency');

      setStartDateofTurnover(localStorage.getItem('customerStartDateOfTurnover'));
      setEndDateofTurnover(localStorage.getItem('customerEndDateOfTurnover'));
      setDateofFinancial(localStorage.getItem('customerDateOfFinancial'));
      }
      else if(localStorage.getItem('whoIs')=='sp'){
        values.Turnover = localStorage.getItem('customerSpouseTurnover');
        values.FinancialAbility = localStorage.getItem('customerSpouseFinancialAbility');
        values.FinancialCurrencyUnit = localStorage.getItem('customerSpouseFinancialCurrencyUnit');
        values.TotalAssetsAmountNumber = localStorage.getItem('customerSpouseTotalAssetsAmountNumber');
        values.CalculatedCurrency = localStorage.getItem('customerSpouseCalculatedCurrency');
  
        setStartDateofTurnover(localStorage.getItem('customerSpouseStartDateOfTurnover'));
        setEndDateofTurnover(localStorage.getItem('customerSpouseEndDateOfTurnover'));
        setDateofFinancial(localStorage.getItem('customerSpouseDateOfFinancial'));
      }
        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


            const [image13 , setImage13] = useState(null);
            const   onImage13Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader13 = new FileReader();
                  reader13.onload = (e) => {
                    setImage13(e.target.result);
                    document.getElementById('target13').style.display ="block";
                    document.getElementById('MyGrayBox13').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader13.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage13 = () =>{
                document.getElementById('target13').style.display ="none";
                document.getElementById('MyGrayBox13').style.display= "flex";
              }


          const [image14 , setImage14] = useState(null);
        const   onImage14Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader14 = new FileReader();
              reader14.onload = (e) => {
                setImage14(e.target.result);
                document.getElementById('target14').style.display ="block";
                document.getElementById('MyGrayBox14').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader14.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage14 = () =>{
            document.getElementById('target14').style.display ="none";
            document.getElementById('MyGrayBox14').style.display= "flex";
          }
           
          

    const [image15 , setImage15] = useState(null);
    const   onImage15Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader15 = new FileReader();
          reader15.onload = (e) => {
            setImage15(e.target.result);
            document.getElementById('target15').style.display ="block";
            document.getElementById('MyGrayBox15').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader15.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage15 = () =>{
        document.getElementById('target15').style.display ="none";
        document.getElementById('MyGrayBox15').style.display= "flex";
      }

        const [DateFirstJob, setDateFirstJob] = useState()
        const [StartDateofTurnover, setStartDateofTurnover] = useState()
        const [EndDateofTurnover, setEndDateofTurnover] = useState()
        const [DateofFinancial, setDateofFinancial] = useState()
        const [Date5Job, setDate5Job] = useState()
        const [Date6Job, setDate6Job] = useState()
        const [DateBusinessLicense1, setDateBusinessLicense1] = useState()
        const [DateBusinessLicense2, setDateBusinessLicense2] = useState()
        const [DateBusinessLicense3, setDateBusinessLicense3] = useState()
        const [DateBusinessLicense4, setDateBusinessLicense4] = useState()
        const [DateBusinessLicense5, setDateBusinessLicense5] = useState()
        const [DateBusinessLicense6, setDateBusinessLicense6] = useState()
        const [LicenseExpirationDate1, setLicenseExpirationDate1] = useState()
        const [LicenseExpirationDate2, setLicenseExpirationDate2] = useState()
        const [LicenseExpirationDate3, setLicenseExpirationDate3] = useState()
        const [LicenseExpirationDate4, setLicenseExpirationDate4] = useState()
        const [LicenseExpirationDate5, setLicenseExpirationDate5] = useState()
        const [LicenseExpirationDate6, setLicenseExpirationDate6] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 4')} :</p>
          <p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && 
          t('Applicant Financial Information')}{localStorage.getItem('whoIs')=='sp' && 
          t('Applicants Spouse Financial Information')}</p> 
          <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a>
        </div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Turnover Number')}</p> </div>
          
          <div className="Cus2Inputs">

          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Turnover" 
                value={values.Turnover}
                type="text"
                placeholder={t('Turnover')}
                onChange={handleChange('Turnover')}
                required
                />
                <label htmlFor="Turnover">{t('Enter the applicant’s Turnover')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={StartDateofTurnover}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Start Date of Turnover')}
              id="StartDateofTurnover"
              onChange={setStartDateofTurnover} 
              className="col-12"
              required
            />}
              <label htmlFor="StartDateofTurnover">{t('Enter the applicant’s Start Date of Turnover')}<p className="ReqiredCus">{t('(Required)')}</p></label>
          </div>
          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={EndDateofTurnover}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('End Date of Turnover')}
              id="EndDateofTurnover"
              onChange={setEndDateofTurnover} 
              className="col-12"
              required
            />}
              <label htmlFor="EndDateofTurnover">{t('Enter the applicant’s End Date of Turnover')}<p className="ReqiredCus">{t('(Required)')}</p></label>
          </div>
          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FinancialAbility" 
                value={values.FinancialAbility}
                type="text"
                placeholder={t('Financial Ability')}
                onChange={handleChange('FinancialAbility')}
                required
                />
                <label htmlFor="FinancialAbility">{t('Enter the applicant’s Financial Ability')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofFinancial}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Financial')}
              id="DateofFinancial"
              onChange={setDateofFinancial} 
              className="col-12"
              required
            />}
              <label htmlFor="DateofFinancial">{t('Enter the applicant’s Date of Financial')}<p className="ReqiredCus">{t('(Required)')}</p></label>
          </div>
          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FinancialCurrencyUnit" 
                value={values.FinancialCurrencyUnit}
                type="text"
                placeholder={t('Financial Currency Unit')}
                onChange={handleChange('FinancialCurrencyUnit')}
                required
                />
                <label htmlFor="FinancialCurrencyUnit">{t('Enter the applicant’s Financial Currency Unit')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Total Assets Amount')}</p> 
          
          </div>


            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalAssetsAmountNumber" 
                value={values.TotalAssetsAmountNumber}
                type="text"
                placeholder={t('Total Assets Amount in Number')}
                onChange={handleChange('TotalAssetsAmountNumber')}
                />
                <label htmlFor="TotalAssetsAmountNumber">{t('Enter the applicant’s Total Assets Amount in Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="CalculatedCurrency" 
                value={values.CalculatedCurrency}
                type="text"
                placeholder={t('Calculated Currency')}
                onChange={handleChange('CalculatedCurrency')}
                />
                <label htmlFor="CalculatedCurrency">{t('Enter the applicant’s Calculated Currency')}</label>
            </div>

          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Financial Documents')}</p> 
          
          </div>
          
          
          
   <div className="MyFileUploadRow">

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target" src={image} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Turnover (1)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox2">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Turnover (2)')}</p> 
          </div>
          </div>

           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Financial Ability (1)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Financial Ability (2)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Property Document (1)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox6">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage6Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Property Document (2)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox7">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage7Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Property Document (2)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox8">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage8Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Property Document (3)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox9">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage9Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Factory Document')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox10">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage10Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Shop Document')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox11">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage11Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Certificate of Stock Exchange')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox12">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage12Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Lease')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox13">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage13Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Financial Supporter Letter (1)')}</p> 
          </div>
          </div>


           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox14">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage14Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Financial Supporter Letter (2)')}</p> 
          </div>
          </div>



           <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox15">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage15Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target15" src={image15} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage15} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Documents')}</p> 
          </div>
          </div>


          
         

   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;