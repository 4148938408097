import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import RegisterLogo from './images/LogoRegister.png';
import { Link } from "react-router-dom";
import {Facebook,Whatsapp,CallCalling,Instagram,Youtube,Home2,SearchNormal1} from 'iconsax-react';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='content_page'>
                <div className='header_page col-12'>
                    <div className='top_header_page col-md-11 col-12'>
                        <div className='titlehead_page col-4'>
                            <h3 className="font-face-gm">{t('Terms')}</h3>
                        </div>
                        <div className='logohead_page col-md-4 col-6'>
                            <img src={RegisterLogo} alt="" />
                        </div>
                        <Link className="font-face-gm icohead_page col-md-4 col-2" to='/'>
                            <Home2 color="white" size="24" />
                        </Link>
                    </div>
                </div>
                <div className='body_page col-12'>
                    <div className='total_terms col-12'>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Agreement to Terms of Telesiness`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`This is a legally binding agreement written between the users (real person and legal person) who use our services and Telesiness about the access and the use of the site and application, and also, any form of our services collectively and it is mandatory for both groups or parties to comply with all provisions.`}</p>
                                    
                                    <p className="font-face-gm">{`You agree, by accessing this service, to read and understand these terms and agree to commit to them. If you do not agree to all of these terms, you are expressly prohibited from using this service and must stop using it immediately.`}</p>
                                    
                                    <p className="font-face-gm">{`Additional terms of services or documentation that may occasionally be posted on the site or program are expressly set forth herein with reference. We reserve the right, due to our view, to make changes or modifications to these terms of service at any time and for any reasons.`}</p>
                                    
                                    <p className="font-face-gm">{`We will notify you of any changes by updating the date "last update" of these terms of service, and you waive any right to receive special notice of any changes here or on the site or schedule.`}</p>
                                    
                                    <p className="font-face-gm">{`It is your responsibility to review these terms of service periodically for updates. You will be subject to changes to any of the terms of service revised by the continued use of the site and the program after the posting date of such revised terms of service and will be deemed to have been acknowledged and accepted by you. The information provided on the site and the program has not been intended for distribution or use by any person or entity in any jurisdiction or country where the distribution or use is contrary to law or regulation or subject us to any registration requirements in this area.`}</p>
                                    
                                    <p className="font-face-gm">{`Accordingly, those persons who want to use the service from other locations, they do it on their own initiative and if the local laws are enforceable, they should obey the local laws.`}</p>
                                    
                                    <p className="font-face-gm">{`This service is intended for users who are at least 18 years old. All users who are considered children in the jurisdiction that they live (usually under the age of 18) for using this service must have permission from their parents or guardians and should be under their direct supervision. If you are children, you should ask your parents or guardian to read and agree to these terms and conditions before using this service.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Intellectual and Scientific Property Rights of Telesiness `}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    {/* <h4 className="font-face-gm">{t('What service do you want?')}</h4> */}
                                    <p className="font-face-gm">{`Telesiness is an application program consisting of an interconnected network of management home pages for business development that reserves all material and intellectual property rights of its owners protected by copyright and trademark law and other intellectual property rights and the laws of unfair competition in Canada, foreign jurisdictions and international conventions. 
In this application, the content and tags are provided for your personal information and use only on the site and application "as it is". Except as expressly provided in these terms of service, any section of the site or the application and any content should not be copied, duplicated, collected, republished, uploaded, posted, publicly displayed, encrypted, translated, transferred, distributed and sold without, for any commercial purposes, our written permission. 
`}</p>
                                    <p className="font-face-gm">{`Once you have determined that you are eligible for using this service, you are limited to accessing and using the service and downloading, printing or copying any part and section of the content that you have properly accessed and it is given only for your personal and non-commercial content. We reserve all rights not expressly granted to you in the service, site, schedule, content and trademark. `}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Privacy Policy of Telesiness`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`We care about data privacy and security. We have performed a privacy policy to inform you of its actions regarding the collection, use, disclosure and protection of your information. 
Please review our privacy policy posted on the site. By using this service, you agree to be bound by our privacy policy which is included in these terms of service. Please note that the site and application are hosted in Canada.  
If you have access to this service from the European Union, Asia, Australia, the United States or any other region of the world with laws or other requirements relating to the collection, use or disclosure of personal data that are applicable in Canada, through continuous use of service, if any, you are transferring your data to Canada and expressly agree to transferring and processing your data to Canada. Please review our data collection policy regarding the collection and processing of data for all users, including those outside of Canada. `}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`User Agencies of Telesiness `}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <h4 className="font-face-gm">{`By using this service, you represent and ensure that;`}</h4>
                                    <p className="font-face-gm">{` 1 - All registration information that you send will be correct, accurate, current and complete.`}</p>
                                    <p className="font-face-gm">{` 2 - You will maintain the accuracy of such information and if such changes are necessary, you will immediately update the registration information.`}</p>
                                    <p className="font-face-gm">{` 3 - You have the legal capacity and agree to follow the terms of service.`}</p>
                                    <p className="font-face-gm">{` 4- You are not less than 18 years old.`}</p>
                                    <p className="font-face-gm">{` 5 - You are not a child in the jurisdiction in which you live, or if you are a child, you have obtained parental permission to use the site, if necessary. `}</p>
                                    <p className="font-face-gm">{` 6 -  You will not have access to the service through automatic or inhuman tools, whether through robots, scripts or other things.`}</p>
                                    <p className="font-face-gm">{` 7 - You will not use this service for illegal or unauthorized purposes.`}</p>
                                    <p className="font-face-gm">{` 8 - Your use of the service does not violate any applicable laws or regulations.`}</p>
                                    <p className="font-face-gm">{` 9 - You have the right to build only one home page and all your functions will be recorded in it. `}</p>
                                    <p className="font-face-gm">{` 10 - In any field you work in, you must follow the rules of that field or class. `}</p>
                                    <p className="font-face-gm">{` 11 - The responsibility of employees, marketers and managers of your home page is with you.`}</p>
                                    <p className="font-face-gm">{` If you provide incorrect and incomplete information that, we have the right to suspend or terminate your account and have the right to refuse any current and future use of the service (or any part of the service). `}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Registration of User `}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`You may be asked to sign up for the service by providing your name, date of birth and e-mail address, as well as creating a username and password to set up your profile and account. You agree to keep your password confidential, if any, and you are responsible for all use of your account and password.`}</p>
                                    <h4 className="font-face-gm">{` We reserve the right to delete, retrieve, or modify your chosen username if we find in our discretion that any of the following issues has occurred:`}</h4>
                                    <p className="font-face-gm">{` (A) The username is inappropriate, improper or non-objectionable. `}</p>
                                    <p className="font-face-gm">{` (B) This username is inactive or unused for a period of more than 5 months or more.`}</p>
                                    <p className="font-face-gm">{` (C) This username is used to impersonate the user and fake user identity. `}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Communication preferences and satisfaction:`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`Sometimes we may contact you by the e-mail address associated with your account (the same e-mail address used during registration). These communications may include new information, products and recommendations, special offers, and other account-related information or transaction messages, but are not limited to them. These communications will also include instructions for requesting consent, acceptance and cancellation. You may also receive SMS and related communications from this service when registering with this service.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Prohibited activities:  `}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`You may not access this service or use it for any purpose other than the purpose that we make the service available. This service may not be used in connection with any business activity except as specifically approved by us. 
This service is based on the smart model and marketing follows the drip system, and we emphasize that the network system should not be pyramidal. Telesiness ensures that it fights and prohibits the functioning of the pyramid system.`}</p>
                                    <h4 className="font-face-gm">{`As a service user, you acknowledge that the following items are wrong and you should agree that you may be dealt legally if you do any of these issues:`}</h4>
                                    <p className="font-face-gm">{`1 - Any unauthorized use of this service such as collecting the usernames or e-mail addresses of users via electronic way or other tools in order to send unsolicited e-mails, or creating user accounts automatically or under false requests.`}</p>
                                    <p className="font-face-gm">{`2 - To buy or upload content in the site or program from the purchasing agent or another agent.`}</p>
                                    <p className="font-face-gm">{`3 - To use this service for advertising or to suggest for selling goods and service.`}</p>
                                    <p className="font-face-gm">{`4 - Violating, disabling, or interfering with the security features of the service is one of the features that prevent or restrict the use or copy of any content or restrictions on the use of the service or the content.`}</p>
                                    <p className="font-face-gm">{`5 - To participate in unauthorized formatting or linking to the service.`}</p>
                                    <p className="font-face-gm">{`6 - You deceive or mislead us and other users especially trying to learn sensitive account information such as user passwords. `}</p>
                                    <p className="font-face-gm">{`7 - Misuse of our support services or submission of incorrect reports of abuse or misconduct. `}</p>
                                    <p className="font-face-gm">{`8 - Any automated use of the system, such as the use of scripts to send comments or messages, or the use of any data mining, robots, or similar data collection and extraction tools. `}</p>
                                    <p className="font-face-gm">{`9 - Disrupting or creating an inappropriate load on the service or networks or other services connected to the site or program. `}</p>
                                    <p className="font-face-gm">{`10 - Attempting to impersonate another user or person or use the username of another user of the service.`}</p>
                                    <p className="font-face-gm">{`11 - Selling or transferring your account. `}</p>
                                    <p className="font-face-gm">{`12 - Using any information obtained from the service in order to hurt, abuse or harm another person. `}</p>
                                    <p className="font-face-gm">{`13 - Using this service as a helper to compete with us or use the service or content for any revenue generation or business company.`}</p>
                                    <p className="font-face-gm">{`14 - Decryption, isolation or reverse engineering of any software including some part of service.`}</p>
                                    <p className="font-face-gm">{`15 - Trying to disobey actions in the service that are designed to prevent or restrict access to the service or any part of the service. `}</p>
                                    <p className="font-face-gm">{`16 - Harassing, intimidating or threatening any of the users.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Terms of using our services`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`We develop technologies and services that enable business owners to communicate with each other, shape communities, and grow businesses. These conditions govern your benefit of Telesinesss and other products, features, services, technologies, and software we present. Except for the items we announce, different requirements apply to them. These products are now brought to you by the Adab Squad Custometer Service.`}</p>
                                    <p className="font-face-gm">{`We don't charge you for using Telesiness or other products and services we cover. Instead, business owners and organizations pay us to advertise their products, increase their workforce, and manage their human resources and accounts. Using our service, you agree that we can show you some resolutions to improve your business according to your needs. We use your data to help your company improve.`}</p>
                                    <p className="font-face-gm">{`We do not sell your data to advertisers. We do not share the information which leads to your recognition directly (e.g., name, address, e-mail, or other contact info) with advertising agencies, organizations, companies, and institutes; unless you decide otherwise.`}</p>
                                    <p className="font-face-gm">{`Our data frame explains how we gather and use your data to grow your human force network to improve your business. 
Also, your dashboard data categorizes under your privacy, and in no circumstances would it be used in other places unless you decide otherwise. All dashboards you create help the other dashboards, so each complete dashboard helps the others and the human resource society grow and gives you better service.
`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`1/1 The services we offer`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`Our mission is to support distributors' and marketers' economic and moral rights. Also, to lower the side and current costs for business owners. We offer the products and services explained below to succeed in this mission.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Provide yourself a personal dashboard:`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`Your experience would be different in Telesiness. You own a space where you can introduce your products or services. The better your dashboard, the more distributors you attract. The more distributors you have, the more customers you will have. We will use data such as your connections, your choices, the settings you choose, the work you do and share, the services you present, and how you introduce them to personalize your experience of business improvement. `}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`We connect you with people and organizations that you need:`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`We help you, the user of our products, to find people, groups, businesses, and organizations in Telesiness, connect with them, and use their services. We use our data to offer propositions to you and the others. For example, according to the data of each dashboard, we can extend the other dashboards. Everyone helps with their business development according to their dashboard's completion process. 
We empower you to find yourself, build your brand, and grow your business.
`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Giving Idea or Opinion`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`You acknowledge and consent that any question, opinion, suggestion, idea, feedback, or other information regarding services ("Posted Items") delivered by you, via anything including e-mail, letter, messengers, or verbal or in various meetings are not private to us, and shall be strictly in our possession. We shall have exclusive rights, including all intellectual property rights. We shall have the right to use these posted items and publish them, if necessary, for legal, commercial, or other purposes. You have no right to sue without your knowledge or pay loss.`}</p>
                                    <p className="font-face-gm">{`With this, you waive all moral rights regarding any post and guarantee that each of your posted items is original. You're also allowed to post these items. You agree that we're not responsible for any violation in claim or act or abuse of any property right in your posted items, and you can't act against us.`}</p>
                                    <h4 className="font-face-gm">{`We reserve this right for us yet are not committed to:`}</h4>
                                    <p className="font-face-gm">{`1) supervising the service for violating these conditions of the service.`}</p>
                                    <p className="font-face-gm">{`2) taking appropriate legal action against anyone who violates the rules or these conditions, by our judgment. Also, to report such a user to the law, with no limits.`}</p>
                                    <p className="font-face-gm">{`3) to reject any of your activities or part of it, limit access to it, limit availability, or (as much as technically possible) inactivate it, by our judgment, and with no limitation.`}</p>
                                    <p className="font-face-gm">{`4)Deleting or inactivating all files and contents which are extremely large or are heavy for our system one way or another, by our judgment and with no limitation, warning, or responsibility.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`User data`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`We will maintain certain data you transfer to the service, to service management, and the data related to your application. Although we usually have data backups, you are only responsible for the data you transfer or related to any activity you have done using the service.
You agree that w have no responsibility regarding the loss damage of these data to you. With this, you wave any action against us regarding any loss or corruption of the data.
`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`Connections, deals, and electronic signatures`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`Visiting the service, creating the profile user account, signing up as a user, sending an e-mail to us, and filling in online forms, shapes the electronic connections. You consent to receive electronic links and agree that all the agreements, announcements, revelations, and other connections we deliver to you electronically via e-mail and this service, will fulfill any legal requirement for such relationships to be written.
With this, you consent to use electronic signatures, agreements, orders, other records, and the electronic delivery of announcements, policies, and transaction records that has begun by the USA.
As a result, you wave all the rights or necessities regarding the laws, rules, orders, or other laws in any legal sphere that requires an original signature, delivering, or maintaining non-electronic records, payments or giving credits in any different kinds.  
`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='box_termlog col-12'>
                            <div className='termlog'>
                                <div className='title_termlog col-md-5 col-12'>
                                    <h3 className="font-face-gm">{`User Attraction Rules`}</h3>
                                </div>
                                <div className='txt_termlog col-md-7 col-12'>
                                    <p className="font-face-gm">{`This section explains the general rules of attracting and presenting the dashboards to the users. All of our users acknowledge that they have read these rules and conditions and have accepted them. If they disagree, they should leave this platform immediately and not use it.`}</p>
                                    <h3 className="font-face-gm">{`Customer:`}</h3>
                                    <p className="font-face-gm">{`Customers' access to our network is only available through other users of this platform. No customer can enter the Telesiness without a reference. We or their connections have already confirmed all the contacts. The customer can use all the services in their dashboard after entering it through a verified user. The customers should support their references so that they receive their services ideally.`}</p>
                                    <h3 className="font-face-gm">{`Agent:`}</h3>
                                    <p className="font-face-gm">{`Agents can enter the Telesiness under two conditions.`}</p>
                                    <p className="font-face-gm">{`1- through confirmation and reference of a distributor 2- by default without having any reference
In the first condition, they'll naturally follow their reference and work in their reference's network.
In the second condition, after they sign up, our manager will introduce them to the nearest distributor, according to their living or job location 
Attention: No agent can start working in Telesiness without having a distributor.
Agents can do marketing unlimitedly and introduce customers to their distributors.
`}</p>
                                    <h4 className="font-face-gm">{`General membership conditions as an agent:`}</h4>
                                    <p className="font-face-gm">{`1- Be over 18 years old`}</p>
                                    <p className="font-face-gm">{`2- Have read our general and partial rules and acknowledge them. `}</p>
                                    <p className="font-face-gm">{`3- Be familiar with social networks`}</p>
                                    <p className="font-face-gm">{`4- Be familiar with the competitive atmosphere of the market`}</p>
                                    <p className="font-face-gm">{`5- Be familiar with the marketing atmosphere`}</p>
                                    <p className="font-face-gm">{`6- Be good in social relationships`}</p>
                                    <p className="font-face-gm">{`7- Follow their distributor`}</p>
                                    <p className="font-face-gm">{`8- Complete ID, general and personal certificates `}</p>
                                    <p className="font-face-gm">{`9- Be active and expand their activity`}</p>
                                    <h4 className="font-face-gm">{`Agents can also apply for membership as a distributor after passing these qualifications:`}</h4>
                                    <p className="font-face-gm">{`1- 3 months passed since they have entered the Telesiness`}</p>
                                    <p className="font-face-gm">{`2- They have introduced at least 12 successful customers to their distributer`}</p>
                                    <p className="font-face-gm">{`3- They have established an active social network that's acceptable to us`}</p>
                                    <p className="font-face-gm">{`4- Have an official website`}</p>
                                    <p className="font-face-gm">{`5- Have registered a company or have a business license`}</p>
                                    <p className="font-face-gm">{`6- Introduce an office`}</p>
                                    <h3 className="font-face-gm">{`Distributer:`}</h3>
                                    <p className="font-face-gm">{`Distributors can enter the Telesiness in three ways.`}</p>
                                    <p className="font-face-gm">{`1- Through confirmation and reference of another distributor, or distributor plus (+) `}</p>
                                    <p className="font-face-gm">{`2- Through verification and reference of a business owner `}</p>
                                    <p className="font-face-gm">{`3- Through confirmation of our support team`}</p>
                                    <p className="font-face-gm">{`In the first condition, with confirmation of another distributor, they can earn the management dashboard of the distributor. Yet still, they need to have the requirements set by us. If they don't qualify, the following damages would be upon the reference distributer.`}</p>
                                    <p className="font-face-gm">{`In the second condition, with confirmation of a business owner, they can earn the management dashboard of the distributor. Yet still, they need to have the requirements set by us. If they don't qualify, all the following damages would be upon the reference business owner. `}</p>
                                    <p className="font-face-gm">{`In the third condition, if a complex doesn't have any references and yet meets the requirements of distributors in their network, they can negotiate with our support team. After general assessments, they can earn the management dashboard of a distributor.`}</p>
                                    <p className="font-face-gm">{`Attention: Normal distributors are only allowed to attract 30 agents.`}</p>
                                    <h4 className="font-face-gm">{`General membership requirements as a distributor:`}</h4>
                                    <p className="font-face-gm">{`1- Be over 18 years old`}</p>
                                    <p className="font-face-gm">{`2- Have read our general and partial rules and acknowledge them.`}</p>
                                    <p className="font-face-gm">{`3- Be familiar with social networks`}</p>
                                    <p className="font-face-gm">{`4- Be familiar with the competitive atmosphere of the market`}</p>
                                    <p className="font-face-gm">{`5- Be familiar with the marketing atmosphere`}</p>
                                    <p className="font-face-gm">{`6- Be good in social relationships`}</p>
                                    <p className="font-face-gm">{`7- Follow their distributor`}</p>
                                    <p className="font-face-gm">{`8- Expand their activity`}</p>
                                    <p className="font-face-gm">{`9- 3 months have passed since they've entered Telesiness`}</p>
                                    <p className="font-face-gm">{`10- They have introduced at least 12 successful customers to their distributer`}</p>
                                    <p className="font-face-gm">{`11- They have established an active social network that's acceptable to us`}</p>
                                    <p className="font-face-gm">{`12- Have an official website`}</p>
                                    <p className="font-face-gm">{`13- Have registered a company or have a business license`}</p>
                                    <p className="font-face-gm">{`14- Introduce an office`}</p>
                                    <p className="font-face-gm">{`15- Their dashboard point must have reached 1900`}</p>
                                    <p className="font-face-gm">{`In case they have not been a former agent and are introduced by us, they should have at least two permanent employees and have paid their taxes`}</p>
                                    <h3 className="font-face-gm">{`Distributor plus (+)`}</h3>
                                    <p className="font-face-gm">{`This super-premium account has all the requirements for a regular distributor and should have acted like that.`}</p>
                                    <p className="font-face-gm">{`The difference between these accounts with regular distributors is that they can connect with other distributor pluses in a VIP form. Also, they can add standard distributors to their network and use their cooperation and marketing network to their benefit.`}</p>
                                    <h4 className="font-face-gm">{`To apply for these kinds of management dashboards, they should have the requirements below:`}</h4>
                                    <p className="font-face-gm">{`1- Have all the needs of a regular distributor`}</p>
                                    <p className="font-face-gm">{`2- The age of their standard distributor is at least six months`}</p>
                                    <p className="font-face-gm">{`3- Have at least 12 active agents`}</p>
                                    <p className="font-face-gm">{`4- Is cooperating with at least 12 business owners`}</p>
                                    <p className="font-face-gm">{`5- Should have at least introduced 24 customers to their business owners`}</p>
                                    <p className="font-face-gm">{`6- Their dashboard point has reached 3700`}</p>
                                    <h3 className="font-face-gm">{`Business owner:`}</h3>
                                    <p className="font-face-gm">{`Business owners can enter our management dashboards in two ways`}</p>
                                    <p className="font-face-gm">{`1- Through the reference of a distributor`}</p>
                                    <p className="font-face-gm">{`2- By signing up directly at Telesiness`}</p>
                                    <p className="font-face-gm">{`In any case, business owners should meet the general requirements to enter these kinds of dashboards. After the inquiry and verifications, their management panel activates.`}</p>
                                    <p className="font-face-gm">{`Attention: No business owner can enter our network without a certificate or business license.`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer_page col-12'>
                    <div className="about-us col-12">
                        <div className="spanAbout">
                        <p> 
                            <Link to="/services"> <span>{t('Services')+' '}</span></Link>
                            <span>|</span>  <Link to="/AboutusLogin"><span>{t('About')}</span></Link> <span>|</span>
                            <Link to="/CollaborationsLogin"><span> {t('Collaborations')}</span></Link> <span>|</span> 
                            <Link to="/TermsLogin"><span>{' '+t('Terms')}</span></Link> <span>|</span>
                            <Link to="/HelpLogin"><span> {t('Help')}</span></Link> <span>|</span> 
                            <Link to="/ContactUsLogin"><span>{' '+t('Contact Us')}</span></Link> 
                        </p>
                        </div>
                    </div>
                    <div className="footer-copy col-12">
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                    <div className="footer-copy2 col-12">
                    <br />
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                </div>
        </div>
    )
}
 
export default Cus1;