import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e1}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentChildrenInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),

      CustomerNumberOfChildren: values.childNumb, 
      CustomerChildFirstNameNumberOne: values.ChildName1, 
      CustomerChildSurnameNumberOne: values.ChildFamily1, 
      CustomerChildGenderNumberOne: values.ChildGender1, 
      CustomerChildAgeNumberOne: values.ChildAge1, 
      CustomerChildFirstNameNumberTwo: values.ChildName2, 
      CustomerChildSurnameNumberTwo: values.ChildFamily2, 
      CustomerChildGenderNumberTwo: values.ChildGender2, 
      CustomerChildAgeNumberTwo: values.ChildAge2, 
      CustomerChildFirstNameNumberThree: values.ChildName3, 
      CustomerChildSurnameNumberThree: values.ChildFamily3, 
      CustomerChildGenderNumberThree: values.ChildGender3, 
      CustomerChildAgeNumberThree: values.ChildAge3, 
      CustomerChildFirstNameNumberFour: values.ChildName4, 
      CustomerChildSurnameNumberFour: values.ChildFamily4, 
      CustomerChildGenderNumberFour: values.ChildGender4, 
      CustomerChildAgeNumberFour: values.ChildAge4, 
      CustomerChildFirstNameNumberFive: values.ChildName5, 
      CustomerChildSurnameNumberFive: values.ChildFamily5, 
      CustomerChildGenderNumberFive: values.ChildGender5, 
      CustomerChildAgeNumberFive: values.ChildAge5, 
      CustomerChildFirstNameNumberSix: values.ChildName6, 
      CustomerChildSurnameNumberSix: values.ChildFamily6, 
      CustomerChildGenderNumberSix: values.ChildGender6, 
      CustomerChildAgeNumberSix: values.ChildAge6, 
      CustomerChildFirstNameNumberSeven: values.ChildName7, 
      CustomerChildSurnameNumberSeven: values.ChildFamily7, 
      CustomerChildGenderNumberSeven: values.ChildGender7, 
      CustomerChildAgeNumberSeven: values.ChildAge7, 
      CustomerChildFirstNameNumberEight: values.ChildName8, 
      CustomerChildSurnameNumberEight: values.ChildFamily8, 
      CustomerChildGenderNumberEight: values.ChildGender8, 
      CustomerChildAgeNumberEight: values.ChildAge8, 
      CustomerChildFirstNameNumberNine: values.ChildName9, 
      CustomerChildSurnameNumberNine: values.ChildFamily9, 
      CustomerChildGenderNumberNine: values.ChildGender9, 
      CustomerChildAgeNumberNine: values.ChildAge9, 
      CustomerChildFirstNameNumberTen: values.ChildName10, 
      CustomerChildSurnameNumberTen: values.ChildFamily10, 
      CustomerChildGenderNumberTen: values.ChildGender10, 
      CustomerChildAgeNumberTen: values.ChildAge10, 
      CustomerChildFirstNameNumberEleven: values.ChildName11, 
      CustomerChildSurnameNumberEleven: values.ChildFamily11, 
      CustomerChildGenderNumberEleven: values.ChildGender11, 
      CustomerChildAgeNumberEleven: values.ChildAge11, 
      CustomerChildFirstNameNumberTwelve: values.ChildName12, 
      CustomerChildSurnameNumberTwelve: values.ChildFamily12, 
      CustomerChildGenderNumberTwelve: values.ChildGender12, 
      CustomerChildAgeNumberTwelve: values.ChildAge12,

  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentChildrenInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerNumberOfChildren: values.childNumb, 
           CustomerChildFirstNameNumberOne: values.ChildName1, 
           CustomerChildSurnameNumberOne: values.ChildFamily1, 
           CustomerChildGenderNumberOne: values.ChildGender1, 
           CustomerChildAgeNumberOne: values.ChildAge1, 
           CustomerChildFirstNameNumberTwo: values.ChildName2, 
           CustomerChildSurnameNumberTwo: values.ChildFamily2, 
           CustomerChildGenderNumberTwo: values.ChildGender2, 
           CustomerChildAgeNumberTwo: values.ChildAge2, 
           CustomerChildFirstNameNumberThree: values.ChildName3, 
           CustomerChildSurnameNumberThree: values.ChildFamily3, 
           CustomerChildGenderNumberThree: values.ChildGender3, 
           CustomerChildAgeNumberThree: values.ChildAge3, 
           CustomerChildFirstNameNumberFour: values.ChildName4, 
           CustomerChildSurnameNumberFour: values.ChildFamily4, 
           CustomerChildGenderNumberFour: values.ChildGender4, 
           CustomerChildAgeNumberFour: values.ChildAge4, 
           CustomerChildFirstNameNumberFive: values.ChildName5, 
           CustomerChildSurnameNumberFive: values.ChildFamily5, 
           CustomerChildGenderNumberFive: values.ChildGender5, 
           CustomerChildAgeNumberFive: values.ChildAge5, 
           CustomerChildFirstNameNumberSix: values.ChildName6, 
           CustomerChildSurnameNumberSix: values.ChildFamily6, 
           CustomerChildGenderNumberSix: values.ChildGender6, 
           CustomerChildAgeNumberSix: values.ChildAge6, 
           CustomerChildFirstNameNumberSeven: values.ChildName7, 
           CustomerChildSurnameNumberSeven: values.ChildFamily7, 
           CustomerChildGenderNumberSeven: values.ChildGender7, 
           CustomerChildAgeNumberSeven: values.ChildAge7, 
           CustomerChildFirstNameNumberEight: values.ChildName8, 
           CustomerChildSurnameNumberEight: values.ChildFamily8, 
           CustomerChildGenderNumberEight: values.ChildGender8, 
           CustomerChildAgeNumberEight: values.ChildAge8, 
           CustomerChildFirstNameNumberNine: values.ChildName9, 
           CustomerChildSurnameNumberNine: values.ChildFamily9, 
           CustomerChildGenderNumberNine: values.ChildGender9, 
           CustomerChildAgeNumberNine: values.ChildAge9, 
           CustomerChildFirstNameNumberTen: values.ChildName10, 
           CustomerChildSurnameNumberTen: values.ChildFamily10, 
           CustomerChildGenderNumberTen: values.ChildGender10, 
           CustomerChildAgeNumberTen: values.ChildAge10, 
           CustomerChildFirstNameNumberEleven: values.ChildName11, 
           CustomerChildSurnameNumberEleven: values.ChildFamily11, 
           CustomerChildGenderNumberEleven: values.ChildGender11, 
           CustomerChildAgeNumberEleven: values.ChildAge11, 
           CustomerChildFirstNameNumberTwelve: values.ChildName12, 
           CustomerChildSurnameNumberTwelve: values.ChildFamily12, 
           CustomerChildGenderNumberTwelve: values.ChildGender12, 
           CustomerChildAgeNumberTwelve: values.ChildAge12,
       
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerNumberOfChildren',response.customerNumberOfChildren);
        localStorage.setItem('customerChildFirstNameNumberOne',response.customerChildFirstNameNumberOne);
        localStorage.setItem('customerChildSurnameNumberOne',response.customerChildSurnameNumberOne);
        localStorage.setItem('customerChildGenderNumberOne',response.customerChildGenderNumberOne);
        localStorage.setItem('customerChildAgeNumberOne',response.customerChildAgeNumberOne);
        localStorage.setItem('customerChildFirstNameNumberTwo',response.customerChildFirstNameNumberTwo);
        localStorage.setItem('customerChildSurnameNumberTwo',response.customerChildSurnameNumberTwo);
        localStorage.setItem('customerChildGenderNumberTwo',response.customerChildGenderNumberTwo);
        localStorage.setItem('customerChildAgeNumberTwo',response.customerChildAgeNumberTwo);
        localStorage.setItem('customerChildFirstNameNumberThree',response.customerChildFirstNameNumberThree);
        localStorage.setItem('customerChildSurnameNumberThree',response.customerChildSurnameNumberThree);
        localStorage.setItem('customerChildGenderNumberThree',response.customerChildGenderNumberThree);
        localStorage.setItem('customerChildAgeNumberThree',response.customerChildAgeNumberThree);
        localStorage.setItem('customerChildFirstNameNumberFour',response.customerChildFirstNameNumberFour);
        localStorage.setItem('customerChildSurnameNumberFour',response.customerChildSurnameNumberFour);
        localStorage.setItem('customerChildGenderNumberFour',response.customerChildGenderNumberFour);
        localStorage.setItem('customerChildAgeNumberFour',response.customerChildAgeNumberFour);
        localStorage.setItem('customerChildFirstNameNumberFive',response.customerChildFirstNameNumberFive);
        localStorage.setItem('customerChildSurnameNumberFive',response.customerChildSurnameNumberFive);
        localStorage.setItem('customerChildGenderNumberFive',response.customerChildGenderNumberFive);
        localStorage.setItem('customerChildAgeNumberFive',response.customerChildAgeNumberFive);
        localStorage.setItem('customerChildFirstNameNumberSix',response.customerChildFirstNameNumberSix);
        localStorage.setItem('customerChildSurnameNumberSix',response.customerChildSurnameNumberSix);
        localStorage.setItem('customerChildGenderNumberSix',response.customerChildGenderNumberSix);
        localStorage.setItem('customerChildAgeNumberSix',response.customerChildAgeNumberSix);
        localStorage.setItem('customerChildFirstNameNumberSeven',response.customerChildFirstNameNumberSeven);
        localStorage.setItem('customerChildSurnameNumberSeven',response.customerChildSurnameNumberSeven);
        localStorage.setItem('customerChildGenderNumberSeven',response.customerChildGenderNumberSeven);
        localStorage.setItem('customerChildAgeNumberSeven',response.customerChildAgeNumberSeven);
        localStorage.setItem('customerChildFirstNameNumberEight',response.customerChildFirstNameNumberEight);
        localStorage.setItem('customerChildSurnameNumberEight',response.customerChildSurnameNumberEight);
        localStorage.setItem('customerChildGenderNumberEight',response.customerChildGenderNumberEight);
        localStorage.setItem('customerChildAgeNumberEight',response.customerChildAgeNumberEight);
        localStorage.setItem('customerChildFirstNameNumberNine',response.customerChildFirstNameNumberNine);
        localStorage.setItem('customerChildSurnameNumberNine',response.customerChildSurnameNumberNine);
        localStorage.setItem('customerChildGenderNumberNine',response.customerChildGenderNumberNine);
        localStorage.setItem('customerChildAgeNumberNine',response.customerChildAgeNumberNine);
        localStorage.setItem('customerChildFirstNameNumberTen',response.customerChildFirstNameNumberTen);
        localStorage.setItem('customerChildSurnameNumberTen',response.customerChildSurnameNumberTen);
        localStorage.setItem('customerChildGenderNumberTen',response.customerChildGenderNumberTen);
        localStorage.setItem('customerChildAgeNumberTen',response.customerChildAgeNumberTen);
        localStorage.setItem('customerChildFirstNameNumberEleven',response.customerChildFirstNameNumberEleven);
        localStorage.setItem('customerChildSurnameNumberEleven',response.customerChildSurnameNumberEleven);
        localStorage.setItem('customerChildGenderNumberEleven',response.customerChildGenderNumberEleven);
        localStorage.setItem('customerChildAgeNumberEleven',response.customerChildAgeNumberEleven);
        localStorage.setItem('customerChildFirstNameNumberTwelve',response.customerChildFirstNameNumberTwelve);
        localStorage.setItem('customerChildSurnameNumberTwelve',response.customerChildSurnameNumberTwelve);
        localStorage.setItem('customerChildGenderNumberTwelve',response.customerChildGenderNumberTwelve);
        localStorage.setItem('customerChildAgeNumberTwelve',response.customerChildAgeNumberTwelve);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        values.childNumb = localStorage.getItem('customerNumberOfChildren');
        values.ChildName1 = localStorage.getItem('customerChildFirstNameNumberOne');
        values.ChildFamily1 = localStorage.getItem('customerChildSurnameNumberOne');
        values.ChildGender1 = localStorage.getItem('customerChildGenderNumberOne');
        values.ChildAge1 = localStorage.getItem('customerChildAgeNumberOne');
        values.ChildName2 = localStorage.getItem('customerChildFirstNameNumberTwo');
        values.ChildFamily2 = localStorage.getItem('customerChildSurnameNumberTwo');
        values.ChildGender2 = localStorage.getItem('customerChildGenderNumberTwo');
        values.ChildAge2 = localStorage.getItem('customerChildAgeNumberTwo');
        values.ChildName3 = localStorage.getItem('customerChildFirstNameNumberThree');
        values.ChildFamily3 = localStorage.getItem('customerChildSurnameNumberThree');
        values.ChildGender3 = localStorage.getItem('customerChildGenderNumberThree');
        values.ChildAge3 = localStorage.getItem('customerChildAgeNumberThree');
        values.ChildName4 = localStorage.getItem('customerChildFirstNameNumberFour');
        values.ChildFamily4 = localStorage.getItem('customerChildSurnameNumberFour');
        values.ChildGender4 = localStorage.getItem('customerChildGenderNumberFour');
        values.ChildAge4 = localStorage.getItem('customerChildAgeNumberFour');
        values.ChildName5 = localStorage.getItem('customerChildFirstNameNumberFive');
        values.ChildFamily5 = localStorage.getItem('customerChildSurnameNumberFive');
        values.ChildGender5 = localStorage.getItem('customerChildGenderNumberFive');
        values.ChildAge5 = localStorage.getItem('customerChildAgeNumberFive');
        values.ChildName6 = localStorage.getItem('customerChildFirstNameNumberSix');
        values.ChildFamily6 = localStorage.getItem('customerChildSurnameNumberSix');
        values.ChildGender6 = localStorage.getItem('customerChildGenderNumberSix');
        values.ChildAge6 = localStorage.getItem('customerChildAgeNumberSix');
        values.ChildName7 = localStorage.getItem('customerChildFirstNameNumberSeven');
        values.ChildFamily7 = localStorage.getItem('customerChildSurnameNumberSeven');
        values.ChildGender7 = localStorage.getItem('customerChildGenderNumberSeven');
        values.ChildAge7 = localStorage.getItem('customerChildAgeNumberSeven');
        values.ChildName8 = localStorage.getItem('customerChildFirstNameNumberEight');
        values.ChildFamily8 = localStorage.getItem('customerChildSurnameNumberEight');
        values.ChildGender8 = localStorage.getItem('customerChildGenderNumberEight');
        values.ChildAge8 = localStorage.getItem('customerChildAgeNumberEight');
        values.ChildName9 = localStorage.getItem('customerChildFirstNameNumberNine');
        values.ChildFamily9 = localStorage.getItem('customerChildSurnameNumberNine');
        values.ChildGender9 = localStorage.getItem('customerChildGenderNumberNine');
        values.ChildAge9 = localStorage.getItem('customerChildAgeNumberNine');
        values.ChildName10 = localStorage.getItem('customerChildFirstNameNumberTen');
        values.ChildFamily10 = localStorage.getItem('customerChildSurnameNumberTen');
        values.ChildGender10 = localStorage.getItem('customerChildGenderNumberTen');
        values.ChildAge10 = localStorage.getItem('customerChildAgeNumberTen');
        values.ChildName11 = localStorage.getItem('customerChildFirstNameNumberEleven');
        values.ChildFamily11 = localStorage.getItem('customerChildSurnameNumberEleven');
        values.ChildGender11 = localStorage.getItem('customerChildGenderNumberEleven');
        values.ChildAge11 = localStorage.getItem('customerChildAgeNumberEleven');
        values.ChildName12 = localStorage.getItem('customerChildFirstNameNumberTwelve');
        values.ChildFamily12 = localStorage.getItem('customerChildSurnameNumberTwelve');
        values.ChildGender12 = localStorage.getItem('customerChildGenderNumberTwelve');
        values.ChildAge12 = localStorage.getItem('customerChildAgeNumberTwelve');
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          ChildName1:'',
          ChildName2:'',
          ChildName3:'',
          ChildName4:'',
          ChildName5:'',
          ChildName6:'',
          ChildName7:'',
          ChildName8:'',
          ChildName9:'',
          ChildName10:'',
          ChildName11:'',
          ChildName12:'',

          ChildFamily1:'',
          ChildFamily2:'',
          ChildFamily3:'',
          ChildFamily4:'',
          ChildFamily5:'',
          ChildFamily6:'',
          ChildFamily7:'',
          ChildFamily8:'',
          ChildFamily9:'',
          ChildFamily10:'',
          ChildFamily11:'',
          ChildFamily12:'',

          ChildGender1:'',
          ChildGender2:'',
          ChildGender3:'',
          ChildGender4:'',
          ChildGender5:'',
          ChildGender6:'',
          ChildGender7:'',
          ChildGender8:'',
          ChildGender9:'',
          ChildGender10:'',
          ChildGender11:'',
          ChildGender12:'',

          ChildAge1:'',
          ChildAge2:'',
          ChildAge3:'',
          ChildAge4:'',
          ChildAge5:'',
          ChildAge6:'',
          ChildAge7:'',
          ChildAge8:'',
          ChildAge9:'',
          ChildAge10:'',
          ChildAge11:'',
          ChildAge12:'',

          childNumb:'',
          
    
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
//*************************************** */
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentChildrenInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  localStorage.setItem('customerNumberOfChildren',response.customerNumberOfChildren);
  localStorage.setItem('customerChildFirstNameNumberOne',response.customerChildFirstNameNumberOne);
  localStorage.setItem('customerChildSurnameNumberOne',response.customerChildSurnameNumberOne);
  localStorage.setItem('customerChildGenderNumberOne',response.customerChildGenderNumberOne);
  localStorage.setItem('customerChildAgeNumberOne',response.customerChildAgeNumberOne);
  localStorage.setItem('customerChildFirstNameNumberTwo',response.customerChildFirstNameNumberTwo);
  localStorage.setItem('customerChildSurnameNumberTwo',response.customerChildSurnameNumberTwo);
  localStorage.setItem('customerChildGenderNumberTwo',response.customerChildGenderNumberTwo);
  localStorage.setItem('customerChildAgeNumberTwo',response.customerChildAgeNumberTwo);
  localStorage.setItem('customerChildFirstNameNumberThree',response.customerChildFirstNameNumberThree);
  localStorage.setItem('customerChildSurnameNumberThree',response.customerChildSurnameNumberThree);
  localStorage.setItem('customerChildGenderNumberThree',response.customerChildGenderNumberThree);
  localStorage.setItem('customerChildAgeNumberThree',response.customerChildAgeNumberThree);
  localStorage.setItem('customerChildFirstNameNumberFour',response.customerChildFirstNameNumberFour);
  localStorage.setItem('customerChildSurnameNumberFour',response.customerChildSurnameNumberFour);
  localStorage.setItem('customerChildGenderNumberFour',response.customerChildGenderNumberFour);
  localStorage.setItem('customerChildAgeNumberFour',response.customerChildAgeNumberFour);
  localStorage.setItem('customerChildFirstNameNumberFive',response.customerChildFirstNameNumberFive);
  localStorage.setItem('customerChildSurnameNumberFive',response.customerChildSurnameNumberFive);
  localStorage.setItem('customerChildGenderNumberFive',response.customerChildGenderNumberFive);
  localStorage.setItem('customerChildAgeNumberFive',response.customerChildAgeNumberFive);
  localStorage.setItem('customerChildFirstNameNumberSix',response.customerChildFirstNameNumberSix);
  localStorage.setItem('customerChildSurnameNumberSix',response.customerChildSurnameNumberSix);
  localStorage.setItem('customerChildGenderNumberSix',response.customerChildGenderNumberSix);
  localStorage.setItem('customerChildAgeNumberSix',response.customerChildAgeNumberSix);
  localStorage.setItem('customerChildFirstNameNumberSeven',response.customerChildFirstNameNumberSeven);
  localStorage.setItem('customerChildSurnameNumberSeven',response.customerChildSurnameNumberSeven);
  localStorage.setItem('customerChildGenderNumberSeven',response.customerChildGenderNumberSeven);
  localStorage.setItem('customerChildAgeNumberSeven',response.customerChildAgeNumberSeven);
  localStorage.setItem('customerChildFirstNameNumberEight',response.customerChildFirstNameNumberEight);
  localStorage.setItem('customerChildSurnameNumberEight',response.customerChildSurnameNumberEight);
  localStorage.setItem('customerChildGenderNumberEight',response.customerChildGenderNumberEight);
  localStorage.setItem('customerChildAgeNumberEight',response.customerChildAgeNumberEight);
  localStorage.setItem('customerChildFirstNameNumberNine',response.customerChildFirstNameNumberNine);
  localStorage.setItem('customerChildSurnameNumberNine',response.customerChildSurnameNumberNine);
  localStorage.setItem('customerChildGenderNumberNine',response.customerChildGenderNumberNine);
  localStorage.setItem('customerChildAgeNumberNine',response.customerChildAgeNumberNine);
  localStorage.setItem('customerChildFirstNameNumberTen',response.customerChildFirstNameNumberTen);
  localStorage.setItem('customerChildSurnameNumberTen',response.customerChildSurnameNumberTen);
  localStorage.setItem('customerChildGenderNumberTen',response.customerChildGenderNumberTen);
  localStorage.setItem('customerChildAgeNumberTen',response.customerChildAgeNumberTen);
  localStorage.setItem('customerChildFirstNameNumberEleven',response.customerChildFirstNameNumberEleven);
  localStorage.setItem('customerChildSurnameNumberEleven',response.customerChildSurnameNumberEleven);
  localStorage.setItem('customerChildGenderNumberEleven',response.customerChildGenderNumberEleven);
  localStorage.setItem('customerChildAgeNumberEleven',response.customerChildAgeNumberEleven);
  localStorage.setItem('customerChildFirstNameNumberTwelve',response.customerChildFirstNameNumberTwelve);
  localStorage.setItem('customerChildSurnameNumberTwelve',response.customerChildSurnameNumberTwelve);
  localStorage.setItem('customerChildGenderNumberTwelve',response.customerChildGenderNumberTwelve);
  localStorage.setItem('customerChildAgeNumberTwelve',response.customerChildAgeNumberTwelve);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentChildrenInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerNumberOfChildren',response.customerNumberOfChildren);
      localStorage.setItem('customerChildFirstNameNumberOne',response.customerChildFirstNameNumberOne);
      localStorage.setItem('customerChildSurnameNumberOne',response.customerChildSurnameNumberOne);
      localStorage.setItem('customerChildGenderNumberOne',response.customerChildGenderNumberOne);
      localStorage.setItem('customerChildAgeNumberOne',response.customerChildAgeNumberOne);
      localStorage.setItem('customerChildFirstNameNumberTwo',response.customerChildFirstNameNumberTwo);
      localStorage.setItem('customerChildSurnameNumberTwo',response.customerChildSurnameNumberTwo);
      localStorage.setItem('customerChildGenderNumberTwo',response.customerChildGenderNumberTwo);
      localStorage.setItem('customerChildAgeNumberTwo',response.customerChildAgeNumberTwo);
      localStorage.setItem('customerChildFirstNameNumberThree',response.customerChildFirstNameNumberThree);
      localStorage.setItem('customerChildSurnameNumberThree',response.customerChildSurnameNumberThree);
      localStorage.setItem('customerChildGenderNumberThree',response.customerChildGenderNumberThree);
      localStorage.setItem('customerChildAgeNumberThree',response.customerChildAgeNumberThree);
      localStorage.setItem('customerChildFirstNameNumberFour',response.customerChildFirstNameNumberFour);
      localStorage.setItem('customerChildSurnameNumberFour',response.customerChildSurnameNumberFour);
      localStorage.setItem('customerChildGenderNumberFour',response.customerChildGenderNumberFour);
      localStorage.setItem('customerChildAgeNumberFour',response.customerChildAgeNumberFour);
      localStorage.setItem('customerChildFirstNameNumberFive',response.customerChildFirstNameNumberFive);
      localStorage.setItem('customerChildSurnameNumberFive',response.customerChildSurnameNumberFive);
      localStorage.setItem('customerChildGenderNumberFive',response.customerChildGenderNumberFive);
      localStorage.setItem('customerChildAgeNumberFive',response.customerChildAgeNumberFive);
      localStorage.setItem('customerChildFirstNameNumberSix',response.customerChildFirstNameNumberSix);
      localStorage.setItem('customerChildSurnameNumberSix',response.customerChildSurnameNumberSix);
      localStorage.setItem('customerChildGenderNumberSix',response.customerChildGenderNumberSix);
      localStorage.setItem('customerChildAgeNumberSix',response.customerChildAgeNumberSix);
      localStorage.setItem('customerChildFirstNameNumberSeven',response.customerChildFirstNameNumberSeven);
      localStorage.setItem('customerChildSurnameNumberSeven',response.customerChildSurnameNumberSeven);
      localStorage.setItem('customerChildGenderNumberSeven',response.customerChildGenderNumberSeven);
      localStorage.setItem('customerChildAgeNumberSeven',response.customerChildAgeNumberSeven);
      localStorage.setItem('customerChildFirstNameNumberEight',response.customerChildFirstNameNumberEight);
      localStorage.setItem('customerChildSurnameNumberEight',response.customerChildSurnameNumberEight);
      localStorage.setItem('customerChildGenderNumberEight',response.customerChildGenderNumberEight);
      localStorage.setItem('customerChildAgeNumberEight',response.customerChildAgeNumberEight);
      localStorage.setItem('customerChildFirstNameNumberNine',response.customerChildFirstNameNumberNine);
      localStorage.setItem('customerChildSurnameNumberNine',response.customerChildSurnameNumberNine);
      localStorage.setItem('customerChildGenderNumberNine',response.customerChildGenderNumberNine);
      localStorage.setItem('customerChildAgeNumberNine',response.customerChildAgeNumberNine);
      localStorage.setItem('customerChildFirstNameNumberTen',response.customerChildFirstNameNumberTen);
      localStorage.setItem('customerChildSurnameNumberTen',response.customerChildSurnameNumberTen);
      localStorage.setItem('customerChildGenderNumberTen',response.customerChildGenderNumberTen);
      localStorage.setItem('customerChildAgeNumberTen',response.customerChildAgeNumberTen);
      localStorage.setItem('customerChildFirstNameNumberEleven',response.customerChildFirstNameNumberEleven);
      localStorage.setItem('customerChildSurnameNumberEleven',response.customerChildSurnameNumberEleven);
      localStorage.setItem('customerChildGenderNumberEleven',response.customerChildGenderNumberEleven);
      localStorage.setItem('customerChildAgeNumberEleven',response.customerChildAgeNumberEleven);
      localStorage.setItem('customerChildFirstNameNumberTwelve',response.customerChildFirstNameNumberTwelve);
      localStorage.setItem('customerChildSurnameNumberTwelve',response.customerChildSurnameNumberTwelve);
      localStorage.setItem('customerChildGenderNumberTwelve',response.customerChildGenderNumberTwelve);
      localStorage.setItem('customerChildAgeNumberTwelve',response.customerChildAgeNumberTwelve);

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerNumberOfChildren','');
      localStorage.setItem('customerChildFirstNameNumberOne','');
      localStorage.setItem('customerChildSurnameNumberOne','');
      localStorage.setItem('customerChildGenderNumberOne','');
      localStorage.setItem('customerChildAgeNumberOne','');
      localStorage.setItem('customerChildFirstNameNumberTwo','');
      localStorage.setItem('customerChildSurnameNumberTwo','');
      localStorage.setItem('customerChildGenderNumberTwo','');
      localStorage.setItem('customerChildAgeNumberTwo','');
      localStorage.setItem('customerChildFirstNameNumberThree','');
      localStorage.setItem('customerChildSurnameNumberThree','');
      localStorage.setItem('customerChildGenderNumberThree','');
      localStorage.setItem('customerChildAgeNumberThree','');
      localStorage.setItem('customerChildFirstNameNumberFour','');
      localStorage.setItem('customerChildSurnameNumberFour','');
      localStorage.setItem('customerChildGenderNumberFour','');
      localStorage.setItem('customerChildAgeNumberFour','');
      localStorage.setItem('customerChildFirstNameNumberFive','');
      localStorage.setItem('customerChildSurnameNumberFive','');
      localStorage.setItem('customerChildGenderNumberFive','');
      localStorage.setItem('customerChildAgeNumberFive','');
      localStorage.setItem('customerChildFirstNameNumberSix','');
      localStorage.setItem('customerChildSurnameNumberSix','');
      localStorage.setItem('customerChildGenderNumberSix','');
      localStorage.setItem('customerChildAgeNumberSix','');
      localStorage.setItem('customerChildFirstNameNumberSeven','');
      localStorage.setItem('customerChildSurnameNumberSeven','');
      localStorage.setItem('customerChildGenderNumberSeven','');
      localStorage.setItem('customerChildAgeNumberSeven','');
      localStorage.setItem('customerChildFirstNameNumberEight','');
      localStorage.setItem('customerChildSurnameNumberEight','');
      localStorage.setItem('customerChildGenderNumberEight','');
      localStorage.setItem('customerChildAgeNumberEight','');
      localStorage.setItem('customerChildFirstNameNumberNine','');
      localStorage.setItem('customerChildSurnameNumberNine','');
      localStorage.setItem('customerChildGenderNumberNine','');
      localStorage.setItem('customerChildAgeNumberNine','');
      localStorage.setItem('customerChildFirstNameNumberTen','');
      localStorage.setItem('customerChildSurnameNumberTen','');
      localStorage.setItem('customerChildGenderNumberTen','');
      localStorage.setItem('customerChildAgeNumberTen','');
      localStorage.setItem('customerChildFirstNameNumberEleven','');
      localStorage.setItem('customerChildSurnameNumberEleven','');
      localStorage.setItem('customerChildGenderNumberEleven','');
      localStorage.setItem('customerChildAgeNumberEleven','');
      localStorage.setItem('customerChildFirstNameNumberTwelve','');
      localStorage.setItem('customerChildSurnameNumberTwelve','');
      localStorage.setItem('customerChildGenderNumberTwelve','');
      localStorage.setItem('customerChildAgeNumberTwelve','');
  
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerTurnover','');
      // localStorage.setItem('customerStartDateOfTurnover','');
      // localStorage.setItem('customerEndDateOfTurnover','');
      // localStorage.setItem('customerFinancialAbility','');
      // localStorage.setItem('customerDateOfFinancial','');
      // localStorage.setItem('customerFinancialCurrencyUnit','');
      // localStorage.setItem('customerTotalAssetsAmountNumber','');
      // localStorage.setItem('customerCalculatedCurrency','');
    }
    })
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }


    
      values.childNumb = localStorage.getItem('customerNumberOfChildren');
      values.ChildName1 = localStorage.getItem('customerChildFirstNameNumberOne');
      values.ChildFamily1 = localStorage.getItem('customerChildSurnameNumberOne');
      values.ChildGender1 = localStorage.getItem('customerChildGenderNumberOne');
      values.ChildAge1 = localStorage.getItem('customerChildAgeNumberOne');
      values.ChildName2 = localStorage.getItem('customerChildFirstNameNumberTwo');
      values.ChildFamily2 = localStorage.getItem('customerChildSurnameNumberTwo');
      values.ChildGender2 = localStorage.getItem('customerChildGenderNumberTwo');
      values.ChildAge2 = localStorage.getItem('customerChildAgeNumberTwo');
      values.ChildName3 = localStorage.getItem('customerChildFirstNameNumberThree');
      values.ChildFamily3 = localStorage.getItem('customerChildSurnameNumberThree');
      values.ChildGender3 = localStorage.getItem('customerChildGenderNumberThree');
      values.ChildAge3 = localStorage.getItem('customerChildAgeNumberThree');
      values.ChildName4 = localStorage.getItem('customerChildFirstNameNumberFour');
      values.ChildFamily4 = localStorage.getItem('customerChildSurnameNumberFour');
      values.ChildGender4 = localStorage.getItem('customerChildGenderNumberFour');
      values.ChildAge4 = localStorage.getItem('customerChildAgeNumberFour');
      values.ChildName5 = localStorage.getItem('customerChildFirstNameNumberFive');
      values.ChildFamily5 = localStorage.getItem('customerChildSurnameNumberFive');
      values.ChildGender5 = localStorage.getItem('customerChildGenderNumberFive');
      values.ChildAge5 = localStorage.getItem('customerChildAgeNumberFive');
      values.ChildName6 = localStorage.getItem('customerChildFirstNameNumberSix');
      values.ChildFamily6 = localStorage.getItem('customerChildSurnameNumberSix');
      values.ChildGender6 = localStorage.getItem('customerChildGenderNumberSix');
      values.ChildAge6 = localStorage.getItem('customerChildAgeNumberSix');
      values.ChildName7 = localStorage.getItem('customerChildFirstNameNumberSeven');
      values.ChildFamily7 = localStorage.getItem('customerChildSurnameNumberSeven');
      values.ChildGender7 = localStorage.getItem('customerChildGenderNumberSeven');
      values.ChildAge7 = localStorage.getItem('customerChildAgeNumberSeven');
      values.ChildName8 = localStorage.getItem('customerChildFirstNameNumberEight');
      values.ChildFamily8 = localStorage.getItem('customerChildSurnameNumberEight');
      values.ChildGender8 = localStorage.getItem('customerChildGenderNumberEight');
      values.ChildAge8 = localStorage.getItem('customerChildAgeNumberEight');
      values.ChildName9 = localStorage.getItem('customerChildFirstNameNumberNine');
      values.ChildFamily9 = localStorage.getItem('customerChildSurnameNumberNine');
      values.ChildGender9 = localStorage.getItem('customerChildGenderNumberNine');
      values.ChildAge9 = localStorage.getItem('customerChildAgeNumberNine');
      values.ChildName10 = localStorage.getItem('customerChildFirstNameNumberTen');
      values.ChildFamily10 = localStorage.getItem('customerChildSurnameNumberTen');
      values.ChildGender10 = localStorage.getItem('customerChildGenderNumberTen');
      values.ChildAge10 = localStorage.getItem('customerChildAgeNumberTen');
      values.ChildName11 = localStorage.getItem('customerChildFirstNameNumberEleven');
      values.ChildFamily11 = localStorage.getItem('customerChildSurnameNumberEleven');
      values.ChildGender11 = localStorage.getItem('customerChildGenderNumberEleven');
      values.ChildAge11 = localStorage.getItem('customerChildAgeNumberEleven');
      values.ChildName12 = localStorage.getItem('customerChildFirstNameNumberTwelve');
      values.ChildFamily12 = localStorage.getItem('customerChildSurnameNumberTwelve');
      values.ChildGender12 = localStorage.getItem('customerChildGenderNumberTwelve');
      values.ChildAge12 = localStorage.getItem('customerChildAgeNumberTwelve');
        return () => clearTimeout(timer);
      }
    }, [state]);

//***************************************************


const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


          const [image13 , setImage13] = useState(null);
          const   onImage13Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader13 = new FileReader();
                reader13.onload = (e) => {
                  setImage13(e.target.result);
                  document.getElementById('target13').style.display ="block";
                  document.getElementById('MyGrayBox13').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader13.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage13 = () =>{
              document.getElementById('target13').style.display ="none";
              document.getElementById('MyGrayBox13').style.display= "flex";
            }


          const [image14 , setImage14] = useState(null);
          const   onImage14Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader14 = new FileReader();
                reader14.onload = (e) => {
                  setImage14(e.target.result);
                  document.getElementById('target14').style.display ="block";
                  document.getElementById('MyGrayBox14').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader14.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage14 = () =>{
              document.getElementById('target14').style.display ="none";
              document.getElementById('MyGrayBox14').style.display= "flex";
            }


            const [image15 , setImage15] = useState(null);
            const   onImage15Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader15 = new FileReader();
                  reader15.onload = (e) => {
                    setImage15(e.target.result);
                    document.getElementById('target15').style.display ="block";
                    document.getElementById('MyGrayBox15').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader15.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage15 = () =>{
                document.getElementById('target15').style.display ="none";
                document.getElementById('MyGrayBox15').style.display= "flex";
              }


            const [image16 , setImage16] = useState(null);
            const   onImage16Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader16 = new FileReader();
                  reader16.onload = (e) => {
                    setImage16(e.target.result);
                    document.getElementById('target16').style.display ="block";
                    document.getElementById('MyGrayBox16').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader16.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage16 = () =>{
                document.getElementById('target16').style.display ="none";
                document.getElementById('MyGrayBox16').style.display= "flex";
              }


            const [image17 , setImage17] = useState(null);
            const   onImage17Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader17 = new FileReader();
                  reader17.onload = (e) => {
                    setImage17(e.target.result);
                    document.getElementById('target17').style.display ="block";
                    document.getElementById('MyGrayBox17').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader17.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage17 = () =>{
                document.getElementById('target17').style.display ="none";
                document.getElementById('MyGrayBox17').style.display= "flex";
              }


              const [image18 , setImage18] = useState(null);
              const   onImage18Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader18 = new FileReader();
                    reader18.onload = (e) => {
                      setImage18(e.target.result);
                      document.getElementById('target18').style.display ="block";
                      document.getElementById('MyGrayBox18').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader18.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage18 = () =>{
                  document.getElementById('target18').style.display ="none";
                  document.getElementById('MyGrayBox18').style.display= "flex";
                }


              const [image19 , setImage19] = useState(null);
              const   onImage19Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader19 = new FileReader();
                    reader19.onload = (e) => {
                      setImage19(e.target.result);
                      document.getElementById('target19').style.display ="block";
                      document.getElementById('MyGrayBox19').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader19.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage19 = () =>{
                  document.getElementById('target19').style.display ="none";
                  document.getElementById('MyGrayBox19').style.display= "flex";
                }


              const [image20 , setImage20] = useState(null);
              const   onImage20Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader20 = new FileReader();
                    reader20.onload = (e) => {
                      setImage20(e.target.result);
                      document.getElementById('target20').style.display ="block";
                      document.getElementById('MyGrayBox20').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader20.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage20 = () =>{
                  document.getElementById('target20').style.display ="none";
                  document.getElementById('MyGrayBox20').style.display= "flex";
                }


            const [image21 , setImage21] = useState(null);
            const   onImage21Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader21 = new FileReader();
                  reader21.onload = (e) => {
                    setImage21(e.target.result);
                    document.getElementById('target21').style.display ="block";
                    document.getElementById('MyGrayBox21').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader21.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage21 = () =>{
                document.getElementById('target21').style.display ="none";
                document.getElementById('MyGrayBox21').style.display= "flex";
              }


            const [image22 , setImage22] = useState(null);
            const   onImage22Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader22 = new FileReader();
                  reader22.onload = (e) => {
                    setImage22(e.target.result);
                    document.getElementById('target22').style.display ="block";
                    document.getElementById('MyGrayBox22').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader22.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage22 = () =>{
                document.getElementById('target22').style.display ="none";
                document.getElementById('MyGrayBox22').style.display= "flex";
              }


            const [image23 , setImage23] = useState(null);
            const   onImage23Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader23 = new FileReader();
                  reader23.onload = (e) => {
                    setImage23(e.target.result);
                    document.getElementById('target23').style.display ="block";
                    document.getElementById('MyGrayBox23').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader23.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage23 = () =>{
                document.getElementById('target23').style.display ="none";
                document.getElementById('MyGrayBox23').style.display= "flex";
              }


          const [image24 , setImage24] = useState(null);
          const   onImage24Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader24 = new FileReader();
                reader24.onload = (e) => {
                  setImage24(e.target.result);
                  document.getElementById('target24').style.display ="block";
                  document.getElementById('MyGrayBox24').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader24.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage24 = () =>{
              document.getElementById('target24').style.display ="none";
              document.getElementById('MyGrayBox24').style.display= "flex";
            }

          const [image25 , setImage25] = useState(null);
          const   onImage25Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader25 = new FileReader();
                reader25.onload = (e) => {
                  setImage25(e.target.result);
                  document.getElementById('target25').style.display ="block";
                  document.getElementById('MyGrayBox25').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader25.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage25 = () =>{
              document.getElementById('target25').style.display ="none";
              document.getElementById('MyGrayBox25').style.display= "flex";
            }

          const [image26 , setImage26] = useState(null);
          const   onImage26Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader26 = new FileReader();
                reader26.onload = (e) => {
                  setImage26(e.target.result);
                  document.getElementById('target26').style.display ="block";
                  document.getElementById('MyGrayBox26').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader26.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage26 = () =>{
              document.getElementById('target26').style.display ="none";
              document.getElementById('MyGrayBox26').style.display= "flex";
            }

          const [image27 , setImage27] = useState(null);
          const   onImage27Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader27 = new FileReader();
                reader27.onload = (e) => {
                  setImage27(e.target.result);
                  document.getElementById('target27').style.display ="block";
                  document.getElementById('MyGrayBox27').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader27.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage27 = () =>{
              document.getElementById('target27').style.display ="none";
              document.getElementById('MyGrayBox27').style.display= "flex";
            }

          const [image28 , setImage28] = useState(null);
          const   onImage28Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader28 = new FileReader();
                reader28.onload = (e) => {
                  setImage28(e.target.result);
                  document.getElementById('target28').style.display ="block";
                  document.getElementById('MyGrayBox28').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader28.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage28 = () =>{
              document.getElementById('target28').style.display ="none";
              document.getElementById('MyGrayBox28').style.display= "flex";
            }

          const [image29 , setImage29] = useState(null);
          const   onImage29Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader29 = new FileReader();
                reader29.onload = (e) => {
                  setImage29(e.target.result);
                  document.getElementById('target29').style.display ="block";
                  document.getElementById('MyGrayBox29').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader29.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage29 = () =>{
              document.getElementById('target29').style.display ="none";
              document.getElementById('MyGrayBox29').style.display= "flex";
            }
                
        const [DateofEnteringHighSchool, setDateofEnteringHighSchool] = useState()
        const [GraduationDate, setGraduationDate] = useState()
        const [DateofEnteringUniversityFogDiplom , setDateofEnteringUniversityFogDiplom ] = useState()
        const [GraduationDateFogDiplom , setGraduationDateFogDiplom ] = useState()
        const [DateofEnteringUniversityBachelor , setDateofEnteringUniversityBachelor ] = useState()
        const [GraduationDateBachelor , setGraduationDateBachelor ] = useState()
        const [DateofEnteringUniversityMasters , setDateofEnteringUniversityMasters ] = useState()
        const [GraduationDateMasters , setGraduationDateMasters ] = useState()
        const [DateofEnteringUniversityPHD , setDateofEnteringUniversityPHD ] = useState()
        const [GraduationDatePHD , setGraduationDatePHD ] = useState()
        const [DateofEnteringUniversityPostDoctoral , setDateofEnteringUniversityPostDoctoral ] = useState()
        const [GraduationDatePostDoctoral , setGraduationDatePostDoctoral ] = useState()
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"><p className="Cus2Step1GoldP">{t('Step 5')} :</p><p className="Cus2Step1BlackP">{ t('Applicant Children Information')}</p><a className="back_pagelink" onClick={(dataCus1e1)=>{childToParentCus1e1(dataCus1e1)}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div><p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Children')}</p> </div>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="childNumb" 
                value={values.childNumb}
                type="text"
                placeholder={t('Number of Children')}
                onChange={handleChange('childNumb')}
                required
                />
                <label htmlFor="childNumb">{t('Enter the applicant’s Number of Children')} <p className="ReqiredCus">{t('(Required)')}</p> </label>
            </div>

           
          <div className="Cus2PersonalInfo col-12"> 
            <p>{t('Profile of Children')}</p>        
          </div>        

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName1" 
                value={values.ChildName1}
                type="text"
                placeholder={t('Child Name (1)')}
                onChange={handleChange('ChildName1')}
                />
                <label htmlFor="ChildName1">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily1" 
                value={values.ChildFamily1}
                type="text"
                placeholder={t('Child Family (1)')}
                onChange={handleChange('ChildFamily1')}
                />
                <label htmlFor="ChildFamily1">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender1"
                  value={values.ChildGender1}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender1')}  
                  placeholder={t('Child’s Gender (1)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender1">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge1" 
                value={values.ChildAge1}
                type="text"
                placeholder={t('Child Age (1)')}
                onChange={handleChange('ChildAge1')}
                />
                <label htmlFor="ChildAge1">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
              
            </div>
           
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName2" 
                value={values.ChildName2}
                type="text"
                placeholder={t('Child Name (2)')}
                onChange={handleChange('ChildName2')}
                />
                <label htmlFor="ChildName2">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily2" 
                value={values.ChildFamily2}
                type="text"
                placeholder={t('Child Family (2)')}
                onChange={handleChange('ChildFamily2')}
                />
                <label htmlFor="ChildFamily2">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender2"
                  value={values.ChildGender2}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender2')}  
                  placeholder={t('Child’s Gender (2)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender2">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge2" 
                value={values.ChildAge2}
                type="text"
                placeholder={t('Child Age (2)')}
                onChange={handleChange('ChildAge2')}
                />
                <label htmlFor="ChildAge2">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">         
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName3" 
                value={values.ChildName3}
                type="text"
                placeholder={t('Child Name (3)')}
                onChange={handleChange('ChildName3')}
                />
                <label htmlFor="ChildName3">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily3" 
                value={values.ChildFamily3}
                type="text"
                placeholder={t('Child Family (3)')}
                onChange={handleChange('ChildFamily3')}
                />
                <label htmlFor="ChildFamily3">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender3"
                  value={values.ChildGender3}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender3')}  
                  placeholder={t('Child’s Gender (3)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender3">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge3" 
                value={values.ChildAge3}
                type="text"
                placeholder={t('Child Age (3)')}
                onChange={handleChange('ChildAge3')}
                />
                <label htmlFor="ChildAge3">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName4" 
                value={values.ChildName4}
                type="text"
                placeholder={t('Child Name (4)')}
                onChange={handleChange('ChildName4')}
                />
                <label htmlFor="ChildName4">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily4" 
                value={values.ChildFamily4}
                type="text"
                placeholder={t('Child Family (4)')}
                onChange={handleChange('ChildFamily4')}
                />
                <label htmlFor="ChildFamily4">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender4"
                  value={values.ChildGender4}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender4')}  
                  placeholder={t('Child’s Gender (4)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender4">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge4" 
                value={values.ChildAge4}
                type="text"
                placeholder={t('Child Age (4)')}
                onChange={handleChange('ChildAge4')}
                />
                <label htmlFor="ChildAge4">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName5" 
                value={values.ChildName5}
                type="text"
                placeholder={t('Child Name (5)')}
                onChange={handleChange('ChildName5')}
                />
                <label htmlFor="ChildName5">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily5" 
                value={values.ChildFamily5}
                type="text"
                placeholder={t('Child Family (5)')}
                onChange={handleChange('ChildFamily5')}
                />
                <label htmlFor="ChildFamily5">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender5"
                  value={values.ChildGender5}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender5')}  
                  placeholder={t('Child’s Gender (5)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender5">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge5" 
                value={values.ChildAge5}
                type="text"
                placeholder={t('Child Age (5)')}
                onChange={handleChange('ChildAge5')}
                />
                <label htmlFor="ChildAge5">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
              
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName6" 
                value={values.ChildName6}
                type="text"
                placeholder={t('Child Name (6)')}
                onChange={handleChange('ChildName6')}
                />
                <label htmlFor="ChildName6">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily6" 
                value={values.ChildFamily6}
                type="text"
                placeholder={t('Child Family (6)')}
                onChange={handleChange('ChildFamily6')}
                />
                <label htmlFor="ChildFamily6">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender6"
                  value={values.ChildGender6}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender6')}  
                  placeholder={t('Child’s Gender (6)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender6">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge6" 
                value={values.ChildAge6}
                type="text"
                placeholder={t('Child Age (6)')}
                onChange={handleChange('ChildAge6')}
                />
                <label htmlFor="ChildAge6">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
              
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName7" 
                value={values.ChildName7}
                type="text"
                placeholder={t('Child Name (7)')}
                onChange={handleChange('ChildName7')}
                />
                <label htmlFor="ChildName7">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily7" 
                value={values.ChildFamily7}
                type="text"
                placeholder={t('Child Family (7)')}
                onChange={handleChange('ChildFamily7')}
                />
                <label htmlFor="ChildFamily7">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender7"
                  value={values.ChildGender7}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender7')}  
                  placeholder={t('Child’s Gender (7)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender7">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge7" 
                value={values.ChildAge7}
                type="text"
                placeholder={t('Child Age (7)')}
                onChange={handleChange('ChildAge7')}
                />
                <label htmlFor="ChildAge7">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName8" 
                value={values.ChildName8}
                type="text"
                placeholder={t('Child Name (8)')}
                onChange={handleChange('ChildName8')}
                />
                <label htmlFor="ChildName8">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily8" 
                value={values.ChildFamily8}
                type="text"
                placeholder={t('Child Family (8)')}
                onChange={handleChange('ChildFamily8')}
                />
                <label htmlFor="ChildFamily8">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender8"
                  value={values.ChildGender8}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender8')}  
                  placeholder={t('Child’s Gender (8)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender8">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge8" 
                value={values.ChildAge8}
                type="text"
                placeholder={t('Child Age (8)')}
                onChange={handleChange('ChildAge8')}
                />
                <label htmlFor="ChildAge8">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">       
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName9" 
                value={values.ChildName9}
                type="text"
                placeholder={t('Child Name (9)')}
                onChange={handleChange('ChildName9')}
                />
                <label htmlFor="ChildName9">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily9" 
                value={values.ChildFamily9}
                type="text"
                placeholder={t('Child Family (9)')}
                onChange={handleChange('ChildFamily9')}
                />
                <label htmlFor="ChildFamily9">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender9"
                  value={values.ChildGender9}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender9')}  
                  placeholder={t('Child’s Gender (9)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender9">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge9" 
                value={values.ChildAge9}
                type="text"
                placeholder={t('Child Age (9)')}
                onChange={handleChange('ChildAge9')}
                />
                <label htmlFor="ChildAge9">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName10" 
                value={values.ChildName10}
                type="text"
                placeholder={t('Child Name (10)')}
                onChange={handleChange('ChildName10')}
                />
                <label htmlFor="ChildName10">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily10" 
                value={values.ChildFamily10}
                type="text"
                placeholder={t('Child Family (10)')}
                onChange={handleChange('ChildFamily10')}
                />
                <label htmlFor="ChildFamily10">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender10"
                  value={values.ChildGender10}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender10')}  
                  placeholder={t('Child’s Gender (10)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender10">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge10" 
                value={values.ChildAge10}
                type="text"
                placeholder={t('Child Age (10)')}
                onChange={handleChange('ChildAge10')}
                />
                <label htmlFor="ChildAge10">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName11" 
                value={values.ChildName11}
                type="text"
                placeholder={t('Child Name (11)')}
                onChange={handleChange('ChildName11')}
                />
                <label htmlFor="ChildName11">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily11" 
                value={values.ChildFamily11}
                type="text"
                placeholder={t('Child Family (11)')}
                onChange={handleChange('ChildFamily11')}
                />
                <label htmlFor="ChildFamily11">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender11"
                  value={values.ChildGender11}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender11')}  
                  placeholder={t('Child’s Gender (11)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender11">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge11" 
                value={values.ChildAge11}
                type="text"
                placeholder={t('Child Age (11)')}
                onChange={handleChange('ChildAge11')}
                />
                <label htmlFor="ChildAge11">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildName12" 
                value={values.ChildName12}
                type="text"
                placeholder={t('Child Name (12)')}
                onChange={handleChange('ChildName12')}
                />
                <label htmlFor="ChildName12">{t('Enter the applicant’s Child Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildFamily12" 
                value={values.ChildFamily12}
                type="text"
                placeholder={t('Child Family (12)')}
                onChange={handleChange('ChildFamily12')}
                />
                <label htmlFor="ChildFamily12">{t('Enter the applicant’s Child Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="ChildGender12"
                  value={values.ChildGender12}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('ChildGender12')}  
                  placeholder={t('Child’s Gender (12)')}
                  >
                    <option  value="">
                    {t('Child’s Gender')}
                  </option >
                  <option  value='1'>{t('Boy')}</option >
                  <option  value='2'>{t('Girl')}</option >
                 
                </select >
                <label htmlFor="ChildGender12">{t('Enter the applicant’s Child’s Gender')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ChildAge12" 
                value={values.ChildAge12}
                type="text"
                placeholder={t('Child Age (12)')}
                onChange={handleChange('ChildAge12')}
                />
                <label htmlFor="ChildAge12">{t('Enter the applicant’s Child Age')}</label>
            </div>
            <div className="inputAndlabel col-lg-8 d-none d-lg-block">
            </div>

          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Children Documents')}</p> 
          
          </div>
          
          
  <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImageChange} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target" src={image} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Passport')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox2">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage2Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child National Card')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox3">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage3Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Birth Certificate')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox4">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage4Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Exit Permission')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox5">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage5Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Financial Ability')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox6">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage6Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Certificate of Education')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox7">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage7Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('First child Other Documents')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox8">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage8Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Passport')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox9">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage9Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child National Card')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox10">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage10Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Birth Certificate')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox11">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage11Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Exit Permission')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox12">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage12Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Financial Ability')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox13">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage13Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Certificate of Education')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox14">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage14Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('2nd Child Other Documents')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox15">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage15Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target15" src={image15} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage15} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Passport')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox16">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage16Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target16" src={image16} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage16} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child National Card')}</p> 
    </div>
    </div>



    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox17">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage17Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target17" src={image17} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage17} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Birth Certificate')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox18">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage18Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target18" src={image18} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage18} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Exit Permission')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox19">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage19Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target19" src={image19} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage19} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Financial Ability')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox20">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage20Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target20" src={image20} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage20} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Certificate of Education')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox21">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage21Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target21" src={image21} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage21} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('3rd Child Other Documents')}</p> 
    </div>
    </div>



    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox22">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage22Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target22" src={image22} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage22} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Passport')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox23">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage23Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target23" src={image23} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage23} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child National Card')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox24">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage24Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target24" src={image24} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage24} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Birth Certificate')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox25">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage25Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target25" src={image25} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage25} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Exit Permission')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox26">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage26Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target26" src={image26} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage26} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Financial Ability')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox27">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage27Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target27" src={image27} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage27} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Certificate of Education')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox28">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input 
          className="MyCusInputFileUpload" 
          type="file" 
          onChange={onImage28Change} 
          id="group_image"
          accept="image/gif, image/jpeg, image/png"
          />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target28" src={image28} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage28} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('4th Child Other Documents')}</p> 
    </div>
    </div>



   </div>


   {localStorage.getItem('userType')=='3' && <div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e1(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
            </div>}
        </div>
        </form>
        </fieldset>
       
        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e1(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
        

     </div>
      );
 }
 
export default Profile2;