import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import RegisterLogo from './images/LogoRegister.png';
import { Link } from "react-router-dom";
import {Facebook,Whatsapp,CallCalling,Instagram,Youtube,Home2,SearchNormal1} from 'iconsax-react';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='content_page'>
                <div className='header_page col-12'>
                    <div className='top_header_page col-md-11 col-12'>
                        <div className='titlehead_page col-4'>
                            <h3 className="font-face-gm">{t('Help')}</h3>
                        </div>
                        <div className='logohead_page col-md-4 col-6'>
                            <img src={RegisterLogo} alt="" />
                        </div>
                        <Link className="font-face-gm icohead_page col-md-4 col-2" to='/'>
                            <Home2 color="white" size="24" />
                        </Link>
                    </div>
                    {/* <div className='bottom_header_page col-12'>
                        <h4 className="txthead_page font-face-gm col-12">{t('Can we help you?')}</h4>
                        <div className="form-group has-success has-feedback col-md-7 col-12" id="searchbox_pages">
                            <input 
                            autoComplete='none'
                            
                            placeholder={t('Search here')}
                            type="text" 
                            className="form-control" 
                            id="input_search_box"/>
                            <SearchNormal1 size="20" color="#292D32" />
                        </div>
                    </div> */}
 
                </div>
                <div className='body_page col-12'>
                    <div className='total_services col-12'>
                        <div className='head_help col-12'>
                            <h3 className="font-face-gm">{'Guide'}</h3>
                        </div>
                        {/* <div className='box_servlog col-6'>
                            <div className='servlog'>
                                <div className='title_servlog'>
                                    <h5 className="font-face-gm">{t('What service do you want?')}</h5>
                                </div>
                                <div className='txt_servlog'>
                                    <p className="font-face-gm">{t('What service do you want? What service do you want? What service do you want?')}</p>
                                </div>
                            </div>
                        </div> */}
                        <div className='box_servlog col-12'>
                            <div className='servlog'>
                                {/* <div className='title_servlog'>
                                    <h5 className="font-face-gm">{`Guide`}</h5>
                                </div> */}
                                <div className='txt_servlog'>
                                    <p className="font-face-gm">{`We've explained all parts in the management dashboard of the same account.
Further explanations are in collaboration and rule part you can also receive more guidance in Contact Us section, from our support team
`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer_page col-12'>
                    <div className="about-us col-12">
                        <div className="spanAbout">
                        <p> 
                            <Link to="/services"> <span>{t('Services')+' '}</span></Link>
                            <span>|</span>  <Link to="/AboutusLogin"><span>{t('About')}</span></Link> <span>|</span>
                            <Link to="/CollaborationsLogin"><span> {t('Collaborations')}</span></Link> <span>|</span> 
                            <Link to="/TermsLogin"><span>{' '+t('Terms')}</span></Link> <span>|</span>
                            <Link to="/HelpLogin"><span> {t('Help')}</span></Link> <span>|</span> 
                            <Link to="/ContactUsLogin"><span>{' '+t('Contact Us')}</span></Link> 
                        </p>
                        </div>
                    </div>
                    <div className="footer-copy col-12">
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                    <div className="footer-copy2 col-12">
                    <br />
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                </div>
        </div>
    )
}
 
export default Cus1;