import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import cus1Pic from './images/cus1.png';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='mainRowCus1 col-12'>
            <div className="myreq"><p>{t('My Requests')}</p></div>
            <div className="cus1Pic myreq_img">
                <img src={cus1Pic} alt="" />
            </div>
            <div className="youdont"> <p>{t('You don’t have any request yet')}</p> </div>
            <div className="setupNew">
                <button className='btn btn-dark' onClick={(dataCus1e1) => {childToParentCus1e1(dataCus1e1);}}>{t('+ Register new Request')}</button>
            </div>

        </div>
    )
}
 
export default Cus1;