import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import RegisterLogo from './images/LogoRegister.png'
import Logo from './images/logo-tele.png';
import {InfoCircle,Home2,ArrowLeft2} from 'iconsax-react';
//*******************end of UI Materials */

import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country
//******************************** */
const ResetPassword = () => {
    //********************************fetching API */
    const [formError , setFormError] = useState(true);
    const [helppassword,setHelppassword] = useState('');
    const [helpStatusMessage,setHelpStatusMessage] = useState('');
    const [helpConfirm, setHelpConfirm] = useState();
    const [helpUniversity, setHelpUniversity] = useState();
    const [helpPhone, setHelpPhone] = useState();
    const [body, setBody] = useState();
    const [author, setAuthor] = useState('kafa');
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    const handleSubmit = (e) => {
        setIspending(true);
         e.preventDefault();
         fetch('https://telesiness.ir/api/CustomerRegister/CustomerRegister' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ReferenceCode:'',
          // LawyerCode: "",
          // AgentReferenceCode: "",
          FirstName: localStorage.getItem('FirstName').toString(),
          LastName:localStorage.getItem('LastName').toString(),
          // DateOfBirth: localStorage.getItem('myDate').toString(),
          // LastEducationalCertificate: localStorage.getItem('Degree').toString(),
          // FieldOfStudy: localStorage.getItem('FieldOfStudy').toString(),
          // UniveristyName: localStorage.getItem('University').toString(),
          // Certificates: localStorage.getItem('Certificate').toString(),
          // SpecializedProfession: localStorage.getItem('Job').toString(),
          // SpecializedSectionInTheFieldOfImmigration: localStorage.getItem('Specialization').toString(),
          EmailAddress: localStorage.getItem('Email').toString(),
          // CountryInWhichYouWork: localStorage.getItem('Country').toString(),
          // TheCityWhereYouWork: localStorage.getItem('City').toString(),
          // LaywerAddress: " ",
          PhoneNumber: localStorage.getItem('Phone').toString(),
          // WebsiteAddress: localStorage.getItem('website').toString(),
          // IccrcCode: localStorage.getItem('iccrc').toString(),
          // LawyerCVName: "",
          Descriptions: localStorage.getItem('About').toString(),
          Username: values.UserName.toString(),
          Password: values.password.toString(),
          RePassword: values.Confirm.toString(),
          ReferenceCode: values.refCode.toString(),
          LastEducationalCertificate:"IT",
          FieldOfStudy:" ",
          UniveristyName:" ",
          


        
         })
        }).then(response => response.json()).then(response=>{

          if(response.statusCode==400 || response.statusCode==404)
          {
            console.log(response.statusMessage);
            setFormError(true);
            setHelpStatusMessage(response.statusMessage);
            setIspending(false);
          }
          else{
            setHelpStatusMessage("");
            setIspending(false);
            history.push('/regstep62');
          }
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
          })
            
       
    }
    //***************************************** */

   // ui materials functions...
        const [values, setValues] = useState({
          password: '',
          Confirm: '',
          UserName: '',
          Email: '',
          refCode: localStorage.getItem('ReferalCode').split('/',1),
          myDate: '',
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if(!(document.getElementById('inputConfirm').value=="") && 
          !(document.getElementById('inputpassword').value == document.getElementById('inputConfirm').value)){
            //  document.getElementById('errorLabelDegree').style.display="flex";
             setHelppassword(t('UnEqual Password...'));
             setFormError(true);
             console.log(document.getElementById('inputpassword').value);
             console.log(document.getElementById('inputConfirm').value);
            }
            else if(!(document.getElementById('inputpassword').value=="")&&
            (document.getElementById('inputpassword').value == document.getElementById('inputConfirm').value)){
              setHelppassword('')
              setFormError(false);
            }

            if(document.activeElement.id=='inputUserName')
              {
                document.getElementById('MyUserNameplaceholder').style.color="transparent";
              }
               if(values.UserName!="")
              {
                 document.getElementById('inputUserName').style.color="#313131";
              }
            
          
            if(document.activeElement.id=='inputpassword')
            {
              document.getElementById('Mypasswordplaceholder').style.color="transparent";
            }
              if(values.password!="")
            {
                document.getElementById('inputpassword').style.color="#313131";
            }

            
            if(document.activeElement.id=='inputConfirm')
            {
              document.getElementById('MyConfirmplaceholder').style.color="transparent";
            }
              if(values.Confirm!="")
            {
                document.getElementById('inputConfirm').style.color="#313131";
            }
        };
      
    
       

// end of ui material functions
const [progress, setProgress] = useState(20);

useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 20 ? 21 : prevProgress + 20));
  }, 10);

  return () => {
    clearInterval(timer);
  };
}, []); 

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    e.preventDefault();
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    document.getElementById('main-row-2id').style.direction="rtl";
}
const  handelUS = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    // document.getElementsByClassName('main-row').style.direction="ltr";
    document.getElementById('main-row-2id').style.direction="ltr";
}

 const handleinputpasswordBlur = (e) =>{
   if(!(document.getElementById('inputpassword').value=="")){
    document.getElementById('errorLabelpassword').style.display="flex";
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
    document.getElementById('MypasswordSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelpassword').style.display="none";
    document.getElementById('Mypasswordplaceholder').style.color="gray";
    document.getElementById('MypasswordSapn').style.color="red";
   }
 }

 const handleinputConfirmBlur = (e) =>{
  if(!(document.getElementById('inputConfirm').value=="")){
   document.getElementById('errorLabelConfirm').style.display="flex";
   document.getElementById('MyConfirmplaceholder').style.color="transparent";
   document.getElementById('MyConfirmSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelConfirm').style.display="none";
   document.getElementById('MyConfirmplaceholder').style.color="gray";
   document.getElementById('MyConfirmSapn').style.color="red";
  }
}

const handleinputUserNameBlur = (e) =>{
  if(!(document.getElementById('inputUserName').value=="")){
   document.getElementById('errorLabelUserName').style.display="flex";
   document.getElementById('MyUserNameplaceholder').style.color="transparent";
   document.getElementById('MyUserNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelUserName').style.display="none";
   document.getElementById('MyUserNameplaceholder').style.color="gray";
   document.getElementById('MyUserNameSapn').style.color="red";
  }
}

const hendaleMouseOverUserName = (e) =>{
  if(document.getElementById('inputUserName').value=="")
  document.getElementById('MyUserNameplaceholder').style.color="#313131";
  document.getElementById('inputUserName').style.borderColor="#313131";
}

const hendaleMouseLeaveUserName = (e) =>{
  if(document.getElementById('inputUserName').value=="")
 document.getElementById('MyUserNameplaceholder').style.color="white";
 document.getElementById('inputUserName').style.borderColor="#31313131";
}

const hendaleMouseOverpassword = (e) =>{
  if(document.getElementById('inputpassword').value=="")
  document.getElementById('Mypasswordplaceholder').style.color="#313131";
  document.getElementById('inputpassword').style.borderColor="#313131";
}

const hendaleMouseLeavepassword = (e) =>{
  if(document.getElementById('inputpassword').value=="")
 document.getElementById('Mypasswordplaceholder').style.color="white";
 document.getElementById('inputpassword').style.borderColor="#31313131";
}

const hendaleMouseOverConfirm = (e) =>{
  if(document.getElementById('inputConfirm').value=="")
  document.getElementById('MyConfirmplaceholder').style.color="#313131";
  document.getElementById('inputConfirm').style.borderColor="#313131";
}

const hendaleMouseLeaveConfirm = (e) =>{
  if(document.getElementById('inputConfirm').value=="")
 document.getElementById('MyConfirmplaceholder').style.color="white";
 document.getElementById('inputConfirm').style.borderColor="#31313131";
}
// console.log( 
//  localStorage.getItem('Country').toString(),
//  localStorage.getItem('City').toString(),
//  localStorage.getItem('Job').toString(),
//  localStorage.getItem('iccrc').toString(),
//  localStorage.getItem('Certificate').toString(),
//  localStorage.getItem('Specialization').toString(),
//  localStorage.getItem('website').toString(),
//   localStorage.getItem('Upload').toString(),
//  localStorage.getItem('FieldOfStudy').toString(),
//  localStorage.getItem('University').toString(),
//  localStorage.getItem('Degree').toString(),
//   localStorage.getItem('FirstName').toString(),
//  localStorage.getItem('LastName').toString(),
//  localStorage.getItem('Phone').toString(),
//   localStorage.getItem('Email').toString(),
//  localStorage.getItem('About').toString(),
//  localStorage.getItem('myDate').toString(),
//   localStorage.getItem('userType').toString(),
//   values.password , values.Confirm,
// );
//***************************************/
    return (
       
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
          <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
              <div className="mainRegisterheader col-xxl-12 col-12">
              <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')+ ' : ' + t('As Customer')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting appropriate user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
              </div>
              <div className="progressRow col-xxl-10 col-12">
                <div className="myshapes d-none d-sm-flex">
                  <div className="mycircle">
                    <h6>{t('3 of 3')}</h6>
                  </div>
                  <div className="myProCirlce5"></div>
                </div>
                <div className="mytext">
                  <h5 className="step1">{t('Step 3')}</h5>
                  <h6 className="choosText">{t('Choose Your Password')}</h6>
                  {/* {document.getElementsByTagName('html')[0].getAttribute("dir")== "ltr" && <label className="d-flex d-sm-none" id="errorLabelRegs"><InfoCircle  size="18"  color="#ff0000" /> {t('Please enter Your Information in Persian only.')} </label>} */}
                </div>
              </div>
              <div className="myForm col-xxl-9 col-xl-10 col-lg-11 col-12">
                <form onSubmit={handleSubmit} className="col-12">
                  <div className="progressStyle lawuserpass_txt col-xxl-10 col-12">
                    <label className="newHelpPAssLabel" htmlFor="inputUserName"><InfoCircle size="24" color="#FF8A65" />{t('User Name Start with Alphabet and its Minimum length is 5')}</label>
                  </div>
                  <div className="input-placeholder col-md-6 col-12">
                    <input
                      value={values.UserName}
                      type="text"
                      className="form-control"
                      id="inputUserName"
                      onBlur={handleinputUserNameBlur}
                      onChange={handleChange('UserName')}
                      onMouseOver={hendaleMouseOverUserName}
                      onMouseLeave={hendaleMouseLeaveUserName}  
                      required 
                      minlength="5"
                      pattern="[a-zA-Z]+[a-zA-Z0-9]+"
                      tabIndex={1}
                      style={{paddingInlineStart:20}}
                      />
                    <div id="MyUserNameplaceholder" className="placeholder">
                      {t('Username')}<span id="MyUserNameSapn"></span>
                    </div>
                    <label id="errorLabelUserName">{helppassword}</label>
                  </div>
                  <div className="input-placeholder col-md-6 col-12">
                    <input
                      value={values.password}
                      type="password"
                      className="form-control"
                      id="inputpassword"
                      onBlur={handleinputpasswordBlur}
                      onChange={handleChange('password')}
                      onMouseOver={hendaleMouseOverpassword}
                      onMouseLeave={hendaleMouseLeavepassword} 
                      required 
                      tabIndex={2}
                      style={{paddingInlineStart:20}}
                      />
                    <div id="Mypasswordplaceholder" className="placeholder">
                      {t('password')}<span id="MypasswordSapn"></span>
                    </div>
                    <label id="errorLabelpassword"></label>
                  </div>
                  {values.refCode!='' &&<div className="input-placeholder col-md-6 col-12">
                    <input
                      value={values.refCode}
                      type="text"
                      className="form-control"
                      id="inputrefCode"
                      // onBlur={handleinputConfirmBlur}
                      onChange={handleChange('refCode')}
                      // onMouseOver={hendaleMouseOverConfirm}
                      // onMouseLeave={hendaleMouseLeaveConfirm} 
                      required 
                      disabled
                      // placeholder="Your Refer Code"
                      tabIndex={4}
                      style={{paddingInlineStart:20}}
                      />
                    {/* <div id="MyConfirmplaceholder" className="placeholder">
                      {t('Confirm password')}<span id="MyConfirmSapn"></span>
                    </div>
                    <label id="errorLabelConfirm">{helppassword}</label> */}
                  </div>}
                  {values.refCode=='' &&<div className="input-placeholder col-md-6 col-12">
                    <input
                      value={values.refCode}
                      type="text"
                      className="form-control"
                      id="inputrefCode"
                      // onBlur={handleinputConfirmBlur}
                      onChange={handleChange('refCode')}
                      // onMouseOver={hendaleMouseOverConfirm}
                      // onMouseLeave={hendaleMouseLeaveConfirm} 
                      required 
                      disabled
                      placeholder={t('Referral Code')}
                      tabIndex={4}
                      style={{paddingInlineStart:20}}
                      />
                    {/* <div id="MyConfirmplaceholder" className="placeholder">
                      {t('Confirm password')}<span id="MyConfirmSapn"></span>
                    </div>
                    <label id="errorLabelConfirm">{helppassword}</label> */}
                  </div>}
                  <div className="input-placeholder col-md-6 col-12">
                    <input
                      value={values.Confirm}
                      type="password"
                      className="form-control"
                      id="inputConfirm"
                      onBlur={handleinputConfirmBlur}
                      onChange={handleChange('Confirm')}
                      onMouseOver={hendaleMouseOverConfirm}
                      onMouseLeave={hendaleMouseLeaveConfirm} 
                      required 
                      tabIndex={3}
                      style={{paddingInlineStart:20}}
                      />
                    <div id="MyConfirmplaceholder" className="placeholder">
                      {t('Confirm password')}<span id="MyConfirmSapn"></span>
                    </div>
                    <label id="errorLabelConfirm">{helppassword}</label>
                  </div>
                  <label id="errorLabelStatus">{helpStatusMessage}</label>
                  <div className="NextStep col-12">
                    <Link to="/regcstep2" className="btn btn-outline-light signup-btn d-none d-sm-flex">{t('Back')}</Link>
                    {!ispending && !formError && <button className="btn btn-dark login-btn">{t('Submit')}</button>}
                    {formError && <button className="btn btn-dark login-btn" disabled>{t('Submit')}</button>}
                    {ispending && <button className="btn btn-dark login-btn " disabled>{t('logging in...')}</button>}
                  </div>
                </form>
              </div>
            </div>
          <div className="footerDasilav col-12">
              <div className="footer-copy">
                Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
              <div className="footer-copy2">
                <br />
                Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
          </div>
        </div>
      </>
    );
}
 
export default ResetPassword;