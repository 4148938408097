import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import {Edit2,Trash,TickCircle,More,InfoCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import CreatableSelect from 'react-select/creatable';
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';

const techCompanies = [
  {},
  { label: "Apple", value: 1 },
  { label: "Facebook", value: 2 },
  { label: "Netflix", value: 3 },
  { label: "Tesla", value: 4 },
  { label: "Amazon", value: 5 },
  { label: "Alphabet", value: 6 },
  { label: "other", value: 7 },
];
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}




const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
  })
}



const Profile = () => {
    const userActive =true;
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const forceUpdate = useForceUpdate();

    const [ServicesPrice , setServicesPrice] = useState([
  {   pen:"true",
      tick:"false",
    distributorCode:'',
    DCName: '',
    distributorServiceCode:'',
    SName:'', 
    SPrice:'',
    distributorCommission:'',
    marketerCommission:'',
    SFee:'',
    aboutService:'', 
    submitDate:'', 
    },
     
    ]);

    const history = useHistory();

    const [isOpen72, setIsOpen72] = useState(true);

    const togglePopup72 = () => {
      setIsOpen72(!isOpen72);
      }


    if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
      techCompanies.pop();
      techCompanies.push({label: "دیگر | وارد کنید ...", value: undefined},);
    }
    else{
      techCompanies.pop();
      techCompanies.push({label: "Other | type here ...", value: undefined},);
    }
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('http://localhost:8000/blogs' , {
        //     method: 'POST',
        //     headers: {"Content-type": "application/json"},
        //     body:JSON.stringify(blog)
        // }).then(()=>{
        //     console.log('new blog added');
        //     setIspending(false);


        //setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }
 
//*********************my values******************************* */
        const [values, setValues] = useState({

          Service: '',
          ServiceNameBox:'',
          Fee: '',
          AboutService: '',
          AboutService2: '',
          proPhone:'3352258774',
          proLastName:'',
          VisaProcess:'',
          RequiredDocuments:'',
          DCCommission:'',
          Country:'',
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          // if(!(document.getElementById('Service').value=="")){
          //   //  document.getElementById('errorLabelDegree').style.display="flex";
          //    document.getElementById('MyServiceplaceholder').style.color="transparent";
          //    document.getElementById('MyServiceSapn').style.color="transparent";
          //   }
          //   else{
          //   //  document.getElementById('errorLabelDegree').style.display="none";
          //    document.getElementById('MyServiceplaceholder').style.color="gray";
          //    document.getElementById('MyServiceSapn').style.color="red";
          //   }
          if(values.Service == 'other')
            {
              document.getElementById('ServiceNameBox').style.display="flex";
              values.Service = values.ServiceNameBox;
            }
        
        };
      

       
//**********************multi language functios **********************/
const {t, i18n} = useTranslation(['Login']);
const [myCalaedar , setMyCalendar]= useState(false);



 const handleinputproLastNameBlur = (e) =>{
  if(!(document.getElementById('proLastNamePrice').value=="")){
   document.getElementById('errorLabelproLastName').style.display="flex";
   document.getElementById('MyproLastNameplaceholder').style.color="transparent";
   document.getElementById('MyproLastNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproLastName').style.display="none";
   document.getElementById('MyproLastNameplaceholder').style.color="gray";
   document.getElementById('MyproLastNameSapn').style.color="red";
  }
}

const handleinputServiceBlur = (e) =>{
  if(!(document.getElementById('Service').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyServiceplaceholder').style.color="transparent";
   document.getElementById('MyServiceSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyServiceplaceholder').style.color="gray";
   document.getElementById('MyServiceSapn').style.color="red";
  }
}

const handleinputFeeBlur = (e) =>{
  if(!(document.getElementById('Fee').value=="")){
   document.getElementById('errorLabelFee').style.display="flex";
   document.getElementById('MyFeeplaceholder').style.color="transparent";
   document.getElementById('MyFeeSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelFee').style.display="none";
   document.getElementById('MyFeeplaceholder').style.color="gray";
   document.getElementById('MyFeeSapn').style.color="red";
  }
}

const handleinputAboutServiceBlur = (e) =>{
  if(!(document.getElementById('AboutService').value=="")){
   document.getElementById('errorLabelAboutService').style.display="flex";
   document.getElementById('MyAboutServiceplaceholder').style.color="transparent";
   document.getElementById('MyAboutServiceSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelAboutService').style.display="none";
   document.getElementById('MyAboutServiceplaceholder').style.color="gray";
   document.getElementById('MyAboutServiceSapn').style.color="red";
  }
}
const [hidePen , setHidePen]=useState(false);
const [hideTick , setHideTick]=useState(true);

//***********************edit pen************************* */
const [selectValue , setSelectValue] = useState({ label: "Select a Service", value: 999 });
useEffect(() => {
  if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
    setSelectValue({ label: "یک گزینه انتخاب کنید", value: 999 });
    console.log(selectValue);
  }
  else{
    setSelectValue({ label: "Select a Service", value: 999 });
    console.log(selectValue);
  }
},[document.getElementsByTagName('html')[0].getAttribute("dir")]);


var selectCounter = 0;
// if (selectValue.label == "other") {
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   setSelectValue({label: "Other| type here...", value: 9999});
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   // console.log(selectValue);
// } else if ((selectValue.value == "Select a Service")){
//   try { document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
// } else if (!(selectValue.label == "other" || selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
  
// }else if (( selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   }
//   catch{}
//  // setSelectCounter(1);
// }
// if((techCompanies.filter(price => price.label == selectValue.label).length==0) && (selectValue.label != "Select a Service"))
// {
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1}
  
//   catch{}
// }

  

  const handlePenClick = (itemKey,a) =>{
    values.AboutService2 = a;
    console.log(itemKey,a);
     for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {

        ServicesPrice[i].pen='false';
        ServicesPrice[i].tick='true';
        //setValues({ ...values, ['Service']: ServicesPrice[i].SName});
        // setValues({ ...values, ['proLastName']: ServicesPrice[i].SPrice , ['Fee']: ServicesPrice[i].SFee , ['Service']: ServicesPrice[i].SName});
        //setValues({ ...values, ['Fee']: ServicesPrice[i].SFee});
        forceUpdate() ;
        setServicesPrice(ServicesPrice);
       
      }
  }
  }
  
  const handleTickClick = (itemKey,a) =>{
    // e.preventDefault();
    fetch('https://telesiness.ir/api/Dashboards/EditDistributorService/1' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({

     Email:localStorage.getItem('email').toString(),

     DistributorServiceCode:a ,

     AboutService:values.AboutService2.toString(),

     
    })
   }).then(response => response.json()).then(response=>{
    
     if(response.statusCode==400)
     {
       console.log(response.statusMessage);
      
     }
     else{
      setValues({ ...values, ['AboutService2']: response.aboutService});
     }

     })
     for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {

        ServicesPrice[i].pen='true';
        ServicesPrice[i].tick='false';
        // setValues({ ...values, ['Service']: ServicesPrice[i].SName});
        // setValues({ ...values, ['proLastName']: ServicesPrice[i].SPrice ,
        //                        ['Fee']: ServicesPrice[i].SFee , 
        //                        ['Service']: ServicesPrice[i].SName,
        //                        ['AboutService']: ServicesPrice[i].SName,
        //                       });
        
        forceUpdate() ;
        //  setServicesPrice(ServicesPrice);
       
      }
  }
  // var j;
  // for(j=0;j<ServicesPrice.length;i++)
  // {
  //   ServicesPrice.pop();
  // }
      
  //     fetch('https://telesiness.ir/api/Dashboards/GetDistributorServiceList' , {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         Email:localStorage.getItem('email')})
  //       }).then(response => response.json()).then(response=>{
  //         try{
  //         var i;
        
  //     for(i in response.distributorService){
  //     ServicesPrice.push({ 
    
  //       pen: "true",
  //       tick: "false", 
  //       SName: response.distributorService[i].distributorServiceName,
  //       Country: response.distributorService[i].distributorServiceCountry,
  //       SPrice: response.distributorService[i].servicePrice, 
  //       SFee: response.distributorService[i].distributorCommission,
  //       Sabout:response.distributorService[i].aboutService,
  //       MarketerCommission: response.distributorService[i].marketerCommission,
  //       serviceCode:response.distributorService[i].distributorServiceCode,
  //       SOther: "false", 
  //       SOtherDesc: "", 
  //       key: (parseInt(i)+1).toString(),},);
  //       console.log(response.distributorService[i]);
  //     } }catch{}
  //   })
    
  }
  
  const handleTrashClick = (itemKey) =>{
    console.log(itemKey);
    setServicesPrice(ServicesPrice.filter(ServicesPrice => ServicesPrice.key != itemKey.toString()));
        forceUpdate() ;
        //setServicesPrice(ServicesPrice);

    }
  

  const handleAddService = (e) =>{
    e.preventDefault();
    fetch('https://telesiness.ir/api/Dashboards/SubmitDistributorService' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({

     // LawyerCode: "",
     // AgentReferenceCode: "",
     // FullName: localStorage.getItem('FirstName').toString() +" " +localStorage.getItem('LastName').toString(),
     // DateOfBirth: '1994-10-10',
     // LastEducationalCertificate: '',
     // FieldOfStudy: '',
     // UniveristyName: '',
     // Certificates: '',

     // Email: localStorage.getItem('Email').toString(),
     // CountryInWhichYouWork: 'Iran',
     // TheCityWhereYouWork: 'Tehran',
     // AgentAddress: " ",
     // PhoneNumber: localStorage.getItem('Phone').toString(),
     // WebsiteAddress: '',
     // NationalCode: localStorage.getItem('IDNumber').toString(),
     // // LawyerCVName: "",
     // AboutMe: '',
     // NationalCode: '',
     // Username: values.UserName.toString(),
     // Password: values.password.toString(),
     // RePassword: values.Confirm.toString()


     Email:localStorage.getItem('email').toString(),
     DistributorCode:localStorage.getItem('AgentCode').toString(),
     DistributorServiceName:values.Service.toString() ,
     DistributorServiceCountry:values.Country,
     ServicePrice:values.proLastNamePrice,
     DistributorCommission :values.DCCommission ,
     AboutService:values.AboutService.toString(),
     MarketerCommission:values.Fee.toString(),
     
     
   
    })
   }).then(response => response.json()).then(response=>{
     if(response.statusCode==400)
     {
       console.log(response.statusMessage);
      
     }
     else{
     
      values.Service='';
      values.Country='';
      values.proLastNamePrice='';
      values.DCCommission='' ;
      values.AboutService='';
      values.Fee='';
      forceUpdate();
     }

       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
 
       // }
     })
   if (values.Service!='Choose Your Service')   
   if(values.Service!='other')
      ServicesPrice.push({ 
         pen: "true",
         tick: "false", 
         SName: values.Service,
         SPrice: values.proLastNamePrice, 
         SFee: values.DCCommission,
         SFeeM: values.Fee,
         SOtherDesc: "", 
         key: (ServicesPrice.length+1),},);
         else 
         ServicesPrice.push({ 
          pen: "true",
          tick: "false", 
          SName: values.ServiceNameBox,
          SPrice: values.proLastNamePrice, 
          SFee: values.DCCommission,
          SFeeM: values.Fee,
          SOtherDesc: "", 
          key: (ServicesPrice.length+1),},);

         forceUpdate();
         setServicesPrice(ServicesPrice);
         console.log(values.Service,values.ServiceNameBox);
  }

  const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="flex";
}
const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="none";
}



const [data2, setData2] = useState([]);

const fetchData2 = () => {
  fetch('https://telesiness.ir/api/Dashboards/MarketerMyService' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.getItem('email'),})
    }).then(response => response.json()).then(response=>{
         var i =0;
         togglePopup72();
         try
         {
             
            if(ServicesPrice.length<2)
            {
                ServicesPrice.pop();
                var myActDec = ''
            for (i in response.marketerService){
               
                ServicesPrice.push( {
                  pen:"true",
                  tick:"false",
                distributorCode:response.marketerService[i].distributorCode,
                DCName: response.marketerService[i].firstName + " " + response.marketerService[i].lastName,
                distributorServiceCode:response.marketerService[i].distributorServiceCode,
                SName:response.marketerService[i].distributorServiceName, 
                SPrice:response.marketerService[i].servicePrice,
                distributorCommission:response.marketerService[i].distributorCommission,
                marketerCommission:response.marketerService[i].marketerCommission,
                SFee:response.marketerService[i].serviceOffer,
                aboutService:response.marketerService[i].aboutService, 
                submitDate:response.marketerService[i].submitDate, 
                currency:response.marketerService[i].currency,
                key:(parseInt(i)+1).toString()},)
                             }
             }
         }

        catch{

        }
        // console.log(response[0]);
        
    });
    
 
};

useEffect(() => {
  fetchData2();
 
  // console.log(data[0].countery_Name);
}, []);

const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
  if (counter.current < 1) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
    // console.log(ServicesPrice);
    forceUpdate();
    setServicesPrice(ServicesPrice);
    return () => clearTimeout(timer);
  }
}, [state]);


const [isOpenRow, setisOpenRow] = useState(true);

const handleAgentRowClick = (itemKey) =>{
    if(isOpenRow){
        document.getElementById(itemKey).style.display="flex";
        setisOpenRow(!isOpenRow);
    }
    else{
        document.getElementById(itemKey).style.display="none";
        setisOpenRow(!isOpenRow);
    }
}
//-------------------------------

     return ( 
         
      <div className="mainRowProPrice col-12">
       
        <div className="personalInfo col-12">
          <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Prices Information')}</strong>
                <div className="priceInfoRowOne col-12 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Prices Information')}</p>
                    </div>
                </div>
          </div>
        </div>
        <div className="top_headmyserv col-12">
          <div className="col-1"><p>{t('ID')}</p> </div>
          <div className="col-4"><p>{t('Service Name')} </p> </div>
          <div className="col-4"><p>{t('Service Price')}</p></div>
          <div className="col-2">
              <p>{t('Agent Commission')} </p>
          </div>
        </div>
        <div className="mainRowProPrice2 list_myserv" id="mainRowProPrice2">
          {ServicesPrice.map(item =>(
              <div className="item_listserv col-12" key={item.key}>
                <div className="agentCusRow col-12">
                    <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                      {<div className="col-1 id_tariff"><p>{item.key}</p> </div>}
                      {item.pen == "true" &&<div className="price_tariff col-lg-4 col-10"><p>{item.SName}</p>
                      {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                              <a><InfoCircle size="18" color="#313131"/></a>
                          </div>} 
                          <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                      </div>}
                      {item.pen == "true" &&<div className="col-lg-4 col-6 hide_servlg"><p>{item.currency + item.SPrice}</p></div>}
                      
                      {item.pen == "true" && <div className="col-lg-2 col-6 hide_servlg"><p>{"%" +item.marketerCommission}</p></div>}
                      {/* {item.tick == "true" &&<div className="col-lg-4 col-12"><input placeholder="About Service" className="col-12" type="text" value={values.AboutService2}/></div>} */}
                      {/* {item.tick == "true" &&<div className="col-lg-3 col-12 "><input placeholder="Required Documents" className="col-12" onChange={handleChange('RequiredDocuments')} type="text" value={ item.SReq}/></div>}
                      {item.tick == "true" && <div className="col-lg-3 col-11"><input placeholder="Visa Process" className="col-12" onChange={handleChange('VisaProcess')} type="text" value={ item.SProcess}/></div>}    */}
                      
                      {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                          <a >
                              <InfoCircle size="18" variant="Bold" color="#E95060"/>
                          </a>
                      </div>} 
                      <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                    </a>
                  </div>
                  {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                            <hr className="linebetitem col-12"/>
                            <div className="d-lg-none col-12 hidenItem"><span>{t('Price')}:</span><p>{"$" +item.SPrice}</p></div>
                            <div className="d-lg-none col-12 hidenItem"><span>{t('Agent Commission')}:</span><p>{"%" +item.marketerCommission}</p></div>
                            <div className="col-lg-6 col-12 hidenItem"><span>{t('Submit Date')}:</span><p>{item.submitDate.split('T',1)}</p></div>
                            <div className="col-lg-6 col-12 hidenItem"><span>{t('Distributor Service Code')}:</span><p>{item.distributorServiceCode}</p></div>
                            <div className="col-12 hidenItem"><span>{t('About Service')}:</span><p>{item.aboutService}</p></div>
                            {/* <div className="col-lg-6 col-12 hidenItem"><span>{t('Distributor Code')}:</span><p>{item.distributorCode}</p></div> */}
                        </div>
                    </div>}
              </div>
                        ))} </div>
                              {isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
     </div>
     
      );
 }
 
export default Profile;