import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import {ArrowDown2,Edit2,TickCircle,InfoCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import Popup from './Popup';
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';


const techCompanies = [
  {},
  { label: "Apple", value: 1 },
  { label: "Facebook", value: 2 },
  { label: "Netflix", value: 3 },
  { label: "Tesla", value: 4 },
  { label: "Amazon", value: 5 },
  { label: "Alphabet", value: 6 },
  { label: "other", value: 7 },
];
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}




const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
  })
}



const Profile = () => {
    const userActive =true;
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const forceUpdate = useForceUpdate();

    const [ServicesPrice , setServicesPrice] = useState([
      {pen:"true",tick:"false",
      SName:'', 
      SPrice:'',
      SFee:'',
      SOther:'',
      SOtherDesc:' ', 
      key:'',
      userId:'',
      lawyerTariffsCode:'',
      Sabout:'',
      mUnit:'',
    },
     
    ]);

    const history = useHistory();


    if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
      techCompanies.pop();
      techCompanies.push({label: "دیگر | وارد کنید ...", value: undefined},);
    }
    else{
      techCompanies.pop();
      techCompanies.push({label: "Other | type here ...", value: undefined},);
    }
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('http://localhost:8000/blogs' , {
        //     method: 'POST',
        //     headers: {"Content-type": "application/json"},
        //     body:JSON.stringify(blog)
        // }).then(()=>{
        //     console.log('new blog added');
        //     setIspending(false);


        //setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }
 
//*********************my values******************************* */
        const [values, setValues] = useState({

          Service: '',
          ServiceNameBox:'',
          Fee: '',
          AboutService: '',
          proPhone:'3352258774',
          proLastName:'',
          VisaProcess:'',
          RequiredDocuments:'',


          AboutService2: '',
          VisaProcess2:'',
          RequiredDocuments2:'',
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          // if(!(document.getElementById('Service').value=="")){
          //   //  document.getElementById('errorLabelDegree').style.display="flex";
          //    document.getElementById('MyServiceplaceholder').style.color="transparent";
          //    document.getElementById('MyServiceSapn').style.color="transparent";
          //   }
          //   else{
          //   //  document.getElementById('errorLabelDegree').style.display="none";
          //    document.getElementById('MyServiceplaceholder').style.color="gray";
          //    document.getElementById('MyServiceSapn').style.color="red";
          //   }
         
        
        };
      

       
//**********************multi language functios **********************/
const {t, i18n} = useTranslation(['Login']);
const [myCalaedar , setMyCalendar]= useState(false);



 const handleinputproLastNameBlur = (e) =>{
  if(!(document.getElementById('proLastNamePrice').value=="")){
   document.getElementById('errorLabelproLastName').style.display="flex";
   document.getElementById('MyproLastNameplaceholder').style.color="transparent";
   document.getElementById('MyproLastNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproLastName').style.display="none";
   document.getElementById('MyproLastNameplaceholder').style.color="gray";
   document.getElementById('MyproLastNameSapn').style.color="red";
  }
}

const handleinputServiceBlur = (e) =>{
  if(!(document.getElementById('Service').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyServiceplaceholder').style.color="transparent";
   document.getElementById('MyServiceSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyServiceplaceholder').style.color="gray";
   document.getElementById('MyServiceSapn').style.color="red";
  }
}

const handleinputFeeBlur = (e) =>{
  if(!(document.getElementById('Fee').value=="")){
   document.getElementById('errorLabelFee').style.display="flex";
   document.getElementById('MyFeeplaceholder').style.color="transparent";
   document.getElementById('MyFeeSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelFee').style.display="none";
   document.getElementById('MyFeeplaceholder').style.color="gray";
   document.getElementById('MyFeeSapn').style.color="red";
  }
}

const handleinputAboutServiceBlur = (e) =>{
  if(!(document.getElementById('AboutService').value=="")){
   document.getElementById('errorLabelAboutService').style.display="flex";
   document.getElementById('MyAboutServiceplaceholder').style.color="transparent";
   document.getElementById('MyAboutServiceSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelAboutService').style.display="none";
   document.getElementById('MyAboutServiceplaceholder').style.color="gray";
   document.getElementById('MyAboutServiceSapn').style.color="red";
  }
}
const [hidePen , setHidePen]=useState(false);
const [hideTick , setHideTick]=useState(true);

//***********************edit pen************************* */
const [selectValue , setSelectValue] = useState({ label: "Select a Service", value: 999 });
useEffect(() => {
  if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
    setSelectValue({ label: "یک گزینه انتخاب کنید", value: 999 });
    console.log(selectValue);
  }
  else{
    setSelectValue({ label: "Select a Service", value: 999 });
    console.log(selectValue);
  }
},[document.getElementsByTagName('html')[0].getAttribute("dir")]);


var selectCounter = 0;
// if (selectValue.label == "other") {
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   setSelectValue({label: "Other| type here...", value: 9999});
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   // console.log(selectValue);
// } else if ((selectValue.value == "Select a Service")){
//   try { document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
// } else if (!(selectValue.label == "other" || selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
  
// }else if (( selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   }
//   catch{}
//  // setSelectCounter(1);
// }
// if((techCompanies.filter(price => price.label == selectValue.label).length==0) && (selectValue.label != "Select a Service"))
// {
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1}
  
//   catch{}
// }

  

  const handlePenClick = (itemKey) =>{
     for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {

        ServicesPrice[i].pen='false';
        ServicesPrice[i].tick='true';
        //setValues({ ...values, ['Service']: ServicesPrice[i].SName});
        // setValues({ ...values, ['proLastName']: ServicesPrice[i].SPrice , ['Fee']: ServicesPrice[i].SFee , ['Service']: ServicesPrice[i].SName});
        //setValues({ ...values, ['Fee']: ServicesPrice[i].SFee});
        forceUpdate() ;
        setServicesPrice(ServicesPrice);
       
      }
  }
  }
  
  const handleTickClick = (itemKey,UserId,LawyerTariffsCode) =>{
    console.log(itemKey,UserId,LawyerTariffsCode);
    fetch('https://telesiness.ir/api/Dashboards/EditLawyerTariffs/1002' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   
        Email:localStorage.getItem('email').toString(),
        UserId: UserId,
        LawyerTariffsCode:LawyerTariffsCode,
        aboutService:values.AboutService2,
        requiredDocuments:values.RequiredDocuments2,
        visaProcess:values.VisaProcess2,
   
        
       })
      }).then(response => response.json()).then(response=>{
        if(response.statusCode==400)
        {
          console.log(response.statusMessage);
         
        }
        else{
        //  setValues({ ...values, ['AboutService']: response.aboutService});
         forceUpdate();
        }
   
        })



    for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {
        ServicesPrice[i].pen='true';
        ServicesPrice[i].tick='false';
        // ServicesPrice[i].SName = values.Service;
        ServicesPrice[i].SPrice = values.proLastName;
        ServicesPrice[i].SFee = values.Fee;
        ServicesPrice[i].Sabout = values.AboutService2;
        forceUpdate() ;
        setServicesPrice(ServicesPrice);
      }
  }
  }
  
  const handleTrashClick = (itemKey) =>{
    console.log(itemKey);
    setServicesPrice(ServicesPrice.filter(ServicesPrice => ServicesPrice.key != itemKey.toString()));
        forceUpdate() ;
        //setServicesPrice(ServicesPrice);

    }
    const handleAddService = (e) =>{
      e.preventDefault();
      togglePopup2();
    }

  const handleAddService2 = () =>{

    if(values.Service!= '1000'){
    
    fetch('https://telesiness.ir/api/Dashboards/InsertLawyerPrice' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({




     Email:localStorage.getItem('email').toString(),
     LawyerCode:localStorage.getItem('lawyerCode').toString(),
     NameOfVisa:values.Service.toString() ,
     WageFigure:values.proLastNamePrice,
     Currency:document.getElementById('mUnit').value,
     PercentageOfCommission :values.Fee ,
     aboutService:values.AboutService.toString(),
     RequiredDocuments:values.RequiredDocuments.toString(),
     VisaProcess:values.VisaProcess.toString(),
     
   
    })
   }).then(response => response.json()).then(response=>{
     if(response.statusCode==400)
     {
       console.log(response.statusMessage);
      
     }
     else{
     
     values.Service = '';
     values.ServiceNameBox='';
     values.proLastNamePrice = '';
     values.Fee = '';
     values.AboutService = '';
     values.RequiredDocuments = '';
     values.VisaProcess = '';
     forceUpdate();
     }

       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
 
       // }
     })
   
      ServicesPrice.push({ 
         pen: "true",
         tick: "false", 
         SName: values.Service,
         SPrice: values.proLastNamePrice, 
         SFee: values.Fee,
         SOther: "false", 
         SOtherDesc: "",
         mUnit:document.getElementById('mUnit').value, 
         key: (6).toString(),},);
         forceUpdate();
         setServicesPrice(ServicesPrice);

   
    
  }
  else{
    
    fetch('https://telesiness.ir/api/Dashboards/InsertLawyerPrice' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   
   
   
   
        Email:localStorage.getItem('email').toString(),
        LawyerCode:localStorage.getItem('lawyerCode').toString(),
        NameOfVisa:values.ServiceNameBox.toString() ,
        WageFigure:values.proLastNamePrice,
        Currency:document.getElementById('mUnit').value,
        PercentageOfCommission :values.Fee ,
        aboutService:values.AboutService.toString(),
        RequiredDocuments:values.RequiredDocuments.toString(),
        VisaProcess:values.VisaProcess.toString(),
        
      
       })
      }).then(response => response.json()).then(response=>{
        if(response.statusCode==400)
        {
          console.log(response.statusMessage);
         
        }
        else{
          values.Service = '';
          values.ServiceNameBox='';
          values.proLastNamePrice = '';
          values.Fee = '';
          values.AboutService = '';
          values.RequiredDocuments = '';
          values.VisaProcess = '';
          forceUpdate();
        }
   
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
        })
      
         ServicesPrice.push({ 
            pen: "true",
            tick: "false", 
            SName: values.ServiceNameBox,
            SPrice: values.proLastNamePrice, 
            SFee: values.Fee,
            SOther: "false", 
            SOtherDesc: "", 
            mUnit:document.getElementById('mUnit').value,
            key: (ServicesPrice.length+1).toString(),},);
            forceUpdate();
            setServicesPrice(ServicesPrice);
   
      
       
     
  }
  togglePopup2();
}
  console.log(values.AboutService);

  const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="flex";
}
const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="none";
}

const [data, setData] = useState([]);

const fetchData = () => {
  fetch("https://telesiness.ir/api/Dashboards/ListOfVisa")
    .then((res) => res.json())
    .then((result) => setData(result))
    .catch((err) => console.log("error"));
    if(data.length==0)
    //  document.getElementById('ServiceNameBox').style.display="flex";
     console.log(data.length);
};

useEffect(() => {
  fetchData();
  // console.log(data[0].countery_Name);
}, []);

const [data2, setData2] = useState([]);

const fetchData2 = () => {
   fetch('https://telesiness.ir/api/Dashboards/GetLawyerTariffs' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Email:localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{
togglePopup72();
      try{
      var i;
      if(ServicesPrice.length<2)
      {
          ServicesPrice.pop();
  for(i in response){
  ServicesPrice.push({ 
    pen: "true",
    tick: "false", 
    SName: response[i].nameOfVisa,
    SPrice: response[i].wageFigure, 
    SFee: response[i].percentageOfCommission,
    Sabout:response[i].aboutService,
    SReq:response[i].requiredDocuments,
    SProcess :response[i].visaProcess,
    SOther: "false", 
    SOtherDesc: "", 
    userId:response[i].userId,
    lawyerTariffsCode:response[i].lawyerTariffsCode,
    mUnit:response[i].currency,
    key: (parseInt(i)+1).toString(),
  },);
    console.log(response[i]);
  }} }catch{}
    })
    
 
};

useEffect(() => {
  fetchData2();
 
  // console.log(data[0].countery_Name);
}, []);

const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
  if (counter.current < 1) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
    console.log(ServicesPrice);
    forceUpdate();
    setServicesPrice(ServicesPrice);
    return () => clearTimeout(timer);
  }
}, [state]);

const [userTypeSelect,setUserTypeSelect] = useState(0);
console.log(userTypeSelect);
values.Service = userTypeSelect;
const myfuncToUserType = (e) =>{
  setUserTypeSelect(e.target.value);
  values.Service= userTypeSelect;
  if(e.target.value == '1000')
  {
    // document.getElementById('ServiceNameBox').style.display="flex";
    document.getElementById('tariff_formID').style.width ='75.5%';
    values.Service = values.ServiceNameBox;
    // console.log(values.Service);
  }
  else{
    document.getElementById('tariff_formID').style.width ='100%';
    values.Service= userTypeSelect;
  }
  console.log(values.Service);
}


const [isOpen, setIsOpen] = useState(false);
const [isOpen2, setIsOpen2] = useState(false);

const [isOpen3, setIsOpen3] = useState(false);
const [isOpen72, setIsOpen72] = useState(true);

const togglePopup = () => {
  setIsOpen(!isOpen);
} 

const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
}

const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
}

const togglePopup72 = () => {
  setIsOpen72(!isOpen72);
  }

const [isOpenRow, setisOpenRow] = useState(true);

const handleAgentRowClick = (itemKey) =>{
    if(isOpenRow){
        document.getElementById(itemKey).style.display="flex";
        setisOpenRow(!isOpenRow);
    }
    else{
        document.getElementById(itemKey).style.display="none";
        setisOpenRow(!isOpenRow);
    }
}
//-------------------------------

     return ( 
         <>
      <div className="mainRowProPrice col-12">
        <form onSubmit={handleAddService} className="form_addmyserv col-12">
        <div className="personalInfo col-12">
          <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Prices Information')}</strong>
                <div className="priceInfoRowOne col-12 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Prices Information')}</p>
                    </div>
                </div>
          </div>
          <div className="tariff_form col-12" id="tariff_formID">
            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="Service"
                  value={userTypeSelect}
                  label={t('Service')}
                  // MenuProps={MenuProps}
                  onChange={myfuncToUserType}  
                  placeholder={t('Service')}
                  >
                   
                   <option  value={t('Select Service Name')}>{t('Select Service Name')}</option >
                   <option  value={t('Visitor Visa')}>{t('Visitor Visa')}</option >
                   <option  value={t('Student Visa (University/ College)')}>{t('Student Visa (University/ College)')}</option >
                   <option  value={t('Student Visa (Primary School, High School)')}>{t('Student Visa (Primary School, High School)')}</option >
                   <option  value={t('Work Visa')}>{t('Work Visa')}</option >
                   <option  value={t('Express Entry')}>{t('Express Entry')}</option >
                   <option  value={t('Skilled Worker')}>{t('Skilled Worker')}</option >
                   <option  value={t('Canadian Experience')}>{t('Canadian Experience')}</option >
                   <option  value={t('Family Sponsership')}>{t('Family Sponsership')}</option >
                   <option  value={t('Provincial Nominees')}>{t('Provincial Nominees')}</option >
                   <option  value={t('Atlantic Immigration Program')}>{t('Atlantic Immigration Program')}</option >
                   <option  value={t('Start-up Visa')}>{t('Start-up Visa')}</option >
                   <option  value={t('Business Visa')}>{t('Business Visa')}</option >
                   <option  value={t('Nurse Visa')}>{t('Nurse Visa')}</option >
                   <option  value={t('Physician Visa')}>{t('Physician Visa')}</option >
                   <option  value={t('Self Employed (Athletes and Artists,…)')}>{t('Self Employed (Athletes and Artists,…)')}</option >
                   <option  value={t('Refugee')}>{t('Refugee')}</option >
                   <option  value={t('Permanent Residency')}>{t('Permanent Residency')}</option >
                   <option  value={t('Invitation Letter/ Conference/ Seminar')}>{t('Invitation Letter/ Conference/ Seminar')}</option >
                   <option  value={'1000'}>{t('other')}</option >
                      {data &&
                        data.map((element) => (
                          <option  value={element.nameOfVisa}>{element.nameOfVisa}</option >
                      ))}
                          

                </select >
                {/* <label htmlFor="SelectLanguage" className="ReqiredCus">{t('Type Your Desired Name for Service and Then click (Create)')}</label> */}
            </div>
            {/* <label htmlFor="proLastName">{t('Price')}</label>
            <input 
              type="text"
              className="form-control"
              id="proLastNamePrice"
                
              value={values.proLastName}
              onChange={handleChange('proLastName')}  
              onBlur={handleinputproLastNameBlur}  
              required
              /> */}
            {userTypeSelect == '1000' && <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ServiceNameBox" 
                value={values.ServiceNameBox}
                type="text"
                placeholder={t('Type Service Name here')}
                onChange={handleChange('ServiceNameBox')}
                required
                />
                {/* <label htmlFor="proLastNamePrice">{t('Enter the Applicant’s Chinese Score')}</label> */}
            </div>}
            <div className="inputAndlabel col-lg-3 col-12">
                <input
                  id="proLastNamePrice" 
                  value={values.proLastNamePrice}
                  type="text"
                  placeholder={t('Price')}
                  onChange={handleChange('proLastNamePrice')}
                  required
                  />
                  {/* <label htmlFor="proLastNamePrice">{t('Enter the Applicant’s Chinese Score')}</label> */}
            </div>

            <div className="inputAndlabel col-lg-1 col-12">
                <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="mUnit"
                    value={values.mUnit}
                    label={t('mUnit')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('mUnit')}  
                    placeholder={t('mUnit')}
                    >
                      <option  value={'USD'}>{t('USD')}</option >
                      <option  value={'CAD'}>{t('CAD')}</option >
                      <option  value={'AUD'}>{t('AUD')}</option >
                      <option  value={'EUR'}>{t('EUR')}</option >
                      <option  value={'GBP'}>{t('GBP')}</option >
                      <option  value={'SEK'}>{t('SEK')}</option >
                      <option  value={'DKK'}>{t('DKK')}</option >
                      <option  value={'AED'}>{t('AED')}</option >
                      <option  value={'TRY'}>{t('TRY')}</option >
                      <option  value={'CNY'}>{t('CNY')}</option >
                      <option  value={'IRR'}>{t('IRR')}</option > 
                      <option  value={'CHF'}>{t('CHF')}</option >
                      <option  value={'OMR'}>{t('OMR')}</option >
                      <option  value={'MXN'}>{t('MXN')}</option >
                      <option  value={'RUB'}>{t('RUB')}</option >
                      <option  value={'INR'}>{t('INR')}</option >
                      <option  value={'SGD'}>{t('SGD')}</option > 
                      <option  value={'JPY'}>{t('JPY')}</option >
                      <option  value={'NOK'}>{t('NOK')}</option >

                  </select >
                 
            </div>

            <div className="inputAndlabel Agentss_Fee col-lg-4 col-12">
                <input
                  id="Fee" 
                  value={values.Fee}
                  type="text"
                  placeholder={t('Distributer commission')}
                  onChange={handleChange('Fee')}
                  required
                  />
                  {/* <label htmlFor="proLastNamePrice">{t('Enter the Applicant’s Chinese Score')}</label> */}
            </div>
          </div>
        </div>
        
        <div className="personalInfoRowTwoTwo tarifffform col-12">
            <div id="MyAboutServiceRow" className="rowTwoColOnePrice col-12 MyAboutServiceRow">
              <div className="Cus2Inputs col-lg-12 col-12">
              <div className="inputAndlabel col-lg-12 col-12">
                <textarea
                    id="AboutService2" 
                    value={values.AboutService}
                    type="text"
                    placeholder={t('About Service')}
                    onChange={handleChange('AboutService')}
                 
                    />
                    <label htmlFor="AboutService2">{t('Enter the Description About Your Service')}</label>
              </div>
              <div className="inputAndlabel col-lg-6 col-12 MyServiceAbout">
                <input
                      id="RequiredDocuments" 
                      value={values.RequiredDocuments}
                      type="text"
                      placeholder={t('Required Documents')}
                      onChange={handleChange('RequiredDocuments')}
                    
                      />
                <label htmlFor="RequiredDocuments">{t('Enter the Customers Required Documents for This Service')}</label>
              </div>
              <div className="inputAndlabel col-lg-6 col-12 MyServiceAbout">
                <input
                      id="VisaProcess" 
                      value={values.VisaProcess}
                      type="text"
                      placeholder={t('Visa Process')}
                      onChange={handleChange('VisaProcess')}
                      
                      />
                <label htmlFor="VisaProcess">{t('Enter the Process of This Service')}</label>
              </div>
              <div className="inputAndlabel col-12 MyServiceAbout_btn">
                  <button className="btn btn-dark col-lg-4 col-12 ServiceAddBtn">{t('+ Add Service')}</button>
                </div>
              </div>
            </div>
        </div>
        </form> 
        <div className="col-12 top_headmyserv">
            <div className="col-1"><p>{t('ID')}</p></div>
            <div className="col-4"><p>{t('Service Name')}</p></div>
            <div className="col-2"><p>{t('Price')}</p></div>
            <div className="col-2"><p>{t('DC Commission')}</p></div>
            <div className="col-2"><p>{t('DC share')}</p></div>
            <div className="col-1"></div>
        </div> 
        <div className="mainRowProPrice2 list_myserv" id="mainRowProPrice2">
          {ServicesPrice.map(item =>(
                    <div className="item_listserv col-12"  key={item.key}>
                      <div className="agentCusRow col-12">
                       <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                           {item.pen == "true" &&<div className="col-1 id_tariff"><p>{item.key}</p> </div>}
                           {item.pen == "true" &&<div className="price_tariff col-lg-4 col-10"><p>{item.SName}</p>
                            {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>} 
                                <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                            </div>}
                            
                            <div className="col-1 d-block d-md-none edit_tariffbtn">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>{
                              values.AboutService2 = item.Sabout;
                              values.RequiredDocuments2 = item.SReq;
                              values.VisaProcess2 = item.SProcess;
                              console.log(item.userId);
                              handlePenClick(item.key);}} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {/* <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/> */}
                            </div>
                            {item.pen == "true" &&<div className="col-lg-2 col-4 hide_servlg"><p>{item.mUnit+' '+item.SPrice}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2 col-4 hide_servlg"><p>{"%" +item.SFee}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2 col-4 hide_servlg"><p>{item.mUnit+' '+(item.SPrice*item.SFee)/100}</p></div>}
                            </a>
                            {item.tick == "true" &&<div className="col-lg-4 col-12 input_editablesrv"><input placeholder="About Service" className="col-12" onChange={handleChange('AboutService2')} type="text" value={values.AboutService2}/></div>}
                            {item.tick == "true" &&<div className="col-lg-3 col-12 input_editablesrv"><input placeholder="Required Documents" className="col-12" onChange={handleChange('RequiredDocuments2')} type="text" value={values.RequiredDocuments2}/></div>}
                            {item.tick == "true" && <div className="col-lg-3 col-12 input_editablesrv"><input placeholder="Visa Process" className="col-12" onChange={handleChange('VisaProcess2')} type="text" value={values.VisaProcess2}/></div>}   
                            {item.tick == "true" && <div className="saverdit_ms" id={item.key} onClick= {()=>handleTickClick(item.key,item.userId,item.lawyerTariffsCode)}><TickCircle size="24" variant="Bold" color="#fff"/>{t('Save changes')}</div>}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>} 
                            <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            <div className="editservlaw">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>{
                              values.AboutService2 = item.Sabout;
                              values.RequiredDocuments2 = item.SReq;
                              values.VisaProcess2 = item.SProcess;
                              console.log(item.userId);
                              handlePenClick(item.key);}} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons1"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key,item.userId,item.lawyerTariffsCode)} size="18" variant="Bold" color="#21D350" className="PriceListIcons1"/>}
                            {/* <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/> */}
                            </div>
                            <ArrowDown2 size="18" className="arrowservlaw" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                          
                       
                       </div>
                       {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                          <hr className="linebetitem col-12"/>
                          <div className="col-lg-6 col-12 hidenItem"><span>{t('Service Code')}:</span><p>{item.lawyerTariffsCode}</p></div>
                          <div className="d-lg-none col-12 hidenItem"><span>{t('Price')}:</span><p>{item.mUnit+' '+item.SPrice}</p></div>
                          <div className="d-lg-none col-12 hidenItem"><span>{t('DC Commission')}:</span><p>{"%" +item.SFee}</p></div>
                          <div className="d-lg-none col-12 hidenItem"><span>{t('DC share')}:</span><p>{item.mUnit+' '+(item.SPrice*item.SFee)/100}</p></div>
                          <div className="col-lg-6 col-12 hidenItem"><span>{t('Process Of Service')}:</span><p>{item.SProcess}</p></div>
                          <div className="col-12 hidenItem"><span>{t('Documents')}:</span><p>{item.SReq}</p></div>
                          <div className="col-12 hidenItem"><span>{t('About Service')}:</span><p>{item.Sabout}</p></div>
                        </div>
                    </div>}
                    </div>
                  ))}</div>
     </div>
      {isOpen2 && <Popup
        content={
              <><div className="PboxFirstRow">
    
                </div><div className="PboxSecondRow Pbox_headagentCus">
                        <h4>{t('Services Never Change After Adding to Your List')}</h4>
                    </div><div className="PboxThirdRow">
                        <p>{t('Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You don’t have Access to Change Fees or Commissions!')}</p>
                    </div><div className="PboxThirdRow col-12">
                        <label>{t('I Read your Attention Marks:')}</label>
                        {/* <div className="PboxThirdRow">
                            <p>{t('Pay attention You can Approve Customer Account once, and after that you cant change status!')}</p>
                        </div> */}
                        <div className="Pbox_headagentCus PboxSecondRow col-12">
                            <button onClick={togglePopup2} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                            <button onClick={handleAddService2} className="btn btn-dark" type="button" id="button-addon2">{t('OK, I Got It')}</button>
                        </div>
                        {/* <div className="input-group mb-3">
                        <button onClick={()=>{togglePopup2();}} className="btn btn-light" type="button" id="button-addon2">{t('Cancel')}</button>
                            <button onClick={()=>{handleAddService2();}} className="btn btn-dark" type="button" id="button-addon2">{t('Understand, Ok Submit It!')}</button>
                        </div> */}
                    </div></>
        }
        handleClose={togglePopup2}
      />}
      {isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
      </>
      );
 }
 
export default Profile;