import { useTranslation } from 'react-i18next';
import { useState , useEffect } from 'react';
import RegisterLogo from './images/LogoRegister.png';
import { Link } from "react-router-dom";
import {Facebook,Whatsapp,CallCalling,Instagram,Youtube,Home2,SearchNormal1} from 'iconsax-react';


const Cus1 = ({childToParentCus1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    //*********************************** */
const dataCus1e1 = true;
    return ( 
        <div className='content_page'>
                <div className='header_page col-12'>
                    <div className='top_header_page col-md-11 col-12'>
                        <div className='titlehead_page col-4'>
                            <h3 className="font-face-gm">{t('About us')}</h3>
                        </div>
                        <div className='logohead_page col-md-4 col-6'>
                            <img src={RegisterLogo} alt="" />
                        </div>
                        <Link className="font-face-gm icohead_page col-md-4 col-2" to='/'>
                            <Home2 color="white" size="24" />
                        </Link>
                    </div>
                </div>
                <div className='body_page col-12'>
                    <div className='txt_aboutus col-12'>
                        <h3 className="font-face-gm">{`About us`}</h3>
                        <p className="font-face-gm">{`Telesiness means tele-business. You can always make money in long-distance form and get acquainted with various businesses. You can develop your business and save costs.
You need a network marketing world that only via us you can access.
`}</p>
                        <p className="font-face-gm">{`This network's designed wisely. With the lowest price, you can have a network of specialist human forces in your marketing network system. With their help, you benefit from a high number of customers.`}</p>
                        <p className="font-face-gm">{`You owe the durability or life of your business to the customers coming to you from far or near distances. Still, the best way to attract them is to have more marketers.`}</p>
                        <p className="font-face-gm">{`You owe the durability or life of your business to the customers coming to you from far or near distances. Still, the best way to attract them is to have more marketers.
While you're doing business in a local location, the possibility of being known by long-distance customers is very low. Also, presenting your products or new products to far and near customers is expensive. So, in a way, the best way to introduce products and attract customers from different locations is in the content marketing solution. This way, your brand or products are presented person to person by word of mouth, with high confidence and credit. The result is the ease of attracting new customers to your business. That's why having professional marketers or new marketers from other locations is highly important. If you attract some human force for your business, you can make some guarantees to attract more customers.
Therefore, join us to enjoy a perfect network of employees and customers.
`}</p>
                        <p className="font-face-gm">{`This network will be at your service for the first and completely free, and it will help you to increase the number of your employees.
Make a dashboard or business profile so your inside employees get to know your services and work with them.
`}</p>
                        <p className="font-face-gm">{`After making a dashboard for your business, make sure to present your services correctly and in the best way. This part is vital for your business. Since durability and the guarantee of your employees' work depends on your services, this is how you can increase the number of your marketers.`}</p>
                        <p className="font-face-gm">{`Attracting more customers depends on your dashboard; you can hire more employees according to your services.`}</p>
                        <p className="font-face-gm">{`If your services are appropriate and competitive, the number of your employees and marketers will increase. In that case, your human force will send more customers to you.
The better results gained by employees, the more customers they have.
`}</p>
                        <p className="font-face-gm">{`The more efficient the result of your employees' activities are, the more your customers will be directly related to your services and expenses.
Then join us and make your dashboard the best you can.
Don't forget, everything depends on your dashboard.
The success of your business depends on your dashboard design.
We will be by your side on this path.
`}</p>
                        <p className="font-face-gm">{`Telesiness is an application based on a management dashboard for managing your financial resources, human forces, and marketing. We try to lower the costs of starting or developing a business and return the complete independence of people in the industry to them. This application can provide all business needs using various tools and pre-designed dashboards. We have no time or space limitations in any business, and all the users can always access it from any location and make money.`}</p>
                        <p className="font-face-gm">{`We are a super management application merged with cyberspace in the form of network marketing, which uses a drop culture strategy in marketing. Its system is defined to provide B2B- B2C- C2B - B2G - G2B services.
Join us to taste the joy of earning money and tele-business.
`}</p>
 <h3 className="font-face-gm">{'Online Businesses Growth'}</h3>
 <h4 className="font-face-gm">{'Businesses are changing'}</h4>
 <p className="font-face-gm">{`Today, with the rapid growth of the world of technology, various internet services, and online businesses, it is impossible to remove the internet from life and many of the basic human needs depend on it.`}</p>               
 <p className="font-face-gm">{`According to the statistics for 2022, more than 5,000,000,000 people have internet access. If we commercially consider the number, it means that the same numbers of business are available to expose our services and products and monetize with the levers of the online world. `}</p>               
 <p className="font-face-gm">{`We have to accept that in the foreseeable future, there is not much opportunity for local and traditional businesses to grow. They have to adapt themselves to the cyber space and state of the art technology or leave the competition scene with full knowledge.`}</p>               
 <h4 className="font-face-gm">{'Time is money'}</h4>
 <p className="font-face-gm">{`Indeed, the result of effort, diligence, and continuity in the right direction for any business will be a success and high earnings.`}</p>               
 <h4 className="font-face-gm">{'But at what is it worth?'}</h4>
 <p className="font-face-gm">{`These results may take many years despite constant efforts. After all, in developing a business hypothesis, time is one of the most significant criteria for decision-making and planning.`}</p>               
 <h4 className="font-face-gm">{`Isn't your time more valuable?`}</h4>
 <p className="font-face-gm">{`Ideally, the time you spend and the output of your efforts will be valuable and you get success quickly.`}</p>               
 <p className="font-face-gm">{`Telesiness is an application based on the value of time. It has tried to save users' time in every section and reach the desired result as soon as possible. It doesn't matter if they are customers or service providers. By intelligence design and with the knowledge of the needs of all kinds of businesses, Telesiness has provided this feature for its users to improve their business to the maximum by saving time.`}</p>               
 <h4 className="font-face-gm">{'A smooth direction to progress'}</h4>
 <p className="font-face-gm">{`By providing appropriate services, training users, and providing professional principles to go step by step in the work path, Telesiness guarantee all its users that they can travel growth path rapidly.`}</p>               
 <p className="font-face-gm">{`This growth path can include many items such as more customers and income, acquiring new job titles, owning employees, and branding users' businesses.`}</p>               
 <p className="font-face-gm">{`Withdrawing the labor force from the colonization of big businesses or unfair organizations, is the Telesiness main objective in a way that a person can have his own business permanently in a short period by his ability and according to the training and pre-determined rules of Telezins. It means that after passing specific steps and a while, Telesiness users can enter the work system and competitive market and start earning by emphasizing his brand.`}</p>               
 <h4 className="font-face-gm">{'What is the meaning of Telesiness?'}</h4>
 <p className="font-face-gm">{`In one sentence, it means remotely business. Telesiness is an abbreviation of the word telebusiness, which means remotely business. It means that business owners, developers, and agents can always provide services to their customers remotely.`}</p>               
 <h4 className="font-face-gm">{'What services do Telesiness provide?'}</h4>
 <p className="font-face-gm">{`By offering a remote management panel for servicing provider users and quick access to the highest quality services for customers, Telesiness enables business owners, developers, and agents to Introduce own self to a large community of customers, and take steps to promote business and cooperation with different people and companies by remote management.`}</p>               
 <h4 className="font-face-gm">{'What system does the structural model of Telesiness use?'}</h4>
 <p className="font-face-gm">{`To provide services to its users, Telesiness follows the following business structural models:`}</p>               
 <p className="font-face-gm">{`●   B to B is that Business-to-Business`}</p>               
 <p className="font-face-gm">{`●   B to C is that Business-to-Customer`}</p>               
 <p className="font-face-gm">{`●   C to B is that Customer-to- Business`}</p>               
 <p className="font-face-gm">{`●   B to G is that Business-to-Government`}</p>               
 <p className="font-face-gm">{`●   G to B is that Government-to-Business.`}</p>               
 <h4 className="font-face-gm">{'Types of Telesiness users'}</h4>
 <p className="font-face-gm">{`Generally, the types of Telesiness users divided into four categories, which are:`}</p>               
 <h4 className="font-face-gm">{'● Business owners'}</h4>
 <p className="font-face-gm">{`Different companies or guilds have the necessary licenses to operate widely and provide services legally; these types of users can log in to the Telesiness system and provide their services to users by going through determined steps and various approvals. `}</p>               
 <h4 className="font-face-gm">{'● Distributor'}</h4>
 <p className="font-face-gm">{`A distributor is a strong group with a record and the necessary and specific licenses, which can help the growth and development of other businesses or manage their business in the marketing space, according to the required knowledge and facilities. The developers offer the services provided by the business owners to the representatives or customers. It should recall that, these collections can enter Telesiness only after passing certain conditions.`}</p>               
 <h4 className="font-face-gm">{'● Agents'}</h4>
 <p className="font-face-gm">{`Agents are the individuals or companies that, with the cooperation of developers, have a significant contribution in attracting customers or managing them. The responsibility and management of each agent are in charge of its high-level developer, and the agent is responsible for introducing relevant developer services.`}</p>               
 <h4 className="font-face-gm">{'● Customer'}</h4>
 <p className="font-face-gm">{`Customers are those looking to receive services or products. The type of users can directly or indirectly communicate with service provider users and receive confidently. Why should you use Telezsiness?`}</p>               
 <h4 className="font-face-gm">{'Why should you use Telesiness?'}</h4>
 <p className="font-face-gm">{`Using Telesiness has many positive aspects, and we will mention some of significant items:`}</p>               
 <p className="font-face-gm">{`●  Rapid and widespread business growth`}</p>               
 <p className="font-face-gm">{`●  Ability to introduce the business to infinite clients without location restrictions`}</p>               
 <p className="font-face-gm">{`●  Ability to manage financial and business accounting issues`}</p>               
 <p className="font-face-gm">{`●  The possibility of using an intelligent and in-program messenger system`}</p>               
 <p className="font-face-gm">{`●  The possibility of providing services to customers without visiting in person`}</p>               
 <p className="font-face-gm">{`●  A drastic reduction in advertising costs and hidden costs in business`}</p>               
 <p className="font-face-gm">{`●  Providing security according to the existing strict and basic rules and regulations for all users`}</p>               
 <p className="font-face-gm">{`●  Ability to manage human resources`}</p>               
 <p className="font-face-gm">{`●  Ability to categorize services in the user panel`}</p>               
 <p className="font-face-gm">{`●  The possibility of creating an independent network to introduce services`}</p>               
 <p className="font-face-gm">{`●  Ability to manage customers specifically in each branch`}</p>               
 <p className="font-face-gm">{`●  The possibility of creating an independent marketing network with human resources specialized in businesses for advertising low-priced`}</p>               
 <h4 className="font-face-gm">{'Telesiness services for business owners'}</h4>
 <p className="font-face-gm">{`If you own a business owner, just set up a counter on Telesiness and put your services on developers.`}</p>               
 <p className="font-face-gm">{`For example: You are an attorney. By starting to work in Telesiness, no need to look for customers, but the developers will start cooperating and attracting customers for you according to the services you have defined. Infinite developers can associate with you and direct people who need legal services to you.`}</p>               
 <p className="font-face-gm">{`Of course, you should note that creating a dashboard as a business owner will require various steps with strict company supervision, and the cooperation of developers depends on the type of services and costs you consider.`}</p>               
 <h4 className="font-face-gm">{'Telesiness services for developers'}</h4>
 <p className="font-face-gm">{`Developers are groups or companies that are engaged in the development of businesses in Telesiness.  They will introduce the services of business owners to customers by employing a set of representatives, marketers, and scientific labor force in different fields. The advantages of working in Telesiness for developers:`}</p>               
 <h5 className="font-face-gm">{'Human resources management'}</h5>
 <p className="font-face-gm">{`In Telesiness, while you have access to infinite labor, you will also have the ability to control and plan them.`}</p>               
 <h5 className="font-face-gm">{'Speed and convenience of communication'}</h5>
 <p className="font-face-gm">{`Be in touch with a business owner is difficult and time-consuming in the real world. But in Telesiness, this has become very easy. It will continue even to the point that by creating suitable resume and earning points, business owners personally offer cooperation to developers.`}</p>               
 <h4 className="font-face-gm">{'Telesiness services for developers'}</h4>
 <h5 className="font-face-gm">{'Opportunities for growth and development are available for everyone'}</h5>
 <p className="font-face-gm">{`The more agent of a developer, more the number of customers are attracted, and the business will grow.
Agents have the ability to train and grow when they start working for the developer, and developers are obliged to train their agents so that they also become new developers.
`}</p> 
<h5 className="font-face-gm">{'Managing your financial resources'}</h5>              
 <p className="font-face-gm">{`In the business world, any group or company has current expenditures under different titles as advertisements, rent, taxes, etc., and must pay them. In Telesiness, expenses become almost zero, and it will have detailed and accurate management of it and income.`}</p>               
 <h5 className="font-face-gm">{'Unlimited market access'}</h5>
 <p className="font-face-gm">{`Typically, a developer can provide services to a limited number of businesses. But by working in Telesiness, he can always grow and attract more business owners according to his abilities. `}</p>               
 <h5 className="font-face-gm">{'Branding and further development'}</h5>
 <p className="font-face-gm">{`Undoubtedly, every developer is interested in progress and seeks to provide more services for more income. One of the best ways to trust building and satisfy people is branding. In Telesiness, every developer to can work on his brand name and grow his team.`}</p>               
 <p className="font-face-gm">{`According to this, business owners are also interested in working with well-known and experienced developers. Generally every developer will help growth and expand the portfolio by continuously working and following the principles and frameworks. `}</p>               
 <h4 className="font-face-gm">{'Telesiness services for agents'}</h4>
 <p className="font-face-gm">{`Agents are people who work directly to attract customers. These people are directly connected to the developer and indirectly to the business owners.`}</p>               
 <p className="font-face-gm">{`Their task will be to attract customers according to the business services. Also, they can progress in their work path by following the principles and continuity and becoming developers in a short period.`}</p>               
 <p className="font-face-gm">{`In the business world, it is difficult to progress as an agent, and he has to go through a difficult path to reach the next step. Since the platform for education and training is provided, by starting the activity in Telesiness, every agent can be a developer after a period soon, by learning and gaining experience. `}</p>               
 <h4 className="font-face-gm">{'Telesiness services for customers'}</h4>
 <p className="font-face-gm">{`Customers are people who, depending on their needs, are looking for specialists to receive services from them in the best way.`}</p>               
 <p className="font-face-gm">{`Significant points that Telesiness applies to customers `}</p>               
 <h5 className="font-face-gm">{'Communicating with service providers at any tips and comparing them'}</h5>
 <p className="font-face-gm">{`For example, a client needs an attorney for his immigration. Generally if he wants to look for an attorney, he will have limitations, and finally, he has to choose one among several available lawyers. But in Telesiness, there will be no limit to the choice, and will select him among the infinity number of other attorneys according to service amount, the satisfaction of the previous people and, the resume of an attorney. It is also possible to provide your request to a large number of lawyers by the developers, which minimizes singular human error and makes the right decision by a group of specialists.`}</p>               
 <p className="font-face-gm">{`The outcome of issue will be better output, speed of service delivery, financial savings, and convenience for the customer.`}</p>               
 <h5 className="font-face-gm">{'Ensuring the quality and output of the work'}</h5>
 <p className="font-face-gm">{`One of the customers concerns is not being sure of the quality of work. But in Telesiness, this concern has been solved in a specialized way completely.`}</p>               
 <p className="font-face-gm">{`The main thing in creating a dashboard for a business owner or developer is that there are different and complicated steps that people has to pass various filters to be approved and start working. As a result, before the customer wants to check the service provider, Telesiness has done this investigation from different dimensions and provided the confidence for the customers to receive the service surely.`}</p>               
 <p className="font-face-gm">{`When a customer applies to receive services, the direct Telesiness supervisors, the relevant developer, and the agent, are monitoring the services of the business owner, and in case of an error, the business owner will take necessary action. Since the customer service process monitored with such precision and intensity, there will be no place for the customer to worry. The customer will be sure of its quality before taking action to receive a service at Telesiness.`}</p>               
 <h5 className="font-face-gm">{'Increasing the speed and convenience of receiving services'}</h5>
 <p className="font-face-gm">{`From the beginning of the customer service process, the business owner is responsible for the various steps; as a result, the work speed will increase, and the steps will complete.`}</p>               
 <p className="font-face-gm">{`For example, emigration has various time-consuming and troublesome steps, such as sending documents, translating texts, booking embassy appointments, etc. But in Telesiness, you can deliver some evidence to the lawyer with a few clicks, and translate them with a few clicks, and book an appointment with a few clicks. Obviously, the speed of doing things in Telesiness is not comparable to other modes.`}</p>               
 <h5 className="font-face-gm">{'Receive services sequentially'}</h5>
 <p className="font-face-gm">{`A positive and important point in using Telesiness services is that services are diverse. This possibility provided to receive all these services continuously in the application, if customers receive a service that follows another service.`}</p>               
 <p className="font-face-gm">{`For example, consider that you will always need other services in the application after receiving services from an immigration lawyer and after obtaining a visa, which is beyond the obligations of your lawyer. But in this regard, Telesiness will provide you with other necessary immigration services, such as buying or renting a house, insurance, ticket purchase, buying or renting a car, employment, banking services, etc. in the destination country.`}</p>               
 <h4 className="font-face-gm">{'What will be the future of active businesses in Telesiness?'}</h4>
 <p className="font-face-gm">{`Activity in Telesiness is profitable and wealth-creating in every direction.`}</p>               
 <p className="font-face-gm">{`According to the previous explanations, this path is smooth and passable if you start working and interested in improvement.`}</p>               
 <p className="font-face-gm">{`In terms of branding, the development of your personality, and your affiliated group, is a very suitable platform that provided.`}</p>               
 <p className="font-face-gm">{`Indeed, people who join Telesiness will be interested in working with those have suitable resumes and higher scores. So you will create a bright future for yourself by starting your activity today. Also, you will ensure yourself forever by going through the different stages of your business.`}</p>               
 <p className="font-face-gm">{`If a person works in Telesiness and gradually brings his/her dashboard to an acceptable level naturally, the dashboard of that group will have a high value.`}</p>               
 <p className="font-face-gm">{`A suitable dashboard includes an appropriate resume, customers and, target audiences with high density, high points obtained from activities, a reliable brand, and gaining trust in the minds of customers. As a result, this dashboard can help a person who started his action from zero to go through the steps of growth at a very high speed and achieve many material and spiritual achievements.`}</p>               
 <p className="font-face-gm">{`According to the current state of business and the move from business to the online field, it is easy to predict that soon many business owners will be forced to make their market online and attract customers in this way.`}</p>               
 <p className="font-face-gm">{`The various levers such as saving time, saving financial resources, easy management of affairs, easy monitoring of human resources, access to a greater community of customers and, etc., provide this opportunity for people who start working in it to present their services online and experiment visible development and progress in the long term.`}</p>               
 <p className="font-face-gm">{`This platform will bring good results for its users.`}</p>               
 <h4 className="font-face-gm">{'Strength of Telesiness'}</h4>
 <p className="font-face-gm">{`As you know, the virtual world is very complex and competitive place where has led to the monopoly of this market by some big companies. But all existing applications provide services in one direction and to attract users exclusively for some kind of special services of the application itself.`}</p>               
 <p className="font-face-gm">{`Telesiness has managed to design a system that this path works uniquely for people and not for the application itself so that by making different tools to its users, it has been able to exclusively return the income from the activity of each group to them. It means that Telesiness reserves the material and spiritual rights of its users.`}</p>               
 <p className="font-face-gm">{`In a way, Telesiness can integrate management tools in the virtual space marketing network by designing an interconnected system.`}</p>               
 <p className="font-face-gm">{`Amazingly, Telesiness has been able to link among management dashboards, virtual space, marketing and branding, shopping, digital currency, recruitment, questions and answers (Q&A), research and development, rendering of services, and welfare work with the network marketing model (drop system) in guilds and different spaces.`}</p>                           
                    </div>
                </div>
                <div className='footer_page col-12'>
                    <div className="about-us col-12">
                        <div className="spanAbout">
                        <p> 
                            <Link to="/services"> <span>{t('Services')+' '}</span></Link>
                            <span>|</span>  <Link to="/AboutusLogin"><span>{t('About')}</span></Link> <span>|</span>
                            <Link to="/CollaborationsLogin"><span> {t('Collaborations')}</span></Link> <span>|</span> 
                            <Link to="/TermsLogin"><span>{' '+t('Terms')}</span></Link> <span>|</span>
                            <Link to="/HelpLogin"><span> {t('Help')}</span></Link> <span>|</span> 
                            <Link to="/ContactUsLogin"><span>{' '+t('Contact Us')}</span></Link> 
                        </p>
                        </div>
                    </div>
                    <div className="footer-copy col-12">
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                    <div className="footer-copy2 col-12">
                    <br />
                        Copyright &copy; 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                    </div>
                </div>
        </div>
    )
}
 
export default Cus1;