import { useState, useEffect,useRef} from "react";
import { Route, useHistory } from "react-router";
import {Edit2,More,Trash,TickCircle,InfoCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';

const techCompanies = [
  {},
  { label: "Apple", value: 1 },
  { label: "Facebook", value: 2 },
  { label: "Netflix", value: 3 },
  { label: "Tesla", value: 4 },
  { label: "Amazon", value: 5 },
  { label: "Alphabet", value: 6 },
  { label: "other", value: 7 },
];
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}




const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
  })
}



const Profile = ({childToParentCus1e1}) => {
    const userActive =true;
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const forceUpdate = useForceUpdate();

    const [ServicesPrice , setServicesPrice] = useState([
      {pen:"true",tick:"false",VName:'', VSituation:'',VDest:'',VCode:'',VCreateDate:'', key:'1'},
    
    ]);

    const history = useHistory();


    if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
      techCompanies.pop();
      techCompanies.push({label: "دیگر | وارد کنید ...", value: undefined},);
    }
    else{
      techCompanies.pop();
      techCompanies.push({label: "Other | type here ...", value: undefined},);
    }
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('http://localhost:8000/blogs' , {
        //     method: 'POST',
        //     headers: {"Content-type": "application/json"},
        //     body:JSON.stringify(blog)
        // }).then(()=>{
        //     console.log('new blog added');
        //     setIspending(false);


        //setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }
 
//*********************my values******************************* */
const [isOpen72, setIsOpen72] = useState(true);
const togglePopup72 = () => {
  setIsOpen72(!isOpen72);
  }

        const [values, setValues] = useState({

          Service: 'male',
          Fee: '',
          AboutService: '',
          proPhone:'3352258774',
          proLastName:'',
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          // if(!(document.getElementById('Service').value=="")){
          //   //  document.getElementById('errorLabelDegree').style.display="flex";
          //    document.getElementById('MyServiceplaceholder').style.color="transparent";
          //    document.getElementById('MyServiceSapn').style.color="transparent";
          //   }
          //   else{
          //   //  document.getElementById('errorLabelDegree').style.display="none";
          //    document.getElementById('MyServiceplaceholder').style.color="gray";
          //    document.getElementById('MyServiceSapn').style.color="red";
          //   }

        
        };
      

       
//**********************multi language functios **********************/
const {t, i18n} = useTranslation(['Login']);
const [myCalaedar , setMyCalendar]= useState(false);



 const handleinputproLastNameBlur = (e) =>{
  if(!(document.getElementById('proLastNamePrice').value=="")){
   document.getElementById('errorLabelproLastName').style.display="flex";
   document.getElementById('MyproLastNameplaceholder').style.color="transparent";
   document.getElementById('MyproLastNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproLastName').style.display="none";
   document.getElementById('MyproLastNameplaceholder').style.color="gray";
   document.getElementById('MyproLastNameSapn').style.color="red";
  }
}

const handleinputServiceBlur = (e) =>{
  if(!(document.getElementById('Service').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyServiceplaceholder').style.color="transparent";
   document.getElementById('MyServiceSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyServiceplaceholder').style.color="gray";
   document.getElementById('MyServiceSapn').style.color="red";
  }
}

const handleinputFeeBlur = (e) =>{
  if(!(document.getElementById('Fee').value=="")){
   document.getElementById('errorLabelFee').style.display="flex";
   document.getElementById('MyFeeplaceholder').style.color="transparent";
   document.getElementById('MyFeeSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelFee').style.display="none";
   document.getElementById('MyFeeplaceholder').style.color="gray";
   document.getElementById('MyFeeSapn').style.color="red";
  }
}

const handleinputAboutServiceBlur = (e) =>{
  if(!(document.getElementById('AboutService').value=="")){
   document.getElementById('errorLabelAboutService').style.display="flex";
   document.getElementById('MyAboutServiceplaceholder').style.color="transparent";
   document.getElementById('MyAboutServiceSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelAboutService').style.display="none";
   document.getElementById('MyAboutServiceplaceholder').style.color="gray";
   document.getElementById('MyAboutServiceSapn').style.color="red";
  }
}
const [hidePen , setHidePen]=useState(false);
const [hideTick , setHideTick]=useState(true);
const [selectValue , setSelectValue] = useState({ label: "Select a Service", value: 999 });
//***********************edit pen************************* */
// const [selectValue , setSelectValue] = useState({ label: "Select a Service", value: 999 });
// useEffect(() => {
//   if(document.getElementsByTagName('html')[0].getAttribute("dir")=="rtl"){
//     setSelectValue({ label: "یک گزینه انتخاب کنید", value: 999 });
//     console.log(selectValue);
//   }
//   else{
//     setSelectValue({ label: "Select a Service", value: 999 });
//     console.log(selectValue);
//   }
// },[document.getElementsByTagName('html')[0].getAttribute("dir")]);


// var selectCounter = 0;
// if (selectValue.label == "other") {
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   setSelectValue({label: "Other| type here...", value: 9999});
//   document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   // console.log(selectValue);
// } else if ((selectValue.value == "Select a Service")){
//   try { document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
// } else if (!(selectValue.label == "other" || selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="none";
//   // document.getElementById('mainRowProPrice2').style.height="328px";
//   selectCounter = 0;}
//   catch{}
  
// }else if (( selectValue.label == "Other | type here ...")){
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   // document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1;
//   }
//   catch{}
//  // setSelectCounter(1);
// }
// if((techCompanies.filter(price => price.label == selectValue.label).length==0) && (selectValue.label != "Select a Service"))
// {
//   try{ document.getElementById('MyAboutServiceRow').style.display="flex";
//   document.getElementById('mainRowProPrice2').style.height="282px";
//   selectCounter = 1}
  
//   catch{}
// }
var selectCounter = 0;
  

  const handlePenClick = (itemKey) =>{
     for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {

        ServicesPrice[i].pen='false';
        ServicesPrice[i].tick='true';
        //setValues({ ...values, ['Service']: ServicesPrice[i].SName});
        setValues({ ...values, ['proLastName']: ServicesPrice[i].SPrice , ['Fee']: ServicesPrice[i].SFee , ['Service']: ServicesPrice[i].SName});
        //setValues({ ...values, ['Fee']: ServicesPrice[i].SFee});
        forceUpdate() ;
        setServicesPrice(ServicesPrice);
       
      }
  }
  }
  
  const handleTickClick = (itemKey) =>{
    for(var i in ServicesPrice){
      if(ServicesPrice[i].key==itemKey)
      {
        ServicesPrice[i].pen='true';
        ServicesPrice[i].tick='false';
        ServicesPrice[i].SName = values.Service;
        ServicesPrice[i].SPrice = values.proLastName;
        ServicesPrice[i].SFee = values.Fee;
        forceUpdate() ;
        setServicesPrice(ServicesPrice);
      }
  }
  }
  
  const handleTrashClick = (itemKey) =>{
    console.log(itemKey);
    setServicesPrice(ServicesPrice.filter(ServicesPrice => ServicesPrice.key != itemKey.toString()));
        forceUpdate() ;
        //setServicesPrice(ServicesPrice);

    }
  

  const handleAddService = (e) =>{
    e.preventDefault();
    if(selectCounter==0)
    {
      setValues({ ...values, ['Service']: selectValue.label});
      ServicesPrice.push({ 
         pen: "true",
         tick: "false", 
         SName: selectValue.label,
         SPrice: values.proLastName, 
         SFee: values.Fee,
         SOther: "false", 
         SOtherDesc: "", 
         key: (ServicesPrice.length+1).toString(),},);
         forceUpdate();
         setServicesPrice(ServicesPrice);
    }
    else{
      setValues({ ...values, ['Service']: selectValue.label});

      ServicesPrice.push({ 
         pen: "true",
         tick: "false", 
         SName: selectValue.label,
         SPrice: values.proLastName, 
         SFee: values.Fee,
         SOther: "true", 
         SOtherDesc: values.AboutService, 
         key: (ServicesPrice.length+1).toString(),},);
         forceUpdate();
         setServicesPrice(ServicesPrice);
    }
    
  }

  const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="flex";
}
const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
    document.getElementById(itemKeyCir).style.display="none";
}

const [data2, setData2] = useState([]);

const fetchData2 = () => {
   fetch('https://telesiness.ir/api/Dashboards/GetCustomerDocumentList' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Email:localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{
      togglePopup72();
      try{
      var i;
      if(ServicesPrice.length<2)
      {
          ServicesPrice.pop();
          var MyDocStat;
          localStorage.removeItem('documentCode');
  for(i in response.customerDOcumentList){
    if(response.customerDOcumentList[i].verifyCustomerByDistributor==0)
    MyDocStat = 'Pending';
    else if(response.customerDOcumentList[i].verifyCustomerByDistributor==1)
    MyDocStat ='Active';
    else
    MyDocStat = 'Deative';
  ServicesPrice.push({ 
    pen: "true",
    tick: "false", 
    VName: response.customerDOcumentList[i].visaType,
    VSituation: MyDocStat, 
    VDest: response.customerDOcumentList[i].destination,
    VCode:response.customerDOcumentList[i].documentCode,
    visaType:response.customerDOcumentList[i].visaType,
    VCreateDate:response.customerDOcumentList[i].submitDate,
    documentCode:response.customerDOcumentList[i].documentCode,
    key: (parseInt(i)+1).toString(),},);
    localStorage.setItem('documentCode',response.customerDOcumentList[i].documentCode);
    
  }} }catch{}
    })
    
 
 
};

useEffect(() => {
  fetchData2();
 
  // console.log(data[0].countery_Name);
}, []);

const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
  if (counter.current < 1) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
    console.log(ServicesPrice);
    forceUpdate();
    setServicesPrice(ServicesPrice);
    return () => clearTimeout(timer);
  }
}, [state]);


//-------------------------------

     return ( 
         <>
      <div className="mainRowProPrice col-12">
        <form onSubmit={handleAddService} className="">
        <div className="personalInfo col-12">
          {/* <div className="priceInfoRowOne col-12">
            <div className="penAndPerson">
              <p className="pricesInfo">{t('Prices Information')}</p>
            </div>
          </div> */}
         
          <div className="personalInfoRowTwo col-12">
         
            <div className="rowTwoColOnePriceCusS111 col-12">

              <div className="input-placeholderPriceTwo111 col-12">

                <div className="input-placeholderPriceTwo1112 col-md-6 col-11">
                <p className="CusStep2TickP">{t('My Requests')}</p>
                </div>
              <div className="input-placeholderPriceTwo111 col-md-6 col-1">
                <button onClick={(dataCus1e1) => {childToParentCus1e1(dataCus1e1);}} id="myServiceBtn" className="btn btn-dark myServiceBtn col-5">{t('+ Register new Request')}</button>
                </div>
               
              </div>
              
              
            <div className="mynewRowCusStep111 col-12">
              <p className="col-1">{t('ID')}</p>
              <p className="col-2">{t('Visa Type')}</p>
              <p className="col-2">{t('Situation')}</p>
              <p className="MyPaddingAlittleMiuns col-2">{t('Destination')}</p>
              <p className="col-2">{t('Code')}</p> 
              <p className="col-2">{t('Creation Date')}</p>
              <p className="col-1"></p>
            </div>
            
              </div>
             
          </div>
         
             </div>
        </form>
        <div className="personalInfoRowTwoTwo col-12">
        <div id="MyAboutServiceRow" className="rowTwoColOnePrice col-12 MyAboutServiceRow">

            </div>
            <hr className="CustomerLine"/>
        </div>
        

            
        <div className="box-requestcus" id="mainRowProPrice2">
        {ServicesPrice.map(item =>(
                    <div key={item.key}>
                      <div className="item_request col-12">
                       {/* <div className="col-1"><p>
                       <input type="checkbox"></input>
                           </p> </div> */}
                           <div className="agentCusRow col-12">
                              {<div className="col-1"><p>{item.key}</p> </div>}
                              <div className=" col-lg-2 col-5"><p>{item.VName}</p></div>
                              <div className=" col-lg-2 col-5 btn_de_activep">
                                {item.VSituation =='Pending'&&<p className="Pending">{item.VSituation}</p>}
                                {item.VSituation =='Deative'&&<p className="deactive">{item.VSituation}</p>}
                                {item.VSituation =='Active'&&<p className="active">{item.VSituation}</p>}
                                {/* <p className="CusSituationGray">{item.VSituation}</p> */}
                                
                              </div>
                              {/* editandtrash */}
                              <div className="PenAndTrashm">
                                {/* <More size="20" color="#313131"/> */}
                                {item.pen == "true" && <Edit2 id={item.key} onClick={(dataCus1e1) => {childToParentCus1e1(dataCus1e1);}} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                              </div>
                              <div className="item_Country col-lg-2 col-12"><span>{t('Country')}:</span><p>{item.VDest}</p></div>
                              <div className="item_TrackingCode col-lg-2 col-12"><span>{t('Tracking Code')}:</span><p>{item.VCode}</p></div>
                              <div className="item_Created col-lg-2 col-12"><span>{t('Created')}:</span><p>{item.VCreateDate.split('T',1)}</p></div>
                              {/* /* { <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a >
                                          <InfoCircle size="18" color="#313131"/>
                                      </a>
                                  </div>} 
                                  <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}  
                              <div className="col-1 PenAndTrash">
                              {item.pen == "true" && <Edit2 id={item.key} onClick={(dataCus1e1) => {childToParentCus1e1(dataCus1e1);}} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                              {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                              <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                              </div>
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))} </div>
                         {isOpen72 && <Popup22
        content={<>
        <div className="PboxMainRow">
       
        <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
          </div>
        </>}
        handleClose={togglePopup72}
      />}
     </div>
     
     
      
      </>);
 }
 
export default Profile;