import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2,TickCircle} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import Popup22 from './Popup22';
import { Circles } from  'react-loader-spinner';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = () => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {AgentPic:'CusPic.png', 
        AgentName:'',
        AgentPhone:'+',
        AgentStatus:'Active',
        AgentClients:'', 
        Email:'',
        key:'129121',suggestedList:[{}]},
      
      ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.Clients < b.Clients) ? 1 : ((b.Clients < a.Clients) ? -1 : 0)));
        // setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.Clients > b.Clients) ? 1 : ((b.Clients > a.Clients) ? -1 : 0)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }

    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteMarketersByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailC ,DistributorCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{

                setIsOpen2(!isOpen2);
                values.EmailC='';
            })
        setIsOpen2(!isOpen2);
    }
    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey,i,marketerCode) =>{
        if(isOpenRow){
            fetch('https://telesiness.ir/api/Dashboards/DistributorAgentsConnection' , {
                     method: 'POST',
                     headers: { 
                      'Content-Type': 'application/json' ,
                      'Cache-Control': 'no-cache'
                  },
                     body: JSON.stringify({ Email: localStorage.getItem('email') ,MarketerCode:marketerCode })
                     }).then(response23 => response23.json()).then(response23=>{
                        people[i-1].Clients=response23.countOfDcAgentClient;
                        people[i-1].suggestedList=response23.marketerSuggestService;
                        document.getElementById(itemKey).style.display="flex";
                        setisOpenRow(!isOpenRow);
                     })
            
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [values, setValues] = useState({
        
        MyOfferToOtherDis:'',
        EmailC:'',
      });
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       
      };
const handleSubmit = (Mcode,Sname,Offer) => {
    
    localStorage.setItem('Mcode',Mcode);
    localStorage.setItem('Sname',Sname);
    localStorage.setItem('Offer',Offer);
       togglePopup();
   
}
    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen72, setIsOpen72] = useState(true);

    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

    const togglePopup72 = () => {
    setIsOpen72(!isOpen72);
    }
//******************************************************************************* */
//********************************************************************************* */
const [respFinal , setrespFinal] = useState([]);
const [finalRespList , setfinalRespList] = useState([]);
const [resp2, setresp2]=useState([]) ;
const [resp21, setresp21]=useState([]) ;
const [dep1, setdep1]=useState(false) ;
const [dep2, setdep2]=useState(false) ;
const [dep3, setdep3]=useState(false) ;
const [dep31, setdep31]=useState(false) ;
const [dep21, setdep21]=useState(false) ;
const [dep11, setdep11]=useState(false) ;
const [dep4, setdep4]=useState(false) ;

const fetchDatarespFinel = () => {
    fetch('https://telesiness.ir/api/Dashboards/GetMarketersList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{

        // console.log(response[0]);
        if(people.length<2)
           people.pop();

           var i =0;
           var myActDec = '';
           for (i in response.marketerList){
               if(response.marketerList[i].marketerStatus == false)
               myActDec = "Deactive";
               else
               myActDec = "Active";
               
               people.push( {
                 AgentClients:response.marketerList[i].customerVisaName,
                 AgentPic:response.marketerList[i].marketerAvatar, 
                 AgentName:response.marketerList[i].marketerFirstName 
                 + " " + 
                 response.marketerList[i].marketerLastName,
                 AgentPhone:response.marketerList[i].marketerPhoneNumber,
                 AgentStatus:myActDec,
                 AdvocacyCode:response.marketerList[i].marketerCode,
                 City:response.marketerList[i].marketerCity,
                 Country:response.marketerList[i].marketerCountry,
                 Address:response.marketerList[i].marketerAddress,
                 Email:response.marketerList[i].marketerWorkMail,
                 Clients:'',
                 suggestedList:'',
                 key:(parseInt(i)+1).toString(),},)

               }
               togglePopup72();

    });

                               }
    // if(finalRespList.length<2)
    // finalRespList.pop();




const handleSubmitToggleTerrafie = () => {
    var sel = document.getElementById("MyOfferToOtherDis");
    var text = sel.options[sel.selectedIndex].text;

    fetch("https://telesiness.ir/api/Dashboards/SubmitAgentServiceByDistributor/3", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            Email: localStorage.getItem("email"),
            UserId: 3,
            MarketerReferralCode: localStorage.getItem("Mcode"),
            DistributorServiceName: text,
            DistributorOffer: localStorage.getItem("Offer"),
            DistributorServiceStatus: "Accept",
            DistributorServiceCode: document.getElementById("MyOfferToOtherDis").value,
        }),
    }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    // setData(response.distributorList)
                    values.MyOfferToOtherDis='';
                    values.MyOffer = '';
                    localStorage.removeItem('Offer');
                    localStorage.removeItem('Sname');
                    localStorage.removeItem('Mcode');
                    forceUpdate();
             }

            catch{
                console.log('error');
            }
            // console.log(response[0]);
            
        });
        togglePopup();
}

const [data, setData] = useState([]);

const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/GetDistributorServiceList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    setData(response.distributorService)
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};

useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);



  useEffect(() => {
    fetchDatarespFinel();
    // console.log(data[0].countery_Name);
  }, []);
const [staterespFinel, setStaterespFinel] = useState({ num: 0 });
const counterrespFinel = useRef(0);




const [state, setState] = useState({ num: 0 });
const counter = useRef(0);



useEffect(() => {
if (counter.current < 1) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

return () => clearTimeout(timer);
}
}, [state]);

// const [values, setValues] = useState({

//     LawyerCode: '',
//     EmailC: '',

//   });

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   }
    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Agents List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Agents List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Agent')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Agent')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Phone Number')}</p></div>
                <div className="col-3"><p>{t('Email')}</p></div>
                <div className="col-1 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                {/* <div className="col-2 marketer_phone_col2"><p>{t('Phone')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div> */}
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
                {people.map(item =>( 
                    <div className="itemBox2 col-12" key={item.key}>
                        <div className="agentCusRow col-12">
                            <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow',item.key,item.AdvocacyCode)}>
                                <div className="col-1 id_agentCus"><p>{item.key}</p> </div>
                                <div className="CusPic2 col-lg-3 col-8">
                                    <div className='img_agentCus'>
                                        <img src={'https://telesiness.ir/MarketersAvatar/'+ item.AgentPic} alt="" />
                                    </div>
                                    <div className='detail_agentCus'>
                                        <p>{item.AgentName}</p>
                                        <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                    </div>
                                     
                                </div>
                                <div className="col-2 phone_agentCus"><p>{item.AgentPhone}{item.AdvocacyCode}</p></div>
                                <div className="col-3 mail_agentCus"><p>{item.Email}</p></div>
                                {item.AgentStatus=='Active' && <div className="col-lg-1 col-4 de_active_agentCus">
                                    <button className="btn-sm active">
                                        {t('Active')}
                                    </button></div>}

                                {item.AgentStatus=='Deactive' && <div className="col-lg-1 col-4 de_active_agentCus">
                                    <button className="btn-sm deactive">
                                        {t('Deactive')}
                                    </button>
                                </div>}
                            </a>
                        </div>  
                        {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                            <div className="hidenItemRow">
                                <hr className="linebetitem col-12"/>
                                <div className="d-lg-none col-12 count_agentCusm hidenItem"><span>{t('Phone')}:</span><p>{item.AgentPhone}</p></div>
                                <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                                <div className="col-lg-3 col-12 hidenItem"><span>{t('Code')}:</span> <p>{item.AdvocacyCode}</p> </div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('Country')}:</span> <p>{item.Country}</p> </div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('City')}:</span> <p>{item.City}</p> </div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('Clients')}:</span> <p>{item.Clients}</p> </div>
                                <div className="col-12 hidenItem"><span>{t('Address')}:</span> <p>{item.Address}</p> </div>
                                <div className='SuggestedServices hidenItem col-12'>
                                <div className="col-12 colm_contentlist">
                                    <span>{t('Your Assigned Services to Agent')}:</span> 
                                    <div className='DisDisService col-12'> 

                                        <div className='myDisDisServiceToAccept'>
                                        {item.suggestedList && item.suggestedList.map(element2 =>( 
                                       
                                            <div className='txt_SugServices col-lg-6 col-12'>
                                                    <span> <b>{element2.distributorServiceName}</b> + {t('Your Assigned offer')} <b>{element2.distributorOffer}%</b></span>
                                                </div>
                                       ))}
                                            {/* <div className='txt_SugServices col-lg-6 col-12'>
                                                    <span>{'element2.distributorServiceName'} + {t('Your Assigned offer')} <b>{'element2.distributorOffer'}</b></span>
                                                </div> */}
                                           
                                        </div>
                                        {/* <span>{t('Startup')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>
                                        <span>{t('Educational Visa')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span> */}
                                    </div> 
                                                
                                </div>
                                <div className="col-12 colm_contentlist">
                                    <span>{t('Assign a Services')}:</span> 
                                    <div className='DisDisService2 col-12'> 
                                        <div className="inputAndlabel col-lg-5 col-12">
                                            <select
                                                aria-label="Default"
                                                // labelId="demo-simple-select-Degree-helper-label"
                                                id="MyOfferToOtherDis"
                                                value={values.MyOfferToOtherDis}
                                                label={t('MyOfferToOtherDis')}
                                                // MenuProps={MenuProps}
                                                onChange={handleChange('MyOfferToOtherDis')}  
                                                placeholder={t('MyOfferToOtherDis')}
                                                required
                                                >
                                                    <option  value="">
                                                    {t('Service')}
                                                   
                                                </option >
                                                {data &&
                                                       data.map((element) => (
                                                <option  value={element.distributorServiceCode}>{element.distributorServiceName + ' ' + element.distributorServiceCountry}</option >
                                               
                                                       ))}
                                            </select >
                                            {/* <label htmlFor="MyOfferToOtherDis">{t('Enter the applicants MyOfferToOtherDis')}<p className="ReqiredCus">{t('(Required)')}</p></label> */}
                                            </div>
                                            <div className="Cus2Inputs col-lg-5 col-12">
                                            {<div className="inputAndlabel porsant_sug col-12">
                                                <input
                                                    id="MyOffer" 
                                                    value={values.MyOffer}
                                                    type="text"
                                                    placeholder={t('Enter Your Offer')}
                                                    onChange={handleChange('MyOffer')}
                                                    
                                                    />
                                                    {/* <label htmlFor="MyOffer">{t('Enter Your Offer to This Service')}</label> */}
                                            </div>}
                                            {values.MyOfferToOtherDis=='200'&&<div className="inputAndlabel porsant_sug col-12">
                                                <input
                                                    id="MyOffer" 
                                                    value={values.MyOffer}
                                                    type="text"
                                                    placeholder={t('15%')}
                                                    disabled
                                                    onChange={handleChange('MyOffer')}
                                                    
                                                    />
                                                    {/* <label htmlFor="MyOffer">{t('Enter Your Offer to This Service')}</label> */}
                                            </div>}
                                            </div>
                                            {<div onClick={() => {handleSubmit(item.AdvocacyCode,values.MyOfferToOtherDis,values.MyOffer);}} className='btn btn-success col-lg-2 myDisSubmitService' >{t('Submit')}</div>}
                                            {values.MyOfferToOtherDis=='200'&&<div className='btn btn-light-outline col-lg-4 myDisSubmitService' >{t('You Assigned Before')}</div>}
                                        {/* <span>{t('Express Entry')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success'>{t('Accept')}</button> <button className="col-3 btn btn-danger">{t('Rejected')}</button>  </span> 
                                        <span>{t('Worker Visa')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success'>{t('Accept')}</button> <button className="col-3 btn btn-danger">{t('Rejected')}</button>  </span> 
                                        <span>{t('Startup')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>
                                        <span>{t('Educational Visa')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span> */}
                                    </div>           
                                </div>
                                </div>
                            </div>
                        </div>}
                        <ArrowDown2 className="arrowshow_list" size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow',item.key,item.AdvocacyCode)}/>
                    </div>
                
                        ))}
            </div>
            {isOpen && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Are you sure about the set tariff?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please be sure of the rate set as commission. It is not possible to change the tariff after final approval')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleSubmitToggleTerrafie} className="col-3 btn btn-dark">{t('OK')}</button>
            </div>
        
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new Agent')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Agent you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}
        {isOpen72 && <Popup22
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup72}
    />}
    </>
        
    );
}
 
export default Agents;