import { useState, useEffect,useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  console.log(values.EnglishCheckBox);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentJobInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      
      CustomerWorkExperienceByYear:values.WorkExperience,
      CustomerDateOfJobNumberOne:DateFirstJob.toString(),
      CustomerPostInJobNumberOne:values.Post1,
      CustomerWorkplaceNumberOne:values.Workplace1,
      CustomerDateOfJobNumberTwo:Date2Job.toString(),
      CustomerPostInJobNumberTwo:values.Post2,
      CustomerWorkplaceNumberTwo:values.Workplace2,
      CustomerDateOfJobNumberThree:Date3Job.toString(),
      CustomerPostInJobNumberThree:values.Post3,
      CustomerWorkplaceNumberThree:values.Workplace3,
      CustomerDateOfJobNumberFour:Date4Job.toString(),
      CustomerPostInJobNumberFour:values.Post4,
      CustomerWorkplaceNumberFour:values.Workplace4,
      CustomerDateOfJobNumberFive:Date5Job.toString(),
      CustomerPostInJobNumberFive:values.Post5,
      CustomerWorkplaceNumberFive:values.Workplace5,
      CustomerDateOfJobNumberSix:Date6Job.toString(),
      CustomerPostInJobNumberSix:values.Post6,
      CustomerWorkplaceNumberSix:values.Workplace6,
      CustomerDateOfBusinessLicenseNumberOne:DateBusinessLicense1.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberOne:values.TypeNameLicense1,
      CustomerBusinessLicenseExpirationDateNumberOne:LicenseExpirationDate1.toString(),
      CustomerDateOfBusinessLicenseNumberTwo:DateBusinessLicense2.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberTwo:values.TypeNameLicense2,
      CustomerBusinessLicenseExpirationDateNumberTwo:LicenseExpirationDate2.toString(),
      CustomerDateOfBusinessLicenseNumberThree:DateBusinessLicense3.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberThree:values.TypeNameLicense3,
      CustomerBusinessLicenseExpirationDateNumberThree:LicenseExpirationDate3.toString(),
      CustomerDateOfBusinessLicenseNumberFour:DateBusinessLicense4.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberFour:values.TypeNameLicense4,
      CustomerBusinessLicenseExpirationDateNumberFour:LicenseExpirationDate4.toString(),
      CustomerDateOfBusinessLicenseNumberFive:DateBusinessLicense5.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberFive:values.TypeNameLicense5,
      CustomerBusinessLicenseExpirationDateNumberFive:LicenseExpirationDate5.toString(),
      CustomerDateOfBusinessLicenseNumberSix:DateBusinessLicense6.toString(),
      CustomerTypeAndNameOfBusinessLicenseNumberSix:values.TypeNameLicense6,
      CustomerBusinessLicenseExpirationDateNumberSix:LicenseExpirationDate6.toString(),

  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentJobInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerWorkExperienceByYear:values.WorkExperience,
           CustomerDateOfJobNumberOne:DateFirstJob.toString(),
           CustomerPostInJobNumberOne:values.Post1,
           CustomerWorkplaceNumberOne:values.Workplace1,
           CustomerDateOfJobNumberTwo:Date2Job.toString(),
           CustomerPostInJobNumberTwo:values.Post2,
           CustomerWorkplaceNumberTwo:values.Workplace2,
           CustomerDateOfJobNumberThree:Date3Job.toString(),
           CustomerPostInJobNumberThree:values.Post3,
           CustomerWorkplaceNumberThree:values.Workplace3,
           CustomerDateOfJobNumberFour:Date4Job.toString(),
           CustomerPostInJobNumberFour:values.Post4,
           CustomerWorkplaceNumberFour:values.Workplace4,
           CustomerDateOfJobNumberFive:Date5Job.toString(),
           CustomerPostInJobNumberFive:values.Post5,
           CustomerWorkplaceNumberFive:values.Workplace5,
           CustomerDateOfJobNumberSix:Date6Job.toString(),
           CustomerPostInJobNumberSix:values.Post6,
           CustomerWorkplaceNumberSix:values.Workplace6,
           CustomerDateOfBusinessLicenseNumberOne:DateBusinessLicense1.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberOne:values.TypeNameLicense1,
           CustomerBusinessLicenseExpirationDateNumberOne:LicenseExpirationDate1.toString(),
           CustomerDateOfBusinessLicenseNumberTwo:DateBusinessLicense2.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberTwo:values.TypeNameLicense2,
           CustomerBusinessLicenseExpirationDateNumberTwo:LicenseExpirationDate2.toString(),
           CustomerDateOfBusinessLicenseNumberThree:DateBusinessLicense3.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberThree:values.TypeNameLicense3,
           CustomerBusinessLicenseExpirationDateNumberThree:LicenseExpirationDate3.toString(),
           CustomerDateOfBusinessLicenseNumberFour:DateBusinessLicense4.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberFour:values.TypeNameLicense4,
           CustomerBusinessLicenseExpirationDateNumberFour:LicenseExpirationDate4.toString(),
           CustomerDateOfBusinessLicenseNumberFive:DateBusinessLicense5.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberFive:values.TypeNameLicense5,
           CustomerBusinessLicenseExpirationDateNumberFive:LicenseExpirationDate5.toString(),
           CustomerDateOfBusinessLicenseNumberSix:DateBusinessLicense6.toString(),
           CustomerTypeAndNameOfBusinessLicenseNumberSix:values.TypeNameLicense6,
           CustomerBusinessLicenseExpirationDateNumberSix:LicenseExpirationDate6.toString(),
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerWorkExperienceByYear',response.customerWorkExperienceByYear);
        localStorage.setItem('customerDateOfJobNumberOne',response.customerDateOfJobNumberOne);
        localStorage.setItem('customerPostInJobNumberOne',response.customerPostInJobNumberOne);
        localStorage.setItem('customerWorkplaceNumberOne',response.customerWorkplaceNumberOne);
        localStorage.setItem('customerDateOfJobNumberTwo',response.customerDateOfJobNumberTwo);
        localStorage.setItem('customerPostInJobNumberTwo',response.customerPostInJobNumberTwo);
        localStorage.setItem('customerWorkplaceNumberTwo',response.customerWorkplaceNumberTwo);
        localStorage.setItem('customerDateOfJobNumberThree',response.customerDateOfJobNumberThree);
        localStorage.setItem('customerPostInJobNumberThree',response.customerPostInJobNumberThree);
        localStorage.setItem('customerWorkplaceNumberThree',response.customerWorkplaceNumberThree);
        localStorage.setItem('customerDateOfJobNumberFour',response.customerDateOfJobNumberFour);
        localStorage.setItem('customerPostInJobNumberFour',response.customerPostInJobNumberFour);
        localStorage.setItem('customerWorkplaceNumberFour',response.customerWorkplaceNumberFour);
        localStorage.setItem('customerDateOfJobNumberFive',response.customerDateOfJobNumberFive);
        localStorage.setItem('customerPostInJobNumberFive',response.customerPostInJobNumberFive);
        localStorage.setItem('customerWorkplaceNumberFive',response.customerWorkplaceNumberFive);
        localStorage.setItem('customerDateOfJobNumberSix',response.customerDateOfJobNumberSix);
        localStorage.setItem('customerPostInJobNumberSix',response.customerPostInJobNumberSix);
        localStorage.setItem('customerWorkplaceNumberSix',response.customerWorkplaceNumberSix);
        localStorage.setItem('customerDateOfBusinessLicenseNumberOne',response.customerDateOfBusinessLicenseNumberOne);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberOne',response.customerTypeAndNameOfBusinessLicenseNumberOne);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberOne',response.customerBusinessLicenseExpirationDateNumberOne);
        localStorage.setItem('customerDateOfBusinessLicenseNumberTwo',response.customerDateOfBusinessLicenseNumberTwo);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberTwo',response.customerTypeAndNameOfBusinessLicenseNumberTwo);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberTwo',response.customerBusinessLicenseExpirationDateNumberTwo);
        localStorage.setItem('customerDateOfBusinessLicenseNumberThree',response.customerDateOfBusinessLicenseNumberThree);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberThree',response.customerTypeAndNameOfBusinessLicenseNumberThree);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberThree',response.customerBusinessLicenseExpirationDateNumberThree);
        localStorage.setItem('customerDateOfBusinessLicenseNumberFour',response.customerDateOfBusinessLicenseNumberFour);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFour',response.customerTypeAndNameOfBusinessLicenseNumberFour);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberFour',response.customerBusinessLicenseExpirationDateNumberFour);
        localStorage.setItem('customerDateOfBusinessLicenseNumberFive',response.customerDateOfBusinessLicenseNumberFive);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFive',response.customerTypeAndNameOfBusinessLicenseNumberFive);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberFive',response.customerBusinessLicenseExpirationDateNumberFive);
        localStorage.setItem('customerDateOfBusinessLicenseNumberSix',response.customerDateOfBusinessLicenseNumberSix);
        localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberSix',response.customerTypeAndNameOfBusinessLicenseNumberSix);
        localStorage.setItem('customerBusinessLicenseExpirationDateNumberSix',response.customerBusinessLicenseExpirationDateNumberSix);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
    
    values.WorkExperience= localStorage.getItem('CustomerWorkExperienceByYear');
    values.Post1= localStorage.getItem('CustomerPostInJobNumberOne');
    values.Workplace1= localStorage.getItem('CustomerWorkplaceNumberOne');
    values.Post2= localStorage.getItem('CustomerPostInJobNumberTwo');
    values.Workplace2= localStorage.getItem('CustomerWorkplaceNumberTwo');
    values.Post3= localStorage.getItem('CustomerPostInJobNumberThree');
    values.Workplace3= localStorage.getItem('CustomerWorkplaceNumberThree');
    values.Post4= localStorage.getItem('CustomerPostInJobNumberFour');
    values.Workplace4= localStorage.getItem('CustomerWorkplaceNumberFour');
    values.Post5= localStorage.getItem('CustomerPostInJobNumberFive');
    values.Workplace5= localStorage.getItem('CustomerWorkplaceNumberFive');
    values.Post6= localStorage.getItem('CustomerPostInJobNumberSix');
    values.Workplace6= localStorage.getItem('CustomerWorkplaceNumberSix');
    values.TypeNameLicense1= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberOne');
    values.TypeNameLicense2= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberTwo');
    values.TypeNameLicense3= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberThree');
    values.TypeNameLicense4= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberFour');
    values.TypeNameLicense5= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberFive');
    values.TypeNameLicense6= localStorage.getItem('CustomerTypeAndNameOfBusinessLicenseNumberSix');

    setDateofOther3degreeValue(localStorage.getItem('customerOtherLanguageNumberThreeDateOfLanguageDegree'));
    setDateFirstJob(localStorage.getItem('customerDateOfJobNumberOne'));
    setDate2Job(localStorage.getItem('customerDateOfJobNumberTwo'));
    setDate3Job(localStorage.getItem('customerDateOfJobNumberThree'));
    setDate4Job(localStorage.getItem('customerDateOfJobNumberFour'));
    setDate5Job(localStorage.getItem('customerDateOfJobNumberFive'));
    setDate6Job(localStorage.getItem('customerDateOfJobNumberSix'));
    setDateBusinessLicense1(localStorage.getItem('customerDateOfBusinessLicenseNumberOne'));
    setDateBusinessLicense2(localStorage.getItem('customerDateOfBusinessLicenseNumberTwo'));
    setDateBusinessLicense3(localStorage.getItem('customerDateOfBusinessLicenseNumberThree'));
    setDateBusinessLicense4(localStorage.getItem('customerDateOfBusinessLicenseNumberFour'));
    setDateBusinessLicense5(localStorage.getItem('customerDateOfBusinessLicenseNumberFive'));
    setDateBusinessLicense6(localStorage.getItem('customerDateOfBusinessLicenseNumberSix'));
    setLicenseExpirationDate1(localStorage.getItem('customerBusinessLicenseExpirationDateNumberOne'));
    setLicenseExpirationDate2(localStorage.getItem('customerBusinessLicenseExpirationDateNumberTwo'));
    setLicenseExpirationDate3(localStorage.getItem('customerBusinessLicenseExpirationDateNumberThree'));
    setLicenseExpirationDate4(localStorage.getItem('customerBusinessLicenseExpirationDateNumberFour'));
    setLicenseExpirationDate5(localStorage.getItem('customerBusinessLicenseExpirationDateNumberFive'));
    setLicenseExpirationDate6(localStorage.getItem('customerBusinessLicenseExpirationDateNumberSix'));

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
else   if(localStorage.getItem('whoIs')=='sp'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpouseJobInformation' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      
      CustomerSpouseWorkExperienceByYear:values.WorkExperience,
      CustomerSpouseDateOfJobNumberOne:DateFirstJob.toString(),
      CustomerSpousePostInJobNumberOne:values.Post1,
      CustomerSpouseWorkplaceNumberOne:values.Workplace1,
      CustomerSpouseDateOfJobNumberTwo:Date2Job.toString(),
      CustomerSpousePostInJobNumberTwo:values.Post2,
      CustomerSpouseWorkplaceNumberTwo:values.Workplace2,
      CustomerSpouseDateOfJobNumberThree:Date3Job.toString(),
      CustomerSpousePostInJobNumberThree:values.Post3,
      CustomerSpouseWorkplaceNumberThree:values.Workplace3,
      CustomerSpouseDateOfJobNumberFour:Date4Job.toString(),
      CustomerSpousePostInJobNumberFour:values.Post4,
      CustomerSpouseWorkplaceNumberFour:values.Workplace4,
      CustomerSpouseDateOfJobNumberFive:Date5Job.toString(),
      CustomerSpousePostInJobNumberFive:values.Post5,
      CustomerSpouseWorkplaceNumberFive:values.Workplace5,
      CustomerSpouseDateOfJobNumberSix:Date6Job.toString(),
      CustomerSpousePostInJobNumberSix:values.Post6,
      CustomerSpouseWorkplaceNumberSix:values.Workplace6,
      CustomerSpouseDateOfBusinessLicenseNumberOne:DateBusinessLicense1.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberOne:values.TypeNameLicense1,
      CustomerSpouseBusinessLicenseExpirationDateNumberOne:LicenseExpirationDate1.toString(),
      CustomerSpouseDateOfBusinessLicenseNumberTwo:DateBusinessLicense2.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberTwo:values.TypeNameLicense2,
      CustomerSpouseBusinessLicenseExpirationDateNumberTwo:LicenseExpirationDate2.toString(),
      CustomerSpouseDateOfBusinessLicenseNumberThree:DateBusinessLicense3.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberThree:values.TypeNameLicense3,
      CustomerSpouseBusinessLicenseExpirationDateNumberThree:LicenseExpirationDate3.toString(),
      CustomerSpouseDateOfBusinessLicenseNumberFour:DateBusinessLicense4.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberFour:values.TypeNameLicense4,
      CustomerSpouseBusinessLicenseExpirationDateNumberFour:LicenseExpirationDate4.toString(),
      CustomerSpouseDateOfBusinessLicenseNumberFive:DateBusinessLicense5.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberFive:values.TypeNameLicense5,
      CustomerSpouseBusinessLicenseExpirationDateNumberFive:LicenseExpirationDate5.toString(),
      CustomerSpouseDateOfBusinessLicenseNumberSix:DateBusinessLicense6.toString(),
      CustomerSpouseTypeAndNameOfBusinessLicenseNumberSix:values.TypeNameLicense6,
      CustomerSpouseBusinessLicenseExpirationDateNumberSix:LicenseExpirationDate6.toString(),

  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpouseJobInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           CustomerSpouseWorkExperienceByYear:values.WorkExperience,
           CustomerSpouseDateOfJobNumberOne:DateFirstJob.toString(),
           CustomerSpousePostInJobNumberOne:values.Post1,
           CustomerSpouseWorkplaceNumberOne:values.Workplace1,
           CustomerSpouseDateOfJobNumberTwo:Date2Job.toString(),
           CustomerSpousePostInJobNumberTwo:values.Post2,
           CustomerSpouseWorkplaceNumberTwo:values.Workplace2,
           CustomerSpouseDateOfJobNumberThree:Date3Job.toString(),
           CustomerSpousePostInJobNumberThree:values.Post3,
           CustomerSpouseWorkplaceNumberThree:values.Workplace3,
           CustomerSpouseDateOfJobNumberFour:Date4Job.toString(),
           CustomerSpousePostInJobNumberFour:values.Post4,
           CustomerSpouseWorkplaceNumberFour:values.Workplace4,
           CustomerSpouseDateOfJobNumberFive:Date5Job.toString(),
           CustomerSpousePostInJobNumberFive:values.Post5,
           CustomerSpouseWorkplaceNumberFive:values.Workplace5,
           CustomerSpouseDateOfJobNumberSix:Date6Job.toString(),
           CustomerSpousePostInJobNumberSix:values.Post6,
           CustomerSpouseWorkplaceNumberSix:values.Workplace6,
           CustomerSpouseDateOfBusinessLicenseNumberOne:DateBusinessLicense1.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberOne:values.TypeNameLicense1,
           CustomerSpouseBusinessLicenseExpirationDateNumberOne:LicenseExpirationDate1.toString(),
           CustomerSpouseDateOfBusinessLicenseNumberTwo:DateBusinessLicense2.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberTwo:values.TypeNameLicense2,
           CustomerSpouseBusinessLicenseExpirationDateNumberTwo:LicenseExpirationDate2.toString(),
           CustomerSpouseDateOfBusinessLicenseNumberThree:DateBusinessLicense3.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberThree:values.TypeNameLicense3,
           CustomerSpouseBusinessLicenseExpirationDateNumberThree:LicenseExpirationDate3.toString(),
           CustomerSpouseDateOfBusinessLicenseNumberFour:DateBusinessLicense4.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberFour:values.TypeNameLicense4,
           CustomerSpouseBusinessLicenseExpirationDateNumberFour:LicenseExpirationDate4.toString(),
           CustomerSpouseDateOfBusinessLicenseNumberFive:DateBusinessLicense5.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberFive:values.TypeNameLicense5,
           CustomerSpouseBusinessLicenseExpirationDateNumberFive:LicenseExpirationDate5.toString(),
           CustomerSpouseDateOfBusinessLicenseNumberSix:DateBusinessLicense6.toString(),
           CustomerSpouseTypeAndNameOfBusinessLicenseNumberSix:values.TypeNameLicense6,
           CustomerSpouseBusinessLicenseExpirationDateNumberSix:LicenseExpirationDate6.toString(),
   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerSpouseWorkExperienceByYear',response.customerSpouseWorkExperienceByYear);
        localStorage.setItem('customerSpouseDateOfJobNumberOne',response.customerSpouseDateOfJobNumberOne);
        localStorage.setItem('customerSpousePostInJobNumberOne',response.customerSpousePostInJobNumberOne);
        localStorage.setItem('customerSpouseWorkplaceNumberOne',response.customerSpouseWorkplaceNumberOne);
        localStorage.setItem('customerSpouseDateOfJobNumberTwo',response.customerSpouseDateOfJobNumberTwo);
        localStorage.setItem('customerSpousePostInJobNumberTwo',response.customerSpousePostInJobNumberTwo);
        localStorage.setItem('customerSpouseWorkplaceNumberTwo',response.customerSpouseWorkplaceNumberTwo);
        localStorage.setItem('customerSpouseDateOfJobNumberThree',response.customerSpouseDateOfJobNumberThree);
        localStorage.setItem('customerSpousePostInJobNumberThree',response.customerSpousePostInJobNumberThree);
        localStorage.setItem('customerSpouseWorkplaceNumberThree',response.customerSpouseWorkplaceNumberThree);
        localStorage.setItem('customerSpouseDateOfJobNumberFour',response.customerSpouseDateOfJobNumberFour);
        localStorage.setItem('customerSpousePostInJobNumberFour',response.customerSpousePostInJobNumberFour);
        localStorage.setItem('customerSpouseWorkplaceNumberFour',response.customerSpouseWorkplaceNumberFour);
        localStorage.setItem('customerSpouseDateOfJobNumberFive',response.customerSpouseDateOfJobNumberFive);
        localStorage.setItem('customerSpousePostInJobNumberFive',response.customerSpousePostInJobNumberFive);
        localStorage.setItem('customerSpouseWorkplaceNumberFive',response.customerSpouseWorkplaceNumberFive);
        localStorage.setItem('customerSpouseDateOfJobNumberSix',response.customerSpouseDateOfJobNumberSix);
        localStorage.setItem('customerSpousePostInJobNumberSix',response.customerSpousePostInJobNumberSix);
        localStorage.setItem('customerSpouseWorkplaceNumberSix',response.customerSpouseWorkplaceNumberSix);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberOne',response.customerSpouseDateOfBusinessLicenseNumberOne);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberOne',response.customerSpouseTypeAndNameOfBusinessLicenseNumberOne);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberOne',response.customerSpouseBusinessLicenseExpirationDateNumberOne);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberTwo',response.customerSpouseDateOfBusinessLicenseNumberTwo);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberTwo',response.customerSpouseTypeAndNameOfBusinessLicenseNumberTwo);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberTwo',response.customerSpouseBusinessLicenseExpirationDateNumberTwo);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberThree',response.customerSpouseDateOfBusinessLicenseNumberThree);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberThree',response.customerSpouseTypeAndNameOfBusinessLicenseNumberThree);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberThree',response.customerSpouseBusinessLicenseExpirationDateNumberThree);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFour',response.customerSpouseDateOfBusinessLicenseNumberFour);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFour',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFour);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFour',response.customerSpouseBusinessLicenseExpirationDateNumberFour);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFive',response.customerSpouseDateOfBusinessLicenseNumberFive);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFive',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFive);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFive',response.customerSpouseBusinessLicenseExpirationDateNumberFive);
        localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberSix',response.customerSpouseDateOfBusinessLicenseNumberSix);
        localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberSix',response.customerSpouseTypeAndNameOfBusinessLicenseNumberSix);
        localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberSix',response.customerSpouseBusinessLicenseExpirationDateNumberSix);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
    
    values.WorkExperience= localStorage.getItem('CustomerSpouseWorkExperienceByYear');
    values.Post1= localStorage.getItem('CustomerSpousePostInJobNumberOne');
    values.Workplace1= localStorage.getItem('CustomerSpouseWorkplaceNumberOne');
    values.Post2= localStorage.getItem('CustomerSpousePostInJobNumberTwo');
    values.Workplace2= localStorage.getItem('CustomerSpouseWorkplaceNumberTwo');
    values.Post3= localStorage.getItem('CustomerSpousePostInJobNumberThree');
    values.Workplace3= localStorage.getItem('CustomerSpouseWorkplaceNumberThree');
    values.Post4= localStorage.getItem('CustomerSpousePostInJobNumberFour');
    values.Workplace4= localStorage.getItem('CustomerSpouseWorkplaceNumberFour');
    values.Post5= localStorage.getItem('CustomerSpousePostInJobNumberFive');
    values.Workplace5= localStorage.getItem('CustomerSpouseWorkplaceNumberFive');
    values.Post6= localStorage.getItem('CustomerSpousePostInJobNumberSix');
    values.Workplace6= localStorage.getItem('CustomerSpouseWorkplaceNumberSix');
    values.TypeNameLicense1= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberOne');
    values.TypeNameLicense2= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberTwo');
    values.TypeNameLicense3= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberThree');
    values.TypeNameLicense4= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberFour');
    values.TypeNameLicense5= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberFive');
    values.TypeNameLicense6= localStorage.getItem('CustomerSpouseTypeAndNameOfBusinessLicenseNumberSix');

    setDateofOther3degreeValue(localStorage.getItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree'));
    setDateFirstJob(localStorage.getItem('customerSpouseDateOfJobNumberOne'));
    setDate2Job(localStorage.getItem('customerSpouseDateOfJobNumberTwo'));
    setDate3Job(localStorage.getItem('customerSpouseDateOfJobNumberThree'));
    setDate4Job(localStorage.getItem('customerSpouseDateOfJobNumberFour'));
    setDate5Job(localStorage.getItem('customerSpouseDateOfJobNumberFive'));
    setDate6Job(localStorage.getItem('customerSpouseDateOfJobNumberSix'));
    setDateBusinessLicense1(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberOne'));
    setDateBusinessLicense2(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberTwo'));
    setDateBusinessLicense3(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberThree'));
    setDateBusinessLicense4(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberFour'));
    setDateBusinessLicense5(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberFive'));
    setDateBusinessLicense6(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberSix'));
    setLicenseExpirationDate1(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberOne'));
    setLicenseExpirationDate2(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberTwo'));
    setLicenseExpirationDate3(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberThree'));
    setLicenseExpirationDate4(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberFour'));
    setLicenseExpirationDate5(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberFive'));
    setLicenseExpirationDate6(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberSix'));

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          ProPic:'',
          // Country:'Iran',
          // City:'Tehran',
          // Address:'Iran - Tehran - Vanak - Zafar ',
          // ZipCode:'5174875988',
          // proNo:'61',
          // Level:'',
          // FieldOfStudy:'',
          // University:'',
          // JobCountry:'USA',
          // JobCity:'',
          // Jobspecialty:'',
          JobUpload:'',
          // iccrc:'',
          // website:'',
          // Specialization:'',
          // Certificate:'',
          // userNameLast:'setin',
          // showPassword: false,
          TypeNameLicense1: '',
          TypeNameLicense2: '',
          TypeNameLicense3: '',
          TypeNameLicense4: '',
          TypeNameLicense5: '',
          TypeNameLicense6: '',
          proLastName: '',
          Gender: '',
          IDNumber:'',
          NationalCode:'',
          birthDate: '',
          proEmail: '',
          proPhone: '',
          proCallNumber:"",
          proPhone2: '',
          proCallNumber2:"",
          proAbout: '',
          firstTimeEdit:'',
          firstTimeEditJob:'',
          firstTimeEditEducational:'',
          firstTimeEditAddress:true,
          firstTimeEditPersonal:true,
          // ProPic: '',
          Post1: '',
          Workplace1: '',
          Post2: '',
          Workplace2: '',
          Post3: '',
          Workplace3: '',
          Post4: '',
          Workplace4: '',
          Post5: '',
          Workplace5: '',
          Post6: '',
          Workplace6: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          WorkExperience:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
          BirthCertificateNumber:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentJobInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  localStorage.setItem('customerWorkExperienceByYear',response.customerWorkExperienceByYear);
  localStorage.setItem('customerDateOfJobNumberOne',response.customerDateOfJobNumberOne);
  localStorage.setItem('customerPostInJobNumberOne',response.customerPostInJobNumberOne);
  localStorage.setItem('customerWorkplaceNumberOne',response.customerWorkplaceNumberOne);
  localStorage.setItem('customerDateOfJobNumberTwo',response.customerDateOfJobNumberTwo);
  localStorage.setItem('customerPostInJobNumberTwo',response.customerPostInJobNumberTwo);
  localStorage.setItem('customerWorkplaceNumberTwo',response.customerWorkplaceNumberTwo);
  localStorage.setItem('customerDateOfJobNumberThree',response.customerDateOfJobNumberThree);
  localStorage.setItem('customerPostInJobNumberThree',response.customerPostInJobNumberThree);
  localStorage.setItem('customerWorkplaceNumberThree',response.customerWorkplaceNumberThree);
  localStorage.setItem('customerDateOfJobNumberFour',response.customerDateOfJobNumberFour);
  localStorage.setItem('customerPostInJobNumberFour',response.customerPostInJobNumberFour);
  localStorage.setItem('customerWorkplaceNumberFour',response.customerWorkplaceNumberFour);
  localStorage.setItem('customerDateOfJobNumberFive',response.customerDateOfJobNumberFive);
  localStorage.setItem('customerPostInJobNumberFive',response.customerPostInJobNumberFive);
  localStorage.setItem('customerWorkplaceNumberFive',response.customerWorkplaceNumberFive);
  localStorage.setItem('customerDateOfJobNumberSix',response.customerDateOfJobNumberSix);
  localStorage.setItem('customerPostInJobNumberSix',response.customerPostInJobNumberSix);
  localStorage.setItem('customerWorkplaceNumberSix',response.customerWorkplaceNumberSix);
  localStorage.setItem('customerDateOfBusinessLicenseNumberOne',response.customerDateOfBusinessLicenseNumberOne);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberOne',response.customerTypeAndNameOfBusinessLicenseNumberOne);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberOne',response.customerBusinessLicenseExpirationDateNumberOne);
  localStorage.setItem('customerDateOfBusinessLicenseNumberTwo',response.customerDateOfBusinessLicenseNumberTwo);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberTwo',response.customerTypeAndNameOfBusinessLicenseNumberTwo);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberTwo',response.customerBusinessLicenseExpirationDateNumberTwo);
  localStorage.setItem('customerDateOfBusinessLicenseNumberThree',response.customerDateOfBusinessLicenseNumberThree);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberThree',response.customerTypeAndNameOfBusinessLicenseNumberThree);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberThree',response.customerBusinessLicenseExpirationDateNumberThree);
  localStorage.setItem('customerDateOfBusinessLicenseNumberFour',response.customerDateOfBusinessLicenseNumberFour);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFour',response.customerTypeAndNameOfBusinessLicenseNumberFour);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberFour',response.customerBusinessLicenseExpirationDateNumberFour);
  localStorage.setItem('customerDateOfBusinessLicenseNumberFive',response.customerDateOfBusinessLicenseNumberFive);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFive',response.customerTypeAndNameOfBusinessLicenseNumberFive);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberFive',response.customerBusinessLicenseExpirationDateNumberFive);
  localStorage.setItem('customerDateOfBusinessLicenseNumberSix',response.customerDateOfBusinessLicenseNumberSix);
  localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberSix',response.customerTypeAndNameOfBusinessLicenseNumberSix);
  localStorage.setItem('customerBusinessLicenseExpirationDateNumberSix',response.customerBusinessLicenseExpirationDateNumberSix);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentJobInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
  
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerWorkExperienceByYear',response.customerWorkExperienceByYear);
      localStorage.setItem('customerDateOfJobNumberOne',response.customerDateOfJobNumberOne);
      localStorage.setItem('customerPostInJobNumberOne',response.customerPostInJobNumberOne);
      localStorage.setItem('customerWorkplaceNumberOne',response.customerWorkplaceNumberOne);
      localStorage.setItem('customerDateOfJobNumberTwo',response.customerDateOfJobNumberTwo);
      localStorage.setItem('customerPostInJobNumberTwo',response.customerPostInJobNumberTwo);
      localStorage.setItem('customerWorkplaceNumberTwo',response.customerWorkplaceNumberTwo);
      localStorage.setItem('customerDateOfJobNumberThree',response.customerDateOfJobNumberThree);
      localStorage.setItem('customerPostInJobNumberThree',response.customerPostInJobNumberThree);
      localStorage.setItem('customerWorkplaceNumberThree',response.customerWorkplaceNumberThree);
      localStorage.setItem('customerDateOfJobNumberFour',response.customerDateOfJobNumberFour);
      localStorage.setItem('customerPostInJobNumberFour',response.customerPostInJobNumberFour);
      localStorage.setItem('customerWorkplaceNumberFour',response.customerWorkplaceNumberFour);
      localStorage.setItem('customerDateOfJobNumberFive',response.customerDateOfJobNumberFive);
      localStorage.setItem('customerPostInJobNumberFive',response.customerPostInJobNumberFive);
      localStorage.setItem('customerWorkplaceNumberFive',response.customerWorkplaceNumberFive);
      localStorage.setItem('customerDateOfJobNumberSix',response.customerDateOfJobNumberSix);
      localStorage.setItem('customerPostInJobNumberSix',response.customerPostInJobNumberSix);
      localStorage.setItem('customerWorkplaceNumberSix',response.customerWorkplaceNumberSix);
      localStorage.setItem('customerDateOfBusinessLicenseNumberOne',response.customerDateOfBusinessLicenseNumberOne);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberOne',response.customerTypeAndNameOfBusinessLicenseNumberOne);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberOne',response.customerBusinessLicenseExpirationDateNumberOne);
      localStorage.setItem('customerDateOfBusinessLicenseNumberTwo',response.customerDateOfBusinessLicenseNumberTwo);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberTwo',response.customerTypeAndNameOfBusinessLicenseNumberTwo);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberTwo',response.customerBusinessLicenseExpirationDateNumberTwo);
      localStorage.setItem('customerDateOfBusinessLicenseNumberThree',response.customerDateOfBusinessLicenseNumberThree);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberThree',response.customerTypeAndNameOfBusinessLicenseNumberThree);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberThree',response.customerBusinessLicenseExpirationDateNumberThree);
      localStorage.setItem('customerDateOfBusinessLicenseNumberFour',response.customerDateOfBusinessLicenseNumberFour);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFour',response.customerTypeAndNameOfBusinessLicenseNumberFour);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberFour',response.customerBusinessLicenseExpirationDateNumberFour);
      localStorage.setItem('customerDateOfBusinessLicenseNumberFive',response.customerDateOfBusinessLicenseNumberFive);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFive',response.customerTypeAndNameOfBusinessLicenseNumberFive);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberFive',response.customerBusinessLicenseExpirationDateNumberFive);
      localStorage.setItem('customerDateOfBusinessLicenseNumberSix',response.customerDateOfBusinessLicenseNumberSix);
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberSix',response.customerTypeAndNameOfBusinessLicenseNumberSix);
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberSix',response.customerBusinessLicenseExpirationDateNumberSix);

     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerWorkExperienceByYear','');
      localStorage.setItem('customerDateOfJobNumberOne','');
      localStorage.setItem('customerPostInJobNumberOne','');
      localStorage.setItem('customerWorkplaceNumberOne','');
      localStorage.setItem('customerDateOfJobNumberTwo','');
      localStorage.setItem('customerPostInJobNumberTwo','');
      localStorage.setItem('customerWorkplaceNumberTwo','');
      localStorage.setItem('customerDateOfJobNumberThree','');
      localStorage.setItem('customerPostInJobNumberThree','');
      localStorage.setItem('customerWorkplaceNumberThree','');
      localStorage.setItem('customerDateOfJobNumberFour','');
      localStorage.setItem('customerPostInJobNumberFour','');
      localStorage.setItem('customerWorkplaceNumberFour','');
      localStorage.setItem('customerDateOfJobNumberFive','');
      localStorage.setItem('customerPostInJobNumberFive','');
      localStorage.setItem('customerWorkplaceNumberFive','');
      localStorage.setItem('customerDateOfJobNumberSix','');
      localStorage.setItem('customerPostInJobNumberSix','');
      localStorage.setItem('customerWorkplaceNumberSix','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberOne','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberOne','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberOne','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberTwo','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberTwo','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberTwo','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberThree','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberThree','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberThree','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberFour','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFour','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberFour','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberFive','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberFive','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberFive','');
      localStorage.setItem('customerDateOfBusinessLicenseNumberSix','');
      localStorage.setItem('customerTypeAndNameOfBusinessLicenseNumberSix','');
      localStorage.setItem('customerBusinessLicenseExpirationDateNumberSix','');

    
   
    })
    })
  } else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseJobInformation' , {
          method: 'POST',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
          body: JSON.stringify({ Email: localStorage.getItem('email')})
        }).then(response => response.json()).then(response=>{
    
          // setIspending(false);
      //  setValues();
      
      localStorage.setItem('customerSpouseWorkExperienceByYear',response.customerSpouseWorkExperienceByYear);
      localStorage.setItem('customerSpouseDateOfJobNumberOne',response.customerSpouseDateOfJobNumberOne);
      localStorage.setItem('customerSpousePostInJobNumberOne',response.customerSpousePostInJobNumberOne);
      localStorage.setItem('customerSpouseWorkplaceNumberOne',response.customerSpouseWorkplaceNumberOne);
      localStorage.setItem('customerSpouseDateOfJobNumberTwo',response.customerSpouseDateOfJobNumberTwo);
      localStorage.setItem('customerSpousePostInJobNumberTwo',response.customerSpousePostInJobNumberTwo);
      localStorage.setItem('customerSpouseWorkplaceNumberTwo',response.customerSpouseWorkplaceNumberTwo);
      localStorage.setItem('customerSpouseDateOfJobNumberThree',response.customerSpouseDateOfJobNumberThree);
      localStorage.setItem('customerSpousePostInJobNumberThree',response.customerSpousePostInJobNumberThree);
      localStorage.setItem('customerSpouseWorkplaceNumberThree',response.customerSpouseWorkplaceNumberThree);
      localStorage.setItem('customerSpouseDateOfJobNumberFour',response.customerSpouseDateOfJobNumberFour);
      localStorage.setItem('customerSpousePostInJobNumberFour',response.customerSpousePostInJobNumberFour);
      localStorage.setItem('customerSpouseWorkplaceNumberFour',response.customerSpouseWorkplaceNumberFour);
      localStorage.setItem('customerSpouseDateOfJobNumberFive',response.customerSpouseDateOfJobNumberFive);
      localStorage.setItem('customerSpousePostInJobNumberFive',response.customerSpousePostInJobNumberFive);
      localStorage.setItem('customerSpouseWorkplaceNumberFive',response.customerSpouseWorkplaceNumberFive);
      localStorage.setItem('customerSpouseDateOfJobNumberSix',response.customerSpouseDateOfJobNumberSix);
      localStorage.setItem('customerSpousePostInJobNumberSix',response.customerSpousePostInJobNumberSix);
      localStorage.setItem('customerSpouseWorkplaceNumberSix',response.customerSpouseWorkplaceNumberSix);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberOne',response.customerSpouseDateOfBusinessLicenseNumberOne);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberOne',response.customerSpouseTypeAndNameOfBusinessLicenseNumberOne);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberOne',response.customerSpouseBusinessLicenseExpirationDateNumberOne);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberTwo',response.customerSpouseDateOfBusinessLicenseNumberTwo);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberTwo',response.customerSpouseTypeAndNameOfBusinessLicenseNumberTwo);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberTwo',response.customerSpouseBusinessLicenseExpirationDateNumberTwo);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberThree',response.customerSpouseDateOfBusinessLicenseNumberThree);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberThree',response.customerSpouseTypeAndNameOfBusinessLicenseNumberThree);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberThree',response.customerSpouseBusinessLicenseExpirationDateNumberThree);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFour',response.customerSpouseDateOfBusinessLicenseNumberFour);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFour',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFour);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFour',response.customerSpouseBusinessLicenseExpirationDateNumberFour);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFive',response.customerSpouseDateOfBusinessLicenseNumberFive);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFive',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFive);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFive',response.customerSpouseBusinessLicenseExpirationDateNumberFive);
      localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberSix',response.customerSpouseDateOfBusinessLicenseNumberSix);
      localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberSix',response.customerSpouseTypeAndNameOfBusinessLicenseNumberSix);
      localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberSix',response.customerSpouseBusinessLicenseExpirationDateNumberSix);
      
    
        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
        }).catch(()=>{
          fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseJobInformation' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
          }).then(response => response.json()).then(response=>{
      
            // setIspending(false);
         if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
         {
          console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
          
          localStorage.setItem('customerSpouseWorkExperienceByYear',response.customerSpouseWorkExperienceByYear);
          localStorage.setItem('customerSpouseDateOfJobNumberOne',response.customerSpouseDateOfJobNumberOne);
          localStorage.setItem('customerSpousePostInJobNumberOne',response.customerSpousePostInJobNumberOne);
          localStorage.setItem('customerSpouseWorkplaceNumberOne',response.customerSpouseWorkplaceNumberOne);
          localStorage.setItem('customerSpouseDateOfJobNumberTwo',response.customerSpouseDateOfJobNumberTwo);
          localStorage.setItem('customerSpousePostInJobNumberTwo',response.customerSpousePostInJobNumberTwo);
          localStorage.setItem('customerSpouseWorkplaceNumberTwo',response.customerSpouseWorkplaceNumberTwo);
          localStorage.setItem('customerSpouseDateOfJobNumberThree',response.customerSpouseDateOfJobNumberThree);
          localStorage.setItem('customerSpousePostInJobNumberThree',response.customerSpousePostInJobNumberThree);
          localStorage.setItem('customerSpouseWorkplaceNumberThree',response.customerSpouseWorkplaceNumberThree);
          localStorage.setItem('customerSpouseDateOfJobNumberFour',response.customerSpouseDateOfJobNumberFour);
          localStorage.setItem('customerSpousePostInJobNumberFour',response.customerSpousePostInJobNumberFour);
          localStorage.setItem('customerSpouseWorkplaceNumberFour',response.customerSpouseWorkplaceNumberFour);
          localStorage.setItem('customerSpouseDateOfJobNumberFive',response.customerSpouseDateOfJobNumberFive);
          localStorage.setItem('customerSpousePostInJobNumberFive',response.customerSpousePostInJobNumberFive);
          localStorage.setItem('customerSpouseWorkplaceNumberFive',response.customerSpouseWorkplaceNumberFive);
          localStorage.setItem('customerSpouseDateOfJobNumberSix',response.customerSpouseDateOfJobNumberSix);
          localStorage.setItem('customerSpousePostInJobNumberSix',response.customerSpousePostInJobNumberSix);
          localStorage.setItem('customerSpouseWorkplaceNumberSix',response.customerSpouseWorkplaceNumberSix);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberOne',response.customerSpouseDateOfBusinessLicenseNumberOne);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberOne',response.customerSpouseTypeAndNameOfBusinessLicenseNumberOne);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberOne',response.customerSpouseBusinessLicenseExpirationDateNumberOne);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberTwo',response.customerSpouseDateOfBusinessLicenseNumberTwo);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberTwo',response.customerSpouseTypeAndNameOfBusinessLicenseNumberTwo);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberTwo',response.customerSpouseBusinessLicenseExpirationDateNumberTwo);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberThree',response.customerSpouseDateOfBusinessLicenseNumberThree);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberThree',response.customerSpouseTypeAndNameOfBusinessLicenseNumberThree);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberThree',response.customerSpouseBusinessLicenseExpirationDateNumberThree);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFour',response.customerSpouseDateOfBusinessLicenseNumberFour);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFour',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFour);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFour',response.customerSpouseBusinessLicenseExpirationDateNumberFour);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFive',response.customerSpouseDateOfBusinessLicenseNumberFive);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFive',response.customerSpouseTypeAndNameOfBusinessLicenseNumberFive);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFive',response.customerSpouseBusinessLicenseExpirationDateNumberFive);
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberSix',response.customerSpouseDateOfBusinessLicenseNumberSix);
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberSix',response.customerSpouseTypeAndNameOfBusinessLicenseNumberSix);
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberSix',response.customerSpouseBusinessLicenseExpirationDateNumberSix);
    
         
      
        }
         
        }).catch(()=>{
          
          localStorage.setItem('customerSpouseWorkExperienceByYear','');
          localStorage.setItem('customerSpouseDateOfJobNumberOne','');
          localStorage.setItem('customerSpousePostInJobNumberOne','');
          localStorage.setItem('customerSpouseWorkplaceNumberOne','');
          localStorage.setItem('customerSpouseDateOfJobNumberTwo','');
          localStorage.setItem('customerSpousePostInJobNumberTwo','');
          localStorage.setItem('customerSpouseWorkplaceNumberTwo','');
          localStorage.setItem('customerSpouseDateOfJobNumberThree','');
          localStorage.setItem('customerSpousePostInJobNumberThree','');
          localStorage.setItem('customerSpouseWorkplaceNumberThree','');
          localStorage.setItem('customerSpouseDateOfJobNumberFour','');
          localStorage.setItem('customerSpousePostInJobNumberFour','');
          localStorage.setItem('customerSpouseWorkplaceNumberFour','');
          localStorage.setItem('customerSpouseDateOfJobNumberFive','');
          localStorage.setItem('customerSpousePostInJobNumberFive','');
          localStorage.setItem('customerSpouseWorkplaceNumberFive','');
          localStorage.setItem('customerSpouseDateOfJobNumberSix','');
          localStorage.setItem('customerSpousePostInJobNumberSix','');
          localStorage.setItem('customerSpouseWorkplaceNumberSix','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberOne','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberOne','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberOne','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberTwo','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberTwo','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberTwo','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberThree','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberThree','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberThree','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFour','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFour','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFour','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberFive','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFive','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberFive','');
          localStorage.setItem('customerSpouseDateOfBusinessLicenseNumberSix','');
          localStorage.setItem('customerSpouseTypeAndNameOfBusinessLicenseNumberSix','');
          localStorage.setItem('customerSpouseBusinessLicenseExpirationDateNumberSix','');
    
        
       
        })
        })
      }
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }

     
      if(localStorage.getItem('whoIs')=='ap'){
      values.EnglishLanguageStatus =localStorage.getItem('customerEnglishLanguageStatus');

      values.WorkExperience =localStorage.getItem('customerWorkExperienceByYear');
      
      values.Post1 =localStorage.getItem('customerPostInJobNumberOne');
      values.Workplace1 =localStorage.getItem('customerWorkplaceNumberOne');
      
      values.Post2 =localStorage.getItem('customerPostInJobNumberTwo');
      values.Workplace2 =localStorage.getItem('customerWorkplaceNumberTwo');
      
      values.Post3 =localStorage.getItem('customerPostInJobNumberThree');
      values.Workplace3 =localStorage.getItem('customerWorkplaceNumberThree');
      
      values.Post4 =localStorage.getItem('customerPostInJobNumberFour');
      values.Workplace4 =localStorage.getItem('customerWorkplaceNumberFour');
      
      values.Post5 =localStorage.getItem('customerPostInJobNumberFive');
      values.Workplace5 =localStorage.getItem('customerWorkplaceNumberFive');
      
      values.Post6 =localStorage.getItem('customerPostInJobNumberSix');
      values.Workplace6 =localStorage.getItem('customerWorkplaceNumberSix');
      
      values.TypeNameLicense1 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberOne');
      
      
      values.TypeNameLicense2 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberTwo');
      
      
      values.TypeNameLicense3 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberThree');
      
      
      values.TypeNameLicense4 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberFour');
      
      
      values.TypeNameLicense5 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberFive');
      
      
      values.TypeNameLicense6 =localStorage.getItem('customerTypeAndNameOfBusinessLicenseNumberSix');
      
      

      setDateofOther3degreeValue(localStorage.getItem('customerOtherLanguageNumberThreeDateOfLanguageDegree'));
      setDateFirstJob(localStorage.getItem('customerDateOfJobNumberOne'));
      setDate2Job(localStorage.getItem('customerDateOfJobNumberTwo'));
      setDate3Job(localStorage.getItem('customerDateOfJobNumberThree'));
      setDate4Job(localStorage.getItem('customerDateOfJobNumberFour'));
      setDate5Job(localStorage.getItem('customerDateOfJobNumberFive'));
      setDate6Job(localStorage.getItem('customerDateOfJobNumberSix'));
      setDateBusinessLicense1(localStorage.getItem('customerDateOfBusinessLicenseNumberOne'));
      setDateBusinessLicense2(localStorage.getItem('customerDateOfBusinessLicenseNumberTwo'));
      setDateBusinessLicense3(localStorage.getItem('customerDateOfBusinessLicenseNumberThree'));
      setDateBusinessLicense4(localStorage.getItem('customerDateOfBusinessLicenseNumberFour'));
      setDateBusinessLicense5(localStorage.getItem('customerDateOfBusinessLicenseNumberFive'));
      setDateBusinessLicense6(localStorage.getItem('customerDateOfBusinessLicenseNumberSix'));
      setLicenseExpirationDate1(localStorage.getItem('customerBusinessLicenseExpirationDateNumberOne'));
      setLicenseExpirationDate2(localStorage.getItem('customerBusinessLicenseExpirationDateNumberTwo'));
      setLicenseExpirationDate3(localStorage.getItem('customerBusinessLicenseExpirationDateNumberThree'));
      setLicenseExpirationDate4(localStorage.getItem('customerBusinessLicenseExpirationDateNumberFour'));
      setLicenseExpirationDate5(localStorage.getItem('customerBusinessLicenseExpirationDateNumberFive'));
      setLicenseExpirationDate6(localStorage.getItem('customerBusinessLicenseExpirationDateNumberSix'));
      }
      else if(localStorage.getItem('whoIs')=='sp'){
        values.EnglishLanguageStatus =localStorage.getItem('customerSpouseEnglishLanguageStatus');

        values.WorkExperience =localStorage.getItem('customerSpouseWorkExperienceByYear');
        
        values.Post1 =localStorage.getItem('customerSpousePostInJobNumberOne');
        values.Workplace1 =localStorage.getItem('customerSpouseWorkplaceNumberOne');
        
        values.Post2 =localStorage.getItem('customerSpousePostInJobNumberTwo');
        values.Workplace2 =localStorage.getItem('customerSpouseWorkplaceNumberTwo');
        
        values.Post3 =localStorage.getItem('customerSpousePostInJobNumberThree');
        values.Workplace3 =localStorage.getItem('customerSpouseWorkplaceNumberThree');
        
        values.Post4 =localStorage.getItem('customerSpousePostInJobNumberFour');
        values.Workplace4 =localStorage.getItem('customerSpouseWorkplaceNumberFour');
        
        values.Post5 =localStorage.getItem('customerSpousePostInJobNumberFive');
        values.Workplace5 =localStorage.getItem('customerSpouseWorkplaceNumberFive');
        
        values.Post6 =localStorage.getItem('customerSpousePostInJobNumberSix');
        values.Workplace6 =localStorage.getItem('customerSpouseWorkplaceNumberSix');
        
        values.TypeNameLicense1 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberOne');
        
        
        values.TypeNameLicense2 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberTwo');
        
        
        values.TypeNameLicense3 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberThree');
        
        
        values.TypeNameLicense4 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFour');
        
        
        values.TypeNameLicense5 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberFive');
        
        
        values.TypeNameLicense6 =localStorage.getItem('customerSpouseTypeAndNameOfBusinessLicenseNumberSix');
        
        
  
        setDateofOther3degreeValue(localStorage.getItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree'));
        setDateFirstJob(localStorage.getItem('customerSpouseDateOfJobNumberOne'));
        setDate2Job(localStorage.getItem('customerSpouseDateOfJobNumberTwo'));
        setDate3Job(localStorage.getItem('customerSpouseDateOfJobNumberThree'));
        setDate4Job(localStorage.getItem('customerSpouseDateOfJobNumberFour'));
        setDate5Job(localStorage.getItem('customerSpouseDateOfJobNumberFive'));
        setDate6Job(localStorage.getItem('customerSpouseDateOfJobNumberSix'));
        setDateBusinessLicense1(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberOne'));
        setDateBusinessLicense2(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberTwo'));
        setDateBusinessLicense3(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberThree'));
        setDateBusinessLicense4(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberFour'));
        setDateBusinessLicense5(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberFive'));
        setDateBusinessLicense6(localStorage.getItem('customerSpouseDateOfBusinessLicenseNumberSix'));
        setLicenseExpirationDate1(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberOne'));
        setLicenseExpirationDate2(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberTwo'));
        setLicenseExpirationDate3(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberThree'));
        setLicenseExpirationDate4(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberFour'));
        setLicenseExpirationDate5(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberFive'));
        setLicenseExpirationDate6(localStorage.getItem('customerSpouseBusinessLicenseExpirationDateNumberSix'));
      }
        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


            const [image13 , setImage13] = useState(null);
            const   onImage13Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader13 = new FileReader();
                  reader13.onload = (e) => {
                    setImage13(e.target.result);
                    document.getElementById('target13').style.display ="block";
                    document.getElementById('MyGrayBox13').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader13.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage13 = () =>{
                document.getElementById('target13').style.display ="none";
                document.getElementById('MyGrayBox13').style.display= "flex";
              }


          const [image14 , setImage14] = useState(null);
        const   onImage14Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader14 = new FileReader();
              reader14.onload = (e) => {
                setImage14(e.target.result);
                document.getElementById('target14').style.display ="block";
                document.getElementById('MyGrayBox14').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader14.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage14 = () =>{
            document.getElementById('target14').style.display ="none";
            document.getElementById('MyGrayBox14').style.display= "flex";
          }
                
        const [DateFirstJob, setDateFirstJob] = useState()
        const [Date2Job, setDate2Job] = useState()
        const [Date3Job, setDate3Job] = useState()
        const [Date4Job, setDate4Job] = useState()
        const [Date5Job, setDate5Job] = useState()
        const [Date6Job, setDate6Job] = useState()
        const [DateBusinessLicense1, setDateBusinessLicense1] = useState()
        const [DateBusinessLicense2, setDateBusinessLicense2] = useState()
        const [DateBusinessLicense3, setDateBusinessLicense3] = useState()
        const [DateBusinessLicense4, setDateBusinessLicense4] = useState()
        const [DateBusinessLicense5, setDateBusinessLicense5] = useState()
        const [DateBusinessLicense6, setDateBusinessLicense6] = useState()
        const [LicenseExpirationDate1, setLicenseExpirationDate1] = useState()
        const [LicenseExpirationDate2, setLicenseExpirationDate2] = useState()
        const [LicenseExpirationDate3, setLicenseExpirationDate3] = useState()
        const [LicenseExpirationDate4, setLicenseExpirationDate4] = useState()
        const [LicenseExpirationDate5, setLicenseExpirationDate5] = useState()
        const [LicenseExpirationDate6, setLicenseExpirationDate6] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 3')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Job Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Spouse Job Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Work Experience')}</p> </div>
          
          <div className="Cus2Inputs">

          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="WorkExperience" 
                value={values.WorkExperience}
                type="text"
                placeholder={t('Work Experience by year')}
                onChange={handleChange('WorkExperience')}
                required
                />
                <label htmlFor="WorkExperience">{t('Enter the applicant’s Work experience by year')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>

           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Job')}</p> 
          
          </div>
         

            


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateFirstJob}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of First Job')}
              id="DateFirstJob"
              onChange={setDateFirstJob} 
              className="col-12"
            />}
              <label htmlFor="DateFirstJob">{t('Enter the applicant’s Date of First Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post1" 
                value={values.Post1}
                type="text"
                placeholder={t('Post (1)')}
                onChange={handleChange('Post1')}
                />
                <label htmlFor="Post1">{t('Enter the applicant’s Post in Job (1)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace1" 
                value={values.Workplace1}
                type="text"
                placeholder={t('Workplace (1)')}
                onChange={handleChange('Workplace1')}
                />
                <label htmlFor="Workplace1">{t('Enter the applicant’s Workplace (1)')}</label>
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Date2Job}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of 2nd Job')}
              id="Date2Job"
              onChange={setDate2Job} 
              className="col-12"
            />}
              <label htmlFor="Date2Job">{t('Enter the applicant’s Date of 2nd Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post2" 
                value={values.Post2}
                type="text"
                placeholder={t('Post (2)')}
                onChange={handleChange('Post2')}
                />
                <label htmlFor="Post2">{t('Enter the applicant’s Post in Job (2)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace2" 
                value={values.Workplace2}
                type="text"
                placeholder={t('Workplace (2)')}
                onChange={handleChange('Workplace2')}
                />
                <label htmlFor="Workplace2">{t('Enter the applicant’s Workplace (2)')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Date3Job}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of 3rd Job')}
              id="Date3Job"
              onChange={setDate3Job} 
              className="col-12"
            />}
              <label htmlFor="Date3Job">{t('Enter the applicant’s Date of 3rd Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post3" 
                value={values.Post3}
                type="text"
                placeholder={t('Post (3)')}
                onChange={handleChange('Post3')}
                />
                <label htmlFor="Post3">{t('Enter the applicant’s Post in Job (3)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace3" 
                value={values.Workplace3}
                type="text"
                placeholder={t('Workplace (3)')}
                onChange={handleChange('Workplace3')}
                />
                <label htmlFor="Workplace3">{t('Enter the applicant’s Workplace (3)')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Date4Job}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of 4th Job')}
              id="Date4Job"
              onChange={setDate4Job} 
              className="col-12"
            />}
              <label htmlFor="Date4Job">{t('Enter the applicant’s Date of 4th Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post4" 
                value={values.Post4}
                type="text"
                placeholder={t('Post (4)')}
                onChange={handleChange('Post4')}
                />
                <label htmlFor="Post4">{t('Enter the applicant’s Post in Job (4)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace4" 
                value={values.Workplace4}
                type="text"
                placeholder={t('Workplace (4)')}
                onChange={handleChange('Workplace4')}
                />
                <label htmlFor="Workplace4">{t('Enter the applicant’s Workplace (4)')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Date5Job}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of 5th Job')}
              id="Date5Job"
              onChange={setDate5Job} 
              className="col-12"
            />}
              <label htmlFor="Date5Job">{t('Enter the applicant’s Date of 5th Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post5" 
                value={values.Post5}
                type="text"
                placeholder={t('Post (5)')}
                onChange={handleChange('Post5')}
                />
                <label htmlFor="Post5">{t('Enter the applicant’s Post in Job (5)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace5" 
                value={values.Workplace5}
                type="text"
                placeholder={t('Workplace (5)')}
                onChange={handleChange('Workplace5')}
                />
                <label htmlFor="Workplace5">{t('Enter the applicant’s Workplace (5)')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Date6Job}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of 6th Job')}
              id="Date6Job"
              onChange={setDate6Job} 
              className="col-12"
            />}
              <label htmlFor="Date6Job">{t('Enter the applicant’s Date of 6th Job')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Post6" 
                value={values.Post6}
                type="text"
                placeholder={t('Post (6)')}
                onChange={handleChange('Post6')}
                />
                <label htmlFor="Post6">{t('Enter the applicant’s Post in Job (6)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Workplace6" 
                value={values.Workplace6}
                type="text"
                placeholder={t('Workplace (6)')}
                onChange={handleChange('Workplace6')}
                />
                <label htmlFor="Workplace6">{t('Enter the applicant’s Workplace (6)')}</label>
            </div>
            
  
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Permissions')}</p> 
          
          </div>
          
       
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (1)')}
              id="DateBusinessLicense1"
              onChange={setDateBusinessLicense1} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense1">{t('Enter the applicant’s Date of business license (1)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense1" 
                value={values.TypeNameLicense1}
                type="text"
                placeholder={t('Type and Name of License (1)')}
                onChange={handleChange('TypeNameLicense1')}
                />
                <label htmlFor="TypeNameLicense1">{t('Enter the applicant’s Type and Name of License (1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate1}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (1)')}
              id="LicenseExpirationDate1"
              onChange={setLicenseExpirationDate1} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate1">{t('Enter the applicant’s License Expiration Date (1)')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (2)')}
              id="DateBusinessLicense2"
              onChange={setDateBusinessLicense2} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense2">{t('Enter the applicant’s Date of business license (2)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense2" 
                value={values.TypeNameLicense2}
                type="text"
                placeholder={t('Type and Name of License (2)')}
                onChange={handleChange('TypeNameLicense2')}
                />
                <label htmlFor="TypeNameLicense2">{t('Enter the applicant’s Type and Name of License (2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate2}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (2)')}
              id="LicenseExpirationDate2"
              onChange={setLicenseExpirationDate2} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate2">{t('Enter the applicant’s License Expiration Date (2)')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (3)')}
              id="DateBusinessLicense3"
              onChange={setDateBusinessLicense3} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense3">{t('Enter the applicant’s Date of business license (3)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense3" 
                value={values.TypeNameLicense3}
                type="text"
                placeholder={t('Type and Name of License (3)')}
                onChange={handleChange('TypeNameLicense3')}
                />
                <label htmlFor="TypeNameLicense3">{t('Enter the applicant’s Type and Name of License (3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate3}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (3)')}
              id="LicenseExpirationDate3"
              onChange={setLicenseExpirationDate3} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate3">{t('Enter the applicant’s License Expiration Date (3)')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (4)')}
              id="DateBusinessLicense4"
              onChange={setDateBusinessLicense4} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense4">{t('Enter the applicant’s Date of business license (4)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense4" 
                value={values.TypeNameLicense4}
                type="text"
                placeholder={t('Type and Name of License (4)')}
                onChange={handleChange('TypeNameLicense4')}
                />
                <label htmlFor="TypeNameLicense4">{t('Enter the applicant’s Type and Name of License (4)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate4}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (4)')}
              id="LicenseExpirationDate4"
              onChange={setLicenseExpirationDate4} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate4">{t('Enter the applicant’s License Expiration Date (4)')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense5}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (5)')}
              id="DateBusinessLicense5"
              onChange={setDateBusinessLicense5} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense5">{t('Enter the applicant’s Date of business license (5)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense5" 
                value={values.TypeNameLicense5}
                type="text"
                placeholder={t('Type and Name of License (5)')}
                onChange={handleChange('TypeNameLicense5')}
                />
                <label htmlFor="TypeNameLicense5">{t('Enter the applicant’s Type and Name of License (5)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate5}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (5)')}
              id="LicenseExpirationDate5"
              onChange={setLicenseExpirationDate5} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate5">{t('Enter the applicant’s License Expiration Date (5)')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateBusinessLicense6}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Business License (6)')}
              id="DateBusinessLicense6"
              onChange={setDateBusinessLicense6} 
              className="col-12"
            />}
              <label htmlFor="DateBusinessLicense6">{t('Enter the applicant’s Date of business license (6)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TypeNameLicense6" 
                value={values.TypeNameLicense6}
                type="text"
                placeholder={t('Type and Name of License (6)')}
                onChange={handleChange('TypeNameLicense6')}
                />
                <label htmlFor="TypeNameLicense6">{t('Enter the applicant’s Type and Name of License (6)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={LicenseExpirationDate6}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('License Expiration Date (6)')}
              id="LicenseExpirationDate6"
              onChange={setLicenseExpirationDate6} 
              className="col-12"
            />}
              <label htmlFor="LicenseExpirationDate6">{t('Enter the applicant’s License Expiration Date (6)')}</label>
          </div>


          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Job qualifications')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target" src={image} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Certificate of Employment')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox2">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Insurance')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Business License')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Permissions')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Business Cards')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox6">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage6Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Company Documents')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox7">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage7Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Factory Documents')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox8">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage8Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('CV')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox9">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage9Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Tax Returns')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox10">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage10Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Payroll')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox11">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage11Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Employment Warrant')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox12">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage12Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Mission Warrant')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox13">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage13Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Responsibility Warrant')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox14">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage14Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash  size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Documents')}</p> 
          </div>
          </div>


          
         

   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>
       
        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;