import {AddSquare,ArrowDown2,Warning2,Calendar2,Briefcase,Star1,CardTick1,CardRemove1,CardEdit} from 'iconsax-react';
import Popup from './Popup';
import Popup2 from './Popup2';
import { useState , useEffect , useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import { Circles } from  'react-loader-spinner';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Home = () => {

    localStorage.removeItem('resp21');
    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'',CustomerStatus:'',CustomerCreated:'', CustomerAgent:'',key:''},
      
      ]);

      const [peopleA , setPeopleA] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'',AgentStatus:'',AgentClients:' ', Email:'',key:''},
       
      ]);
      const [peopleD , setPeopleD] = useState([
        {visa:'',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'',CustomerStatus:'',CustomerCreated:'', CustomerAgent:'',key:''},
      
      ]);

      const [peopleM , setPeopleM] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'',AgentStatus:'',AgentClients:' ', Email:'',key:''},
       
      ]);

      useEffect(() => {
       
        fetch('https://telesiness.ir/api/Dashboards/DistributorLatestCustomers' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email') })
            }).then(response => response.json()).then(response=>{
                 var i =0;
                 var j =0;
                 try
                 {
                    
                    if(people.length<2)
                    {
                        people.pop();
                        var myActDec = ''
                    for (i in response.customersListViewModels){
                        // people[i].CustomerName = response.customersListViewModels[i].customerFirstName + " " + response.customersListViewModels[i].customerLastName;
                        // people[i].CustomerCreated = response.customersListViewModels[i].customerRegisterDate;
                        
                        if(response.customersListViewModels[i].verifyCustomerByDistributor == '2')
                        myActDec = "Deactive";
                        else  if(response.customersListViewModels[i].verifyCustomerByDistributor == '1')
                        myActDec = "Active";
                        else
                        myActDec = "Pending";
                        people.push( {visa:'false',
                        CustomerPic:'CusPic.png', 
                        CustomerName:response.customersListViewModels[i].customerFirstName + " " + 
                        response.customersListViewModels[i].customerLastName,
                        CustomerPhone:response.customersListViewModels[i].customerPhoneNumber,
                        CustomerStatus:myActDec,
                        CustomerCreated:response.customersListViewModels[i].customerRegisterDate, 
                        CustomerAgent:'',key:i},)
                                     }
                        
                        for (j in response.dcSharedCustomers){
                        // people[i].CustomerName = response.dcSharedCustomers[i].customerFirstName + " " + response.dcSharedCustomers[i].customerLastName;
                        // people[i].CustomerCreated = response.dcSharedCustomers[i].customerRegisterDate;
                        
                        if(response.dcSharedCustomers[j].verifyCustomerByDistributor == '2')
                        myActDec = "Deactive";
                        else  if(response.dcSharedCustomers[j].verifyCustomerByDistributor == '1')
                        myActDec = "Active";
                        else
                        myActDec = "Pending";
                        people.push( {visa:'false',
                        CustomerPic:'CusPic.png', 
                        CustomerName:response.dcSharedCustomers[j].customerFirstName + " " + 
                        response.dcSharedCustomers[j].customerLastName,
                        CustomerPhone:response.dcSharedCustomers[j].customerPhoneNumber,
                        CustomerStatus:myActDec,
                        CustomerCreated:response.dcSharedCustomers[j].customerRegisterDate, 
                        CustomerAgent:'',key:i+j+1},)
                                        }
                     }
                 }
    
                catch{
    
                }
                // console.log(response[0]);
                
            });

      }, []);

     

//******************************************************************************* */
//********************************************************************************* */
const [respFinal , setrespFinal] = useState([]);
const [finalRespList , setfinalRespList] = useState([]);
const [resp2, setresp2]=useState([]) ;
const [resp21, setresp21]=useState([]) ;
const [dep1, setdep1]=useState(false) ;
const [dep2, setdep2]=useState(false) ;
const [dep3, setdep3]=useState(false) ;
const [dep31, setdep31]=useState(false) ;
const [dep21, setdep21]=useState(false) ;
const [dep11, setdep11]=useState(false) ;
const [dep4, setdep4]=useState(false) ;

const fetchDatarespFinel = () => {
    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{

        // console.log(response[0]);
        setrespFinal(response);
        console.log(response);
        setdep1(true);

    });
    if(people.length<2)
           people.pop();
    // if(finalRespList.length<2)
    // finalRespList.pop();
}

const fetchDatarespFinel2 = () => {

    try{
  var j1 = 0;
  var j = 0;
  var nmeLocal='';

    
  for (j in respFinal.lawyerList){
              
                 fetch('https://telesiness.ir/api/Dashboards/CountOfLawyerClientsOnDcHomePanel' , {
                     method: 'POST',
                     headers: { 
                      'Content-Type': 'application/json' ,
                      'Cache-Control': 'no-cache'
                  },
                     body: JSON.stringify({ Email: localStorage.getItem('email') ,LawyerCode:respFinal.lawyerList[j1].lawyerCode })
                     }).then(response2 => response2.json()).then(response2=>{
                      nmeLocal = 'resp6131'+j1;
   console.log(respFinal)     
   console.log(j1);              
   console.log(respFinal.lawyerList[j1].lawyerCode);            
   console.log(nmeLocal);
   console.log(response2);
   localStorage.setItem(nmeLocal,JSON.stringify(response2));
   console.log(JSON.parse(localStorage.getItem('resp6131'+j1))); 
                    // setfinalRespList(response2);
                    //   finalRespList.push( {
                    //       Clients:JSON.parse(localStorage.getItem('resp313'+j1)).lawyerCounts,
                    //       lawyerTariffsList:JSON.parse(localStorage.getItem('resp313'+j1)).lawyerTariffsList,
                    //       key:(parseInt(j)+1).toString(),
                    //       AgentPic:'CusPic.png', 
                    //       AgentName:'',
                    //       AgentPhone:'',
                    //       AgentStatus:'Active',
                    //       AgentClients:'24 ', 
                    //       Email:'',
                    //       AdvocacyCode:'',
                    //       City:'',
                    //       Country:'',
                    //       Address:'',

                      
                    //   },)
                      

                      j1++;
                      
                     })

  }
} catch{}

try{
    var j12 = 0;
    var j2 = 0;
    var nmeLocal='';
  
      
    for (j2 in respFinal.distributorList){
                
                   fetch('https://telesiness.ir/api/Dashboards/CountOfLawyerClientsOnDcHomePanel' , {
                       method: 'POST',
                       headers: { 
                        'Content-Type': 'application/json' ,
                        'Cache-Control': 'no-cache'
                    },
                       body: JSON.stringify({ Email: localStorage.getItem('email') ,LawyerCode:respFinal.distributorList[j2].lawyerCode })
                       }).then(response22 => response22.json()).then(response22=>{
                        nmeLocal = 'resp6132'+j12;
     console.log(respFinal)     
     console.log(j12);              
     console.log(respFinal.distributorList[j2].lawyerCode);            
     console.log(nmeLocal);
     console.log(response22);
     localStorage.setItem(nmeLocal,JSON.stringify(response22));
     console.log(JSON.parse(localStorage.getItem('resp6132'+j12))); 
                      // setfinalRespList(response2);
                      //   finalRespList.push( {
                      //       Clients:JSON.parse(localStorage.getItem('resp313'+j1)).lawyerCounts,
                      //       lawyerTariffsList:JSON.parse(localStorage.getItem('resp313'+j1)).lawyerTariffsList,
                      //       key:(parseInt(j)+1).toString(),
                      //       AgentPic:'CusPic.png', 
                      //       AgentName:'',
                      //       AgentPhone:'',
                      //       AgentStatus:'Active',
                      //       AgentClients:'24 ', 
                      //       Email:'',
                      //       AdvocacyCode:'',
                      //       City:'',
                      //       Country:'',
                      //       Address:'',
  
                        
                      //   },)
                        
  
                      j12++;
                        
                       })
                       
    }
  } catch{}


  setdep2(true);
console.log(respFinal);
}


const fetchDatarespFinel3 = () => {
  var i =0;
  var j =0;
  var j2 =0;
  var j3 =0;
  var j4 =0;
  var myActDec = '';
  var nmeLocal='';
 
      peopleA.pop();
   
    try{
    for(i in respFinal.lawyerList){
        // if(respFinal!=undefined)
        // if(respFinal.distributorList[i].distributorStatus == false)
        // myActDec = "Deactive";
        // else
        // myActDec = "Active";
        peopleA.push({
            AgentPic:'CusPic.png', 
            AgentName:respFinal.lawyerList[i].lawyerFirstName + " " + respFinal.lawyerList[i].lawyerLastName,
            AgentPhone:respFinal.lawyerList[i].lawyerCode,
            AgentStatus:'Active',
            AgentClients:JSON.parse(localStorage.getItem('resp6131'+i)).countOfLawyerClients,
            Email:'kambiz.fakhr@yahoo.com',
            key:(parseInt(i)+1).toString()},)
        console.log(respFinal);
    }
    
    } catch{}
         

   try{
//   if(dep2)
for(j in respFinal.distributorList){
     
    //    console.log(JSON.parse(localStorage.getItem('resp6132'+2))); 
     
  
       peopleA.push({
        AgentPic:'CusPic.png', 
        AgentName:respFinal.distributorList[j].lawyerFirstName + " " + respFinal.distributorList[j].lawyerLastName,
        AgentPhone:respFinal.distributorList[j].lawyerCode,
        AgentStatus:'Active',
        AgentClients:JSON.parse(localStorage.getItem('resp6132'+j2)).countOfLawyerClients,
        Email:'kambiz.fakhr@yahoo.com',
        key:(parseInt(j)+1+ parseInt(i)+1).toString()},)

    
    j2++;
}
 } catch{}

    
                     
                  //  setdep3(true);
                   
                      
                  for (i in respFinal.lawyerList){
                      localStorage.removeItem('resp6131'+j3);
                      j3++; 
                  }  
                  for (i in respFinal.distributorList){
                    localStorage.removeItem('resp6132'+j4);
                    j4++; 
                }            
                  console.log(people);
                      setdep3(true);
                      
  }
  
  const fetchDatarespFinel4 = () => {
      setdep4(true);
      
  }
//******************************************************************************* */
//********************************************************************************* */


//     fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
// method: 'POST',
// headers: { 'Content-Type': 'application/json' },
// body: JSON.stringify({ Email: localStorage.getItem('email') })
// }).then(response => response.json()).then(response=>{
//      var i =0;
//      var j =0;
//      try{ 

//         if(peopleA.length<2)
//         {
//             peopleA.pop();
//             for (j in response.lawyerList){
//                 // console.log(response.lawyerList[i].distributorFirstName);
//                 // peopleA[i].AgentName = response.lawyerList[i].distributorFirstName + " " + response.lawyerList[i].distributorLastName;
//                 // peopleA[i].AgentClients = response.lawyerList[i].clientsCount;
//                 // peopleA[i].AgentPhone = response.lawyerList[i].distributorCode;
//                 peopleA.push({
//                     AgentPic:'CusPic.png', 
//                     AgentName:response.lawyerList[j].lawyerFirstName + " " + response.lawyerList[j].lawyerLastName,
//                     AgentPhone:response.lawyerList[j].lawyerCode,
//                     AgentStatus:'Active',
//                     AgentClients:response.lawyerList[j].clientsCount, 
//                     Email:'kambiz.fakhr@yahoo.com',
//                     key:(parseInt(j)+1).toString()},)
//             }
//             for (i in response.distributorList){
//                 // console.log(response.distributorList[i].distributorFirstName);
//                 // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
//                 // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
//                 // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
//                 peopleA.push({
//                     AgentPic:'CusPic.png', 
//                     AgentName:response.distributorList[i].lawyerFirstName + " " + response.distributorList[i].lawyerLastName,
//                     AgentPhone:response.distributorList[i].lawyerCode,
//                     AgentStatus:'Active',
//                     AgentClients:response.distributorList[i].clientsCount, 
//                     Email:'kambiz.fakhr@yahoo.com',
//                     key:(parseInt(j)+1 + parseInt(i)+1).toString()},)
//             }
      
//         }
      
        
//      }

//     catch{}
//     // console.log(response.distributorList[0].distributorFirstName);
//     //   forceUpdate();
//     // window.location.reload(false);
    
// });
useEffect(() => {
    
    fetch('https://telesiness.ir/api/Dashboards/GetMarketersList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
            togglePopup7();
             var i =0;
             try{ 
        
                if(peopleM.length<2)
                {
                    peopleM.pop();
                    for (i in response.marketerList){
                        // console.log(response.marketerList[i].distributorFirstName);
                        // peopleM[i].AgentName = response.marketerList[i].distributorFirstName + " " + response.marketerList[i].distributorLastName;
                        // peopleM[i].AgentClients = response.marketerList[i].clientsCount;
                        // peopleM[i].AgentPhone = response.marketerList[i].distributorCode;
                        peopleM.push({AgentPic:'CusPic.png', AgentName:response.marketerList[i].marketerFirstName + " " + response.marketerList[i].marketerLastName,AgentPhone:response.marketerList[i].marketerPhoneNumber,AgentStatus:response.marketerList[i].marketerStatus,AgentClients:response.marketerList[i].clientsCount, Email:'kambiz.fakhr@yahoo.com',key:response.marketerList[i].marketerCode},)
                    }
                }
              
                
             }
        
            catch{}
            // console.log(response.marketerList[0].distributorFirstName);
            //   forceUpdate();
            // window.location.reload(false);
            
        });
    
        fetch('https://telesiness.ir/api/Dashboards/DistributorsList' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email') })
            }).then(response => response.json()).then(response=>{
                 var i =0;
                 try{ 
            
                    if(peopleD.length<2)
                    {
                        peopleD.pop();
                        for (i in response.distributorsList){
                            // console.log(response.distributorsList[i].distributorFirstName);
                            // peopleD[i].AgentName = response.distributorsList[i].distributorFirstName + " " + response.distributorsList[i].distributorLastName;
                            // peopleD[i].AgentClients = response.distributorsList[i].clientsCount;
                            // peopleD[i].AgentPhone = response.distributorsList[i].distributorCode;
                            peopleD.push({
                                AgentPic:'CusPic.png', 
                                AgentName:response.distributorsList[i].distributorFirstName 
                                + " " + response.distributorsList[i].distributorLastName,
                                AgentPhone:response.distributorsList[i].distributorPhoneNumber,
                                AgentStatus:'Active',
                                AgentClients:response.distributorsList[i].distributorPhoneNumber, 
                                Email:'kambiz.fakhr@yahoo.com',
                                key:response.distributorsList[i].distributorCode},)
                        }
                    }
                  
                    
                 }
            
                catch{}
                // console.log(response.distributorsList[0].distributorFirstName);
                //   forceUpdate();
                // window.location.reload(false);
                
            });
            
    }, []);




useEffect(() => {
    fetchDatarespFinel();
    // console.log(data[0].countery_Name);
    }, []);
const [staterespFinel, setStaterespFinel] = useState({ num: 0 });
const counterrespFinel = useRef(0);

useEffect(() => {
    fetchDatarespFinel2();
    // console.log(data[0].countery_Name);
    }, [dep1]);

    useEffect(() => {
    fetchDatarespFinel3();
    // console.log(data[0].countery_Name);
    }, [respFinal]);

    useEffect(() => {
    fetchDatarespFinel4();
    // console.log(data[0].countery_Name);
    }, [dep3]); 


const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
  if (counter.current < 1) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

    return () => clearTimeout(timer);
    forceUpdate();
  }
}, [state]);
      const [values, setValues] = useState({
        EmailL: '',
        EmailD: '',
        EmailC: '',
        EmailM: '',
        checked: '',
        refCode: '',
        showPassword: false,
        countOfCustomerDocumentInProgress: '',
        countOfCustomerDocumentCompleted: '',
        countOfCustomerDocumentCanceled: '',
      });
      const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [isOpen4, setIsOpen4] = useState(false);
   const [isOpen5, setIsOpen5] = useState(false);
   const [isOpen6, setIsOpen6] = useState(false);
   const [isOpen7, setIsOpen7] = useState(true);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}


const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
const togglePopup2 = () => {
setIsOpen2(!isOpen2);
}
const togglePopup3 = () => {
setIsOpen3(!isOpen3);
}
const togglePopup4 = () => {
setIsOpen4(!isOpen4);
}
const togglePopup5 = () => {
setIsOpen5(!isOpen5);
}

const togglePopup6 = () => {
setIsOpen6(!isOpen6);
}
const togglePopup7 = () => {
setIsOpen7(!isOpen7);
}
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleAddNewUserAgentAgent = (itemKey) =>{
        setIsOpen5(!isOpen5);
    }
    const handleAddNewUserMarketer = (itemKey) =>{
        setIsOpen6(!isOpen6);
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
      const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.EmailC ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{
                setIsOpen2(!isOpen2);
                values.EmailC='';
            }
                );
        setIsOpen2(!isOpen2)
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteLawyersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ LawyerEmailAddress: values.EmailL ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{
                setIsOpen3(!isOpen3);
                values.EmailL='';
            })
        setIsOpen3(!isOpen3)
        
    }
    const handleAddUserAgentAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailD ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{
                setIsOpen5(!isOpen5);
                values.EmailD='';
            }
                )
        setIsOpen5(!isOpen5)
        
    }
    
    const handleAddUserAgentMarketerSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteMarketersByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailM ,DistributorCode:localStorage.getItem('AgentCode') })
            }).then().then(response=>{
                setIsOpen6(!isOpen6);
                values.EmailM='';
            })
        setIsOpen6(!isOpen6)
        
    }
    useEffect(() => {
           
        fetch('https://telesiness.ir/api/Dashboards/CountOfDistributorCustomersDocumentInHomePanel' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                Email: localStorage.getItem('email') 
            })
            }).then(response => response.json()).then(response=>{
                values.countOfCustomerDocumentInProgress = response.countOfCustomerDocumentInProgress;
                values.countOfCustomerDocumentCompleted = response.countOfCustomerDocumentCompleted;
                values.countOfCustomerDocumentCanceled = response.countOfCustomerDocumentCanceled;
            })
      }, []);

    return ( 
        <div className="HomeMainRow home_agentandlawyer col-12">
           <div className="HomeRow1">
                <div className="total_summary col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>{values.countOfCustomerDocumentCompleted}</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled Files')}</h4>
                                <p>{values.countOfCustomerDocumentCanceled}</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In Progress Files')}</h4>
                                <p>{values.countOfCustomerDocumentInProgress}</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
   
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>{values.countOfCustomerDocumentCompleted} </p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled Files')}</h4>
                                <p>{values.countOfCustomerDocumentCanceled}</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In Progress Files')}</h4>
                                <p>{values.countOfCustomerDocumentInProgress}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                                        <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
      
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Customers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {people.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                          
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.CustomerStatus=='Active' && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.CustomerStatus=='Deactive' && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                            {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 btn_de_active">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                        <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>
 {/* end responsive*/}                                   
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents ">
                                            
                                                <div id={item.key} className="ChoosBox2"> 
                                                    
                                                <p>{item.AgentClients + t(' Client')}</p>
                                                
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>
{/* end responsive*/}                                     
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Distributers')}</p>
                                {localStorage.getItem('isDistributorPlus')==1  && <AddSquare className='AddUserHome' onClick={handleAddNewUserAgentAgent} size="40" color="#000000" variant="Bold"/>}
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleD.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents ">
                                            
                                                <div id={item.key} className="ChoosBox2"> 
                                                    
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.key }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentClients}</p></div>
{/* end responsive*/}                                     
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
      
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Agents')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserMarketer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleM.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>
 {/* add deactiveitem or activeitem classname for itemBox baseof active or deactive*/}                                          
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.AgentStatus==true && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.AgentStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>
 {/* end responsive*/}                                   
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>

            </Carousel>
            <div className="HomeRow2 home_dashpan col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-10 col-12 d-none d-lg-flex">
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Customers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {people.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>  
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.CustomerStatus=='Active' && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.CustomerStatus=='Deactive' && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                            {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 btn_de_active">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                        <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>                               
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents">
                                                <div id={item.key} className="ChoosBox2"> 
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.AgentClients }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>                                    
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                {localStorage.getItem('isDistributorPlus')==1  &&<p>{t('Latest Distributers')}</p>}
                                {localStorage.getItem('isDistributorPlus')!=1  &&<p className="ProDCHomePTag_disable">{t('Latest Distributers')}<b class="ProDcDeactive"> (Pro)</b></p>}
                                {localStorage.getItem('isDistributorPlus')==1  && <AddSquare className='AddUserHome' onClick={handleAddNewUserAgentAgent} size="40" color="#000000" variant="Bold"/>}
                            </div>
                            {localStorage.getItem('isDistributorPlus')==1  &&<div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleD.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents "> 
                                                <div id={item.key} className="ChoosBox2"> 
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.key }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentClients}</p></div>                             
                                    </div>
                                            ))}
                                </div>
                            </div>}
                            {localStorage.getItem('isDistributorPlus')!=1  && <>
                            <p className='ProDCHomePTag'>{t('This Option Will Be Active After You improve Panel to Pro Version!')}</p>
                            </>}
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Agents')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserMarketer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleM.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                         
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.AgentStatus==true && <div className="col-3 btn_de_active">
                                            <button onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}
                                        {item.AgentStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br/>
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>                                 
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="box_HomeLightBoxRowTwo col-lg-2 col-md-12 col-12">
                    <div className="HomeLightBoxRowTwo HomeCol3Row2">
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Calendar2 size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Registery Date')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('registerDate').split('T',1)}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Briefcase className='HomeBrifcasePad' size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Agency Code')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('agentCode')}</p>
                        </div>
                        <div className="HomeCol3Row2_2 rate_star col-lg-12 col-4">
                            <div className="Star svg_HomeLightBox" onClick={togglePopup4}>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131"/>
                                <Star1 size="24" color="#313131"/>
                            </div>
                            <p className='HomeCol3P1 Star' onClick={togglePopup4}>720</p>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why do You want to De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
     {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type your Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    {isOpen3 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailL} onChange={handleChange('EmailL')} type="email" id="email" name="email" className="form-control" placeholder={t('Type your Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup3}
    />}

{isOpen5 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailD} onChange={handleChange('EmailD')} type="email" id="email" name="email" className="form-control" placeholder={t('Type your Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup5}
    />}

{isOpen6 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Agent')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Agent you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.EmailM} onChange={handleChange('EmailM')} type="email" id="email" name="email" className="form-control" placeholder={t('Type your Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentMarketerSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup6}
    />}


{isOpen4 && <Popup
      content={<>
      <div className="PboxMainRow">
      <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Your Score in Telesiness Shows Your Activity')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Dear user, these services will be available soon!')}</p>
            </div>
            <div className="PboxThirdRow">
                <div className="Star" onClick={togglePopup}>
                    <Star1 size="24" color="#313131" variant="Bold"/>
                    <Star1 size="24" color="#313131" variant="Bold"/>
                    <Star1 size="24" color="#313131" variant="Bold"/>
                    <Star1 size="24" color="#313131"/>
                    <Star1 size="24" color="#313131"/>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup4}
    />}

{isOpen7 && <Popup2
      content={<>
      <div className="PboxMainRow">
     
      <Circles color="#EEEEEE" height={140} width={140} ariaLabel="circles-loading"/>
        </div>
      </>}
      handleClose={togglePopup7}
    />}
        </div>
                
     );
}
 
export default Home;