import { useState, useEffect , useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const forceUpdate = useForceUpdate();
    const handleSubmitPersonal = (e) => {
      e.preventDefault();
  // localStorage.setItem('firstTimeCustomer',false);
  // console.log((localStorage.getItem('firstTimeCustomer')));
  // childToParentCus1e2(dataCus1);
  if(localStorage.getItem('whoIs')=='ap'){
  if(!localStorage.getItem('EvFormUpdate')){ 
  fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerLanguageCertificate' , {
 method: 'POST',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({
//Evaluation Customer form
      //Evaluation Customer Form Personal Information
      Email: localStorage.getItem('email'),
    
      CustomerDocumentNumber:localStorage.getItem('documentCode'),
      
      CustomerEnglishLanguageStatus:values.EnglishLanguageStatus,
      CustomerDateOfIELTSDegree:DateofIELTSdegreevalue.toString(),
      CustomerTotalScoreOfTheIELTSDegree:values.TotalScoreDegreeIELTS,
      CustomerIELTSReadingScore:values.IELTSReadingScore,
      CustomerIELTSWritingScore:values.IELTSWritingScore,
      CustomerIELTSListeningScore:values.IELTSListeningScore,
      CustomerIELTSSpeakingScore:values.IELTSSpeackingScore,
      CustomerDateOfTOEFLDegree:DateofTOEFLdegreevalue.toString(),
      CustomerTotalScoreOfTheTOEFLDegree:values.TotalScoreDegreeTOEFL,
      CustomerTOEFLReadingScore:values.TOEFLReadingScore,
      CustomerTOEFLWritingScore:values.TOEFLWritingScore,
      CustomerTOEFLListeningScore:values.TOEFLListeningScore,
      CustomerTOEFLSpeakingScore:values.TOEFLSpeackingScore,
      CustomerOtherLanguageNumberOneDateOfLanguageDegree:DateofOther1degreevalue.toString(),
      CustomerTotalScoreOfOtherLanguageNumberOne:values.TotalScoreDegreeOtherOne,
      CustomerOtherLanguageNumberOneReadingScore:values.OtherOneReadingScore,
      CustomerOtherLanguageNumberOneWritingScore:values.OtherOneWritingScore,
      CustomerOtherLanguageNumberOneListeningScore:values.OtherOneListeningScore,
      CustomerOtherLanguageNumberOneSpeakingScore:values.OtherOneListeningScore,
      CustomerOtherLanguageNumberTwoDateOfLanguageDegree:DateofOther2degreevalue.toString(),
      CustomerTotalScoreOfOtherLanguageNumberTwo:values.TotalScoreDegreeOtherTwo,
      CustomerOtherLanguageNumberTwoReadingScore:values.OtherTwoReadingScore,
      CustomerOtherLanguageNumberTwoWritingScore:values.OtherTwoWritingScore,
      CustomerOtherLanguageNumberTwoListeningScore:values.OtherTwoListeningScore,
      CustomerOtherLanguageNumberTwoSpeakingScore:values.OtherTwoSpeackingScore,
      CustomerOtherLanguageNumberThreeDateOfLanguageDegree:DateofOther3degreevalue.toString(),
      CustomerTotalScoreOfOtherLanguageNumberThree:values.TotalScoreDegreeOtherThree,
      CustomerOtherLanguageNumberThreeReadingScore:values.OtherThreeReadingScore,
      CustomerOtherLanguageNumberThreeWritingScore:values.OtherThreeWritingScore,
      CustomerOtherLanguageNumberThreeListeningScore:values.OtherThreeListeningScore,
      CustomerOtherLanguageNumberThreeSpeakingScore:values.OtherThreeSpeackingScore,
  
  })
 }).then(response => response.json()).then(response=>{

   if(response.statusCode==400 || response.statusCode==404)
   {
     console.log(response.statusMessage);
     console.log(values.EnglishCheckBox);
    //  setFormError(true);
    //  setHelpStatusMessage(response.statusMessage);
    //  setIspending(false);
   }
   else{
    //  setHelpStatusMessage("");
    //  setIspending(false);
    //  history.push('/regstep6');
   }
     // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
     //  // ReactSession.setStoreType("localStorage");
     //  localStorage.setItem('username', response.fullName);

     // }
   })
  }
  else 
  {
    fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerLanguageCertificate' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
   //Evaluation Customer form
           //Evaluation Customer Form Personal Information
           Email: localStorage.getItem('email'),
           CustomerCode:localStorage.getItem('customerCode'),
           
           
           CustomerEnglishLanguageStatus:values.EnglishLanguageStatus,
           CustomerDateOfIELTSDegree:DateofIELTSdegreevalue.toString(),
           CustomerTotalScoreOfTheIELTSDegree:values.TotalScoreDegreeIELTS,
           CustomerIELTSReadingScore:values.IELTSReadingScore,
           CustomerIELTSWritingScore:values.IELTSWritingScore,
           CustomerIELTSListeningScore:values.IELTSListeningScore,
           CustomerIELTSSpeakingScore:values.IELTSSpeackingScore,
           CustomerDateOfTOEFLDegree:DateofTOEFLdegreevalue.toString(),
           CustomerTotalScoreOfTheTOEFLDegree:values.TotalScoreDegreeTOEFL,
           CustomerTOEFLReadingScore:values.TOEFLReadingScore,
           CustomerTOEFLWritingScore:values.TOEFLWritingScore,
           CustomerTOEFLListeningScore:values.TOEFLListeningScore,
           CustomerTOEFLSpeakingScore:values.TOEFLSpeackingScore,
           CustomerOtherLanguageNumberOneDateOfLanguageDegree:DateofOther1degreevalue.toString(),
           CustomerTotalScoreOfOtherLanguageNumberOne:values.TotalScoreDegreeOtherOne,
           CustomerOtherLanguageNumberOneReadingScore:values.OtherOneReadingScore,
           CustomerOtherLanguageNumberOneWritingScore:values.OtherOneWritingScore,
           CustomerOtherLanguageNumberOneListeningScore:values.OtherOneListeningScore,
           CustomerOtherLanguageNumberOneSpeakingScore:values.OtherOneListeningScore,
           CustomerOtherLanguageNumberTwoDateOfLanguageDegree:DateofOther2degreevalue.toString(),
           CustomerTotalScoreOfOtherLanguageNumberTwo:values.TotalScoreDegreeOtherTwo,
           CustomerOtherLanguageNumberTwoReadingScore:values.OtherTwoReadingScore,
           CustomerOtherLanguageNumberTwoWritingScore:values.OtherTwoWritingScore,
           CustomerOtherLanguageNumberTwoListeningScore:values.OtherTwoListeningScore,
           CustomerOtherLanguageNumberTwoSpeakingScore:values.OtherTwoSpeackingScore,
           CustomerOtherLanguageNumberThreeDateOfLanguageDegree:DateofOther3degreevalue.toString(),
           CustomerTotalScoreOfOtherLanguageNumberThree:values.TotalScoreDegreeOtherThree,
           CustomerOtherLanguageNumberThreeReadingScore:values.OtherThreeReadingScore,
           CustomerOtherLanguageNumberThreeWritingScore:values.OtherThreeWritingScore,
           CustomerOtherLanguageNumberThreeListeningScore:values.OtherThreeListeningScore,
           CustomerOtherLanguageNumberThreeSpeakingScore:values.OtherThreeSpeackingScore,
         
   

   
       })
      }).then(response => response.json()).then(response=>{
   
        localStorage.setItem('customerEnglishLanguageStatus',response.customerEnglishLanguageStatus);
        localStorage.setItem('customerDateOfIELTSDegree',response.customerDateOfIELTSDegree);
        localStorage.setItem('customerTotalScoreOfTheIELTSDegree',response.customerTotalScoreOfTheIELTSDegree);
        localStorage.setItem('customerIELTSReadingScore',response.customerIELTSReadingScore);
        localStorage.setItem('customerIELTSWritingScore',response.customerIELTSWritingScore);
        localStorage.setItem('customerIELTSListeningScore',response.customerIELTSListeningScore);
        localStorage.setItem('customerIELTSSpeakingScore',response.customerIELTSSpeakingScore);
        localStorage.setItem('customerDateOfTOEFLDegree',response.customerDateOfTOEFLDegree);
        localStorage.setItem('customerTotalScoreOfTheTOEFLDegree',response.customerTotalScoreOfTheTOEFLDegree);
        localStorage.setItem('customerTOEFLReadingScore',response.customerTOEFLReadingScore);
        localStorage.setItem('customerTOEFLWritingScore',response.customerTOEFLWritingScore);
        localStorage.setItem('customerTOEFLListeningScore',response.customerTOEFLListeningScore);
        localStorage.setItem('customerTOEFLSpeakingScore',response.customerTOEFLSpeakingScore);
        localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree',response.customerOtherLanguageNumberOneDateOfLanguageDegree);
        localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne',response.customerTotalScoreOfOtherLanguageNumberOne);
        localStorage.setItem('customerOtherLanguageNumberOneReadingScore',response.customerOtherLanguageNumberOneReadingScore);
        localStorage.setItem('customerOtherLanguageNumberOneWritingScore',response.customerOtherLanguageNumberOneWritingScore);
        localStorage.setItem('customerOtherLanguageNumberOneListeningScore',response.customerOtherLanguageNumberOneListeningScore);
        localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore',response.customerOtherLanguageNumberOneSpeakingScore);
        localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree',response.customerOtherLanguageNumberTwoDateOfLanguageDegree);
        localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo',response.customerTotalScoreOfOtherLanguageNumberTwo);
        localStorage.setItem('customerOtherLanguageNumberTwoReadingScore',response.customerOtherLanguageNumberTwoReadingScore);
        localStorage.setItem('customerOtherLanguageNumberTwoWritingScore',response.customerOtherLanguageNumberTwoWritingScore);
        localStorage.setItem('customerOtherLanguageNumberTwoListeningScore',response.customerOtherLanguageNumberTwoListeningScore);
        localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore',response.customerOtherLanguageNumberTwoSpeakingScore);
        localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree',response.customerOtherLanguageNumberThreeDateOfLanguageDegree);
        localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree',response.customerTotalScoreOfOtherLanguageNumberThree);
        localStorage.setItem('customerOtherLanguageNumberThreeReadingScore',response.customerOtherLanguageNumberThreeReadingScore);
        localStorage.setItem('customerOtherLanguageNumberThreeWritingScore',response.customerOtherLanguageNumberThreeWritingScore);
        localStorage.setItem('customerOtherLanguageNumberThreeListeningScore',response.customerOtherLanguageNumberThreeListeningScore);
        localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore',response.customerOtherLanguageNumberThreeSpeakingScore);

        localStorage.setItem('EvFormUpdate',true);
        
        console.log(localStorage.getItem('EvFormUpdate'));
    
    
    

    values.EnglishLanguageStatus =localStorage.getItem('customerEnglishLanguageStatus');
    
    values.TotalScoreDegreeIELTS =localStorage.getItem('customerTotalScoreOfTheIELTSDegree');
    values.IELTSReadingScore =localStorage.getItem('customerIELTSReadingScore');
    values.IELTSWritingScore =localStorage.getItem('customerIELTSWritingScore');
    values.IELTSListeningScore =localStorage.getItem('customerIELTSListeningScore');
    values.IELTSSpeackingScore =localStorage.getItem('customerIELTSSpeakingScore');
    
    values.TotalScoreDegreeTOEFL =localStorage.getItem('customerTotalScoreOfTheTOEFLDegree');
    values.TOEFLReadingScore =localStorage.getItem('customerTOEFLReadingScore');
    values.TOEFLWritingScore =localStorage.getItem('customerTOEFLWritingScore');
    values.TOEFLListeningScore =localStorage.getItem('customerTOEFLListeningScore');
    values.TOEFLSpeackingScore =localStorage.getItem('customerTOEFLSpeakingScore');
    
    values.TotalScoreDegreeOtherOne =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberOne');
    values.OtherOneReadingScore =localStorage.getItem('customerOtherLanguageNumberOneReadingScore');
    values.OtherOneWritingScore =localStorage.getItem('customerOtherLanguageNumberOneWritingScore');
    values.OtherOneListeningScore =localStorage.getItem('customerOtherLanguageNumberOneListeningScore');
    values.OtherOneSpeackingScore =localStorage.getItem('customerOtherLanguageNumberOneSpeakingScore');
    
    values.TotalScoreDegreeOtherTwo =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberTwo');
    values.OtherTwoReadingScore =localStorage.getItem('customerOtherLanguageNumberTwoReadingScore');
    values.OtherTwoWritingScore =localStorage.getItem('customerOtherLanguageNumberTwoWritingScore');
    values.OtherTwoListeningScore =localStorage.getItem('customerOtherLanguageNumberTwoListeningScore');
    values.OtherTwoSpeackingScore =localStorage.getItem('customerOtherLanguageNumberTwoSpeakingScore');
    
    values.TotalScoreDegreeOtherThree =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberThree');
    values.OtherThreeReadingScore =localStorage.getItem('customerOtherLanguageNumberThreeReadingScore');
    values.OtherThreeWritingScore =localStorage.getItem('customerOtherLanguageNumberThreeWritingScore');
    values.OtherThreeListeningScore =localStorage.getItem('customerOtherLanguageNumberThreeListeningScore');
    values.OtherThreeSpeackingScore =localStorage.getItem('customerOtherLanguageNumberThreeSpeakingScore');
   
    // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');

    
    




        if(response.statusCode==400 || response.statusCode==404)
        {
          console.log(response.statusMessage);
          console.log(values.EnglishCheckBox);
         //  setFormError(true);
         //  setHelpStatusMessage(response.statusMessage);
         //  setIspending(false);
        }
        else{
         //  setHelpStatusMessage("");
         //  setIspending(false);
         //  history.push('/regstep6');
        }
          // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
          //  // ReactSession.setStoreType("localStorage");
          //  localStorage.setItem('username', response.fullName);
    
          // }
      })
  
  }
}
else if(localStorage.getItem('whoIs')=='sp'){
  if(!localStorage.getItem('EvFormUpdate')){ 
    fetch('https://telesiness.ir/api/CustomerDocuments/SubmitCustomerDocumentSpouseLanguageCertificate' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({
  //Evaluation Customer form
        //Evaluation Customer Form Personal Information
        Email: localStorage.getItem('email'),
      
        CustomerDocumentNumber:localStorage.getItem('documentCode'),
        
        CustomerSpouseEnglishLanguageStatus:values.EnglishLanguageStatus,
        CustomerSpouseDateOfIELTSDegree:DateofIELTSdegreevalue.toString(),
        CustomerSpouseTotalScoreOfTheIELTSDegree:values.TotalScoreDegreeIELTS,
        CustomerSpouseIELTSReadingScore:values.IELTSReadingScore,
        CustomerSpouseIELTSWritingScore:values.IELTSWritingScore,
        CustomerSpouseIELTSListeningScore:values.IELTSListeningScore,
        CustomerSpouseIELTSSpeakingScore:values.IELTSSpeackingScore,
        CustomerSpouseDateOfTOEFLDegree:DateofTOEFLdegreevalue.toString(),
        CustomerSpouseTotalScoreOfTheTOEFLDegree:values.TotalScoreDegreeTOEFL,
        CustomerSpouseTOEFLReadingScore:values.TOEFLReadingScore,
        CustomerSpouseTOEFLWritingScore:values.TOEFLWritingScore,
        CustomerSpouseTOEFLListeningScore:values.TOEFLListeningScore,
        CustomerSpouseTOEFLSpeakingScore:values.TOEFLSpeackingScore,
        CustomerSpouseOtherLanguageNumberOneDateOfLanguageDegree:DateofOther1degreevalue.toString(),
        CustomerSpouseTotalScoreOfOtherLanguageNumberOne:values.TotalScoreDegreeOtherOne,
        CustomerSpouseOtherLanguageNumberOneReadingScore:values.OtherOneReadingScore,
        CustomerSpouseOtherLanguageNumberOneWritingScore:values.OtherOneWritingScore,
        CustomerSpouseOtherLanguageNumberOneListeningScore:values.OtherOneListeningScore,
        CustomerSpouseOtherLanguageNumberOneSpeakingScore:values.OtherOneListeningScore,
        CustomerSpouseOtherLanguageNumberTwoDateOfLanguageDegree:DateofOther2degreevalue.toString(),
        CustomerSpouseTotalScoreOfOtherLanguageNumberTwo:values.TotalScoreDegreeOtherTwo,
        CustomerSpouseOtherLanguageNumberTwoReadingScore:values.OtherTwoReadingScore,
        CustomerSpouseOtherLanguageNumberTwoWritingScore:values.OtherTwoWritingScore,
        CustomerSpouseOtherLanguageNumberTwoListeningScore:values.OtherTwoListeningScore,
        CustomerSpouseOtherLanguageNumberTwoSpeakingScore:values.OtherTwoSpeackingScore,
        CustomerSpouseOtherLanguageNumberThreeDateOfLanguageDegree:DateofOther3degreevalue.toString(),
        CustomerSpouseTotalScoreOfOtherLanguageNumberThree:values.TotalScoreDegreeOtherThree,
        CustomerSpouseOtherLanguageNumberThreeReadingScore:values.OtherThreeReadingScore,
        CustomerSpouseOtherLanguageNumberThreeWritingScore:values.OtherThreeWritingScore,
        CustomerSpouseOtherLanguageNumberThreeListeningScore:values.OtherThreeListeningScore,
        CustomerSpouseOtherLanguageNumberThreeSpeakingScore:values.OtherThreeSpeackingScore,
    
    })
   }).then(response => response.json()).then(response=>{
  
     if(response.statusCode==400 || response.statusCode==404)
     {
       console.log(response.statusMessage);
       console.log(values.EnglishCheckBox);
      //  setFormError(true);
      //  setHelpStatusMessage(response.statusMessage);
      //  setIspending(false);
     }
     else{
      //  setHelpStatusMessage("");
      //  setIspending(false);
      //  history.push('/regstep6');
     }
       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
  
       // }
     })
    }
    else 
    {
      fetch('https://telesiness.ir/api/CustomerDocuments/EditCustomerDocumentSpouseLanguageCertificate' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
     //Evaluation Customer form
             //Evaluation Customer Form Personal Information
             Email: localStorage.getItem('email'),
             CustomerCode:localStorage.getItem('customerCode'),
             
             
             CustomerSpouseEnglishLanguageStatus:values.EnglishLanguageStatus,
             CustomerSpouseDateOfIELTSDegree:DateofIELTSdegreevalue.toString(),
             CustomerSpouseTotalScoreOfTheIELTSDegree:values.TotalScoreDegreeIELTS,
             CustomerSpouseIELTSReadingScore:values.IELTSReadingScore,
             CustomerSpouseIELTSWritingScore:values.IELTSWritingScore,
             CustomerSpouseIELTSListeningScore:values.IELTSListeningScore,
             CustomerSpouseIELTSSpeakingScore:values.IELTSSpeackingScore,
             CustomerSpouseDateOfTOEFLDegree:DateofTOEFLdegreevalue.toString(),
             CustomerSpouseTotalScoreOfTheTOEFLDegree:values.TotalScoreDegreeTOEFL,
             CustomerSpouseTOEFLReadingScore:values.TOEFLReadingScore,
             CustomerSpouseTOEFLWritingScore:values.TOEFLWritingScore,
             CustomerSpouseTOEFLListeningScore:values.TOEFLListeningScore,
             CustomerSpouseTOEFLSpeakingScore:values.TOEFLSpeackingScore,
             CustomerSpouseOtherLanguageNumberOneDateOfLanguageDegree:DateofOther1degreevalue.toString(),
             CustomerSpouseTotalScoreOfOtherLanguageNumberOne:values.TotalScoreDegreeOtherOne,
             CustomerSpouseOtherLanguageNumberOneReadingScore:values.OtherOneReadingScore,
             CustomerSpouseOtherLanguageNumberOneWritingScore:values.OtherOneWritingScore,
             CustomerSpouseOtherLanguageNumberOneListeningScore:values.OtherOneListeningScore,
             CustomerSpouseOtherLanguageNumberOneSpeakingScore:values.OtherOneListeningScore,
             CustomerSpouseOtherLanguageNumberTwoDateOfLanguageDegree:DateofOther2degreevalue.toString(),
             CustomerSpouseTotalScoreOfOtherLanguageNumberTwo:values.TotalScoreDegreeOtherTwo,
             CustomerSpouseOtherLanguageNumberTwoReadingScore:values.OtherTwoReadingScore,
             CustomerSpouseOtherLanguageNumberTwoWritingScore:values.OtherTwoWritingScore,
             CustomerSpouseOtherLanguageNumberTwoListeningScore:values.OtherTwoListeningScore,
             CustomerSpouseOtherLanguageNumberTwoSpeakingScore:values.OtherTwoSpeackingScore,
             CustomerSpouseOtherLanguageNumberThreeDateOfLanguageDegree:DateofOther3degreevalue.toString(),
             CustomerSpouseTotalScoreOfOtherLanguageNumberThree:values.TotalScoreDegreeOtherThree,
             CustomerSpouseOtherLanguageNumberThreeReadingScore:values.OtherThreeReadingScore,
             CustomerSpouseOtherLanguageNumberThreeWritingScore:values.OtherThreeWritingScore,
             CustomerSpouseOtherLanguageNumberThreeListeningScore:values.OtherThreeListeningScore,
             CustomerSpouseOtherLanguageNumberThreeSpeakingScore:values.OtherThreeSpeackingScore,
           
     
  
     
         })
        }).then(response => response.json()).then(response=>{
     
          localStorage.setItem('customerSpouseEnglishLanguageStatus',response.customerSpouseEnglishLanguageStatus);
          localStorage.setItem('customerSpouseDateOfIELTSDegree',response.customerSpouseDateOfIELTSDegree);
          localStorage.setItem('customerSpouseTotalScoreOfTheIELTSDegree',response.customerSpouseTotalScoreOfTheIELTSDegree);
          localStorage.setItem('customerSpouseIELTSReadingScore',response.customerSpouseIELTSReadingScore);
          localStorage.setItem('customerSpouseIELTSWritingScore',response.customerSpouseIELTSWritingScore);
          localStorage.setItem('customerSpouseIELTSListeningScore',response.customerSpouseIELTSListeningScore);
          localStorage.setItem('customerSpouseIELTSSpeakingScore',response.customerSpouseIELTSSpeakingScore);
          localStorage.setItem('customerSpouseDateOfTOEFLDegree',response.customerSpouseDateOfTOEFLDegree);
          localStorage.setItem('customerSpouseTotalScoreOfTheTOEFLDegree',response.customerSpouseTotalScoreOfTheTOEFLDegree);
          localStorage.setItem('customerSpouseTOEFLReadingScore',response.customerSpouseTOEFLReadingScore);
          localStorage.setItem('customerSpouseTOEFLWritingScore',response.customerSpouseTOEFLWritingScore);
          localStorage.setItem('customerSpouseTOEFLListeningScore',response.customerSpouseTOEFLListeningScore);
          localStorage.setItem('customerSpouseTOEFLSpeakingScore',response.customerSpouseTOEFLSpeakingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberOneDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberOneDateOfLanguageDegree);
          localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberOne',response.customerSpouseTotalScoreOfOtherLanguageNumberOne);
          localStorage.setItem('customerSpouseOtherLanguageNumberOneReadingScore',response.customerSpouseOtherLanguageNumberOneReadingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberOneWritingScore',response.customerSpouseOtherLanguageNumberOneWritingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberOneListeningScore',response.customerSpouseOtherLanguageNumberOneListeningScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberOneSpeakingScore',response.customerSpouseOtherLanguageNumberOneSpeakingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree);
          localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo',response.customerSpouseTotalScoreOfOtherLanguageNumberTwo);
          localStorage.setItem('customerSpouseOtherLanguageNumberTwoReadingScore',response.customerSpouseOtherLanguageNumberTwoReadingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberTwoWritingScore',response.customerSpouseOtherLanguageNumberTwoWritingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberTwoListeningScore',response.customerSpouseOtherLanguageNumberTwoListeningScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberTwoSpeakingScore',response.customerSpouseOtherLanguageNumberTwoSpeakingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree);
          localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberThree',response.customerSpouseTotalScoreOfOtherLanguageNumberThree);
          localStorage.setItem('customerSpouseOtherLanguageNumberThreeReadingScore',response.customerSpouseOtherLanguageNumberThreeReadingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberThreeWritingScore',response.customerSpouseOtherLanguageNumberThreeWritingScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberThreeListeningScore',response.customerSpouseOtherLanguageNumberThreeListeningScore);
          localStorage.setItem('customerSpouseOtherLanguageNumberThreeSpeakingScore',response.customerSpouseOtherLanguageNumberThreeSpeakingScore);
  
          localStorage.setItem('EvFormUpdate',true);
          
          console.log(localStorage.getItem('EvFormUpdate'));
      
      
      
  
      values.EnglishLanguageStatus =localStorage.getItem('customerSpouseEnglishLanguageStatus');
      
      values.TotalScoreDegreeIELTS =localStorage.getItem('customerSpouseTotalScoreOfTheIELTSDegree');
      values.IELTSReadingScore =localStorage.getItem('customerSpouseIELTSReadingScore');
      values.IELTSWritingScore =localStorage.getItem('customerSpouseIELTSWritingScore');
      values.IELTSListeningScore =localStorage.getItem('customerSpouseIELTSListeningScore');
      values.IELTSSpeackingScore =localStorage.getItem('customerSpouseIELTSSpeakingScore');
      
      values.TotalScoreDegreeTOEFL =localStorage.getItem('customerSpouseTotalScoreOfTheTOEFLDegree');
      values.TOEFLReadingScore =localStorage.getItem('customerSpouseTOEFLReadingScore');
      values.TOEFLWritingScore =localStorage.getItem('customerSpouseTOEFLWritingScore');
      values.TOEFLListeningScore =localStorage.getItem('customerSpouseTOEFLListeningScore');
      values.TOEFLSpeackingScore =localStorage.getItem('customerSpouseTOEFLSpeakingScore');
      
      values.TotalScoreDegreeOtherOne =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberOne');
      values.OtherOneReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneReadingScore');
      values.OtherOneWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneWritingScore');
      values.OtherOneListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneListeningScore');
      values.OtherOneSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneSpeakingScore');
      
      values.TotalScoreDegreeOtherTwo =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo');
      values.OtherTwoReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoReadingScore');
      values.OtherTwoWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoWritingScore');
      values.OtherTwoListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoListeningScore');
      values.OtherTwoSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoSpeakingScore');
      
      values.TotalScoreDegreeOtherThree =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberThree');
      values.OtherThreeReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeReadingScore');
      values.OtherThreeWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeWritingScore');
      values.OtherThreeListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeListeningScore');
      values.OtherThreeSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeSpeakingScore');
     
      // values. =localStorage.getItem('CustomerEvaluationFormTrackingNumber');
  
      
      
  
  
  
  
          if(response.statusCode==400 || response.statusCode==404)
          {
            console.log(response.statusMessage);
            console.log(values.EnglishCheckBox);
           //  setFormError(true);
           //  setHelpStatusMessage(response.statusMessage);
           //  setIspending(false);
          }
          else{
           //  setHelpStatusMessage("");
           //  setIspending(false);
           //  history.push('/regstep6');
          }
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
        })
    
    }
}
}
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
        
          ProPic:'',
          
          JobUpload:'',
         
          // ProPic: '',

          EnglishLanguageStatus:'',
      
          TotalScoreDegreeIELTS:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
        
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
 
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

//*************************************** */
//*************************************** */
if(localStorage.getItem('whoIs')=='ap'){
fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerLanguageCertificate' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  localStorage.setItem('customerEnglishLanguageStatus',response.customerEnglishLanguageStatus);
  localStorage.setItem('customerDateOfIELTSDegree',response.customerDateOfIELTSDegree);
  localStorage.setItem('customerTotalScoreOfTheIELTSDegree',response.customerTotalScoreOfTheIELTSDegree);
  localStorage.setItem('customerIELTSReadingScore',response.customerIELTSReadingScore);
  localStorage.setItem('customerIELTSWritingScore',response.customerIELTSWritingScore);
  localStorage.setItem('customerIELTSListeningScore',response.customerIELTSListeningScore);
  localStorage.setItem('customerIELTSSpeakingScore',response.customerIELTSSpeakingScore);
  localStorage.setItem('customerDateOfTOEFLDegree',response.customerDateOfTOEFLDegree);
  localStorage.setItem('customerTotalScoreOfTheTOEFLDegree',response.customerTotalScoreOfTheTOEFLDegree);
  localStorage.setItem('customerTOEFLReadingScore',response.customerTOEFLReadingScore);
  localStorage.setItem('customerTOEFLWritingScore',response.customerTOEFLWritingScore);
  localStorage.setItem('customerTOEFLListeningScore',response.customerTOEFLListeningScore);
  localStorage.setItem('customerTOEFLSpeakingScore',response.customerTOEFLSpeakingScore);
  localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree',response.customerOtherLanguageNumberOneDateOfLanguageDegree);
  localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne',response.customerTotalScoreOfOtherLanguageNumberOne);
  localStorage.setItem('customerOtherLanguageNumberOneReadingScore',response.customerOtherLanguageNumberOneReadingScore);
  localStorage.setItem('customerOtherLanguageNumberOneWritingScore',response.customerOtherLanguageNumberOneWritingScore);
  localStorage.setItem('customerOtherLanguageNumberOneListeningScore',response.customerOtherLanguageNumberOneListeningScore);
  localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore',response.customerOtherLanguageNumberOneSpeakingScore);
  localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree',response.customerOtherLanguageNumberTwoDateOfLanguageDegree);
  localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo',response.customerTotalScoreOfOtherLanguageNumberTwo);
  localStorage.setItem('customerOtherLanguageNumberTwoReadingScore',response.customerOtherLanguageNumberTwoReadingScore);
  localStorage.setItem('customerOtherLanguageNumberTwoWritingScore',response.customerOtherLanguageNumberTwoWritingScore);
  localStorage.setItem('customerOtherLanguageNumberTwoListeningScore',response.customerOtherLanguageNumberTwoListeningScore);
  localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore',response.customerOtherLanguageNumberTwoSpeakingScore);
  localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree',response.customerOtherLanguageNumberThreeDateOfLanguageDegree);
  localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree',response.customerTotalScoreOfOtherLanguageNumberThree);
  localStorage.setItem('customerOtherLanguageNumberThreeReadingScore',response.customerOtherLanguageNumberThreeReadingScore);
  localStorage.setItem('customerOtherLanguageNumberThreeWritingScore',response.customerOtherLanguageNumberThreeWritingScore);
  localStorage.setItem('customerOtherLanguageNumberThreeListeningScore',response.customerOtherLanguageNumberThreeListeningScore);
  localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore',response.customerOtherLanguageNumberThreeSpeakingScore);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerLanguageCertificate' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerEnglishLanguageStatus',response.customerEnglishLanguageStatus);
      localStorage.setItem('customerDateOfIELTSDegree',response.customerDateOfIELTSDegree);
      localStorage.setItem('customerTotalScoreOfTheIELTSDegree',response.customerTotalScoreOfTheIELTSDegree);
      localStorage.setItem('customerIELTSReadingScore',response.customerIELTSReadingScore);
      localStorage.setItem('customerIELTSWritingScore',response.customerIELTSWritingScore);
      localStorage.setItem('customerIELTSListeningScore',response.customerIELTSListeningScore);
      localStorage.setItem('customerIELTSSpeakingScore',response.customerIELTSSpeakingScore);
      localStorage.setItem('customerDateOfTOEFLDegree',response.customerDateOfTOEFLDegree);
      localStorage.setItem('customerTotalScoreOfTheTOEFLDegree',response.customerTotalScoreOfTheTOEFLDegree);
      localStorage.setItem('customerTOEFLReadingScore',response.customerTOEFLReadingScore);
      localStorage.setItem('customerTOEFLWritingScore',response.customerTOEFLWritingScore);
      localStorage.setItem('customerTOEFLListeningScore',response.customerTOEFLListeningScore);
      localStorage.setItem('customerTOEFLSpeakingScore',response.customerTOEFLSpeakingScore);
      localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree',response.customerOtherLanguageNumberOneDateOfLanguageDegree);
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne',response.customerTotalScoreOfOtherLanguageNumberOne);
      localStorage.setItem('customerOtherLanguageNumberOneReadingScore',response.customerOtherLanguageNumberOneReadingScore);
      localStorage.setItem('customerOtherLanguageNumberOneWritingScore',response.customerOtherLanguageNumberOneWritingScore);
      localStorage.setItem('customerOtherLanguageNumberOneListeningScore',response.customerOtherLanguageNumberOneListeningScore);
      localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore',response.customerOtherLanguageNumberOneSpeakingScore);
      localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree',response.customerOtherLanguageNumberTwoDateOfLanguageDegree);
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo',response.customerTotalScoreOfOtherLanguageNumberTwo);
      localStorage.setItem('customerOtherLanguageNumberTwoReadingScore',response.customerOtherLanguageNumberTwoReadingScore);
      localStorage.setItem('customerOtherLanguageNumberTwoWritingScore',response.customerOtherLanguageNumberTwoWritingScore);
      localStorage.setItem('customerOtherLanguageNumberTwoListeningScore',response.customerOtherLanguageNumberTwoListeningScore);
      localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore',response.customerOtherLanguageNumberTwoSpeakingScore);
      localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree',response.customerOtherLanguageNumberThreeDateOfLanguageDegree);
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree',response.customerTotalScoreOfOtherLanguageNumberThree);
      localStorage.setItem('customerOtherLanguageNumberThreeReadingScore',response.customerOtherLanguageNumberThreeReadingScore);
      localStorage.setItem('customerOtherLanguageNumberThreeWritingScore',response.customerOtherLanguageNumberThreeWritingScore);
      localStorage.setItem('customerOtherLanguageNumberThreeListeningScore',response.customerOtherLanguageNumberThreeListeningScore);
      localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore',response.customerOtherLanguageNumberThreeSpeakingScore);
     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerEnglishLanguageStatus','');
      localStorage.setItem('customerDateOfIELTSDegree','');
      localStorage.setItem('customerTotalScoreOfTheIELTSDegree','');
      localStorage.setItem('customerIELTSReadingScore','');
      localStorage.setItem('customerIELTSWritingScore','');
      localStorage.setItem('customerIELTSListeningScore','');
      localStorage.setItem('customerIELTSSpeakingScore','');
      localStorage.setItem('customerDateOfTOEFLDegree','');
      localStorage.setItem('customerTotalScoreOfTheTOEFLDegree','');
      localStorage.setItem('customerTOEFLReadingScore','');
      localStorage.setItem('customerTOEFLWritingScore','');
      localStorage.setItem('customerTOEFLListeningScore','');
      localStorage.setItem('customerTOEFLSpeakingScore','');
      localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree','');
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne','');
      localStorage.setItem('customerOtherLanguageNumberOneReadingScore','');
      localStorage.setItem('customerOtherLanguageNumberOneWritingScore','');
      localStorage.setItem('customerOtherLanguageNumberOneListeningScore','');
      localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore','');
      localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree','');
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo','');
      localStorage.setItem('customerOtherLanguageNumberTwoReadingScore','');
      localStorage.setItem('customerOtherLanguageNumberTwoWritingScore','');
      localStorage.setItem('customerOtherLanguageNumberTwoListeningScore','');
      localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore','');
      localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree','');
      localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree','');
      localStorage.setItem('customerOtherLanguageNumberThreeReadingScore','');
      localStorage.setItem('customerOtherLanguageNumberThreeWritingScore','');
      localStorage.setItem('customerOtherLanguageNumberThreeListeningScore','');
      localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore','');
    
   
    })
    else{
      // localStorage.setItem('customerEnglishLanguageStatus','');
      // localStorage.setItem('customerDateOfIELTSDegree','');
      // localStorage.setItem('customerTotalScoreOfTheIELTSDegree','');
      // localStorage.setItem('customerIELTSReadingScore','');
      // localStorage.setItem('customerIELTSWritingScore','');
      // localStorage.setItem('customerIELTSListeningScore','');
      // localStorage.setItem('customerIELTSSpeakingScore','');
      // localStorage.setItem('customerDateOfTOEFLDegree','');
      // localStorage.setItem('customerTotalScoreOfTheTOEFLDegree','');
      // localStorage.setItem('customerTOEFLReadingScore','');
      // localStorage.setItem('customerTOEFLWritingScore','');
      // localStorage.setItem('customerTOEFLListeningScore','');
      // localStorage.setItem('customerTOEFLSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne','');
      // localStorage.setItem('customerOtherLanguageNumberOneReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo','');
      // localStorage.setItem('customerOtherLanguageNumberTwoReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree','');
      // localStorage.setItem('customerOtherLanguageNumberThreeReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore','');
    }
    })
  }
  else if(localStorage.getItem('whoIs')=='sp'){
    fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseLanguageCertificate' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email')})
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
  localStorage.setItem('customerSpouseEnglishLanguageStatus',response.customerSpouseEnglishLanguageStatus);
  localStorage.setItem('customerSpouseDateOfIELTSDegree',response.customerSpouseDateOfIELTSDegree);
  localStorage.setItem('customerSpouseTotalScoreOfTheIELTSDegree',response.customerSpouseTotalScoreOfTheIELTSDegree);
  localStorage.setItem('customerSpouseIELTSReadingScore',response.customerSpouseIELTSReadingScore);
  localStorage.setItem('customerSpouseIELTSWritingScore',response.customerSpouseIELTSWritingScore);
  localStorage.setItem('customerSpouseIELTSListeningScore',response.customerSpouseIELTSListeningScore);
  localStorage.setItem('customerSpouseIELTSSpeakingScore',response.customerSpouseIELTSSpeakingScore);
  localStorage.setItem('customerSpouseDateOfTOEFLDegree',response.customerSpouseDateOfTOEFLDegree);
  localStorage.setItem('customerSpouseTotalScoreOfTheTOEFLDegree',response.customerSpouseTotalScoreOfTheTOEFLDegree);
  localStorage.setItem('customerSpouseTOEFLReadingScore',response.customerSpouseTOEFLReadingScore);
  localStorage.setItem('customerSpouseTOEFLWritingScore',response.customerSpouseTOEFLWritingScore);
  localStorage.setItem('customerSpouseTOEFLListeningScore',response.customerSpouseTOEFLListeningScore);
  localStorage.setItem('customerSpouseTOEFLSpeakingScore',response.customerSpouseTOEFLSpeakingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberOneDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberOneDateOfLanguageDegree);
  localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberOne',response.customerSpouseTotalScoreOfOtherLanguageNumberOne);
  localStorage.setItem('customerSpouseOtherLanguageNumberOneReadingScore',response.customerSpouseOtherLanguageNumberOneReadingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberOneWritingScore',response.customerSpouseOtherLanguageNumberOneWritingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberOneListeningScore',response.customerSpouseOtherLanguageNumberOneListeningScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberOneSpeakingScore',response.customerSpouseOtherLanguageNumberOneSpeakingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree);
  localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo',response.customerSpouseTotalScoreOfOtherLanguageNumberTwo);
  localStorage.setItem('customerSpouseOtherLanguageNumberTwoReadingScore',response.customerSpouseOtherLanguageNumberTwoReadingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberTwoWritingScore',response.customerSpouseOtherLanguageNumberTwoWritingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberTwoListeningScore',response.customerSpouseOtherLanguageNumberTwoListeningScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberTwoSpeakingScore',response.customerSpouseOtherLanguageNumberTwoSpeakingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree);
  localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberThree',response.customerSpouseTotalScoreOfOtherLanguageNumberThree);
  localStorage.setItem('customerSpouseOtherLanguageNumberThreeReadingScore',response.customerSpouseOtherLanguageNumberThreeReadingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberThreeWritingScore',response.customerSpouseOtherLanguageNumberThreeWritingScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberThreeListeningScore',response.customerSpouseOtherLanguageNumberThreeListeningScore);
  localStorage.setItem('customerSpouseOtherLanguageNumberThreeSpeakingScore',response.customerSpouseOtherLanguageNumberThreeSpeakingScore);
  

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataCustomerDocumentSpouseLanguageCertificate' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus')})
      }).then(response => response.json()).then(response=>{
        
        // setIspending(false);
     if((localStorage.getItem('emailCus')!=null && localStorage.getItem('usertype')!=3))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      
      localStorage.setItem('customerSpouseEnglishLanguageStatus',response.customerSpouseEnglishLanguageStatus);
      localStorage.setItem('customerSpouseDateOfIELTSDegree',response.customerSpouseDateOfIELTSDegree);
      localStorage.setItem('customerSpouseTotalScoreOfTheIELTSDegree',response.customerSpouseTotalScoreOfTheIELTSDegree);
      localStorage.setItem('customerSpouseIELTSReadingScore',response.customerSpouseIELTSReadingScore);
      localStorage.setItem('customerSpouseIELTSWritingScore',response.customerSpouseIELTSWritingScore);
      localStorage.setItem('customerSpouseIELTSListeningScore',response.customerSpouseIELTSListeningScore);
      localStorage.setItem('customerSpouseIELTSSpeakingScore',response.customerSpouseIELTSSpeakingScore);
      localStorage.setItem('customerSpouseDateOfTOEFLDegree',response.customerSpouseDateOfTOEFLDegree);
      localStorage.setItem('customerSpouseTotalScoreOfTheTOEFLDegree',response.customerSpouseTotalScoreOfTheTOEFLDegree);
      localStorage.setItem('customerSpouseTOEFLReadingScore',response.customerSpouseTOEFLReadingScore);
      localStorage.setItem('customerSpouseTOEFLWritingScore',response.customerSpouseTOEFLWritingScore);
      localStorage.setItem('customerSpouseTOEFLListeningScore',response.customerSpouseTOEFLListeningScore);
      localStorage.setItem('customerSpouseTOEFLSpeakingScore',response.customerSpouseTOEFLSpeakingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberOneDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberOneDateOfLanguageDegree);
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberOne',response.customerSpouseTotalScoreOfOtherLanguageNumberOne);
      localStorage.setItem('customerSpouseOtherLanguageNumberOneReadingScore',response.customerSpouseOtherLanguageNumberOneReadingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberOneWritingScore',response.customerSpouseOtherLanguageNumberOneWritingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberOneListeningScore',response.customerSpouseOtherLanguageNumberOneListeningScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberOneSpeakingScore',response.customerSpouseOtherLanguageNumberOneSpeakingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree);
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo',response.customerSpouseTotalScoreOfOtherLanguageNumberTwo);
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoReadingScore',response.customerSpouseOtherLanguageNumberTwoReadingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoWritingScore',response.customerSpouseOtherLanguageNumberTwoWritingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoListeningScore',response.customerSpouseOtherLanguageNumberTwoListeningScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoSpeakingScore',response.customerSpouseOtherLanguageNumberTwoSpeakingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree',response.customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree);
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberThree',response.customerSpouseTotalScoreOfOtherLanguageNumberThree);
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeReadingScore',response.customerSpouseOtherLanguageNumberThreeReadingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeWritingScore',response.customerSpouseOtherLanguageNumberThreeWritingScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeListeningScore',response.customerSpouseOtherLanguageNumberThreeListeningScore);
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeSpeakingScore',response.customerSpouseOtherLanguageNumberThreeSpeakingScore);
     
  
    }
     
    }).catch(()=>{
      
      localStorage.setItem('customerSpouseEnglishLanguageStatus','');
      localStorage.setItem('customerSpouseDateOfIELTSDegree','');
      localStorage.setItem('customerSpouseTotalScoreOfTheIELTSDegree','');
      localStorage.setItem('customerSpouseIELTSReadingScore','');
      localStorage.setItem('customerSpouseIELTSWritingScore','');
      localStorage.setItem('customerSpouseIELTSListeningScore','');
      localStorage.setItem('customerSpouseIELTSSpeakingScore','');
      localStorage.setItem('customerSpouseDateOfTOEFLDegree','');
      localStorage.setItem('customerSpouseTotalScoreOfTheTOEFLDegree','');
      localStorage.setItem('customerSpouseTOEFLReadingScore','');
      localStorage.setItem('customerSpouseTOEFLWritingScore','');
      localStorage.setItem('customerSpouseTOEFLListeningScore','');
      localStorage.setItem('customerSpouseTOEFLSpeakingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberOneDateOfLanguageDegree','');
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberOne','');
      localStorage.setItem('customerSpouseOtherLanguageNumberOneReadingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberOneWritingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberOneListeningScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberOneSpeakingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree','');
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo','');
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoReadingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoWritingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoListeningScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberTwoSpeakingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree','');
      localStorage.setItem('customerSpouseTotalScoreOfOtherLanguageNumberThree','');
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeReadingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeWritingScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeListeningScore','');
      localStorage.setItem('customerSpouseOtherLanguageNumberThreeSpeakingScore','');
    
   
    })
    else{
      // localStorage.setItem('customerSpouseEnglishLanguageStatus','');
      // localStorage.setItem('customerSpouseDateOfIELTSDegree','');
      // localStorage.setItem('customerSpouseTotalScoreOfTheIELTSDegree','');
      // localStorage.setItem('customerSpouseIELTSReadingScore','');
      // localStorage.setItem('customerSpouseIELTSWritingScore','');
      // localStorage.setItem('customerIELTSListeningScore','');
      // localStorage.setItem('customerIELTSSpeakingScore','');
      // localStorage.setItem('customerDateOfTOEFLDegree','');
      // localStorage.setItem('customerTotalScoreOfTheTOEFLDegree','');
      // localStorage.setItem('customerTOEFLReadingScore','');
      // localStorage.setItem('customerTOEFLWritingScore','');
      // localStorage.setItem('customerTOEFLListeningScore','');
      // localStorage.setItem('customerTOEFLSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberOne','');
      // localStorage.setItem('customerOtherLanguageNumberOneReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberOneSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberTwo','');
      // localStorage.setItem('customerOtherLanguageNumberTwoReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberTwoSpeakingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeDateOfLanguageDegree','');
      // localStorage.setItem('customerTotalScoreOfOtherLanguageNumberThree','');
      // localStorage.setItem('customerOtherLanguageNumberThreeReadingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeWritingScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeListeningScore','');
      // localStorage.setItem('customerOtherLanguageNumberThreeSpeakingScore','');
    }
    })
  }
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
        forceUpdate();


      if (localStorage.getItem('attachmentFile')!='null')
      {
        values.JobUpload =localStorage.getItem('attachmentFile');
        values.files2 = localStorage.getItem('attachmentFile')
      }
      else 
      {
        values.JobUpload = '';
        values.files2 = '';
      }

     
      if(localStorage.getItem('whoIs')=='ap'){
      values.EnglishLanguageStatus =localStorage.getItem('customerEnglishLanguageStatus');
      
      values.TotalScoreDegreeIELTS =localStorage.getItem('customerTotalScoreOfTheIELTSDegree');
      values.IELTSReadingScore =localStorage.getItem('customerIELTSReadingScore');
      values.IELTSWritingScore =localStorage.getItem('customerIELTSWritingScore');
      values.IELTSListeningScore =localStorage.getItem('customerIELTSListeningScore');
      values.IELTSSpeackingScore =localStorage.getItem('customerIELTSSpeakingScore');
      
      values.TotalScoreDegreeTOEFL =localStorage.getItem('customerTotalScoreOfTheTOEFLDegree');
      values.TOEFLReadingScore =localStorage.getItem('customerTOEFLReadingScore');
      values.TOEFLWritingScore =localStorage.getItem('customerTOEFLWritingScore');
      values.TOEFLListeningScore =localStorage.getItem('customerTOEFLListeningScore');
      values.TOEFLSpeackingScore =localStorage.getItem('customerTOEFLSpeakingScore');
      
      values.TotalScoreDegreeOtherOne =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberOne');
      values.OtherOneReadingScore =localStorage.getItem('customerOtherLanguageNumberOneReadingScore');
      values.OtherOneWritingScore =localStorage.getItem('customerOtherLanguageNumberOneWritingScore');
      values.OtherOneListeningScore =localStorage.getItem('customerOtherLanguageNumberOneListeningScore');
      values.OtherOneSpeackingScore =localStorage.getItem('customerOtherLanguageNumberOneSpeakingScore');
      
      values.TotalScoreDegreeOtherTwo =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberTwo');
      values.OtherTwoReadingScore =localStorage.getItem('customerOtherLanguageNumberTwoReadingScore');
      values.OtherTwoWritingScore =localStorage.getItem('customerOtherLanguageNumberTwoWritingScore');
      values.OtherTwoListeningScore =localStorage.getItem('customerOtherLanguageNumberTwoListeningScore');
      values.OtherTwoSpeackingScore =localStorage.getItem('customerOtherLanguageNumberTwoSpeakingScore');
      
      values.TotalScoreDegreeOtherThree =localStorage.getItem('customerTotalScoreOfOtherLanguageNumberThree');
      values.OtherThreeReadingScore =localStorage.getItem('customerOtherLanguageNumberThreeReadingScore');
      values.OtherThreeWritingScore =localStorage.getItem('customerOtherLanguageNumberThreeWritingScore');
      values.OtherThreeListeningScore =localStorage.getItem('customerOtherLanguageNumberThreeListeningScore');
      values.OtherThreeSpeackingScore =localStorage.getItem('customerOtherLanguageNumberThreeSpeakingScore');
  
      setDateofIELTSdegreeValue(localStorage.getItem('customerDateOfIELTSDegree'));
      setDateofTOEFLdegreeValue(localStorage.getItem('customerDateOfTOEFLDegree'));
      setDateofOther1degreeValue(localStorage.getItem('customerOtherLanguageNumberOneDateOfLanguageDegree'));
      setDateofOther2degreeValue(localStorage.getItem('customerOtherLanguageNumberTwoDateOfLanguageDegree'));
      setDateofOther3degreeValue(localStorage.getItem('customerOtherLanguageNumberThreeDateOfLanguageDegree'));
      }
      else if(localStorage.getItem('whoIs')=='sp'){
        values.EnglishLanguageStatus =localStorage.getItem('customerSpouseEnglishLanguageStatus');
      
        values.TotalScoreDegreeIELTS =localStorage.getItem('customerSpouseTotalScoreOfTheIELTSDegree');
        values.IELTSReadingScore =localStorage.getItem('customerSpouseIELTSReadingScore');
        values.IELTSWritingScore =localStorage.getItem('customerSpouseIELTSWritingScore');
        values.IELTSListeningScore =localStorage.getItem('customerSpouseIELTSListeningScore');
        values.IELTSSpeackingScore =localStorage.getItem('customerSpouseIELTSSpeakingScore');
        
        values.TotalScoreDegreeTOEFL =localStorage.getItem('customerSpouseTotalScoreOfTheTOEFLDegree');
        values.TOEFLReadingScore =localStorage.getItem('customerSpouseTOEFLReadingScore');
        values.TOEFLWritingScore =localStorage.getItem('customerSpouseTOEFLWritingScore');
        values.TOEFLListeningScore =localStorage.getItem('customerSpouseTOEFLListeningScore');
        values.TOEFLSpeackingScore =localStorage.getItem('customerSpouseTOEFLSpeakingScore');
        
        values.TotalScoreDegreeOtherOne =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberOne');
        values.OtherOneReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneReadingScore');
        values.OtherOneWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneWritingScore');
        values.OtherOneListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneListeningScore');
        values.OtherOneSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberOneSpeakingScore');
        
        values.TotalScoreDegreeOtherTwo =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberTwo');
        values.OtherTwoReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoReadingScore');
        values.OtherTwoWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoWritingScore');
        values.OtherTwoListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoListeningScore');
        values.OtherTwoSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberTwoSpeakingScore');
        
        values.TotalScoreDegreeOtherThree =localStorage.getItem('customerSpouseTotalScoreOfOtherLanguageNumberThree');
        values.OtherThreeReadingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeReadingScore');
        values.OtherThreeWritingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeWritingScore');
        values.OtherThreeListeningScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeListeningScore');
        values.OtherThreeSpeackingScore =localStorage.getItem('customerSpouseOtherLanguageNumberThreeSpeakingScore');
    
        setDateofIELTSdegreeValue(localStorage.getItem('customerSpouseDateOfIELTSDegree'));
        setDateofTOEFLdegreeValue(localStorage.getItem('customerSpouseDateOfTOEFLDegree'));
        setDateofOther1degreeValue(localStorage.getItem('customerSpouseOtherLanguageNumberOneDateOfLanguageDegree'));
        setDateofOther2degreeValue(localStorage.getItem('customerSpouseOtherLanguageNumberTwoDateOfLanguageDegree'));
        setDateofOther3degreeValue(localStorage.getItem('customerSpouseOtherLanguageNumberThreeDateOfLanguageDegree'));
      }
        return () => clearTimeout(timer);
      }
    }, [state]);

//********************************************************** */
//********************************************************** */


const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


              const [image13 , setImage13] = useState(null);
              const   onImage13Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader13 = new FileReader();
                    reader13.onload = (e) => {
                      setImage13(e.target.result);
                      document.getElementById('target13').style.display ="block";
                      document.getElementById('MyGrayBox13').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader13.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage13 = () =>{
                  document.getElementById('target13').style.display ="none";
                  document.getElementById('MyGrayBox13').style.display= "flex";
                }

        const [DateofIELTSdegreevalue, setDateofIELTSdegreeValue] = useState(localStorage.getItem('customerDateOfIELTSDegree'))
        const [DateofTOEFLdegreevalue, setDateofTOEFLdegreeValue] = useState(localStorage.getItem('customerDateOfTOEFLDegree'))
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState(localStorage.getItem('customerOtherLanguageNumberOneDateOfLanguageDegree'))
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState(localStorage.getItem('customerOtherLanguageNumberTwoDateOfLanguageDegree'))
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState(localStorage.getItem('customerOtherLanguageNumberThreeDateOfLanguageDegree'))
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
        // console.log(DateofIELTSdegreevalue.toString());
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 2-1')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Foreign Language Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Spouse Foreign Language Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully to follow-up your request process')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Language Certifications')}</p> </div>
          
          <div className="Cus2Inputs">

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EnglishLanguageStatus"
                  value={values.EnglishLanguageStatus}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EnglishLanguageStatus')}  
                  placeholder={t('English Language Status')}
                  required
                  >
                    <option  value="">
                    {t('English Language Status')}
                  </option >
                  <option  value='1'>{t('I Have')}</option >
                  <option  value='2'>{t('I do not have')}</option >
                  <option  value='3'>{t('I am reading')}</option >
                  <option  value='4'>{t('I will be able to get a degree in the next 6 months')}</option >
                  <option  value='5'>{t('I will not be able to get a degree for the next 8 months')}</option > 
                </select >
                <label htmlFor="EnglishLanguageStatus">{t('Enter the applicant’s English Language Status')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('IELTS')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofIELTSdegreevalue}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of IELTS degree')}
              id="DateofIELTSdegree"
              onChange={setDateofIELTSdegreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofIELTSdegree">{t('Enter the applicant’s Date of IELTS degree')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeIELTS" 
                value={values.TotalScoreDegreeIELTS}
                type="text"
                placeholder={t('The total score of the degree')}
                onChange={handleChange('TotalScoreDegreeIELTS')}
                />
                <label htmlFor="TotalScoreDegreeIELTS">{t('Enter the applicant’s total score of the degree')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSReadingScore" 
                value={values.IELTSReadingScore}
                type="text"
                placeholder={t('IELTS Reading Score')}
                onChange={handleChange('IELTSReadingScore')}
                />
                <label htmlFor="IELTSReadingScore">{t('Enter the applicant’s IELTS Reading Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSWritingScore" 
                value={values.IELTSWritingScore}
                type="text"
                placeholder={t('IELTS Writing Score')}
                onChange={handleChange('IELTSWritingScore')}
                />
                <label htmlFor="IELTSWritingScore">{t('Enter the applicant’s IELTS Writing Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSListeningScore" 
                value={values.IELTSListeningScore}
                type="text"
                placeholder={t('IELTS Listening Score')}
                onChange={handleChange('IELTSListeningScore')}
                />
                <label htmlFor="IELTSListeningScore">{t('Enter the applicant’s IELTS Listening Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSSpeackingScore" 
                value={values.IELTSSpeackingScore}
                type="text"
                placeholder={t('IELTS Speacking Score')}
                onChange={handleChange('IELTSSpeackingScore')}
                />
                <label htmlFor="IELTSSpeackingScore">{t('Enter the applicant’s IELTS Speacking Score')}</label>
            </div>

            

           
  
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('TOEFL')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofTOEFLdegreevalue}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of TOEFL degree')}
              id="DateofTOEFLdegree"
              onChange={setDateofTOEFLdegreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofTOEFLdegree">{t('Enter the applicant’s Date of TOEFL degree')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeTOEFL" 
                value={values.TotalScoreDegreeTOEFL}
                type="text"
                placeholder={t('The total score of the degree')}
                onChange={handleChange('TotalScoreDegreeTOEFL')}
                />
                <label htmlFor="TotalScoreDegreeTOEFL">{t('Enter the applicant’s total score of the degree')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLReadingScore" 
                value={values.TOEFLReadingScore}
                type="text"
                placeholder={t('TOEFL Reading Score')}
                onChange={handleChange('TOEFLReadingScore')}
                />
                <label htmlFor="TOEFLReadingScore">{t('Enter the applicant’s TOEFL Reading Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLWritingScore" 
                value={values.TOEFLWritingScore}
                type="text"
                placeholder={t('TOEFL Writing Score')}
                onChange={handleChange('TOEFLWritingScore')}
                />
                <label htmlFor="TOEFLWritingScore">{t('Enter the applicant’s TOEFL Writing Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLListeningScore" 
                value={values.TOEFLListeningScore}
                type="text"
                placeholder={t('TOEFL Listening Score')}
                onChange={handleChange('TOEFLListeningScore')}
                />
                <label htmlFor="TOEFLListeningScore">{t('Enter the applicant’s TOEFL Listening Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLSpeackingScore" 
                value={values.TOEFLSpeackingScore}
                type="text"
                placeholder={t('TOEFL Speacking Score')}
                onChange={handleChange('TOEFLSpeackingScore')}
                />
                <label htmlFor="TOEFLSpeackingScore">{t('Enter the applicant’s TOEFL Speacking Score')}</label>
            </div>

            

          <div className="Cus2PersonalInfo col-12"> 
            <p>{t('Other Language (1)')}</p> 
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther1degreevalue}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (1)')}
              id="DateofOther1degree"
              onChange={setDateofOther1degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther1degree">{t('Enter the applicant’s Date of Other Language degree') + '(1)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherOne" 
                value={values.TotalScoreDegreeOtherOne}
                type="text"
                placeholder={t('The total score of the Other Language degree(1)')}
                onChange={handleChange('TotalScoreDegreeOtherOne')}
                />
                <label htmlFor="TotalScoreDegreeOtherOne">{t('Enter the applicant’s total score of the Other Language degree(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneReadingScore" 
                value={values.OtherOneReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(1)')}
                onChange={handleChange('OtherOneReadingScore')}
                />
                <label htmlFor="OtherOneReadingScore">{t('Enter the applicant’s Other Language Reading Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneWritingScore" 
                value={values.OtherOneWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(1)')}
                onChange={handleChange('OtherOneWritingScore')}
                />
                <label htmlFor="OtherOneWritingScore">{t('Enter the applicant’s Other Language Writing Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneListeningScore" 
                value={values.OtherOneListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(1)')}
                onChange={handleChange('OtherOneListeningScore')}
                />
                <label htmlFor="OtherOneListeningScore">{t('Enter the applicant’s Other Language Listening Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneSpeackingScore" 
                value={values.OtherOneSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(1)')}
                onChange={handleChange('OtherOneSpeackingScore')}
                />
                <label htmlFor="OtherOneSpeackingScore">{t('Enter the applicant’s Other Language Speacking Score(1)')}</label>
            </div>
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Other Language (2)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther2degreevalue}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (2)')}
              id="DateofOther2degree"
              onChange={setDateofOther2degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther2degree">{t('Enter the applicant’s Date of Other Language degree') + '(2)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherTwo" 
                value={values.TotalScoreDegreeOtherTwo}
                type="text"
                placeholder={t('The total score of the Other Language degree(2)')}
                onChange={handleChange('TotalScoreDegreeOtherTwo')}
                />
                <label htmlFor="TotalScoreDegreeOtherTwo">{t('Enter the applicant’s total score of the Other Language degree(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoReadingScore" 
                value={values.OtherTwoReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(2)')}
                onChange={handleChange('OtherTwoReadingScore')}
                />
                <label htmlFor="OtherTwoReadingScore">{t('Enter the applicant’s Other Language Reading Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoWritingScore" 
                value={values.OtherTwoWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(2)')}
                onChange={handleChange('OtherTwoWritingScore')}
                />
                <label htmlFor="OtherTwoWritingScore">{t('Enter the applicant’s Other Language Writing Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoListeningScore" 
                value={values.OtherTwoListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(2)')}
                onChange={handleChange('OtherTwoListeningScore')}
                />
                <label htmlFor="OtherTwoListeningScore">{t('Enter the applicant’s Other Language Listening Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoSpeackingScore" 
                value={values.OtherTwoSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(2)')}
                onChange={handleChange('OtherTwoSpeackingScore')}
                />
                <label htmlFor="OtherTwoSpeackingScore">{t('Enter the applicant’s Other Language Speacking Score(2)')}</label>
            </div>

            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Other Language (3)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther3degreevalue}
              format="YYYY/MM/DD "
              calendar={gregorian}
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (3)')}
              id="DateofOther3degree"
              onChange={setDateofOther3degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther3degree">{t('Enter the applicant’s Date of Other Language degree') + '(3)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherThree" 
                value={values.TotalScoreDegreeOtherThree}
                type="text"
                placeholder={t('The total score of the Other Language degree(3)')}
                onChange={handleChange('TotalScoreDegreeOtherThree')}
                />
                <label htmlFor="TotalScoreDegreeOtherThree">{t('Enter the applicant’s total score of the Other Language degree(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeReadingScore" 
                value={values.OtherThreeReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(3)')}
                onChange={handleChange('OtherThreeReadingScore')}
                />
                <label htmlFor="OtherThreeReadingScore">{t('Enter the applicant’s Other Language Reading Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeWritingScore" 
                value={values.OtherThreeWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(3)')}
                onChange={handleChange('OtherThreeWritingScore')}
                />
                <label htmlFor="OtherThreeWritingScore">{t('Enter the applicant’s Other Language Writing Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeListeningScore" 
                value={values.OtherThreeListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(3)')}
                onChange={handleChange('OtherThreeListeningScore')}
                />
                <label htmlFor="OtherThreeListeningScore">{t('Enter the applicant’s Other Language Listening Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeSpeackingScore" 
                value={values.OtherThreeSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(3)')}
                onChange={handleChange('OtherThreeSpeackingScore')}
                />
                <label htmlFor="OtherThreeSpeackingScore">{t('Enter the applicant’s Other Language Speacking Score(3)')}</label>
            </div>




          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Foreign Language Documents')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">
          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
              <div className="MyCusUploadGray" id="MyGrayBox">
                  <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                  <input 
                  className="MyCusInputFileUpload"
                  type="file" 
                  onChange={onImageChange} 
                  id="group_image"
                  accept="image/gif, image/jpeg, image/png"
                  />
                  {/* <input 
                  type="file" 
                  className="MyCusInputFileUpload"
                  onChange={}
                  /> */}
              </div>
              <div className="MyClickAbleRectForHide">
              <img id="target" src={image} className="MyCusImageUploadPreview"/>
              <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
                <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
                <ExportSquare size="24" color="#ffffff" />
                </div>
              </div>
              <p>{t('IELTS')}</p> 
            </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
              <div className="MyCusUploadGray" id="MyGrayBox2">
                  <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                  <input
                  className="MyCusInputFileUpload"
                  type="file" 
                  onChange={onImage2Change} 
                  id="group_image"
                  accept="image/gif, image/jpeg, image/png"
                  />
                  {/* <input 
                  type="file" 
                  className="MyCusInputFileUpload"
                  onChange={}
                  /> */}
              </div>
              <div className="MyClickAbleRectForHide">
                <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
                <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
                  <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
                  <ExportSquare size="24" color="#ffffff" />
                </div>
              </div>
              <p>{t('TOEFL')}</p> 
            </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
            <p>{t('Other Language(1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Language(2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Language(3)')}</p> 
          </div>
          </div>

         

   </div>


   {localStorage.getItem('userType')=='3' && <div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
            {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>
        
        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are You Sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;