import { Hidden } from "@mui/material";
import { useState } from "react";
import React from "react";
import { Visibility } from "semantic-ui-react";
 
const Popup = props => {

  // const [isOpen, setIsOpen] = useState(false);
  const togglePopup11 = () => {
    // console.log(isOpen);
    if(!(document.getElementById('MobileLeftNavPro') || document.getElementById('MobileLeftNavHelpCenter'))){
      document.getElementById('leftNavMobileMenu').style.visibility="hidden";
      // setIsOpen(!isOpen);
    }
    // else if(window.innerWidth<750){
  
    //   document.getElementById('leftNavMobileMenu').style.removeProperty('visibility');
    //   document.getElementById('leftNavMobileMenu').style.visibility="visible";
    //   // setIsOpen(!isOpen);
    // }
    // forceUpdate();
    //console.log(isOpen);
  
  } 
  return (
    <div onClick={togglePopup11} className="popup-box" id='leftNavMobileMenu'>
      <div className="menu_mobile">
        {/* <div className="close-icon" onClick={props.handleClose}><span>&times;</span></div> */}
        {props.content}
      </div>
    </div>
  );
};
 
export default Popup;